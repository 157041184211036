<template>
    <div id="itVisualisation" class="tests conteneurTable" v-if="testDatasReady">
        <div v-if="comparaisonEnCours" class="ratios">

            <h3 class="titreListe petit">
                <span @click.stop="ratio='BNA'" v-bind:class="{enLien:ratio!='BNA', enCours:ratio=='BNA'}" class="partieGauche"><abbr>BNA</abbr></span>
                <span @click.stop="ratio='ACE'" v-bind:class="{enLien:ratio!='ACE', enCours:ratio=='ACE'}" class="partieMilieu"><abbr>ACE</abbr></span>
                <span @click.stop="ratio='TIR'" v-bind:class="{enLien:ratio!='TIR', enCours:ratio=='TIR'}" class="partieDroite"><abbr>TIR</abbr></span>
            </h3>
            <h3 class="titreListe petit">
                <span @click.stop="ratio='volume'" v-bind:class="{enLien:ratio!='volume', enCours:ratio=='volume'}" class="partieGauche"><abbr>Volume</abbr></span>
                <span class="partieMilieuVide">&nbsp;</span>
                <span @click.stop="ratio='volumeUnitaire'" v-bind:class="{enLien:ratio!='volumeUnitaire', enCours:ratio=='volumeUnitaire'}" class="partieDroite vu"><abbr>Volume unitaire</abbr></span>
            </h3>
            <h3 class="titreListe" v-if="comparaison.it1.coupeRase && comparaison.it2.coupeRase">
                <span @click.stop="ratio='S&S'" v-bind:class="{enLien:ratio!='S&S', enCours:ratio=='S&S'}" class="partieGauche"><abbr>Stock forêt / Produits bois</abbr></span>
<!--
                <span @click.stop="ratio='SUB'" v-bind:class="{enLien:ratio!='SUB', enCours:ratio=='SUB'}" class="partieMilieu">Substitution</span>
                <span @click.stop="ratio='TOT'" v-bind:class="{enLien:ratio!='TOT', enCours:ratio=='TOT'}" class="partieDroite">Total carbone</span>
-->                <span class="partieMilieu partieMilieuVide">&nbsp;</span>
                <span @click.stop="ratio='SUB'" v-bind:class="{enLien:ratio!='SUB', enCours:ratio=='SUB'}" class="partieDroite"><abbr>Substitution</abbr></span>
            </h3>

        </div>
        <div v-if="comparaisonEnCours" class="containerCourbes" v-html="contentSVG2"></div>

        <div v-if="ratioInfoComparaison" class="infoComparaison" v-html="ratioInfoComparaison"></div>

    </div>
</template>


<script>

import { ApiConsts } from "../../js/api/index.js"
import * as SteresConst from '../../js/steres_const.js'

//import { SteresConstantes } from "../../js/calculs/constantes.js"
import { UfPeuplement1, UfPeuplement2 } from "../../js/calculs/peuplementObj.js"
//import { UfPplDendrometrique1, UfPplDendrometrique2 } from '../../js/calculs/dendrometriqueObj.js'
import { UfPplDendrometrique1, UfPplDendrometrique2 } from '../../js/calculs/dendrometriqueObjTER.js'
import { UfPplFinancier1, UfPplFinancier2 } from '../../js/calculs/financierObj.js'
import { UfPplCarbone1, UfPplCarbone2 } from '../../js/calculs/carboneObj.js'

import { courbeSVG } from "../../js/svg/svgCourbes.js"
import { grapheSVG } from "../../js/svg/svgGraphes.js"

export default {


    props: {
        data: {
            type: Object
        }
    },

    data(){
        return {
            comparaison:JSON.parse(JSON.stringify(this.data)),
            datasReady:false,
            constantes : {},
            ppl : {},
            tabValeurS:[],
            svgContent2: "",
            courbe2: null,
            ratio:"BNA"
        }
    },

    created: function () {
        return(0);
    },

    mounted: function () {
        this.datasReady = true;
        return(0);
    },

    updated: function () {
    },

    watch: {

        comparaisonEnCours(newValue) {
            if(newValue) {
                // 2022
                //on différencie les courbes carbone
                if  (newValue && (newValue.ratio =='S&S' || newValue.ratio =='SUB' || newValue.ratio =='TOT')) {
                    this.lancerAffichage(newValue, 1)
                }
                else {
                    this.lancerAffichage(newValue, 0)
                }
            }
        },
        data(newValue) {
            if(newValue) {
                this.comparaison = JSON.parse(JSON.stringify(this.data));
            }
        },
        ratio(newValue) {
            if(newValue) {
                this.comparaison.ratio = newValue;
                this.comparaison = JSON.parse(JSON.stringify(this.comparaison));
            }
        },
    },

    computed: {
        testDatasReady() {
            return(this.$store.getters.constantes.ready && this.datasReady);
        },

        uf() {
            return this.$store.getters.infosUf
        },

        comparaisonEnCours() {
            if (this.testDatasReady) {
                return this.comparaison;
            }
            return null;
        },

        contentSVG2 () {
            return this.svgContent2
        },
        ratioInfoComparaison() {
            var infos = null;
            if (!this.comparaison || !this.comparaison.ratio) {
                return(infos);
            }
            if ((this.comparaison.ratio == 'S&S') || (this.comparaison.ratio == 'SUB') || (this.comparaison.ratio == 'TOT')) {
                infos = "<b>IMPORTANT :</b><br/><br/>"
                }
            switch (this.comparaison.ratio) {
                case 'S&S' :
                    infos += "Le stock moyen de long terme est l'indicateur utilisé pour définir le gain carbone dans le label Bas-Carbone et pour les certifications internationales.<br/><br/>Cet indicateur consiste à calculer la valeur d'un stock moyen comme si on répétait l'itinéraire à l'infini, d'où sa pertinence pour les comparaisons (notamment lorsque les durées d'itinéraire sont différentes).";
                    break;
                case 'SUB' :
                    infos += "Il s'agit ici d'un flux moyen annuel de substitution sur la durée de l'itinéraire à l'issue de la Coupe Rase.";
                    break;
                case 'TOT' :
                    infos += "";
                    break;
            }
            return(infos);
        }
    },

    methods: {


        arrondir(valeur, puissance = 3) {
            var puissanceDe10 = Math.pow(10, puissance);
            var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
            return(arrondi);
        },
        formaterEuros: function(valeur) {
            return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
        },

        lancerAffichage(comparaison, carbone) {
            this.constantes = this.$store.getters.constantes;
            this.abaqueEclaircies = this.constantes.abaqueEclaircies;
            this.infosPropriete = this.$store.getters.infosPropriete;
            this.svgContent2 = "";
            this.visualiserITs(comparaison, carbone);
            setTimeout(courbeSVG.maj.bind(null, this.courbe2), 1000);
        },

        visualiserITs(comparaison, carbone) {
            var it1 = comparaison.it1;
            var it2 = comparaison.it2;

            this.uf.peuplement = this.$store.getters.infosPeuplement; // on s'assure d'être à jour

            // peuplement
            UfPeuplement1.compiler(this.uf, this.infosPropriete, this.constantes);
            UfPeuplement2.compiler(this.uf, this.infosPropriete, this.constantes);

            // dendrometrie
            var ageMaxCalculs = UfPeuplement1.essence.vieMax+1;
            if  (comparaison.ratio =='S&S' || comparaison.ratio =='SUB' || comparaison.ratio =='TOT') {
                // on double la logueur de stockage car on double le stokage des valeurs "stock moyen de long terme"
                ageMaxCalculs *= 2;
            }
            var params1 = {"it":it1, "calculOptimumFutur":true, "calculOptimumPasse":false, "ageMaxTest":ageMaxCalculs};
            UfPplDendrometrique1.initialiserUfPeuplement(UfPeuplement1, params1);
            var params2 = {"it":it2, "calculOptimumFutur":true, "calculOptimumPasse":false, "ageMaxTest":ageMaxCalculs};
            UfPplDendrometrique2.initialiserUfPeuplement(UfPeuplement2, params2);

            // pour affichage !
            this.ppl = UfPplDendrometrique1.ppl;

            // et calculs/ratios financiers
            var params = {"ageMaxTest":ageMaxCalculs};
            UfPplFinancier1.initialiserUfFinancier(UfPplDendrometrique1, params);
            UfPplFinancier2.initialiserUfFinancier(UfPplDendrometrique2, params);

            // initialisation courbes
            if (carbone) { // spécifique carbone
                UfPplCarbone1.initialiserUfCarbone(UfPplDendrometrique1, this.constantes);
                UfPplCarbone2.initialiserUfCarbone(UfPplDendrometrique2, this.constantes);
                // barres
                this.courbe2 = UfPplCarbone1.initialiserGraphesComparaison(UfPplCarbone1, UfPplCarbone2, comparaison.ratio);
                this.svgContent2 = grapheSVG.initialiserGraphe(this.$store, this.courbe2);
            }
            else {
                this.courbe2 = UfPplFinancier1.initialiserCourbesComparaison(UfPplFinancier1, UfPplFinancier2, comparaison.ratio);
                // courbes
                this.svgContent2 = courbeSVG.initialiserCourbe(this.$store, this.courbe2);
            }


            // on stocke les résultats

            this.$store.commit('setDendrometrie1', UfPplDendrometrique1);
            this.$store.commit('setDendrometrie2', UfPplDendrometrique2);
//            this.$store.commit('setFinances', UfPplFinancier);
        },

    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #itineraires {
        #itinerairesTechniquesComparaisonVisu {
            min-height: 800px;

            span {
                    &:hover {
                        text-decoration: none;
                    }
                }


            #itVisualisation {

                .ratios {
                    margin:0;
                    text-align: center;
                    font-size:90%;
                    margin : 1em auto 3em;

                    h3 {
                        margin:0.5em auto;
                    }

                    .v-input--radio-group {
                        background-color: transparent;
                        margin: 1em 1em 0;
                        padding: 0;

                        .v-input__slot {
                            padding: 0;
                            margin: 0;
                            text-align: center;

                            .v-input--radio-group__input {
                                display: inline-block;

                                .v-radio {
                                    display: inline-block;
                                    margin-bottom: 0;

                                    .v-label, .v-icon {
                                        color: #238b71;
                                        vertical-align: super;
                                    }
                                    /*
                                    .v-input--selection-controls__input {
                                        input {
                                            color: #238b71;
                                            background-color: #238b71;
                                        }
                                    }
                                    */
                                }
                            }
                        }
                    }

                    span {
                        margin:0 1em 0 0.3em;
                    }

                    h3.titreListe {
                        max-width: 800px;
                        span {
                            width:30%;
                            margin:0;
                            &.vu {
                                width:24%;
                            }
                        }
                        &.petit {
                            span {
                                width:19%;
                            }
                        }

                        @media only screen and (max-width: 820px) {
                            span {
                                    height: 4em;
                                    font-size: 0.9em;
                                    vertical-align: middle;
                                    display: inline-block;
                                    position:relative;
                                    abbr {
                                        position:relative;
                                        vertical-align: middle;
                                        top:50%;
                                        transform:translateY(-50%);
                                        display: block;
                                        height: auto;
                                    }
                                }
                            }

                    }
                }

                .containerCourbes {
                    text-align: center;
                }

                #courbeHauteur {
                    /*max-width:1200px;*/
                    width:100%;
                    margin:0;
                    background-color: white;
                    padding : 0;
                }
                .containerCourbes svg {
                    pointer-events:all;

                }
                .infoComparaison {
                    margin:0 0 4em 50px;
                }


            }
        }
    }
</style>
