<style>
/*
    @import './Courbes.css';
*/
</style>

<template>

  <div id="densite" class="home containerLog">
    <div class="sectionAffichage">
        <div class="conteneur">

            <h1 >Pins maritimes : densité d'un peuplement</h1>

            <h3>Calculer la densité de votre parcelle de pins maritimes</h3>

            <div class="presentation">
                Le CPFA vous propose cet outil de mesure de densités des peuplement en pins maritimes pour vous permettre de normaliser vos données et d'augmenter leur qualité.
                <br/><br/>
                Consultez les informations ci-dessous pour connaître les process que nous préconisons.
            </div>
            <div class="centrer"><v-btn class="a-btn leBouton" type="button" @click="enSavoirPlus=true;dialogESP = true;">Information : quelles mesures pour le calcul de la densité d'un peuplement ?</v-btn></div>

            <div class="clear"></div>

            <div class="infosSurface">
                <h2>
                    <span class="numberCircle">1</span><span class="titre">Surface boisée (facultatif)</span>
                    <div class="clear"></div>
                </h2>
                <div class="consigne clear consigneSurface">
                    Si vous la connaissez, indiquez la surface boisée de votre peuplement en <span class="souligne">hectare</span> (1 h par défaut).<br/>Cette surface sera utilisée pour vous proposer un nombre de placettes optimal.
                </div>
                <div class="saisieSurface">
                    <v-text-field
                            label="Surface de votre peuplement (h)"
                            v-model="surfacePeuplement"
                            type="number"
                             @keydown="filtrerNombresPositifs"
                            min=1
                            required
                            ref="numSaisie"
                    ></v-text-field>
                </div>
                <div class="clear"></div>
            </div>

            <h2>
                    <span class="numberCircle">2</span><span class="titre">Calcul de la densité</span>
                    <div class="clear"></div>
            </h2>
            <div class="leCalcul">
                <div class="gauche resultat">Votre résultat<i class="fas fa-arrow-right"></i></div>
                <calcul-densite
                    @retourDensite="onRetourDensite"
                    @retourSelectionMethode="onRetourSelectionMethode"
                    :initDatas="infosCalculsDensite"
                    :surfaceBoisee="laSurfaceBoisee"
                    :initCirconference="circonferenceQuadratique"
                    @click:clear="clickClear"
                    required
                    ref="calculDensite"
                />
                <div v-if="surfaceTerriereChoisi">
                     <span>Circonférence : {{circonferenceQuadratique}} cm</span>

                     <div v-if="lesCirconferences.length" class="itemIconeFct" @click.stop="circonferencesRenseignerSerie()">
                        Série de circonférences :
                        <span class="" v-for="(serieCirconferences, numSaisieCirconferences) in lesCirconferences" :key="numSaisieCirconferences" >
                              <span v-if="numSaisieCirconferences">, </span>
                              {{ serieCirconferences }}
                        </span>
                        <!-- <img class="iconeFct" alt="Editer la série" src="@/assets/img/crayon.svg" /> -->
                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                    </div>
                    <div v-if="!lesCirconferences.length">
                        <v-btn class="a-btn" type="button" @click="circonferencesRenseignerSerie">Saisir la série de circonférences *</v-btn>
                    </div>
                </div>
                <calcul-circonference-serie v-if="circonferencesSerieEnCours"
                    @retourCirconferencesSerie="onRetourCirconferencesSerie"
                    :initDatas="mesureForm"
                    :surfaceBoisee="laSurfaceBoisee"
                    :ouvrirDialog=circonferencesSerieEnCours
                    ref="toto"
                />
            </div>


        </div>
    </div>
         <v-dialog v-model="dialogESP" v-if="enSavoirPlus" content-class="infoImageDialog">
             <v-card>
                 <v-card-title class="headline">Comment réaliser une mesure</v-card-title>
                 <v-card-text>
                     <div class="grandeImage">
                     <img  src="@/assets/img/mMesuresDensite.png" width="748px" />
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="lienAnnulerModeForet" color="" text @click="dialogESP=false;enSavoirPlus=false;">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

  </div>

</template>

<script>

import CalculDensite from '../components/ui/mesures/CalculDensite.vue'
import CalculCirconferenceSerie from '../components/ui/mesures/CalculCirconferenceSerie.vue'


import { ApiTools } from "../js/api/index.js"

export default {

    name: 'densite',
    components: {
        'calcul-densite': CalculDensite,
        'calcul-circonference-serie': CalculCirconferenceSerie
    },
    data() {
        return {
            surfacePeuplement:1,
            mesureForm: {
                "id":0,
                "userId":this.$store.getters.utilisateurCourantId,
                "peuplementId":this.$store.getters.peuplementId,
                "utilisateurId":this.$store.getters.utilisateurCourantId,
                "dateMesure":"",
                "densiteMoyenne":0.0,
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "hauteurMoyenne":0.0,
                "hauteurJson":"[]",
                "tabHauteurs":[],
                "circonferenceQuadratique":0.0,
                "circonferenceQuadratiqueJson":"[]",
                "tabCirconferences":[],
                "version":0,
				"idLocal":true,
            },
            calculsDensite : {
                methodeId : 0,
                methodesPossibles : { // refaire avec table calc_densitemethodes ?
                    choix : false,
                    interlignes : true,
                    surfaceTerriere : true,
                    ratios : false,
                    saisieDirecte : false,
                },
                interlignes : {
                    nombre : '',
                    largeur : [],
                    longueur : '',
                    tabNombresArbres:[]
                },
                saisieDirecte : {
                    densite : ''
                },
                surfaceTerriere : {
                    surfaceTerriere : ''
                },
            },
            circonferencesSerieEnCours:false,
            selectedMethode:-1,
            dialogESP:false,
            enSavoirPlus:false
        }
    },
    mounted: function () {
    },
    updated: function () {

    },
    computed: {
        laSurfaceBoisee() {
            var laSurfacePeuplement = parseFloat(this.surfacePeuplement);
            if (isNaN(laSurfacePeuplement) || !laSurfacePeuplement) {
                laSurfacePeuplement = 1;
            }
            return(laSurfacePeuplement);
        },
        surfaceTerriereChoisi() {
            return(this.selectedMethode == 2);
        },
        // pour le calcul de la densité
        infosCalculsDensite() {
            if (this.mesureForm.densiteMethodeId) {
                this.calculsDensite.methodeId = this.mesureForm.densiteMethodeId;
                var objJson = JSON.parse(this.mesureForm.densiteJson);
                if (this.mesureForm.densiteMethodeId == 1) {  // refaire avec table calc_densitemethodes ?
                    if (typeof(objJson.longueur) !== 'undefined') {
                        this.calculsDensite.interlignes = objJson;
                    }
                }
                if (this.mesureForm.densiteMethodeId == 2) {
                    if (typeof(objJson.surfaceTerriere) !== 'undefined') {
                        this.calculsDensite.surfaceTerriere = objJson;
                    }
                }
                if (this.mesureForm.densiteMethodeId == 4) {
                    if (typeof(objJson.densite) !== 'undefined') {
                        this.calculsDensite.saisieDirecte = objJson;
                    }
                }
            }
            return (JSON.parse(JSON.stringify(this.calculsDensite)));
            //return (this.calculsDensite)
        },
        circonferenceQuadratique() {
            if (!this.mesureForm.tabCirconferences.length) {
                this.mesureForm.circonferenceQuadratique = 0;
                return 0;
            }
            const reducer = (accumulator, currentValue) => accumulator + (currentValue*currentValue); // on fait la somme des carrés
            const totalCarres = this.mesureForm.tabCirconferences.reduce(reducer, 0);
            this.mesureForm.circonferenceQuadratique = Math.sqrt(totalCarres / this.mesureForm.tabCirconferences.length); // racine carré de la moyenne des carrés

            var circonferenceQuadratique = ApiTools.arrondir(this.mesureForm.circonferenceQuadratique, 2);
            return circonferenceQuadratique;
        },
        lesCirconferences() {
            return(this.mesureForm.tabCirconferences);
        },

    },
    methods: {
                // gestion du calcul de densite
        onRetourDensite(densiteObj) {
            /*
            this.mesureForm.densiteMoyenne = densiteObj.densite;
            this.mesureForm.densiteMethodeId = densiteObj.methodeId;

            this.mesureForm.densiteJson = "{}";
            if (densiteObj.methodeId == 1) {
                this.mesureForm.densiteJson = JSON.stringify(densiteObj.interlignes); // JSON.parse à l'aller
            }
            if (densiteObj.methodeId == 2) {
                this.mesureForm.densiteJson = JSON.stringify(densiteObj.surfaceTerriere); // JSON.parse à l'aller
            }
            if (densiteObj.methodeId == 4) {
                this.mesureForm.densiteJson = JSON.stringify(densiteObj.saisieDirecte); // JSON.parse à l'aller
            }
            */
        },
        onRetourSelectionMethode(idMethod) {
            this.selectedMethode = idMethod;
        },
        clickClear() {

        },
            // CIRCONFERENCES
            // edition d'une série
        circonferencesRenseignerSerie() {
            this.circonferencesSerieEnCours = true;
        },
        onRetourCirconferencesSerie(tabSerie) {
            var tab = [];
            if (tabSerie && (tabSerie.length > 0) && (tabSerie[0] != '')) {
                this.mesureForm.tabCirconferences = tab.concat(tabSerie);
            }
            else {
                var tabTMP = [];
                this.mesureForm.tabCirconferences = tab.concat(tabTMP);
            }

            this.mesureForm.circonferenceQuadratiqueJson = JSON.stringify(this.mesureForm.tabCirconferences); // JSON.parse à l'aller
            this.circonferencesSerieEnCours = false;
        },

        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },
    }
}


</script>

<style lang="scss">
    #app {
        #densite {
            min-width: 540px;

            div.sectionAffichage {
                max-width: 640px;
                margin-left:auto;
                margin-right:auto;
                @media screen and (max-width: 840px) {
                    margin-left:20px;
                    margin-right:20px;
                }

                .conteneur {
                    text-align: left;

                    h1, p, ul {
                        text-align: left;
                    }

                    a {
                        color:#238b71;
                        font-weight:bold;
                    }

                    .bloc {
                        margin-top:3em;
                    }
                    .souligne {
                        text-decoration: underline;
                    }

                    img {
                        max-height: 130px;
                        width:auto;
                        margin:1em auto 1em;


                        vertical-align: middle;
                    }
                    .infosSurface {
                        margin:2em 0;

                        .consigneSurface {
                            float: left;
                            max-width: 49%;
                            margin:0 1% 0 0;
                        }
                        .saisieSurface {
                            margin:0 0 0 1%;
                            max-width: 49%;
                            width: 350px;
                            float: right;
                        }
                    }

                    .methode {
                        margin : 1em 0;
                    }
                    .resultat {
                        font-weight: bold;
                    }
                    .v-input__slot {
                        margin-bottom: 0;
                        margin-top: 1em;
                    }
                    .leBouton {
                        font-size:0.8em;
                        height: 30px;
                        margin: 1em auto;
                    }
                    .leCalcul {
                        margin : 2em 0 0;
                        padding : 1em;
                        border: 1px solid rgb(35, 139, 113);
                        clear: both;
                        span.resultatOK {
                            border-bottom:3px solid #00ff27;
                        }
                    }
                    h2 {
                        margin:1.8em 0 0.6em;
                        color:#394F4E;
                        vertical-align: middle;
                        .titre {
                            padding:0.5em;
                            display: inline-block;
                        }
                    }
                    .numberCircle {
                        border-radius: 50%;
                        width: 2.2em;
                        height: 2.2em;
                        padding: 8px;
                        display:block;
                        float:left;
                        background: #fff;
                        border: 2px solid #394F4E;
                        /*color: #666;*/
                        text-align: center;
                        font-size: 1.2em;
                        vertical-align: top;
                    }
                }

            }
        }

                    .infoImageDialog {
                        max-width: 900px;
                        overflow:hidden;
                        div {
                            position:relative;
                            overflow:hidden;
                        }

                        .grandeImage {
                            overflow: scroll;
                            text-align: center;
                            height: auto;
                            max-height: 70vh;
                            max-width:770px;
                            margin: 0 auto;

                        img {
                            width:auto;
                            height:auto;
                            max-width: none;
                            }
                        }
                    }
    }

</style>
