import lf from 'lovefield'

export function const_tarifs (schemaBuilder) {
  schemaBuilder.createTable('const_tarifs')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('essenceId', lf.Type.INTEGER)
      .addColumn('anneeDebutValidite', lf.Type.INTEGER)
      .addColumn('volumeMoyen', lf.Type.DOUBLE)
      .addColumn('prixM3', lf.Type.DOUBLE)
      .addPrimaryKey(['id'], false);
}
