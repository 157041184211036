<template>
 <div>
    <form id="ProprieteEditionForm">
        <v-text-field
            label="Nom de la propriété *"
            v-model="form.identifiant"
            required
            :error-messages="error.identifiant"
            ref="identifiant"
        ></v-text-field>


        <h2 class="titreListe" >Administration</h2>

        <v-text-field
            label="Adresse"
            v-model="form.adresse"
            ref="adresse"
        ></v-text-field>

        <a-autocomplete-villes
               @selectVille="onSelectVille"
                :initItem="codePostal_ville"
                :clearable="true"
                @click:clear="clickClear"
                required
                :error-messages="error.ville"
        />

        <v-text-field
            label="Siret *"
            v-model="form.siret"
            required
            :error-messages="error.siret"
            ref="siret"
            type="number"
            :counter="14"
            @keydown="filtrerNombresEntiersPositifs"
        ></v-text-field>

        <div class="ensemble">
            <h3 class="titreListe" >Propriétaire *</h3>
            <v-checkbox
                v-model="gestionnaireEstProprietaireCheck"
                label="Je suis le propriétaire"
                @change="verifierGestionnaireEstProprietaireCheck()"
            ></v-checkbox>
            <div  v-if="!gestionnaireProprietaire && form.proprietaireId"  class="itemIconeFct" @click.stop="editerProprietaire()">
                <span class="">Propriétaire : {{form.infosProprietaire}} </span>
                <!-- <img class="iconeFct" alt="Editer les infos du propriétaire" src="@/assets/img/crayon.svg" /> -->
                <i class="fa fa-pencil svg" aria-hidden="true"></i>
            </div>
            <v-btn v-if="!gestionnaireProprietaire && !form.proprietaireId" class="a-btn boutonFonctionObjet prioritaire" type="button" @click="editerProprietaire()">Saisir le propriétaire</v-btn>
        </div>

        <div v-if="saisieProprietaireAyantDroitOK"  class="avertissement">
            Attention, n'oubliez pas d'enregistrer la propriété pour sauvegarder vos saisies !
        </div>

        <div class="ensemble">
            <h3 class="titreListe" >Ayants droit :</h3>
            <div class="aGauche" v-if="1">
                <span  class="itemListe" v-for="(ad, numAd) in tabAyantsDroit" :key="numAd">
                    <span  class="itemIconeFct"   @click.stop="editerAyantDroit(ad.id)">
                        <span class="">{{ad.prenom}} {{ad.nom}}</span>
                        <!-- <img class="iconeFct" alt="Editer les infos de l'ayant droit" src="@/assets/img/crayon.svg" /> -->
                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                    </span>
                    <i class="fas fa-times itemIconeFct" @click.stop="retirerAyantDroit(ad.id)"></i>
                </span>
                <a class="itemIconeFct clear ajout" @click="editerAyantDroit(0)">+ Ajouter un ayant droit</a>
            </div>
            <div class="clear"></div>
        </div>



        <h2 class="titreListe" >Gestion</h2>
        <p class="info">Taux d'actualisation appliqué aux recettes et aux dépenses, permettant d'évaluer la rentabilité dans le temps.</p>
        <v-text-field
            label="Taux d'actualisation en %"
            v-model="form.tauxActualisation"
            :error-messages="error.tauxActualisation"
            type="number"
            min=0.01
            max=15
            step=0.5
            @keydown="filtrerNombresPositifs"
        ></v-text-field>

        <h3 class="titreListe" >Dépenses annuelles à appliquer à toute la propriété</h3>
        <div class="listeDepenses">
            <div class="itemListeDepenses" v-for="(laDepense, numDepense) in lesDepenses"  >
                    <propriete-depense :data='laDepense' :dataCout=true @retourItemCheck="onRetourDepense"></propriete-depense>
            </div>
        </div>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->
        <div class="boutonsValidation">
            <v-btn class="a-btn secondaire" type="button" @click="cancelForm">Annuler</v-btn>
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Enregistrer</v-btn>
        </div>
    </form>

    <saisie-utilisateur v-if="saisieProprietaireEnCours"
        @retourSaisieUtilisateur="onRetourSaisieProprietaire"
        :initDatas="utilisateur"
        :ouvrirDialog=saisieProprietaireEnCours
    />
    <saisie-utilisateur v-if="saisieAyantDroitEnCours"
        @retourSaisieUtilisateur="onRetourSaisieAyantDroit"
        :initDatas="ayantDroit"
        :ouvrirDialog=saisieAyantDroitEnCours
    />

     <v-dialog v-model="dialogError" v-if="errorProprietaire" >
         <v-card>
             <v-card-title class="headline">Erreur de saisie</v-card-title>
             <v-card-text>
                 <span v-if="errorProprietaire">{{errorProprietaire}}</span>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength/*, email*/, minValue, maxValue } from 'vuelidate/lib/validators'

import { /*ApiConsts,*/ ApiUser, ApiTools } from "../../js/api/index.js"
import AddeoAutocompleteVilles from '../ui/autocomplete/AutocompleteVilles.vue'

import UtilisateurForm from '../ui/UtilisateurForm.vue'
import ItemCheck from '../ui/ItemCheck.vue'


export default {
    components: {
        'saisie-utilisateur': UtilisateurForm,
        'a-autocomplete-villes': AddeoAutocompleteVilles,
        'propriete-depense': ItemCheck
    },

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            dialog: false,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.data)),
            old: null,
            error: {
                identifiant: '',
                ville: '',
                siret: '',
                tauxActualisation: '',
            },
            codePostal_ville: null,
            clear:{
                    id: '',
                    label: ''
                },
            wait:{
                    id: '',
                    label: 'Please wait'
                },
            saisieProprietaireAyantDroitOK : false,
            saisieProprietaireEnCours : false,
            saisieAyantDroitEnCours : false,
            utilisateur: {
                id:0,
                valide:0,
                identifiant:"",
                prenom:"",
                nom:"",
                /*
                adresse:"",
                codeCommune:"",
                codePostal :"",
                commune :"",
                */
                adresseMail:"",
                version:0,
                //codePostal_ville: null,
                utilisateurDroitEcriture:false,
                proprietaire:true
            },
            gestionnaireEstProprietaireCheck:false,
            ayantDroit: null,
            tabIdAyantsDroit: null,
            tabAyantsDroit: [],
            tabDepenses:[],
            dialogError:false,
            errorProprietaire:false,
        }
    },

    created() {
        this.saisieProprietaireAyantDroitOK=false;
        this.initialiserAyantsDroit();
        this.initialiserProprietaire(this.form.proprietaire, this.form.proprietaireDroitEcriture);
        this.initialiserDepenses();

        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        this.gestionnaireEstProprietaireCheck = this.proprietaireGestionnaire
        this.initCodePostal_ville();
    },

    mounted() {
        this.$refs.identifiant.focus();
    },

    validations() {
        return {
            form: {
                identifiant: { required, maxLength: maxLength(32) },
                ville: {  maxLength: maxLength(56) },
                siret: { required, minLength:minLength(14), maxLength:maxLength(14) },
                tauxActualisation: { minValue:minValue(0.01), maxValue:maxValue(15) },
            }
        }
    },

    computed: {
        proprietaireGestionnaire() {
            return(this.form.gestionnaireId && (this.form.gestionnaireId == this.form.proprietaireId))
        },
        gestionnaireProprietaire() {
            return(this.gestionnaireEstProprietaireCheck)
        },
        lesDepenses() {
            return(this.tabDepenses.sort(this.triDepense));
        }
    },

    methods: {
        onRetourDepense(depense) {
            this.tabDepenses[depense.code].choisi = depense.choisi;
        },


        triDepense(x, y) {
            if (x.libelle < y.libelle) {
                return(-1)
            }
            if (x.libelle > y.libelle) {
                return(1)
            }
            return(0)
        },

        // PROPRIETAIRE
        verifierGestionnaireEstProprietaireCheck() {
            if (this.gestionnaireEstProprietaireCheck) {
                this.form.proprietaireId = this.form.gestionnaireId;
            }
            else {
                    // on remet dans l'état précédent
                this.initialiserProprietaire(this.utilisateur);
                // this.form.proprietaireId = 0;
            }
        },

        // saisie du propriétaire
        editerProprietaire() {
            this.saisieProprietaireEnCours = true;
        },
        onRetourSaisieProprietaire(datas) {
            this.saisieProprietaireEnCours = false;

            // et on traite en fonction du retour
            if (datas) {
                this.saisieProprietaireAyantDroitOK=true;
                this.initialiserProprietaire(datas);
            }
        },
        initialiserProprietaire(proprietaire, utilisateurDroitEcriture = -1) {
            if (proprietaire) {
                this.form.proprietaireId = proprietaire.id;
                if (utilisateurDroitEcriture >= 0) {
                    proprietaire.utilisateurDroitEcriture = utilisateurDroitEcriture;
                }
                this.form.proprietaireDroitEcriture = proprietaire.utilisateurDroitEcriture;
                this.form.infosProprietaire = proprietaire.prenom + " " + proprietaire.nom;

                this.initialiserUtilisateur(proprietaire)
                }
        },
        initialiserUtilisateur(utilisateur) {
            this.utilisateur.id = utilisateur.id;
            this.utilisateur.prenom = utilisateur.prenom;
            this.utilisateur.nom = utilisateur.nom;
            this.utilisateur.adresseMail = utilisateur.adresseMail;
            this.utilisateur.utilisateurDroitEcriture = utilisateur.utilisateurDroitEcriture;
        },

        // AYANTS DROIT
        initialiserAyantsDroit() {
            this.tabIdAyantsDroit = JSON.parse(this.form.ayantsDroitJson);
            this.tabAyantsDroit = [];
            var thisComponent =this;

            // on charge les ayants droits
            var allPromisesUtilisateurs = [];
            var utilisateurs = [];
            this.tabIdAyantsDroit.forEach(ayantDroit => {
                var i = utilisateurs.length;
                utilisateurs[i] = ApiUser.lireUtilisateur(ayantDroit);
                allPromisesUtilisateurs.push(utilisateurs[i])
                })
            return Promise.all(allPromisesUtilisateurs).then(responseUtilisateurs => {
                responseUtilisateurs.forEach(responseUtilisateur => {
                    if (typeof(responseUtilisateur.utilisateur) !== 'undefined') {
                        thisComponent.tabAyantsDroit.push(responseUtilisateur.utilisateur);
                    }
                })
            })
        },
        retirerAyantDroit(id) {
            this.tabAyantsDroit.splice(this.tabAyantsDroit.findIndex(item => item.id == id), 1);
            this.tabIdAyantsDroit.splice(this.tabIdAyantsDroit.findIndex(item => item == id), 1);
        },
        editerAyantDroit(id = 0) {
            if (id) { // ici, en fonction de l'ID, on lit les infos necessaire à l'édition de la personne
                this.ayantDroit = this.tabAyantsDroit.find(item => item.id == id);
            }
            else {
                this.ayantDroit = this.nouvelAyantDroit();
            }
            this.saisieAyantDroitEnCours = true;
        },
        onRetourSaisieAyantDroit(datas) {

            if (datas) {
                this.saisieProprietaireAyantDroitOK=true;
                var index = this.tabAyantsDroit.findIndex(item => item.id == datas.id);

                if (index >= 0) {
                    this.tabAyantsDroit[index] = datas;
                }
                else {
                    this.tabIdAyantsDroit.push(datas.id);
                    this.tabAyantsDroit.push(datas);
                }
            }

            this.saisieAyantDroitEnCours = false;
        },

        nouvelAyantDroit() {
            return({
                id:0,
                valide:0,
                identifiant:"",
                prenom:"",
                nom:"",
                adresseMail:"",
                version:0,
                utilisateurDroitEcriture:false,
                proprietaire:false
            })
        },


        // DEPENSES
        initialiserDepenses() {
            this.tabDepenses = JSON.parse(this.form.depensesJson);
            var thisObj = this;
            this.tabDepenses.forEach(depense => {
                thisObj.tabDepenses[depense.code] = depense;
            })
        },

        initCodePostal_ville() {
            var codePostal = "";
            var libelle = "";
            if (!ApiTools.isStringEmpty(this.form.codePostal)) {
                codePostal = this.form.codePostal;
                libelle = this.form.libelleCommune;
                this.codePostal_ville = JSON.stringify({
                    id: '',
                    label: codePostal + ' ' + libelle,
                    codePostal:codePostal,
                    libelle:libelle,

                })
            }
            else {
                this.codePostal_ville = null;
            }

            if (ApiTools.isStringEmpty(codePostal) || ApiTools.isStringEmpty(libelle)) {
                return(0);
            }
            return(1);
        },

        // gestion de l'autocomplete
        onSelectVille(villeObj) {
            this.codePostal_ville = JSON.stringify(villeObj.codePostal_ville)
            this.form.codeCommune = villeObj.codeComm
            this.form.codePostal = villeObj.codePostal
            this.form.libelleCommune = villeObj.libelle
        },
        clickClear() {
            this.codePostal_ville = ''
            this.form.codeCommune = ''
            this.form.codePostal = ''
            this.form.libelleCommune = ''
        },
        majCodePostal_ville(objGlob, codePostal_ville) {
            objGlob.codePostal_ville = codePostal_ville;
        },

        // annulation du formulaire
        cancelForm() {
            // on remet les données anciennes dans 'form'
            this.form = JSON.parse(JSON.stringify(this.old))

            this.initCodePostal_ville();

            // on prépare le message passé à la prochaine page
            var message = "La saisie de la propriété "+this.form.identifiant+ " a été abandonnée.";
            // on va au bon endroit avec les bonnes valeurs
            this.terminerEdition(message);
        },

        // enregistrement du formulaire
        async saveForm() {

            // on reinit à vide les messages d'erreur
            this.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (this.$v.$invalid) {
                // maj erreurs
                this.identifiantErrors();
                this.villeErrors();
                this.siretErrors();
                this.tauxActualisationErrors();
                return false
            }


            // si pas de proprio
            if (!this.form.proprietaireId) {
                this.dialogError = true;
                this.errorProprietaire = "Attention ! Vous devez indiquer qui est le propriétaire.";
                return;
            }

            // on maj les ayants droit
            this.form.ayantsDroitJson = JSON.stringify(this.tabIdAyantsDroit); // JSON.parse à l'aller
            this.form.depensesJson = JSON.stringify(this.tabDepenses); // JSON.parse à l'aller
            if (this.form.tauxActualisation == "") {
                this.form.tauxActualisation = parseFloat(this.$store.getters.constantes.steres.tauxActualisation);
            }
            else {
                this.form.tauxActualisation = parseFloat(this.form.tauxActualisation);
            }


            // si pas de modification des données
            if (JSON.stringify(this.old) === JSON.stringify(this.form)) {
                this.terminerEdition();
                return false
            }

            // on met à jour avec les valeurs du STORE (globales)
            this.form.userId = this.$store.getters.utilisateurCourantId

            // on somme la surface
            this.form.surface = this.surfaceTotale;

            // on sauvegarde (distant et local)
            this.old = JSON.parse(JSON.stringify(this.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
            var entityToSave = JSON.parse(JSON.stringify(this.form))
            console.log("saveForm OK")
            //const response = await ApiUser.setPropriete(entityToSave)
            return await ApiUser.setPropriete(entityToSave) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
             .then(response => {
                this.$store.commit('setProprieteId', response); // tmp

                // on prépare le message passé à la prochaine page
                var message = "";
                message = "La saisie de la propriété "+this.form.identifiant+ " a bien été enregistrée.";
                /*
                if (navigator.onLine) {
                    message = "La saisie de la propriété "+this.form.identifiant+ " a bien été enregistrée.";
                }
                else {
                    message = "La saisie de la propriété "+this.form.identifiant+ " a bien été enregistrée. Attention, vous travaillez hors ligne, il vous faudra donc synchroniser cette saisie ultérieurement avce la base de données Stères.";
                }
                */
                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message);
            })
        },


        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message) {
            if (message) {
                this.$store.commit('donnerMessage', message);
            }
            //this.$store.commit('setProprieteId', 0); // tmp
            //this.$store.commit('resetDataApplication');
            if (parseInt(this.$store.getters.proprieteId) > 0) {
                this.$router.push({ name: "propriete" });
            }
            else {
                this.$router.push({ name: "proprietes" });
            }
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.identifiant = ''
            this.error.ville = ''
            this.error.siret = ''
            this.error.tauxActualisation = ''
            this.errorProprietaire = ''
        },
        identifiantErrors () {
            if (!this.$v.form.identifiant.$invalid) return false // verifier invalid
            if (!this.$v.form.identifiant.maxLength) {
                this.error.identifiant  = "La taille maximale de l'identifiant est de 32 caractères"
            }
            if (!this.$v.form.identifiant.required) {
                 this.error.identifiant  = "La saisie d'un identifiant est obligatoire"
            }
            return true
        },
        villeErrors () {
            /*
            if (!this.$v.form.commentaire.$invalid) return false // verifier invalid
            if (!this.$v.form.commentaire.maxLength) {
                this.error.commentaire  = "La taille maximale du commentaire est de 250 caractères"
            }
            */
            return true
        },
        siretErrors () {
            if (!this.$v.form.siret.$invalid) return false // verifier invalid
            if (!this.$v.form.siret.required) {
                 this.error.siret  = "La saisie d'un siret est obligatoire"
            }
            if  ((!this.$v.form.siret.minLength) || (!this.$v.form.siret.maxLength)) {
                 this.error.siret  = "La saisie d'un siret (14 chiffres) est obligatoire"
            }
            return true
        },
        tauxActualisationErrors () {
            if (!this.$v.form.tauxActualisation.$invalid) return false // verifier invalid
            if ((!this.$v.form.tauxActualisation.required) || (!this.$v.form.tauxActualisation.minValue) || (!this.$v.form.tauxActualisation.maxValue)) {
                 this.error.tauxActualisation  = "La saisie d'un taux d'actualisation n'est pas obligatoire mais vous ne pouvez saisir que des valeurs comprises entre 0.01 et 15 %."
            }
            return true
        },

        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },

        fermerDialogError() {
            this.dialogError = false;
        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {
    form#ProprieteEditionForm    {
        div.v-input--checkbox {
            background-color:transparent;
            padding-top: 0;

            .v-icon {
                color:#394F4E;
                }
            label {
                color:#394F4E;
                font-weight: 600;
             }
        }
        .itemIconeFct {
            margin-left: 0.6em;

            span {
                margin-right:1em;
            }
        }
        div.avertissement {
            color:#E84B00;
            margin-bottom: 2em;
        }

        div.ensemble {
            padding-left: 1em;
            border-left: 3px solid #238b71;
            margin-bottom:2em;

            .itemListe {
                min-width:200px;
                display: inline-block;
                /*margin-left:0.4em;*/

                .fa-times {
                    color:#444444;
                    font-size:1.4em;
                    vertical-align: top;
                }
            }

            .aGauche, .aDroite {
                max-width:200px;
                float: left;
            }

            .ajout {
                margin-left:0.4em;
                /*margin-top:1em;*/
            }
        }


        div.boutonsValidation {
            margin-top:4em;
        }

        .listeDepenses {
            padding :1em 0 0 1em;
        }
    }
}

</style>
