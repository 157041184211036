<template>
<div>

    <span id="refermer" @click.stop="$parent.refermerSynthese()">
        <i class="fas fa-window-close-o"></i>
        <div class="clear"></div>
    </span>
 <div id="synthese1">
    <div>
        <div v-if="!$store.getters.modeForet" class="aDroite infoImpression"  @click.stop="$parent.imprimerSynthese('syntheseA')">Vous pouvez imprimer cette synthèse :
            <i class="fas fa-print"></i>
        </div>
        <div id="aImprimer" class="conteneur" v-if="ready">
            <div class="gauche image"><img src="../../assets/img/logoCPFAv.png" /></div>
            <div id="infosGeneriques" class="droite"></div>

            <h2 v-if="!$store.getters.modeForet" @click.stop="exporterTableau(laTabValeursExportees)" class="lien"><i class="fas fa-download"></i>Programme complet des coupes</h2>
            <h2 v-if="$store.getters.modeForet">Programme complet des coupes</h2>

                <table class="infos tabulation"  :id="numAnnee" v-for="(lAnnee, numAnnee) in leTabCoupesAnneePropriete" :key="numAnnee" v-if="lAnnee[0].valeursStockees.recettes">
                    <tr v-if="lAnnee[0].valeursStockees.recettes" class="ligneAnnee">
                        <th colspan="9" class="aGauche">{{lAnnee.annee}}</th>
                    </tr>
                    <tr class="sousTitres">
                        <th class="grand">UF</th>
                        <th class="petit">Age</th>
                        <th>Surface<br/>boisée</th>
                        <th>Opération</th>
                        <th>Prélèvement</th>
                        <th>V.U.</th>
                        <th>Volume/ha</th>
                        <th>Volume total</th>
                        <th>Recettes</th>
                    </tr>
                    <tr class="donnees" v-if="numInfo"  v-for="(lInfo, numInfo) in lAnnee" :id="numInfo" :key="numInfo">
                        <td v-if="lInfo.type === 'EC'">{{lInfo.identifiantUF}}</td>
                        <td class="petit aDroite" v-if="lInfo.type === 'EC'">{{ lInfo.age }} ans</td>
                        <td class="aDroite" v-if="lInfo.type === 'EC'">{{arrondiHectares(lInfo.surfaceBoisee)}} ha</td>
                        <td class="aDroite" v-if="lInfo.type === 'EC' && !lInfo.depressage">Eclaircie</td>
                        <td class="aDroite" v-if="lInfo.type === 'EC' && lInfo.depressage">Dépressage</td>
                        <td class="auCentre messageError" colspan="5" v-if="lInfo.type === 'EC' && lInfo.depressage">&nbsp</td>
                        <td class="auCentre messageError" colspan="5" v-if="lInfo.type === 'EC' && !lInfo.depressage && !lInfo.valeursStockees.densiteAvantEclaircie">
                            Informations non disponibles (données de peuplement partielles)
                        </td>
                        <td class="aDroite"  v-if="lInfo.type === 'EC' && lInfo.valeursStockees.densiteAvantEclaircie">
                            {{formaterValeur(lInfo.valeursStockees.densiteEclaircie / lInfo.valeursStockees.densiteAvantEclaircie*100, 1)}}%
                        </td>
                        <td class="aDroite"  v-if="lInfo.type === 'EC' && lInfo.valeursStockees.densiteAvantEclaircie">{{formaterVolumeTab(lInfo.valeursStockees.volumeUnitaireCoupe)}} m3</td>
                        <td class="aDroite"  v-if="lInfo.type === 'EC' && lInfo.valeursStockees.densiteAvantEclaircie">{{formaterVolumeTab(lInfo.valeursStockees.volumeCoupe/lInfo.surfaceBoisee)}} m3</td>
                        <td class="aDroite"  v-if="lInfo.type === 'EC' && lInfo.valeursStockees.densiteAvantEclaircie">{{formaterVolumeTab(lInfo.valeursStockees.volumeCoupe)}} m3</td>
                        <td class="aDroite"  v-if="lInfo.type === 'EC' && lInfo.valeursStockees.densiteAvantEclaircie">{{formaterEuros(lInfo.valeursStockees.recettes)}}</td>
                    </tr>
                    <tr class="total sousTotal" v-if="lAnnee[0].valeursStockees.recettesEclaircies">
                        <td colspan="2" class="aDroite">Total (éclaircies)</td>
                        <td class="aDroite">{{arrondiHectares(lAnnee[0].valeursStockees.surfacesEclaircies)}} ha</td>
                        <td colspan="4"></td>
                        <td class="aDroite">{{formaterVolumeTab(lAnnee[0].valeursStockees.volumeTotalEclaircies)}} m3</td>
                        <td class="aDroite">{{formaterEuros(lAnnee[0].valeursStockees.recettesEclaircies)}}</td>
                    </tr>
                    <tr class="donnees" v-if="numInfo"  v-for="(lInfo, numInfo) in lAnnee" >
                        <td v-if="lInfo.type === 'CR'">{{lInfo.identifiantUF}}</td>
                        <td class="petit aDroite" v-if="lInfo.type === 'CR'">{{ lInfo.age }} ans</td>
                        <td class="aDroite" v-if="lInfo.type === 'CR'">{{arrondiHectares(lInfo.surfaceBoisee)}} ha</td>
                        <td class="aDroite" v-if="lInfo.type === 'CR'">Coupe Rase</td>
                        <td class="aDroite"  v-if="lInfo.type === 'CR'">100%</td>
                        <td class="aDroite"  v-if="lInfo.type === 'CR'">{{formaterVolumeTab(lInfo.valeursStockees.volumeUnitaireCoupe)}} m3</td>
                        <td class="aDroite"  v-if="lInfo.type === 'CR'">{{formaterVolumeTab(lInfo.valeursStockees.volumeCoupe/lInfo.surfaceBoisee)}} m3</td>
                        <td class="aDroite"  v-if="lInfo.type === 'CR'">{{formaterVolumeTab(lInfo.valeursStockees.volumeCoupe)}} m3</td>
                        <td class="aDroite"  v-if="lInfo.type === 'CR'">{{formaterEuros(lInfo.valeursStockees.recettes)}}</td>
                    </tr>
                    <tr class="total sousTotal" v-if="lAnnee[0].valeursStockees.recettesCoupesRases">
                        <td colspan="2" class="aDroite">Total (coupes rases)</td>
                        <td class="aDroite">{{arrondiHectares(lAnnee[0].valeursStockees.surfacesCoupesRases)}} ha</td>
                        <td colspan="4"></td>
                        <td class="aDroite">{{formaterVolumeTab(lAnnee[0].valeursStockees.volumeTotalCoupesRases)}} m3</td>
                        <td class="aDroite">{{formaterEuros(lAnnee[0].valeursStockees.recettesCoupesRases)}}</td>
                    </tr>
                    <tr class="total" >
                        <td class="aDroite">Total recettes : </td>
                        <td class="petit"></td>
                        <td class="aDroite">{{arrondiHectares(lAnnee[0].valeursStockees.surfacesCoupesRases+lAnnee[0].valeursStockees.surfacesEclaircies)}} ha</td>
                        <td colspan="4"></td>
                        <td class="aDroite">{{formaterVolumeTab(lAnnee[0].valeursStockees.volumeTotalEclaircies+lAnnee[0].valeursStockees.volumeTotalCoupesRases)}} m3</td>
                        <td class="aDroite">{{formaterEuros(lAnnee[0].valeursStockees.recettes)}}</td>
                    </tr>
                    <tr class="total" >
                        <td class="aDroite">Total dépenses : </td>
                        <td class="petit"></td>
                        <td colspan="6"></td>
                        <td class="aDroite">{{formaterEuros(lAnnee[0].valeursStockees.depenses)}}</td>
                    </tr>
                    <tr class="total" >
                        <td class="aDroite">Solde : </td>
                        <td class="petit"></td>
                        <td colspan="6"></td>
                        <td class="aDroite">{{formaterEuros(lAnnee[0].valeursStockees.recettes-lAnnee[0].valeursStockees.depenses)}}</td>
                    </tr>
                </table>

        </div>
    </div>
</div>
</div>
</template>


<script>

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
import { addeoData } from "../../js/api/datas.js"


//import PeuplementDendrometrieVisualisation from './PeuplementDendrometrieVisualisation.vue'


export default {
    components: {
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            // init les données du form à partir des props
            propriete: JSON.parse(JSON.stringify(this.data)),
            tabCoupesAnneePropriete:[],
            ready:false,
        }
    },

    created() {
        this.initialiser();
    },

    computed: {
        leTabCoupesAnneePropriete() {
            var tabVide=[];
            if (this.tabCoupesAnneePropriete.length==0 || typeof(this.tabCoupesAnneePropriete[0].annee) === 'undefined') {
                return(tabVide);
            }
            var annee = this.tabCoupesAnneePropriete[0].annee;
            return(this.tabCoupesAnneePropriete.filter(
                coupeAnnee => (coupeAnnee.annee-annee) < 21
                ));
        },

        laTabValeursExportees() {

            var copieTab = this.leTabCoupesAnneePropriete.slice();
            // var anneCoupeRase = this.laCoupeRase;
            // var anneeDateInstallation = this.ppl.anneeDateInstallation
            var tab = [];
            copieTab.forEach((lAnnee, numAnnee) => {
                lAnnee.forEach((lInfo, numInfo) => {
                    if (lInfo.type === '_FI') {
                        return;
                    }

                    var obj = {};
                    // annee	depensesTotales	recettesTotales	codeUF	libelleUF	age	surface	operation	recetteOperation

                    obj.annee = this.exporterValeur(lAnnee.annee);
                    obj.depensesTotales = this.exporterValeur(lAnnee[0].valeursStockees.depenses);
                    obj.recettesTotales = this.exporterValeur(lAnnee[0].valeursStockees.recettes);

                    obj.codeUF = this.exporterValeur(lInfo.codeUF);
                    obj.libelleUF = this.exporterValeur(lInfo.identifiantUF);
                    obj.age = this.exporterValeur(lInfo.age);
                    obj.surfaceBoisee = this.exporterValeur(lInfo.surfaceBoisee);
                    if (lInfo.type === 'CR') {
                        obj.operation = this.exporterValeur("Coupe rase");
                        obj.prelevement = this.exporterValeur(1);
                    }
                    if (lInfo.type === 'EC') {
                        obj.operation = this.exporterValeur("Eclaircie");
                        if ( (typeof(lInfo.depressage) !== 'undefined') && lInfo.depressage) {
                            obj.operation = this.exporterValeur("Dépressage");
                        }
                        if (lInfo.valeursStockees.densiteAvantEclaircie) {
                            obj.prelevement = this.exporterValeur(lInfo.valeursStockees.densiteEclaircie/lInfo.valeursStockees.densiteAvantEclaircie);
                        }
                        else {
                            obj.prelevement = this.exporterValeur(0)
                        }
                    }
                    obj.volumeUnitaireCoupe = this.exporterValeur(lInfo.valeursStockees.volumeUnitaireCoupe);
                    obj.volumeCoupeHa = this.exporterValeur(lInfo.valeursStockees.volumeCoupe/lInfo.surfaceBoisee);
                    obj.volumeTotalCoupe = this.exporterValeur(lInfo.valeursStockees.volumeCoupe);

                    obj.recetteOperation = this.exporterValeur(lInfo.valeursStockees.recettes);

                    tab.push(obj);
                })
            })
            return(tab);
        }

    },

    methods: {
        exporterValeur(valeur) {
            var valeurRetournee = ""+valeur;
            if ( (typeof(valeur) === 'undefined') ) {
                valeurRetournee = "0";
            }
            return(valeurRetournee.replace('.', ','));
        },

        exporterTableau(tableau) {
            var nomCSV = this.propriete.identifiant + "_synthese_programme_des_coupes";
            return addeoData.exporterTableau(tableau, nomCSV);
        },

        initialiser() {
            var tabCoupesPropriete = [];
            var valeursStockees = [];
            // on lit toutes les UF de la propriété pour trouver les coupes / eclaircies qu'on va intégrer année par année
            this.propriete.UFs.forEach(uf => {
                if ((typeof(uf.peuplement) !== 'undefined') && uf.peuplement && uf.peuplement.ITs) {
                    var coupeRase = uf.coupeRase;
                    var eclaircies = uf.eclaircies;
                    var valeursStockees = uf.valeursStockees;
                    if (coupeRase) {
                        var objetCoupe = {};
                        objetCoupe.type = "CR";
                        objetCoupe.annee = coupeRase + uf.peuplement.anneeRetenue;
                        objetCoupe.age = coupeRase;
                        objetCoupe.identifiantUF = uf.identifiant;
                        objetCoupe.codeUF = uf.code;
                        objetCoupe.surfaceBoisee = uf.peuplement.surfaceBoisee;
                        objetCoupe.valeursStockees = uf.valeursStockees[coupeRase];
                        tabCoupesPropriete.push(objetCoupe);
                    }
                    if (typeof(eclaircies) !== 'undefined') {
                        eclaircies.forEach(eclaircie => {
                            if (eclaircie) {
                                if (uf.dendrometrieAnnee.anneeCourante <= eclaircie.age) {
                                //if ((typeof(uf.peuplement) !== 'undefined')
                                    var objetEclaircie = {};
                                    objetEclaircie.type = "EC";
                                    objetEclaircie.depressage = eclaircie.depressage; // potentiellement undefined
                                    objetEclaircie.annee = uf.peuplement.anneeRetenue + eclaircie.age;
                                    objetEclaircie.age = eclaircie.age;
                                    objetEclaircie.identifiantUF = uf.identifiant;
                                    objetEclaircie.codeUF = uf.code;
                                    objetEclaircie.surfaceBoisee = uf.peuplement.surfaceBoisee;
                                    objetEclaircie.valeursStockees = uf.valeursStockees[eclaircie.age];
                                    tabCoupesPropriete.push(objetEclaircie);
                                }
                            }
                        })
                    }
                }
                else {
                    // this.calculerDepenseUF(propriete, uf, constantes);
                }

            })
            tabCoupesPropriete.sort(this.triCoupe);
            var tabCoupesAnneePropriete = this.traiterAnneesPropriete(tabCoupesPropriete);
            this.tabCoupesAnneePropriete = tabCoupesAnneePropriete;
            this.ready = true;
        },

        triCoupe(x, y) {
           if (x.annee != y.annee) {
                return(x.annee - y.annee);
            }
           if (x.identifiantUF < y.identifiantUF) {
               return(-1)
           }
           if (x.identifiantUF > y.identifiantUF) {
               return(1)
           }
           if (x.type < y.type) {
               return(-1)
           }
           if (x.type > y.type) {
               return(1)
           }
           return(0)
        },

        traiterAnneesPropriete(tabCoupesProprieteSansFiltre) {

            // on ne veut que les 20 années à partir de l'année courante
            var laDate=new Date();
            const anneeCourante = laDate.getFullYear();
            var tabCoupesPropriete = tabCoupesProprieteSansFiltre.filter(
                coupeAnnee => (((coupeAnnee.annee-anneeCourante) < 21) && (coupeAnnee.annee >= anneeCourante))
                );

            var anneePrecedente = 0;
            var surfaceBoisee = 0;
            var tabAnnees = [];
            var cptAnnees = -1;
            var tabCoupesAnnee = [];

            var valeursStockees = this.propriete.valeursStockees;
            var valeursStockeesFI = null;
            var leThis = this;

            // on lit toutes les coupes - triées par années -
            tabCoupesPropriete.forEach(coupe => {
                if (coupe.annee != anneePrecedente) {
                    cptAnnees++;
                    if (anneePrecedente) {
                        cptAnnees = leThis.comblerTabAnnees(tabAnnees, cptAnnees, valeursStockees, anneePrecedente, coupe.annee);
                    }
                    else {
                        leThis.initialiserTabAnneesAnnee(tabAnnees, cptAnnees, valeursStockees, coupe.annee);
                    }
                    valeursStockeesFI = tabAnnees[cptAnnees][0].valeursStockees;

                    var lAnnee = "annee"+coupe.annee;
                    anneePrecedente = coupe.annee;
//                    tabAnnees[cptAnnees].push(coupe);
                }
                /*
                else {
                    tabAnnees[cptAnnees].push(coupe);
                }
                */
                tabAnnees[cptAnnees].push(coupe);

                if (coupe.type == "CR") {
                    valeursStockeesFI.recettesCoupesRases +=  coupe.valeursStockees.recettes;
                    valeursStockeesFI.surfacesCoupesRases +=  coupe.surfaceBoisee;
                    valeursStockeesFI.volumeTotalCoupesRases += coupe.valeursStockees.volumeCoupe;
                }
                if (coupe.type == "EC") {
                    valeursStockeesFI.recettesEclaircies +=  coupe.valeursStockees.recettes;
                    valeursStockeesFI.surfacesEclaircies +=  coupe.surfaceBoisee;
                    valeursStockeesFI.volumeTotalEclaircies += coupe.valeursStockees.volumeCoupe;
                }
            })
            return(tabAnnees);
        },

        initialiserTabAnneesAnnee(tabAnnees, cptAnnees, valeursStockees, annee) {
            var lAnnee = "annee" + annee;
            var objAnnee = {};
            objAnnee.type = "_FI";
            objAnnee.annee = annee;
            objAnnee.age = 0;
            objAnnee.identifiantUF = "";
            objAnnee.surfaceBoisee = "";
            objAnnee.valeursStockees = valeursStockees[lAnnee];
            objAnnee.valeursStockees.recettesEclaircies = 0;
            objAnnee.valeursStockees.recettesCoupesRases = 0;
            objAnnee.valeursStockees.surfacesEclaircies = 0;
            objAnnee.valeursStockees.surfacesCoupesRases = 0;
            objAnnee.valeursStockees.volumeTotalEclaircies = 0;
            objAnnee.valeursStockees.volumeTotalCoupesRases = 0;
            tabAnnees[cptAnnees] = [];
            tabAnnees[cptAnnees].push(objAnnee);
            tabAnnees[cptAnnees].annee = annee;
        },

        comblerTabAnnees(tabAnnees, cptAnnees, valeursStockees, anneePrecedente, anneeSuivante) {
            //for(var cptAges = anneePrecedente-1; cptAges >= anneeSuivante; cptAges--) {
            for(var cptAges = anneePrecedente+1; cptAges <= anneeSuivante; cptAges++) {
                this.initialiserTabAnneesAnnee(tabAnnees, cptAnnees, valeursStockees, cptAges);
                cptAnnees++;
                }
            return(cptAnnees-1);
        },

        formaterEuros(valeur) {
            return(ApiTools.formaterEuros0(valeur));
        },
        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterVolumeTab(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterValeur(valeur, arrondi) {
            return(ApiTools.arrondir(valeur, arrondi));
        }

    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {

    #refermer {
        cursor:pointer;
        position:absolute;
        top:0.2em;
        /*color: #238B71;*/
        vertical-align: top;
        left: 50%;
        display: block;
        z-index: 2;
        i {
            font-size:2.2em;
            margin-left:0.4em;
            color: #565656;
        }
        span {
            vertical-align: top;
            display: inline-block;
            padding: 0.8em 0 0;
        }
    }

    #synthese1 {
        padding:5mm;
        border: 1px solid #cccccc;
        overflow-x:auto;

        #infosGeneriques {
            display:none;
        }

        .infoImpression {
            color:#238b71 !important;
            cursor:pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .fa-print {
            font-size:2em;
            cursor:pointer;
        }
        .fa-download {
            margin-right : 1em;
        }
        .conteneur {
            max-width: 200mm;
            margin:0;
        }

        table.tabulation {
            width: 100%;
            min-width: 600px;
            max-width: 900px;

            tr {
                th, td {
                    max-width: 100px;
                    min-width: 100px;
                    width: 9%;
                    &.grand {
                        max-width: 140px;
                        min-width: 140px;
                    }
                    &.petit {
                        max-width: 60px;
                        min-width: 60px;
                    }
                    &.aGauche {
                        text-align: left;
                    }
                    &.auMilieu {
                        text-align: center;
                    }
                }
                th {
                    padding: 0.3em 0;
                    text-align: center;
                    line-height: 1.2em;
                }
                td {
                    padding: 0 1% 0 0;
                }
                &.ligneAnnee {
                    th {
                        font-weight: bold;
                        background-color: #DFEAE1;
                        text-align: center;
                    }
                }
                &.total {
                    td {
                        color: black;
                    }
                }
                &.sousTotal {
                    td {
                        padding-bottom: 0.6em;
                    }
                }
                &.sousTitres {
                    th {
                        /*font-weight: bold;*/
                        color: #aaa;
                    }
                }
            }

        }

        table {
                width: 100%;
                min-width: 600px;
                max-width: 200mm;
                background-color: transparent;
                margin-bottom: 1em;
                color: #223938;
                padding: 0.2em 1%;
                border-spacing : 0;
                margin:3mm 0;
                table-layout:auto;

                tr {
                    vertical-align: top;

                    th, tr {
                        padding:0;
                    }

                    &:first-child, &:nth-child(2) {
                        th {
                            span {
                                 display: inline;
                            }
                        }
                    }

                    th {
                        text-align: left;
                        /*
                        padding:1em 0;
                        */
                        width: 15%;
                        min-width: 150px;
                        max-width: 60mm;
                        white-space: nowrap;
                        font-weight: normal;

                        span {
                            display: none;

                            &.annee {
                                display: inline;
                                font-weight: bold;
                            }
                        }

                    }

                    td {
                        /*
                        padding:1em 1em;
                        */
                        width: 20%;
                        min-width: 200px;
                        max-width: 40mm;

                        span.utile.inutile {
                            visibility:hidden;
                        }

                        &.petit {
                            width: 12%;
                            min-width: 120px;
                            max-width: 30mm;
                            span {
                                display: inline-block;
                                width: 100%;
                            }
                        }
                        &.comble {
                                width: auto;
                                min-width:0;
                            }

                    }
                    img.iconeFct {
                        text-align: left;
                        margin-left:1em;
                        float: none;
                        width : auto;
                    }
                }
            }
        .image {
            display:none;
        }
        .auCentre {
            text-align: center;
        }
        .aDroite {
            text-align: right;
        }
        .aGauche {
            text-align: left;
        }
        .droite {
            float: right;
        }
    }
}
    body#app {
        #syntheseA {
            color:#000000;
            font-family: Helvetica, Arial, sans-serif !important;

            h2 {
                font-family: Helvetica, Arial, sans-serif !important;
            }
            /*
            background: url("../../assets/img/cpfa.png") transparent no-repeat top left;
            background-size: 15mm 20mm;
            */
            .infosGeneriques {
                margin-right: 2em;
            }
            .image {
                text-align:right;
                /*display:inline;*/
                img {
                    width: 10mm;
                    height:auto;
                }
            }
            h2 {
                font-size:4mm;
                text-align: center;
                margin-top: 0;

                i.fa-download {
                   display:none;
                }
            }
            .aDroite {
                text-align: right;
                vertical-align: top;
            }
            .majuscules {
                text-transform: uppercase;
            }
            table {
                width: 200mm;
                font-size:3.2mm;
                margin-left:10mm;

                tr {
                    color:#444444;
                    &.ligneAnnee {
                        color:#000000;
                    }
                    th {
                        width: 15mm;
                        text-align: left;
                    }
                    td {
                        width: 30mm;
                        text-align: left;
                    }
                    td.petit {
                        min-width: 25mm;
                    }
                }
            }
        }
    }

</style>
