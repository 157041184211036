<template>
<div>

    <span id="refermer" @click.stop="$parent.refermerSynthese()">
        <i class="fas fa-window-close-o"></i>
        <div class="clear"></div>
    </span>
 <div id="synthese4">
    <div>
        <div v-if="!$store.getters.modeForet" class="aDroite infoImpression"  @click.stop="$parent.imprimerSynthese('syntheseD')">Vous pouvez imprimer cette synthèse :
            <i class="fas fa-print"></i>
        </div>
        <div id="aImprimer" class="conteneur" v-if="ready">
            <div class="gauche image"><img src="../../assets/img/logoCPFAv.png" /></div>
            <div id="infosGeneriques" class="droite"></div>

            <h2 v-if="!$store.getters.modeForet" @click.stop="exporterTableau(laTabValeursExportees)" class="lien"><i class="fas fa-download"></i>Etat de la propriété</h2>
            <h2 v-if="$store.getters.modeForet">Etat de la propriété</h2>


            <div v-if="" class="containerCourbes" v-html="contentSVGD"></div>
            <!--<div v-if="itEnCours" class="containerCourbes" v-html="contentSVG1"></div>            -->

                <table class="infos">
                    <tr class="entete">
                        <th class="">
                            Age
                        </th>
                        <th class="">
                            Surface
                        </th>
                        <th class="">
                            % de la propriété
                        </th>
                    </tr>
                    <tr class="aDroite"  :id="numClasse" v-for="(laClasse, numClasse) in tabAgesPropriete" :key="numClasse" v-bind:class="{impair : numClasse%2}">
                        <td>{{laClasse.libelle}}</td>
                        <td><span v-if="laClasse.surface">{{arrondiHectares(laClasse.surface)}} ha</span></td>
                        <td><span v-if="laClasse.surface && propriete.surface">{{formaterValeur(laClasse.surface/propriete.surface*100, 2)}} %</span></td>
                    </tr>
                    <tr>
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr class="aDroite">
                        <td>Total *</td>
                        <td>{{arrondiHectares(surfaceClasses)}} ha</td>
                        <td><span v-if="propriete.surface">{{formaterValeur(surfaceClasses/propriete.surface*100, 2)}} %</span></td>
                    </tr>
                    <tr class="aDroite">
                        <td colspan="3">&nbsp;</td>
                        <td class="cacher">Moyenne CR-49 **</td>
                        <td class="cacher">{{arrondiHectares(surfaceCR49/nbItemsCR49)}} ha</td>
                        <td class="cacher"><span v-if="propriete.surface">{{formaterValeur((surfaceCR49/nbItemsCR49)/propriete.surface*100, 2)}} %<!--<br/>{{arrondiHectares(propriete.surface)}} ha--></span></td>
                    </tr>

                    <tr class="aDroite impair">
                        <td>Surface d'équilibre **</td>
                        <td>{{arrondiHectares(surfaceEquilibre)}} ha<br/></td>
                        <td><span v-if="propriete.surface">{{formaterValeur(surfaceEquilibre/propriete.surface*100, 2)}} %</span></td>
                    </tr>
                    <tr>
                        <td class="aGauche info" colspan="3"><br/>* Le total des surfaces en coupe rase et boisées est normalement inférieur à la surface totale de la propriété<span v-if="0"><br/><br/>** Le calcul de la <u>moyenne</u> a été effectué sur la <u>totalité de la surface divisée par le nombre de classes de CR à 45-49 ans</u> (et non pas sur la totalité des classes)</span><br/><br/>** Surface d’équilibre (exprimée en terme de surface boisée) : si les classes d’ages sont équilibrées sur la propriété, elle correspond alors à la surface moyenne à couper puis à reboiser sur 5 ans pour maintenir cet équilibre des classes d’age.</td>
                    </tr>
                </table>


        </div>
    </div>
</div>
</div>
</template>


<script>

import { SteresConstantes } from "../../js/calculs/constantes.js"
import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
import { addeoData } from "../../js/api/datas.js"

import { grapheSVG } from "../../js/svg/svgGraphes.js"


//import PeuplementDendrometrieVisualisation from './PeuplementDendrometrieVisualisation.vue'


export default {
    components: {
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            // init les données du form à partir des props
            propriete: JSON.parse(JSON.stringify(this.data)),
            tabAgesPropriete:[],
            surfaceClasses:0,
            surfaceBoisee:0,
            surfaceCR49:0,
            nbItemsCR49:11,
            ready:false,
            svgContentD: "",
            courbeD: null,
        }
    },

    created() {
        this.initialiser();
    },

    computed: {

        laTabValeursExportees() {

            var copieTab = this.tabAgesPropriete.slice();
            // var anneCoupeRase = this.laCoupeRase;
            // var anneeDateInstallation = this.ppl.anneeDateInstallation
            var tab = [];
            copieTab.forEach((laClasse, numAnnee) => {

                var obj = {};
                // annee	depensesTotales	recettesTotales	codeUF	libelleUF	age	surface	operation	recetteOperation

                obj.classeAge = this.exporterValeur(laClasse.libelle);
                obj.surfaceHa = this.exporterValeur(laClasse.surface);
                if (this.propriete.surface) {
                    obj.pourcentagePropriete = this.exporterValeur(laClasse.surface/this.propriete.surface*100);
                }
                else {
                    obj.pourcentagePropriete = 0;
                }

                tab.push(obj);
            })

            // surface d'équilibre
            var obj = {};
            obj.classeAge = "Surface d'équilibre";
            obj.surfaceHa = this.exporterValeur(this.surfaceEquilibre);
            if (this.propriete.surface) {
                obj.pourcentagePropriete = this.exporterValeur(this.surfaceEquilibre/this.propriete.surface*100);
            }
            else {
                obj.pourcentagePropriete = 0;
            }
            tab.push(obj);

            return(tab);

        },

        contentSVGD () {
            //return("");
            return this.svgContentD
        },

        surfaceEquilibre() {
            const lEssence = this.$store.getters.constantes.params.const_essence;
            return(this.surfaceBoisee*lEssence.exploitabiliteCoef/(lEssence.exploitabiliteAge+lEssence.exploitabiliteDelai));
        }

    },

    methods: {
        exporterValeur(valeur) {
            var valeurRetournee = ""+valeur;
            return(valeurRetournee.replace('.', ','));
        },

        exporterTableau(tableau) {
            var nomCSV = this.propriete.identifiant + "_synthese_etat_propriete";
            return addeoData.exporterTableau(tableau, nomCSV);
        },

        initialiser() {
            this.svgContentD = "";
            var tabAgesPropriete = [];
            var surfaceBoisee = 0;
            var surfaceCR49 = 0;
            var surfaceClasses = 0;
            SteresConstantes.classesAge.forEach(classeAge => {
                tabAgesPropriete[classeAge.index] = Object.assign({}, classeAge);
                tabAgesPropriete[classeAge.index].surface = 0;
            })
            // on lit toutes les UF de la propriété pour trouver les coupes / eclaircies qu'on va intégrer année par année
            this.propriete.UFs.forEach(uf => {
                if ((typeof(uf.peuplement) !== 'undefined') && uf.peuplement) {
                    var classeAge = this.$parent.donnerClasseAge(uf.peuplement);
                    surfaceBoisee += uf.peuplement.surfaceBoisee;
                    tabAgesPropriete[classeAge.index].surface += uf.peuplement.surfaceBoisee;
                    if (uf.peuplement.lAge < 50) {
                        surfaceCR49 += uf.peuplement.surfaceBoisee;
                    }
                    surfaceClasses += uf.peuplement.surfaceBoisee;
                }
                else { // si pas de peuplement, on prend en compte toute la surface
                    surfaceBoisee += uf.surface;
                    tabAgesPropriete[0].surface += uf.surface;
                    surfaceCR49 += uf.surface;
                    surfaceClasses += uf.surface;
                }

            })

            this.tabAgesPropriete = tabAgesPropriete;
            this.surfaceBoisee = surfaceBoisee;
            this.surfaceCR49 = surfaceCR49;
            this.surfaceClasses = surfaceClasses;

            // courbes
            if (this.propriete.surface) {
                this.courbeD = this.initialiserGraphes();
                this.svgContentD = grapheSVG.initialiserGraphe(this.$store, this.courbeD);
            }

            this.ready = true;


        },


// ****************************************************************************************************************************************************************************
// ****************************************************************************************************************************************************************************
    initialiserGraphes() {



        var itemsX=[];
        SteresConstantes.classesAge.forEach(classeAge => {
            classeAge.classe = classeAge.libelleCourt;
            itemsX.push(classeAge);
        })
        var itemCR49={"index":itemsX.length, "libelle":"CR-49", "libelleCourt":"CR-49", "x":itemsX.length};
        var items = [];
        items[0] = {};
        itemCR49.itemsY = items;
        var pourcentEquilibre = 0;
        if (this.propriete.surface) {
            items[0].y = this.formaterValeur(this.surfaceCR49/this.nbItemsCR49/this.propriete.surface*100, 1);
            pourcentEquilibre = this.surfaceEquilibre/this.propriete.surface*100;
        }
        else {
            items[0].y = 0;
        }
        items[0].yLibelle = items[0].y + " %";
        items[0].yLibelleBis = "(" + this.arrondiHectares(this.surfaceCR49/this.nbItemsCR49) + " ha)";
        itemsX.push(itemCR49);

        var graphe = {"negatifPositif":0, "idCourbe":"D4_",
                      "infos" : {"titre":"Répartition des surfaces par classe d'âge", "classe":"multi", "itemsX":itemsX, "axeX":"ans", "donneesX":"", "intervalleX":1, "debutX":0, "minX":0, "minY":0},
                      "infosY" : [
                          {"libelleAxeY":"Surfaces", "uniteLibelleAxeY":"%", "xAxeY":"gauche", "donneesY":"%", "intervalleY":10, "max":100, "min":0},
                      ],
                      "surfaceEquilibre" : {"libelle1":"Surface", "libelle2":"d'équilibre", "valeur" : this.arrondiHectares(this.surfaceEquilibre), "y" : this.formaterValeur(pourcentEquilibre, 1)},
                      "datas": []}; // todo MAXs en base

        var pourcentMax = 0;
        this.tabAgesPropriete.forEach(elt => {
            var item = {};
            var items = [];
            item.itemsY = items;
            items[0] = {};

            item.x = elt.index;
            item.xLibelle = elt.libelle;
            if (this.propriete.surface) {
                items[0].y = this.formaterValeur(elt.surface/this.propriete.surface*100, 1);
            }
            else {
                items[0].y = 0;
            }
            items[0].yLibelle = items[0].y + " %";
            items[0].yLibelleBis = "(" + this.arrondiHectares(elt.surface) + " ha)";
            graphe.datas[elt.index] = item;

            pourcentMax = Math.max(items[0].y, pourcentMax);
        })
        graphe.infosY[0].max = pourcentMax;
        //graphe.datas[itemCR49.index] = itemCR49; // pas de colonne CR-49
        return(graphe);
    },

        formaterEuros(valeur) {
            return(ApiTools.formaterEuros0(valeur));
        },
        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterVolumeTab(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterValeur(valeur, arrondi) {
            return(ApiTools.arrondir(valeur, arrondi));
        }

    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {




    #refermer {
        cursor:pointer;
        position:absolute;
        top:0.2em;
        /*color: #238B71;*/
        vertical-align: top;
        left: 50%;
        display: block;
        z-index: 2;
        i {
            font-size:2.2em;
            margin-left:0.4em;
            color: #565656;
        }
        span {
            vertical-align: top;
            display: inline-block;
            padding: 0.8em 0 0;
        }
    }

    #synthese4 {
        padding:5mm;
        border: 1px solid #cccccc;
        overflow-x:auto;

        #infosGeneriques {
            display:none;
        }

        .infoImpression {
            color:#238b71 !important;
            cursor:pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .fa-print {
            font-size:2em;
            cursor:pointer;
        }
        .fa-download {
            margin-right : 1em;
        }
        .conteneur {
            /*max-width: 200mm;*/
            margin:0;
            text-align:center;
        }

        table {
                width: 100%;
                min-width: 600px;
                max-width: 720px;
                background-color: transparent;
                color: #223938;
                padding: 1em  1% 0.2em;
                border-spacing : 0;
                margin:3mm auto 1em;
                table-layout:auto;

                tr {
                    vertical-align: top;
                    &.entete {
                        th {
                            padding-bottom:1em;
                        }
                    }

                    th, tr {
                        padding:0;
                    }

                    &:first-child, &:nth-child(2) {
                        th {
                            span {
                                 display: inline;
                            }
                        }
                    }

                    th, td {
                        width: 16%;
                        min-width: 120px;
                        max-width: 36mm;
                    }
                    th {
                        margin-bottom: 1em;
                        text-align: center;
                        white-space: nowrap;
                        font-weight: normal;
                        font-weight: bold;

                        span {
                            display: none;

                            &.annee {
                                display: inline;
                                font-weight: bold;
                            }
                        }

                    }

                    td {
                        padding-right:2em;
                        span.utile.inutile {
                            visibility:hidden;
                        }
                        &.vide {
                            color: transparent;
                        }

                        &.petit {
                            width: 12%;
                            min-width: 120px;
                            max-width: 30mm;
                            span {
                                display: inline-block;
                                width: 100%;
                            }
                        }
                        &.comble {
                                width: auto;
                                min-width:0;
                            }
                        &.info {
                            font-size:90%;
                            font-style: italic;
                        }

                    }
                    &.impair {
                        td {
                            background-color: #F2F7F3;
                        }
                    }
                    img.iconeFct {
                        text-align: left;
                        margin-left:1em;
                        float: none;
                        width : auto;
                    }
                }
            }
        .image {
            display:none;
        }
        .aDroite {
            text-align: right;
        }
        .droite {
            float: right;
        }
    }
}
    body#app {
        #syntheseD {

            .containerCourbes {
                display: block;
            }

            svg {
                font-family: 'robotoregular', Helvetica, Arial, sans-serif;
                font-size:1.6em;
            }

            color:#000000;
            font-family: Helvetica, Arial, sans-serif !important;

            h2 {
                font-family: Helvetica, Arial, sans-serif !important;
            }
            /*
            background: url("../../assets/img/cpfa.png") transparent no-repeat top left;
            background-size: 15mm 20mm;
            */
            .infosGeneriques {
                margin-right: 2em;
            }
            .image {
                text-align:right;
                /*display:inline;*/
                img {
                    width: 10mm;
                    height:auto;
                }
            }
            h2 {
                font-size:4mm;
                text-align: center;
                margin-top: 0;

                i.fa-download {
                   display:none;
                }
            }
            .aDroite {
                text-align: right;
                vertical-align: top;
            }
            .majuscules {
                text-transform: uppercase;
            }
            table {
                max-width: 200mm;
                width: 200mm;
                font-size:3.2mm;
                margin-left:10mm;

                tr {
                    color:#444444;
                    &.ligneAnnee {
                        color:#000000;
                    }
                    th {
                        width: 15mm;
                        text-align: left;
                    }
                    td {
                        width: 30mm;
                        text-align: left;
                    }
                    td.petit {
                        min-width: 25mm;
                    }
                }
            }
        }
    }

</style>
