/* @ngInject */
class ApiToolsObj {
  /* @ngInject */
  constructor($q) { // Injection des dépendances SteresDB et $q
    this.$q = $q
  }

    clone(originalObject){
        if((typeof originalObject !== 'object') || originalObject === null){
            throw new TypeError("originalObject parameter must be an object which is not null");
        }

        var deepCopy = JSON.parse(JSON.stringify(originalObject));

        // Une petite récursivité
        function deepProto(originalObject, deepCopy){
            deepCopy.__proto__ = Object.create(originalObject.constructor.prototype);
            for(var attribute in originalObject){
                if(typeof originalObject[attribute] === 'object' && originalObject[attribute] !== null){
                    deepProto(originalObject[attribute], deepCopy[attribute]);
                }
            }
        }
        deepProto(originalObject, deepCopy);

        return deepCopy;
    }


    isStringEmpty(str) {
        switch (str) {
            case "":
            case 0:
            case "0":
            case null:
            case false:
            case typeof this == "undefined":
                return true;
            default:
                return false;
            }
        }

    toggleClassElt(elt, addRemoveClass, className) {
        if (elt) {
            if (addRemoveClass === 'addClass') {
                elt.classList.add(className);
            }
            else {
                elt.classList.remove(className);
            }
        }
    }
    appAttendre(attendre) {
        var addRemoveClass ='addClass';
        if (!attendre) {
            addRemoveClass ='removeClass';
        }
        var eltApp = document.getElementById("app");
        this.toggleClassElt(eltApp, addRemoveClass, "curseurAttendre");
    }

    tarifStandard(gestionCommercialeJson) {
        var datas = JSON.parse(gestionCommercialeJson);
        return(datas.prixEuroHectare);
    }
    offreLancement(gestionCommercialeJson) {
        var datas = JSON.parse(gestionCommercialeJson);
        return(datas.offreLancement);
    }

    // ARRONDIS : {"arrondiSurfaceHectares":4,"arrondiHauteurMetres":1,"arrondiCirconferenceCentimetres":0,"arrondiDensiteTigesHectare":0,"arrondiVolumeM3":3}
    arrondiSurface(valeur, arrondisJson, min = true) {
        var arrondis = JSON.parse(arrondisJson);
        if (min) {
            return(this.arrondiMin(valeur, arrondis.arrondiSurfaceHectares));
        }
        else {
            return(this.arrondir(valeur, arrondis.arrondiSurfaceHectares));
        }
    }
    arrondiSurfaceTab(valeur, arrondisJson) {
        var arrondis = JSON.parse(arrondisJson);
        var number = this.arrondir(valeur, arrondis.arrondiSurfaceHectares);
        var nombreOk = number.toFixed(arrondis.arrondiSurfaceHectares);
        return(nombreOk);
    }
    arrondiHauteur(valeur, arrondisJson, min = true) {
        var arrondis = JSON.parse(arrondisJson);
        if (min) {
            return(this.arrondiMin(valeur, arrondis.arrondiHauteurMetres));
        }
        else {
            return(this.arrondir(valeur, arrondis.arrondiHauteurMetres));
        }
    }
    arrondiHauteurTab(valeur, arrondisJson) {
        var arrondis = JSON.parse(arrondisJson);
        var number = this.arrondir(valeur, arrondis.arrondiHauteurMetres);
        var nombreOk = number.toFixed(arrondis.arrondiHauteurMetres);
        return(nombreOk);
    }
    arrondiCirconference(valeur, arrondisJson, min = true) {
        var arrondis = JSON.parse(arrondisJson);
        if (min) {
            return(this.arrondiMin(valeur, arrondis.arrondiCirconferenceCentimetres));
        }
        else {
            return(this.arrondir(valeur, arrondis.arrondiCirconferenceCentimetres));
        }
    }
    arrondiCirconferenceTab(valeur, arrondisJson) {
        var arrondis = JSON.parse(arrondisJson);
        var number = this.arrondir(valeur, arrondis.arrondiCirconferenceCentimetres);
        var nombreOk = number.toFixed(arrondis.arrondiCirconferenceCentimetres);
        return(nombreOk);
    }
    arrondiDensite(valeur, arrondisJson, min = true) {
        var arrondis = JSON.parse(arrondisJson);
        if (min) {
            return(this.arrondiMin(valeur, arrondis.arrondiDensiteTigesHectare));
        }
        else {
            return(this.arrondir(valeur, arrondis.arrondiDensiteTigesHectare));
        }
    }
    arrondiDensiteTab(valeur, arrondisJson) {
        var arrondis = JSON.parse(arrondisJson);
        var number = this.arrondir(valeur, arrondis.arrondiDensiteTigesHectare);
        var nombreOk = number.toFixed(arrondis.arrondiDensiteTigesHectare);
        return(nombreOk);
    }
    arrondiVolume(valeur, arrondisJson, min = true) {
        var arrondis = JSON.parse(arrondisJson);
        if (min) {
            return(this.arrondiMin(valeur, arrondis.arrondiVolumeM3));
        }
        else {
            return(this.arrondir(valeur, arrondis.arrondiVolumeM3));
        }
    }
    arrondiVolumeTab(valeur, arrondisJson) {
        var arrondis = JSON.parse(arrondisJson);
        var number = this.arrondir(valeur, arrondis.arrondiVolumeM3);
        var nombreOk = number.toFixed(arrondis.arrondiVolumeM3);
        return(nombreOk);
    }
    arrondir(valeur, puissance = 3) {
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

    // cette fonction est utilisée quand la valeur non arrondie sert de référence min à une valeur saisie - on ne peut donc aps afficher une valeur supérieure qui serait refusée à la saisie
    arrondiMin(valeur, puissance = 3) {
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.floor(valeur*puissanceDe10)/puissanceDe10; // arrondi min à x chiffres (3 par défaut)
        return(arrondi);
    }

    carboneVersCO2(valeur, massesMolairesJson) {
        const massesMolaires = JSON.parse(massesMolairesJson);
        return(valeur / massesMolaires.C *  massesMolaires.co2);
    }

    formaterEuros(valeur) {
        return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
    }
    formaterEuros0(valeur) {
        return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(valeur))
    }
    formaterCarbone0(valeur) {
        return(this.arrondir(valeur, 0) + " t eqC");
    }
    formaterPourCent0(valeur) {
        return(this.arrondir(valeur, 0) + " %");
    }

            // pour la saisie des nombres
    filtrerNombresEntiersPositifs(e){
        const key = e.key;

        // If is '.' key, stop it
        if ((key === '.') || (key === ','))
            return e.preventDefault();
        // If is '-+' key, stop it
        if ((key === '-') || (key === '+'))
            return e.preventDefault();
        // OPTIONAL
        // If is 'e' key, stop it
        if (key === 'e')
            return e.preventDefault();
        }
    filtrerNombresPositifs(e){
        const key = e.key;

        // If is '-+' key, stop it
        if ((key === '-') || (key === '+'))
            return e.preventDefault();
        // OPTIONAL
        // If is 'e' key, stop it
        if (key === 'e')
            return e.preventDefault();
        }
}

export const ApiTools = new ApiToolsObj()
