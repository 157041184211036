import lf from 'lovefield'

export function user_utilisateurs (schemaBuilder) {
  schemaBuilder.createTable('user_utilisateurs')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('identifiant', lf.Type.STRING)
      .addColumn('nom', lf.Type.STRING)
      .addColumn('prenom', lf.Type.STRING)
      .addColumn('profilId', lf.Type.INTEGER)
      .addColumn('qualificationId', lf.Type.INTEGER)
    /*
      .addColumn('adresse', lf.Type.STRING)
      .addColumn('codePostal', lf.Type.STRING)
      .addColumn('commune', lf.Type.STRING)
      .addColumn('codeCommune', lf.Type.STRING)
      .addColumn('TVA', lf.Type.STRING)
      .addColumn('nTVA', lf.Type.STRING)
      .addColumn('telephone', lf.Type.STRING)
      */
      .addColumn('adresseMail', lf.Type.STRING)
      .addColumn('version', lf.Type.INTEGER)
      .addColumn('synchroLocal', lf.Type.INTEGER) // savoir si l'utilisateur est synchro avec la base centralisée true : oui, false : non ...
      .addPrimaryKey(['id'], false)
      .addNullable(['synchroLocal']);
}
