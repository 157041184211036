import lf from 'lovefield'

export function const_essences_cultures (schemaBuilder) {
  schemaBuilder.createTable('const_essences_cultures')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('essenceId', lf.Type.INTEGER)
      .addColumn('cultureId', lf.Type.INTEGER)
      .addColumn('hauteurFinInstallation', lf.Type.DOUBLE)
      .addColumn('borneMesureAutorisee', lf.Type.INTEGER)
      .addPrimaryKey(['id'], false);
}
