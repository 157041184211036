import lf from 'lovefield'

export function calc_bobi (schemaBuilder) {
  schemaBuilder.createTable('calc_bobi')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('vuMin', lf.Type.DOUBLE)
      .addColumn('vuMaxInf', lf.Type.DOUBLE)
      .addColumn('pourcentBoSciage', lf.Type.INTEGER)
      .addColumn('pourcentBoCanter', lf.Type.INTEGER)
      .addColumn('pourcentBoTotal', lf.Type.INTEGER)
      .addColumn('pourcentBi', lf.Type.INTEGER)
      .addPrimaryKey(['id'], true); // attention, le fait de mettre un id ralenti singulièrement le readAll sur cette table et vu qu'on n'y accède jamais par id ...
}
