import lf from 'lovefield'

export function user_uf (schemaBuilder) {
  schemaBuilder.createTable('user_uf')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('proprieteId', lf.Type.INTEGER)
      .addColumn('terroirId', lf.Type.INTEGER)
      .addColumn('code', lf.Type.STRING)
      .addColumn('identifiant', lf.Type.STRING)
      .addColumn('surface', lf.Type.DOUBLE)
      .addColumn('commentaire', lf.Type.STRING)
      .addColumn('version', lf.Type.INTEGER)
      .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon)
      .addColumn('modeForet', lf.Type.INTEGER) // savoir si on a fait une modif de l'UF ou de ses enfants en mode foret : false ou true
      .addPrimaryKey(['id'], false)
      .addNullable(['idLocal'])
      .addNullable(['modeForet']);
}
