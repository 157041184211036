/*
import * as TerrassorConst from '../../terrassor_const.js'
import { set_Terrassor_INTERFACE, get_Terrassor_INTERFACE } from "../../interface.js";
*/
//import dateFns from 'date-fns'

import { SteresDB } from '../db/SteresDB.js'
import { addeoData } from "./datas.js";
import { LovefieldService } from "../db/LovefieldService.js"


import { SteresConstantes } from "../calculs/constantes.js"
import { UfPeuplement } from "../calculs/peuplementObj.js"
//import { UfPplDendrometrique } from '../calculs/dendrometriqueObj.js'
import { UfPplDendrometrique } from '../calculs/dendrometriqueObjTER.js'
import { UfPplFinancier } from '../calculs/financierObj.js'
import { UfPplCarbone } from '../calculs/carboneObj.js'

import * as SteresConst from '../steres_const.js'


/* @ngInject */
class ApiUserObj {
  /* @ngInject */
  constructor($q) { // Injection des dépendances SteresDB et $q
    // on les enregistre sur 'this' afin de les reutiliser plus tard.
    //this.SteresDB = SteresDB
    this.$q = $q
    this.synchroLocal = true; // a priori, c'est bon
    this.limiteLecture = 100;

/*
  this.analyseFieldsToSerialize =
      ['erreursCoherenceTab', 'erreursDureeTab', 'erreursInstallationTab', 'erreursInutileTab', 'erreursRemblaiTab', 'erreursUsageTab', 'tabBonusQualiteMouvements', 'tabBonusInstallationQ','tabBonusInstallationS','tabBonusInstallationE']
*/
  }

// tables users

    // tous les utilisateurs : TMP
    getUserUtilisateurs() {
        return SteresDB.readAllFromTable('user_utilisateurs')
    }


    /*
    lecture directe dans base locale
    */
    lireUtilisateur(id) {
        var retour = {};
        return SteresDB.readRowInTable(id, 'user_utilisateurs')
        .then(response => {
            retour.utilisateur = response;
            return(retour);
        })
    }


    async setUtilisateur(utilisateur) {
        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versios entre bd locale et distante)
        utilisateur.version += 1;
        // on commence par la sauvegarde en ligne pour obtenir un id
        //await addeoData.ecrireDatasUtilisateur(utilisateur)
        return addeoData.ecrireDatasUtilisateur(utilisateur)
          .then(response => {
            if (response && (response.retour == true)) {
                utilisateur.id = response.data.id;
                utilisateur.identifiant = response.data.identifiant;
                utilisateur.profilId = response.data.profilId;
                utilisateur.qualificationId = response.data.qualificationId;
                utilisateur.idLocal = false
                console.log("setUtilisateur, synchro OK")
            }
          else {
              // pb sur stockage BD centralisée
              // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
              this.ecrireSynchroUtilisateur(utilisateur.userId, false);
              console.log("setUtilisateur, synchro PAS OK")
                return 0
          }
            return SteresDB.saveRowInTable(utilisateur, 'user_utilisateurs')
            .then(response => {
                return utilisateur.id
            })
        })
  }

    /*
    * on stocke qu'on n'a pas pu sauvegarder en ligne : permettra d'afficher le bouton pour le faire
    */
    ecrireSynchroUtilisateur(idUtilisateur, etat) {
        var lApi = this;
        SteresDB.readRowInTable(idUtilisateur, 'user_utilisateurs')
            .then(response => {
                response.synchroLocal = etat
                lApi.majSynchro(etat);
                SteresDB.saveRowInTable(response, 'user_utilisateurs')
            })
    }
/*
    getUserUsers() {
        return SteresDB.readAllFromTable('user_users')
    }
*/
    /*
    * on stocke qu'on n'a pas pu sauvegarder en ligne : permettra d'afficher le bouton pour le faire
    */
/*
    ecrireSynchroUser(idUser, etat) {
        var lApi = this;
        SteresDB.readRowInTable(idUser, 'user_users')
            .then(response => {
                response.synchroLocal = etat
                lApi.majSynchro(etat);
                SteresDB.saveRowInTable(response, 'user_users')
            })
    }
*/
    majSynchro(synchroLocal) {
        this.synchroLocal = synchroLocal;
    }
    lireSynchro() {
        if (this.synchroLocal === false) {
            return false
        }
        return true // on return true si "undefined"
    }

// tables proprietes

    async getUserProprietes(idUser) {
        //return await SteresDB.readUserproprietesInTable(idUser, 'user_proprietes')
//        return SteresDB.readUserproprietesInTable(idUser, 'user_proprietes')
        return SteresDB.readAllFromTable('user_proprietes') // il n'y a que les tables du user !!!
        .then(response => {
                return(response)
            })
        //return await SteresDB.readUserproprietesInTable(idUser, 'user_proprietes')
        /*
        .then(response => {
            return SteresDB.readAyantsDroitJsonproprietesInTable(idUser, 'user_proprietes')
            .then(responseBis => {
                return(response)
            })
        })
        */
    }

    initialiserDepenses(constantes, tabDepensesProprieteBD = []) {
        var depenses = constantes.params.const_depenses;
        var tabDepensesPropriete = []
        depenses.forEach(depense => {
            if (depense.annuelle) {
                var depensePropriete = {};
                var depenseProprieteBD = tabDepensesProprieteBD.find( depenseOK => depenseOK.code === depense.code)
                // on initialise ou on rafraichit (selon cas) : on reprend les données "constantes" sauf le choix
                depensePropriete.id = depense.id;
                depensePropriete.code = depense.code;
                depensePropriete.libelle = depense.libelle;
                depensePropriete.anneeDebut = depense.anneeDebut;
                depensePropriete.coutHectare = depense.coutHectare;
                depensePropriete.choisi = depense.bonnePratiqueOuValide;
                if (typeof(depenseProprieteBD) !== 'undefined') {
                    depensePropriete.choisi = depenseProprieteBD.choisi;  // on conserve l'état stocké en base
                }
                tabDepensesPropriete.push(depensePropriete);
            }
        })
        return(JSON.stringify(tabDepensesPropriete));
    }

    lireDatasProprieteDesynchroIteration(idPropriete, limiteLecture, iteration) {
            return addeoData.lireDatasPropriete(iteration, limiteLecture, idPropriete).then(response => {
                //return LovefieldService.synchroniserProprieteUFs(response.data, false).then(responseBidon => {
                return LovefieldService.synchroniserProprieteUFs(response.data, 2).then(responseBidon => { // 2020/12 on ne passe ni true ni false car on veut une synchro "one shot" ET sans truncate
                    return(response);
                })
            })
    }
/*
    lireDatasProprieteDesynchro(idPropriete, nbUFs) {

        var limiteLecture = this.limiteLecture;
        var nbIterations = parseInt(nbUFs / limiteLecture);
        var debut = 2;
        for (var i = debut; i < debut+nbIterations; i++) {
            this.lireDatasProprieteDesynchroIteration(idPropriete, limiteLecture, i);
        }
        return(1);
    }
*/
    async lireDatasProprieteSynchro(idPropriete) {
        var limiteLecture = this.limiteLecture;
        return addeoData.lireDatasPropriete(1, limiteLecture, idPropriete).then(response => {
            if (response) {
                return(LovefieldService.synchroniserProprieteUFs(response.data, true)).then(responseBidon => {
                    return(response);
                })
            }
            else {
                console.log("lireDatasProprieteSynchro impossible", idPropriete);
                return(null);
            }
        })
    }

    lirePropriete(id, constantes) {
        var retour = {};
        return SteresDB.readRowInTable(id, 'user_proprietes')
        .then(response => {
            if ((typeof(response) === 'undefined') || !response) {
                return(response);
            }

            var propriete = JSON.parse(JSON.stringify(response));
            retour.propriete = propriete;
            propriete.surface=0;

            // init ayantsDroitJson (création en cours de dev)
            if ((typeof(propriete.ayantsDroitJson) === 'undefined') || (propriete.ayantsDroitJson == "")) { // sécurisation modification base
                propriete.ayantsDroitJson = "[]";
            }
            // le jSon contient des 0 en début et en fin - on n'en veut pas
            propriete.ayantsDroitJson = propriete.ayantsDroitJson.replace('[0,', '[')
            propriete.ayantsDroitJson = propriete.ayantsDroitJson.replace(',0]', ']')

            // init depensesJson (champ créé en cours de dev et potentiellement vide)
            if ((typeof(propriete.depensesJson) === 'undefined') || (propriete.depensesJson == "")) { // sécurisation modification base
                propriete.depensesJson = this.initialiserDepenses(constantes);
            }
            else {
                propriete.depensesJson = this.initialiserDepenses(constantes, JSON.parse(propriete.depensesJson)); // s'il manque des dépenses, on les rajoutes
            }

            return SteresDB.readInTable(propriete.id, 'proprieteId', 'user_uf', "identifiant", false)
            .then(response => {
                retour.UFs = response; // attention, il y a ici un système de cache bizarre et response continue à contenir un pointeur "propriete" qui n'existe pas en base !!!!!!!!!
                //retour.propriete.UFs = response; // 2020/02/27 - accéder aux UFs depuis la propriété

                var tabUFs = [];
                retour.UFs.forEach(uf => {
                    uf.tabParcelles = [];
                    uf.surface = 0;
                    uf.peuplement = null;
                    tabUFs["lId"+uf.id] = uf;
                });

                var ids = retour.UFs.map(function(row) {
                    return row['id'];
                    });

                // 2020/12
                // il faut lire également toutes les parcelles de la propriété (uf par UF)
                return SteresDB.readInTableFromTab(ids, 'ufId', 'user_ufparcelle', "libelleCommune", false)
                .then(responseParcelles => {

                    var surface = 0;
                    // on a maintenant toutes les parcelles de la propriété qu'il faut réassocier aux UF
                    if ((typeof(responseParcelles) !== 'undefined') && responseParcelles) {
                        responseParcelles.forEach(parcelle => {
                            tabUFs["lId"+parcelle.ufId].tabParcelles.push(parcelle);
                            tabUFs["lId"+parcelle.ufId].surface += parseFloat(parcelle.surface);
                            surface += parseFloat(parcelle.surface);
                        })
                    }
                    propriete.surface+=surface;

                    // 2020/12
                    // il faut lire également toutes les peuplements de la propriété
                    return SteresDB.readInTableFromTab(ids, 'ufId', 'user_ufpeuplement', "ufId", false)
                    .then(responsePeuplements => {

                        // on a maintenant toutes les peuplements de la propriété qu'il faut réassocier aux UF
                        var tabPeuplements = [];
                        if ((typeof(responsePeuplements) !== 'undefined') && responsePeuplements) {
                            responsePeuplements.forEach(peuplement => {
                                tabUFs["lId"+peuplement.ufId].peuplement = peuplement;

                                // au passage on prépare l'association des peuplements aux mesures et eclaircies
                                peuplement.mesures = [];
                                peuplement.eclaircies = [];
                                tabPeuplements["lId"+peuplement.id] = peuplement;
                            })
                        }

                        var idsPeuplements = responsePeuplements.map(function(row) {
                            return row['id'];
                        });

                                            // 2020/12
                        // il faut lire également toutes les mesures et eclaircies des peuplements de la propriété
                        return SteresDB.readInTableFromTab(idsPeuplements, 'peuplementId', 'user_ufpeuplementmesure', "peuplementId", false)
                        .then(responseMesures => {
                            return SteresDB.readInTableFromTab(idsPeuplements, 'peuplementId', 'user_ufpeuplementeclaircie', "peuplementId", false)
                            .then(responseEclaircies => {

                                // on a maintenant toutes les eclaircies de la propriété qu'il faut réassocier aux peuplements
                                if ((typeof(responseEclaircies) !== 'undefined') && responseEclaircies) {
                                    responseEclaircies.forEach(eclaircie => {
                                        tabPeuplements["lId"+eclaircie.peuplementId].eclaircies.push(eclaircie);
                                    })
                                }
                                // idem mesures
                                if ((typeof(responseMesures) !== 'undefined') && responseMesures) {
                                    responseMesures.forEach(mesure => {
                                        tabPeuplements["lId"+mesure.peuplementId].mesures.push(mesure);
                                    })
                                }

                                return(retour);
                            })
                        })
                    })
                })
            })
        })
    }



    async setPropriete(propriete) {
        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versios entre bd locale et distante)
        propriete.version += 1;
        // on veut un jSon qui commence par "[0," et fini par ",0]" de cette façon, toutes les valeurs x lues sont de type ",x," ce qui permet des requêtes SQL
        if (propriete.ayantsDroitJson != "[]") {
            propriete.ayantsDroitJson = propriete.ayantsDroitJson.replace('[', '[0,')
            propriete.ayantsDroitJson = propriete.ayantsDroitJson.replace(']', ',0]')
        }

        // on commence par la sauvegarde en ligne pour obtenir un id
        return addeoData.ecrireDatasPropriete(propriete)
        .then(response => {
            if (response && (response.retour == true)) {
                propriete.id = response.data
                propriete.idLocal = false
                console.log("setPropriete, synchro OK")
            }
            else {
              // pb sur stockage BD centralisée
              // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
              this.ecrireSynchroUtilisateur(propriete.userId, false);
              console.log("setPropriete, synchro PAS OK")
            }
            return SteresDB.saveRowInTable(propriete, 'user_proprietes')
            .then(response => {
                return(propriete.id)
            })
        })
  }

    async autoriserSynchronisationPropriete(propriete, userId) {

        // on commence par la sauvegarde en ligne pour obtenir un id
        return addeoData.autoriserSynchronisationDatasPropriete(propriete)
        .then(response => {
            if (response && (response.retour == true)) {
                if (userId == response.data) {
                    console.log("autoriserSynchronisationPropriete, synchro OK")
                    return(true);
                }
            }
            console.log("autoriserSynchronisationPropriete, synchro PAS OK")
            return(false);
        })
  }

    async synchroniserPropriete(propriete, proprieteUFs) {

        // on commence par la sauvegarde en ligne pour obtenir un id
        return addeoData.synchroniserDatasPropriete(propriete, proprieteUFs) // // toutes les infos "hierarchiques" de la propriété sont dans proprieteUFs !!!
        .then(response => {
            if (response && (response.retour == true)) {
                console.log("synchroniserPropriete, synchro OK")
                // on ne fait rien car on sort vers l'écran "proprieteS" et on va relire l'ensemble de la propriété si on y retourne
                return(response.data);
/*
                // TODO on met à jour la Base locale avec les id qui vont bien ? Ou plutôt on recharge l'utilisatuer et on va sur "propriétés" ?
                // ...

                // 2020/12 - il faut ici obligatoirement recharger les parcelles en base, car elles ont de nouveaux id
                    // 1) création du tableau
                var ufs = response.data.ufs;
                var tabParcelles=[];
                var parcellesBidon = ufs.map(function(row) {
                    row['parcelles'].forEach(parcelle => {
                        tabParcelles.push(parcelle);
                    })
                    return row['parcelles'];
                    });

                    // 2) écriture du tableau : 2020/12 Toutes les parcelles même si synchro partielle ?
                return SteresDB.truncate('user_ufparcelle').then(resultBidon => {
                    return SteresDB.saveRowsInTable(tabParcelles, 'user_ufparcelle').then(responseBidon => {
                        return(response.data);
                    })
                })
*/
            }
            console.log("synchroniserPropriete, synchro PAS OK")
            return(false);
        })
  }


    /*
    destruction directe dans base locale ET - si possible - dans base distante
    // TODO destruction peuplement et données associées
    */
    deletePropriete(propriete) {

        // on detruit l'arborescence
        if (typeof(propriete.uf) !== 'undefined') {
            propriete.uf.forEach(uf => {
            // this.deleteUF(uf); plus tard TODO
            })
        }
        SteresDB.deleteRowInTable(propriete.id, 'user_proprietes');

        // NB : une UF contient ici des données enfants (ie uf.tabParcelles) qui sont stockées dans une table à part de la base
        // c'est l'API PHP qui se débrouille pour ça !!!
        addeoData.deletePropriete(propriete.id)
        .then(response => {
            if (!response || (response.retour != true)) {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(propriete.userId, false)
                console.log("deletePropriete, synchro PAS OK")
            }
        })
  }


/*******************************
Unités Forestières
*******************************/

    /*
    lecture directe dans base locale
    */
    async lireUF(id) {
        var retour = {};
        return  SteresDB.readRowInTable(id, 'user_uf')
        .then(response => {
            retour.uf = response;
            retour.uf.surface = 0;
            return SteresDB.readInTable(response.id, 'ufId', 'user_ufparcelle')
            .then(response => {
                var tabTmp = [];
                tabTmp = tabTmp.concat(response);
                tabTmp = tabTmp.sort(this.triVille);
                retour.parcelles = tabTmp;
                retour.parcelles.forEach(parcelle => retour.uf.surface += parseFloat(parcelle.surface));
                return(retour);
            })
        })
    }

    // tri des parcelles par ordre de ville puis d'identifiant
    triVille(x, y) {
       if (x.libelleCommune < y.libelleCommune) {
           return(-1)
       }
       if (x.libelleCommune > y.libelleCommune) {
           return(1)
       }
       if (x.identifiant < y.identifiant) {
           return(-1)
       }
       if (x.identifiant > y.identifiant) {
           return(1)
       }
       return(0)
    }

    /*
    ecriture -si possible - dans base distante ET directe dans base locale
    */
    async setUF(uf) {

        var code="";
        // si on a un id d'Unité Forestière alors on détruit toutes les parcelles (on va les recréer ...)
        if (uf.id) {
            SteresDB.deleteInTable(uf.id, 'ufId', 'user_ufparcelle'); // on n'attend pas ...
        }
        else {
            code = uf.code;
        }


        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versios entre bd locale et distante)
        uf.version += 1;
        uf.tabParcelles.forEach(parcelle => {
            parcelle.version = uf.version;
        })

        // NB : une UF contient ici des données enfants (ie uf.tabParcelles) qui sont stockées dans une table à part de la base
        // c'est l'API PHP qui se débrouille pour ça !!!
      // on commence par la sauvegarde en ligne pour obtenir un id
        return await addeoData.ecrireDatasUF(uf)
        .then(response => {
            if (response && (response.retour == true)) {
                uf.id = response.data.uf.id;
                uf.idLocal = false;
                uf.tabParcelles = response.data.parcelles;
                console.log("setUF, synchro OK")
                if (code != "") {
                    return(SteresDB.deleteInTable(uf.id, 'ufId', 'user_ufparcelle')) // mieux vaut tard que jamais ... 'user_ufparcelle') ?
                    .then(response => {        // on met maintenant à jour en local
                        return SteresDB.saveRowInTable(uf, 'user_uf')
                        .then(response => {
                            uf.tabParcelles.forEach(parcelle => {
                                parcelle.ufId = response.id;
                            })
                            SteresDB.saveRowsInTable(uf.tabParcelles, 'user_ufparcelle') // on n'attend pas la réponse ...
                            return(uf.id)
                        })
                    })
                }
            }
            else {
                // pas problème particulier car pas de création d'UF'
                if (addeoData.testerModeForet(uf)) {
                    // id UF stocké dans modeForetUfId
                    // donc on ne fait rien, le marquage va être fait par le saveRowInTable
                }
                else { // le code ci-dessous me semble caduque ...
                    // pb sur stockage BD centralisée
                    // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                    this.ecrireSynchroUtilisateur(uf.userId, false)
                    console.log("setUF, synchro PAS OK")
                }
            }

        // on met maintenant à jour en local
        return SteresDB.saveRowInTable(uf, 'user_uf')
            .then(response => {
                uf.tabParcelles.forEach(parcelle => {
                    parcelle.ufId = response.id;
                })
                SteresDB.saveRowsInTable(uf.tabParcelles, 'user_ufparcelle') // on n'attend pas la réponse ...
                return(uf.id)
            })
        })
  }

    // on stocke modeFore dans l'UF de façon à la synchroniser
    marquerUfModeForet(idUf) {
        if ((typeof(idUf) !== 'undefined') && idUf) {
            return  SteresDB.readRowInTable(idUf, 'user_uf')
            .then(response => {
                response.modeForet = true;
                return SteresDB.saveRowInTable(response, 'user_uf') // on n'attend pas
            })
        }
    }

    /*
    destruction directe dans base locale ET - si possible - dans base distante
    // TODO destruction peuplement et données associées
    */
    deleteUF(uf) {

        // on commence en local
        uf.tabParcelles.forEach(parcelle => {
            SteresDB.deleteRowInTable(parcelle.id, 'user_ufparcelle');
        })
        SteresDB.deleteRowInTable(uf.id, 'user_uf');

        // NB : une UF contient ici des données enfants (ie uf.tabParcelles) qui sont stockées dans une table à part de la base
        // c'est l'API PHP qui se débrouille pour ça !!!
        addeoData.deleteUF(uf.id)
        .then(response => {
            if (!response || (response.retour != true)) {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(uf.userId, false)
                console.log("deleteUF, synchro PAS OK")
            }
        })
  }


/*******************************
Unités Forestières : peuplements
*******************************/
 /**/
    anneeRetenueCourante(moisBascule) { // ce code est proche de "anneeMinAcceptable" dans dendrometrique.obj - prise en compte de l'éclaircie aprés que le temps ... soit passé ...
        //var lAnnee = new Date().getFullYear();
        var lAnnee = 0;
        var moisReel = new Date().getMonth()+1;
        if (moisReel >= moisBascule) {
            lAnnee += 1;
        }
        return lAnnee;
    }
/**/
    /*

    */
	anneeRetenuePeuplement(dateInstallation, constantes) {
		if (!dateInstallation.length) {
			return("Date d'installation inconnue");
		}
		var annee = parseInt(dateInstallation.substring(0, 0+4));
        if (dateInstallation.length == 4) {
            return(annee);
        }
        /*
		var mois = parseInt(dateInstallation.substring(5, 5+2));
		if (mois < constantes.steres.moisBascule) {
			annee--;
		}
		return(annee);
        */
        // devient

		var mois = parseInt(dateInstallation.substring(5, 5+2));
		if (mois >= constantes.steres.moisBascule) {
			annee++;
		}
		return(annee);
	}


    lirePeuplementsUF(ufId) {
        return SteresDB.readInTable(ufId, 'ufId', 'user_ufpeuplement', 'dateInstallation', true); // on va trier par dateInstallation DESC
    }
    lirePeuplementUF(ufId) { // il n'y a MAINTENANT qu'un SEUL peuplement par UF
        return SteresDB.readInTable(ufId, 'ufId', 'user_ufpeuplement')
            .then(resultsArray => {
                return resultsArray.pop()
            });
    }

    lireEclairciesIT(itId) {
        return SteresDB.readInTable(itId, 'itId', 'user_ufpeuplement_iteclaircie', 'id', true); // on va trier ...
    }

    lirePeuplementInTable(id, ufId) {
        if (id) {
            return SteresDB.readRowInTable(id, 'user_ufpeuplement')
        }
        else {
            return this.lirePeuplementUF(ufId)
        }
    }

    /**
     * calculerDepenseUF
     *
     * @return dépenses de l'UF sans peuplement
     */
    calculerDepenseUF(propriete, uf, constantes) {
        var depenses = JSON.parse(propriete.depensesJson);
        var depensesPrevuesAnnees = 0;
        depenses.forEach(depense => {
            if (depense.choisi && !depense.anneeDebut) {
                depensesPrevuesAnnees += depense.coutHectare;
            }
        })
        // injection des dépenses
        uf.depensesPrevuesAnnees = depensesPrevuesAnnees *  uf.surface;
    }

    /**
     * retrouverEclaircieVirtuelle
     *
     * @return L'éclaircie est elle virtuelle (future)
     */
    retrouverEclaircieVirtuelle(age, peuplement, eclaircies=null) {
        var eclaircieTrouvee = null;
        if (!eclaircies) {
            eclaircies = peuplement.eclaircies;
        }
        eclaircies.forEach(eclaircie => {
            if ((eclaircie.age == age) && (typeof(eclaircie.proposition) !== 'undefined') && eclaircie.proposition) {
                eclaircieTrouvee = eclaircie;
            }
        })
        return(eclaircieTrouvee);
    }

    /**
     * calculerUFIT
     *
     * @return résultats financiers de l'IT
     */
    calculerUFItChoisi(propriete, uf, constantes) {

        // init
        uf.surfaceEclaircieAnnees = 0;
        uf.surfaceCoupeRaseAnnees = 0;
        uf.valeurSurPiedAnnees = 0;
        uf.valeurAttenteAnnees = 0;
        uf.recettesPrevuesAnnees = 0;
        uf.surfaceEclaircieAnneeRetenue = 0;
        uf.surfaceCoupeRaseAnneeRetenue = 0;
        uf.recettesPrevuesAnneeRetenue = 0;
        uf.depensesPrevuesAnnees = 0;
        uf.coupeRase = 0;
        uf.valeurCoupeRase = 0;

        //if ((typeof(uf.peuplement) !== 'undefined') && uf.peuplement && uf.peuplement.ITs && (uf.peuplement.etatUtilisateur != SteresConstantes.etatINVALIDE)) {
        if ((typeof(uf.peuplement) !== 'undefined') && uf.peuplement && uf.peuplement.ITs /*&& (uf.peuplement.etatUtilisateur != SteresConstantes.etatINVALIDE)*/ ) { // l'état peut changer !!!
            var itChoisi = uf.peuplement.ITs.find(element => element.choisi);
            if (typeof(itChoisi) !== 'undefined') {
                this.calculerUFIt(propriete, uf, constantes, itChoisi);
            }
        }
        else {
            this.calculerDepenseUF(propriete, uf, constantes);
        }

    }

    /**
     * majDatasItChoisi
     *
     * Données générique stockées pour réaffichage
     */
    majDatasItChoisi(propriete, uf, it, UfPplDendrometrique, UfPplFinancier, UfPplCarbone) {
            var wiz = "bug";
        var surfaceBoisee = uf.peuplement.surfaceBoisee;
        var anneeCourante = UfPplFinancier.ppl.anneeCourante-1;
        var anneeRetenueCourante = anneeCourante + this.anneeRetenueCourante(UfPplDendrometrique.constantesSteres.moisBascule);
        uf.dendrometrieAnnee = UfPplDendrometrique.tabValeursCalculeeS[anneeCourante];
        uf.dendrometrieAnneeRetenue = UfPplDendrometrique.tabValeursCalculeeS[anneeRetenueCourante];
        if (typeof(uf.dendrometrieAnnee) === 'undefined') {
            wiz = "bag";
            return(wiz);
        }
        if (typeof(uf.dendrometrieAnneeRetenue) === 'undefined') {
            anneeRetenueCourante = anneeCourante;
            uf.dendrometrieAnneeRetenue = uf.dendrometrieAnnee;
        }
        if (uf.dendrometrieAnneeRetenue.eclaircieProspective) {
            const toto = 1;
        }
        uf.dendrometrieAnnee.anneeCourante = anneeCourante;
        uf.dendrometrieAnneeRetenue.anneeCourante = anneeRetenueCourante;
        var valeursCalculeeS = UfPplFinancier.tabValeursCalculeeS[anneeCourante];
        var valeursCalculeeSNplusUn = UfPplFinancier.tabValeursCalculeeS[anneeRetenueCourante];
        uf.depensesPrevuesAnnees = valeursCalculeeS.depenses * surfaceBoisee;
        var recetteEclaircie = valeursCalculeeS.recetteEclaircie;
        var recetteEclaircieNplusUn = valeursCalculeeSNplusUn.recetteEclaircie;
        uf.surfaceCoupeRaseAnnees = 0;
        var recetteCR = 0;
        if (it.coupeRase && (it.coupeRase == anneeCourante)) {
            recetteCR = valeursCalculeeS.recettePotentielleCR;
            //recetteCR = valeursCalculeeS.recettePotentielleCRavantEclaircie; // BC 2020/07 - pas de prise en compte de l'éclaircie proposée
            uf.surfaceCoupeRaseAnnees = surfaceBoisee;
        }
        var surfaceCoupeRaseAnneeRetenue = 0;
        var recetteCRNplusUn = 0;
        if (it.coupeRase && (it.coupeRase == anneeRetenueCourante)) {
            recetteCRNplusUn = valeursCalculeeSNplusUn.recettePotentielleCR;
            uf.surfaceCoupeRaseAnneeRetenue = surfaceBoisee;
        }
        uf.surfaceEclaircieAnnees = 0;
        if (uf.dendrometrieAnnee.eclaircieClass) {
            uf.surfaceEclaircieAnnees = surfaceBoisee;
        }
        uf.surfaceEclaircieAnneeRetenue = 0;
        if (uf.dendrometrieAnneeRetenue.eclaircieClass) {
            uf.surfaceEclaircieAnneeRetenue = surfaceBoisee;
        }
        uf.recettesPrevuesAnnees = (recetteEclaircie+recetteCR) * surfaceBoisee;
        uf.recettesPrevuesAnneeRetenue = (recetteEclaircieNplusUn+recetteCRNplusUn) * surfaceBoisee;
        if (uf.identifiant == "Test peuplement connu") {
            var toto = 1;
        }
        uf.valeurAttenteAnnees = valeursCalculeeS.valeurAttente * surfaceBoisee;
        uf.valeurSurPiedAnnees = valeursCalculeeS.recettePotentielleCR * surfaceBoisee;
        if ((typeof(valeursCalculeeS.recettePotentielleCRavantEclaircie) !== 'undefined') && valeursCalculeeS.recettePotentielleCRavantEclaircie) {
            uf.valeurSurPiedAnneesAvantEclaircie = valeursCalculeeS.recettePotentielleCRavantEclaircie * surfaceBoisee;
        }
        uf.coupeRase = it.coupeRase;
        uf.valeurCoupeRase = UfPplFinancier.tabValeursCalculeeS[it.coupeRase].recettePotentielleCR * surfaceBoisee;
        uf.eclaircies = UfPplDendrometrique.eclaircies;

        // NB : a priori pas de traitement particulier pour le carbone, on passe juste UfPplCarbone pour le stockage et la "compilation" des valeurs
        uf.valeursStockees = this.stockerValeurs(propriete, UfPplDendrometrique, UfPplFinancier, UfPplCarbone, anneeCourante, it.coupeRase, surfaceBoisee);


        return(wiz);
    }

    /**
     * calculerUFIT
     *
     * @return résultats financiers de l'IT
     */
    calculerUFIt(propriete, uf, constantes, it) {

        // peuplement
        UfPeuplement.compiler(uf, propriete, constantes);

        // dendrometrie
        var params = {"it":it, "calculOptimumFutur":true, "calculOptimumPasse":false, "ageMaxTest":UfPeuplement.essence.vieMax};
        UfPplDendrometrique.initialiserUfPeuplement(UfPeuplement, params);

        // et calculs/ratios financiers
        var paramsFi = {"ageMaxTest":UfPeuplement.essence.vieMax};
        UfPplFinancier.initialiserUfFinancier(UfPplDendrometrique, paramsFi);
        if (it.auto || (typeof(it.coupeRase) === 'undefined') || !it.coupeRase ) {
            it.coupeRase = it.optimumSteres;
        }

        // calculs carbone
        UfPplCarbone.initialiserUfCarbone(UfPplDendrometrique, constantes);

        // stockage des résultats sur l'IT choisi
        if (it.choisi) {
            this.majDatasItChoisi(propriete, uf, it, UfPplDendrometrique, UfPplFinancier, UfPplCarbone);
        }

        // TODO UF sans peuplement et/ou sans IT qui génère des dépenses
        var valeurSurPiedAnnees = uf.valeurSurPiedAnnees;
        var peuplement = uf.peuplement;
        if ((peuplement !== 'undefined') && peuplement) {
            var eclaircieVirtuelle = this.retrouverEclaircieVirtuelle(UfPplDendrometrique.anneeCourante, peuplement);
            if (eclaircieVirtuelle && (typeof(uf.valeurSurPiedAnneesAvantEclaircie) !== 'undefined')) {
                valeurSurPiedAnnees = uf.valeurSurPiedAnneesAvantEclaircie;
            }
        }


        //propriete.surface += uf.surface;
        propriete.surfaceEclaircieAnnees += uf.surfaceEclaircieAnnees;
        propriete.surfaceEclaircieAnneeRetenue += uf.surfaceEclaircieAnneeRetenue;
        propriete.surfaceCoupeRaseAnnees += uf.surfaceCoupeRaseAnnees;
        propriete.surfaceCoupeRaseAnneeRetenue += uf.surfaceCoupeRaseAnneeRetenue;
        propriete.valeurAttenteAnnees += uf.valeurAttenteAnnees;
        // propriete.valeurSurPiedAnnees += uf.valeurSurPiedAnnees; devient
        propriete.valeurSurPiedAnnees += valeurSurPiedAnnees;
        propriete.recettesPrevuesAnnees += uf.recettesPrevuesAnnees;
        propriete.recettesPrevuesAnneeRetenue += uf.recettesPrevuesAnneeRetenue;
        propriete.depensesPrevuesAnnees += uf.depensesPrevuesAnnees;
    }

    /**
     * stockerValeurs
     *
     * @return stockage des valeurs nécessaires aux synthèses
     */
    stockerValeurs(propriete, pplDendrometrique, pplFinancier, pplCarbone, anneeCourante, coupeRase, surfaceBoisee) {
        var tabValeurs = [];
/*
        if (coupeRase == 0) {
            coupeRase = Math.min(pplDendrometrique.ppl.essence.vieMax, pplDendrometrique.tabValeursCalculeeS.length-1);
        }
*/
        // BC 2022/09
        // on ne stocke plus uniquement jusqu'à la dernière coupe rase mais jusqu'à la fin de notre affichage des synthèses
        var cptMax = Math.max(SteresConst.SYNTHESE_COUPES_MAX, SteresConst.SYNTHESE_CARBONE_MAX, coupeRase);
        cptMax = Math.min(cptMax+anneeCourante, pplDendrometrique.ppl.essence.vieMax, pplDendrometrique.tabValeursCalculeeS.length-1);


        //for(var cptAges = anneeCourante; cptAges <= coupeRase; cptAges++) {
        for(var cptAges = anneeCourante; cptAges <= cptMax; cptAges++) {
            var dendrometrie = pplDendrometrique.tabValeursCalculeeS[cptAges];
            var financier = pplFinancier.tabValeursCalculeeS[cptAges];
            var carbone = pplCarbone.tabValeursCalculeeS[cptAges];
            if (typeof(financier) === 'undefined') {
                console.log("stockerValeurs pb financier : ", propriete.identifiant, cptAges);
            }
            var objStock = {};
            objStock.depenses = financier.depenses * surfaceBoisee;
            objStock.recettes = financier.recetteEclaircie;
            objStock.volumeUnitaire = dendrometrie.volumeUnitaire;
            objStock.volumeCoupe = 0;
            objStock.volumeUnitaireCoupe = 0;
            objStock.densiteEclaircie = 0;
            objStock.densiteAvantEclaircie = 0;
            if ((typeof(dendrometrie.densiteEclaircie) !== 'undefined') && dendrometrie.densiteEclaircie) {
                objStock.densiteEclaircie = dendrometrie.densiteEclaircie;
                objStock.densiteAvantEclaircie = dendrometrie.densiteAvantEclaircie;
                objStock.volumeCoupe = dendrometrie.volumeRecolteEclaircie * surfaceBoisee;
                objStock.volumeUnitaireCoupe = dendrometrie.volumeUnitaireEclaircie;
            }
            objStock.anneeDate = financier.anneeDate;
            objStock.surfaceBoisee = financier.surfaceBoisee;
            if (coupeRase && (coupeRase == cptAges)) { // BC 2022/09 - ajout du test "if (coupeRase)"
                objStock.recettes += financier.recettePotentielleCR;
                objStock.volumeCoupe = dendrometrie.volume * surfaceBoisee;
                objStock.volumeUnitaireCoupe = dendrometrie.volumeUnitaire;
            }
            objStock.recettes *= surfaceBoisee;

                // carbone
            if ((typeof(carbone) !== 'undefined')
                    && (typeof(carbone.carboneSequestreSol) !== 'undefined')
                    && (typeof(carbone.carboneSequestre) !== 'undefined')
                    && (typeof(carbone.carboneStocke) !== 'undefined')
                    && (typeof(carbone.carboneSubstitue) !== 'undefined')) {
                objStock.carboneSequestreSol = carbone.carboneSequestreSol * surfaceBoisee;
                objStock.carboneSequestre = carbone.carboneSequestre * surfaceBoisee;
                objStock.carboneStocke = carbone.carboneStocke * surfaceBoisee;
                objStock.carboneSubstitue = carbone.carboneSubstitue * surfaceBoisee;
            }
            else  {
                objStock.carboneSequestreSol = 0;
                objStock.carboneSequestre = 0;
                objStock.carboneStocke = 0;
                objStock.carboneSubstitue = 0;
                console.log("stockerValeurs pb carbone : ", propriete.identifiant, cptAges);
            }

            tabValeurs[cptAges] = objStock;

            this.stockerValeursPropriete(propriete, objStock);
        }

        return(tabValeurs);
    }

    stockerValeursPropriete(propriete, objStock) {
        var anneeDate = "annee"+objStock.anneeDate;
        if (typeof(propriete.valeursStockees[anneeDate]) === 'undefined') {
            propriete.valeursStockees[anneeDate] = {};
            propriete.valeursStockees[anneeDate].depenses = 0;
            propriete.valeursStockees[anneeDate].recettes = 0;
            propriete.valeursStockees[anneeDate].annee = objStock.anneeDate;
            // carbone
            propriete.valeursStockees[anneeDate].carboneSequestreSol = 0;
            propriete.valeursStockees[anneeDate].carboneSequestre = 0;
            propriete.valeursStockees[anneeDate].carboneStocke = 0;
            propriete.valeursStockees[anneeDate].carboneSubstitue = 0;
        }
        propriete.valeursStockees[anneeDate].depenses += objStock.depenses;
        propriete.valeursStockees[anneeDate].recettes += objStock.recettes;

        // carbone
        propriete.valeursStockees[anneeDate].carboneSequestreSol += objStock.carboneSequestreSol;
        propriete.valeursStockees[anneeDate].carboneSequestre += objStock.carboneSequestre;
        propriete.valeursStockees[anneeDate].carboneStocke += objStock.carboneStocke;
        propriete.valeursStockees[anneeDate].carboneSubstitue += objStock.carboneSubstitue;
    }

    /**
     * calculerProprieteITs
     *
     * @return résultats financiers de la propriété
     */
    calculerProprieteITs(propriete, UFs, constantes) {
        if (typeof(UFs) === 'undefined') {
            return;
        }
        //propriete.surface = 0;
        propriete.surfaceEclaircieAnnees = 0;
        propriete.surfaceEclaircieAnneeRetenue = 0;
        propriete.surfaceCoupeRaseAnnees = 0;
        propriete.surfaceCoupeRaseAnneeRetenue = 0;
        propriete.valeurAttenteAnnees = 0;
        propriete.valeurSurPiedAnnees = 0;
        propriete.recettesPrevuesAnnees = 0;
        propriete.recettesPrevuesAnneeRetenue = 0;
        propriete.depensesPrevuesAnnees = 0;
        propriete.valeursStockees = {};

var d0 = new Date();
var t0 = d0.getTime();
console.log("calculerProprieteITs, before boucle")
        UFs.forEach(uf => {
            this.calculerUFItChoisi(propriete, uf, constantes);
        })

var d1 = new Date();
var t1 = d1.getTime();
console.log("calculerProprieteITs, after boucle : ", t1-t0)

        //compilerCalculs
        //propriete.calculsTermines = true;
    }

    /*
    lecture directe dans base locale
    */
    lirePeuplement(id, constantes, ufId = 0) {
        return this.lirePeuplementInTable(id, ufId)
        .then(response => {
            if (typeof(response) === 'undefined') {
                return response // même retour que lirePeuplementUF précédemment
            }
            var peuplement = response;
            if (id == 0) {
                id = peuplement.id;
            }

            // init travauxJson (champ créé en cours de dev et potentiellement vide)
            if ((typeof(peuplement.travauxJson) === 'undefined') || (peuplement.travauxJson == "")) { // sécurisation modification base
                peuplement.travauxJson = this.initialiserTravaux(constantes);
            }
            else {
                peuplement.travauxJson = this.initialiserTravaux(constantes, JSON.parse(peuplement.travauxJson)); // s'il manque des travaux, on les rajoute
            }

                // l'année retenue peut être la précédente ( a priori si mois antérieur à juin)
            peuplement.anneeRetenue = this.anneeRetenuePeuplement(peuplement.dateInstallation, constantes);

            var laCulture = constantes.params.const_cultures["id"+peuplement.cultureId];
            if (typeof(laCulture) === 'undefined') {
                constantes.params.const_cultures.forEach(culture => {
                    constantes.params.const_cultures["id"+culture.id] = culture; // pour le F5
                    constantes.params.const_cultures[culture.code] = culture; // pour le F5
                    if (culture.id == peuplement.cultureId) {
                        laCulture = culture;
                    }
                })
            }
            peuplement.cultureCode = laCulture.code;
            // et au passage on demande les mesures de chaque peuplement
            return this.lireMesuresPeuplement(id)
            .then(responseMesures => {
                peuplement.mesures = responseMesures.sort(this.triMesures); // mesures triées !
                return this.lireEclairciesPeuplement(id)
                .then(responseEclaircies => {
                    peuplement.eclaircies = responseEclaircies.sort(this.triEclaircies); // eclaircies triées !
                    //retour.peuplement = peuplement;
                    return(this.lirePeuplementITs(peuplement));
                })
            })
        })
    }

    triMesures(x, y) {
       if (x.dateMesure < y.dateMesure) {
           return(-1)
       }
       if (x.dateMesure > y.dateMesure) {
           return(1)
       }

       return(0)
    }
    triEclaircies(x, y) {
       if (x.dateEclaircie < y.dateEclaircie) {
           return(-1)
       }
       if (x.dateEclaircie > y.dateEclaircie) {
           return(1)
       }

       return(0)
    }


    initialiserTravaux(constantes, tabTravauxBD = []) {
        var depenses = constantes.params.const_depenses;
        var tabTravaux = []
        depenses.forEach(depense => {
            if (!depense.annuelle) {
                var travailPeuplement = {};
                var travailPeuplementBD = tabTravauxBD.find( depenseOK => depenseOK.code === depense.code)
                // on initialise ou on rafraichit (selon cas) : on reprend les données "constantes" sauf le choix
                travailPeuplement.id = depense.id;
                travailPeuplement.code = depense.code;
                travailPeuplement.libelle = depense.libelle;
                travailPeuplement.anneeDebut = depense.anneeDebut;
                travailPeuplement.coutHectare = depense.coutHectare;
                travailPeuplement.choisi = depense.bonnePratiqueOuValide;
                if (typeof(travailPeuplementBD) !== 'undefined') {
                    travailPeuplement.choisi = travailPeuplementBD.choisi;  // on conserve l'état stocké en base
                }
                tabTravaux.push(travailPeuplement);
            }
        })
        return(JSON.stringify(tabTravaux));
    }

    /*
    * associer tous ses ITs à un peuplement
    */
    lirePeuplementITs(peuplement) {

        var retour = {};
        retour.peuplement = peuplement;

        var id = peuplement.id;
        return this.lireITsPeuplement(id)
        .then(responseITs => {
            peuplement.ITs = responseITs;
            var allPromisesEclaircies = [];
            var eclaircies = [];
            responseITs.forEach(IT => {
                if (IT) {
                    IT.eclaircies = []; // on initialise à vide
                    // et au passage on demande les éclaircies de chaque IT
                    var l = eclaircies.length
                    eclaircies[l] = ApiUser.lireEclairciesIT(IT.id)
                    allPromisesEclaircies.push(eclaircies[l])
                }
            })
            return Promise.all(allPromisesEclaircies).then(responseEclaircies => {
                responseEclaircies.forEach(eclairciesIT => {
                    this.associerEclairciesITs(eclairciesIT, peuplement.ITs);
                })
            return(retour);
            })
        })
    }

    /*
    // betonnage pour arrêt des accès direct et bugs F5
    */
    reinitTabConst(tab) {
        /*
        var tabOk = tab.slice()
        tabOk.forEach(tabElt => {
            tabOk["id"+tabElt.id] = tabElt;
            tabOk[tabElt.code] = tabElt;
        })
        return(tabOk);
        */
        tab.forEach(tabElt => {
            tab["id"+tabElt.id] = tabElt;
            tab[tabElt.code] = tabElt;
        })
    }
    rechercherItemTabConst(tab, id, code) {
        var item = null;
        if (id) {
            item =  tab["id"+id];
        }
        else {
            item =  tab[code];
        }
        return(item);
    }
    trouverItemTabConst(tab, id, code) {
        var item = this.rechercherItemTabConst(tab, id, code);
        if (typeof(item) !== 'undefined') {
            return(item);
        }
        this.reinitTabConst(tab);
        return(this.rechercherItemTabConst(tab, id, code)); // ce coup-ci, on est sûr de trouver !
    }

    /*
    ecriture -si possible - dans base distante ET directe dans base locale
    */
    async setPeuplement(peuplement, constantes) {

        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versions entre bd locale et distante)
        peuplement.version += 1;
        var nouveauPeuplement = false;
        if (peuplement.id == 0) {
            nouveauPeuplement = true;
        }


      // on commence par la sauvegarde en ligne pour obtenir un id
        return await addeoData.ecrireDatasPeuplement(peuplement)
        .then(response => {
            if (response && (response.retour == true)) {
                peuplement.id = response.data.peuplement.id
                peuplement.idLocal = false;
                if (nouveauPeuplement) {
                    peuplement.eclaircies = [];
                    peuplement.mesures = [];
                    peuplement.anneeRetenue = this.anneeRetenuePeuplement(peuplement.dateInstallation, constantes);
                    peuplement.cultureCode = this.trouverItemTabConst(constantes.params.const_cultures, peuplement.cultureId, "").code; // verrouille ci-dessous
                    // peuplement.cultureCode = constantes.params.const_cultures["id"+peuplement.cultureId].code;
                }

                console.log("setPeuplement, synchro OK")
                if (nouveauPeuplement && response.data.it) {
                    peuplement.it = response.data.it;
                    peuplement.ITs = response.data.ITs;
                    //peuplement.ITs[0].eclaircies = [];
                }
            }
            else {
                // pas problème particulier car pas de création de peuplement
                if (addeoData.testerModeForet(peuplement)) {
                    this.marquerUfModeForet(peuplement.modeForetUfId); // id UF stocké dans modeForetUfId pour peuplements, mesures et eclaircies
                }
                else { // le code ci-dessous me semble caduque ...
                    // pb sur stockage BD centralisée
                    // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                    this.ecrireSynchroUtilisateur(peuplement.userId, false)
                    console.log("setPeuplement, synchro PAS OK")
                }
            }

            // on met maintenant à jour en local
            return SteresDB.saveRowInTable(peuplement, 'user_ufpeuplement')
            .then(response => {
                if (nouveauPeuplement && peuplement.it) {
                    return SteresDB.saveRowInTable(peuplement.it, 'user_ufpeuplement_it')
                    .then(response => {
                        return(peuplement)
                    })
                }
                else {
                    return(peuplement)
                }
            })
        })
  }


    async setPeuplementDirect(peuplement) {
        return SteresDB.saveRowInTable(peuplement, 'user_ufpeuplement')
        .then(response => {
            return(response)
        })
    }

    /*
    pour chaque série d'item, on écrit d'abord en ligne puis dans la base locale
    */
    setEnfantsPeuplement(peuplement) {
        return this.setMesuresPeuplement(peuplement)
        .then(responseMesures => {
            peuplement.mesures = responseMesures;
            return this.setEclairciesPeuplement(peuplement)
            .then(responseEclaircies => {
                peuplement.eclaircies = responseEclaircies;
                return this.setITsPeuplement(peuplement)
                .then(responseITs => {
                    peuplement.ITs = responseITs;
                    return peuplement
                })
            })
        })
    }

    /*
    on écrit d'abord en ligne puis dans la base locale
    */
    setITsPeuplement(peuplement) {
            var allPromisesITs = [];
            peuplement.ITs.forEach(it => {
                if (it) {
                    it.peuplementId = peuplement.id;
                    allPromisesITs.push(addeoData.ecrireDatasIt(it))
                    }
                })
            return Promise.all(allPromisesITs)
            .then(responseITs => {
                // on construit le tableau
                var ITs = [];
                responseITs.forEach(retour => {
                    if (retour.data && retour.data.it) {
                        ITs.push(retour.data.it);
                    }
                })
                // on passe aux eclaircies
                    // avant tout, on reaffecte les éclaircies aux ITs car ecrireDatasIt(it) ne gère pas les éclairices des IT
                peuplement.ITs.forEach((it, index) => {
                    ITs[index].eclaircies = it.eclaircies
                })
                var allPromisesEclaircies = [];
                ITs.forEach(IT => {
                    IT.eclaircies.forEach(eclaircie => {
                        eclaircie.itId = IT.id;
                        allPromisesEclaircies.push(addeoData.ecrireDatasItEclaircie(eclaircie)); // toutes les éclaircies de tous les IT
                    })
                })
                return Promise.all(allPromisesEclaircies)
                .then(responseEclaircies => {
                    // on construit le tableau : un seul pour toutes les éclaircies de tous les ITs
                    var ITsEclaircies = [];
                    responseEclaircies.forEach(retour => {
                        if (retour.data && retour.data.eclaircie) {
                            ITsEclaircies.push(retour.data.eclaircie);
                        }
                    })

                    // on boucle sur les ITs et le tableau des eclaircies pour remplir les tableaux d'eclaircies de chaque IT
                    ITs.forEach(IT => {
                        IT.eclaircies = [];
                        ITsEclaircies.forEach(eclaircie => {
                            if (eclaircie.itId == IT.id) {
                                IT.eclaircies.push(eclaircie);
                            }
                        })
                    })

                    // on met maintenant à jour en local
                    SteresDB.saveRowsInTable(ITsEclaircies, 'user_ufpeuplement_iteclaircie') // toutes les eclaircies de tous les ITS, on n'attend pas la réponse ...
                    SteresDB.saveRowsInTable(ITs, 'user_ufpeuplement_it') // on n'attend pas la réponse ...
                    return ITs
                })
            })
    }

    /*
    on écrit d'abord en ligne puis dans la base locale
    */
    setMesuresPeuplement(peuplement) {
            var allPromisesMesures = [];
            peuplement.mesures.forEach(mesure => {
                if (mesure) {
                    mesure.peuplementId = peuplement.id;
                    allPromisesMesures.push(addeoData.ecrireDatasMesure(mesure))
                    }
                })
            return Promise.all(allPromisesMesures).then(responseMesures => {
                // on construit le tableau
                var mesures = [];
                responseMesures.forEach(retour => {
                    if (retour.data && retour.data.mesure) {
                        mesures.push(retour.data.mesure);
                    }
                })
                // on met maintenant à jour en local
                SteresDB.saveRowsInTable(mesures, 'user_ufpeuplementmesure') // on n'attend pas la réponse ...
                return mesures
            })
    }
    /*
    on écrit d'abord en ligne puis dans la base locale
    */
    setEclairciesPeuplement(peuplement) {
            var allPromisesEclaircies = [];
            //var eclaircies = [];
            peuplement.eclaircies.forEach(eclaircie => {
                if (eclaircie) {
                    eclaircie.peuplementId = peuplement.id;
                    allPromisesEclaircies.push(addeoData.ecrireDatasEclaircie(eclaircie))
                    }
                })
            return Promise.all(allPromisesEclaircies).then(responseEclaircies => {
                // on construit le tableau
                var eclaircies = [];
                responseEclaircies.forEach(retour => {
                    if (retour.data && retour.data.eclaircie) {
                        eclaircies.push(retour.data.eclaircie);
                    }
                })
                // on met maintenant à jour en local
                SteresDB.saveRowsInTable(eclaircies, 'user_ufpeuplementeclaircie') // on n'attend pas la réponse ...
                return eclaircies
            })
    }

    /*
    destruction directe dans base locale ET - si possible - dans base distante
    // TODO destruction peuplement et données associées
    */
    deletePeuplement(peuplement) {

        // on commence en local
        peuplement.mesures.forEach(mesure => {
            SteresDB.deleteRowInTable(mesure.id, 'user_ufpeuplementmesure');
        })
        peuplement.eclaircies.forEach(eclaircie => {
            SteresDB.deleteRowInTable(eclaircie.id, 'user_ufpeuplementeclaircie');
        })
        SteresDB.deleteRowInTable(peuplement.id, 'user_ufpeuplement');

        // NB : un peuplement contient des données enfants (mesures, eclaircies ...) qui sont stockées à part de la base
        // c'est l'API PHP qui se débrouille pour ça !!!
        return addeoData.deletePeuplement(peuplement.id)
        .then(response => {
            if (!response || (response.retour != true)) {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(peuplement.userId, false)
                console.log("deletePeuplement, synchro PAS OK")
            }
            return(response)
        })
  }

    validerAnneeMesure(id, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerAnnee(id, annee, peuplement, objRetour, apiTools, it, 1, 0))
    }
    validerAnneeEclaircie(id, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerAnnee(id, annee, peuplement, objRetour, apiTools, it, 0, 1))
    }

    // valable pour les peuplements et pour les ITs
    validerAnnee(id, annee, peuplement, objRetour, apiTools, it, isMesure, isEclaircie) {
        var pb = false;
        var pbAnnee = 0;

        if (typeof(peuplement) === 'undefined') {
            return(true); // sécurité
        }
        var objPere = peuplement;
        if (it) {
            objPere = it;
        }

        // même date sur une mesure ?
        if (typeof(objPere.mesures) !== 'undefined') {
            objPere.mesures.forEach(mesure => {
                var anneeRetenue = this.anneeRetenuePeuplement(mesure.dateMesure, objRetour.$store.getters.constantes);
                if (anneeRetenue == annee) {
                    if (!isMesure || (mesure.id != id)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                    }
                }
            })
            if (pb) {
                objRetour.errorDensite = "Il existe déjà une mesure pour l'année "+pbAnnee+". L'application n'autorise pas plusieurs mesures pour la même année, ni une mesure et une éclaircie : dans ce dernier cas, privilégiez l'éclaircie.";
                return(false);
            }
        }

        // même date sur une éclaircie ?
        if (typeof(objPere.eclaircies) !== 'undefined') {
            objPere.eclaircies.forEach(eclaircie => {
                var anneeRetenue = this.anneeRetenuePeuplement(eclaircie.dateEclaircie, objRetour.$store.getters.constantes);
                if (anneeRetenue == annee) {
                    if (!isEclaircie || (eclaircie.id != id)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                    }
                }
            })
            if (pb) {
                objRetour.errorDensite = "Il existe déjà une éclaircie pour l'année "+pbAnnee+". L'application n'autorise pas plusieurs éclaircies pour la même année, ni une mesure et une éclaircie : dans ce dernier cas, privilégiez l'éclaircie.";
                return(false);
            }
        }

        return(true);
    }

    validerHauteurMesure(id, hauteur, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerHauteur(id, hauteur, annee, peuplement, objRetour, apiTools, it, 1, 0))
    }
    validerHauteurEclaircie(id, hauteur, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerHauteur(id, hauteur, annee, peuplement, objRetour, apiTools, it, 0, 1))
    }
    // la hauteur de la mesure doit être cohérente avec celles des autres mesures
    // H(m-1) <= H(m) <= H(m+1)
    validerHauteur(id, hauteur, annee, peuplement, objRetour, apiTools, it, isMesure, isEclaircie) {

        var pb = false;
        var pbAnnee = 0;
        var pbValeur = 0;

        // remarque pas de bornage sur les hauteurs
        if (typeof(peuplement) === 'undefined') {
                return(true);
            }

        // toutes les mesures
        if (typeof(peuplement.mesures) !== 'undefined') {
            // puis toutes les (autres) mesures
            peuplement.mesures.forEach(mesure => {
                var anneeRetenue = this.anneeRetenuePeuplement(mesure.dateMesure, objRetour.$store.getters.constantes);
                if (((mesure.hauteurMoyenne < hauteur) && (anneeRetenue > annee)) || ((mesure.hauteurMoyenne > hauteur) && (anneeRetenue < annee))) {
                    if (!isMesure || (mesure.id != id) || (it)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                        pbValeur = mesure.hauteurMoyenne;
                    }
                }
            })
            if (pb) {
                objRetour.errorHauteur = "La hauteur moyenne calculée est incohérente avec celle de la mesure de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 2)+" m) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                return(false);
            }
        }

        // puis toutes les (autres) eclaircies
        if (typeof(peuplement.eclaircies) !== 'undefined') {
            peuplement.eclaircies.forEach(eclaircie => {
                var anneeRetenue = this.anneeRetenuePeuplement(eclaircie.dateEclaircie, objRetour.$store.getters.constantes);
                if (eclaircie.hauteurMoyenne && (((eclaircie.hauteurMoyenne < hauteur) && (anneeRetenue > annee)) || ((eclaircie.hauteurMoyenne > hauteur) && (anneeRetenue < annee))) ) {
                    if (!isEclaircie || (eclaircie.id != id) || (it)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                        pbValeur = eclaircie.hauteurMoyenne;
                    }
                }
            })
            if (pb) {
                objRetour.errorHauteur = "La hauteur moyenne calculée est incohérente avec celle de l'éclaircie de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 1)+" m) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                return(false);
            }
        }

        // remarque : pas de hauteur sur les éclaircies IT, donc pas de test

        return(true);
    }

    validerCirconferenceMesure(id, circonference, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerCirconference(id, circonference, annee, peuplement, objRetour, apiTools, it, 1, 0))
    }
    validerCirconferenceEclaircie(id, circonference, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerCirconference(id, circonference, annee, peuplement, objRetour, apiTools, it, 0, 1))
    }
    // la circonference de la mesure doit être cohérente avec celles des autres mesures
    // C(m-1) <= C(m) <= C(m+1)
    validerCirconference(id, circonference, annee, peuplement, objRetour, apiTools, it, isMesure, isEclaircie) {

        var pb = false;
        var pbAnnee = 0;
        var pbValeur = 0;

        // remarque pas de bornage sur les circonferences
        if (typeof(peuplement) === 'undefined') {
                return(true);
            }

        // toutes les mesures
        if (typeof(peuplement.mesures) !== 'undefined') {
            // puis toutes les (autres) mesures
            peuplement.mesures.forEach(mesure => {
                var anneeRetenue = this.anneeRetenuePeuplement(mesure.dateMesure, objRetour.$store.getters.constantes);
                if (((mesure.circonferenceQuadratique < circonference) && (anneeRetenue > annee)) || ((mesure.circonferenceQuadratique > circonference) && (anneeRetenue < annee))) {
                    if (!isMesure || (mesure.id != id) || (it)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                        pbValeur = mesure.circonferenceQuadratique;
                    }
                }
            })
            if (pb) {
                objRetour.errorCirconference = "La circonférence calculée est incohérente avec celle de la mesure de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 2)+" cm) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                return(false);
            }
        }

        // puis toutes les (autres) eclaircies
        if (typeof(peuplement.eclaircies) !== 'undefined') {
            peuplement.eclaircies.forEach(eclaircie => {
                var anneeRetenue = this.anneeRetenuePeuplement(eclaircie.dateEclaircie, objRetour.$store.getters.constantes);
                if (eclaircie.circonferenceQuadratique && (((eclaircie.circonferenceQuadratique < circonference) && (anneeRetenue > annee)) || ((eclaircie.circonferenceQuadratique > circonference) && (anneeRetenue < annee))) ) {
                    if (!isEclaircie || (eclaircie.id != id) || (it)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                        pbValeur = eclaircie.circonferenceQuadratique;
                    }
                }
            })
            if (pb) {
                objRetour.errorCirconference = "La circonférence calculée est incohérente avec celle de l'éclaircie de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 1)+" cm) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                return(false);
            }
        }

        // remarque : pas de circonférence sur les éclaircies IT, donc pas de test

        return(true);
    }


    validerDensiteMesure(id, densite, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerDensite(id, densite, annee, peuplement, objRetour, apiTools, it, 1, 0))
    }
    validerDensiteEclaircie(id, densite, annee, peuplement, objRetour, apiTools, it = null) {
        return(this.validerDensite(id, densite, annee, peuplement, objRetour, apiTools, it, 0, 1))
    }

    validerDensite(id, densite, annee, peuplement, objRetour, apiTools, it, isMesure, isEclaircie) {

        var pb = false;
        var pbAnnee = 0;
        var pbValeur = 0;
        var minDensite = 50;
        var maxDensite = 5000;


        if ((densite < minDensite) || (densite > maxDensite)) {
            objRetour.errorDensite = "Les densités saisies sur Steres doivent être comprises entre 50 et 5000 tiges par hectares.";
            return(false);
        }


        if (typeof(peuplement) === 'undefined') {
                return(true);
            }


        // on commence par l'installation
        if (peuplement.densiteInstallation && (densite > peuplement.densiteInstallation)) {
            objRetour.errorDensite = "La densité ne peut pas être plus forte que celle indiquée à l'installation ("+apiTools.arrondir(peuplement.densiteInstallation, 0)+" tiges/ha) ! Revoyez vos saisies.";
            return(false);
        }


        if (typeof(peuplement.mesures) !== 'undefined') {
            // puis toutes les (autres) mesures
            peuplement.mesures.forEach(mesure => {
                var anneeRetenue = this.anneeRetenuePeuplement(mesure.dateMesure, objRetour.$store.getters.constantes);
                if (((mesure.densiteMoyenne < densite) && (anneeRetenue < annee)) || ((mesure.densiteMoyenne > densite) && (anneeRetenue > annee))) {
                    if (!isMesure || (mesure.id != id) || (it)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                        pbValeur = mesure.densiteMoyenne;
                    }
                }
            })
            if (pb) {
                objRetour.errorDensite = "La densité moyenne calculée est incohérente avec celle de la mesure de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 0)+" tiges/ha) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                return(false);
            }
        }

        // puis toutes les (autres) eclaircies
        if (typeof(peuplement.eclaircies) !== 'undefined') {
            peuplement.eclaircies.forEach(eclaircie => {
                var anneeRetenue = this.anneeRetenuePeuplement(eclaircie.dateEclaircie, objRetour.$store.getters.constantes);
                if (((eclaircie.densiteMoyenne < densite) && (anneeRetenue < annee)) || ((eclaircie.densiteMoyenne > densite) && (anneeRetenue > annee))) {
                    if (!isEclaircie || (eclaircie.id != id) || (it)) {
                        pb = true;
                        pbAnnee = anneeRetenue;
                        pbValeur = eclaircie.densiteMoyenne;
                    }
                }
            })
            if (pb) {
                objRetour.errorDensite = "La densité moyenne calculée est incohérente avec celle de l'éclaircie de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 0)+" tiges/ha) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                return(false);
            }
        }

        if (it) {
            // puis toutes les (autres) eclaircies
            if (typeof(it.eclaircies) !== 'undefined') {
                it.eclaircies.forEach(eclaircie => {
                    var anneeRetenue = this.anneeRetenuePeuplement(eclaircie.dateEclaircie, objRetour.$store.getters.constantes);
                    if (((eclaircie.densiteMoyenne < densite) && (anneeRetenue < annee)) || ((eclaircie.densiteMoyenne > densite) && (anneeRetenue > annee))) {
                        if (!isEclaircie || (eclaircie.id != id) || (it)) {
                            pb = true;
                            pbAnnee = anneeRetenue;
                            pbValeur = eclaircie.densiteMoyenne;
                        }
                    }
                })
                if (pb) {
                    objRetour.errorDensite = "La densité moyenne calculée est incohérente avec celle de l'éclaircie de l'année "+pbAnnee+" ("+apiTools.arrondir(pbValeur, 0)+" tiges/ha) ! Revoyez vos saisies ou supprimez la mesure précédente.";
                    return(false);
                }
            }
        }

        return(true);
    }

    /*
    lecture directe dans base locale
    */
    lireMesure(id) {
        var retour = {};
        return SteresDB.readRowInTable(id, 'user_ufpeuplementmesure')
        .then(response => {
            retour.mesure = response;
            // quand on lit, on met directement les Json dans les tables associées
            retour.mesure.tabHauteurs = JSON.parse(retour.mesure.hauteurJson);
            retour.mesure.tabCirconferences = JSON.parse(retour.mesure.circonferenceQuadratiqueJson);
            // NB : le mécanisme des densités est différent puisqu'on y tocke un objet "complexe"
            return(retour);
        })
    }

    /*
    les mesures d'un peuplement
    */
    lireMesuresPeuplement(peuplementId) { // il n'y a MAINTENANT qu'un SEUL peuplement par UF
        return SteresDB.readInTable(peuplementId, 'peuplementId', 'user_ufpeuplementmesure', 'dateMesure', true); // on va trier par dateMesure DESC
    }

    /*
    ecriture -si possible - dans base distante ET directe dans base locale
    */
    async setMesure(mesure) {

        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versions entre bd locale et distante)
        mesure.version += 1;


      // on commence par la sauvegarde en ligne pour obtenir un id
        //await addeoData.ecrireDatasMesure(mesure)
        return addeoData.ecrireDatasMesure(mesure)
        .then(response => {
            if (response && (response.retour == true)) {
                mesure.id = response.data.mesure.id
                mesure.idLocal = false;
                console.log("setMesure, synchro OK")
            }
            else {
                // en cas de création de mesure, l'id ne sera pas valable
                if (addeoData.testerModeForet(mesure)) {
                    this.marquerUfModeForet(mesure.modeForetUfId); // id UF stocké dans modeForetUfId pour peuplements, mesures et eclaircies
                }
                else { // le code ci-dessous me semble caduque ...
                    // pb sur stockage BD centralisée
                    // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                    this.ecrireSynchroUtilisateur(mesure.userId, false)
                    console.log("setMesure, synchro PAS OK")
                }
            }

            // on met maintenant à jour en local
            return SteresDB.saveRowInTable(mesure, 'user_ufpeuplementmesure')
        })
  }


    /*
    destruction directe dans base locale ET - si possible - dans base distante
    */
    deleteMesure(mesure) {

        // on commence en local
        SteresDB.deleteRowInTable(mesure.id, 'user_ufpeuplementmesure');

        // c'est l'API PHP qui se débrouille pour ça !!!
        addeoData.deleteMesure(mesure)
        .then(response => {
            if (!response || (response.retour != true)) {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(mesure.userId, false)
                console.log("deleteMesure, synchro PAS OK")
            }
        })
  }


    /*
    lecture directe dans base locale
    */
    lireEclaircie(id) {
        var retour = {};
        return SteresDB.readRowInTable(id, 'user_ufpeuplementeclaircie')
        .then(response => {
            retour.eclaircie = response;
            // quand on lit, on met directement les Json dans les tables associées
            retour.eclaircie.tabHauteurs = JSON.parse(retour.eclaircie.hauteurJson);
            retour.eclaircie.tabCirconferences = JSON.parse(retour.eclaircie.circonferenceQuadratiqueJson);
            return(retour);
        })
    }


    /*
    les éclaircies d'un peuplement
    */
    lireEclairciesPeuplement(peuplementId) {
        return SteresDB.readInTable(peuplementId, 'peuplementId', 'user_ufpeuplementeclaircie', 'dateEclaircie', true) // on va trier par dateMesure DESC
        .then(response => {
            var eclaircies = response;

            eclaircies.forEach(eclaircie => {
                // quand on lit, on met directement les Json dans les tables associées
                eclaircie.tabHauteurs = JSON.parse(eclaircie.hauteurJson);
                eclaircie.tabCirconferences = JSON.parse(eclaircie.circonferenceQuadratiqueJson);
            })

            return(response);
        })
    }
    /*
    ecriture -si possible - dans base distante ET directe dans base locale
    */
    async setEclaircie(eclaircie) {

        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versions entre bd locale et distante)
        eclaircie.version += 1;


      // on commence par la sauvegarde en ligne pour obtenir un id
        return addeoData.ecrireDatasEclaircie(eclaircie)
//        await addeoData.ecrireDatasEclaircie(eclaircie)
        .then(response => {
            if (response && (response.retour == true)) {
                eclaircie.id = response.data.eclaircie.id
                eclaircie.idLocal = false;
                console.log("setEclaircie, synchro OK")
            }
            else {
                // en cas de création d'eclaircie, l'id ne sera pas valable
                if (addeoData.testerModeForet(eclaircie)) {
                    this.marquerUfModeForet(eclaircie.modeForetUfId); // id UF stocké dans modeForetUfId pour peuplements, mesures et eclaircies
                }
                else { // le code ci-dessous me semble caduque ...
                    // pb sur stockage BD centralisée
                    // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                    this.ecrireSynchroUtilisateur(eclaircie.userId, false);
                    console.log("setEclaircie, synchro PAS OK");
                }
            }

            // on met maintenant à jour en local
            return SteresDB.saveRowInTable(eclaircie, 'user_ufpeuplementeclaircie')
        })
  }

    /*
    destruction directe dans base locale ET - si possible - dans base distante
    */
    async deleteEclaircie(eclaircie) {

        // on commence en local
        return(SteresDB.deleteRowInTable(eclaircie.id, 'user_ufpeuplementeclaircie'))
        .then(response => {
            // c'est l'API PHP qui se débrouille pour ça !!!
            return addeoData.deleteEclaircie(eclaircie)
            .then(response => {
                if (!response || (response.retour != true)) {
                    // pb sur stockage BD centralisée
                    // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                    this.ecrireSynchroUtilisateur(eclaircie.userId, false)
                    console.log("deleteEclaircie, synchro PAS OK")
                }
                return(response)
            })
        })
  }

    /*
    les IT d'un peuplement
    */
    lireITsPeuplement(peuplementId) {
        return SteresDB.readInTable(peuplementId, 'peuplementId', 'user_ufpeuplement_it', 'id', true); // on va trier par dateMesure DESC
    }

    associerEclairciesITs(tabEclaircies, tabITs) {
        if (!tabEclaircies || !tabEclaircies.length) {
            return;
        }
        var itId = tabEclaircies[0].itId;
        tabITs.forEach(IT => {
            if (IT.id == itId) {
                IT.eclaircies = tabEclaircies.sort(this.triEclaircies); // eclaircies triées !
                //IT.eclaircies.push = tabEclaircies;
            }
        })
    }
/*
        associerEclairciesPeuplement(tabEclaircies, tabUFs) {
            if (!tabEclaircies || !tabEclaircies.length) {
                return;
            }
            var peuplementId = tabEclaircies[0].peuplementId;
            tabUFs.forEach(uf => {
                if (uf.peuplement && (uf.peuplement.id == peuplementId)) {
                    uf.peuplement.eclaircies = tabEclaircies;
                }
            })
        },
*/

    /*
    ecriture -si possible - dans base distante ET directe dans base locale
    */
    async setIt(it) {

        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versions entre bd locale et distante)
        it.version += 1;


      // on commence par la sauvegarde en ligne pour obtenir un id
        return await addeoData.ecrireDatasIt(it)
        .then(response => {
            if (response && (response.retour == true)) {
                it.id = response.data.it.id
                it.idLocal = false;
                console.log("setIT, synchro OK")
            }
            else {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(it.userId, false)
                console.log("setIT, synchro PAS OK")
            }

            // on met maintenant à jour en local
            return SteresDB.saveRowInTable(it, 'user_ufpeuplement_it')
            .then(response => {
                return(it.id)
            })
        })
  }



    /*
    destruction directe dans base locale ET - si possible - dans base distante
    // TODO destruction peuplement et données associées
    */
    deleteIt(it) {

        // on commence en local
        if (it.eclaircies) {
            it.eclaircies.forEach(eclaircie => {
                SteresDB.deleteRowInTable(eclaircie.id, 'user_ufpeuplement_iteclaircie');
            })
        }
        SteresDB.deleteRowInTable(it.id, 'user_ufpeuplement_it');

        // NB : un peuplement contient des données enfants (eclaircies ...) qui sont stockées à part de la base
        // c'est l'API PHP qui se débrouille pour ça !!!
        return addeoData.deleteIt(it.id)
        .then(response => {
            if (!response || (response.retour != true)) {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(it.userId, false)
                console.log("deleteIt, synchro PAS OK")
            }
            return(response)
        })
  }

    lireItEclaircie(id) {
        var retour = {};
        return SteresDB.readRowInTable(id, 'user_ufpeuplement_iteclaircie')
        .then(response => {
            retour.eclaircie = response;
            // quand on lit, on met directement les Json dans les tables associées
            retour.eclaircie.tabHauteurs = JSON.parse(retour.eclaircie.hauteurJson);
            retour.eclaircie.tabCirconferences = JSON.parse(retour.eclaircie.circonferenceQuadratiqueJson);
            return(retour);
        })
    }

    /*
    ecriture -si possible - dans base distante ET directe dans base locale
    */
    async setItEclaircie(eclaircie) {

        // à chaque sauvegarde, on incrémente la version (possibilité d'incohérence de versions entre bd locale et distante)
        eclaircie.version += 1;


      // on commence par la sauvegarde en ligne pour obtenir un id
        return addeoData.ecrireDatasItEclaircie(eclaircie)
        .then(response => {
            if (response && (response.retour == true)) {
                eclaircie.id = response.data.eclaircie.id
                eclaircie.idLocal = false;
                console.log("setEclaircie, synchro OK")
            }
            else {
                // pb sur stockage BD centralisée
                // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                this.ecrireSynchroUtilisateur(eclaircie.userId, false)
                console.log("setItEclaircie, synchro PAS OK")
            }

            // on met maintenant à jour en local
            return SteresDB.saveRowInTable(eclaircie, 'user_ufpeuplement_iteclaircie')
            .then(responseLocale => {
                //return eclaircie
                return responseLocale
            })
        })
  }


    /*
    destruction directe dans base locale ET - si possible - dans base distante
    */
    async deleteItEclaircie(eclaircie) {

        // on commence en local
        return(SteresDB.deleteRowInTable(eclaircie.id, 'user_ufpeuplement_iteclaircie'))
        .then(response => {
            // c'est l'API PHP qui se débrouille pour ça !!!
            return addeoData.deleteItEclaircie(eclaircie.id)
            .then(response => {
                if (!response || (response.retour != true)) {
                    // pb sur stockage BD centralisée
                    // on stocke que la synchro n'est pas bonne : id local est pourri (idLocal à true - géré par le formulaire)
                    this.ecrireSynchroUtilisateur(eclaircie.userId, false)
                    console.log("deleteEclaircie, synchro PAS OK")
                }
                return(response)
            })
        })
  }




    async setLocalSynchroDirect(localSynchro, moisPrecedent) {
        SteresDB.purgeRowInTableSynchro(moisPrecedent); // on n'attends pas
        console.log("setLocalSynchroDirect - purge : ", moisPrecedent);

        return SteresDB.saveRowInTable(localSynchro, 'local_synchro')
        .then(response => {
            return(response)
        })
    }

    // tous les utilisateurs : TMP
    getLocalSynchro() {
        return SteresDB.readAllFromTable('local_synchro')
    }

}

export const ApiUser = new ApiUserObj()
