<template>
    <div class="containerLog">

        <div v-if="!$parent.connexionUtilisateurValide">
            Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="propriete" v-if="$parent.connexionUtilisateurValide && constantesReady && isReady" class="sousBandeauInterne" v-bind:class="{lectureSeule : $store.getters.lectureSeule}">


            <div id="infosAdministratives" class="sectionAffichage bandeauInterne">
                <div class="wrapper">
                    <div class="conteneur">

                        <h1 v-if="laPropriete" class="blocACompleter">{{laPropriete.identifiant}}</h1>
                        <span v-if="!$store.getters.lectureSeule && !$store.getters.modeForet" class="complementBloc menuContextuel">
                            <span class="lien" @click.stop="menuOuvert=true" ><i class="fas fa-pencil"></i></span>

                            <transition name="fade" :duration="1000">
                                <v-form v-if="menuOuvert">
                                    <span class="lien droiteAbs" @click.stop="menuOuvert=false"><i class="fas fa-window-close"></i></span>
                                    <v-container fluid>
                                        <ul>
                                            <li v-if="!laPropriete.saisieHorsLigneId" @click.stop="editerPropriete(laPropriete)">Modifier la propriété</li>
                                            <li v-if="!laPropriete.saisieHorsLigneId" @click.stop="supprimerProprieteouPas()">Supprimer la propriété</li>
                                            <li v-if="1 && !laPropriete.saisieHorsLigneId && ufPremierPeuplement" @click.stop="dialogForet=true;">Passer en "Mode Forêt"</li>
                                        </ul>
                                    </v-container>
                                </v-form>
                            </transition>
                        </span>
                        <span v-if="$store.getters.lectureSeule && !$store.getters.modeForet && (laPropriete.saisieHorsLigneId == $store.getters.utilisateurCourantId)" class="complementBloc menuSecours menuContextuel">
                            <span class="lien" @click.stop="dialogForet2=true" ><i class="fas fa-ambulance" aria-hidden="true"></i></span>
                        </span>


                        <div class="clear" ></div>
                        <div v-if="laPropriete" class="infosGlobal" >
                            <div class="proprieteInfos gauche clear" >
                                <span class="gras">
                                {{laPropriete.adresse}}<br/>
                                <span class="majuscules">{{laPropriete.codePostal}} {{laPropriete.libelleCommune}}</span></span><br/>
                                {{laPropriete.adresseMail}}<br/><br/>
                                SIRET n° {{laPropriete.siret}}
                            </div>
                            <div class="proprietaireInfos gauche" >
                                <h3>propriétaire<span v-if="leProprietaireId == gestionnaireId"> / gestionnaire</span></h3>
                                <span class="gras">{{laPropriete.proprietaire.prenom}} {{laPropriete.proprietaire.nom}} </span><br/>
                                {{laPropriete.proprietaire.adresseMail}}
                            </div>
                            <div class="gestionnaireInfos gauche" v-if="leProprietaireId != gestionnaireId">
                                <h3>gestionnaire</h3>
                                <span class="gras">{{laPropriete.gestionnaire.prenom}} {{laPropriete.gestionnaire.nom}} </span><br/>
                                {{laPropriete.gestionnaire.adresseMail}}
                            </div>
                        </div>
                        <div class="clear" ></div>
                    </div>
                </div>
            </div>

            <div id="infosEconomiques" class="sectionAffichage">

                <transition name="fade" :duration="5000" >
                    <div class="messageInformation" v-if="messageInformation">
                        {{messageInformation}}
                    </div>
                </transition>

                <div class="wrapper">
                    <div class="conteneur">
                        <h2 class="blocACompleter">Données économiques</h2>
                        <span class="complementBloc masquerDemasquer"   @click.stop="ecoOuvert=false" v-if="ecoOuvert">
                            <i class="fas fa-caret-down"></i><span class="facultatif640">Masquer</span>
                        </span>
                        <span class="complementBloc masquerDemasquer"   @click.stop="ecoOuvert=true" v-if="ecoOuvert==false">
                            <i class="fas fa-caret-up"></i><span class="facultatif640">Montrer</span>
                        </span>

                        <transition name="fadeHeight">
                            <div v-if="ecoOuvert">
                                <div>
                                    <div class="gauche deuxBlocs"><span class="enGros">{{valeurSurPiedPrevuesAnnees}}</span>Valeur sur pied en {{anneeCourante}}</div>
                                    <div class="gauche deuxBlocs"><span class="enGros">{{valeurAttentePrevuesAnnees}}</span>Valeur d'attente en {{anneeCourante}}</div>
                                    <div class="clear"></div>
                                </div>
                                <div>
                                    <div class="gauche deuxBlocs"><span class="enGros">{{surfaceEclairciePrevuesAnnees}}</span>Surface à éclaircir<br />en {{affichageAnnee}} (octobre à mars)</div>
                                    <div class="gauche deuxBlocs"><span class="enGros">{{surfaceCoupeRasePrevuesAnnees}}</span>Surface de coupe rase<br />en {{affichageAnnee}}</div>
                                    <div class="clear"></div>
                                </div>

                                <div class="clear"></div>
                                <div class="ecoOuvert">
                                    <div class="alerte gauche deuxBlocs">
                                        <div class="gauche">
                                            <i class="fas fa-info"></i>
                                            <span v-if="!existeTarifsSpecifiques">Cette propriété utilise les tarifs STERES définis par défaut</span>
                                            <span v-if="existeTarifsSpecifiques">Cette propriété utilise des tarifs spécifiques</span>
                                        </div>
                                        <v-btn v-if="!$store.getters.lectureSeule && !$store.getters.modeForet" class="boutonFonctionObjet droite prioritaire" type="button" @click.stop="editerTarifsPropriete()">Modifier</v-btn>
                                        <div class="clear"></div>
                                    </div>
                                    <div  id="choixSynthese" class="alerte gauche deuxBlocs facultatif640">
                                        <div class="gauche">
                                            <i class="fas fa-plus"></i>
                                            <span>Afficher une synthèse </span>
                                        </div>
                                        <div class="droite">
                                            <v-select
                                                v-model="syntheseCourante"
                                                item-text="identifiant"
                                                item-value="id"
                                                :items="listeSyntheses"
                                                label="Synthèse"
                                                :return-object="true"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
<div id="syntheses">
                    <synthese-1 :data='proprieteEtUFs' v-if="syntheseCourante && (syntheseCourante.id == 1)"></synthese-1>
                    <synthese-2 :data='proprieteEtUFs' v-if="syntheseCourante && (syntheseCourante.id == 2)"></synthese-2>
                    <synthese-3 :data='proprieteEtUFs' v-if="syntheseCourante && (syntheseCourante.id == 3)"></synthese-3>
                    <synthese-4 :data='proprieteEtUFs' v-if="syntheseCourante && (syntheseCourante.id == 4)"></synthese-4>
                    <synthese-5 :data='proprieteEtUFs' v-if="syntheseCourante && (syntheseCourante.id == 5)"></synthese-5>
</div>
                            </div>
                        </transition>
                    </div>

                </div>
            </div>

            <div id="infosForestieres" class="sectionAffichage">
                <div class="wrapper">
                    <div class="conteneur conteneurTable">

                        <h2 class="titreListe" ><span v-if="lesUFs">{{lesUFs.length}} </span>Unités Forestières <span v-if="lesUFs && lesTotauxUFs">: {{formaterHectares(lesTotauxUFs.surface)}}</span> <span class="cacher">cadastraux</span>
                            <span class="plusPetit avertissement" v-if="leNbUFs > lesUFs.length">
                                <span v-if="leNbUFs > leNbUFsLues"> (Chargement en cours de {{leNbUFsLues}} / {{nb_uf}} UFs - Merci de patienter en attendant le chargement complet</span>
                                <v-btn v-if="0 && (leNbUFs == leNbUFsLues)" class="boutonFonctionObjet prioritaire" type="button" @click.stop="rafraichir()">Afficher {{nb_uf}} UFs</v-btn>
                            </span>
                        </h2>

                        <table v-if="lesUFs" class="liste">

                            <tr class="sansBord">
                                <td class="centrer">
                                    <v-btn v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && (lesUFs.length < maxUFsPropriete) && !limitationUFs" class="boutonFonctionObjet prioritaire" type="button" @click.stop="creerUF">Ajouter une UF</v-btn>
                                </td>
                                <td colspan="3" class="centrer">
                                    <v-btn v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && (lesUFs.length < maxUFsPropriete) && !existeLimitationUFs" class="boutonFonctionObjet prioritaire" type="button" @click.stop="importerUFs">Importer des UF</v-btn>
                                    <span class="infoBlocageBouton" v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && (lesUFs.length < maxUFsPropriete) && existeLimitationUFs && !limitationUFs" >Import d'UFs désactivée en mode "ESSAI". Pour plus d'informations, merci de contacter l'équipe STERES (contact@steres.fr).</span>
                                </td>
                                <td colspan="7">&nbsp;
                                    <span v-if="lesUFs.length >= maxUFsPropriete && !limitationUFs" class="avertissement">Attention, le nombre d'UF d'une propriété est pour le moment limité à 1000.<br/>Vous ne pouvez plus ajouter ou importer d'UF.</span>
                                    <span v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && limitationUFs" class="avertissement">Vous êtes en mode "ESSAI" et avez atteint le nombre maximum d'Unités Forestières pour cette propriété. Pour pouvoir créer ou importer de nouvelles Unités Forestières, merci de contacter l'équipe STERES (contact@steres.fr).</span>
                                </td>
                            </tr>

                            <tr class="header"><th class="centrer">Unité Forestière</th><th class="crayon">&nbsp;</th><th>Année du peuplement</th><th>Classe<br/>d’âge</th><th>Surface<br/>boisée</th><th v-if="avantBascule">Recettes<br/>{{affichageAnnee}}</th><th v-if="!avantBascule">Recettes réelles<br/>{{affichageAnneePred}}</th><th v-if="!avantBascule">Recettes prévues<br/>{{affichageAnnee}}</th><th>Dépenses<br/>prévues {{anneeCourante}}</th><th>Valeur<br/>sur pied</th><th>Valeur<br/>d'attente</th><th>Coupe<br/>rase</th><th class=" etat">Etat</th></tr>
                            <tr class="sousHeader">
                                <th class="centrer">Totaux</th>
                                <th colspan="3">&nbsp;</th>
                                <th>{{formaterHectaresTab(lesTotauxUFs.surfaceBoisee)}}</th>
                                <th  v-if="avantBascule">{{formaterEuros(lesTotauxUFs.recettesPrevuesAnnees)}}</th>
                                <th  v-if="!avantBascule">{{formaterEuros(lesTotauxUFs.recettesPrevuesAnnees)}}</th>
                                <th  v-if="!avantBascule">{{formaterEuros(lesTotauxUFs.recettesPrevuesAnneeRetenue)}}</th>
                                <th>{{formaterEuros(lesTotauxUFs.depensesPrevuesAnnees)}}</th>
                                <th>{{formaterEuros(lesTotauxUFs.valeurSurPiedAnnees)}}</th>
                                <th>{{formaterEuros(lesTotauxUFs.valeurAttenteAnnees)}}</th>
                                <th>{{formaterEuros(lesTotauxUFs.valeurCoupeRase)}}</th>
                                <th class="">&nbsp;</th>
                            </tr>

                            <tr class="sansBord filtres">
                                <td class="filtrage">
                                    <v-text-field
                                        label="Nom de l'UF"
                                        v-model="trisFiltres.testIdentifiant"
                                    ></v-text-field>
                                    <v-checkbox
                                        v-model="trisFiltres.filtrerIdentifiant"
                                    ></v-checkbox>
                                    <!--
                                    <v-radio-group v-model="filtrerIdentifiant">
                                        <v-radio
                                          ></v-radio>
                                    </v-radio-group>
                                    -->
                                </td>
                                <td colspan="2" class="filtrage centrageVert">
                                    <v-text-field
                                        label="Année"
                                        v-model="trisFiltres.testAnnee"
                                        class="petit"
                                        type="number"
                                        @keydown="filtrerNombresEntiersPositifs"
                                        min=50
                                        max=5000
                                    ></v-text-field>
                                    <div class="conteneurPourRadio">
                                        <div class="pourRadio">&nbsp;<span class="operateur">&#x3C;</span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerAnneeAvant"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio">&nbsp;<span class="operateur">=</span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerAnnee"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio">&nbsp;<span class="operateur">></span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerAnneeApres"
                                            ></v-checkbox>
                                        </div>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </td>
                                <td class="filtrage">
                                    <v-select
                                          :items="classesAge"
                                          item-text="libelle"
                                          item-value="age"
                                          label="Age"
                                        v-model="trisFiltres.testClasseAge"
                                    ></v-select>
                                    <v-checkbox
                                        v-model="trisFiltres.filtrerClasseAge"
                                    ></v-checkbox>
                                </td>

                                <td class="centrageVert filtrage">
                                    <v-text-field
                                        label="Surface"
                                        v-model="trisFiltres.testSurfaceBoisee"
                                        class="petit"
                                        type="number"
                                        @keydown="filtrerNombresEntiersPositifs"
                                        min=0
                                        max=99999
                                    ></v-text-field>
                                    <div class="conteneurPourRadio">
                                        <div class="pourRadio">&nbsp;<span class="operateur">&#x3C;</span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerSurfaceBoiseeInf"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio">&nbsp;<span class="operateur">=</span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerSurfaceBoisee"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio">&nbsp;<span class="operateur">></span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerSurfaceBoiseeSup"
                                            ></v-checkbox>
                                        </div>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </td>
                                <td colspan="4">
                                </td>
                                <td class="centrageVert filtrage">
                                    <v-text-field
                                        label="Année"
                                        v-model="trisFiltres.testCoupeRase"
                                        class="petit"
                                        type="number"
                                        @keydown="filtrerNombresEntiersPositifs"
                                        min=50
                                        max=5000
                                    ></v-text-field>
                                    <div class="conteneurPourRadio">
                                        <div class="pourRadio">&nbsp;<span class="operateur">&#x3C;</span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerCoupeRaseAvant"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio">&nbsp;<span class="operateur">=</span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerCoupeRase"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio">&nbsp;<span class="operateur">></span>
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerCoupeRaseApres"
                                            ></v-checkbox>
                                        </div>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </td>
                                <td class="centrageVert filtrage">
                                    <div class="conteneurPourRadio">
                                        <div class="pourRadio"><span class="labelEtat etatVALIDE">Valide</span>&nbsp;
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerEtatVALIDE"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio"><span class="labelEtat etatAVALIDER">A valider</span>&nbsp;
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerEtatAVALIDER"
                                            ></v-checkbox>
                                        </div>
                                        <div class="pourRadio"><span class="labelEtat etatINVALIDE">Invalide</span>&nbsp;
                                            <v-checkbox
                                                v-model="trisFiltres.filtrerEtatINVALIDE"
                                            ></v-checkbox>
                                        </div>
                                        <div class="clear">
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr class="sansBord listeVide" v-if="listeVide">
                                <td colspan="2">
                                </td>
                                <td colspan="3">
                                    Pas d'UF pour ce filtre
                                </td>
                                <td colspan="7">
                                </td>
                            </tr>

                            <tr class="sansBord navigationListe haut" v-if="!listeVide">
                                <td colspan="3">
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsPremierAffichage" class="fa fa-fast-backward actif" aria-hidden="true" @click.stop="reculerDebutListe"></i>
                                    <i v-if="isUFsPremierAffichage" class="fa fa-fast-backward inactif" aria-hidden="true"></i>
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsPremierAffichage" class="fa fa-backward actif" aria-hidden="true" @click.stop="reculerListe"></i>
                                    <i v-if="isUFsPremierAffichage" class="fa fa-backward inactif" aria-hidden="true"></i>
                                </td>
                                <td class="centrer">
                                    {{premiereUFsAffichee}} - {{derniereUFsAffichee}}
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsDernierAffichage" class="fa fa-forward actif" aria-hidden="true" @click.stop="avancerListe"></i>
                                    <i v-if="isUFsDernierAffichage" class="fa fa-forward inactif" aria-hidden="true"></i>
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsDernierAffichage" class="fa fa-fast-forward actif" aria-hidden="true" @click.stop="avancerFinListe"></i>
                                    <i v-if="isUFsDernierAffichage" class="fa fa-fast-forward inactif" aria-hidden="true"></i>
                                </td>
                                <td colspan="4">
                                </td>
                            </tr>

                            <tr class="sousTotaux">
                                <th class="centrer">Sous-total (page)</th>
                                <th colspan="3">&nbsp;</th>
                                <th class="nombre">{{formaterHectaresTab(lesTotauxPartielsUFs.surfaceBoisee)}}</th>
                                <th  v-if="avantBascule">{{formaterEuros(lesTotauxPartielsUFs.recettesPrevuesAnnees)}}</th>
                                <th  v-if="!avantBascule">{{formaterEuros(lesTotauxPartielsUFs.recettesPrevuesAnnees)}}</th>
                                <th  v-if="!avantBascule">{{formaterEuros(lesTotauxPartielsUFs.recettesPrevuesAnneeRetenue)}}</th>
                                <th>{{formaterEuros(lesTotauxPartielsUFs.depensesPrevuesAnnees)}}</th>
                                <th>{{formaterEuros(lesTotauxPartielsUFs.valeurSurPiedAnnees)}}</th>
                                <th>{{formaterEuros(lesTotauxPartielsUFs.valeurAttenteAnnees)}}</th>
                                <th>{{formaterEuros(lesTotauxPartielsUFs.valeurCoupeRase)}}</th>
                                <th class="">&nbsp;</th>
                            </tr>

                            <tr class="actionTris">
                                <th class="centrer"  @click.stop="retrier('triUF')" v-bind:class="{actif : trisFiltres.triUF}">
                                    <i v-if="trisFiltres.triUF < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUF >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i></th>
                                <th>&nbsp;</th>
                                <th class="nombre"  @click.stop="retrier('triPeuplementAnnee')" v-bind:class="{actif : trisFiltres.triPeuplementAnnee}">
                                    <i v-if="trisFiltres.triPeuplementAnnee < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triPeuplementAnnee >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triPeuplementAge')" v-bind:class="{actif : trisFiltres.triPeuplementAge}">
                                    <i v-if="trisFiltres.triPeuplementAge < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triPeuplementAge >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triPeuplementSurfaceBoisee')" v-bind:class="{actif : trisFiltres.triPeuplementSurfaceBoisee}">
                                    <i v-if="trisFiltres.triPeuplementSurfaceBoisee < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triPeuplementSurfaceBoisee >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triUfRecettesPrevuesAnnees')" v-bind:class="{actif : trisFiltres.triUfRecettesPrevuesAnnees}">
                                    <i v-if="trisFiltres.triUfRecettesPrevuesAnnees < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUfRecettesPrevuesAnnees >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th  v-if="!avantBascule" class="nombre"  @click.stop="retrier('triUfrecettesPrevuesAnneeRetenue')" v-bind:class="{actif : trisFiltres.triUfrecettesPrevuesAnneeRetenue}">
                                    <i v-if="trisFiltres.triUfrecettesPrevuesAnneeRetenue < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUfrecettesPrevuesAnneeRetenue >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triUfDepensesPrevuesAnnees')" v-bind:class="{actif : trisFiltres.triUfDepensesPrevuesAnnees}">
                                    <i v-if="trisFiltres.triUfDepensesPrevuesAnnees < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUfDepensesPrevuesAnnees >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triUfValeurReelleSurPiedAnnees')" v-bind:class="{actif : trisFiltres.triUfValeurReelleSurPiedAnnees}">
                                    <i v-if="trisFiltres.triUfValeurReelleSurPiedAnnees < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUfValeurReelleSurPiedAnnees >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triUfValeurAttenteAnnees')" v-bind:class="{actif : trisFiltres.triUfValeurAttenteAnnees}">
                                    <i v-if="trisFiltres.triUfValeurAttenteAnnees < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUfValeurAttenteAnnees >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triUfCoupeRase')" v-bind:class="{actif : trisFiltres.triUfCoupeRase}">
                                    <i v-if="trisFiltres.triUfCoupeRase < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triUfCoupeRase >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                                <th class="nombre"  @click.stop="retrier('triPeuplementEtatUtilisateur')" v-bind:class="{actif : trisFiltres.triPeuplementEtatUtilisateur}">
                                    <i v-if="trisFiltres.triPeuplementEtatUtilisateur < 0"  class="fa fa-caret-down svg" aria-hidden="true"></i>
                                    <i v-if="trisFiltres.triPeuplementEtatUtilisateur >= 0"  class="fa fa-caret-up svg" aria-hidden="true"></i>
                                </th>
                            </tr>

                            <tr :id="tabIds[numUF]" class="UFsList liens"  v-for="(laUF, numUF) in lesUFsList" :key="numUF"
                                v-bind:class="{etatAVALIDER : laUF.peuplement && laUF.peuplement.etatUtilisateur == etatAVALIDER, etatVALIDE : laUF.peuplement && laUF.peuplement.etatUtilisateur == etatVALIDE, etatINVALIDE : laUF.peuplement && laUF.peuplement.etatUtilisateur == etatINVALIDE}">
                                <td class="lien centrer lienUnique uf" @click.stop="ouvrirUF(laUF)">
                                    <!-- <img v-if="!$store.getters.lectureSeule" class="iconeFct" alt="Editer l'UF'" src="@/assets/img/crayon.svg" /> -->
                                    <i v-if="!$store.getters.lectureSeule"  class="fa fa-pencil svg" aria-hidden="true"></i>
                                    <span>
                                        {{ laUF.identifiant }}
                                    </span>
                                </td>

                                <td v-if="!laUF.peuplement" colspan="5" class=" aGauche">
                                    <v-btn v-if="!$store.getters.lectureSeule && !$store.getters.modeForet" class="boutonFonctionObjet prioritaire" type="button" @click.stop="creerPeuplement(laUF)">Ajouter un peuplement</v-btn>
                                </td>
                                <td v-if="!laUF.peuplement && !avantBascule">
                                    &nbsp;
                                </td>
                                <td v-if="!laUF.peuplement" class="  nombre"  @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.depensesPrevuesAnnees)}} </td>
                                <td v-if="!laUF.peuplement" colspan="4" class=" aGauche">
                                    &nbsp;
                                </td>
                                <td v-if="laUF.peuplement" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" class="liens lien nombre crayon" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)">
                                    <i v-if="!$store.getters.lectureSeule"  class="fa fa-pencil svg" aria-hidden="true"></i>
                                </td>
                                <td v-if="laUF.peuplement" class="liens lien" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)">
                                    {{ laUF.peuplement.anneeRetenue }}
                                </td>
                                <td v-if="laUF.peuplement" class="liens lien classeAge" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)">
                                    {{ laUF.peuplement.classeAge }}
                                </td>
                                <td v-if="laUF.peuplement" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterHectaresTab(laUF.peuplement.surfaceBoisee) }}</td>
                                <td v-if="laUF.peuplement && avantBascule" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.recettesPrevuesAnnees)}} </td>
                                <td v-if="laUF.peuplement && !avantBascule" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.recettesPrevuesAnnees)}} </td>
                                <td v-if="laUF.peuplement && !avantBascule" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.recettesPrevuesAnneeRetenue)}} </td>
                                <td v-if="laUF.peuplement" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.depensesPrevuesAnnees)}} </td>

                                <td v-if="laUF.peuplement" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.valeurReelleSurPiedAnnees)}} </td>
                                <td v-if="laUF.peuplement" class="liens lien nombre" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"> {{formaterEuros(laUF.valeurAttenteAnnees)}} </td>
                                <td v-if="laUF.peuplement" class="liens lien nombre coupeRase" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)"><span v-if="laUF.coupeRase">{{laUF.peuplement.anneeRetenue+laUF.coupeRase}} - {{laUF.coupeRase}} ans<br/>{{formaterEuros(laUF.valeurCoupeRase)}} </span></td>
                                <td v-if="laUF.peuplement" @click.stop="ouvrirPeuplement(laUF, laUF.peuplement)" class="liens lien nombre etat" @mouseenter="majAll(true, numUF)" @mouseleave="majAll(false, numUF)">
                                    <i v-if="laUF.peuplement.etatUtilisateur == etatVALIDE"  class="etatVALIDE fa fa-check svg" aria-hidden="true"></i>
                                    <i v-if="laUF.peuplement.etatUtilisateur == etatAVALIDER"  class="etatAVALIDER fa fa-exclamation svg" aria-hidden="true"></i>
                                    <i v-if="laUF.peuplement.etatUtilisateur == etatINVALIDE"  class="etatINVALIDE fa fa-times svg" aria-hidden="true"></i>

                                </td>
                            </tr>
                            <tr class="sansBord navigationListe" v-if="!listeVide">
                                <td colspan="3">
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsPremierAffichage" class="fa fa-fast-backward actif" aria-hidden="true" @click.stop="reculerDebutListe"></i>
                                    <i v-if="isUFsPremierAffichage" class="fa fa-fast-backward inactif" aria-hidden="true"></i>
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsPremierAffichage" class="fa fa-backward actif" aria-hidden="true" @click.stop="reculerListe"></i>
                                    <i v-if="isUFsPremierAffichage" class="fa fa-backward inactif" aria-hidden="true"></i>
                                </td>
                                <td class="centrer">
                                    {{premiereUFsAffichee}} - {{derniereUFsAffichee}}
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsDernierAffichage" class="fa fa-forward actif" aria-hidden="true" @click.stop="avancerListe"></i>
                                    <i v-if="isUFsDernierAffichage" class="fa fa-forward inactif" aria-hidden="true"></i>
                                </td>
                                <td class="centrer">
                                    <i v-if="!isUFsDernierAffichage" class="fa fa-fast-forward actif" aria-hidden="true" @click.stop="avancerFinListe"></i>
                                    <i v-if="isUFsDernierAffichage" class="fa fa-fast-forward inactif" aria-hidden="true"></i>
                                </td>
                                <td colspan="4">
                                </td>
                            </tr>
                            <tr class="sansBord">
                                <td class="centrer">
                                    <v-btn v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && (lesUFs.length < maxUFsPropriete) && !limitationUFs" class="boutonFonctionObjet prioritaire" type="button" @click.stop="creerUF">Ajouter une UF</v-btn>
                                </td>
                                <td colspan="3" class="centrer">
                                    <v-btn v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && (lesUFs.length < maxUFsPropriete) && !existeLimitationUFs" class="boutonFonctionObjet prioritaire" type="button" @click.stop="importerUFs">Importer des UF</v-btn>
                                    <span class="infoBlocageBouton" v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && (lesUFs.length < maxUFsPropriete) && existeLimitationUFs && !limitationUFs" >Import d'UFs désactivée en mode "ESSAI". Pour plus d'informations, merci de contacter l'équipe STERES (contact@steres.fr).</span>
                                </td>
                                <td colspan="7">&nbsp;
                                    <span v-if="lesUFs.length >= maxUFsPropriete && !limitationUFs" class="avertissement">Attention, le nombre d'UF d'une propriété est pour le moment limité à 1000.<br/>Vous ne pouvez plus ajouter ou importer d'UF.</span>
                                    <span v-if="!$store.getters.lectureSeule && !$store.getters.modeForet && limitationUFs" class="avertissement">Vous êtes en mode "ESSAI" et avez atteint le nombre maximum d'Unités Forestières pour cette propriété. Pour pouvoir créer ou importer de nouvelles Unités Forestières, merci de contacter l'équipe STERES (contact@steres.fr).</span>
                                </td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>

<v-dialog v-model="dialog" v-if="propriete.id" >
      <v-card>
        <v-card-title class="headline">Suppression</v-card-title>

        <v-card-text>
          Voulez-vous vraiment supprimer la propriete {{ propriete.libelle }} ainsi que toutes ses unités forestières et peuplements liés ?<br/>Cette action est irréversible.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialog = false;menuOuvert = false">Non</v-btn>
          <v-btn color="" text @click="supprimerPropriete()">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogForet" >
      <v-card>
        <v-card-title class="headline">Passage en "Mode Forêt"</v-card-title>

        <v-card-text v-if="$parent.okServiceWorker">
          Voulez-vous passer en "Mode Forêt" ?<br/><br/>Ce mode vous permettra de saisir des données dendrométriques en l'absence de connexion Internet.<br/>Par la suite et avec l'assurance d'une bonne connexion, vous devrez synchroniser votre terminal vers le serveur STERES centralisé.<br/><br/>NB : le passage en "Mode Forêt" verrouille l'accés à votre propriété pour ses autres ayants droit et limite votre propre accès à cette propriété.
        </v-card-text>
        <v-card-text v-if="!$parent.okServiceWorker">
          Votre navigateur ne permet pas de travailler "hors-ligne" et donc de passer en mode forêt.<br/><br/>Nous vous conseillons d'installer, "Google Chrome" ou "Mozilla Firefox".
        </v-card-text>

        <v-card-actions v-if="$parent.okServiceWorker">
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogForet = false;menuOuvert = false">Non</v-btn>
          <v-btn color="" text @click="menuOuvert = false;dialogForet = false;dialogForet2 = false;$parent.versModeForet(2)">Oui</v-btn>
        </v-card-actions>
        <v-card-actions v-if="!$parent.okServiceWorker">
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogForet = false;menuOuvert = false">OK</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogForet2" >
      <v-card>
        <v-card-title class="headline">Fin du "Mode Forêt"</v-card-title>

        <v-card-text>
          Attention. Le mode forêt est activé sur votre compte mais sur un autre terminal.<br/>Si vous réinitialisez le mode forêt sur cette machine, l'ensemble des saisies réalisées sur le terminal en "Mode Forêt" seront perdues ? La réinitialisation doit donc être considérée comme un secours en cas de perte du terminal en question.<br/><br/>Voulez-vous vraiment abandonner le "Mode Forêt" sur l'ensemble de vos terminaux ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogForet2 = false;menuOuvert = false">Non</v-btn>
          <v-btn color="" text @click="menuOuvert = false;dialogForet = false;dialogForet2 = false;$parent.versModeForet(0)">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogForet3" >
      <v-card>
        <v-card-title class="headline">Préparation du "Mode Forêt"</v-card-title>

        <v-card-text>
          La préparation du mode forêt demande quelques secondes.<br/>Merci de valider ce message puis de patienter.<br/><br/>Un nouveau message vous préviendra quand STERES sera prêt.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogForet3 = false;$parent.versModeForet(2)">OK</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogForet4" >
      <v-card>
        <v-card-title class="headline">"Mode Forêt" initialisé</v-card-title>

        <v-card-text>
          Le mode forêt est maintenant prêt.<br/>Vous pouvez partir en forêt et saisir vos mesures même en l'absence de connexion Internet.<br/><br/>N'oubliez pas : à votre retour, il vous faudra synchroniser votre terminal !
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogForet4 = false;">OK</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
        </div>
    </div>
</template>


<script>

import { SteresConstantes } from "../js/calculs/constantes.js"
import { ApiTools, ApiUser } from '../js/api/index.js'
//import { addeoData } from "../js/api/datas.js"
//import { LovefieldService } from "../js/db/LovefieldService.js"

import ProprieteSynthese1 from '../components/propriete/ProprieteSynthese1.vue'
import ProprieteSynthese2 from '../components/propriete/ProprieteSynthese2.vue'
import ProprieteSynthese3 from '../components/propriete/ProprieteSynthese3.vue'
import ProprieteSynthese4 from '../components/propriete/ProprieteSynthese4.vue'
import ProprieteSynthese5 from '../components/propriete/ProprieteSynthese5.vue'

export default {

    name: 'propriete',

    components: {
        'synthese-1': ProprieteSynthese1,
        'synthese-2': ProprieteSynthese2,
        'synthese-3': ProprieteSynthese3,
        'synthese-4': ProprieteSynthese4,
        'synthese-5': ProprieteSynthese5,
     },


    data(){
        return {
            propriete: null,
            UFs: [],
            UFsTampon: [], // pour affichage conditionnel
            maxUFsPropriete:1000,
            maxUFsAffichage:20,
            nb_uf:0,
            nb_uf_lues:0,
            lesConstantesReady: false,
            ready: false,
            menuOuvert: false,
            tabIds:[],
            ecoOuvert:true,
            dialog: false,
            syntheseCourante:null,
            dialogForet: false,
            dialogForet2: false,
            dialogForet3: false,
            dialogForet4: false,
            trisFiltres :{
                premiereUFsAffichage:0,
                testIdentifiant:"",
                filtrerIdentifiant:false,
                testAnnee:"",
                filtrerAnneeAvant:false,
                filtrerAnnee:false,
                filtrerAnneeApres:false,
                testClasseAge:"",
                filtrerClasseAge:false,
                testSurfaceBoisee:"",
                filtrerSurfaceBoiseeInf:false,
                filtrerSurfaceBoisee:false,
                filtrerSurfaceBoiseeSup:false,
                testCoupeRase:"",
                filtrerCoupeRaseAvant:false,
                filtrerCoupeRase:false,
                filtrerCoupeRaseApres:false,
                filtrerEtatVALIDE:false,
                filtrerEtatAVALIDER:false,
                filtrerEtatINVALIDE:false,
                triUF:1,
                triPeuplementAnnee:0,
                triPeuplementSurfaceBoisee:0,
                triUfRecettesPrevuesAnnees:0,
                triUfrecettesPrevuesAnneeRetenue:0,
                triUfDepensesPrevuesAnnees:0,
                triUfValeurReelleSurPiedAnnees:0,
                triUfValeurAttenteAnnees:0,
                triUfCoupeRase:0,
                triPeuplementEtatUtilisateur:0,
                triPeuplementAge:0
            }
        }
    },

    created: function () {
        this.$store.commit('setUfId', 0);
        this.syntheseCourante = this.listeSyntheses[0];
        this.$store.commit('resetDataApplication');

    },

    mounted: function () {
        this.remplirTabIds(this.maxUFsPropriete);

        setTimeout(this.datasApplicationCommit, 2000);
    },

    watch: {


        filtrerIdentifiant(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerIdentifiant");
                this.UFsTampon = this.lesUFsTamponIdentifiant();
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        testIdentifiant(newVal) {
            if (this.filtrerIdentifiant) {
                this.UFsTampon = this.lesUFsTamponIdentifiant();
            }
        },
        filtrerAnneeAvant(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerAnneeAvant");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreAnnee);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerAnnee(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerAnnee");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreAnnee);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerAnneeApres(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerAnneeApres");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreAnnee);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        testAnnee(newVal) {
            if (this.filtrerAnneeAvant || this.filtrerAnnee || this.filtrerAnneeApres) {
                this.UFsTampon = this.lesUFsTamponParam(this.filtreAnnee);
            }
        },

        filtrerClasseAge(newVal){
            if (newVal) {
                this.reinitialiserFiltres("filtrerClasseAge");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreClasseAge);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        testClasseAge(newVal) {
            if (this.filtrerClasseAge) {
                this.UFsTampon = this.lesUFsTamponParam(this.filtreClasseAge);
            }
        },


        testSurfaceBoisee(newVal) {
            if (this.filtrerSurfaceBoiseeInf || this.filtrerSurfaceBoisee || this.filtrerSurfaceBoiseeSup) {
                this.UFsTampon = this.lesUFsTamponParam(this.filtreSurfaceBoisee);
            }
        },
        filtrerSurfaceBoiseeInf(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerSurfaceBoiseeInf");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreSurfaceBoisee);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerSurfaceBoisee(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerSurfaceBoisee");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreSurfaceBoisee);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerSurfaceBoiseeSup(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerSurfaceBoiseeSup");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreSurfaceBoisee);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        testCoupeRase(newVal) {
            if (this.filtrerCoupeRaseAvant || this.filtrerCoupeRase || this.filtrerCoupeRaseApres) {
                this.UFsTampon = this.lesUFsTamponParam(this.filtreCoupeRase);
            }
        },
        filtrerCoupeRaseAvant(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerCoupeRaseAvant");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreCoupeRase);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerCoupeRase(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerCoupeRase");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreCoupeRase);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerCoupeRaseApres(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerCoupeRaseApres");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreCoupeRase);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerEtatVALIDE(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerEtatVALIDE");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreEtat);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerEtatAVALIDER(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerEtatAVALIDER");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreEtat);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },
        filtrerEtatINVALIDE(newVal) {
            if (newVal) {
                this.reinitialiserFiltres("filtrerEtatINVALIDE");
                this.UFsTampon = this.lesUFsTamponParam(this.filtreEtat);
            }
            else {
                if (!this.filtrer) {
                    this.UFsTampon = this.lesUFsTampon();
                }
            }
        },

        lesConstantesReady(newVal) {
            if (newVal) {
                this.proprieteCourante(this.$store.getters.constantes);
            }
        },

        syntheseCourante(newVal) {
            if (newVal) {
                //this.majItCourant(newVal);
            }
        },
        modeForetPreparation(newVal) {
            if (newVal == 2) {
                this.$store.commit('setModeForet', 3);
                setTimeout(this.avancerVers3, 245);
            }
            if (newVal == 5) {
                setTimeout(this.avancerVers5, 245);
            }
            if (newVal == 11) {
                this.avancerVers11();
            }
        },

        ready(newValue) {
            if (newValue) {
                ApiUser.calculerProprieteITs(this.propriete, this.UFs, this.$store.getters.constantes);


                if (this.nb_uf > this.nb_uf_lues) {
                    this.lireDatasProprieteDesynchro(this.propriete.id, this.nb_uf); // on n'attends pas
                }
                else {
                    this.UFsTampon = this.lesUFsTampon(); // chargement "originel" : sans filtre
                    this.$parent.chargementProprieteEnCours = false;

                    // on récupère les filtres et tris si on les a stockés
                    Object.assign(this.trisFiltres, this.$store.getters.trisFiltresPropriete);
                }

// plus dans le mounted
        if (this.$store.getters.modeForet == 4) {
            this.$store.commit('setModeForet', 5);
        }
        if (this.$store.getters.modeForet == 10) {
            this.$store.commit('setModeForet', 11);
        }
            }
        }

    },

    computed: {
        limitationUFs() {
            var limitationNbUFsPropriete = this.$store.getters.utilisateurCourant.nbUFsPropriete;
            if ((limitationNbUFsPropriete <= 0) || (limitationNbUFsPropriete > this.UFs.length)) {
                return(false);
            }
            return(true);
        },
        existeLimitationUFs() {
            var limitationNbUFsPropriete = this.$store.getters.utilisateurCourant.nbUFsPropriete;
            if (limitationNbUFsPropriete <= 0) {
                return(false);
            }
            return(true);
        },
        testIdentifiant() {
            return(this.trisFiltres.testIdentifiant)
        },
        filtrerIdentifiant() {
            return(this.trisFiltres.filtrerIdentifiant)
        },
        testAnnee() {
            return(this.trisFiltres.testAnnee)
        },
        filtrerAnneeAvant() {
            return(this.trisFiltres.filtrerAnneeAvant)
        },
        filtrerAnnee() {
            return(this.trisFiltres.filtrerAnnee)
        },
        filtrerAnneeApres() {
            return(this.trisFiltres.filtrerAnneeApres)
        },
        testClasseAge() {
            return(this.trisFiltres.testClasseAge)
        },
        filtrerClasseAge() {
            return(this.trisFiltres.filtrerClasseAge)
        },
        testSurfaceBoisee() {
            return(this.trisFiltres.testSurfaceBoisee)
        },
        filtrerSurfaceBoiseeInf() {
            return(this.trisFiltres.filtrerSurfaceBoiseeInf)
        },
        filtrerSurfaceBoisee() {
            return(this.trisFiltres.filtrerSurfaceBoisee)
        },
        filtrerSurfaceBoiseeSup() {
            return(this.trisFiltres.filtrerSurfaceBoiseeSup)
        },
        testCoupeRase() {
            return(this.trisFiltres.testCoupeRase)
        },
        filtrerCoupeRaseAvant() {
            return(this.trisFiltres.filtrerCoupeRaseAvant)
        },
        filtrerCoupeRase() {
            return(this.trisFiltres.filtrerCoupeRase)
        },
        filtrerCoupeRaseApres() {
            return(this.trisFiltres.filtrerCoupeRaseApres)
        },
        filtrerEtatVALIDE() {
            return(this.trisFiltres.filtrerEtatVALIDE)
        },
        filtrerEtatAVALIDER() {
            return(this.trisFiltres.filtrerEtatAVALIDER)
        },
        filtrerEtatINVALIDE() {
            return(this.trisFiltres.filtrerEtatINVALIDE)
        },
        etatAVALIDER() {
            return(SteresConstantes.etatAVALIDER);
        },
        etatINVALIDE() {
            return(SteresConstantes.etatINVALIDE);
        },
        etatVALIDE() {
            return(SteresConstantes.etatVALIDE);
        },
        filtrer() {
            return(this.trisFiltres.filtrerIdentifiant
                   || this.filtrerAnneeAvant || this.filtrerAnnee || this.filtrerAnneeApres
                   || this.filtrerClasseAge
                   || this.filtrerSurfaceBoiseeInf || this.filtrerSurfaceBoisee || this.filtrerSurfaceBoiseeSup
                   || this.filtrerCoupeRaseAvant || this.filtrerCoupeRase || this.filtrerCoupeRaseApres
                   || this.filtrerEtatVALIDE || this.filtrerEtatAVALIDER || this.filtrerEtatINVALIDE
                  );
        },
        listeVide() {
            if ((this.lesUFsList.length == 0) && this.filtrer) {
                return(true);
            }
            return(false);
        },
        leNbUFs() {
            return(this.nb_uf);
        },
        leNbUFsLues() {
            return(this.nb_uf_lues);
        },
        constantesReady() {
            this.lesConstantesReady = this.$store.getters.constantes.ready;
            return(this.$store.getters.constantes.ready);
        },
        leProprietaireId() {
            if (typeof(this.laPropriete.proprietaire) !== 'undefined') {
                return(this.laPropriete.proprietaire.id);
            }
            return(this.laPropriete.proprietaireId);
        },
        gestionnaireId() {
            return(this.laPropriete.gestionnaireId);
        },

        affichageAnneePred() {
            if (this.lesUFs.length && this.ufPremierPeuplement) {
                if (this.ufPremierPeuplement.dendrometrieAnneeRetenue.anneeCourante != this.ufPremierPeuplement.dendrometrieAnnee.anneeCourante) {
                    var leAnnee =  this.ufPremierPeuplement.dendrometrieAnnee.anneeCourante + this.ufPremierPeuplement.peuplement.anneeRetenue;
                    var leAnneeMoinsUn =  this.ufPremierPeuplement.dendrometrieAnnee.anneeCourante + this.ufPremierPeuplement.peuplement.anneeRetenue - 1;
                    return(""+ leAnneeMoinsUn + " / "+ leAnnee);
                }
            }
            return("");
        },
        affichageAnnee() {
            if (this.lesUFs.length && this.ufPremierPeuplement) {
                if (this.ufPremierPeuplement.dendrometrieAnneeRetenue.anneeCourante == this.ufPremierPeuplement.dendrometrieAnnee.anneeCourante) {
                    var leAnnee =  this.ufPremierPeuplement.dendrometrieAnneeRetenue.anneeCourante + this.ufPremierPeuplement.peuplement.anneeRetenue;
                    var leAnneeMoinsUn =  this.ufPremierPeuplement.dendrometrieAnneeRetenue.anneeCourante + this.ufPremierPeuplement.peuplement.anneeRetenue - 1;
                    return(""+ leAnneeMoinsUn + " / "+ leAnnee);
                }
                else {
                    var leAnneePlusUn =  this.ufPremierPeuplement.dendrometrieAnneeRetenue.anneeCourante + this.ufPremierPeuplement.peuplement.anneeRetenue;
                    var leAnnee =  this.ufPremierPeuplement.dendrometrieAnnee.anneeCourante + this.ufPremierPeuplement.peuplement.anneeRetenue;
                    return(""+ leAnnee + " / "+ leAnneePlusUn);
                }
            }
            return("");
        },
        avantBascule() {
                    //return(false); // pour test
            if (this.lesUFs.length && this.ufPremierPeuplement) {
                if (this.ufPremierPeuplement.dendrometrieAnneeRetenue.anneeCourante == this.ufPremierPeuplement.dendrometrieAnnee.anneeCourante) {
                    return(true);
                }
                else {
                    return(false);
                }
            }
            return(true);
        },


        modeForetPreparation() {
            return(this.$store.getters.modeForet);
        },

        listeSyntheses() {
            var liste = [];
            liste[0] = {"identifiant":"Choisir ...", "id":0};
            liste[1] = {"identifiant":"Programme des coupes", "id":1};
            liste[2] = {"identifiant":"Synthèse des coupes", "id":3};
            liste[3] = {"identifiant":"Synthèse carbone", "id":5};
            liste[4] = {"identifiant":"Interventions à réaliser dans l'année", "id":2};
            liste[5] = {"identifiant":"Etat de la propriété", "id":4};
            return(liste);
        },

        laSurface() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.surface) === 'undefined')) {
                return(0);
            }
            return(this.formaterHectares(this.propriete.surface));
        },
        surfaceEclairciePrevuesAnnees() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.surfaceEclaircieAnnees) === 'undefined')) {
                return(0);
            }
            if (this.avantBascule) {
                return(this.formaterHectares(this.propriete.surfaceEclaircieAnnees));
            }
            else {
                return(this.formaterHectares(this.propriete.surfaceEclaircieAnneeRetenue));
            }
        },
        surfaceCoupeRasePrevuesAnnees() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.surfaceCoupeRaseAnnees) === 'undefined')) {
                return(0);
            }
            if (this.avantBascule) {
                return(this.formaterHectares(this.propriete.surfaceCoupeRaseAnnees));
            }
            else {
                return(this.formaterHectares(this.propriete.surfaceCoupeRaseAnneeRetenue));
            }
        },
        valeurAttentePrevuesAnnees() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.valeurAttenteAnnees) === 'undefined')) {
                return(0);
            }
            return(ApiTools.formaterEuros0(this.propriete.valeurAttenteAnnees));
        },
        valeurSurPiedPrevuesAnnees() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.valeurSurPiedAnnees) === 'undefined')) {
                return(0);
            }
            return(ApiTools.formaterEuros0(this.propriete.valeurSurPiedAnnees));
        },
        depensesPrevuesAnnees() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.depensesPrevuesAnnees) === 'undefined')) {
                return(0);
            }
            return(ApiTools.formaterEuros0(this.propriete.depensesPrevuesAnnees));
        },
        recettesPrevuesAnnees() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.recettesPrevuesAnnees) === 'undefined')) {
                return(0);
            }
            return(ApiTools.formaterEuros0(this.propriete.recettesPrevuesAnnees));
            //nb.toLocaleString()
        },

        messageInformation() {
            return this.$store.getters.messageInformation
        },
        datasApplication() {
            if (!this.$store.getters.dataApplication) {
                this.$store.commit('majDataApplication');
            }
            return(this.$store.getters.dataApplication)
        },
        laPropriete() {
            return this.propriete
        },
        lesUFs() {
            return this.UFs.sort(this.triUFs)
        },

        lesUFsList() {
            this.trisFiltres.premiereUFsAffichage = Math.min(this.trisFiltres.premiereUFsAffichage, this.UFsTampon.length);
            // return this.UFsTampon.slice(this.trisFiltres.premiereUFsAffichage, this.trisFiltres.premiereUFsAffichage+this.maxUFsAffichage);
            var listeFiltreeTriee = this.UFsTampon.slice(this.trisFiltres.premiereUFsAffichage, this.trisFiltres.premiereUFsAffichage+this.maxUFsAffichage);

            if (this.trisFiltres.triUF) {
                return listeFiltreeTriee.sort(this.triUFs)
            }
            if (this.trisFiltres.triPeuplementAnnee) {
                return listeFiltreeTriee.sort(this.triPeuplementsAnnee)
            }
            if (this.trisFiltres.triPeuplementSurfaceBoisee) {
                return listeFiltreeTriee.sort(this.triPeuplementsSurfaceBoisee)
            }
            if (this.trisFiltres.triUfRecettesPrevuesAnnees) {
                return listeFiltreeTriee.sort(this.triUfsRecettesPrevuesAnnees)
            }
            if (this.trisFiltres.triUfrecettesPrevuesAnneeRetenue) {
                return listeFiltreeTriee.sort(this.triUfsrecettesPrevuesAnneeRetenue)
            }
            if (this.trisFiltres.triUfDepensesPrevuesAnnees) {
                return listeFiltreeTriee.sort(this.triUfsDepensesPrevuesAnnees)
            }
            if (this.trisFiltres.triUfValeurReelleSurPiedAnnees) {
                return listeFiltreeTriee.sort(this.triUfsValeurReelleSurPiedAnnees)
            }
            if (this.trisFiltres.triUfValeurAttenteAnnees) {
                return listeFiltreeTriee.sort(this.triUfsValeurAttenteAnnees)
            }
            if (this.trisFiltres.triUfCoupeRase) {
                return listeFiltreeTriee.sort(this.triUfsCoupeRase)
            }
            if (this.trisFiltres.triPeuplementEtatUtilisateur) {
                return listeFiltreeTriee.sort(this.triPeuplementsEtatUtilisateur)
            }
            if (this.trisFiltres.triPeuplementAge) {
                return listeFiltreeTriee.sort(this.triPeuplementsAge)
            }
            return listeFiltreeTriee;
        },
        ufPremierPeuplement() {
            if (!this.UFs.length) {
                return(null);
            }
            var i=0;
            var uf = null;
            while (1) {
                uf = this.UFs[i];
                if (uf.peuplement && uf.dendrometrieAnneeRetenue) {
                    break;
                }
                i++;
                if (i >= this.UFs.length) {
                    return(null);
                }
            }
            return(uf);
        },
        lesTotauxUFs() {
            // 	Valeur sur pied	valeur d'attente	Coupe rase (age et montant)
            var totaux = {};
            totaux.surface = 0;
            totaux.surfaceBoisee = 0;
            totaux.recettesPrevuesAnnees = 0;
            totaux.recettesPrevuesAnneeRetenue = 0;
            totaux.depensesPrevuesAnnees = 0;
            totaux.valeurAttenteAnnees = 0;
            totaux.valeurSurPiedAnnees = 0;
            totaux.valeurCoupeRase = 0;
            var thisObj = this;
            this.UFs.forEach(uf => { // totaux sur l'ensemble de la propriété
            // this.lesUFsList.forEach(uf => { // totaux uniquement sur la liste affichée
                totaux.surface += uf.surface;
                if (uf.peuplement) {
                    totaux.surfaceBoisee += uf.peuplement.surfaceBoisee;
                }

                var valeurSurPiedAnnees = uf.valeurSurPiedAnnees;
                var peuplement = uf.peuplement;
                if ((peuplement !== 'undefined') && peuplement) {
                    var eclaircieVirtuelle = ApiUser.retrouverEclaircieVirtuelle(thisObj.anneeCourante-peuplement.anneeRetenue, peuplement);
                    if (eclaircieVirtuelle && (typeof(uf.valeurSurPiedAnneesAvantEclaircie) !== 'undefined')) {
                        valeurSurPiedAnnees = uf.valeurSurPiedAnneesAvantEclaircie;
                    }
                }
                totaux.recettesPrevuesAnnees += uf.recettesPrevuesAnnees;
                totaux.recettesPrevuesAnneeRetenue += uf.recettesPrevuesAnneeRetenue;
                totaux.depensesPrevuesAnnees += uf.depensesPrevuesAnnees;
                totaux.valeurAttenteAnnees += uf.valeurAttenteAnnees;
                // totaux.valeurSurPiedAnnees += uf.valeurSurPiedAnnees; DEVIENT
                totaux.valeurSurPiedAnnees += valeurSurPiedAnnees;
                uf.valeurReelleSurPiedAnnees = valeurSurPiedAnnees; // au passage !
                totaux.valeurCoupeRase += uf.valeurCoupeRase;
            })
            return (totaux)
        },
        lesTotauxPartielsUFs() {
            // 	Valeur sur pied	valeur d'attente	Coupe rase (age et montant)
            var totaux = {};
            totaux.surfaceBoisee = 0;
            totaux.recettesPrevuesAnnees = 0;
            totaux.recettesPrevuesAnneeRetenue = 0;
            totaux.depensesPrevuesAnnees = 0;
            totaux.valeurAttenteAnnees = 0;
            totaux.valeurSurPiedAnnees = 0;
            totaux.valeurCoupeRase = 0;
            var thisObj = this;
            //this.UFs.forEach(uf => {
            this.lesUFsList.forEach(uf => { // totaux uniquement sur la liste affichée
                if (uf.peuplement) {
                    totaux.surfaceBoisee += uf.peuplement.surfaceBoisee;
                }

                var valeurSurPiedAnnees = uf.valeurSurPiedAnnees;
                var peuplement = uf.peuplement;
                if ((peuplement !== 'undefined') && peuplement) {
                    var eclaircieVirtuelle = ApiUser.retrouverEclaircieVirtuelle(thisObj.anneeCourante-peuplement.anneeRetenue, peuplement);
                    if (eclaircieVirtuelle && (typeof(uf.valeurSurPiedAnneesAvantEclaircie) !== 'undefined')) {
                        valeurSurPiedAnnees = uf.valeurSurPiedAnneesAvantEclaircie;
                    }
                }
                totaux.recettesPrevuesAnnees += uf.recettesPrevuesAnnees;
                totaux.recettesPrevuesAnneeRetenue += uf.recettesPrevuesAnneeRetenue;
                totaux.depensesPrevuesAnnees += uf.depensesPrevuesAnnees;
                totaux.valeurAttenteAnnees += uf.valeurAttenteAnnees;
                // totaux.valeurSurPiedAnnees += uf.valeurSurPiedAnnees; DEVIENT
                totaux.valeurSurPiedAnnees += valeurSurPiedAnnees;
                uf.valeurReelleSurPiedAnnees = valeurSurPiedAnnees; // au passage !
                totaux.valeurCoupeRase += uf.valeurCoupeRase;
            })
            return (totaux)
        },
        proprieteEtUFs() {
            var propriete = JSON.parse(JSON.stringify(this.propriete));
            propriete.UFs = this.UFs;
            return(propriete);
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        anneeCourante() {
            var ladate=new Date();
            return(ladate.getFullYear());
        },
        existeTarifsSpecifiques() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.tarifsJson) === 'undefined') || (this.propriete.tarifsJson === "")) {
                return(false);
            }
            return(true);
        },
        isUFsDernierAffichage() {
            if (this.trisFiltres.premiereUFsAffichage + this.maxUFsAffichage >= this.nbUFsAffichees) {
                return(true)
            }
            return(false);
        },
        isUFsPremierAffichage() {
            if (!this.trisFiltres.premiereUFsAffichage) {
                return(true)
            }
            return(false);
        },
        premiereUFsAffichee() {
            return(this.trisFiltres.premiereUFsAffichage + 1);
        },
        derniereUFsAffichee() {
            return(Math.min(this.trisFiltres.premiereUFsAffichage + this.maxUFsAffichage, this.nbUFsAffichees));
        },
        nbUFsAffichees() {
            return(this.UFsTampon.length);
        },
        classesAge() {
            return(SteresConstantes.classesAge)
        }

    },

    methods: {
        rafraichir() {
            document.location.reload(true);
        },
        avancerVers3() {
            this.ouvrirUF(this.lesUFs[0]);
        },
        avancerVers5() {
            var uf = this.ufPremierPeuplement;
            this.ouvrirPeuplement(uf, uf.peuplement);
        },
        avancerVers11() {
            this.$store.commit('setModeForet', 1); // prêt :)
            this.dialogForet4 = true; // on le dit !

        },
        triUFs(x, y) {
            if (this.trisFiltres.triUF > 0) {
                return x.identifiant.toString().localeCompare(y.identifiant.toString());
            }
            else {
                return y.identifiant.toString().localeCompare(x.identifiant.toString());
            }
        },
        triChampNumerique(objX, objY, champNumerique, triAscDesc, testerPeuplement, inverserUfSansPeuplement = 1) {
            var laDifference = 0;
            if (objX || objY) {
                if (!objX || (testerPeuplement && !objX.peuplement)) {
                    laDifference = -1 * inverserUfSansPeuplement;
                }
                else {
                    if (!objY || (testerPeuplement && !objY.peuplement)) {
                        laDifference = 1 * inverserUfSansPeuplement;
                    }
                    else {
                        laDifference = objX[champNumerique] - objY[champNumerique];
                    }
                }
            }
            if (triAscDesc > 0) {
                return laDifference;
            }
            else {
                return laDifference*(-1);
            }
        },
        triPeuplementsAnnee(x, y) {
            return(this.triChampNumerique(x.peuplement, y.peuplement, 'anneeRetenue', this.trisFiltres.triPeuplementAnnee, false))
        },
        triPeuplementsSurfaceBoisee(x, y) {
            return(this.triChampNumerique(x.peuplement, y.peuplement, 'surfaceBoisee', this.trisFiltres.triPeuplementSurfaceBoisee, false))
        },
        triUfsRecettesPrevuesAnnees(x, y) {
            return(this.triChampNumerique(x, y, 'recettesPrevuesAnnees', this.trisFiltres.triUfRecettesPrevuesAnnees, true))
        },
        triUfsrecettesPrevuesAnneeRetenue(x, y) {
            return(this.triChampNumerique(x, y, 'recettesPrevuesAnneeRetenue', this.trisFiltres.triUfrecettesPrevuesAnneeRetenue, true))
        },
        triUfsDepensesPrevuesAnnees(x, y) {
            return(this.triChampNumerique(x, y, 'depensesPrevuesAnnees', this.trisFiltres.triUfDepensesPrevuesAnnees, true))
        },
        triUfsValeurReelleSurPiedAnnees(x, y) {
            return(this.triChampNumerique(x, y, 'valeurReelleSurPiedAnnees', this.trisFiltres.triUfValeurReelleSurPiedAnnees, true))
        },
        triUfsValeurAttenteAnnees(x, y) {
            return(this.triChampNumerique(x, y, 'valeurAttenteAnnees', this.trisFiltres.triUfValeurAttenteAnnees, true))
        },
        triUfsCoupeRase(ufX, ufY) {
            var peuplementX = ufX.peuplement;
            var peuplementY = ufY.peuplement;
            var laDifference = 0;
            if (ufX || ufY || peuplementX || peuplementY) {
                if (!ufX || !peuplementX) {
                    laDifference = -1;
                }
                else {
                    if (!ufY || !peuplementY) {
                        laDifference = 1;
                    }
                    else {
                        laDifference = (peuplementX.anneeRetenue + ufX.coupeRase) - (peuplementY.anneeRetenue + ufY.coupeRase);
                    }
                }
            }
            if (this.trisFiltres.triUfCoupeRase > 0) {
                return laDifference;
            }
            else {
                return laDifference*(-1);
            }
        },
        triPeuplementsEtatUtilisateur(x, y) {
            return(this.triChampNumerique(x.peuplement, y.peuplement, 'etatUtilisateur', this.trisFiltres.triPeuplementEtatUtilisateur, false, -1))
        },
        triPeuplementsAge(x, y) {
            return(this.triChampNumerique(x.peuplement, y.peuplement, 'lAge', this.trisFiltres.triPeuplementAge, false))
        },
        formaterEuros(valeur) {
            return(ApiTools.formaterEuros0(valeur));
        },
        formaterHectares(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson)+" ha");
            //return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson)+" ha");
        },
        formaterHectaresTab(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson)+" ha");
        },
        datasApplicationCommit() {
            this.$store.commit('majDataApplication');
            return(this.$store.getters.dataApplication)
            },

        proprieteCouranteBD(constantes, idPropriete) {
var d0 = new Date();
var t0 = d0.getTime();
console.log("proprieteCouranteBD, before boucle")


            var thisObj = this;
            // on lit les infos de la propriété dont les UF puis ...
            return ApiUser.lirePropriete(idPropriete, constantes).then(response => {
                thisObj.propriete = response.propriete;
                //this.propriete.calculsTermines = false;
                thisObj.UFs = response.UFs;
                thisObj.$store.commit('setProprieteInfos', thisObj.propriete); // pour être sûr d'être à jour !
                // this.$store.commit('setProprieteUFs', this.UFs); // pour être sûr d'être à jour !  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState


                return ApiUser.lireUtilisateur(thisObj.propriete.gestionnaireId)
                .then(response => {
                    thisObj.propriete.gestionnaire = response.utilisateur; // il y a 1 gestionnaire par propriete

                    return ApiUser.lireUtilisateur(thisObj.propriete.proprietaireId)
                    .then(response => {
                        thisObj.propriete.proprietaire = response.utilisateur; // il y a 1 proprietaire par propriete

                        var allPromisesPeuplements = [];
                        var peuplements = [];
                        // .. on lit maintenant les peuplements de chaque UF
                        thisObj.UFs.forEach(uf => {
                            uf.peuplement = null; // reinit
                            var i = peuplements.length;
                            // peuplements[i] = ApiUser.lirePeuplementUF( uf.id); // il y en a maintenant un seul  !!!
                            // BC 2020/0206 SIMPLIFICATION TE appel à la fonction complète de l'API
                            peuplements[i] = ApiUser.lirePeuplement(0, thisObj.$store.getters.constantes, uf.id); // promesse sur response.peuplement;

                            allPromisesPeuplements.push(peuplements[i])
                        })
                        return Promise.all(allPromisesPeuplements)
                        .then(responses => {
                            var UFs = thisObj.UFs;
                            // on associe les peuplements aux UFs
                            responses.forEach(response => {
                                if (typeof(response) !== 'undefined') {
                                    var lePeuplement = response.peuplement;
                                    this.donnerClasseAge(lePeuplement);
                                    UFs.forEach(uf => {
                                        if (lePeuplement.ufId == uf.id) {
                                            uf.peuplement = lePeuplement
                                        }
                                    })
                                }
                            })
                            thisObj.ready = true;
    var d1 = new Date();
    var t1 = d1.getTime();
    console.log("proprieteCouranteBD, after boucle : ", t1-t0)
                            return UFs;
                        })
                    })
                })
            })
        },
        lireDatasProprieteDesynchro(idPropriete, nbUFs) {

var d0 = new Date();
var t0 = d0.getTime();
console.log("lireDatasProprieteDesynchro, avant requetes : ")

            var limiteLecture = ApiUser.limiteLecture;
            var nbIterations = parseInt(nbUFs / limiteLecture);
            var nbIterationsValides = 0;
            var debut = 2;
            for (var i = debut; i < debut+nbIterations; i++) {
                ApiUser.lireDatasProprieteDesynchroIteration(idPropriete, limiteLecture, i).then(response => {
                    nbIterationsValides++;
                    if (nbIterationsValides == nbIterations) {
                        this.nb_uf_lues = this.nb_uf;
                        this.ready = false; // on va ercharger une liste, on n'est plus "prêt" !
var d1 = new Date();
var t1 = d1.getTime();
console.log("lireDatasProprieteDesynchro, réception requetes : ", t1-t0)
                        return(this.proprieteCouranteBD(this.$store.getters.constantes, idPropriete)); // on fini de lire dans la base distante - on affiche
                    }
                    else {
                        this.nb_uf_lues += response.data.user_uf.length;
                    }
                })
            }
            return(1);
        },
        proprieteCourante(constantes) {
            if (!this.$store.getters.proprieteId) {
                return;
            }
            this.$parent.chargementProprieteEnCours = true;
            var idPropriete = this.$store.getters.proprieteId;
            if (idPropriete == this.$store.getters.proprieteChargeeId) {
                this.nb_uf = this.$store.getters.proprieteChargeeNbUfs;
                this.nb_uf_lues = this.$store.getters.proprieteChargeeNbUfs;
                return(this.proprieteCouranteBD(constantes, idPropriete));
            }
            else {
                return ApiUser.lireDatasProprieteSynchro(idPropriete).then(response => {
                    if (response) {
                        this.nb_uf = response.data.nb_uf;
                        this.nb_uf_lues = response.data.user_uf.length;

                        this.$store.commit('setProprieteChargeeNbUfs', response.data.nb_uf);
                        this.$store.commit('setProprieteChargeeId', idPropriete);
                        return(this.proprieteCouranteBD(constantes, idPropriete));
                    }
                    else {
                        console.log("proprieteCourante lecture impossible", idPropriete);
                        return(null);
                    }
                })
            }
        },

        associerMesuresPeuplement(tabMesures, tabUFs) {
            if (!tabMesures || !tabMesures.length) {
                return;
            }
            var peuplementId = tabMesures[0].peuplementId;
            tabUFs.forEach(uf => {
                if (uf.peuplement && (uf.peuplement.id == peuplementId)) {
                    uf.peuplement.mesures = tabMesures;
                }
            })
        },
        associerEclairciesPeuplement(tabEclaircies, tabUFs) {
            if (!tabEclaircies || !tabEclaircies.length) {
                return;
            }
            var peuplementId = tabEclaircies[0].peuplementId;
            tabUFs.forEach(uf => {
                if (uf.peuplement && (uf.peuplement.id == peuplementId)) {
                    uf.peuplement.eclaircies = tabEclaircies;
                }
            })
        },

        donnerClasseAge(lePeuplement) {
            lePeuplement.classeAge = "CR";
            lePeuplement.lAge = 0;
            var classeAge = SteresConstantes.classesAge[0];
            if (typeof(lePeuplement.anneeRetenue) !== 'undefined') {
                var age = this.anneeCourante - lePeuplement.anneeRetenue;
                classeAge = SteresConstantes.associerClasseAge(age);
                lePeuplement.classeAge = classeAge.libelle;
                lePeuplement.lAge = age;
            }
            return(classeAge);
        },

        editerPropriete(propriete) {
            this.$store.commit('setProprieteId', propriete.id);
            this.$store.commit('resetDataApplication');
            this.$store.commit('setTrisFiltresPropriete', this.trisFiltres);
            this.$router.push({ name: "proprieteedition" });
        },
        editerTarifsPropriete() {
            this.$store.commit('setProprieteId', this.propriete.id);
            this.$store.commit('resetDataApplication');
            this.$store.commit('setTrisFiltresPropriete', this.trisFiltres);
            this.$router.push({ name: "proprietetarifs" });
        },

        ouvrirUF(uf) {
            if (this.$store.getters.lectureSeule) {
                return;
            }
            var idUF = -1;
            if (uf) {
                idUF = uf.id;
                this.$store.commit('setUfInfos', uf);
            }
            this.$store.commit('setUfId', idUF);
            this.$store.commit('resetDataApplication');
            this.$store.commit('setTrisFiltresPropriete', this.trisFiltres);
            this.$router.push({ name: "ufedition" });
        },
        creerUF() {
            this.ouvrirUF(null)
        },
        importerUFs() {
            if (this.$store.getters.lectureSeule) {
                return;
            }
            this.$store.commit('setTrisFiltresPropriete', this.trisFiltres);
            this.$router.push({ name: "ufimport" });
        },
        ouvrirPeuplement(uf, peuplement) {
            var idUF = uf.id;
            var idPeuplement = -1;
            if (peuplement) {
                idPeuplement = peuplement.id;
                this.$store.commit('setPeuplementInfos', peuplement);
            }
            this.$store.commit('setUfInfos', uf);
            this.$store.commit('setUfId', idUF);
            this.$store.commit('setPeuplementId', idPeuplement);
            this.$store.commit('resetDataApplication');
            this.$store.commit('setTrisFiltresPropriete', this.trisFiltres);
            if (peuplement) {
                this.$router.push({ name: "peuplement" });
            }
            else {
                this.$router.push({ name: "peuplementedition" })
            }
        },
        creerPeuplement(uf) {
            this.ouvrirPeuplement(uf, null);
        },
        // tableau rempli
        remplirTabIds(max) {
            for (var i = 0; i <= max; i++) {
                this.tabIds.push("tr"+i);
            }
        },
        majAll(inOut, numUF) {
            var domElt = document.getElementById(this.tabIds[numUF]);
            if (inOut) {
                ApiTools.toggleClassElt(domElt, 'removeClass', "in");
                ApiTools.toggleClassElt(domElt, 'addClass', "in");
            }
            else {
                ApiTools.toggleClassElt(domElt, 'removeClass', "in");
            }

        },

        // fonctions sur boutons
        doNothing() {
        },
        supprimerProprieteouPas() {
            this.menuOuvert=false;
            this.dialog = true;
        },
        supprimerPropriete() {
            this.dialog = false;

            ApiUser.deletePropriete(this.propriete) // on detruit notre UF (en DB locale et SI POSSIBLE en ligne)
            this.$store.commit('donnerMessage', "La propriété "+this.propriete.identifiant+" a bien été supprimée");
            //this.$store.commit('setProprieteId', 0);
            //this.$store.commit('resetDataApplication');

            this.$store.commit('resetTrisFiltresPropriete');
            this.$router.push({ name: "proprietes" });

        },
        refermerSynthese() {
            this.syntheseCourante = this.listeSyntheses[0];
        },

        imprimerSynthese(idSynthese) {
            // Get HTML to print from element
            var today = new Date();
            var mois = today.getMonth()+1;
            mois =  (mois < 10 ? '0' : '') + mois;
            document.getElementById('infosGeneriques').innerHTML = this.propriete.identifiant+" - "+today.getDate()+"/"+mois+"/"+today.getFullYear();

            this.idSynthese = idSynthese;
            //const prtHtml = document.getElementById('aImprimer').innerHTML;
            this.prtHtml = document.getElementById('aImprimer').innerHTML;

            // Get all stylesheets HTML
            //let stylesHtml = '';
            this.stylesHtml = '';
            //var elt = document.querySelector('#synthese');
            //var idElt = "#" + idSynthese;
            var nodes = document.querySelectorAll('link[rel="stylesheet"], style');
            nodes.forEach(node => {
                    this.stylesHtml += node.outerHTML;
                /*
                if (node.outerHTML.indexOf(idElt) != -1) {
                //if (node.outerHTML.indexOf("#synthese") != -1) {
                    stylesHtml += node.outerHTML;
                }
                */
            })

            setTimeout(this.preparerSyntheseTimeout, 245);
            /*
            // Open the print window
            const WinPrint = window.open('', 'Impression synthèse STERES', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            if (WinPrint) {
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                  <meta http-equiv="expires" content="0">
                  <meta http-equiv="pragma" content="no-cache">
                    ${stylesHtml}
                  </head>
                  <body id="app" style="font-size:8px">
                    <div id="${idSynthese}">
                        ${prtHtml}
                    </div>
                  </body>
                </html>`);

                WinPrint.document.close();
                WinPrint.focus();
                this.WinPrint = WinPrint;
                setTimeout(this.imprimerSyntheseTimeout, 200);
            }
            */
        },

        preparerSyntheseTimeout() {
                        // Open the print window
            const WinPrint = window.open('', 'Impression synthèse STERES', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            if (WinPrint) {
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                  <meta http-equiv="expires" content="0">
                  <meta http-equiv="pragma" content="no-cache">
                    ${this.stylesHtml}
                  </head>
                  <body id="app" style="font-size:8px">
                    <div id="${this.idSynthese}">
                        ${this.prtHtml}
                    </div>
                  </body>
                </html>`);

                WinPrint.document.close();
                WinPrint.focus();
                this.WinPrint = WinPrint;
                setTimeout(this.imprimerSyntheseTimeout, 750);
            }
        },

        imprimerSyntheseTimeout() {
            this.WinPrint.print();
            this.WinPrint.close();
        },
        avancerListe() {
            this.trisFiltres.premiereUFsAffichage += this.maxUFsAffichage;
        },
        reculerListe() {
            this.trisFiltres.premiereUFsAffichage = Math.max(this.trisFiltres.premiereUFsAffichage-this.maxUFsAffichage, 0);
        },
        reculerDebutListe() {
            this.trisFiltres.premiereUFsAffichage = 0;
        },
        avancerFinListe() {
            this.trisFiltres.premiereUFsAffichage = this.nbUFsAffichees-this.maxUFsAffichage;
        },
        retrier(idTri) {
            const etatTri = this.trisFiltres[idTri];
            this.trisFiltres.triUF=0;
            this.trisFiltres.triPeuplementAnnee=0;
            this.trisFiltres.triPeuplementSurfaceBoisee=0;
            this.trisFiltres.triUfRecettesPrevuesAnnees=0;
            this.trisFiltres.triUfrecettesPrevuesAnneeRetenue=0;
            this.trisFiltres.triUfDepensesPrevuesAnnees=0;
            this.trisFiltres.triUfValeurReelleSurPiedAnnees=0;
            this.trisFiltres.triUfValeurAttenteAnnees=0;
            this.trisFiltres.triUfCoupeRase=0;
            this.triPeuplementEtatUtilisateur=0;
            this.triPeuplementAge=0;
            if (etatTri) {
                this.trisFiltres[idTri]= etatTri*(-1);
            }
            else {
                this.trisFiltres[idTri]= 1;
            }
        },
        reinitialiserFiltres(chaineId) {
            this.trisFiltres.filtrerIdentifiant=false;
            this.trisFiltres.filtrerAnneeAvant=false;
            this.trisFiltres.filtrerAnnee=false;
            this.trisFiltres.filtrerAnneeApres=false;
            this.trisFiltres.filtrerClasseAge=false;
            this.trisFiltres.filtrerSurfaceBoiseeInf=false;
            this.trisFiltres.filtrerSurfaceBoisee=false;
            this.trisFiltres.filtrerSurfaceBoiseeSup=false;
            this.trisFiltres.filtrerCoupeRaseAvant=false;
            this.trisFiltres.filtrerCoupeRase=false;
            this.trisFiltres.filtrerCoupeRaseApres=false;
            this.trisFiltres.filtrerEtatVALIDE=false;
            this.trisFiltres.filtrerEtatAVALIDER=false;
            this.trisFiltres.filtrerEtatINVALIDE=false;
            this.trisFiltres[chaineId]=true;
        },
        filtreIdentifiant(uf) {
            if ((typeof(this.testIdentifiant) === 'undefined') || (this.testIdentifiant == "")) {
                return (true);
            }
            var idem = uf.identifiant.localeCompare(this.testIdentifiant, 'en', { sensitivity: 'base' });
            if (idem) {
                return(false);
            }
            return (true);
        },
        aContientB(a, b, decalage) {
            var longueur = b.length;
            if (decalage+longueur > a.length) {
                return(0);
            }
            var newA = a.substring(decalage, decalage+longueur)
            if (!newA.localeCompare(b, 'en', { sensitivity: 'base' })) {
                return(1);
            }
            return(this.aContientB(a, b, decalage+1));
        },
        filtreIdentifiantPartiel(uf) {
            if ((typeof(this.testIdentifiant) === 'undefined') || (this.testIdentifiant == "")) {
                return (true);
            }
            return(this.aContientB(uf.identifiant, this.testIdentifiant, 0));
        },
        lesUFsTampon() {
            var UFsTampon = this.lesUFs.slice(0);
            return UFsTampon;
        },
        lesUFsTamponIdentifiant() {
            var UFsTampon = this.lesUFs.filter(this.filtreIdentifiantPartiel);
            /*
            if (!UFsTampon.length) {
                UFsTampon = this.lesUFs.slice(0);
            }
            */
            return UFsTampon;
        },
        filtreAnnee(uf) {
            if ((typeof(this.testAnnee) === 'undefined') || (this.testAnnee == "")) {
                return (true);
            }
            var testAnnee = parseInt(this.testAnnee);
            if ((typeof(uf.peuplement) === 'undefined') || !uf.peuplement || (typeof(uf.peuplement.anneeRetenue) === 'undefined') || isNaN(testAnnee)) {
                return (false);
            }
            var anneeRetenue = uf.peuplement.anneeRetenue;

            if (this.filtrerAnneeAvant) {
                return(anneeRetenue < testAnnee);
            }
            if (this.filtrerAnnee) {
                return(anneeRetenue == testAnnee);
            }
            if (this.filtrerAnneeApres) {
                return(anneeRetenue > testAnnee);
            }

            return(false);
        },
        filtreClasseAge(uf) {
            if ((typeof(this.testClasseAge) === 'undefined') || (this.testClasseAge == "")) {
                return (true);
            }
            var min = parseInt(this.testClasseAge.min);
            var max = parseInt(this.testClasseAge.max);
            if ( ((typeof(uf.peuplement) === 'undefined') || !uf.peuplement || (typeof(uf.peuplement.lAge) === 'undefined')) && (min<0) && (max<0)) {
                return (true); // APs particulier "CR" : pas de peuplement
            }
            if ((typeof(uf.peuplement) === 'undefined') || !uf.peuplement || (typeof(uf.peuplement.lAge) === 'undefined') || isNaN(min) || isNaN(max)) {
                return (false);
            }

            return((uf.peuplement.lAge >= min) && (uf.peuplement.lAge <= max))
        },
        filtreSurfaceBoisee(uf) {
            if ((typeof(this.testSurfaceBoisee) === 'undefined') || (this.testSurfaceBoisee == "")) {
                return (true);
            }
            var testSurfaceBoisee = parseInt(this.testSurfaceBoisee);
            if ((typeof(uf.peuplement) === 'undefined') || !uf.peuplement || (typeof(uf.peuplement.surfaceBoisee) === 'undefined') || isNaN(testSurfaceBoisee)) {
                return (false);
            }
            var surfaceBoisee = uf.peuplement.surfaceBoisee;

            if (this.filtrerSurfaceBoiseeInf) {
                return(surfaceBoisee < testSurfaceBoisee);
            }
            if (this.filtrerSurfaceBoisee) {
                return(surfaceBoisee == testSurfaceBoisee);
            }
            if (this.filtrerSurfaceBoiseeSup) {
                return(surfaceBoisee > testSurfaceBoisee);
            }

            return(false);
        },
        filtreCoupeRase(uf) {
            if ((typeof(this.testCoupeRase) === 'undefined') || (this.testCoupeRase == "")) {
                return (true);
            }
            var testCoupeRase = parseInt(this.testCoupeRase);
            if ((typeof(uf.peuplement) === 'undefined') || !uf.peuplement || (typeof(uf.peuplement.anneeRetenue) === 'undefined')
                || (typeof(uf.coupeRase) === 'undefined') || isNaN(testCoupeRase)) {
                return (false);
            }
            var coupeRase = uf.peuplement.anneeRetenue+uf.coupeRase;

            if (this.filtrerCoupeRaseAvant) {
                return(coupeRase < testCoupeRase);
            }
            if (this.filtrerCoupeRase) {
                return(coupeRase == testCoupeRase);
            }
            if (this.filtrerCoupeRaseApres) {
                return(coupeRase > testCoupeRase);
            }

            return(false);
        },
        filtreEtat(uf) {
            if ((typeof(uf.peuplement) === 'undefined') || !uf.peuplement || (typeof(uf.peuplement.etatUtilisateur) === 'undefined') ) {
                return (false);
            }
            var etatUtilisateur = uf.peuplement.etatUtilisateur;

            if (this.filtrerEtatVALIDE) {
                return(etatUtilisateur == this.etatVALIDE);
            }
            if (this.filtrerEtatAVALIDER) {
                return(etatUtilisateur == this.etatAVALIDER);
            }
            if (this.filtrerEtatINVALIDE) {
                return(etatUtilisateur == this.etatINVALIDE);
            }

            return(false);
        },

        lesUFsTamponParam(filtre) {
            var UFsTampon = this.lesUFs.filter(filtre);
            return UFsTampon;
        },
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
#app {

    h2 {
        span.plusPetit {
            font-size: 0.8em;
        }
        span.avertissement {
            color:#E84B00;

            button.boutonFonctionObjet {
                font-weight: normal;
                background-color: #E84B00;
            }
        }
    }
    #infosAdministratives {
        background-color: transparent;
        padding-top:0;
        /*
        padding-top:2em;
        box-shadow: 4px 4px 4px rgba(60, 80, 79, 0.23);
        */
        h3 {
            margin:0;
            padding:0;
            text-transform: uppercase;
            color:#cdcdcd;
        }
    }
    #infosEconomiques {
        #syntheses {
            background-color: #fcfdfd;
        }
        background-color: white;
        .ecoOuvert {
            padding-bottom:0.2em;
        }
        #choixSynthese {
            .v-input {
                margin:0;
                max-width:250px;
            }

            .v-input__control {
                width: 480px;
                max-height: 3em;

                .v-select__slot {
                    /*background-color: #2C5747;*/
                    background-color: #3E7B64;
                }
                .v-text-field__details {
                    display:none;
                }
            }
        }
    }
    .alerte .gauche {
        margin-top: 1em;
    }

    .masquerDemasquer {
        cursor: pointer;
        font-size: 1.1em;
        color: #238B71;
        i {
            font-size: 1.8em;
        }
        span {
            vertical-align: text-bottom;
            font-weight: bold;
            line-height: 2em;
        }
    }

    #infosForestieres {
        background-color: #F2F2F2;

        table {
            margin:1%;
            width:98%;
            font-size:1.1em;
            font-weight: bold;

            tr.header {
                background-color: transparent;

            }
            tr.in .liens, tr.liens td.lienUnique:hover {
                background-color: #F4F9F8;
                /*color:black;*/

            }
            tr.sansBord {
                td {
                    padding-bottom: 0;
                }
                &.listeVide {
                    color:orangered;
                }
            }
            th.etat, td.etat {
                /*min-width: 110px;*/
                min-width: 80px;
            }
            th {
                padding:2em 16px 2em 0;
            }
            td {
                padding:2em 8px;
            }
            td, th {
                /*padding:2em 1.2em;
                border-bottom:1px solid #ddd;*/
                max-width: 120px;
                /*min-width: 140px;*/
                &.crayon {
                    /*
                    min-width: 80px;
                    width: 80px;
                    */
                    min-width: 20px;
                    width: 20px;
                }
                &.coupeRase {
                    min-width: 120px;
                    max-width: 130px;
                }
            }
            td.lien {
                /*color:#238b71;*/
                &.uf {
                    background-color: #FBFBFB;
                }
                &.classeAge {
                    font-size:0.8em;
                    padding-top:2.2em;
                    color:#aaa;
                    vertical-align: middle;
                }
            }
            td.etat {
                text-align: center;
            }
            .sousHeader {
                td, th {
                    padding:1em 1.2em;
                    color:#777777;
                }
            }
            .sousTotaux, .actionTris {
                td, th {
                    border-bottom: 0px solid #AFACAB;
                }

            }
            .sousTotaux {
                th {
                    padding-bottom:0;
                    padding-top:1em;
                    color:#777777;
                }
            }
            .actionTris {
                th {
                    padding:0.4em 0 0;
                    cursor:pointer;
                    i.svg {
                        color:#238b71;
                        font-size:120%;
                    }
                    &:hover {
                        i.svg {
                            color:#223938;
                        }
                    }
                    &.actif {
                        i.svg {
                            font-size:160%;
                            color:#37CCA7;
                        }
                        &:hover {
                            i.svg {
                                color:#92E2CE;
                            }
                        }
                    }
                    &.nombre {
                        padding-right: 2em;
                    }
                }

            }
            span.avertissement {
                color:#E84B00;
            }
            span.infoBlocageBouton {
                font-size:0.7em;
                font-weight: normal;
                color:#E84B00;
                text-align: left;
                display: inline-block;
            }

        }
    }


    .lectureSeule {
        .menuContextuel.menuSecours {
            display:block;
            span.lien {
                background-color: #583D3D;
                cursor:pointer;

                i.fas {
                    color: wheat;
                }
            }
        }

        #infosForestieres {
            tr.liens {
                td.lienUnique {
                    cursor:default;
                    &:hover{
                        background-color: transparent;
                    }
                }
                td.liens {
                    cursor:pointer;
                }
            }

        }

        /*.boutonFonctionObjet, .iconeFct, i.svg { 2021/04 SVG montrables ...*/
        .boutonFonctionObjet, .iconeFct {
            display:none;
        }
        .UFsList {
            td.lienUnique {
                span {
                    cursor:default;
                }
            }
        }
    }



    div.infosGlobal {
        border-top:2px solid #eaeaea;
        padding-top:1em;
        display:inline-block;
    }
    div.proprieteInfos {
        margin : 0 0 1em;
    }
    div.proprietaireInfos  {
        margin-left:6em;
        margin-right:4em;
    }


    #infosForestieres {
        .liste {
            border-spacing: 0px;

            td, th {
                border-top: 0px solid #AFACAB;
                border-bottom: 1px solid #AFACAB;
                text-align: right;
                &.centrer {
                    text-align: center;
                }
                &.aGauche {
                    text-align: left;
                }
            }
            td {
                &.uf {
                   text-transform: uppercase;
                    min-width: 200px;
                }
                &:first-child {
                    border-right: 1px solid #DFEAE1;
                }
                &:last-child {
                    border-right: 1px solid #DFEAE1;
                }
            }
            .sansBord {
                td {
                    border:none;
                }
            }

            .navigationListe {
                color:#238b71;
                td {
                    padding-bottom:1em !important;
                }
                &.haut {
                    td {
                        padding-top: 0.4em !important;
                        padding-bottom: 0.4em !important;
                    }
                }
                .actif {
                    cursor:pointer;
                }
                .inactif {
                    opacity: 0.5;
                }
            }

            tr.filtres {
                td {
                    /*
                    text-align: left;
                    padding:1.4em 0.4em 0.6em ;
                    */
                    text-align: center;
                    padding:1.4em 20px 0.6em 0;
                    border-right: 1px solid #fff;

                    &:first-of-type {
                        padding-left:0;
                    }
                    &:last-of-type {
                        border:none;
                    }
                    .conteneurPourRadio {
                        display:inline-block;
                        .pourRadio {
                            margin:8px 0;
                            color:#777;
                            .operateur {
                                width: 12px;
                                display: inline-block;
                                text-align: center;
                            }
                            .labelEtat {
                                text-align: left;
                                font-size:0.9em;
                                font-weight: normal;
                                width:54px;
                                display: inline-block;

                                &.etatVALIDE {
                                    border-bottom:1px solid green;
                                }
                                &.etatAVALIDER {
                                    border-bottom:1px solid orange;
                                }
                                &.etatINVALIDE {
                                    border-bottom:1px solid red;
                                }
                            }
                        }
                    }
                    &.filtrage {
                        min-width: 106px;
                    }
                    &.centrageVert {
                        .v-input {
                            &.v-text-field {
                                transform: translateY(-28px);
                            }
                        }
                    }

                    .v-input {
                        font-size:0.8em;
                        background-color: #fff;
                        color:#777;
                        padding: 0.1em 0;
                        border:1px #ddd solid;
                        border-radius: unset;
                        font-weight: normal;
                        display:inline-block;

                        &.v-text-field {
                            width: 88%;
                            margin:0 1% 0 0;

                            &.petit {
                                max-width: 60px;
                            }
                        }

                        &.v-select  {
                            width: 88%;
                            margin:0 1% 0 0;

                            .v-select__slot {
                                border-radius: 0;
                                background-color: transparent;
                                padding: 0 0.3em;

                                .v-select__selection {
                                    color: #777;
                                    margin:0;
                                }
                                .v-icon {
                                    color: #777;
                                }
                                label {
                                    top:0 !important;
                                }
                                .v-input__append-inner {
                                    position: absolute;
                                    right: 0;
                                }
                            }
                        }

                        &.v-input--checkbox, &.v-input--radio-group {
                            background-color: transparent;
                            border:none;
                            margin:0;
                            padding: 0;
                            .v-input__slot {
                                padding:0;
                                margin:0;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                .v-input--selection-controls__input {
                                    padding:0;
                                    margin:0;

                                    input {
                                        color:#ddd;
                                    }

                                    .v-icon {
                                        color:#777;
                                    }
                                }
                            }
                        }
                        .v-input__slot {
                            padding:0 0.4em;
                            margin:0;
                            .v-label {
                                color:#aaa;
                                top: 0;
                                font-size:1.0em;
                            }
                            &:before {
                                display:none;
                            }
                            &:after {
                                display:none;
                            }
                            input {
                                color:#333;
                            }
                            input[type=number] {
                                /* Firefox */
                                -moz-appearance: textfield;

                                /* Chrome */
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin:0;
                                }

                                /* Opéra*/
                                &::-o-inner-spin-button {
                                    -o-appearance: none;
                                    margin: 0;
                                }
                            }
                        }
                        .v-text-field__details {
                            display:none;
                        }
                    }
                }
            }
        }
    }

    .UFsList {
        span {
            cursor:pointer;
            text-align: left;
        }
        .uf {
            span {
                margin-left:30px;
                display:block;
            }
            img.iconeFct {
                /*float: none;*/
                margin-top: 0.2em;
            }
            i.svg {
                 vertical-align: middle;
                 float:left;
            }
        }

        &.etatVALIDE, &.etatINVALIDE, &.etatAVALIDER {
            td:NOT(.uf) {
                i.svg {
                    margin-left:0.4em;
                }
            }
        }
        i.svg {
            &.etatVALIDE {
                color:green;
            }
            &.etatAVALIDER {
                color:orange;
            }
            &.etatINVALIDE {
                color:red;
            }
        }
/*
        &.etatINVALIDE {
            td:NOT(.uf) {
                color:red;
            }
        }
        &.etatAVALIDER {
            td:NOT(.uf) {
                color:orange;
            }
        }
*/
    }

    .fadeHeight-enter-active, .fadeHeight-leave-active {
        transition: all 0.2s;
        max-height: 230px;
    }
    .fadeHeight-enter, .fadeHeight-leave-to {
        opacity: 0;
        max-height: 0px;
    }


@media screen and (max-width: 800px) {

    div.proprietaireInfos, div.gestionnaireInfos   {
        margin-left:4em;
        margin-right:0;
    }
}
@media screen and (max-width: 600px) {

    div.proprietaireInfos, div.gestionnaireInfos   {
        display:none;
    }
}
}

</style>
