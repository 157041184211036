import lf from 'lovefield'
import { generateSchemas } from './schemas/index.js'
import * as SteresConst from '../steres_const.js'
//import { NWfunctionCheck, NWCheck, NWcopyUserDataFile, NWwriteFile, NWresetTerrassor, NWreloadTerrassor, NWgetUserTempDataFilePath } from '../../nw/NWfunctions.js'

import { SteresDB } from './SteresDB.js'
import { addeoData } from "../api/datas.js";
import { ApiConsts} from "../api/index.js"

//import dateFns from 'date-fns'

class LovefieldDB {

  constructor(data) {
    this.db = null;
    this.refs = {};
    this.allJsonData = {};
    this.lf = lf;  // BC 2019/10
  }

  bindAllJsonData(data) {
      this.allJsonData = data;  
  }

dbName() {

    var leDomaine = window.location.hostname;
    if ( (leDomaine !== "steres.addeo.com") && (leDomaine.indexOf("localhost")< 0) ) {
        console.log('lf dbName : ', SteresConst.DATABASE_NAME_PROD);
        return(SteresConst.DATABASE_NAME_PROD);
    }
    else {
        console.log('lf dbName : ', SteresConst.DATABASE_NAME_DEV);
        return(SteresConst.DATABASE_NAME_DEV);
    }
}

/*
* Lecture des données json et 
* 1. rattachement "as is"
* 2. création ou update de la base STERES si nécessaire
*/
loadData(allConstData, allUserData) {

    if (allConstData && allUserData) {
        /*
        allConstDataTMP.const_toto1[0] = allConstData.data;
        allUserDataTMP.user_toto2[1] = allUserData.data;
        //var allDataTables = this.mergeDatas(allConstDataTMP, allUserDataTMP);
        */
        var allDataTables = this.mergeDatas(allConstData.data.tables, allUserData.data);
        var allJsonData =  {"name": this.dbName(), "version": SteresConst.REQUIRED_DATABASE_VERSION, "tables":allDataTables};

        this.bindAllJsonData(allJsonData);
    
        var allJsonDataDuplicate = JSON.parse(JSON.stringify(allJsonData));
  
        return this.getDbConnection() 
            .then(db => {
                if (!db) {
                    console.log('loadData : base non ouverte 1');
                    return;
                }

                // si la base est vide, on charge la tables des communes et on continue sans attendre
                this.isDBEmpty()
                .then(isEmpty => {
                    if(isEmpty) {
                        addeoData.lireCommunes()
                        .then(allCommunes => {
                            this.enregistrerItemsLocaux(allCommunes.data.datas_communes, "datas_communes")
                        })
                    }
                    else {
                        ApiConsts.countCommunesFromTable()
                        .then(results => {
                            if ((typeof(results) == 'undefined') || !results || (!results.length) || (results[0]['COUNT(id)'] ==0)) {
                                addeoData.lireCommunes()
                                .then(allCommunes => {
                                    this.enregistrerItemsLocaux(allCommunes.data.datas_communes, "datas_communes")
                                })
                            }
                        })
                    }
                })

                console.log('version actuelle de Steres', db.getSchema().version());
                this.dbVersion = db.getSchema().version();
                console.log('loadData : allJsonDataDuplicate', allJsonDataDuplicate);
                return(LovefieldService.importData(allJsonDataDuplicate));
            })
    }
    else {        
        return this.getDbConnection() 
            .then(db => {
                if (!db) {
                    console.log('loadData : base non ouverte 2');
                    return;
                }
                return this.isDBEmpty()
                    .then(isEmpty => {
                        if(isEmpty) {
                            alert("Votre application ne peut être lancée faute de connexion et de données préchargées");
                            return(null);
                        }
                        else {
                            return Promise.resolve(this.db);
                        }
                })
            })
    }
}


/*
* Création / ouverture de la base (force la recréation en cas d'upgrade nécessaire) 
*/
  getDbConnection() {
//console.log('getDbConnection')
    if (this.db != null) {
//console.log('getDbConnection not null')
      return Promise.resolve(this.db);
    }

//console.log('getDbConnection : null')
    var schemaBuilder = this.buildSchema();

   return Promise.resolve(schemaBuilder.connect({'onUpgrade': this.onUpgradeDB}))
   //return Promise.resolve(schemaBuilder.connect())
      .then(db => {
        if (typeof(db) === 'undefined') {
            this.db = null
            return(null)
            }
        this.db = db
//console.log('getDbConnection : getSchema')
        this.dbVersion = db.getSchema().version()
        this.onConnected()
        return db;
      })  
  }

/* 
*
*/
  checkDBVersions() {
//console.log('getDbConnection : checkDBVersions')
    if(this.dbVersion < SteresConst.REQUIRED_DATABASE_VERSION)  {
        //vide la base et recharger l'application
      //NWresetTerrassor()
    }
}

/*
*  Pour ttes les tables
* this.refs.<tablename> <- schema de la table
*/
  onConnected() {
    let dbSchema = this.db.getSchema()
    var tables = dbSchema.tables()

    // ajouter les références des tables directement dans this.refs
    // exemple : this.refs.const_cubaturestypes = dbSchema.table("const_cubaturestypes")

    tables.forEach(t => { // version std du foreach  : array.forEach(function(currentValue, index, arr), thisValue)
      name = t.getName();
      this.refs[name] = t;
    }, this);
  }


/*
*
*/
onUpgradeDB(rawDB) {
  var ver = rawDB.getVersion()
  var requiredVer = SteresConst.REQUIRED_DATABASE_VERSION
  if(ver == 'undefined' || ver == 0)
     return Promise.resolve(console.log('LovefieldService::onUpgradeDB', ver))

  if(ver < requiredVer)  {
      console.log('onUpgradeDB : upgrade', ver, requiredVer)
        //vider la base et recharger l'application
        // NWresetTerrassor()
      LovefieldService.refreshSteres();
    }
    else {
      console.log('onUpgradeDB : no upgrade', ver, requiredVer)
    }
}

refreshSteres() {
    console.log("reloadSteres, delete DB")
    var request = window.indexedDB.deleteDatabase(this.dbName());
    request.onsuccess = function(e) {
      console.log("Base de données Steres supprimée, rechargement de la page",e)
      //NWreloadTerrassor()
      LovefieldService.reloadSteres();
      };
    request.onerror = function(e) {
      console.log("Erreur lors de la suppression de la BD Steres", e)
      alert("Erreur lors de la suppression de la BD Steres")
    };
    request.onblocked = function(e) {
      console.log("BD Steres : blocked state", e)
      LovefieldService.reloadSteres();
      //NWreloadTerrassor()
    };

}
reloadSteres() {
        setTimeout('window.location.reload(true)', 1000);
}

  /*
  *   Création des tables (if not exists !)
  */
  buildSchema() {
console.log('buildSchema')
    // le schema builder va fournir les primitives comme createTable
    var schemaBuilder = lf.schema.create(this.dbName(), SteresConst.REQUIRED_DATABASE_VERSION);
console.log('buildSchema : create')
    this.schemaBuilder = schemaBuilder

    // generateSchemas; voir dans db/schemas/index.js    
    generateSchemas(schemaBuilder)
console.log('buildSchema : generateSchemas')
    return schemaBuilder;
  }

/*
* 
* Pour ttes les tables, vérifier si elles ont des données ou non
*/
  isDBEmpty() {
    var tableNames = Object.keys(this.refs)
    var allSelectQueries = tableNames.map(name => this.db.select().from(this.refs[name]).exec())

    return Promise.all(allSelectQueries)
    .then(results => {
      return results.map(resultOfQuery => resultOfQuery.length).every(len => len === 0)
    })
    
  }

/*
* Not used ???
*/
  dropAllTables() {

console.log('dropAllTables');
  return this.getDbConnection()
    .then(db => {
console.log('dropAllTables : getDbConnection ok');
        var tableNames = Object.keys(this.refs)
    /*  tableNames.forEach(t => {
        this.db.dropTable(t) // code RA
      });*/
        console.log('dropAllTables : job must be done');
        var allSelectQueries = tableNames.map(name => this.db.delete().from(this.refs[name]).exec()); // BC
        return Promise.all(allSelectQueries)
        })
    .then(results => {
      console.log('dropAllTables : job is done');
      return results
    })
}

/*
*
*/
 exportAllSteres() {
      return this.getDbConnection()
      .then(db => {
         return db.export()
      })
      .then(data => {
        console.log('exportAllSteres : DB exported to json successfully', data)
        return data
      })
  }

/*
*
*/
globalTables () {

    return(SteresConst.GLOBAL_TABLES);
 }
/*
*
*/
userTables () {
    return(SteresConst.USER_TABLES);
 }
/*
*
*/
userProprieteTables () {
    return(SteresConst.USER_PROPRIETE_TABLES);
 }
/*
* recursivite sur synchroniserTable
*/
async synchroniserTableRecursivite(tableNames, occurence, synchroGlobale, dataTables)
    {
         await this.synchroniserTable(synchroGlobale, tableNames[occurence], dataTables)
             .then(resultBidon => {
                if (tableNames.length-1 == occurence) {
                    return(resultBidon)
                }
                return(this.synchroniserTableRecursivite(tableNames, occurence+1, synchroGlobale, dataTables));
         })
    }

/*
* 1/ liste des tables synchronisables (automatique CONST et validation USER)
* 2/ id unique ou dupliqués
* 3/ champs last updated dans chaque table (mais quid des ordis pas à l'heure)
* 4/ Validation de l'utilisateur
* NB : il s'agit d'une synchronisation de la base distante vers le local
* la synchro de la base distante sera faite par un PUSH (attention à la gestion des id) - il faudra recréer les ID sur la base distante puis les remettre à jour sur la base locale
* => il FAUT donc passer par des codes qui devront être uniques et stables (concatenation ID utilisateur)
*/
 synchroniserGlobal(dataTables) { // TODO
     var synchroGlobale = true;
     var tableNames = this.globalTables()
     return(this.synchroniserTableRecursivite(tableNames, 0, synchroGlobale, dataTables))
     /*
     tableNames.forEach(name => {
         this.synchroniserTable(synchroGlobale, name, dataTables); // passer par mécanisme de promesses multiples
     })

console.log('synchroniserGlobal : before resolve');
    return Promise.resolve(this.db);
    */
 }

/*
***
*/
synchroniserUser(data) {
console.log('synchroniserUser');
     var synchroGlobale = false;  // BC 2019/12 - on supprime tout cf. synchroniserTable - attention pour la synchro il faudra reprendre l'algo pour ne detruire que ce qui n'est pas à jour
     synchroGlobale = true; // du coup on peut passer le param à true pour le moment
    // (NB les datas non publiées ne sont pas passées par l'admin)
     var tableNames = this.userTables()
     return(this.synchroniserTableRecursivite(tableNames, 0, synchroGlobale, data))
/*
     tableNames.forEach(name => {
         this.synchroniserTable(synchroGlobale, name, data); // passer par mécanisme de promesse multiples
     })
console.log('synchroniserUser : before resolve');
     return Promise.resolve(this.db);
*/
 }
    
 synchroniserProprieteUFs(data, synchroGlobale)  { //  synchroGlobale = true;  // BC 2019/12 - on supprime tout cf. synchroniserTable - attention pour la synchro il faudra reprendre l'algo pour ne detruire que ce qui n'est pas à jour
     var tableNames = this.userProprieteTables();
     return(this.synchroniserTableRecursivite(tableNames, 0, synchroGlobale, data))
}

/*
* 1/ liste des tables synchronisables (automatique CONST et validation USER)
* 2/ id unique ou dupliqués
* 3/ champs last updated dans chaque table (mais quid des ordis pas à l'heure)
* 4/ Validation de l'utilisateur
* 5/ Quid des enregistrements supprimés ? Ne aps supprimer mais juste champ ACTIF ?
*/
 async synchroniserTable(synchroGlobale, nomTable, dataTables) { // TODO promesses ?
     
     // si on ne reçoit rien, on ne fait rien ...
     if ((typeof(dataTables) == 'undefined') || (typeof(dataTables[nomTable]) == 'undefined')) {
         return
     }     // si on ne reçoit rien, on ne fait rien ... ou presque
     if (dataTables[nomTable].length == 0) {
         if (synchroGlobale == 1) {
             return SteresDB.truncate(nomTable)
         }
         return SteresDB.readAllFromTable(nomTable) // ce read ne sert à rien mais il permet la gestion d'une promesse
     }
    var donneesDistantes = dataTables[nomTable];
     if (synchroGlobale) {
         if (synchroGlobale > 1) { // 2020/12 : reception des données 100+n : on copie mais on ne vide pas la table
             return(this.enregistrerItemsLocaux(donneesDistantes, nomTable));
         }
         else {
             return SteresDB.truncate(nomTable)
                 .then(resultBidon => {
                 return(this.enregistrerItemsLocaux(donneesDistantes, nomTable));
             })
         }
     }
     else {
// 2020/11 - cette destruction ne devrait pas se faire ?
// return SteresDB.truncate(nomTable)
//        .then(resultBidon => {
            return SteresDB.readAllFromTable(nomTable)
            .then(donneesLocalesArray => {
                //console.log('synchroniserTable : synchroniserItems', nomTable);
                var allPromises = [];
                // pour chaque itm reçu, on le met à jour s'il n'existe pas ou qu'il est plus récent (version)
                donneesDistantes.forEach(item=> {
                    var promise = this.synchroniserItemLocal(item, donneesLocalesArray, nomTable)
                    allPromises.push(promise);
                })
                //return(donneesDistantes);
                return Promise.all(allPromises)
            })
//        })
     }
     // on vachercher dans la table, 
     // 1 - tous les enregistrements manquants
     // 2 - tous les enregistrements pas à jour / pour ces derniers, on utilise uniquement un compteur de version
 }
    

/*
* met à jour si item n'existe pas ou qu'il est plus récent (version)
* attention chaque table doit posseder un id !!!!!!!!!!!!!!!!!!!!!!!!!
*/
async synchroniserItemLocal(itemDistant, donneesLocales, nomTable) {
    var existe = false;
    var itemLocalReg = null;
    donneesLocales.forEach(itemLocal=> {
        if (itemLocal.id == itemDistant.id) {
            existe = true;
            itemLocalReg = itemLocal;
        }
    })
    if (existe) {
        //var versionLocale = itemLocal[SteresConst.CHAMP_VERSION];
        var versionLocale = itemLocalReg;
        var versionDistante = itemDistant[SteresConst.CHAMP_VERSION];
        if ((typeof(versionLocale) === 'undefined') || (typeof(versionDistante) === 'undefined') || (versionLocale < versionDistante)) {
            // on enregistre l'élément distant dans notre base
            //return(this.enregistrerItemLocal(itemDistant, nomTable, itemLocal));
            return(this.enregistrerItemLocal(itemDistant, nomTable, itemLocalReg));
        }
    }
    else {
        //console.log('synchroniserItem : nouvel item', itemDistant.id, nomTable)
        // on enregistre l'élément distant dans notre base
        return(this.enregistrerItemLocal(itemDistant, nomTable));
    }
}

/*
* met à jour si item n'existe pas ou qu'il est plus récent (version)
*/
enregistrerItemLocal(item, nomTable, itemOld = null) {
    if (itemOld) {
        itemOld = item
    }
    return(SteresDB.saveRowInTable (item, nomTable))
}
/*
* met à jour si items n'existent pas ou plus récents : optimisé car toutes les requêtes créées en une seule fois
*/
enregistrerItemsLocaux (items, nomTable) {
    return(SteresDB.saveRowsInTable (items, nomTable))
}

/*
* Init des données à partir des tables exportées de MySQL cf http://asco-pre.privilis.com/exports/json
* Ne marche que sur une BD vide. Ici on simplifie en testant la présence d'au moins une table
* RA 2017.03.20; transformer les dates de mouvements en Date()
*/
  importData(data) {
    return this.isDBEmpty()
    .then(isEmpty => {
      if(!isEmpty) {
// BC STERES - attention ici il faudra mettre en place un contrôle de cohérence entre les données locales et les données en ligne
// il faudra probablement séparere le traitement des données utilisaterus et les autres
// les données utilisateurs seront celles qui doivent être basculées en ligne en cas de besoin ...  
           return(this.synchroniserGlobal(data.tables));
      }

      console.warn('Réinitialisation de la base STERES.')        
      return this.db.import(data)
    })// fct native lf
    .then(() => this.db)
  }

// BC STERES - attention ici il faudra mettre en place un contrôle de cohérence entre les données locales et les données en ligne
// il faudra probablement séparere le traitement des données utilisateurs et les autres
// les données utilisateurs seront celles qui doivent être basculées en ligne en cas de besoin ...
    importUserDatas(userDatas) {
        return this.isDBEmpty()
        .then(isEmpty => {
            if(!isEmpty) {
                return(this.synchroniserUser(userDatas)); // on doit toujour passer ici
        }

      console.warn('importUserDatas : erreur base vide : Réinitialisation de la base STERES avec données partielles !')
      return this.db.import(userDatas)
    })// fct native lf
    .then(() => this.db)

    }

/*
    var lanalyse = this.transformDatesAnalyse(data.tables['simu_analyses'][0])
    data.tables['simu_analyses'][0] = lanalyse

    var m_array = this.transformDatesMouvements(data.tables['simu_mouvements'])
    //data['simu_mouvements'] = m_array // BC 2017/11 arghhhh - il manque .tables .... est-ce que ça veut dire que la bidouille au dessus ne sert à rien ?
        // où plus probablement, si je comprends bien, que transformDatesMouvements agit directement sur l'objet passé en paramètre :)
    data.tables['simu_mouvements'] = m_array

    return this.isDBEmpty()
    .then(isEmpty => {
      if(!isEmpty) {
              console.warn("La base Terrassor est déjà renseignée. L'import n'est disponible que pour une base vide.");
              console.warn('Utilisation des données actuelles de la base.');
              console.warn(this.db);
              return Promise.resolve(this.db);
      }

      console.warn('Réinitialisation de la base Terrassor.')        
      return this.db.import(data)
    })// fct native lf
    .then(() => this.db) 
  }
*/   

  /*
  * Champs debut et fin de simu-mouvements passés du format Json/ISOString en objet Date()
  */
  /*
  transformDatesMouvements(m_array) {
  if(!m_array)
    return;
  m_array.forEach(m => {
    m.debut = new Date(m.debut)
    m.fin = new Date(m.fin)
  })
  return m_array
}
  transformDatesAnalyse(analyse) {
  if(!analyse)
    return;

    if ((typeof(analyse.date_etude) != 'undefined') && analyse.date_etude) {
        analyse.date_etude = new Date(analyse.date_etude)
    }
    if ((typeof(analyse.date_validation) != 'undefined') && analyse.date_validation) {
        analyse.date_validation = new Date(analyse.date_validation)
    }

      return analyse
}
*/


  exportData() {
    return this.db.export()
      .then(data => {
        console.log('exportData : DB exported to json successfully', data)
        return data
      })
  }
    
    
  /*
* Ajout des tables de l'utilisateur
*/  
 mergeDatas (const_data, user_data) {
      
     Object.keys(user_data).forEach(function(key) { const_data[key] = user_data[key]; });
	 return const_data;
     
  //   var datas = jQuery.extend(c_data, u_data); // priorité à consts
//  return datas

}

  /*
  *
  */

  /*
* nom des tables élève (étude)
*/
/*
 eleveDataTables (complet) {
    var tableNamesComplet = ['simu_analyses','simu_cubatures','simu_echelons','simu_echelons_simu_engins','simu_eleves', 'simu_engins', 'simu_etudes',
                    'simu_etudes_exo_cubatures_exo_mouvements', 'simu_installations', 'simu_installations_const_installations', 
                    'simu_mouvements',
                    'simuexo_chantiers', 'simuexo_cubatures', 'simuexo_cubatures_couches', 'simuexo_mouvements', 'simuexo_mouvements_simuexo_cubatures',
                    'exo_chantiers', 'exo_cubatures', 'exo_mouvements', 'exo_cubatures_exo_mouvements'];
    var tableNames = ['simu_analyses','simu_cubatures','simu_echelons','simu_echelons_simu_engins','simu_eleves', 'simu_engins', 'simu_etudes',
                    'simu_etudes_exo_cubatures_exo_mouvements', 'simu_installations', 'simu_installations_const_installations', 
                    'simu_mouvements',
                    'simuexo_chantiers', 'simuexo_cubatures', 'simuexo_cubatures_couches', 'simuexo_mouvements', 'simuexo_mouvements_simuexo_cubatures'];
                    // 'simuexo_chantiers', 'simuexo_cubatures', 'simuexo_cubatures_couches', 'simuexo_cubatures_simuexo_cubatures']
     if (complet) {
            return(tableNamesComplet);
         }
    return(tableNames);
 }
*/


  /*
* Ajout des tables simu de l'élève ; si pas de donbnées élèves, vider les tables 'simu'
*/
/*    
 mergeChantierAndEleveData (c_data, e_data, consts)
{

if(!consts)
    return
    var complet = false;
if(!c_data) {
    c_data = {};
    complet = true;
}

console.log('mergeChantierAndEleveData : consts', consts)
console.log('mergeChantierAndEleveData : c_data', c_data)
console.log('mergeChantierAndEleveData : e_data', e_data)


  var datas = jQuery.extend(c_data, consts); // priorité à consts

  var tableNames = this.eleveDataTables(complet)
  
  if(!e_data || e_data.length == 0)
  {
    tableNames.forEach(name => {datas[name] = []})
    return datas
  }

//remplacer / insérer les données élèves
console.log('mergeChantierAndEleveData 1 : datas', datas)
 tableNames.forEach(name => {
console.log('mergeChantierAndEleveData : tableNames.forEach - ', name)
     datas[name] = e_data[name]
 })
console.log('mergeChantierAndEleveData 2 : datas', datas)

 return datas

}
*/



  // -----------------------

 }

export const LovefieldService = new LovefieldDB()
