<template>
 <div>

<v-dialog v-model="dialog"  persistent>
      <v-card>
        <v-card-title class="headline">Saisie de la série de surfaces terrières</v-card-title>

        <v-card-text>
            <p class="consigne">
                Indiquez les surfaces terrières mesurées en m²
                <br/><br/>
                NB : Pour une surface boisée de {{surfaceBoisee}} ha (soit {{nbPlacettes}} placettes), nous préconisons une série de {{nbPreconise}}.
            </p>
        </v-card-text>


          <div class="modaleConteneur" v-if="ready">

              <div class="itemListEdit" v-for="(laSaisie, numSaisie) in lesSaisies" :key="numSaisie" >
                <span v-if="tabIds.length" class="pour-v-text-field messageError cacher" :id="tabIds[numSaisie]">Avant de valider à nouveau, vous devez saisir un nombre dans le champ ci-dessous ou utiliser la poubelle pour le supprimer.</span>
                <v-text-field
                    :label="leLabel(numSaisie+1)"
                    v-model="form.surfaceTerriere[numSaisie]"
                    type="number"
                     @keydown="filtrerNombresPositifs"
                    min=1
                    required
                    ref="numSaisie"
                ></v-text-field>
                <!-- <img class="iconeFct" alt="Supprimer saisie" src="@/assets/img/poubelle.svg" @click.stop="supprimerSaisie(numSaisie)" />  -->
                <span class="iconeFct svg"  @click.stop="supprimerSaisie(numSaisie)"><i class="fa fa-trash svg" aria-hidden="true"></i></span>
            </div>

        <a class="" @click="ajouterSaisieVide">+ Ajouter une saisie</a>

        </div>




        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="lienAnnulerModeForet" text @click="annulerSerie()">Annuler</v-btn>
          <v-btn  text @click="validerSerie()">Valider</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

</div>
</template>


<script>

/*
import { validationMixin } from 'vuelidate'
import { required, maxLength, email, minValue } from 'vuelidate/lib/validators'
*/
import { ApiTools } from "../../../js/api/index.js"


export default {

 //   mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        initDatas: {
            type: Object
        },
        ouvrirDialog : Boolean,
        surfaceBoisee : Number,
    },

    data() {
        return {
            dialog: this.ouvrirDialog,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.initDatas)),
            old: null,
            tabIds:[],
            ready:false,
        }
    },

    created() {
        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        //this.old = ApiTools.clone(this.form);
        //this.dialog = true;

        this.remplirTabIds(100);
        this.ready = true;

    },

    mounted() {
        // on donne le focus
        setTimeout(this.donnerFocus.bind(), 100);
    },

    validations() {
    },

    computed: {
        nbPreconise() {
            return (/*5*/1*(this.nbPlacettes));
        },
        nbPlacettes() {
            return (3*(1+Math.floor((this.surfaceBoisee-0.0000001)/5)));
        },
        lesSaisies() {
            if (!this.form.surfaceTerriere.length) {
                this.ajouterSaisieVide();
            }
            return this.form.surfaceTerriere;
        }
    },

    methods: {
        leLabel(rang) {
            return("Surface terrière : " + rang + "/" + this.nbPreconise);
        },
        donnerFocus() {
            this.$nextTick(() => {
                this.$refs.numSaisie[0].focus();
            })
        },
        // gestion des saisies
        reinitialiserObjetTabSurfaceTerriere() {
            var tab = [];
            this.form.surfaceTerriere = tab.concat(this.form.surfaceTerriere);
        },
        ajouterSaisieVide() {
            var tabSurfaceTerriere = this.form.surfaceTerriere;
            var position = tabSurfaceTerriere.length;
            tabSurfaceTerriere[position] = "";
            // pour forcer le reaffichage
            this.reinitialiserObjetTabSurfaceTerriere();
                        // on donne le focus quand on est prêt
            this.$nextTick(() => {
                this.$refs.numSaisie[position].focus();
            })

        },
        supprimerSaisie(numSaisie) {
            this.form.surfaceTerriere.splice(numSaisie, 1);
            // pour forcer le reaffichage
            this.form.surfaceTerriere = this.form.surfaceTerriere.concat([]);

                        // on donne le focus s'il n'en reste qu'un
            if (this.form.surfaceTerriere.length == 1) {
                this.$nextTick(() => {
                    this.$refs.numSaisie[0].focus();
                })
            }
        },


        validerSerie() {

            var ok = true;
            if ((this.form.surfaceTerriere.length == 0) || ((this.form.surfaceTerriere.length == 1) && this.form.surfaceTerriere[0]=="")) {
                this.form.surfaceTerriere = [];
            }
            else {
                for (var i=0; i<this.form.surfaceTerriere.length; i++) {
                    var eltMessage = document.getElementById("m"+i);
                    if (this.form.surfaceTerriere[i] == "") {
                        ok = false;
                        ApiTools.toggleClassElt(eltMessage, 'removeClass', "cacher");
                    }
                    else {
                        var leNumber = parseInt(this.form.surfaceTerriere[i]);
                        this.form.surfaceTerriere[i] = leNumber;
                        ApiTools.toggleClassElt(eltMessage, 'addClass', "cacher");
                    }
                }
            }

            if (ok) {
                this.$emit('retourSurfaceTerriereSerie', this.form.surfaceTerriere)
            }
        },
        annulerSerie() {
            this.dialog = false;
            this.form = JSON.parse(JSON.stringify(this.old));
            this.$emit('retourSurfaceTerriereSerie', this.form.surfaceTerriere)
        },

        // pour la saisie
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },
        // This can also prevent copy + paste invalid character
        filterInput(e){
            e.target.value = e.target.value.replace(/[^0-9]+/g, '');
        },

        // tableau rempli
        remplirTabIds(max) {
            for (var i = 0; i <= max; i++) {
                this.tabIds.push("m"+i);
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {
    .v-dialog {
        i.svg {
            font-size: 1.8em;
            float:none;
        }
    }
}
</style>
