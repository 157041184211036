<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="peuplementEditionEclaircie" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="eclaircie.id" class="blocACompleter">Edition d'{{depressageOuEclaircie}}</h1>
                <h1 v-if="!eclaircie.id" class="blocACompleter">Ajout d'{{depressageOuEclaircie}}</h1>

                <span v-if="eclaircie.id && !$store.getters.modeForet" class="complementBloc">
                <v-btn class="boutonFonctionObjet" type="button" @click="supprimerEclaircieouPas">Supprimer</v-btn>
                </span>


                <p class="info">Les champs suivants marqués du symbole * sont obligatoires.</p>

                <div class="edition">
                    <eclaircies-form-peuplement  @retourDepressage="onRetourDepressage" :data='eclaircie' :data2='peuplement'></eclaircies-form-peuplement>
                    <div class="illustration illustration2"></div>
                </div>

                <v-dialog v-model="dialog" v-if="eclaircie.id" >
                    <v-card>
                        <v-card-title class="headline">Suppression</v-card-title>

                        <v-card-text>
                            Voulez-vous vraiment supprimer l'éclaircie {{ eclaircie.dateEclaircie }} ?<br/>Cette action est irréversible.
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="dialog = false">Non</v-btn>
                            <v-btn color="" text @click="supprimerEclaircie()">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </div>
        </div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import PeuplementEclaircieForm from '../components/uf/PeuplementEclaircieForm.vue'

export default {

    components: {
        'eclaircies-form-peuplement': PeuplementEclaircieForm,
     },

    data() {
        return {
            isEclaircie:true,
            uf:this.$store.getters.infosUf,
            peuplement:this.$store.getters.infosPeuplement,
            eclaircie: {
                "id":0,
                "userId":this.$store.getters.utilisateurCourantId,
                "peuplementId":this.$store.getters.peuplementId,
                "dateEclaircie":"",
                "densiteMoyenne":0.0,
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "hauteurMoyenne":0.0,
                "hauteurJson":"[]",
                "tabHauteurs":[],
                "circonferenceQuadratique":0.0,
                "circonferenceQuadratiqueJson":"[]",
                "tabCirconferences":[],
                "version":0,
				"idLocal":true,
            },
            ready: false,
            dialog: false
        }
    },
    created() {
        this.isEclaircie = this.eclairciePossible;

    },

    mounted: function () {
        this.eclaircieCourante();
        if (this.$store.getters.modeForet == 9) {
            this.$store.commit('setModeForet', 10);
        }
    },


    watch: {
        modeForetPreparation(newVal) {
/*
            if (newVal == 10 && this.ready) {
                setTimeout(this.avancerVers10(), 500); // normalement on ne passe pas par là
                ;
            }
*/
        },
        isReady(newVal) {
            if (newVal && this.modeForetPreparation == 10) {
                setTimeout(this.avancerVers10, 245); // normalement on passe pas par là
            }
        }
    },

    computed: {
        depressageOuEclaircie() {
            if (this.isEclaircie) {
                return("une éclaircie");
            }
            return("un dépressage");

        },
        eclairciePossible() {
            if (this.moisMinimum > this.moisCourant) {
                return false;
            }
            return true;
        },
        moisCourant() {
            var lAnnee = new Date().getFullYear();
            var leMois = "" + (new Date().getMonth()+1);
            // var leMois = "" + (1+1); // pour test
            leMois.padStart(2, '0');
            return lAnnee+"-"+leMois
        },
        borneCulture() {
            return(this.$store.getters.constantes.params.const_essence.borneMesureAutorisee);
        },
        moisMinimum() {
            const borneCulture = this.borneCulture;
            const anneeMois = this.uf.peuplement.dateInstallation.split('-');
            var annee = parseInt(anneeMois[0]) + borneCulture;
            return(""+annee+"-"+anneeMois[1]);
        },

        // UF à jour
        laUF() {
            return this.uf
        },
        // Peuplement à jour
        lePeuplement() {
            return this.peuplement
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        modeForetPreparation() {
            return(this.$store.getters.modeForet);
        },
    },

    methods: {
         avancerVers10() {
            setTimeout(() => {this.$router.push({ name: "propriete" });}, 245);
        },
        // lecture en base et maj du ready
        eclaircieCourante() {
            var eclaircieId = this.$store.getters.eclaircieId;
            if (eclaircieId > 0) {
                return ApiUser.lireEclaircie(eclaircieId).then(response => {
                    this.eclaircie = response.eclaircie;
                    this.ready = true;
                })
            }
            else {
                this.ready = true;
            }
        },
        // fonctions sur boutons
        supprimerEclaircieouPas() {
            this.dialog = true;
        },
        supprimerEclaircie() {
            this.dialog = false;
            return ApiUser.deleteEclaircie(this.eclaircie).then(response => { // on detruit notre Eclaircie (en DB locale et SI POSSIBLE en ligne)
                this.$store.commit('donnerMessage', "L'éclaircie du "+this.eclaircie.dateEclaircie+" a bien été supprimée");
                //this.$store.commit('setEclaircieId', 0);
                //this.$store.commit('resetDataApplication');
                this.$router.push({ name: "peuplement" });
            })
        },

        onRetourDepressage(isDepressage) {
            this.isEclaircie = !isDepressage;
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
}
</style>
