<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="ufDecoupe" v-if="$parent.connexionUtilisateurValide && isReady" class="sousBandeauInterne" v-bind:class="{lectureSeule : $store.getters.lectureSeule}">
            <div id="infosAdministratives" class="sectionAffichage bandeauInterne">
                <div class="wrapper">

                    <div class="conteneur">

                        <h1>
                            Découpage de l'unité forestière : "{{uf.identifiant}}"
                        </h1>

                        <div class="clear" ></div>

                        <p class="intro">Vous pouvez découper une UF et donc créer une nouvelle UF en gardant une partie de l'ancienne, la nouvelle parcelle gardera les caractéristiques de la parcelle d'origine (dendrométrie, itinéraire...). A vous de la faire évoluer différemment.</p>

                        <p class="info">Les champs suivis d'une * sont obligatoires.</p>
                    </div>
                </div>
            </div>


            <div id="infosTab" class="sectionAffichage">

                <div class="wrapper">
                    <div class="conteneur">
                        <decoupe-parcelles-uf :data='laUF'></decoupe-parcelles-uf>
                    </div>
                </div>
            </div>




        </div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import UfDecoupeParcelles from '../components/uf/UfDecoupeParcelles.vue'


export default {

    components: {
        'decoupe-parcelles-uf': UfDecoupeParcelles,

     },

    data() {
        return {
            uf:null,
            ready: false,
        }
    },
    created() {
        this.$store.commit('resetDataApplication');
        this.uf = this.$store.getters.infosUf;
        this.ready = true;
    },

    mounted: function () {

    },

    watch: {

    },

    computed: {
        // UF à jour
        laUF() {
            return this.uf
        },

        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },

    },

    methods: {

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#app {
    #ufDecoupe {
    table  {
        th, td {
            padding:0 10px;
            margin:0;
        }
        th {
            font-size:0.8em;
            font-weight: normal;
        }
    }
    }

}
</style>
