/****** PRINCIPES
-------------------------> Algorithme de calculs des HMAXs à reprendre
Par itération, on calcule le HMAX qui nous permet d'atteindre la première mesure.
Ce HMAX sera celui de toute les années jusqu'à cette mesure.

A/ il n'y a pas d'autres mesures
Le HMAX est conservé pour toute la croissance
NB :
- il est possible que cette unique mesure soit une éclaircie, on considère alors qu'en dépit de la marge d'erreur, ce HMAX calculé est préférable au HMAX par défaut du contexte.
- les éclaircies ne peuvent être prises en compte que si la hauteur est mesurée

B/ il existe d'autres mesures
On calcule les HMAX qui nous permettent de passer de mesure en mesure (formule)

Plusieurs règles :

a) le HMAX (valide) qui conduit à la dernière mesure est conservé pour toute la suite de la croissance
b) si l'écart entre deux mesures (mesure "m" et mesure précédente "m-1") est insuffisant :
 b1 - le HMAX est utilisé pour donner une croissance cohérente entre ces deux mesures
 MAIS il n'est pas conservé pour la suite de la croissance
 b2 - le HMAX utilisé est alors celui calculé entre la mesure "m" et la mesure "m-x". "m-x" étant la première mesure précédent "m" et qui répond à la condition d'éloignement temporel
 il est possible que "m-x" soit une éclaircie
c) pour donner une croissance cohérente à la suite d'une éclaircie, les éclaircies sont prises en compte
 MAIS
 uniquement comme "m-1" ou "m-x" (cf. discussion précédente)


Exemple de comportement attendu (cf. TEST 4 (HMAX 36,23) avec éclaircie en 2021 et pas 2020)
Soit une plantation réalisée à l'année A et des mesures et éclaircies successives M1, E1, M2, E2 et M3

1/ "A" à "M1" : le HMAX entre "A" et "M1"-1 est calculé par itération pour approcher la mesure M1
2/ "M1" à "E1" : pas calculé - à ce stade, report du HMAX précédent
3/ "E1" à "M2" : le HMAX entre "E1" et "M2"-1 est calculé par formule
il est appliqué entre les deux dates MAIS n'est pas valide (écart trop court)
on calcule donc "M1" à "M2" par formule
le HMAX obtenu est appliqué de "M1" à "E1" et potentiellement à partir de M2 (si dernière mesure)
4/ "M2" à "E2" : pas calculé - à ce stade, report du HMAX valide précédent ("M1" à "M2" visible entre "M1" et "E1")
5/ "E2" à "M3" : le HMAX entre "E2" et "M3"-1 est calculé par formule -
il s'agit du dernier HMAX valide qui est reporté pour toute la croissance

Critique
La proximité entre M2 et M1 qui sont séparés d'une éclaircie donne un HMAX top fort
Seules évolutions simples :
augmenter l'écart minimum pour considérer que deux mesures sont pertinentes
faire les reports vers l’arrière et pas vers l'avant ("M2" à "E2" serait reporté de "E2" à "M3" et pas de "E1" à "M2" - en l’occurrence "M1" à "M2")
En l’occurrence, je doute qu'on obtienne une solution idéale dans tous les cas, et on commence à frôler "l'usine à gaz" (attention à la maintenance longue)
FIN PRINCIPES ******/

import { SteresConstantes } from "./constantes.js"
import * as SteresConst from '../steres_const.js'

//*********************************
class UF_PPL_DENDROMETRIQUE {
    constructor() {
        this.ppl = null;
        this.mesures = [];
        this.eclaircies = [];
        this.eclairciesAuto = [];
        this.tabValeursCalculeeS = []; // asymptote de la hauteur - on stocke un HMAX pour chaque hauteur car à chaque nouvelle mesure, on a un nouvel HMAX
                    // de plus, on va lisser les valeurs intermédiares pour lisser les autres calculs
        this.infosFinInstallation = null;
        this.ageDonneesValides = 0; // wiz
        this.donneesIncompletes = false;
        this.ready = false;
        this.calculerStock = false;

        var ddr0 = {"age":0,"qualification":"Q"}
        }


// ****************************************************************************************************************************************************************************
// NIVEAU 0 - Méthodes appelées par l'extérieur
//   a)  initialiserUfPeuplement - Initialisation de l'objet et calculs des résultats
//   b)  initialiserCourbes - Affichage de la courbe
// ****************************************************************************************************************************************************************************

//********************************* initialiserUfPeuplement ************************************
// initialise l'objet
// calcule la dendrométrie
// Paramètres :
// ppl : peuplement sur lequel on travaille - objet issu de "peuplementObj.js" concaténé des valeurs du peuplement lues en base de données
// params : paramètres supplémentaires
// ie.
// - "it": itinéraire technique sur lequel on calcule les valeurs
// - "calculOptimumFutur":true - booléen qui indique qu'on va ajouter les éclairices futures
// - "calculOptimumPasse":false, - caduque : permettait d'injecter des éclaircies dans le passé
// - "ageMaxTest":UfPeuplement1.essence.vieMax - nombres d'années calculées
    initialiserUfPeuplement(ppl, params) {

        // reinit
        this.mesures = [];
        this.eclaircies = [];
        this.eclairciesAuto = [];
        this.tabValeursCalculeeS = [];
        this.ageValeurDeStock = -1;

        // initialisation des paramètres
        this.ppl = ppl;
        this.calculOptimumFutur = params.calculOptimumFutur;
        this.calculOptimumPasse = false;

        // etat "steres" de la parcelle
        this.derniereInfoComplete = -1;
        this.derniereEclaircie = -1;
        this.etat = SteresConstantes.etatVALIDE; // a priori, c'est bon

        //this.calculOptimumPasse = params.calculOptimumPasse;
        /* non on remplace calculOptimumComplet par calculOptimumPasse
        if (this.calculOptimumComplet) {
            this.calculOptimumFutur = this.calculOptimumComplet; // si on fait le complet, on fait le futur
        }
        */
        // paramètrage selon IT
        var it = params.it
        if ((typeof(it) !== 'undefined') && it) {
            this.calculOptimumFutur = it.auto;
            this.ppl.it = it;
        }

        // raccourci pour gain de temps
        this.constantesSteres = ppl.uf.propriete.constantesSteres;

        // intégration des données saisies (il peut en manquer !)
        this.ageMaxTest = Math.trunc(params.ageMaxTest);

        // 2022/09
        /**/
        this.calerPeuplement();
        const premiereMesureOuEclaircie = this.initialiserMesuresEtEclaircies();
        const hmaxStatistique = this.initialiserHmaxEtInstallation();
        if (premiereMesureOuEclaircie) {
            // si on a au moins une mesure (ou eclairice mesurée), on prends en compte cette hauteur pour le HMAX qui sert à recalculer l'installation
            this.itererHmaxEtInstallationPremiereMesureOuEclaircieComplete(premiereMesureOuEclaircie, hmaxStatistique);
            // on a fait l'irération sur la première mesure : on a une fin d'installation définitive et un premier HMAX
            // on calcule les HAX suivant à partir de notre premier
            this.calculerHmaxGeometriques(premiereMesureOuEclaircie);
        }

        // calcul de l'optimum jusqu'à l'année définie dans le jSon
        this.ageValeurDeStock = 0; // pour autoriser le calcul de la coupe rase "stock"

        // NB : dans le calcul des valeurs Géométriques, il faut intégrer les mesures (et éclaircies complètes)
        // de façon à adapter les différents HMAX
        this.calculerValeursGeometriques(this.ageMaxTest);

        // on a fait tous nos calcul, on peut "transmettre" l'état du peuplement
        this.finaliserEtat();

        this.ready = true;
        return(this);
    }


// ****************************************************************************************************************************************************************************
// initialiserCourbes - Affichage de la dendrométrie
// ****************************************************************************************************************************************************************************
    //initialiserCourbes = function(ageMax = this.tabValeursCalculeeS.length-1) {
    initialiserCourbes(ageMax = this.tabValeursCalculeeS.length-1) {

        this.ppl.essence.densiteMax = 1800;
        var hauteurMax = 0;
        var circonferenceMax = 0;
        var volumeMax = 0;
        var densiteMax = 0;

        var courbe = {"negatifPositif":0, "idCourbe":"d_", "debutValide":this.ageDonneesValides, "anneeCourante":this.ppl.anneeCourante, "anneeDuJour":this.ppl.anneeDateInstallation+this.ppl.anneeCourante-1,
                      "infos" : {"titre":"Evolution par années", "classe":"multi", "axeX":"Age", "donneesX":"ans", "intervalleX":10, "debutX":0, "minY":0},
                      "infosY" : [
                          {"libelleAxeY":"Hauteur", "uniteLibelleAxeY":"(en m)", "xAxeY":"gauche", "donneesY":"m", "intervalleY":5, "max":this.ppl.essence.hauteurMax, "min":0}, {"libelleAxeY":"Circonférence", "uniteLibelleAxeY":"(en cm)",  "xAxeY":"gauche", "donneesY":"cm", "intervalleY":20, "max":this.ppl.essence.circonferenceMax, "min":0, "nonNul":true},{"libelleAxeY":"Volume unitaire", "uniteLibelleAxeY":"(en m3)", "xAxeY":"droite", "donneesY":"m3", "intervalleY":0.2, "max":this.ppl.essence.volumeMax, "min":0, "nonNul":true}, {"libelleAxeY":"Densité", "uniteLibelleAxeY":"(en t/ha)",  "xAxeY":"droite", "donneesY":"t/ha", "intervalleY":300, "max":this.ppl.essence.densiteMax, "min":0, "nonNul":true}
                      ],
                      "datas": [],
                     "montrerAnnees":true
                     }; // todo MAXs en base
        if (this.ppl.it) {
            courbe.infos.titre = this.ppl.it.identifiant;
            this.coupeRase = this.ppl.it.coupeRase;
            courbe.coupeRase = this.coupeRase;
        }

        //courbe.debutValide = this.ageDonneesValides;
        //courbe.anneeCourante = this.ppl.anneeCourante;

        for(var cptAns = 0; cptAns <= ageMax; cptAns++) {
            var item = {};
            item.coupeRase = false;
            var items = [];
            item.itemsY = items;
            items[0] = {};
            items[1] = {};
            items[2] = {};
            items[3] = {};
            item.x = cptAns;

            // hauteur
            var hauteur = this.arrondir(this.tabValeursCalculeeS[cptAns].hauteur);
            /*if (this.tabValeursCalculeeS[cptAns].hauteurAvantEclaircie > 0) { // s'il y a une eclaircie, on affiche la "vrai" hauteur avant l'éclaircie
                hauteur = this.arrondir(this.tabValeursCalculeeS[cptAns].hauteurAvantEclaircie);
            }*/
            items[0].y = hauteur;
            if (items[0].y < 0) {
                items[0].yinconnu = true;
            }
            hauteurMax = Math.max(hauteurMax, hauteur)

            // circonférence
            var circonference = this.tabValeursCalculeeS[cptAns].circonference*100;
            /*if (this.tabValeursCalculeeS[cptAns].circonferenceAvantEclaircie > 0) { // s'il y a une eclaircie, on affiche la "vrai" circonference avant l'éclaircie
                circonference = this.tabValeursCalculeeS[cptAns].circonferenceAvantEclaircie*100;
            }*/
            items[1].y = this.arrondir(Math.max(-1, circonference));
            if (items[1].y < 0) {
                items[1].yinconnu = true;
            }
            circonferenceMax = Math.max(circonferenceMax, circonference)

            // volume unitaire
            var volumeUnitaire = this.tabValeursCalculeeS[cptAns].volumeUnitaire
            items[2].y = this.arrondir(Math.max(-1, volumeUnitaire));
            if (items[2].y < 0) {
                items[2].yinconnu = true;
            }
            volumeMax = Math.max(volumeMax, volumeUnitaire)

            // densité
            var densite = this.tabValeursCalculeeS[cptAns].densite
            items[3].y = this.arrondir(Math.max(-1, densite), 0);
            if (items[3].y < 0) {
                items[3].yinconnu = true;
            }
            densiteMax = Math.max(densiteMax, densite)


            // est-ce qu'on a une mesure ou une eclaircie sur l'année
            item.mesure = false;
            if (typeof(this.mesures[cptAns]) !== 'undefined') {
            item.mesure = true;
            }
            item.eclaircie = false;
            item.depressage = false;
            if (typeof(this.eclaircies[cptAns]) !== 'undefined') {
                item.eclaircie = true;
                if (this.eclaircies[cptAns].fromIT || (typeof(this.eclaircies[cptAns].proposition) !== 'undefined')) {
                    item.eclaircieIT = true;
                }

                if ((typeof(this.eclaircies[cptAns].depressage) !== 'undefined') && this.eclaircies[cptAns].depressage) {
                    item.depressage = true;
                }
            }

            var anneeReelle = cptAns;
            if (this.coupeRase && (anneeReelle == this.coupeRase)) {
                item.coupeRase = true;
                this.ppl.it.volumeCoupeRase = this.tabValeursCalculeeS[cptAns].volume;
                this.ppl.it.volumeUnitaireCoupeRase = this.tabValeursCalculeeS[cptAns].volumeUnitaire;
            }

            courbe.datas[cptAns] = item;
        }

        courbe.infosY[0].max = Math.min(hauteurMax, this.ppl.essence.hauteurMax);
        courbe.infosY[1].max = Math.min(circonferenceMax, this.ppl.essence.circonferenceMax);
        courbe.infosY[2].max = Math.min(volumeMax, this.ppl.essence.volumeMax);
        courbe.infosY[3].max = Math.min(densiteMax, this.ppl.essence.densiteMax);

        return(courbe);
    }


// ****************************************************************************************************************************************************************************
// NIVEAU 1 - Prise en compte des paramètres "historiques" du peuplement : Mesures et eclaircies
// et prospectifs : Its
// ****************************************************************************************************************************************************************************

    // ************************************************************************************  2022/09
    initialiserHmaxEtInstallation = function(paramHMAX = 0) {

        // init des variables
        var essence = this.ppl.essence;
        var age = 0;
        var HMAX=this.ppl.HMAX; // valeur statistique
        if (paramHMAX) {
            HMAX = paramHMAX;
        }
        var hauteurFinInstallation = 0;
        var hauteurCalculee = 0;
        var hauteurStock = 0;
        var hauteurUtilisee = 0;
        var dataFinInstallation = {"hauteur":0, "age":0};

        // année 0 à 0
        this.initialiserValeurCalculee(0);
        this.initialiserValeurCalculeeHauteur(0, 0);
        this.initialiserValeurCalculeeDensite(0, this.ppl.calculerDensite());

        const ageMaxInstallation = Math.max(SteresConst.PREMIERE_MESURE_SEMIS, SteresConst.PREMIERE_MESURE_PLANTATION);

        // on boucle jusqu'à atteindre la hauteur
        while (1) {

            // le HMAX statistique pour toutes les valeurs
            this.initialiserValeurCalculeeHMAX(age, HMAX);


            // accroissement
            var iH = essence.aInstallation * HMAX;
            hauteurCalculee += iH;

            // rappel : plus de mesure ni d'éclaircie "mesurée" en phase d'intallation
            // c'est donc TOUJOURS la hauteurCalculee qui est utilisée

            // on stocke la hauteur
            this.initialiserValeurCalculeeHauteur(age+1, hauteurCalculee);

            // on peut maintenant connaitre la densite
            this.integrerDensiteAnnee(age); // ici uniquement si on a un depressage : on met à jour la valeur de densité de l'année
            var densite = this.calculerDensite(age);
            this.initialiserValeurCalculeeDensite(age+1, densite);

            age++;

            // sorties si installation finie ou soupcon de boucle infinie (bug)
            if (this.finInstallation(hauteurCalculee)) {
                break;
            }
            if (age > ageMaxInstallation) {
                console.log("initialiserHmaxEtInstallation : boucle infinie cassée");
                break;
            }

        }

        // on ajoute le HMAX statistique jusqu'au bout
        // this.initialiserValeurCalculeeHMAX(age, HMAX);
        for(var cptAnsBoucle = age; cptAnsBoucle < this.ageMaxTest; cptAnsBoucle++) {
            this.initialiserValeurCalculeeHMAX(cptAnsBoucle, HMAX);
        }

        dataFinInstallation.hauteur = hauteurCalculee;
        dataFinInstallation.age = age;

        this.infosFinInstallation = dataFinInstallation;
        return(HMAX);
	}

    //*************************
    initialiserMesuresEtEclaircies = function() {

        // integrer test ici sur
        // SteresConst.PREMIERE_MESURE_SEMIS SteresConst.PREMIERE_MESURE_PLANTATION
        // pour ne pas prendre en compte ce qui ne nous convient plus
        // on accepte juste la densité !!!!!!!!!!!!!!!!!!


            // injection des mesures et eclaircies du peuplement
        var premiereMesure = true;
        var premiereEclaircie = true;
        //var derniereEclaircie = this.derniereEclaircieValide();
        //var approximerEclaircie = false;
        var objPremiereMesure = null;
        var mesureCourante = null;
        var mesurePred = null;

        // on fait une premiere boucle pour qualifier (ou non) les mesures
        var cptMesuresEtEclaircies = 0;
        while (this.ppl.mesures[cptMesuresEtEclaircies] || this.ppl.eclaircies[cptMesuresEtEclaircies]) {

                        // on travaille indifféremment sur une mesure ou une éclaircie complète
            if (this.ppl.mesures[cptMesuresEtEclaircies]) {
                mesureCourante = this.ppl.mesures[cptMesuresEtEclaircies];
            }
            if (this.ppl.eclaircies[cptMesuresEtEclaircies]) {
                if ((typeof(this.ppl.eclaircies[cptMesuresEtEclaircies].hauteurMoyenne) !== 'undefined') && this.ppl.eclaircies[cptMesuresEtEclaircies].hauteurMoyenne) {
                mesureCourante = this.ppl.eclaircies[cptMesuresEtEclaircies];
                }
                else {
                    // une éclaircie incomplète : on n'en parle plus
                    this.ppl.eclaircies[cptMesuresEtEclaircies].valideeHMAX = -1;
                }
            }

            if (mesureCourante) {
                mesureCourante.valideeHMAX = true;
                if (mesurePred) {
                    // est-ce que la mesure pred n'est pas trop proche de la suivante ?
                    if (!this.validerMesureOuEclairciePred(mesureCourante, mesurePred)) {
                        mesurePred.valideeHMAX = false;
                    }
                }
                mesurePred = mesureCourante;
            }
            cptMesuresEtEclaircies++;
        }

        // on fait une deuxième boucle pour éventuellement requalifier des mesures
        cptMesuresEtEclaircies = 0;
        var mesureCourante = null;
        var mesurePred = null;
        while (this.ppl.mesures[cptMesuresEtEclaircies] || this.ppl.eclaircies[cptMesuresEtEclaircies]) {

                        // on travaille indifféremment sur une mesure ou une éclaircie complète
            if (this.ppl.mesures[cptMesuresEtEclaircies]) {
                mesureCourante = this.ppl.mesures[cptMesuresEtEclaircies];
            }
            if (this.ppl.eclaircies[cptMesuresEtEclaircies] && (typeof(this.ppl.eclaircies[cptMesuresEtEclaircies].hauteurMoyenne) !== 'undefined') && this.ppl.eclaircies[cptMesuresEtEclaircies].hauteurMoyenne) {
                mesureCourante = this.ppl.eclaircies[cptMesuresEtEclaircies];
            }


            // si on a une mesure intermédiare non validée qui en bloque une autre, on regarde si on peut débloquer la première
            if (mesureCourante) {
                if (mesurePred && (mesureCourante.valideeHMAX == false) && (mesurePred.valideeHMAX == false)) {
                    mesurePred.valideeHMAX = true; // NB : on conserve la première mesure comme mesurePred et on va la revalider
                }
                else { // autre config ou itération suivante
                    if (mesurePred && ((mesurePred.valideeHMAX != true) || !this.validerMesureOuEclairciePred(mesureCourante, mesurePred))) {
                        mesurePred.valideeHMAX = false;
                    }
                    mesurePred = mesureCourante;
                }
            }

            cptMesuresEtEclaircies++;
        }


        // et on travaille sur les mesures validées
        cptMesuresEtEclaircies = 0;
        while (this.ppl.mesures[cptMesuresEtEclaircies] || this.ppl.eclaircies[cptMesuresEtEclaircies]) {
/**/
            // on travaille indifféremment sur une mesure ou une éclaircie complète
            if (this.ppl.mesures[cptMesuresEtEclaircies]) {
                if (this.ppl.mesures[cptMesuresEtEclaircies].valideeHMAX) {
                    mesureCourante = this.ppl.mesures[cptMesuresEtEclaircies];
                    this.ajouterUneMesure(mesureCourante) ;
                }
                else  {
                    this.ajouterUneMesure(this.ppl.mesures[cptMesuresEtEclaircies]) ;
                }
            }
            if (this.ppl.eclaircies[cptMesuresEtEclaircies]) {
                if (this.ppl.eclaircies[cptMesuresEtEclaircies].valideeHMAX && (typeof(this.ppl.eclaircies[cptMesuresEtEclaircies].hauteurMoyenne) !== 'undefined') && this.ppl.eclaircies[cptMesuresEtEclaircies].hauteurMoyenne) {
                    mesureCourante = this.ppl.eclaircies[cptMesuresEtEclaircies];
                    mesureCourante.hauteur = mesureCourante.hauteurMoyenne;
                    this.ajouterUneEclaircie(mesureCourante, false);
                }
                else {
                    this.ajouterUneEclaircie(this.ppl.eclaircies[cptMesuresEtEclaircies], false);
                }
            }

            if (mesureCourante && !mesureCourante.invalide) {
                if (premiereMesure) {
                    premiereMesure = false;
                    objPremiereMesure = mesureCourante;
                }
            }
            cptMesuresEtEclaircies++;
        }

        this.initialiserDernieresInfos();

        var eclairciesIt = [];
        if (this.ppl.it && this.ppl.it.eclaircies) {
            eclairciesIt = this.ppl.it.eclaircies;
        }
        var it = this.ppl.it;

        cptMesuresEtEclaircies = 0;
        while (eclairciesIt[cptMesuresEtEclaircies]) { // wiz
            if (eclairciesIt[cptMesuresEtEclaircies]) {
                var anneeEclaircie = parseInt(eclairciesIt[cptMesuresEtEclaircies].dateEclaircie);
                if (anneeEclaircie && (anneeEclaircie >= this.anneeMinAcceptable(this.constantesSteres.moisBascule))) {
                    this.ajouterUneEclaircie(eclairciesIt[cptMesuresEtEclaircies], true) ;
                }
            }
            cptMesuresEtEclaircies++;
        }

        return(objPremiereMesure);
    }

    // **************************************
	integrerDensiteAnnee = function(age) {
        if (typeof(this.mesures[age]) !== 'undefined') {
            // et densité
            this.initialiserValeurCalculeeDensite(age, this.mesures[age].densite);
            return(true);
        }
        if (typeof(this.eclaircies[age]) !== 'undefined') {
            // et densité
            this.initialiserValeurCalculeeDensite(age, this.eclaircies[age].densiteApres);
            this.tabValeursCalculeeS[age].densiteApres = this.eclaircies[age].densiteApres; // on sait qu'on a eu un depressage
            return(true);
        }
        return(false);
    }

    // **************************************
    itererHmaxEtInstallationPremiereMesureOuEclaircieComplete = function(premiereMesureOuEclaircie, hmaxStatistique) {

        var HMAX = hmaxStatistique;
        var HMAXcalcule = 0;
        var infosFinInstallation = null;
        var i=18;
        while (i--)  { // on itère pour ce rapprocher de la bonne valeur de départ ...

            // pour comparaison
            const oldInfosFinInstallation = JSON.parse(JSON.stringify(this.infosFinInstallation));

            HMAXcalcule = this.calculerPremierHMAX(premiereMesureOuEclaircie); // on doit tomber sur la première mesure en prenant en compte la fin de l'installation (d'où la boucle car cette valeur change avec le HMAX)
            HMAX = (HMAX+2*HMAXcalcule)/3; // priorité au dernier calcul

            if (i) {
                this.initialiserHmaxEtInstallation(HMAX);
            }

            // important - il peut arriver qu'on fluctue entre deux valeurs de HMax qui induisent des durées d'installation différentes
            // on prend donc le calcul qui nous rapproche le plus de la valeur limite de fin d'installation
            if (!i) {

                // on calcule le delta sur le HMAX itéré
                this.initialiserHmaxEtInstallation(HMAX);
                const deltaHauteur = this.infosFinInstallation.hauteur - this.ppl.essence.hauteurFinInstallation;

                // le delta nous permet de savoir si on est en mode oscillation ou focalisation
                // si on est en mode oscillation (cas rare) ET qu'on est sur la crête la plus éloignée, on refera un tour !
                if (deltaHauteur > (oldInfosFinInstallation.hauteur - this.ppl.essence.hauteurFinInstallation)) {
                    i++; // on va refaire un tour, pour arrêter l'oscillation sur la meilleure valeur
                    console.log("itererHmaxEtInstallationPremiereMesureOuEclaircieComplete : non optimal");
                }
                else {
                    console.log("itererHmaxEtInstallationPremiereMesureOuEclaircieComplete : optimal");
                    // la sortie se fait sur le "vrai" HMAX calculé
                    HMAX = HMAXcalcule;
                    this.initialiserHmaxEtInstallation(HMAX);
                    // si on est sur une eclaircie (et si on le peut) on calcule les valeurs avant éclaircie une derniere fois
                    // de façon à ce que le HMAX soit calculé sur la hauteur "avant eclaircie"
                    if (this.isPremiereMesureUneEclaircieCompleteAvecRetourArrierePossible(premiereMesureOuEclaircie)) {
                        HMAX = this.calculerPremierHMAX(premiereMesureOuEclaircie);
                        this.initialiserHmaxEtInstallation(HMAX);
                    }
                }
            }
            if (i == 1) {
                const debug=1;
            }
        }
    }

    //*********************************
    approximerDensite(age) {
        const essence = this.ppl.essence;
        const valeursCalculeeS = this.tabValeursCalculeeS[age];
        // la hauteur calculée se fait ici FORCEMENT sur une croissance géométrique car on n'est plus en phase d'installation
        // (même si on est sur le calcul du premier HMAX qui est utilisé sur cette phase d'installation !!!)
        const hauteurCalculee = this.calculerHauteur(valeursCalculeeS.HMAX, this.tabValeursCalculeeS[age-1].hauteur, essence);
        this.initialiserValeurCalculeeHauteur(age, hauteurCalculee);
        const densite = this.calculerDensite(age-1);
        this.initialiserValeurCalculeeDensite(age, densite);
    }

    //*********************************
    // on a une éclaircie "complète" qui nous sert pour le calcul du HMAX
    // on calcul la hauteur avant éclaircie
    // NB : on est dans le cadre d'une itération "approximative" car on est "en recherche" du HMAX
    // et on essaie donc de s'approcher de la valeur de HMAX qui va nous faire tomber sur la mesure
    // la complexité ici est que la mesure est "après éclaircie" donc on regarde en arrière et on essaie de faire coller ...
	calculerHauteurAvantEclaircieCompletePourHMAX = function(eclaircie) {
        const age = eclaircie.age;
        const densiteApres = eclaircie.densiteMoyenne;
        const hauteurApresEclaircie = eclaircie.hauteur;
        // on fait un retour en arriere pour calculer la densite l'année d'avant notre éclaircie (NB : le calcul est fait sur notre HMAH itéré précédent)
        // ce retour en arrière est nécessaire puisque on peut être en train de calculer le HMAX utilisé en phase d'installation
        // on a donc des valeurs approximées pour cette phase MAIS pas de valeurs autres que le HMAX et ce, de la fin de l'installation jusqu'à la mesure de l'éclaircie !!!
        var cpt = 1;
        // recherche de la dernière densité connue
        while (typeof(this.tabValeursCalculeeS[age-cpt].densite) === 'undefined') {
            cpt++;
        }
        // approximation des densités suivantes
        while (cpt > 1) {
            cpt--;
            this.approximerDensite(age-cpt);
        }
        // calcul de la hauteurAvantEclaircie
        const densiteAvant = this.tabValeursCalculeeS[age-cpt].densite; // approximation
        const densiteEclaircie = densiteAvant - densiteApres;
        const hauteurAvantEclaircie = (hauteurApresEclaircie * densiteApres) / (densiteAvant - (this.ppl.essence.coefEclaircie * densiteEclaircie));
        return(hauteurAvantEclaircie);
    }

    //*********************************
    // test qui nous permet de savoir si la mesure est une éclaircie complète
	isMesureUneEclaircieComplete = function(mesure) {
        if ((typeof(mesure.dateEclaircie) !== 'undefined') && (typeof(mesure.densiteMoyenne) !== 'undefined') ) {
            return(true);
        }
        return(false);
    }

    //*********************************
    // test qui nous permet de savoir si la mesure est une éclaircie pour laquelle on peut recalculer des valeurs AVANT éclairce
	isPremiereMesureUneEclaircieCompleteAvecRetourArrierePossible = function(mesure) {
        if (this.isMesureUneEclaircieComplete(mesure) && this.ppl.densiteInstallation) {
            // NB le test sur this.ppl.densiteInstallation induit que (pour le moment) on ne s'interesse qu'au calcul du premier HMAX
            return(true);
        }
        return(false);
    }


    //*********************************
	calculerPremierHMAX = function(mesure) {
        var HMAX = 0;
        var age = mesure.age;
        var hauteur = mesure.hauteur;

        // question : si eclaircie est-il possible de retrouver une hauteur AvantEclaircie ?
        if (this.isPremiereMesureUneEclaircieCompleteAvecRetourArrierePossible(mesure)) {
            hauteur = this.calculerHauteurAvantEclaircieCompletePourHMAX(mesure);
        }


        var calcIntermediare = Math.pow(1-this.ppl.essence.a, age-this.infosFinInstallation.age);
        calcIntermediare = (hauteur-this.infosFinInstallation.hauteur)/(1-calcIntermediare);
        HMAX = calcIntermediare+this.infosFinInstallation.hauteur;


        return(HMAX);
    }

    //*********************************
    calculerHmaxGeometriques = function(premiereMesureOuEclaircie) {

        var mesurePred = premiereMesureOuEclaircie;
        var cptAnsBoucle = premiereMesureOuEclaircie.age+1;
        while(1) {
            // test de sortie sur cptAnsBoucle
            if (cptAnsBoucle > this.ppl.anneeCourante) {
                return;
            }

            var mesure = this.mesures[cptAnsBoucle];
            if (typeof(mesure) === 'undefined') {
                mesure = this.eclaircies[cptAnsBoucle];
                if ((typeof(mesure) === 'undefined') || (typeof(mesure.hauteurMoyenne) === 'undefined') || (mesure.hauteurMoyenne == 0)) {
                    cptAnsBoucle++;
                    continue;
                }
            }
            //if (typeof(mesure) !== 'undefined') {
                if (mesure.valideeHMAX > 0) {
                    this.calculerValeursGeometriques(mesurePred.age); // il faut les valeurs jusqu'à la mesurePred pour faire les calculs - c'est absoluement nécessaire si on a une éclaircie dans le "tas" des mesures
                    this.calculerHMAXsuivant(mesure, mesurePred);
                    mesurePred = mesure;
                }
            //}
            cptAnsBoucle++;
        }
    }

    //*********************************
	calculerHMAXsuivant = function(mesure, mesurePred) {
        var HMAX = 0;
        const agePred = mesurePred.age;
        //var age = mesure.age;
        var hauteur = mesure.hauteur;
        const anneeDate = mesure.anneeDate;
        const isMesureUneEclaircieComplete = this.isMesureUneEclaircieComplete(mesure);
        var cpt = 1;

        // question : si eclaircie est-il possible de retrouver une hauteur AvantEclaircie ?
        if (isMesureUneEclaircieComplete) {
            cpt = 2;
        }

        // on bouclera deux fois si on doit recalculer la hauteur
        // dans ce cas, il est important de "boucler" une première fois pour initialiser les valeurs
        // "comme si" les mesures de l'éclaircie étaient des mesures "normales" (ie. pas après éclaircie)
        while (cpt) {
            cpt --;
            var calcIntermediare = Math.pow(1-this.ppl.essence.a, anneeDate-mesurePred.anneeDate);
            calcIntermediare = (hauteur-mesurePred.hauteur)/(1-calcIntermediare);
            HMAX = calcIntermediare+mesurePred.hauteur;

            // il faut reinitialiser jusqu'au HMAX pred
            //for(var cptAnsBoucle = agePred+1; cptAnsBoucle <= age; cptAnsBoucle++) {
            for(var cptAnsBoucle = agePred+1; cptAnsBoucle <= this.ageMaxTest; cptAnsBoucle++) { // on boucle systématiquement jusqu'au bout
                this.initialiserValeurCalculeeHMAX(cptAnsBoucle, HMAX);
            }

            // question : si eclaircie est-il possible de retrouver une hauteur AvantEclaircie ?
            if (isMesureUneEclaircieComplete) {
                hauteur = this.calculerHauteurAvantEclaircieCompletePourHMAX(mesure);
            }
        }

        return(HMAX);
    }


    //*********************************
    ajouterUneMesure = function(mesureJson) {

        // 2022/10
        if (typeof(mesureJson.anneeRetenue) === 'undefined') {
            if (typeof(mesureJson.dateMesure) !== 'undefined') {
                mesureJson.anneeRetenue = this.anneeRetenue(mesureJson.dateMesure);
            }
        }
        // possible si on modifie la date du peuplement
        if (!this.mesureOuEclaircieAcceptable(mesureJson)) {
            // mesureJson.invalide = true; fait dans la fonction
            return false;
        }
        mesureJson.invalide = false;

        // calage pour fonctionnement hors tests ...
        if (typeof(mesureJson.dateMesure) !== 'undefined') {
            mesureJson.anneeDate = this.anneeRetenue(mesureJson.dateMesure);
            mesureJson.age = mesureJson.anneeDate - this.ppl.anneeDateInstallation;
        }
        if (typeof(mesureJson.hauteurMoyenne) !== 'undefined') {
            mesureJson.hauteur = mesureJson.hauteurMoyenne;
        }
        if (typeof(mesureJson.circonferenceQuadratique) !== 'undefined') {
            mesureJson.circonference = mesureJson.circonferenceQuadratique/100;
        }
        if (typeof(mesureJson.densiteMoyenne) !== 'undefined') {
            mesureJson.densite = mesureJson.densiteMoyenne;
        }


        if (mesureJson.age == -1) {
            mesureJson.age = mesureJson.anneeDate - this.ppl.anneeDateInstallation;
        }

        mesureJson.annee = mesureJson.age+1; // notre tableau commence à 0 ce qui correspond à l'age et pas aux années
        var mesure = mesureJson;
        this.mesures[mesure.age] = mesure;

        return;
    }


    //*********************************
    ajouterUneEclaircie = function(eclaircieJson, fromIT) {

        // 2020/06
        if (typeof(eclaircieJson.itId) !== 'undefined') {

            if (!this.mesureOuEclaircieAcceptable(eclaircieJson)) {
            //if (eclaircieJson.anneeRetenue < this.anneeMinAcceptable(this.constantesSteres.moisBascule)) {
                this.pbEclaircieIT = true;
                this.eclaircieJson.pbEclaircieIT = true;
                return false;
            }
        }
        else { // 2022/10
            if (typeof(eclaircieJson.anneeRetenue) === 'undefined') {
                if (typeof(eclaircieJson.dateEclaircie) !== 'undefined') {
                    eclaircieJson.anneeRetenue = this.anneeRetenue(eclaircieJson.dateEclaircie);
                }
            }
            // possible si on modifie la date du peuplement
            if (!this.mesureOuEclaircieAcceptable(eclaircieJson)) {
                //eclaircieJson.invalide = true; fait dans la fonction
                return false;
            }
        }
        eclaircieJson.invalide = false;

        // calage pour fonctionnement hors tests ...
        if (typeof(eclaircieJson.dateEclaircie) !== 'undefined') {
            eclaircieJson.anneeDate = this.anneeRetenue(eclaircieJson.dateEclaircie);
            eclaircieJson.age = eclaircieJson.anneeDate - this.ppl.anneeDateInstallation;
        }
        if (typeof(eclaircieJson.densiteMoyenne) !== 'undefined') {
            eclaircieJson.densiteApres = eclaircieJson.densiteMoyenne;
        }


        eclaircieJson.annee = eclaircieJson.age+1; // notre tableau commence à 0 ce qui correspond à l'age et pas aux années
        var eclaircie = eclaircieJson;
        eclaircie.fromIT = fromIT;
        if (!fromIT || typeof(this.eclaircies[eclaircie.age]) === 'undefined') { // une eclaircie IT n'en écrase pas une autre
            this.eclaircies[eclaircie.age] = eclaircie;
        }


        return;
    }

    //*********************************
    validerMesureOuEclairciePred = function(mesure, mesurePred) {
        if (mesure.anneeDate-mesurePred.anneeDate < this.ppl.essence.deltaMesuresPourHMAX) {
            return(false);
        }
    return(true);
    }

    // ************************************************************************************  fin 2022/09


// ****************************************************************************************************************************************************************************
// NIVEAU 2 - Calculs de la dendrométrie
// dendrométrie avant installation (il faut fixer la fin d'installation)
// calculs des valeurs post installation
// ****************************************************************************************************************************************************************************

//**********************************************************
// calculerValeursGeometriques
// param : année
// calcule et stocke les valeurs dendrométriques à partir du tableau des HMAXs
//*********************************
	calculerValeursGeometriques = function(ageMax = -1) {

        this.ageValeurDeStock = 0; // important pour reinitialiser le calcul

        var essence = this.ppl.essence;
        if (ageMax == -1) {
            ageMax = this.ppl.essence.vieMax;
        }

        if ((typeof(this.tabValeursCalculeeS[this.infosFinInstallation.age]) === 'undefined') || (typeof(this.tabValeursCalculeeS[this.infosFinInstallation.age]).HMAX === 'undefined')) {
console.log("calculerValeursGeometriques : pb initialisation ");
            }

        var age = this.infosFinInstallation.age;
        var HMAX = this.tabValeursCalculeeS[age].HMAX;

        for(var cptAns = this.infosFinInstallation.age; cptAns <= ageMax; cptAns++) {

            // travailler sur le bon HMAX
            if ((typeof(this.tabValeursCalculeeS[cptAns]) !== 'undefined') && (typeof(this.tabValeursCalculeeS[cptAns]).HMAX !== 'undefined')) {
                HMAX = this.tabValeursCalculeeS[cptAns].HMAX;
            }
            else {
                this.initialiserValeurCalculeeHMAX(cptAns, HMAX);
            }

            // attention si on a une mesure, c'est elle qu'on utilise pour l'année d'après
            this.integrerMesuresAnnee(cptAns);

            // accroissement hauteur
            var hauteurPred = this.tabValeursCalculeeS[cptAns].hauteur;
            var hauteurCalculee = this.calculerHauteur(HMAX, hauteurPred, essence); // BC 202102 Création de "calculerHauteur"
            /*
            var iH = essence.a * (HMAX - hauteurPred);
            var hauteurCalculee = hauteurPred+iH;
            */

            // en cas d'eclaircie, la nouvelle hauteur remplacera la hauteur calculée
            this.anticiperPourEclaircie(cptAns, hauteurCalculee); // wizwiz
            var hauteurSuiteEclaircie = this.gererEclaircies(cptAns);
            if (hauteurSuiteEclaircie) {
                hauteurCalculee = this.calculerHauteur(HMAX, hauteurSuiteEclaircie, essence); // BC 202102 Création de "calculerHauteur"
                /*
                var iH = essence.a * (HMAX - hauteurSuiteEclaircie);
                hauteurCalculee = hauteurSuiteEclaircie+iH;
                */
                // hauteur en l'absence d'eclaircie
                this.initialiserValeurCalculeeHauteurAvantEclaircie(cptAns, hauteurPred);
            }

            // hauteur (et circonférence)
            this.initialiserValeurCalculeeHauteur(cptAns+1, hauteurCalculee);


            // on peut maintenant connaitre la densite
            var densite = this.calculerDensite(cptAns);

            // on stocke la densite
            this.initialiserValeurCalculeeDensite(cptAns+1, densite);
        }
        // on ajoute le HMAX de ageMax
        this.initialiserValeurCalculeeHMAX(cptAns, HMAX);

    }



// ****************************************************************************************************************************************************************************
// NIVEAU 1.1 - Fonctions nécessaires à l'initialisation des paramètres
// ****************************************************************************************************************************************************************************

//*********************************
//  calerPeuplement : Mise en cohérence des valeurs initiales de TEST et des valeurs stockées en BD (il s'agit juste du nom des variables)
//*********************************
    calerPeuplement() {

        // calage pour fonctionnement hors tests ...
        if (typeof(this.ppl.anneeRetenue) !== 'undefined') {
            this.ppl.anneeDateInstallation = this.ppl.anneeRetenue;
        }
        if (typeof(this.ppl.densiteInstallation) !== 'undefined') {
            this.ppl.densite = this.ppl.densiteInstallation;
        }
        if ((typeof(this.ppl.uf) !== 'undefined') && (typeof(this.ppl.uf.surface) !== 'undefined')) {
            this.ppl.surfaceUF = this.ppl.uf.surface;
        }

        var ladate=new Date();
        this.ppl.anneeCourante = ladate.getFullYear() - this.ppl.anneeDateInstallation + 1;
    }

//*********************************
//  derniereEclaircieValide : retourne la dernière éclaircie réalisée sur le peuplemnt
//*********************************
    derniereEclaircieValide() {
        var ok = -1;
        this.ppl.eclaircies.forEach((eclaircie, index) => {
            if (eclaircie.hauteurMoyenne) {
                ok = index
            }
        })
        return(ok);
    }



// ****************************************************************************************************************************************************************************
// NIVEAU 2 - Dendrométrie suite
// Gestion des éclaircies - REELLES (intégrées) - VIRTUELLES (proposées)
// ****************************************************************************************************************************************************************************

    //*********************************
    // changement de l'état du peuplement    (valide / validé par utilisateur / à valider / invalide)
    /*
        this.etatVALIDE = 1;
        this.etatVALIDEUTILISATEUR = 2;
        this.etatAVALIDER = 3;
        this.etatINVALIDE = 4;
    */
    indiquerEtatAValider() {
        if (this.etat == SteresConstantes.etatVALIDE) {
            this.etat = SteresConstantes.etatAVALIDER;
        }
    }
    indiquerEtatInvalide() {
        this.etat = SteresConstantes.etatINVALIDE;
    }
    finaliserEtat() { // TODO : finaliser selon l'état "utilisateur"

        // cas particuliers où on modifie l'état utilisateur :
        /*
        ** STERES peut modifier automatiquement les états choisis par l'utilisateur UNIQUEMENT dans quatre cas précis :
        • un peuplement est devenu invalide
        • à l'origine le peuplement était "à valider" + "validé" puis il est devenu "valide" + "validé" puis rechange à nouveau en "à valider" + "validé" : dans ce cas STERES change le peuplement de "validé" en "à valider"
        • à l'origine le peuplement était "invalidé" puis il devient "valide" + "invalidé" : dans ce cas STERES change le peuplement de "invalidé" en "validé"
        • à l'origine le peuplement était "invalide" + "invalidé" puis il devient "à valider" + "invalidé" : dans ce cas STERES change le peuplement de "invalidé" en "à valider"
        */
        if (this.etat == SteresConstantes.etatINVALIDE) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
        if ((this.etat == SteresConstantes.etatAVALIDER) && (this.ppl.uf.peuplement.etatSteres == SteresConstantes.etatVALIDE) && (this.ppl.uf.peuplement.etatUtilisateur == SteresConstantes.etatVALIDE) ) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
        if ((this.etat == SteresConstantes.etatVALIDE) && (this.ppl.uf.peuplement.etatUtilisateur != SteresConstantes.etatVALIDE) ) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
        if ((this.etat == SteresConstantes.etatAVALIDER) && (this.ppl.uf.peuplement.etatSteres == SteresConstantes.etatINVALIDE) && (this.ppl.uf.peuplement.etatUtilisateur == SteresConstantes.etatINVALIDE) ) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }

        this.ppl.uf.peuplement.etatSteres = this.etat;

        // si on n'a pas encore d'état utilisateur, c'est celui de STERES
        if (!this.ppl.uf.peuplement.etatUtilisateur) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
    }

    //*********************************
    initialiserDernieresInfos() {

        var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
        var existeMesure = Math.max(0, this.mesures.length-1);
        var existeEclaircieValide = 0;
        var i = this.eclaircies.length;
        while (i) {
            i--;
            if (this.eclaircies[i]  && this.eclaircies[i].hauteurMoyenne && this.eclaircies[i].anneeDate < anneeMinAcceptable)
                {
                    existeEclaircieValide = i;
                    break;
                }
        }
        this.derniereInfoComplete = Math.max(existeMesure, existeEclaircieValide);
        this.derniereEclaircie = Math.max(0, this.eclaircies.length-1);

        if (!this.derniereInfoComplete && ! this.ppl.densiteInstallation) {
            this.indiquerEtatInvalide();
        }

        // pareil si le peuplement est trop ancien
        if (this.ppl.anneeCourante > this.ppl.essence.vieMax)  {
            this.indiquerEtatInvalide();
        }

    }

    //*********************************
    derniereInfoValidePassee(cptAns) {
        if ((this.derniereInfoComplete < 0) || (this.derniereEclaircie< 0)) {
            return(false);
        }
        else {
            if (cptAns > this.derniereInfoComplete) { // on depasse la dernière info complète
                if ((this.derniereInfoComplete || this.ppl.densiteInstallation) && (cptAns <= this.derniereEclaircie)) { // mais pas la dernière eclaircie incomplète qui suit des infos valides !
                    return(false);
                }
                else {
                    this.toto = 5;
                    return(true);
                }
            }
            else {
                return(false);
            }
        }
    }

    //*********************************
    // en cas d'eclaircie, la nouvelle hauteur remplacera la hauteur calculée
	gererEclaircies = function(cptAns) {
        var ageReel = cptAns;
        var hauteurSuiteEclaircie = 0;

        // 2020/05
        if (ageReel) {
            this.tabValeursCalculeeS[ageReel].cumulVolumeRecolteAvantEclaircie = this.tabValeursCalculeeS[ageReel-1].cumulVolumeRecolteEclaircie;
            this.tabValeursCalculeeS[ageReel].cumulVolumeRecolteEclaircie = this.tabValeursCalculeeS[ageReel-1].cumulVolumeRecolteEclaircie;
        }
/* 2022/12 attention - léger bug ci-dessous dans le cas ou l'historique propose une éclaircie qui sera comptabilisée dans l'année qui suit
        if (ageReel < this.ppl.anneeCourante-1) {
            if (this.derniereInfoValidePassee(cptAns)) {
                if (this.infosFinInstallation) { // rien l'année 0
                    if (this.proposerEclaircieAnnee(ageReel, 0)) {
                        this.indiquerEtatAValider();
                    }
                }
            }
            else {
                hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel, 0);
            }
        }
        else {
            if (ageReel == this.ppl.anneeCourante-1) {
                hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel, 0);
                var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
                var anneeReelle = ageReel+ this.ppl.anneeRetenue;
                if (!hauteurSuiteEclaircie && this.calculOptimumFutur && (anneeReelle>=anneeMinAcceptable)) { // TODO si après mois de juin, ça n'est plus possible (wiz) - this.anneeMinAcceptable(this.constantesSteres.moisBascule)
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 1);
                }
            }
            else {
                if (this.calculOptimumFutur) {
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 1);
                }
                else {
                    hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel, 1);
                }
            }
        }
*/
        // 2022/12 - correction du cas ou l'historique propose une éclaircie qui sera comptabilisée dans l'année qui suit : on prend en compte l'éclaircie pour ne pas la proposer en IT !
        var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
        var decalage = anneeMinAcceptable-this.anneeAujourdhui()-1;
        if (ageReel < this.ppl.anneeCourante+decalage) {
            if (this.derniereInfoValidePassee(cptAns)) {
                if (this.infosFinInstallation) { // rien l'année 0
                    if (this.proposerEclaircieAnnee(ageReel, 0)) {
                        this.indiquerEtatAValider();
                    }
                }
            }
        }

        if (ageReel <= this.ppl.anneeCourante+decalage) {
            if (ageReel == this.ppl.anneeCourante+decalage) {
                const toto = 1;
                }
            if (!this.derniereInfoValidePassee(cptAns)) {
                hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel, 0);
            }
        }
        if ((ageReel >= this.ppl.anneeCourante+decalage) && !hauteurSuiteEclaircie) {
            if (ageReel == this.ppl.anneeCourante+decalage) {
                hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel, 0);
                //var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
                var anneeReelle = ageReel+ this.ppl.anneeRetenue;
                if (!hauteurSuiteEclaircie && this.calculOptimumFutur && (anneeReelle>=anneeMinAcceptable)) { // TODO si après mois de juin, ça n'est plus possible (wiz) - this.anneeMinAcceptable(this.constantesSteres.moisBascule)
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 1);
                }
            }
            else {
                if (this.calculOptimumFutur) {
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 1);
                }
                else {
                    hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel, 1);
                }
            }
        }

        // s'il y a eu une eclaircie, le calcul devient inutile ... ??????
        // s'il y a eu une eclaircie, le calcul devient inutile ... on calcul la nouvelle densité
        var eclaircie = this.eclaircies[cptAns];
        if (typeof(eclaircie) !== 'undefined') {
            this.initialiserValeurCalculeeDensite(ageReel, eclaircie.densiteApres);
        }

        return(hauteurSuiteEclaircie);
    }


    //*********************************
    // est-ce que le rapport densité, circonférence nécessite une éclaircie ?
	proposerEclaircieAnnee = function(cptAns, integrerEclaircie) {

        if (cptAns+1 >= this.tabValeursCalculeeS.length) { // ne pas aller trop loin
            return(0);
        }

        var continuer = true;
        var densiteApres = 0;
        var densite = 0;
        var tarif = -1;
        // nouveaux paramètres : densité minimale à ne pas franchir
        var eclaircieDensiteMin = this.ppl.lireEclaircieDensiteMin();
        // volume minimum d'éclaircie (pourcentage de la densité initiale)
        var eclaircieVolumePourCentMin = this.ppl.lireEclaircieVolumePourCentMin();

        // cas particulier des SEMIS
        if ((this.ppl.cultureCode == "SEMIS") || (this.ppl.cultureCode == "SENAT")) {
            var tabDepressages = JSON.parse(this.ppl.essence.semisJson);
            tabDepressages.forEach(depressageSemis => {
                if (cptAns == depressageSemis.annee) {
                    densite = this.tabValeursCalculeeS[cptAns].densite;
                    //if ((densite > depressageSemis.densite) && ((depressageSemis.densite / densite * 100) >= eclaircieVolumePourCentMin)) {
                    if ((densite > depressageSemis.densite) && this.validerPourcent(densite, depressageSemis.densite, eclaircieVolumePourCentMin) ) {
                        densiteApres = depressageSemis.densite;
                        tarif = depressageSemis.tarif;
                        }
                }
            })
        }

        if (!densiteApres) { // on n'a pas déclenché l'éclaircie spécifique
                // demande CPFA de travailler sur l'anticipation des valeurs de fin d'année : "cptAns+1" à la place de "cptAns"
            //var circonferenceX = "circonference"+Math.trunc(this.tabValeursCalculeeS[cptAns].circonference*100); // en cm
            var circonferenceX = "circonference"+Math.trunc(this.tabValeursCalculeeS[cptAns+1].circonference*100); // en cm
            var eltAbaque = this.constantesSteres.abaqueEclaircies[circonferenceX];
            if (typeof(eltAbaque) === 'undefined') {
                 continuer = false;
             }
                // demande CPFA de travailler sur l'anticipation des valeurs de fin d'année : "cptAns+1" à la place de "cptAns"
            //var densite = this.tabValeursCalculeeS[cptAns].densite;
            densite = this.tabValeursCalculeeS[cptAns+1].densite;
            if (continuer && (densite < eltAbaque.densiteMax)) {
                continuer = false;
            }

            if (!continuer) {
                this.tabValeursCalculeeS[cptAns].eclaircieClass = false; // juste pour l'affichage
                this.tabValeursCalculeeS[cptAns].eclairciePropClass = false; // juste pour l'affichage
                this.tabValeursCalculeeS[cptAns].densiteEclaircie = 0;
                this.tabValeursCalculeeS[cptAns].surfaceTerriereEclaircie = 0;
                this.tabValeursCalculeeS[cptAns].volumeRecolteEclaircie = 0;
                this.tabValeursCalculeeS[cptAns].circonferenceAvantEclaircie = -1;
                return(0);
            }

            // utilisation des nouveaux paramètres
            densiteApres = eltAbaque.densiteMin;
            if (densiteApres < eclaircieDensiteMin) {
                densiteApres = eclaircieDensiteMin;
            }
            //if ( (densiteApres >= densite) || ((densiteApres / densite * 100) < eclaircieVolumePourCentMin) ) {
            if ( (densiteApres >= densite) || !this.validerPourcent(densite, densiteApres, eclaircieVolumePourCentMin) ) {
                return(0);
            }
            else {
                var debugOk = 1; // debug
            }
        }

        if (!integrerEclaircie) {
            return(1); // l'eclaircie ne doit pas être intégrée au tableau de valeur, on se contente de la détecter
        }

        var eclaircie = {};
        eclaircie.age = cptAns;
        eclaircie.densiteApres = densiteApres;
        eclaircie.densite = densite - eclaircie.densiteApres;
        eclaircie.proposition = true;
        if (tarif >= 0) {
            eclaircie.tarif = tarif;
        }

        this.eclaircies[eclaircie.age] = eclaircie;
        this.eclairciesAuto.push(eclaircie);

        this.tabValeursCalculeeS[cptAns].eclairciePropClass = true; // juste pour l'affichage

        return(this.integrerEclaircieAnnee(cptAns, 1));
    }

//************************************
// l'éclaircie répond-telle à la règle : pourcentage minimum
//*********************************
    validerPourcent(avant, apres, min) {
        if (!avant) return false;
        var rapport = (avant-apres) / avant;
        if ((rapport*100) < min) return false;
        return true;
    }


//*******************************************
// anticiperPourEclaircie
// Suite à modification demande :
// les valeurs utilisées pour le déclenchement d'une éclairice à l'année N ne sont plus celles de l'année N mais celle de l'année N+1
// donc à chaque année N on calcule des valeurs virtuelles N+1 pour savoir si on déclenche une éclaircie
// si éclairice déclenchée : ces valeurs n'existeront jamais
//*********************************
	anticiperPourEclaircie = function(annee, hauteurCalculee) {

        var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
        var anneeReelle = annee + this.ppl.anneeRetenue;
        //if ((anneeReelle < anneeMinAcceptable) && !this.calculOptimumPasse) {
        if ((anneeReelle < anneeMinAcceptable) && !this.derniereInfoValidePassee(annee)) {
            return; // on ne déclenche pas d'éclaircie
        }

        // hauteur (et circonférence)
        this.initialiserValeurCalculeeHauteur(annee+1, hauteurCalculee);

        // pour affichage DEBUG
        this.tabValeursCalculeeS[annee].anticipationHauteur = this.tabValeursCalculeeS[annee+1].hauteur;
        this.tabValeursCalculeeS[annee].anticipationCirconference = this.tabValeursCalculeeS[annee+1].circonference;

        // on peut maintenant connaitre la densite
        var densite = this.calculerDensite(annee);
        // on stocke la densite
        this.initialiserValeurCalculeeDensite(annee+1, densite);

        // pour affichage DEBUG
        this.tabValeursCalculeeS[annee].anticipationDensite = this.tabValeursCalculeeS[annee+1].densite;
    }

//************************************
// année minimum pour proposer une éclaircie
//*********************************
    anneeMinAcceptable(moisBascule) { // prise en compte de l'éclaircie aprés que le temps ... soit passé ...
        var lAnnee = new Date().getFullYear();
        var moisReel = new Date().getMonth()+1;
        if (moisReel >= moisBascule) {
            lAnnee += 1;
        }
        return lAnnee;
    }

    anneeAujourdhui() {
        var lAnnee = new Date().getFullYear();
        return lAnnee;
    }

//************************************
// éclaircie sans mesures
//*********************************
    testerEclaicieIncomplete(mesureOuEclaircie) {
        if (typeof(mesureOuEclaircie.dateEclaircie) !== 'undefined') {
            if (!mesureOuEclaircie.circonferenceQuadratique) {
                return(true);
            }
        }
        return(false);
    }

//************************************
// cohérence avec règles ... pour mesures ou éclaircies existantes
//*********************************
    mesureOuEclaircieAcceptable(mesureOuEclaircie) { // cohérence avec règles ...

        const moisBascule = this.constantesSteres.moisBascule;
        const eclaicieIncomplete = this.testerEclaicieIncomplete(mesureOuEclaircie) ;

        const anneePossible = this.ppl.anneeRetenue+this.ppl.essence.borneMesureAutorisee;
        var anneeRetenue = mesureOuEclaircie.anneeRetenue;
        var dateEclaircie = mesureOuEclaircie.dateEclaircie;
        if (typeof(anneeRetenue) === 'undefined') {
            // possible pour les ITs
            if (typeof(mesureOuEclaircie.age) !== 'undefined') {
                anneeRetenue = this.ppl.anneeRetenue + mesureOuEclaircie.age;
            }
            else {
                anneeRetenue = dateEclaircie;
            }
            dateEclaircie = "" + anneeRetenue + "-03";
        }
        if ((anneeRetenue > anneePossible)
            || (eclaicieIncomplete && (this.ppl.dateInstallation < dateEclaircie))) {

            if (anneeRetenue <= anneePossible && eclaicieIncomplete) {
                mesureOuEclaircie.depressage = true;
            }

            return(true);
        }

        mesureOuEclaircie.invalide = true;
        return(false);
    }


/****************************************************************** Stockage dans la table des valeurs *************************************************************/
/***************************************************************** et calculs intermédiares ************************************************************************/

    //*********************************
	initialiserValeurCalculee = function(age) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            this.tabValeursCalculeeS[age].cumulVolumeRecolteAvantEclaircie = 0;
            this.tabValeursCalculeeS[age].cumulVolumeRecolteEclaircie = 0;
            this.tabValeursCalculeeS[age].eclaircieProspective = false;
        }
    }
    //*********************************
	initialiserValeurCalculeeHMAX = function(age, HMAX) {
        this.initialiserValeurCalculee(age);
        this.tabValeursCalculeeS[age].HMAX = HMAX;
    }


    //*********************************
    initialiserValeurCalculeeHauteurAvantEclaircie = function(age, hauteur) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }
        this.tabValeursCalculeeS[age].hauteurAvantEclaircie = hauteur;
    }


    //*********************************
	initialiserValeurCalculeeHauteur = function(age, hauteur) {

        this.initialiserValeurCalculee(age);

        // 2020/05
        this.tabValeursCalculeeS[age].hauteurPrecedente = 0;
        this.tabValeursCalculeeS[age].HMAXPrecedent = this.tabValeursCalculeeS[age].HMAX;
        if (age) {
            this.tabValeursCalculeeS[age].hauteurPrecedente = this.tabValeursCalculeeS[age-1].hauteur;
        this.tabValeursCalculeeS[age].HMAXPrecedent = this.tabValeursCalculeeS[age-1].HMAX;
        }

        this.tabValeursCalculeeS[age].hauteur = hauteur;
        this.tabValeursCalculeeS[age].hauteurAvantEclaircie = -1;

        // et dans tous les cas, on recalcule la circonférence
        // ce qui veut dire qu'en cas de calcul "direct" de la circonférence (eclaircie) : il faut appeler "initialiserValeurCalculeeCirconference" en direct
        var croissanceCirconference = 0;
        var circonference = 0;
        if (age && (this.tabValeursCalculeeS[age-1].hauteur > this.ppl.essence.hauteurSeuilCirconference0) ) { // NB : sous la hauteur seuil, la circonférence est TOUJOURS nulle
            var croissanceHauteur = this.tabValeursCalculeeS[age].hauteur - this.tabValeursCalculeeS[age-1].hauteur;
            var croissanceCirconferencePotentielle = croissanceHauteur * this.ppl.essence.RACH;
            if (0) { // le test ci-dessous est abandonné JC DUPRAT - on considère qu'on est toujours en concurrence
            /*if (!this.infosFinInstallation || (typeof(this.infosFinInstallation.age) === 'undefined') || (age <= this.infosFinInstallation.age)) {*/
                croissanceCirconference = croissanceCirconferencePotentielle;
                circonference = this.tabValeursCalculeeS[age-1].circonference + croissanceCirconference
            }
            else { // TODO
                croissanceCirconference = croissanceCirconferencePotentielle * this.tabValeursCalculeeS[age-1].concurrence;
                circonference = this.tabValeursCalculeeS[age-1].circonference + croissanceCirconference;
                if (this.tabValeursCalculeeS[age-1].concurrence == -1) {
                    circonference = -1;
                }
            }

        }



        this.initialiserValeurCalculeeCirconference(age, circonference);
        this.tabValeursCalculeeS[age].croissanceCirconference = croissanceCirconference;

        // pour marquer l'année courante (on le fait ici car on calcule TOUJOURS la hauteur)
        this.tabValeursCalculeeS[age].anneeCouranteClass = false; // pour l'affichage
        if (age == this.ppl.anneeCourante-1) {
            this.tabValeursCalculeeS[age].anneeCouranteClass = true; // pour l'affichage
        }
    }


    //*********************************
	initialiserValeurCalculeeCirconference = function(age, circonference) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }

        // 2020/05
        this.tabValeursCalculeeS[age].circonferencePrecedente = 0;
        if (age) {
            this.tabValeursCalculeeS[age].circonferencePrecedente = this.tabValeursCalculeeS[age-1].circonference;
        }

        //this.tabValeursCalculeeS[age].circonference = this.arrondir(circonference);
        this.tabValeursCalculeeS[age].circonference = circonference;


        // le volume unitaire est fonction de la hauteur et de la circonférence : V = (c² × H / 4π) * coef forme = volume cylindre  * coef forme
        if (circonference == -1) {
            this.tabValeursCalculeeS[age].volumeUnitaire = -1;
        }
        else {
            this.tabValeursCalculeeS[age].volumeUnitaire = (Math.pow(circonference, 2) * this.tabValeursCalculeeS[age].hauteur / (4 * this.constantesSteres.PI)) * this.ppl.essence.coefForme;
        }
    }

    //*********************************
    initialiserValeurCalculeeDensite = function(age, densite) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }

        var essence = this.ppl.essence;

        this.tabValeursCalculeeS[age].densite = densite;

        if (densite == -1) {
            this.tabValeursCalculeeS[age].volumeUnitaire = -1; // 2022/10
            this.tabValeursCalculeeS[age].circonference = -1; // 2022/10
            this.tabValeursCalculeeS[age].surfaceTerriere = -1;
            this.tabValeursCalculeeS[age].concurrence = -1;
            this.tabValeursCalculeeS[age].mortalite = -1;
            this.tabValeursCalculeeS[age].volume = -1;
            return;
        }

        // surface terrière qui dépend directement de la densité et de la circonférence
        var circonference = this.tabValeursCalculeeS[age].circonference;
        var surfaceTerriere = densite * (Math.pow(circonference * 100, 2) / (4*this.constantesSteres.PI)) / 10000;
        this.tabValeursCalculeeS[age].surfaceTerriere = surfaceTerriere;


        // et la concurrence qui dépend directement de la densité et de la circonférence
        var concurrence = 1-(essence.k*this.tabValeursCalculeeS[age].densite*circonference*100);
        this.tabValeursCalculeeS[age].concurrence = concurrence;

        // et la mortalité qui dépend directement de la concurrence
        var mortalite = essence.mortalite;
        this.tabValeursCalculeeS[age].concurrenceClass = false; // pour l'affichage
        if (age) {
            if (concurrence < essence.seuilMortaliteConcurrence) {
                mortalite = this.tabValeursCalculeeS[age-1].mortalite + ( this.tabValeursCalculeeS[age-1].mortalite * essence.augmentationMortalite);
                mortalite = Math.max(mortalite, essence.mortalite);
                this.tabValeursCalculeeS[age].concurrenceClass = true; // pour l'affichage
            }
        }
        // pause mortalité après eclarcie :-)
        if ((age > essence.pauseMortaliteEclarcie) && (this.eclaircies[age-1] || this.eclaircies[age])) { // pas de mortalité pendant 2 ans à partir de l'année de l'éclaircie
            mortalite = 0;
        }
        this.tabValeursCalculeeS[age].mortalite = mortalite;


        // on peut passer aux valeurs de volume (/ha)
        this.tabValeursCalculeeS[age].volume = this.tabValeursCalculeeS[age].volumeUnitaire*densite;
        // 2020/05
        this.tabValeursCalculeeS[age].volumePrecedent = 0;
        if (age) {
            this.tabValeursCalculeeS[age].volumePrecedent = this.tabValeursCalculeeS[age-1].volume;
        }

    }

/****************************************************************** FONCTIONS "LIBRAIRIE" *************************************************************/


//*********************************
// calculerHauteur
// param : HMAX, hauteurPred, essence
// nouvelle hauteur
//*******************************
    calculerHauteur = function(HMAX, hauteurPred, essence) {
        var iH = essence.a * (HMAX - hauteurPred);
        var hauteurCalculee = hauteurPred+iH;
        return(hauteurCalculee);
    }

//*********************************
// calculerDensite
// param : année précédente
// prise en compte de la mortalité
// densité à l'hectare
//*******************************
    calculerDensite = function(age) {
        var densitePred = this.tabValeursCalculeeS[age].densite;
        if (densitePred == -1) { // on n'a pas cette valeur !
            return(densitePred)
        }
        return(densitePred - (densitePred * this.tabValeursCalculeeS[age].mortalite));
    }

//*********************************
// integrerMesuresAnnee
// remplace une hauteur calculée par une hauteur mesurée
// param : année
// return : hauteur
//*********************************
	integrerMesuresAnnee = function(age) {

        var hauteurMesuree = 0;
        this.tabValeursCalculeeS[age].mesuresClass = false; // juste pour l'affichage
        if (typeof(this.mesures[age]) !== 'undefined') {
            hauteurMesuree = this.mesures[age].hauteur;
            this.tabValeursCalculeeS[age].hauteurAvantMesure = this.tabValeursCalculeeS[age].hauteur;
            this.initialiserValeurCalculeeHauteur(age, hauteurMesuree); // et du coup on ecrase l'année en cours avec la valeur mesurée

            // idem pour circonference
            this.initialiserValeurCalculeeCirconference(age, this.mesures[age].circonference);
            // et densité
            this.initialiserValeurCalculeeDensite(age, this.mesures[age].densite);

            this.tabValeursCalculeeS[age].mesuresClass = true; // juste pour l'affichage
        }
        return(hauteurMesuree);
    }

//*********************************
// integrerEclaircieAnnee
// remplace une hauteur calculée par une hauteur mesurée
// calcule les valeurs de l'éclaircie et les valeurs avant (différentes des valeurs calculées)
// param : année
// return : hauteur
// Utilise :
// - Circonférence après éclaircie = CAPR = RACINE( GAPR * 4π / NAPR)
// - Hauteur après éclaircie =  HAPR = (HAV * NAV - HECL * NECL) / NAPR
//*********************************
	integrerEclaircieAnnee = function(age, prospective) {

        var essence = this.ppl.essence;
        var eclaircie = this.eclaircies[age];

        // pas d'éclaircie ou éclaircie invalide
        if ( (typeof(eclaircie) === 'undefined') || (!this.mesureOuEclaircieAcceptable(eclaircie)) ) {
            this.tabValeursCalculeeS[age].eclaircieClass = false; // juste pour l'affichage
            this.tabValeursCalculeeS[age].eclairciePropClass = false; // juste pour l'affichage
            this.tabValeursCalculeeS[age].densiteEclaircie = 0;
            this.tabValeursCalculeeS[age].surfaceTerriereEclaircie = 0;
            this.tabValeursCalculeeS[age].volumeRecolteEclaircie = 0;
            this.tabValeursCalculeeS[age].circonferenceAvantEclaircie = -1;
            // ajout 2020/05
            this.tabValeursCalculeeS[age].hauteurEclaircie = 0;
            this.tabValeursCalculeeS[age].circonferenceEclaircie = 0;

            return(0);
        }


        var tarifEclaircie = eclaircie.tarif;
        if (typeof(tarifEclaircie) !== 'undefined') {
            this.tabValeursCalculeeS[age].tarifEclaircie = tarifEclaircie;
        }

        // calcul de la nouvelle hauteur
        var hauteur = this.tabValeursCalculeeS[age].hauteur;
        var densite = this.tabValeursCalculeeS[age].densite;
        var densiteEclaircie = densite - eclaircie.densiteApres;
        if (densite <= 0) {
            densiteEclaircie = -1;
        }
        this.tabValeursCalculeeS[age].densiteApres = eclaircie.densiteApres;
        this.tabValeursCalculeeS[age].densiteEclaircie = densiteEclaircie;
        var hauteurApresEclaircie = ((hauteur * densite) - (hauteur * essence.coefEclaircie * densiteEclaircie))/eclaircie.densiteApres;
        if ((typeof(eclaircie.hauteurMoyenne) !== 'undefined') && eclaircie.hauteurMoyenne) {
            hauteurApresEclaircie = eclaircie.hauteurMoyenne; // si on a saisie une mesure lors de l'éclaircie, elle est prioritaire sur le calcul
        }
        this.tabValeursCalculeeS[age].hauteur = hauteurApresEclaircie; // on ecrit en "direct"

        // calcul de la nouvelle circonférence
        var circonference = this.tabValeursCalculeeS[age].circonference;
        //var surfaceTerriere = densite * (Math.pow(circonference * 100, 2) / (4*this.constantesSteres.PI)) / 10000;
        var surfaceTerriere = this.tabValeursCalculeeS[age].surfaceTerriere;
        var surfaceTerriereEclaircie = densiteEclaircie * (Math.pow(circonference *  essence.coefEclaircieCirc * 100, 2) / (4*this.constantesSteres.PI)) / 10000;
        this.tabValeursCalculeeS[age].surfaceTerriereEclaircie = surfaceTerriereEclaircie;
        var surfaceTerriereApres = surfaceTerriere - surfaceTerriereEclaircie;
        var circonferenceApresEclaircie = Math.sqrt(surfaceTerriereApres * 4 * this.constantesSteres.PI / eclaircie.densiteApres);
        if ((typeof(eclaircie.circonferenceQuadratique) !== 'undefined') && eclaircie.circonferenceQuadratique) {
            circonferenceApresEclaircie = eclaircie.circonferenceQuadratique/100; // si on a saisie une mesure lors de l'éclaircie, elle est prioritaire sur le calcul
        }

            // ajout 2020/05
        var hauteurAvantEclaircie = (hauteurApresEclaircie * eclaircie.densiteApres) / (densite - (essence.coefEclaircie * densiteEclaircie)); // 2020/07 - on repart des valeurs après éclaircies
        this.tabValeursCalculeeS[age].hauteurEclaircie = hauteurAvantEclaircie*essence.coefEclaircie;
        //this.tabValeursCalculeeS[age].hauteurEclaircie = hauteur*essence.coefEclaircie; // TODO calculer à partir de hauteurApresEclaircie
        var surfaceTerriereApresCorrigee = circonferenceApresEclaircie * circonferenceApresEclaircie * eclaircie.densiteApres / (4 *this.constantesSteres.PI);
        var surfaceTerriereAvantCorrigee = surfaceTerriereEclaircie + surfaceTerriereApresCorrigee;
        var circonferenceAvantCorrigee = Math.sqrt(surfaceTerriereAvantCorrigee * 4 * this.constantesSteres.PI / densite);
        //circonference = circonferenceAvantCorrigee;

        // 2022/12 - on revient au calcul simple suite à la réunion du 2022/12/19
        this.tabValeursCalculeeS[age].circonferenceEclaircie = circonference*essence.coefEclaircieCirc;
        //this.tabValeursCalculeeS[age].circonferenceEclaircie = circonferenceAvantCorrigee*essence.coefEclaircieCirc;

        this.tabValeursCalculeeS[age].densiteAvantEclaircie = densite; // on sauve la densite d'avant pour "comprendre"
        this.tabValeursCalculeeS[age].circonferenceAvantEclaircie = circonference; // on sauve la circonference d'avant pour "comprendre"
        // attention, l'écriture en direct ne modifie pas le volume unitaire ! il faut donc passer par la fonction standard
        //this.tabValeursCalculeeS[age].circonference = circonferenceApresEclaircie; // on ecrit en "direct"
        this.initialiserValeurCalculeeCirconference(age, circonferenceApresEclaircie);

        this.tabValeursCalculeeS[age].volumeUnitaireAvantEclaircie = ((Math.pow(circonference, 2) * hauteur)/(4*this.constantesSteres.PI))*essence.coefForme;
        this.tabValeursCalculeeS[age].volumeAvantEclaircie = this.tabValeursCalculeeS[age].volumeUnitaireAvantEclaircie * densite;

        if (this.ppl.uf.identifiant == "Semis abandonné") {
            var toto = 1;
        }

        var volumeUnitairePotentielEclaircie = ((Math.pow(circonference*essence.coefEclaircieCirc, 2) * (hauteur*essence.coefEclaircie))/(4*this.constantesSteres.PI))*essence.coefForme;
        this.tabValeursCalculeeS[age].volumeUnitaireEclaircie = volumeUnitairePotentielEclaircie; // 0 possible
        this.tabValeursCalculeeS[age].volumeRecolteEclaircie = volumeUnitairePotentielEclaircie * densiteEclaircie; // 0 possible
        //this.tabValeursCalculeeS[age].cumulVolumeRecolteAvantEclaircie = this.tabValeursCalculeeS[age].cumulVolumeRecolteEclaircie;
        this.tabValeursCalculeeS[age].cumulVolumeRecolteEclaircie += this.tabValeursCalculeeS[age].volumeRecolteEclaircie;

        this.tabValeursCalculeeS[age].eclaircieClass = true; // juste pour l'affichage

        // 2022/12
        const isProspective = prospective || eclaircie.fromIT;
        this.tabValeursCalculeeS[age].eclaircieProspective = false;
        if (isProspective) { // découpage pour debug
            this.tabValeursCalculeeS[age].eclaircieProspective = true;
        }

        return(hauteurApresEclaircie);
    }

//*********************************
// année retenue en fonction du mois (et du mois bascule)
//*********************************
    anneeRetenue = function(dateInstallation) {
        var constantes = {};
        constantes.steres = this.constantesSteres;
        return(this.anneeRetenuePeuplement(dateInstallation, constantes));
	}
//*********************************
// année retenue en fonction du mois (et du mois bascule passé en param)
// duplication de ApiUser
//*********************************
	anneeRetenuePeuplement(dateInstallation, constantes) {
		if (!dateInstallation.length) {
			return("Date d'installation inconnue");
		}
		var annee = parseInt(dateInstallation.substring(0, 0+4));
        if (dateInstallation.length == 4) {
            return(annee);
        }
		var mois = parseInt(dateInstallation.substring(5, 5+2));
		if (mois >= constantes.steres.moisBascule) {
			annee++;
		}
		return(annee);
	}


//*********************************
// finInstallation
// params : hauteur
// return : booléen - fin d'installation si la hauteur passée en paramètre dépasse la hauteur de fin d'installation de l'essence
//*********************************
	finInstallation = function(hauteur) {
        //var cultureCode = this.ppl.cultureCode;
        var essence = this.ppl.essence;

        if (hauteur >= essence.hauteurFinInstallation) {
            this.debug = this.debuger();
            return(1);
            }
            this.debug = false;
        return(0);
    }

    //*********************************
	debuger = function() {

            this.debug = true;
        return(false);
    }


    //*********************************
    arrondir = function(valeur, puissance = 3) {
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

//********************************************************************************************************
// validerMesurePred : peut-on utiliser la mesure précédente (proximité temporelle avzec la suivante) - NB : le smesures les plus récentes sont les plus pertinentes
//********************************************************************************************************
    validerMesurePred = function(mesure, mesurePred) {
        if (mesure.anneeDate-mesurePred.anneeDate < this.ppl.essence.deltaMesuresPourHMAX) {
            return(false);
        }
    return(true);
    }

//*********************************
// estFutur : est-on dans le futur
//*********************************
	estFutur = function(annee) {
        return(annee >= this.ppl.anneeCourante-1);
    }

}


export const UfPplDendrometrique = new UF_PPL_DENDROMETRIQUE()
export const UfPplDendrometrique1 = new UF_PPL_DENDROMETRIQUE()
export const UfPplDendrometrique2 = new UF_PPL_DENDROMETRIQUE()
