<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="peuplementEditionMesure" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="mesure.id" class="blocACompleter">Edition d'une mesure</h1>
                <h1 v-if="!mesure.id" class="blocACompleter">Ajout d'une mesure</h1>

                <span v-if="mesure.id && !$store.getters.modeForet" class="complementBloc">
                <v-btn class="boutonFonctionObjet" type="button" @click="supprimerMesureouPas">Supprimer</v-btn>
                </span>


                <p class="info">Les champs suivants marqués du symbole * sont obligatoires.</p>

                <div class="edition">
                    <mesures-form-peuplement :data='mesure' :data2='peuplement'></mesures-form-peuplement>
                    <div class="illustration illustration2"></div>
                </div>

                <v-dialog v-model="dialog" v-if="mesure.id" >
                    <v-card>
                        <v-card-title class="headline">Suppression</v-card-title>

                        <v-card-text>
                            Voulez-vous vraiment supprimer la mesure  {{ mesure.dateMesure }}?<br/>Cette action est irréversible.
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="dialog = false">Non</v-btn>
                            <v-btn color="" text @click="supprimerMesure()">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </div>
        </div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import PeuplementMesureForm from '../components/uf/PeuplementMesureForm.vue'

export default {

    components: {
        'mesures-form-peuplement': PeuplementMesureForm,
     },

    data() {
        return {
            uf:this.$store.getters.infosUf,
            peuplement:this.$store.getters.infosPeuplement,
            mesure: {
                "id":0,
                "userId":this.$store.getters.utilisateurCourantId,
                "peuplementId":this.$store.getters.peuplementId,
                "utilisateurId":this.$store.getters.utilisateurCourantId,
                "dateMesure":"",
                "densiteMoyenne":0.0,
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "hauteurMoyenne":0.0,
                "hauteurJson":"[]",
                "tabHauteurs":[],
                "circonferenceQuadratique":0.0,
                "circonferenceQuadratiqueJson":"[]",
                "tabCirconferences":[],
                "version":0,
				"idLocal":true,
            },
            ready: false,
            dialog: false
        }
    },
    created() {

    },

    mounted: function () {
        this.mesureCourante();
        if (this.$store.getters.modeForet == 8) {
            this.$store.commit('setModeForet', 9);
        }
    },


    watch: {
        modeForetPreparation(newVal) {
/*
            if (newVal == 9 && this.ready) {
                setTimeout(this.avancerVers9(), 500); // normalement on ne passe pas par là
                ;
            }
*/
        },
        isReady(newVal) {
            if (newVal && this.modeForetPreparation == 9) {
                setTimeout(this.avancerVers9, 245); // normalement on passe pas par là
            }
        }
    },

    computed: {
        // UF à jour
        laUF() {
            return this.uf
        },
        // Peuplement à jour
        lePeuplement() {
            return this.peuplement
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        modeForetPreparation() {
            return(this.$store.getters.modeForet);
        },
    },

    methods: {
        avancerVers9() {
            this.$parent.clicPourAvanceModeForet('lienModeForet', 0);
            setTimeout(this.avancerVers9Suite, 245);
        },
         avancerVers9Suite() {
            this.$parent.clicPourAvanceModeForet('lienAnnulerModeForet', 0);
            this.$parent.clicPourAvanceModeForet('lienModeForet', 1);
            setTimeout(this.avancerVers9SuiteSuite, 245);
        },
         avancerVers9SuiteSuite() {
            this.$parent.clicPourAvanceModeForet('lienAnnulerModeForet', 0);
            this.$parent.clicPourAvanceModeForet('lienModeForet', 2);
            setTimeout(this.avancerVers9Fin, 245);
        },
         avancerVers9Fin() {
            this.$parent.clicPourAvanceModeForet('lienAnnulerModeForet', 0);
            setTimeout(() => {this.$router.push({ name: "peuplementeclaircie" });}, 245);
        },
        // lecture en base et maj du ready
        mesureCourante() {
            var mesureId = this.$store.getters.mesureId;
            if (mesureId > 0) {
                return ApiUser.lireMesure(mesureId).then(response => {
                    this.mesure = response.mesure;
                    this.ready = true;
                })
            }
            else {
                this.ready = true;
            }
        },
        // fonctions sur boutons
        supprimerMesureouPas() {
            this.dialog = true;
        },
        supprimerMesure() {
            this.dialog = false;
            ApiUser.deleteMesure(this.mesure) // on detruit notre mesure (en DB locale et SI POSSIBLE en ligne)
            this.$store.commit('donnerMessage', "La mesure du "+this.mesure.dateMesure+" a bien été supprimée");
            //this.$store.commit('setMesureId', 0);
            //this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplement" });
        }

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
}
</style>
