<template>
    <div id="navFooter">
        <div class="logoCPFA">
        </div>
        <div class="navConteneur">
            <div v-if="!this.$store.getters.modeForet">
                <router-link to="/credits" class="routeEnCours itemFooterBas" >Crédits</router-link>
                <span class="itemFooterBas"><i class="fas fa-circle"></i></span>
                <router-link to="/conditionsGenerales" class="routeEnCours itemFooterBas" >Conditions Générales d'Utilisation</router-link>
                <span class="itemFooterBas"><i class="fas fa-circle"></i></span>
                <router-link to="/donneespersonnelles" class="routeEnCours itemFooterBas" >Données personnelles</router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        /**
         * Données à injecter
         */
        data: {
            type: Object
        }
    },
    data() {
        return {
            // init les données du form à partir des props
            datasMenu : this.data,
        }
    },
    created() {
    },
    computed: {
        connexionOK() {
            return(this.data.connexion);
        },
        nomPropriete() {
            var chaine = this.$store.getters.infosPropriete.identifiant;
            if (!chaine) {
                return("");
            }
            if (this.$store.getters.infosPropriete.identifiant.length >= 28) {
                var newChaine = this.$store.getters.infosPropriete.identifiant.slice(0, 25);
                newChaine += "..."
            }
            else {
                return this.$store.getters.infosPropriete.identifiant
            }
            return(newChaine)
        },
        lIt() {
            var peuplement = this.$store.getters.infosPeuplement;
            var itId = this.data.itId;
            var trouve = "Itinéraire technique";
                if (peuplement) {
                peuplement.ITs.forEach(it => {
                    if (it.id == itId) {
                        trouve = it.identifiant;
                    }
                })
            }
            return(trouve);
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
    #app {
        @media screen and (max-width: 1024px) {
             .containerLog {
                 padding-bottom: 160px !important;
             }
        }
        div#footer.footer {
            min-height: 68px;


            @media screen and (max-width: 640px) {
                padding:0 !important;
                margin:0;
            }

            @media screen and (max-width: 440px) {
                min-height:100px;
                #navFooter {
                    min-height:100px;
                }
            }

            @media screen and (max-width: 1024px) {

                #navFooter {
                    min-height:160px !important;
                }
                .itemFooterBas {
                    display:block;
                    padding:0.2em 0;
                    font-size: 80%
                    }
            }

            #navFooter {
                width: 100%;
                /*max-width:1000px;*/
                margin: 0;
                padding: 0;
                text-align: center;
                font-size:0.8em;
                min-height: 80px;

                .logoCPFA {
                    min-width: 120px;
                    min-height: 60px;
                    position: absolute;
                    bottom: 5px;
                    right: 2%;
                    background-image: url("../../assets/img/logoCPFA.png");
                    background-size: 120px 60px;
                    z-index: 3;

                    @media screen and (max-width: 640px) {
                        min-width: 90px;
                        min-height: 45px;
                        background-size: 90px 45px;
                        right: 0;
                        }
                }

                .navConteneur {
                    padding: 25px 0 0;
                    min-height: 68px;

                    span.routeEnCours, a.routeEnCours {
                        border-bottom: none;
                        height: auto;
                        display: inline-block;
                    }

                    a {
                        &.router-link-exact-active {
                        /*color: #42b983;*/
                        }
                        &:hover {
                            color:#7DAB87;
                        }
                    }
                }
            }
        }
        &.modeForet {
            #footer.footer {
                background-color: #fed99d;
                min-height: 0;

                .messageInternet {
                    padding-top:1em;
                    font-size: 0.6em;
                }

                #navFooter {
                    min-height: 0  !important;

                    .logoCPFA {
                        background-image: url("../../assets/img/logoCPFAv.png");
                        top: 15px;
                        right: 10%;
                    }

                    #navConteneur {
                        display:none;
                        min-height: 0;
                    }
                }
            }
        }
    }
</style>

