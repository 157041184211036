<template>
 <div>
     <form v-if="isReady">

             <div v-if="lesTarifs.length">
                 Série des tarifs :
                 <div class="ligneTarifs" >
                     <span >
                         Volume Unitaire moyen (m3),
                    </span>
                     <span >
                         Prix associé (€)
                    </span>
                </div>
                 <div v-bind:class="{pair : (Math.trunc(numSaisieTarifs /2) * 2) == numSaisieTarifs, impair : (Math.trunc(numSaisieTarifs /2) * 2) !== numSaisieTarifs}" class="ligneTarifs" v-for="(itemTarifs, numSaisieTarifs) in lesTarifs" :key="numSaisieTarifs"  @click.stop="tarifsRenseignerItem(itemTarifs, numSaisieTarifs)">
                     <span class="itemIconeFct" >
                         {{ itemTarifs.volumeMoyen }}
                    </span>
                     <span class="itemIconeFct" >
                         {{ itemTarifs.prixM3 }}
                    </span>
                    <!-- <img class="iconeFct" alt="Editer le tarif" src="@/assets/img/crayon.svg" /> -->
                    <i class="fa fa-pencil svg" aria-hidden="true"></i>
                </div>
            </div>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->

        <div class="boutonsValidation">
            <v-btn class="a-btn secondaire" type="button" @click="cancelForm">Annuler</v-btn>
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Enregistrer</v-btn>
        </div>
    </form>

    <v-dialog v-model="dialogError" v-if="messageErreur" >
         <v-card>
             <v-card-title class="headline">Erreur de saisie</v-card-title>
             <v-card-text>
                 {{messageErreur}}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <tarif-serie v-if="tarifsSerieEnCours"
        @retourTarifsSerie="onRetourTarifsSerie"
        :initDatas="itemEnCours"
        :ouvrirDialog=tarifsSerieEnCours
    />


</div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
//import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObj.js'
import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObjTER.js'

import ProprieteTarifSerie from './ProprieteTarifSerie.vue'


export default {

    components: {
        'tarif-serie': ProprieteTarifSerie,
    },

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Array
        },
        data2: {
            type: Object
        }
    },

    data() {
        return {
            ready:false,
            // init les données à partir des props
            tabTarifs: JSON.parse(JSON.stringify(this.data)),
            propriete : JSON.parse(JSON.stringify(this.data2)),
            tarifsSerieEnCours:false,
            itemEnCours:null,
            numItemEnCours:0,
            dialogError:false,
            messageErreur:null
        }
    },

    created() {
        this.initItems();

    },

    watch: {
    },

    computed: {

        isReady() {
            if (!this.ready) {
                return(false);
            }
            return(this.ready);
        },


        // HAUTEURS
            // pour affichage "à jour" de la liste
        lesTarifs() {
            return(this.tabTarifs);
        },

    },

    methods: {

        //
        initItems() {
            this.ready = true;
        },

                // HAUTEURS
            // edition d'une série
        tarifsRenseignerItem(item, numItem) {
            this.tarifsSerieEnCours = true;
            this.itemEnCours = item;
            this.numItemEnCours = numItem;
        },

        onRetourTarifsSerie(item) {
            if (item) {
                this.tabTarifs[this.numItemEnCours] = item;
            }
            //this.form.tarifsJson = JSON.stringify(this.tabTarifs); // JSON.parse à l'aller
            this.tarifsSerieEnCours = false;
        },

        cancelForm() {
            this.$emit('retourForm', null);
        },

        validerTabTarifs() {
            return(true); // 2021/07 - on inhibe ce test

            var prixPrecedent = 0;
            var erreur = false;
            var listeVolumesMoyens = "";
            this.tabTarifs.forEach(tarif => {
                if (tarif.prixM3 < prixPrecedent) {
                    if (erreur) {
                        listeVolumesMoyens += ", ";
                    }
                    listeVolumesMoyens += tarif.volumeMoyen;
                    erreur = true;
                }
                prixPrecedent = tarif.prixM3;
            })
            if (erreur) {
                this.messageErreur = "Les tarifs ne peuvent pas diminuer quand le volume augmente. Vérifiez les lignes qui correspondent aux volumes moyens suivants : " +listeVolumesMoyens;
                this.dialogError = true;
                return(false);
            }
            return(true);
        },

        // enregistrement du formulaire
        saveForm() {
            if (this.validerTabTarifs()) {
                this.$emit('retourForm', this.tabTarifs);
            }
        },

        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },

        fermerDialogError() {
            this.dialogError = false;
            this.messageErreur = null;
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .ligneTarifs {
        padding: 0.2em 0.4em 0.2em 8%;
        &.impair {
        background-color: #e5e5e5;
        }
        &.pair {
            background-color: #F4FAF1;
        }

        &:hover {
            background-color: #fefefe;
        }

        &>span {
            width:44%;
            display: inline-block;
        }
        i.svg {
            padding-left:20%;
        }
        .itemIconeFct {
            padding-left:20%;
            width:35%;
        }
        img.iconeFct {
            float: none;
        }
    }
</style>
