<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="UfImport" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 class="">Importation automatisée d'Unités Forestières</h1>


                <p class="intro">Cette interface vous permet l'importation d'une ou plusieurs Unités Forestières - chacune comprenant une ou plusieurs parcelles cadastrale - ainsi que des peuplements associés.</p>

                <p class="info">Important : Le fichier doit impérativement correspondre au format CSV ci-dessous.</p>

                <div class="container" v-if="!erreurs">
                      <!--UPLOAD-->
                      <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                        <h2>Dépôt CSV</h2>
                        <div class="dropbox">
                            <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                            accept=".csv" class="input-file">
<!--
                            <input type="file" onchange=read(this)>
-->

                            <p v-if="isInitial">
                              Glissez/déposez votre fichier ici pour lancer l'importation<br> ou cliquez pour le rechercher
                            </p>
                            <p v-if="isSaving">
                              Uploading {{ fileCount }} files...
                            </p>
                        </div>
                      </form>
                    <div class="output" v-if="uploadedFile">{{uploadedFile}}</div>
                </div>
                <div id="erreursContainer" v-if="erreurs">
                    <div id="erreurs">
                        <ul>
                            <li v-for="erreur in tabErreurs" >
                                {{erreur}}
                            </li>
                        </ul>
                    </div>
                    <v-btn class="boutonFonctionObjet prioritaire centrer" type="button" @click.stop="reset">Recommencer</v-btn>
                </div>
            </div>

            <div class="aGauche exemples">
            <span  @click.stop="ouvrirPDF">Télécharger la notice explicative</span>
            <br/>
            <br/>
            <span  @click.stop="ouvrirCSV">Télécharger un fichier CSV exemple</span>
            <br/>
            <br/>
            <span v-if="!voirImgParams"  @click.stop="montrerImgParams">Consulter les paramètres d'exportation (tableur vers fichier CSV)</span>
            <img v-if="voirImgParams" src="@/assets/img/export.png" @click.stop="cacherImgParams" title="Cliquer pour cacher" />
            </div>
        </div>

        <div class="clear"></div>

        <v-dialog v-model="dialog">
          <v-card>
            <v-card-title class="headline">Avertissement</v-card-title>

            <v-card-text>
                <div>
                L'importation s'est déroulée correctement.
                </div>
                <div v-if="avertissementUF || avertissementPeuplement || avertissementCoherencePeuplement">
                Notez que :
                </div>
                <div v-if="avertissementUF">
                    - pour chaque Unité Forestière, les informations génériques renseignées sur leur première ligne ont été prises en compte en priorité.
                </div>
                <div v-if="avertissementPeuplement">
                    - seules les informations de peuplement renseignées sur les dernières lignes de chaque Unité Forestière ont été prises en compte.
                </div>
                <div v-if="avertissementCoherencePeuplement">
                    - pour les UF mises à jour et qui possédaient déjà un peuplement, il vous faudra valider la cohérences de vos nouvelles données avec les mesures ou éclaircies précédemment saisies .
                </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="" text @click="terminerEdition">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDialog">
                <v-card>
                    <v-card-title class="headline">Confirmation</v-card-title>
                    <v-card-text>
                        <div>Plusieurs unités forestières différentes possèdent un même libellé.</div>
                            <ul>
                                <li v-for="dupli in tabDuplis" >
                                    {{dupli}}
                                </li>
                            </ul>
                        <div>Souhaitez-vous quand même réaliser l'importation ?</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="abandonnerTraiterUFs">Non</v-btn>
                        <v-btn color="" text @click="continuerTraiterUFs">Oui</v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
        <v-dialog v-model="confirmDialog2">
                <v-card>
                    <v-card-title class="headline">Confirmation</v-card-title>
                    <v-card-text>
                        <div>Une ou des unités forestières existent déjà avec le(s) code(s) :</div>
                            <ul>
                                <li v-for="dupli in tabDuplisExistant" >
                                    {{dupli}}
                                </li>
                            </ul>
                        <div>Souhaitez-vous quand même réaliser l'importation et modifier ces UF ?</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="abandonnerTraiterUFs">Non</v-btn>
                        <v-btn color="" text @click="traiterUFs">Oui</v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>

    </div>
</template>


<script>

import { ApiConsts, ApiUser, ApiUpload } from '../js/api/index.js'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;


export default {

    components: {
 //       'edition-uf': UfEditionForm,
     },

    data() {
      return {
        uploadedFile: null,
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'leCSV',
        erreurs:false,
        dialog:false,
        avertissementUF:false,
        avertissementPeuplement:false,
        avertissementCoherencePeuplement:false,
        message:"",
        confirmDialog:false,
        tabDuplis:[],
        confirmDialog2:false,
        tabDuplisExistant:[],
        pdfChemin:'/img/',
        urlChemin:'/img/',
        pdfNom:'noticeImportsCSV.pdf',
        urlNom:'steres-exemple-importUF.csv',
        voirImgParams:false,
        UFsExistant:null
      }
    },
    created() {
        this.reset();

    },

    mounted: function () {
        //this.reset(); pas deux fois 2020/11 ?
    },

    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
    // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
    },

    methods: {
        forceFileDownload(response, leFichier){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', leFichier) //or any other extension
            document.body.appendChild(link)
            link.click()
        },
        montrerImgParams() {
            this.voirImgParams = true;
        },
        cacherImgParams() {
            this.voirImgParams = false;
        },
        ouvrirPDF() {
            this.ouvrirFichier(this.pdfChemin, this.pdfNom)
        },
        ouvrirCSV() {
            this.ouvrirFichier(this.urlChemin, this.urlNom)
        },
        ouvrirFichier(leChemin, leFichier) {
          this.$http({
            method: 'get',
            url: leChemin+leFichier,
            responseType: 'arraybuffer'
          })
          .then(response => {
            this.forceFileDownload(response, leFichier)
          })
          .catch(() => console.log('error occured'))
        },

        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFile = null;
            this.uploadError = null;
            this.ready = true;
            this.tabUFs = [];
            this.nbUImportees = 0;
            this.tabCommunes = [];
            ApiConsts.readCommunesFromTable().then(responses => { // on n'attends pas car l'utilisateur est forcément plus lent que la machine
                if (responses) {
                    responses.forEach(commune => {
                        this.tabCommunes[""+commune.codeComm] = commune;
                    })
                }
            });
            this.tabErreurs = [];
            this.erreurs = false;
            this.dialog = false;
            this.avertissementUF = false;
            this.avertissementPeuplement = false;
            this.avertissementCoherencePeuplement = false;
            this.message = "";
            this.confirmDialog=false;
            this.tabDuplis=[];
            this.tabDuplisExistant=[];
            //this.UFsExistant = this.$store.getters.infosProprieteUFs; // pour être sûr d'être à jour !  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState
            return ApiUser.lirePropriete(this.$store.getters.proprieteId, this.$store.getters.constantes).then(response => {
                this.UFsExistant  = response.UFs; // pour être sûr d'être à jour !  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState
            }) // on n'attends pas car l'utilisateur est forcément plus lent que la machine
          },
          save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            ApiUpload.upload(formData)
              .then(x => {
                this.uploadedFile = x[0].csv;
                this.currentStatus = STATUS_SUCCESS;
                this.traiterCSV();
              })
              .catch(err => {
                this.tabErreurs[0] = err.response;
                this.erreurs = true;
                this.currentStatus = STATUS_FAILED;
              });
          },
          filesChange(fieldName, fileList) {
            // handle file changes
            const formData = new FormData();

            // un seul fichier
            if (!fileList.length) {
                this.tabErreurs[0] = "Attention, vous ne pouvez importer qu'un seul fichier à la fois";
                this.erreurs = true;
                return;
            }


            // CSV
            var name = fileList[0].name;
            var extension = name.substring(name.length-4, name.length);
            if (extension.toUpperCase() != ".CSV") {
                this.tabErreurs[0] = "Attention, vous devez importer un fichier CSV, par exemple \"uf.csv\"";
                this.erreurs = true;
                return;
            }

            // append the files to FormData
            Array
              .from(Array(fileList.length).keys())
              .map(x => {
                formData.append(fieldName, fileList[x], fileList[x].name);
              });

            // save it
            this.save(formData);
          },
        traiterCSV() {
            var nbChamps = 16;
            var allTextLines = this.uploadedFile.split(/\r\n|\n/);
            if (allTextLines.length < 2) {
                this.tabErreurs[0] = "Attention, votre fichier CSV doit comporter plusieurs lignes, la première ligne étant la suivante :";
                this.tabErreurs[1] = "\"ufCode	ufLibelle	ufCodeLande	ufCommentaire	parcelleLibelle	parcelleCodePostal	parcelleCodeCommune	parcelleSectionCadastre	parcelleNumCadastre	parcelleSousParcelle	parcelleSurfaceCadastre	peuplementDateInstallation	peuplementCodeCulture	peuplementCodeEssence	peuplementSurfaceBoisee	peuplementDensiteInstallation\"";
                this.erreurs = true;
                return;
            }

            var separateur= "	";
            var headers = allTextLines[0].split(separateur);
            if (headers.length != nbChamps) {
                this.tabErreurs[0] = "Attention, votre fichier CSV doit comporter "+nbChamps+" colonnes séparées par des TABULATIONS, la première ligne étant la suivante :";
                this.tabErreurs[1] = "\"ufCode	ufLibelle	ufCodeLande	ufCommentaire	parcelleLibelle	parcelleCodePostal	parcelleCodeCommune	parcelleSectionCadastre	parcelleNumCadastre	parcelleSousParcelle	parcelleSurfaceCadastre	peuplementDateInstallation	peuplementCodeCulture	peuplementCodeEssence	peuplementSurfaceBoisee	peuplementDensiteInstallation\"";
                this.erreurs = true;
                return;
            }

            var lines = [];
            var parseOK = true;
            for (var i=1; i<allTextLines.length; i++) {
                var data = allTextLines[i].split(separateur);
                if (data.length == headers.length) {
                    var line = '{';
                    for (var j=0; j<headers.length; j++) {
                        var tmp = '"'+headers[j]+'" : "'+data[j]+'"';
                        line += tmp;
                        if (j+1<headers.length) {
                            line += ", ";
                        }
                    }
                    line += '}';
                    try {
                        var ligneObj = JSON.parse(line);
                        } catch (e) {
                        parseOK = false;
                        }
                    if (parseOK && (
                        (typeof ligneObj.ufCode === "undefined")
                        || (typeof ligneObj.ufCode === "undefined")
                        || (typeof ligneObj.ufLibelle === "undefined")
                        || (typeof ligneObj.ufCodeLande === "undefined")
                        || (typeof ligneObj.ufCommentaire === "undefined")
                        || (typeof ligneObj.parcelleLibelle === "undefined")
                        || (typeof ligneObj.parcelleCodePostal === "undefined")
                        || (typeof ligneObj.parcelleCodeCommune === "undefined")
                        || (typeof ligneObj.parcelleSectionCadastre === "undefined")
                        || (typeof ligneObj.parcelleNumCadastre === "undefined")
                        || (typeof ligneObj.parcelleSousParcelle === "undefined")
                        || (typeof ligneObj.parcelleSurfaceCadastre === "undefined")
                        || (typeof ligneObj.peuplementDateInstallation === "undefined")
                        || (typeof ligneObj.peuplementCodeCulture === "undefined")
                        || (typeof ligneObj.peuplementCodeEssence === "undefined")
                        || (typeof ligneObj.peuplementSurfaceBoisee === "undefined")
                        || (typeof ligneObj.peuplementDensiteInstallation === "undefined")) ) {
                        parseOK = false;
                        }
                    lines.push(ligneObj); // tableau de textes au format Json
                }
                else {
                    if (allTextLines[i] != "") { // pas d'erreur sur suat de ligne
                        parseOK = false;
                    }
                }

                if (!parseOK) {
                    this.tabErreurs[0] = "Attention, chaque ligne de votre fichier CSV doit comporter "+nbChamps+" colonnes séparées par des TABULATIONS, la première ligne étant la suivante :";
                    this.tabErreurs[1] = "\"ufCode	ufLibelle	ufCodeLande	ufCommentaire	parcelleLibelle	parcelleCodePostal	parcelleCodeCommune	parcelleSectionCadastre	parcelleNumCadastre	parcelleSousParcelle	parcelleSurfaceCadastre	peuplementDateInstallation	peuplementCodeCulture	peuplementCodeEssence	peuplementSurfaceBoisee	peuplementDensiteInstallation\"";
                    this.erreurs = true;
                    return;
                }
            }

            // on traite UF par UF
            var codeUfpred = lines[0].ufCode;
            var tabUf = [];
            var tabCodes = [];
            tabCodes[lines[0].ufCode] = true;
            var ordreCodeOk = true;
            tabUf.push(lines[0]);
            for (var i=1; i<lines.length; i++) {
                if (codeUfpred != lines[i].ufCode) {
                    if (tabCodes[lines[i].ufCode]) {
                        this.tabErreurs[0] = "Attention, les enregistrements concernant une même Unité Forestière doivent impérativement se suivrent : "+lines[i].ufCode+".";
                        this.erreurs = true;
                        return;
                    }
                    tabCodes[lines[i].ufCode] = true;
                    this.preparerUF(tabUf); // une UF à la fois
                    tabUf = [];
                    codeUfpred = lines[i].ufCode;
                }
                tabUf.push(lines[i]);
            }
            this.preparerUF(tabUf); // une UF à la fois : la dernière
            if (this.tabUFs.length >= 1000) {
                this.tabErreurs[0] = "Attention, pour le moment STERES limite le nombre d'UF à 1000 par propriété. Merci de patienter !";
                this.erreurs = true;
            }
            if (!this.erreurs) {
                this.traiterUFsOK(); // si tout OK on écrit en base - sinon on donne les erreurs
            }
//alert(lines);
        },
        // création de l'objet UF à partir de toutes les lignes d'une même UF
        preparerUF(tabUf) {
            var uf = null;
            for (var i=0; i<tabUf.length; i++) {
                uf = this.traiterLigne(tabUf[i], uf, ((i+1 === tabUf.length)?1:0));
            }
            this.tabUFs.push(uf);
        },
        traiterLigne(ligneObj, uf, derniereLigneUF) {
            if (!uf) {
                uf = this.nouvelleUF(ligneObj);
                // peuplement en attente : sur la première ligne de l'UF
            }
            else {
                this.testerAvertissementUF(ligneObj);
            }
            var parcelle = this.nouvelleParcelle(ligneObj);
            uf.surface = this.additionnerFloat(uf.surface, parcelle.surface);
            uf.tabParcelles.push(parcelle);
            if (derniereLigneUF) {
                uf.peuplement = this.nouveauPeuplement(ligneObj, uf);
            }
            else {
                this.testerAvertissementPeuplement(ligneObj);
            }
            return(uf);
        },
        additionnerFloat(a, b) {
            var fca = this.facteurDeCorrection(a);
            var fcb = this.facteurDeCorrection(b);
            var fc = Math.max(fca, fcb);
            return((a*fc + b*fc)/fc)
        },
        facteurDeCorrection(x) {
            var fc = 1;
            while (x*fc != Math.trunc(x*fc)) {
                fc *= 10;
            }
            return(fc);
        },
        testerAvertissementUF(ligneObj) {
            if (ligneObj.ufLibelle !== "") {
                this.avertissementUF = true;
            }
            if (ligneObj.ufCodeLande !== "") {
                this.avertissementUF = true;
            }
            if (ligneObj.ufCommentaire !== "") {
                this.avertissementUF = true;
            }
        },
        validerUF(ligneObj) {
            if (ligneObj.ufCode == "") {
                this.tabErreurs.push("Les codes des Unités Forestières doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (ligneObj.ufCode.length > 20) {
                    this.tabErreurs.push("Les codes des Unités Forestières ne doivent pas dépasser 20 caractères : "+ligneObj.ufCode+".");
                    this.erreurs = true;
                }
            }
            if (ligneObj.ufLibelle == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les libellés des Unités Forestières doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (ligneObj.ufLibelle.length > 50) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les libellés des Unités Forestières ne doivent pas dépasser 50 caractères : "+ligneObj.ufLibelle+".");
                    this.erreurs = true;
                }
            }
            if (ligneObj.ufCodeLande == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes de types de lande doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (this.terroirId(ligneObj.ufCodeLande) == 0) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Le code du type de lande est inconnu : "+ligneObj.ufCodeLande+".");
                    this.erreurs = true;
                }
            }
        },
        nouvelleUF(ligneObj) {
            this.validerUF(ligneObj); // on valide, on ne bloque pas - on affichera le maximum d'erreurs ...
            // ufCode	ufLibelle	ufCodeLande	ufCommentaire
            var lUf = {
                "userId":this.$store.getters.utilisateurCourantId,
                "id":0,
                "code":ligneObj.ufCode,
                "identifiant":ligneObj.ufLibelle,
                "proprieteId":this.$store.getters.proprieteId,
                "terroirId":this.terroirId(ligneObj.ufCodeLande),
                "surface":0,
                "commentaire":ligneObj.ufCommentaire,
                "version":0,
				"idLocal":false,
                tabParcelles:[]
            };
            return(lUf);
        },
        isNumericPlus(chaine) {
            if (chaine[0] == '0') {
                chaine = chaine.substring(1);
            }
            var intChaine = parseInt(chaine);
            var chaineInt = ""+intChaine;
            if (chaineInt == chaine) {
                return(true);
            }
            return(false);
        },
        isNumeric(chaine) {
            var intChaine = parseInt(chaine);
            var chaineInt = ""+intChaine;
            if (chaineInt == chaine) {
                return(true);
            }
            return(false);
        },
        isFloat(chaine) {
            var maChaine = chaine.replace(',', '.');
            if (maChaine.indexOf(".") >= 0) { // si 0 terminal après la virgule
                while (maChaine.lastIndexOf("0") == maChaine.length-1) {
                    maChaine = maChaine.substring(0, maChaine.length-2);
                }
                if (maChaine.lastIndexOf(".") == maChaine.length-1) {
                    maChaine = maChaine.substring(0, maChaine.length-2);
                }
            }
            var floatChaine = parseFloat(maChaine);
            var chaineFloat = ""+floatChaine;
            if (chaineFloat == maChaine) {
                return(true);
            }
            return(false);
        },
        monParseFloat(chaine) {
            var maChaine = chaine.replace(',', '.');
            return(parseFloat(maChaine));
        },
        validerParcelle(ligneObj) {
            if (ligneObj.parcelleLibelle == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les libellés - ou lieux-dits - des parcelles doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (ligneObj.parcelleLibelle.length > 50) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les libellés des parcelles ne doivent pas dépasser 50 caractères : "+ligneObj.parcelleLibelle+".");
                    this.erreurs = true;
                }
            }
            if (ligneObj.parcelleCodePostal == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes postaux des parcelles doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (ligneObj.parcelleCodePostal.length != 5) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes postaux des parcelles doivent comporter 5 caractères : "+ligneObj.parcelleCodePostal+".");
                    this.erreurs = true;
                }
                if (!this.isNumeric(ligneObj.parcelleCodePostal)) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes postaux ne doivent contenir que des chiffres : "+ligneObj.parcelleCodePostal+".");
                    this.erreurs = true;

                }
            }
            if (ligneObj.parcelleCodeCommune == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes communes des parcelles doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (ligneObj.parcelleCodeCommune.length != 5) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes communes des parcelles doivent comporter 5 caractères : "+ligneObj.parcelleCodeCommune+".");
                    this.erreurs = true;
                }
                if (!this.isNumeric(ligneObj.parcelleCodeCommune)) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes communes ne doivent contenir que des chiffres : "+ligneObj.parcelleCodeCommune+".");
                    this.erreurs = true;
                }
                if (this.tabCommunes && this.tabCommunes.length) {
                    if (typeof this.tabCommunes[ligneObj.parcelleCodeCommune] === "undefined") {
                        this.tabErreurs.push(ligneObj.ufCode+" : "+ "Le code commune est inconnu : "+ligneObj.parcelleCodeCommune+".");
                        this.erreurs = true;
                    }
                    else {
                        var codePostal = this.tabCommunes[ligneObj.parcelleCodeCommune].codePostal;
                        if (codePostal != ligneObj.parcelleCodePostal) {
                            this.tabErreurs.push(ligneObj.ufCode+" : "+ "Le code postal saisi "+ligneObj.parcelleCodePostal+" ne correspond pas à la commune : " +ligneObj.parcelleCodeCommune+" - "+ligneObj.libelleCommune+" : "+codePostal+".");
                            this.erreurs = true;
                        }
                    }
                }
            }
            if (ligneObj.parcelleSectionCadastre.length > 2) {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "La section cadastrale ne doit pas dépasser 2 caractères : "+ligneObj.parcelleSectionCadastre+".");
                this.erreurs = true;
            }
            if (ligneObj.parcelleNumCadastre.length > 5) {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Le numéro cadastral ne doit pas dépasser 4 caractères : "+ligneObj.parcelleNumCadastre+".");
                this.erreurs = true;
            }
            if (ligneObj.parcelleSurfaceCadastre == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les surfaces cadastrales doivent impérativement être remplies");
                this.erreurs = true;
            }
            else {
                if ((!this.isFloat(ligneObj.parcelleSurfaceCadastre)) || (this.monParseFloat(ligneObj.parcelleSurfaceCadastre) <= 0)) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les surfaces cadastrales doivent être non nulles : "+ligneObj.parcelleSurfaceCadastre+".");
                    this.erreurs = true;
                }
            }

        },
        nouvelleParcelle(ligneObj) {
            this.validerParcelle(ligneObj); // on valide, on ne bloque pas - on affichera le maximum d'erreurs ...
            // parcelleCode	parcelleLibelle	parcelleCodePostal	parcelleCodeCommune	parcelleSectionCadastre	parcelleNumCadastre	parcelleSousParcelle	parcelleSurfaceCadastre
            var libelleCommune = "";
            if (this.tabCommunes && this.tabCommunes.length && (typeof this.tabCommunes[ligneObj.parcelleCodeCommune] !== "undefined") ) {
                libelleCommune = this.tabCommunes[ligneObj.parcelleCodeCommune].libelle;
            }
            var laParcelle = {
                "id":0,
                "ufId":0,
                //"code":ligneObj.parcelleCode,
                "identifiant":ligneObj.parcelleLibelle,
                "codePostal":ligneObj.parcelleCodePostal,
                "codeCommune":ligneObj.parcelleCodeCommune,
                "libelleCommune":libelleCommune,
                "sectionCadastrale":ligneObj.parcelleSectionCadastre,
                "numeroCadastral":ligneObj.parcelleNumCadastre,
                "sousParcelle":ligneObj.parcelleSousParcelle,
                "surface":this.monParseFloat(ligneObj.parcelleSurfaceCadastre),
                "version":0};
            return(laParcelle);
        },
        testerAvertissementPeuplement(ligneObj) {
            if (ligneObj.peuplementDateInstallation !== "") {
                this.avertissementPeuplement = true;
            }
            if (ligneObj.peuplementCodeCulture !== "") {
                this.avertissementPeuplement = true;
            }
            if (ligneObj.peuplementCodeEssence !== "") {
                this.avertissementPeuplement = true;
            }
            if (ligneObj.peuplementSurfaceBoisee !== "") {
                this.avertissementPeuplement = true;
            }
            if (ligneObj.peuplementDensiteInstallation !== "") {
                this.avertissementPeuplement = true;
            }
            // peuplementDateInstallation	peuplementCodeCulture	peuplementCodeEssence	peuplementSurfaceBoisee	peuplementDensiteInstallation
        },
        validerPeuplement(ligneObj, uf) {
            if (ligneObj.peuplementDateInstallation == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les dates d'installation des peuplements doivent impérativement être remplies");
                this.erreurs = true;
            }
            else {
                if (ligneObj.peuplementDateInstallation.length != 7) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les dates d'installation des peuplements doivent être au format \"AAAA-MM\": "+ligneObj.peuplementDateInstallation+".");
                    this.erreurs = true;
                }
                else {
                    var lAnnee = ligneObj.peuplementDateInstallation.substring(0, 4);
                    var leMois = ligneObj.peuplementDateInstallation.substring(5, 7);
                    var intLeMois = parseInt(leMois);
                    if ( (!this.isNumeric(lAnnee)) ||  (!this.isNumericPlus(leMois)) ||  (intLeMois < 1) ||  (intLeMois > 12) ) {
                        this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les dates d'installation des peuplements doivent être au format \"AAAA-MM\", exemple \"2001-01\" : "+ligneObj.peuplementDateInstallation+".");
                        this.erreurs = true;
                    }
                    var ladateAujourdhui=new Date();
                    var anneeAujourdhui=ladateAujourdhui.getFullYear();
                    var anneeCompare = parseInt(lAnnee);
                    var moisBascule = this.$store.getters.constantes.steres.moisBascule;
                    if (anneeAujourdhui < anneeCompare)  {
                        this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les années d'installation des peuplements ne peuvent pas dépasser l'année courante : "+ligneObj.peuplementDateInstallation+" > "+anneeAujourdhui+".");
                        this.erreurs = true;
                    }
                    else {
                        var anneeDemain = anneeAujourdhui+1;
                        if ((anneeAujourdhui == anneeCompare) && (intLeMois >= moisBascule)) {
                            this.tabErreurs.push(ligneObj.ufCode+" : "+ "L'année retenue pour l'installation d'un peuplement ne peut pas dépasser l'année courante : "+ligneObj.peuplementDateInstallation+" = année retenue "+anneeDemain+".");
                            this.erreurs = true;
                        }
                    }
                }
            }
            if (ligneObj.peuplementCodeCulture == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes de types de culture doivent impérativement être remplis");
                this.erreurs = true;
            }
            else {
                if (this.cultureId(ligneObj.peuplementCodeCulture) == 0) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Le code du type de culture est inconnu : "+ligneObj.peuplementCodeCulture+".");
                    this.erreurs = true;
                }
            }
            if (ligneObj.peuplementCodeEssence == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les codes de types d'essence doivent impérativement être remplis");
                this.erreurs = true;
            }
            if (ligneObj.peuplementSurfaceBoisee == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les surfaces des peuplements doivent impérativement être remplies");
                this.erreurs = true;
            }
            else {
                if (!this.isFloat(ligneObj.peuplementSurfaceBoisee)) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les surfaces des peuplements ne doivent contenir que des chiffres : "+ligneObj.peuplementSurfaceBoisee+".");
                    this.erreurs = true;
                }
                else {
                    var surfaceBoisee = this.monParseFloat(ligneObj.peuplementSurfaceBoisee);
                    if (surfaceBoisee <= 0) {
                        this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les surfaces des peuplements ne peuvent pas être nulles : "+ligneObj.peuplementSurfaceBoisee+".");
                        this.erreurs = true;
                    }
                    if (surfaceBoisee > uf.surface) {
                        this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les surfaces des peuplements ne peuvent pas dépasser celles des UF : "+ligneObj.peuplementSurfaceBoisee+" > "+uf.surface+".");
                        this.erreurs = true;
                    }
                }
            }
            if (ligneObj.peuplementDensiteInstallation == "") {
                this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les densités d'installation doivent impérativement être remplies");
                this.erreurs = true;
            }
            else {
                if (!this.isNumeric(ligneObj.peuplementDensiteInstallation)) {
                    this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les densités d'installation ne doivent contenir que des chiffres : "+ligneObj.peuplementDensiteInstallation+".");
                    this.erreurs = true;
                }
                else {
                    var densiteInstallation = parseInt(ligneObj.peuplementDensiteInstallation);
                    if ((densiteInstallation < 50) || (densiteInstallation > 5000)) {
                        this.tabErreurs.push(ligneObj.ufCode+" : "+ "Les densités d'installation ne doivent être comprises entre 50 Tiges/ha, > 5000 Tiges/ha : "+ligneObj.peuplementDensiteInstallation+".");
                        this.erreurs = true;
                    }
                }
            }
            // peuplementDateInstallation	peuplementCodeCulture	peuplementCodeEssence	peuplementSurfaceBoisee	peuplementDensiteInstallation
        },
        nouveauPeuplement(ligneObj, uf) {
            this.validerPeuplement(ligneObj, uf); // on valide, on ne bloque pas - on affichera le maximum d'erreurs ...
            // peuplementDateInstallation	peuplementCodeCulture	peuplementCodeEssence	peuplementSurfaceBoisee	peuplementDensiteInstallation
            var cultureId = this.cultureId(ligneObj.peuplementCodeCulture);
            var essenceId = this.essenceId(ligneObj.peuplementCodeEssence);
            var lePeuplement = {
                "userId":this.$store.getters.utilisateurCourantId,
                "id":0,
                "ufId":0,
                "dateInstallation":ligneObj.peuplementDateInstallation,
                "cultureId":cultureId,
                "essenceId":essenceId,
                "surfaceBoisee":this.monParseFloat(ligneObj.peuplementSurfaceBoisee),
                "densiteInstallation":parseInt(ligneObj.peuplementDensiteInstallation),
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "travauxJson":ApiUser.initialiserTravaux(this.$store.getters.constantes),
                "eclaircieDensiteMin":this.$store.getters.constantes.params.const_essence.eclaircieDensiteMin,
                "etatSteres":0,
                "etatUtilisateur":0,
                "version":0,
				"idLocal":false};
            return(lePeuplement);
        },
        cultureId(code) {
            var laCulture = this.$store.getters.constantes.params.const_cultures;
            var item = ApiUser.trouverItemTabConst(laCulture, 0, code);
            if (item) {
                return(item.id);
            }
            return(0);
            /*
            if (typeof laCulture[code] === "undefined") {
                return(0);
            }
            return(laCulture[code].id);
            */
        },
        terroirId(code) {
            var leTerroir = this.$store.getters.constantes.params.const_terroirs;
            var item = ApiUser.trouverItemTabConst(leTerroir, 0, code);
            if (item) {
                return(item.id);
            }
            return(0);
            /*
            if (typeof leTerroir[code] === "undefined") {
                return(0);
            }
            return(leTerroir[code].id);
            */
        },
        essenceId(code) {
            // une seule essence à ce jour
            /*
            var lEssence = this.$store.getters.constantes.params.const_essences;
            return(lEssence[code].id);
            */
            var lEssence = this.$store.getters.constantes.params.const_essences;
            return(lEssence[0].id);
        },
                // si plusieurs fois les même libellés : confirmation
        traiterUFsOK() {
            var trouve = false;
            var trouve2 = false;
            var thisObj = this;
            this.tabUFs.forEach((uf, index) => {
                thisObj.tabUFs.forEach((ufCompare, indexCompare) => {
                    if ((indexCompare > index) &&(!ufCompare.identifiant.localeCompare(uf.identifiant, 'fr', {sensitivity: "base"})) ) {
                        if (ufCompare.code != uf.code) {
                            trouve = true;
                            this.tabDuplis.push(uf.identifiant);
                        }
                    }
                })
                if (this.testerUFexistante(uf.code)) {
                    trouve2 = true;
                }
            })
            if (trouve) {
                this.confirmDialog = true;
            }
            else {
                if (trouve2) {
                    this.confirmDialog2 = true;
                }
                else {
                    this.traiterUFs();
                }
            }
        },

                // création de l'objet UF à partir de toutes les lignes d'une même UF
        continuerTraiterUFs() {
            this.confirmDialog = false;
            if (this.tabDuplisExistant.length) {
                this.confirmDialog2 = true;
            }
            else {
                this.traiterUFs();
            }
        },

                // création de l'objet UF à partir de toutes les lignes d'une même UF
        traiterUFs() {
            this.confirmDialog = false;
            this.confirmDialog2 = false;
            this.$parent.importationEnCours = true;
            var thisObj  = this;
            var nbUFsTraitables = 0;
            var nbUFsTraitees = 0;
            this.tabUFs.forEach(uf => {
            // ecriture de l'UF
                return ApiUser.setUF(uf) // maj de l'UF et de toutes ses parcelles (les anciennes parcelles sont détruites)
                .then(responseUfId => {
                    var ufId = responseUfId;
                        // a-t-on déjà un peuplement ?
                    return ApiUser.lirePeuplement(0, thisObj.$store.getters.constantes, ufId).then(response => {
                        if ((typeof response !== "undefined") && (typeof response.peuplement !== "undefined")) {
                            var peuplement = response.peuplement;
                            if (uf.peuplement == null) {
                                console.log('traiterUFs : uf.peuplement == null')
                            }
                            else {
                                // on met à jour uniquement ce qui doit l'être
                                peuplement.dateInstallation = uf.peuplement.dateInstallation;
                                peuplement.cultureId = uf.peuplement.cultureId;
                                peuplement.essenceId = uf.peuplement.essenceId;
                                peuplement.surfaceBoisee = uf.peuplement.surfaceBoisee;
                                peuplement.densiteInstallation = uf.peuplement.densiteInstallation;
                                peuplement.densiteMethodeId = 0;
                                peuplement.densiteJson = "{}";
                                if ( ((typeof peuplement.mesures !== "undefined") && (peuplement.mesures.length))
                                    || ((typeof peuplement.eclaircies !== "undefined") && (peuplement.eclaircies.length)) ) {
                                    thisObj.avertissementCoherencePeuplement=true;
                                }
                            }
                        }
                        else {
                            var peuplement = uf.peuplement;
                            if (peuplement) {
                                peuplement.ufId = ufId;
                            }
                            else {
                                console.log('traiterUFs : bug ')
                            }
                        }
                        if (peuplement) {
                            nbUFsTraitables++;
                        }
                        return ApiUser.setPeuplement(peuplement, thisObj.$store.getters.constantes) // et on enregistre
                        .then(responsePeuplement => {
                            var toto = responsePeuplement; // pour validation
                            thisObj.nbUImportees++;
                            if (thisObj.nbUImportees == this.tabUFs.length) { // on ne sait pas à quelle itération on arrive ici, mais c'est la dernière qui compte !
                                thisObj.message = "L'importation des Unités Forestières s'est déroulée correctement, vous pouvez désormais les consulter ou les modifier.";
                                thisObj.$parent.importationEnCours = false;
                                thisObj.dialog = true;
                            }
//                            else  {
//                                this.message = "Problème sur l'importation des Unités Forestières.";
//                            }
                        })
                    })
                })
            })
        },
                // on va au bon endroit avec les bonnes valeurs
        terminerEdition() {
            this.dialog = false;
            if (this.message) {
                this.$store.commit('donnerMessage', this.message);
            }
            this.$router.push({ name: "propriete" });
        },

        abandonnerTraiterUFs() {
            this.tabErreurs.push("Importation abandonnée par l'utilisateur.");
            this.erreurs = true;
            this.confirmDialog = false;
            this.confirmDialog2 = false;
        },

        testerUFexistante(codeUF) {
            var trouve = false;
            this.UFsExistant.forEach(uf => {
                if (!codeUF.localeCompare(uf.code, 'fr', {sensitivity: "base"})) {
                    this.tabDuplisExistant.push(uf.code);
                    trouve = true;
                }
            })
            return(trouve);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #app {
        #erreursContainer {
            text-align: center;
        }
        #erreurs {
            background-color: rgba(255, 151, 0, 0.4);
            border: 1px solid #cccccc;
            width:100%;
            /*max-width: 800px;*/
            margin: 2em 0;
            padding:2em 1em 1em 2em;
            text-align: left;
            font-size:0.9em;
            font-style: italic;

            li {
                margin-bottom:1em;
            }
        }

          .dropbox {
            outline: 2px dashed grey; /* the dash box */
            outline-offset: -10px;
            background: white;
            color: dimgray;
            padding: 10px 10px;
            min-height: 200px; /* minimum height */
            position: relative;
            cursor: pointer;
          }

          .input-file {
            opacity: 0; /* invisible but it's there! */
            width: 100%;
            height: 200px;
            position: absolute;
            cursor: pointer;
          }

          .dropbox:hover {
            background: lightblue; /* when mouse over to the drop zone, change color */
          }

          .dropbox p {
            font-size: 1.2em;
            text-align: center;
            padding: 50px 0;
          }
        .output {
            font-style: italic;
            font-size:0.8em;
            color:#223938;
        }
        .exemples {
            padding-top:3em;

            span, img {
                cursor:pointer;
            }
            span {
                color: #238b71;
                font-weight: bold;
            }
        }

    }
</style>



