<template>
 <div>
    <form>
        <v-text-field
            label="Nom de l'unité forestière *"
            v-model="form.identifiant"
            required
            :error-messages="error.identifiant"
            ref="identifiant"
        ></v-text-field>

        <h2 class="titreListe" >Type de lande *</h2>
<!--
        <p class="info">Choisir entre : Lande sèche (bruyère, hélianthème...), Lande mésophile (fougère...), Lande humide à alios (molinie, brande...), Lande humide sans alios, (Molinie, Bourdaine), Ancien champs (graminées, ronces...)</p>
-->
        <v-select
            v-model="form.terroirId"
            item-text="libelle"
            item-value="id"
            :items="lesItemsTerroir"
            label="Type de lande *"
            :error-messages="error.terroir"
            required
            minValue=1
        ></v-select>

        <span class="infoLecture">Surface : {{arrondiHectares(surfaceTotale)}} hectare(s)</span>

        <div id="parcelles">
        <h2 class="titreListe" >Parcelles cadastrales *</h2>

            <div class="itemsList">
                <div class="itemListEdit" v-bind:class="{itemEdit:laParcelleCourante==numParcelle, itemList:laParcelleCourante!=numParcelle}"  v-for="(laParcelle, numParcelle) in  lesParcelles" :key="numParcelle" >
                    <span class="groupList" v-on:click="reinitParcelleCourante(numParcelle)">
                        {{ laParcelle.libelleCommune }} - {{ laParcelle.identifiant }} <span  class="surface">-> {{ arrondiHectares(laParcelle.surface) }} ha</span>
                        <span  class="cadastre" v-if="laParcelle.sectionCadastrale"><br/>Réf. cadastrale : {{ laParcelle.sectionCadastrale }} - {{ laParcelle.numeroCadastral }}  </span>
                    </span>

                    <!-- <img class="iconeFct" alt="Supprimer parcelle" src="@/assets/img/poubelle.svg" @click.stop="renseignerParcellePoubelle(laParcelle, numParcelle)" /> -->
                    <span class="iconeFct svg"  @click.stop="renseignerParcellePoubelle(laParcelle, numParcelle)" ><i class="fa fa-trash svg" aria-hidden="true"></i></span>

                    <span class="groupEdit">
                        <v-text-field
                            label="Lieu dit / Dénomination *"
                            v-model="laParcelle.identifiant"
                            ref="numParcelle"
                            required
                        ></v-text-field>

                        <a-autocomplete-villes
                           @selectVille="onSelectVille"
                            :initItem="codePostal_ville[numParcelle]"
                            :clearable="true"
                            @click:clear="clickClear"
                            required
                        />
                        <div class="blocIntermediare">
                            <span>Informations cadastrales</span>
                            <v-text-field
                                label="Section"
                                v-model="laParcelle.sectionCadastrale"
                                class="aCompleter"
                                maxLength="3"
                            ></v-text-field>
                            <v-text-field
                                label="Numéro"
                                v-model="laParcelle.numeroCadastral"
                                class="complement"
                                maxLength="5"
                                type="number"
                                @keydown="filtrerNombresEntiersPositifs"
                                min=1
                                max=9999
                            ></v-text-field>
                            <v-text-field
                                label="Sous-parcelle"
                                v-model="laParcelle.sousParcelle"
                            ></v-text-field>
                            <v-text-field
                                label="Surface (en hectares) *"
                                v-model="laParcelle.surface"
                                type="number"
                                @keydown="filtrerNombresPositifs"
                                min=1
                                required
                            ></v-text-field>
                        </div>
                    </span>
                </div>
            </div>

            <a class="" @click="ajouterParcelleVide">+ Ajouter une parcelle</a>
        </div>

        <h2 class="titreListe" >Commentaire</h2>
        <v-textarea
            label="Commentaire"
            v-model="form.commentaire"
            :error-messages="error.commentaire"
        ></v-textarea>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->
        <div class="boutonsValidation">
            <v-btn class="a-btn secondaire" type="button" @click="cancelForm">Annuler</v-btn>
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Enregistrer</v-btn>
        </div>
    </form>


<v-dialog v-model="dialog" v-if="laParcelleModale" >
      <v-card>
        <v-card-title class="headline">Suppression</v-card-title>

        <v-card-text>
          Voulez-vous vraiment détruire la parcelle {{ laParcelleModale.identifiant }} ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color=" " text @click="dialog = false">Non</v-btn>
          <v-btn color=" " text @click="detruireParcelle(laParcelleModale)">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogErrorParcelle" v-if="errorParcelle" >
      <v-card>
        <v-card-title class="headline">Attention</v-card-title>

        <v-card-text>
          {{ errorParcelle }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="fermerDialogErrorParcelle()">OK</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialog" v-if="memeLibelle" >
      <v-card>
        <v-card-title class="headline">Demande de confirmation</v-card-title>

        <v-card-text>
          Il existe déjà une Unité Forestière avec ce nom : "{{ form.identifiant }}", voulez-vous quand même la créer ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color=" " text @click="dialog = false;memeLibelle = false;">Non</v-btn>
          <v-btn color=" " text @click="finaliserSaveForm();">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>


</div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, maxLength, email, minValue } from 'vuelidate/lib/validators'

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
import AddeoAutocompleteVilles from '../ui/autocomplete/AutocompleteVilles.vue'

export default {
    components: {
        'a-autocomplete-villes': AddeoAutocompleteVilles
    },

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            dialog: false,
            dialogErrorParcelle:false,
            errorParcelle:false,
            memeLibelle:false,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.data)),
            old: null,
            error: {
                identifiant: '',
                commentaire: '',
                terroir: '',
            },
            itemsTerroir: [],
            parcelleCourante:-1,
            codePostal_ville: [],
            laParcelleModale:null,
            clear:{
                    id: '',
                    label: ''
                },
            wait:{
                    id: '',
                    label: 'Please wait'
                },
            UFsExistant:null
        }
    },

    created() {
        // on est obligé de lire l'ensemble des UFs uniquement pour le test sur les nouveaux libellés : testerNouveauLibelle (du coup on n'attends pas le chargement)
        ApiUser.lirePropriete(this.$store.getters.proprieteId, this.$store.getters.constantes).then(response => {
            this.UFsExistant  = response.UFs; // pour être sûr d'être à jour !  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState
        }) // on n'attends pas car l'utilisateur est forcément plus lent que la machine

        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        //this.old = ApiTools.clone(this.form);
        var laParcelleCourante = this.laParcelleCourante; // si on rentre dans l'écran avec une seule parcelle, elle est OBLIGATOIREMENT la courante
        this.initParcelleCourante();
        this.initItemsTerroir();
    },

    mounted() {
        this.$refs.identifiant.focus();
    },

    validations() {
        return {
            form: {
                identifiant: { required, maxLength: maxLength(50) },
                commentaire: {  maxLength: maxLength(250) },
                terroirId: { required, minValue:minValue(1) },
            }
        }
    },

    computed: {
        // si pas de parcelle
        laParcelleCourante() {
            if (this.form.tabParcelles.length == 1) {
                this.parcelleCourante = 0;
            }
            return(this.parcelleCourante);
        },
        // pour affichage "à jour" de la liste
        lesParcelles() {
            return(this.form.tabParcelles);
        },
        // calcul de la somme des surfaces des parcelles
        surfaceTotale() {
            var laSurface = 0.0;
            this.form.tabParcelles.forEach(parcelle => {
                var surface = parseFloat(parcelle.surface)
                if (isNaN(surface)) {
                    surface = 0;
                }
                laSurface += surface;
                laSurface = this.arrondir(laSurface);  // bug javascript 0.4+0.2 = 0.600000000001 :-/

            })
            return laSurface;
        },
        // pour le combo "type de lande"
        lesItemsTerroir() {
            return(this.itemsTerroir);
        }
    },

    methods: {
        arrondir(valeur, puissance = 5) {
            var puissanceDe10 = Math.pow(10, puissance);
            var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
            return(arrondi);
        },
        // contenu du combo type de lande
        initItemsTerroir() {
            var constantes = this.$store.getters.constantes;
            this.itemsTerroir = constantes.params.const_terroirs; // attention on a des datas 0,n mais aussi associatives (par code) : ça ne semble pas géner vue
        },
        // gestion des parcelles
        reinitialiserObjetTabParcelles() {
            var tab = [];
            this.form.tabParcelles = tab.concat(this.form.tabParcelles);
        },
        ajouterParcelleVide() {
            var tabParcelles = this.form.tabParcelles;
            var nouvelleParcelle = {};
            var numParcelle = tabParcelles.length;
            /*
            var nomTemporaire = "Parcelle" + numParcelle;
            this.reinitParcelle(nouvelleParcelle, nomTemporaire);
            */
            this.reinitParcelle(nouvelleParcelle);
            tabParcelles[tabParcelles.length] = nouvelleParcelle;
            // pour forcer le reaffichage
            this.reinitialiserObjetTabParcelles();
            //this.form.tabParcelles = this.form.tabParcelles.concat([]);
            this.reinitParcelleCourante(numParcelle)
            // on donne le focus quand on est prêt
            this.$nextTick(() => {
                if (numParcelle> 0 ) {
                    this.$refs.numParcelle[numParcelle].focus();
                }
            })
        },
        reinitParcelle(parcelle, identifiant="") {
            parcelle.id = 0;
            parcelle.ufId = this.form.id;
            parcelle.identifiant =identifiant;
            parcelle.codePostal = "";
            parcelle.codeCommune = "";
            parcelle.libelleCommune = "";
            parcelle.sectionCadastrale = "";
            parcelle.numeroCadastral = "";
            parcelle.sousParcelle = "";
            parcelle.surface = "";
            parcelle.version = 0;
        },



        initParcelleCourante() {
            var codePostal = "";
            var libelle = "";
            if (typeof(this.form.tabParcelles[this.parcelleCourante]) !== 'undefined') {
                codePostal = this.form.tabParcelles[this.parcelleCourante].codePostal;
                libelle = this.form.tabParcelles[this.parcelleCourante].libelleCommune;
            }

            this.codePostal_ville[this.parcelleCourante] = JSON.stringify({
                id: '',
                label: codePostal + ' ' + libelle,
                codePostal:codePostal,
                libelle:libelle,
                })
            if (ApiTools.isStringEmpty(codePostal) || ApiTools.isStringEmpty(libelle)) {
                return(0);
            }
            return(1);
        },
        reinitParcelleCourante(numParcelle) {
            if (this.parcelleCourante >= 0) {
                var parcelleValidable = this.form.tabParcelles[this.parcelleCourante];
                if (parcelleValidable.identifiant == "") {
                    this.dialogErrorParcelle = true;
                    this.errorParcelle = "Attention, vous ne pouvez pas laisser l'identifiant de la parcelle vide. Si vous souhaitez supprimer cette parcelle, utilisez l'icône \"Poubelle\"";
                    return;
                }
            }
            if (this.parcelleCourante == numParcelle) { // on referme et c'est tout
                this.parcelleCourante = -1;
                return;
            }
            this.parcelleCourante = numParcelle;
            this.initParcelleCourante();
        },
        // destruction des parcelles
        renseignerParcellePoubelle(theParcelle, numParcelle) {
            this.dialog = true;
            this.laParcelleModale=theParcelle;
            this.laParcelleModale.numParcelle = numParcelle;
        },
        detruireParcelle(theParcelle) {
            this.dialog = false;
            // on ne reste pas sur la parcelle qu'on supprime
            if (this.parcelleCourante == theParcelle.numParcelle) {
                this.parcelleCourante = 0 ;
            }
            if (this.form.tabParcelles.length > 1) { // si on peut on supprime
                console.log("detruireParcelle : ", theParcelle.numParcelle)
                this.form.tabParcelles.splice(theParcelle.numParcelle, 1);
                // pour forcer le reaffichage
                this.form.tabParcelles = this.form.tabParcelles.concat([]);
            }
            else { // pour la dernière - on efface !
                this.reinitParcelle(this.form.tabParcelles[0]);
                    console.log("detruireParcelle")
                // en attente
            }
            this.initParcelleCourante();
        },

        // gestion de l'autocomplete
        onSelectVille(villeObj) {
            this.codePostal_ville[this.parcelleCourante] = JSON.stringify(villeObj.codePostal_ville)
            this.form.tabParcelles[this.parcelleCourante].codeCommune = villeObj.codeComm
            this.form.tabParcelles[this.parcelleCourante].codePostal = villeObj.codePostal
            this.form.tabParcelles[this.parcelleCourante].libelleCommune = villeObj.libelle
        },
        clickClear() {
            this.codePostal_ville[this.parcelleCourante] = ''
            this.form.tabParcelles[this.parcelleCourante].codeCommune = ''
            this.form.tabParcelles[this.parcelleCourante].codePostal = ''
            this.form.tabParcelles[this.parcelleCourante].libelleCommune = ''
        },
        majCodePostal_ville(objGlob, codePostal_ville) {
            objGlob.codePostal_ville = codePostal_ville;
        },

        // annulation du formulaire
        cancelForm() {
            // on remet les données anciennes dans 'form'
            this.form = JSON.parse(JSON.stringify(this.old))

            // devient
            // this.initParcelleCourante()
            var savTab =  [];
            savTab = savTab.concat(this.codePostal_ville);
            var tabTmp = [];
            tabTmp[0] = JSON.stringify(this.wait);
            this.codePostal_ville = tabTmp;
            setTimeout(this.majCodePostal_ville.bind(null, this, savTab), 250);

            // on prépare le message passé à la prochaine page
            var message = "La saisie de l'Unité Forestière "+this.form.identifiant+ " a été abandonnée.";
            // on va au bon endroit avec les bonnes valeurs
            this.terminerEdition(message);
        },


        testerNouveauLibelle() {
            var ufTest = this.form; // pour .identifiant et id
            var identifiant = ufTest.identifiant;
            //var UFs = this.$store.getters.infosProprieteUFs;
            var UFs = this.UFsExistant; // pour être sûr d'être à jour !  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState

            var trouve = false;
            UFs.forEach(uf => {
                if (!identifiant.localeCompare(uf.identifiant, 'fr', {sensitivity: "base"})) {
                    if (ufTest.id != uf.id) {
                        trouve = true;
                    }
                }
            })

            if (trouve) {
                this.dialog = true;
                this.memeLibelle = true;
                return(false);
            }
            return(true);
        },

        // enregistrement du formulaire
        saveForm() {

            // on reinit à vide les messages d'erreur
            this.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (this.$v.$invalid) {
                // maj erreurs
                this.identifiantErrors();
                this.commentaireErrors();
                this.terroirErrors();
                return false
            }
            // si les seules erreurs sont sur la liste de parcelle, il faut le dire "à la main" à l'utilisateur
            if (!this.validerParcelles()) {
                return false
            }

            // si pas de modification des données
            if (JSON.stringify(this.old) === JSON.stringify(this.form)) {
                this.terminerEdition();
                return false
            }

            // on met à jour avec les valeurs du STORE (globales)
            this.form.userId = this.$store.getters.utilisateurCourantId
            this.form.proprieteId = this.$store.getters.proprieteId

            // on somme la surface
            this.form.surface = this.surfaceTotale;

            if (this.testerNouveauLibelle()) {
                this.finaliserSaveForm();
            }
/*
            // on sauvegarde (distant et local)
                this.old = JSON.parse(JSON.stringify(this.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
                var entityToSave = JSON.parse(JSON.stringify(this.form))
                console.log("saveForm OK")
                const response = await ApiUser.setUF(entityToSave) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)

                // on prépare le message passé à la prochaine page
                var message = "";
                if (navigator.onLine) {
                    message = "La saisie de l'Unité Forestière "+this.form.identifiant+ " a bien été enregistrée.";
                }
                else {
                    message = "La saisie de l'Unité Forestière "+this.form.identifiant+ " a bien été enregistrée. Attention, vous travaillez hors ligne, il vous faudra donc synchroniser cette saisie ultérieurement avce la base de données Stères.";
                }
                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message);
*/
        },

        async finaliserSaveForm() {

            this.dialog = false;
            this.memeLibelle = false;

            // on sauvegarde (distant et local)
            this.old = JSON.parse(JSON.stringify(this.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
            this.form.modeForet = this.$store.getters.modeForet;
            var entityToSave = JSON.parse(JSON.stringify(this.form))
            console.log("saveForm OK")
            const response = await ApiUser.setUF(entityToSave) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)

            // on prépare le message passé à la prochaine page
            var message = "";
            message = "La saisie de l'Unité Forestière "+this.form.identifiant+ " a bien été enregistrée.";
            /*
            if (navigator.onLine) {
                message = "La saisie de l'Unité Forestière "+this.form.identifiant+ " a bien été enregistrée.";
            }
            else {
                message = "La saisie de l'Unité Forestière "+this.form.identifiant+ " a bien été enregistrée. Attention, vous travaillez hors ligne, il vous faudra donc synchroniser cette saisie ultérieurement avce la base de données Stères.";
            }
            */
            // on va au bon endroit avec les bonnes valeurs
            this.terminerEdition(message);

        },

        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message) {
            if (message) {
                this.$store.commit('donnerMessage', message);
            }
            //this.$store.commit('setUfId', 0); // tmp
            //this.$store.commit('resetDataApplication');
            this.$router.push({ name: "propriete" });
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.identifiant = ''
            this.error.commentaire = ''
            this.error.terroir = ''
        },
        identifiantErrors () {
            if (!this.$v.form.identifiant.$invalid) return false // verifier invalid
            if (!this.$v.form.identifiant.maxLength) {
                this.error.identifiant  = "La taille maximale de l'identifiant est de 50 caractères"
            }
            if (!this.$v.form.identifiant.required) {
                 this.error.identifiant  = "La saisie d'un identifiant est obligatoire"
            }
            return true
        },
        commentaireErrors () {
            if (!this.$v.form.commentaire.$invalid) return false // verifier invalid
            if (!this.$v.form.commentaire.maxLength) {
                this.error.commentaire  = "La taille maximale du commentaire est de 250 caractères"
            }
            return true
        },
        terroirErrors () {
            if (!this.$v.form.terroirId.$invalid) return false // verifier invalid
            if ((!this.$v.form.terroirId.required) || (!this.$v.form.terroirId.minValue)) {
                 this.error.terroir  = "Le choix d'un type de lande est obligatoire"
            }
            return true
        },
        // validation manuelle (pas de vuelidate car on est dans une liste partiellement affichée)
        validerParcelles () {
            var ok = true;
            var tabParcelles = this.form.tabParcelles;
            if (!tabParcelles.length) {
                this.dialogErrorParcelle = true;
                this.errorParcelle = "Attention, votre unité forestière doit au moins être composée d'une parcelle.";
                return false;
            }
            var cpt = 0;
            var numParcelleError = 0;
            tabParcelles.forEach(parcelle => {
                if (parcelle.identifiant == "") {
                    this.dialogErrorParcelle = true;
                    this.errorParcelle = "Vous devez saisir un lieu-dit.";
                    ok = false;
                    numParcelleError = cpt;
                }
                else {
                    if (parcelle.codePostal == "") {
                        this.dialogErrorParcelle = true;
                        this.errorParcelle = "Attention, vous devez associer une commune à votre parcelle.";
                        ok = false;
                        numParcelleError = cpt;
                    }
                    else {
                        if (parcelle.surface === "") {
                            this.dialogErrorParcelle = true;
                            this.errorParcelle = "Attention, vous devez saisir une surface valide pour votre parcelle.";
                            ok = false;
                            numParcelleError = cpt;
                        }
                        if (parcelle.surface === 0) {
                            this.dialogErrorParcelle = true;
                            this.errorParcelle = "Attention, vous devez saisir la surface de votre parcelle.";
                            ok = false;
                            numParcelleError = cpt;
                        }
                    }
                }
                cpt++;
            })
            if (!ok) {
                this.parcelleCourante = -1;
                this.reinitParcelleCourante(numParcelleError)
            }

            if (ok && (typeof(this.form.peuplement) !== 'undefined') && (this.form.peuplement) && (this.form.peuplement.surfaceBoisee)) {
                var surface = this.surfaceTotale;
                if (this.form.peuplement.surfaceBoisee > surface) {
                    this.dialogErrorParcelle = true;
                    this.errorParcelle = "Attention, la surface boisée déjà saisie pour le peuplement ("+this.form.peuplement.surfaceBoisee+" ha) dépasse la nouvelle surface de l'unité forestière. Conservez des valeurs cohérentes.";
                    ok = false;

                }
            }
            return ok;
      },

        fermerDialogErrorParcelle() {
            this.dialogErrorParcelle = false;
            this.$nextTick(() => {
                if (this.parcelleCourante > 0 ) {
                    this.$refs.numParcelle[this.parcelleCourante].focus();
                }
            })
        },
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },
        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #parcelles {
        padding-bottom: 1em;
        border-bottom: 1px solid #CDCDCD;
    }
</style>
