<template>
<div>

    <span id="refermer" @click.stop="$parent.refermerSynthese()">
        <i class="fas fa-window-close-o"></i>
        <div class="clear"></div>
    </span>
 <div id="synthese2">
    <div>
        <div v-if="!$store.getters.modeForet" class="aDroite infoImpression"  @click.stop="$parent.imprimerSynthese('syntheseB')">Vous pouvez imprimer cette synthèse :
            <i class="fas fa-print"></i>
        </div>
        <div id="aImprimer" class="conteneur" v-if="ready">
            <div class="gauche image"><img src="../../assets/img/logoCPFAv.png" /></div>
            <div id="infosGeneriques" class="droite"></div>

            <h2 v-if="!$store.getters.modeForet" @click.stop="exporterTableau(laTabValeursExportees)" class="lien"><i class="fas fa-download"></i>Détail des interventions souhaitables<span v-if="tabUfEclaircies.length">{{laAnnee}}</span></h2>
            <h2 v-if="$store.getters.modeForet">Détail des interventions souhaitables<span v-if="tabUfEclaircies.length">{{laAnnee}}</span></h2>

            <div class="sousTitreSynthese" v-if="tabUfEclaircies.length">Eclaircies prévues<!-- dans l'année--> : {{formaterVolume(infosEclaircies.volume)}} m3 pour {{formaterEuros(infosEclaircies.recette)}} sur {{formaterSurface(infosEclaircies.surface)}} ha</div>
            <table class="infos"  :id="numUF" v-for="(lUF, numUF) in tabUfEclaircies" :key="numUF">
                <tr>
                    <td colspan="4">
                        <b>{{lUF.identifiant}}</b> :
                        {{lUF.peuplement.surfaceBoisee}} ha boisés sur {{lUF.libelleTerroir}}
                    </td>
                </tr>
                <tr v-if="lUF.commentaire">
                    <td >
                        &nbsp;
                    </td>
                    <td colspan="3">
                        {{lUF.commentaire}}
                    </td>
                </tr>
                <tr>

                    <td>
                        &nbsp;
                    </td>
                    <td>
                        {{lUF.peuplement.libelleCulture}} de {{lUF.peuplement.anneeRetenue}}
                    </td>
                    <td>
                        <span v-if="lUF.dendrometrieAnneeRetenue">Age : {{lUF.dendrometrieAnneeRetenue.anneeCourante}} ans</span>
                    </td>
                    <td>
                        <span v-if="lUF.derniereEclaircie && lUF.derniereEclaircie >= 0">Dernière éclaircie à {{lUF.derniereEclaircie}} ans<br/></span>
                        <span v-if="lUF.derniereMesure && lUF.derniereMesure >= 0">Dernière mesure à {{lUF.derniereMesure}} ans</span>
                    </td>


                </tr>
                <tr>
                    <td></td>
                    <td  colspan="3">
                        <span class="" v-if="lUF.dendrometrieAnneeRetenue && lUF.dendrometrieAnneeRetenue.densiteEclaircie">
                            HMAX : {{arrondir(lUF.dendrometrieAnneeRetenue.HMAXPrecedent)}} -  Hauteur : {{formaterHauteur(lUF.dendrometrieAnneeRetenue.hauteurAvantEclaircie)}} m -
                            Circonférence : {{formaterCirconference(lUF.dendrometrieAnneeRetenue.circonferenceAvantEclaircie*100)}} cm<br/>Accroissement en volume : {{formaterVolume((lUF.dendrometrieAnneeRetenue.volumeAvantEclaircie+lUF.dendrometrieAnneeRetenue.cumulVolumeRecolteAvantEclaircie)/lUF.dendrometrieAnneeRetenue.anneeCourante)}} m3/ha/an
                        </span>
                    </td>
                </tr>

                <tr>
                    <td class="aDroite">
                        <span> Recettes attendues :&nbsp;</span>
                    </td>
                    <td colspan="3">
                        {{formaterEuros(lUF.recettesPrevuesAnneeRetenue/lUF.peuplement.surfaceBoisee)}}/ha -
                        {{formaterEuros(lUF.recettesPrevuesAnneeRetenue)}}
                        <span v-if="lUF.dendrometrieAnneeRetenue && !lUF.dendrometrieAnneeRetenue.densiteEclaircie && lUF.dendrometrieAnneeRetenue.densiteApres"> (Dépressage ou informationns incomplètes)</span>
                    </td>
                </tr>

                <tr v-if="lUF.dendrometrieAnneeRetenue && lUF.dendrometrieAnneeRetenue.densiteEclaircie">
                    <td colspan="4">
                    <table class="infosEclaircies">
                        <tr>
                            <th></th>
                            <th>Densité<span class="unites">(tiges/ha)</span></th>
                            <th>Prélèvement<span class="unites">(%)</span></th>
                            <th>Circ.<span class="unites">(cm)</span></th>
                            <th>Vol. unit.<span class="unites">(m3)</span></th>
                            <th>Vol./ha<span class="unites">(m3/ha)</span></th>
                            <th>Vol. tot.<span class="unites">(m3)</span></th>
                        </tr>
                        <tr class="aDroite">
                            <td class="libelle">Avant éclaircie :</td>
                            <td>{{formaterDensiteTab(lUF.dendrometrieAnneeRetenue.densiteAvantEclaircie)}}</td>
                            <td></td>
                            <td>{{formaterCirconferenceTab(lUF.dendrometrieAnneeRetenue.circonferenceAvantEclaircie*100)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeUnitaireAvantEclaircie)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeAvantEclaircie)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeAvantEclaircie*lUF.peuplement.surfaceBoisee)}}</td>
                        </tr>
                        <tr class="aDroite">
                            <td class="libelle">Eclaircie :</td>
                            <td>{{formaterDensiteTab(lUF.dendrometrieAnneeRetenue.densiteEclaircie)}}</td>
                            <td>{{formaterValeur(lUF.dendrometrieAnneeRetenue.densiteEclaircie/lUF.dendrometrieAnneeRetenue.densiteAvantEclaircie*100, 1)}}</td>
                            <td>{{formaterCirconferenceTab(lUF.dendrometrieAnneeRetenue.circonferenceEclaircie*100)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeUnitaireEclaircie)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeRecolteEclaircie)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeRecolteEclaircie*lUF.peuplement.surfaceBoisee)}}</td>
                        </tr>
                        <tr class="aDroite">
                            <td class="libelle">Après éclaircie :</td>
                            <td>{{formaterDensiteTab(lUF.dendrometrieAnneeRetenue.densite)}}</td>
                            <td></td>
                            <td>{{formaterCirconferenceTab(lUF.dendrometrieAnneeRetenue.circonference*100)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeUnitaire)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volume)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volume*lUF.peuplement.surfaceBoisee)}}</td>
                        </tr>
                    </table>
                    </td>
                </tr>

            </table>

            <div class="sousTitreSynthese" v-if="tabUfCoupesRases.length">Coupes rases prévues<!-- dans l'année--> : : {{formaterVolume(infosCoupesRases.volume)}} m3 pour {{formaterEuros(infosCoupesRases.recette)}} sur {{formaterSurface(infosCoupesRases.surface)}} ha</div>
            <table class="infos"  :id="numUF" v-for="(lUF, numUF) in tabUfCoupesRases">
                <tr>
                    <td colspan="4">
                        <b>{{lUF.identifiant}}</b> :
                        {{lUF.peuplement.surfaceBoisee}} ha boisés sur {{lUF.libelleTerroir}}
                    </td>
                </tr>
                <tr v-if="lUF.commentaire">
                    <td >
                        &nbsp;
                    </td>
                    <td colspan="3">
                        {{lUF.commentaire}}
                    </td>
                </tr>
                <tr>

                    <td>
                        &nbsp;
                    </td>
                    <td>
                        {{lUF.peuplement.libelleCulture}} de {{lUF.peuplement.anneeRetenue}}
                    </td>
                    <td>
                        <span v-if="lUF.dendrometrieAnneeRetenue">Age : {{lUF.dendrometrieAnneeRetenue.anneeCourante}} ans</span>
                    </td>
                    <td>
                        <span v-if="lUF.derniereEclaircie && lUF.derniereEclaircie >= 0">Dernière éclaircie à {{lUF.derniereEclaircie}} ans<br/></span>
                        <span v-if="lUF.derniereMesure && lUF.derniereMesure >= 0">Dernière mesure à {{lUF.derniereMesure}} ans</span>
                    </td>


                </tr>

                <tr>
                    <td class="aDroite">
                    </td>
                    <td colspan="3">
                        <span v-if="lUF.dendrometrieAnneeRetenue">
                            HMAX : {{arrondir(lUF.dendrometrieAnneeRetenue.HMAXPrecedent)}} -  Hauteur : {{formaterHauteur(lUF.dendrometrieAnneeRetenue.hauteur)}} m<span v-if="lUF.dendrometrieAnneeRetenue.anneeCourante"> -
                            Circonférence : {{formaterCirconference(lUF.dendrometrieAnneeRetenue.circonference*100)}} cm
                            <br/>Accroissement en volume : {{formaterVolume((lUF.dendrometrieAnneeRetenue.volume+lUF.dendrometrieAnneeRetenue.cumulVolumeRecolteEclaircie)/lUF.dendrometrieAnneeRetenue.anneeCourante)}} m3/ha/an</span>
                        </span>
                    </td>
                </tr>


                <tr>
                    <td class="aDroite">
                        <span > Recettes attendues :&nbsp;</span>
                    </td>
                    <td colspan="3">
                        {{formaterEuros(lUF.recettesPrevuesAnneeRetenue/lUF.peuplement.surfaceBoisee)}}/ha -
                        {{formaterEuros(lUF.recettesPrevuesAnneeRetenue)}}<br/>
                    </td>
                </tr>

                <tr v-if="lUF.dendrometrieAnneeRetenue">
                    <td colspan="4">
                    <table class="infosEclaircies">
                        <tr>
                            <th></th>
                            <th>Densité<span class="unites">(tiges/ha)</span></th>
                            <th>Prélèvement<span class="unites">(%)</span></th>
                            <th>Circ.<span class="unites">(cm)</span></th>
                            <th>Vol. unit.<span class="unites">(m3)</span></th>
                            <th>Vol./ha<span class="unites">(m3/ha)</span></th>
                            <th>Vol. tot.<span class="unites">(m3)</span></th>
                        </tr>
                        <tr class="aDroite">
                            <td class="libelle">Coupe Rase :</td>
                            <td>{{formaterDensiteTab(lUF.dendrometrieAnneeRetenue.densite)}}</td>
                            <td>100</td>
                            <td>{{formaterCirconferenceTab(lUF.dendrometrieAnneeRetenue.circonference*100)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volumeUnitaire)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volume)}}</td>
                            <td>{{formaterVolumeTab(lUF.dendrometrieAnneeRetenue.volume*lUF.peuplement.surfaceBoisee)}}</td>
                        </tr>
                    </table>
                    </td>
                </tr>

            </table>


            <div class="sousTitreSynthese" v-if="tabUfSansPeuplement.length">Parcelles nues disponibles pour le reboisement :</div>
            <table class="infos"  :id="numUF1" v-for="(lUF, numUF1) in tabUfSansPeuplement">
                <tr>
                    <td colspan="4">
                        <b>{{lUF.identifiant}}</b> :
                        {{lUF.surface}} ha sur {{lUF.libelleTerroir}}
                    </td>
                </tr>
                <tr v-if="lUF.commentaire">
                    <td >
                        &nbsp;
                    </td>
                    <td colspan="3">
                        {{lUF.commentaire}}
                    </td>
                </tr>
            </table>

            <div class="sousTitreSynthese" v-if="tabUfSansIntervention.length">Pas d'intervention prévue<!-- dans l'année--> :</div>
            <table class="infos"  :id="numUF2" v-for="(lUF, numUF2) in tabUfSansIntervention">
                <tr>
                    <td colspan="4">
                        <b>{{lUF.identifiant}}</b> :
                        {{lUF.peuplement.surfaceBoisee}} ha boisés sur {{lUF.libelleTerroir}}
                    </td>
                </tr>
                <tr v-if="lUF.commentaire">
                    <td >
                        &nbsp;
                    </td>
                    <td colspan="3">
                        {{lUF.commentaire}}
                    </td>
                </tr>
                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        Planté en {{lUF.peuplement.anneeRetenue}}
                    </td>
                    <td>
                        <span v-if="lUF.dendrometrieAnneeRetenue">Age : {{lUF.dendrometrieAnneeRetenue.anneeCourante}} ans</span>
                    </td>
                    <td>
                        <span v-if="lUF.derniereEclaircie && lUF.derniereEclaircie >= 0">Dernière éclaircie à {{lUF.derniereEclaircie}} ans<br/></span>
                        <span v-if="lUF.derniereMesure && lUF.derniereMesure >= 0">Dernière mesure à {{lUF.derniereMesure}} ans</span>
                    </td>
                </tr>
                <tr>
                    <td class="aDroite">
                    </td>
                    <td colspan="3">
                        <span v-if="lUF.dendrometrieAnneeRetenue">
                            HMAX : {{arrondir(lUF.dendrometrieAnneeRetenue.HMAXPrecedent)}} -  Hauteur : {{formaterHauteur(lUF.dendrometrieAnneeRetenue.hauteur)}} m<span v-if="lUF.dendrometrieAnneeRetenue.anneeCourante"> -
                            Circonférence : {{formaterCirconference(lUF.dendrometrieAnneeRetenue.circonference*100)}}
                            <br/>Accroissement en volume : {{formaterVolume((lUF.dendrometrieAnneeRetenue.volume+lUF.dendrometrieAnneeRetenue.cumulVolumeRecolteEclaircie)/lUF.dendrometrieAnneeRetenue.anneeCourante)}} m3/ha/an</span>
                            <br/>
                            Densité : {{formaterDensite(lUF.dendrometrieAnneeRetenue.densite)}} tiges/ha cm -
                            VU : {{formaterVolume(lUF.dendrometrieAnneeRetenue.volumeUnitaire)}} m3 -
                            Volume : {{formaterVolume(lUF.dendrometrieAnneeRetenue.volume)}} m3/ha
                        </span>
                        <span v-if="!lUF.dendrometrieAnneeRetenue">
                            Peuplement "non valide"
                        </span>
                    </td>
                </tr>
            </table>

        </div>
    </div>
</div>
</div>
</template>


<script>

import { SteresConstantes } from "../../js/calculs/constantes.js"
import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
import { addeoData } from "../../js/api/datas.js"


export default {
    components: {
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            // init les données du form à partir des props
            propriete: JSON.parse(JSON.stringify(this.data)),
            tabCoupesAnneePropriete:[],
            tabUfEclaircies:[],
            tabUfCoupesRases:[],
            tabUfSansPeuplement:[],
            tabUfSansIntervention:[],
            ready:false,
            constantes:null,
        }
    },

    created() {
        this.initialiser();
    },

    computed: {
        laAnnee() {
            if (this.tabUfEclaircies.length && this.tabUfEclaircies[0].dendrometrieAnneeRetenue) {
                if (this.tabUfEclaircies[0].dendrometrieAnneeRetenue.anneeCourante == this.tabUfEclaircies[0].dendrometrieAnnee.anneeCourante) {
                    var leAnnee =  this.tabUfEclaircies[0].dendrometrieAnneeRetenue.anneeCourante + this.tabUfEclaircies[0].peuplement.anneeRetenue;
                    var leAnneeMoinsUn =  this.tabUfEclaircies[0].dendrometrieAnneeRetenue.anneeCourante + this.tabUfEclaircies[0].peuplement.anneeRetenue - 1;
                    return(" en "+ leAnneeMoinsUn + " / "+ leAnnee);
                }
                else {
                    var leAnneePlusUn =  this.tabUfEclaircies[0].dendrometrieAnneeRetenue.anneeCourante + this.tabUfEclaircies[0].peuplement.anneeRetenue;
                    var leAnnee =  this.tabUfEclaircies[0].dendrometrieAnnee.anneeCourante + this.tabUfEclaircies[0].peuplement.anneeRetenue;
                    return(" en "+ leAnnee + " / "+ leAnneePlusUn);
                }
            }
            return("");
        },

        laTabValeursExportees() {

            var tab = [];

            // intervention	= Eclaircie prévue dans l'année
            this.tabUfEclaircies.forEach((lUF, numAnnee) => {

                var obj = {};
                        // intervention	codeUF	libelleUF	surfaceBoisee	codeTerroir	libelleTerroir	commentaireUF	anneePeuplement	age	ageDerniereEclaircie	ageDerniereMesure	avantEclaircieHMAX	avantEclaircieHauteur	avantEclaircieAccroissementVolume	avantEclaircieDensite	avantEclaircieCirconference	avantEclaircieVU	avantEclaircieVolume	eclaircieDensite	eclaircieCirconference	eclaircieVU	eclaircieVolume	apresEclaircieDensite	apresEclaircieCirconference	apresEclaircieVU	apresEclaircieVolume	recetteHA	recetteTotale
                obj.intervention = this.exporterValeur("Eclaircie prévue dans l'année");

                obj.codeUF = this.exporterValeur(lUF.code);
                obj.libelleUF = this.exporterValeur(lUF.identifiant);
                obj.surface = this.exporterValeur(lUF.surface);
                obj.surfaceBoisee = this.exporterValeur(lUF.peuplement.surfaceBoisee);
                obj.libelleTerroir = this.exporterValeur(lUF.libelleTerroir);
                obj.commentaireUF = this.exporterValeur(lUF.commentaire);
                obj.anneePeuplement = this.exporterValeur(lUF.peuplement.anneeRetenue);
                obj.age = 0;
                if ((typeof(lUF.dendrometrieAnneeRetenue) !== 'undefined') && lUF.dendrometrieAnneeRetenue) {
                    obj.age = this.exporterValeur(lUF.dendrometrieAnneeRetenue.anneeCourante);
                    if (!lUF.dendrometrieAnneeRetenue.densiteEclaircie && lUF.dendrometrieAnneeRetenue.densiteApres) {
                        obj.intervention = this.exporterValeur("Dépressage prévu dans l'année");
                    }
                }
                var derniereEclaircie = "";
                if (lUF.derniereEclaircie > 0) {
                    derniereEclaircie = lUF.derniereEclaircie;
                }
                obj.ageDerniereEclaircie = this.exporterValeur(derniereEclaircie);
                var derniereMesure = "";
                if (lUF.derniereMesure > 0) {
                    derniereMesure = lUF.derniereMesure;
                }
                obj.ageDerniereMesure = this.exporterValeur(derniereMesure);

                // avant eclaircie
                var avantInterventionHMAX = "";
                var avantInterventionHauteur = "";
                var avantInterventionAccroissementVolume = "";
                var avantInterventionDensite = "";
                var avantInterventionCirconference = "";
                var avantInterventionVU = "";
                var avantInterventionVolume = "";
                if (lUF.dendrometrieAnneeRetenue.densiteEclaircie) {
                    avantInterventionHMAX = lUF.dendrometrieAnneeRetenue.HMAXPrecedent;
                    avantInterventionHauteur = lUF.dendrometrieAnneeRetenue.hauteurAvantEclaircie;
                    avantInterventionAccroissementVolume = (lUF.dendrometrieAnneeRetenue.volumeAvantEclaircie+lUF.dendrometrieAnneeRetenue.cumulVolumeRecolteAvantEclaircie)/lUF.dendrometrieAnneeRetenue.anneeCourante;
                    avantInterventionDensite = lUF.dendrometrieAnneeRetenue.densiteAvantEclaircie;
                    avantInterventionCirconference = lUF.dendrometrieAnneeRetenue.circonferenceAvantEclaircie*100;
                    avantInterventionVU = lUF.dendrometrieAnneeRetenue.volumeUnitaireAvantEclaircie;
                    avantInterventionVolume = lUF.dendrometrieAnneeRetenue.volumeAvantEclaircie;
                }
                obj.avantInterventionHMAX = this.exporterValeur(avantInterventionHMAX);
                obj.avantInterventionHauteur = this.exporterValeur(avantInterventionHauteur);
                obj.avantInterventionAccroissementVolume = this.exporterValeur(avantInterventionAccroissementVolume);
                obj.avantInterventionDensite = this.exporterValeur(avantInterventionDensite);
                obj.avantInterventionCirconference = this.exporterValeur(avantInterventionCirconference);
                obj.avantInterventionVU = this.exporterValeur(avantInterventionVU);
                obj.avantInterventionVolume = this.exporterValeur(avantInterventionVolume);

                // eclaircie
                var interventionDensite = "";
                var interventionCirconference = "";
                var interventionVU = "";
                var interventionVolume = "";
                if (lUF.dendrometrieAnneeRetenue.densiteEclaircie) {
                    interventionDensite = lUF.dendrometrieAnneeRetenue.densiteEclaircie;
                    interventionCirconference = lUF.dendrometrieAnneeRetenue.circonferenceEclaircie*100;
                    interventionVU = lUF.dendrometrieAnneeRetenue.volumeUnitaireEclaircie;
                    interventionVolume = lUF.dendrometrieAnneeRetenue.volumeRecolteEclaircie;
                }
                obj.interventionDensite = this.exporterValeur(interventionDensite);
                obj.interventionCirconference = this.exporterValeur(interventionCirconference);
                obj.interventionVU = this.exporterValeur(interventionVU);
                obj.interventionVolume = this.exporterValeur(interventionVolume);

                // après eclaircie
                var apresInterventionDensite = "";
                var apresInterventionCirconference = "";
                var apresInterventionVU = "";
                var apresInterventionVolume = "";
                if (lUF.dendrometrieAnneeRetenue.densiteEclaircie) {
                    apresInterventionDensite = lUF.dendrometrieAnneeRetenue.densite;
                    apresInterventionCirconference = lUF.dendrometrieAnneeRetenue.circonference*100;
                    apresInterventionVU = lUF.dendrometrieAnneeRetenue.volumeUnitaire;
                    apresInterventionVolume = lUF.dendrometrieAnneeRetenue.volume;
                }
                obj.apresInterventionDensite = this.exporterValeur(apresInterventionDensite);
                obj.apresInterventionCirconference = this.exporterValeur(apresInterventionCirconference);
                obj.apresInterventionVU = this.exporterValeur(apresInterventionVU);
                obj.apresInterventionVolume = this.exporterValeur(apresInterventionVolume);

                obj.recetteHA = this.exporterValeur(lUF.recettesPrevuesAnnees/lUF.peuplement.surfaceBoisee);
                obj.recetteTotale = this.exporterValeur(lUF.recettesPrevuesAnnees);

                tab.push(obj);
            })


            // intervention	= Coupe rase prévue dans l'année
            this.tabUfCoupesRases.forEach((lUF, numAnnee) => {

                var obj = {};
                        // intervention	codeUF	libelleUF	surfaceBoisee	codeTerroir	libelleTerroir	commentaireUF	anneePeuplement	age	ageDerniereEclaircie	ageDerniereMesure	avantEclaircieHMAX	avantEclaircieHauteur	avantEclaircieAccroissementVolume	avantEclaircieDensite	avantEclaircieCirconference	avantEclaircieVU	avantEclaircieVolume	eclaircieDensite	eclaircieCirconference	eclaircieVU	eclaircieVolume	apresEclaircieDensite	apresEclaircieCirconference	apresEclaircieVU	apresEclaircieVolume	recetteHA	recetteTotale
                obj.intervention = this.exporterValeur("Coupe rase prévue dans l'année");

                obj.codeUF = this.exporterValeur(lUF.code);
                obj.libelleUF = this.exporterValeur(lUF.identifiant);
                obj.surface = this.exporterValeur(lUF.surface);
                obj.surfaceBoisee = this.exporterValeur(lUF.peuplement.surfaceBoisee);
                obj.libelleTerroir = this.exporterValeur(lUF.libelleTerroir);
                obj.commentaireUF = this.exporterValeur(lUF.commentaire);

                obj.anneePeuplement = this.exporterValeur(lUF.peuplement.anneeRetenue);
                obj.age = 0;
                if ((typeof(lUF.dendrometrieAnneeRetenue) !== 'undefined') && lUF.dendrometrieAnneeRetenue) {
                    obj.age = this.exporterValeur(lUF.dendrometrieAnneeRetenue.anneeCourante);
                }
                var derniereEclaircie = "";
                if (lUF.derniereEclaircie > 0) {
                    derniereEclaircie = lUF.derniereEclaircie;
                }
                obj.ageDerniereEclaircie = this.exporterValeur(derniereEclaircie);
                var derniereMesure = "";
                if (lUF.derniereMesure  > 0) {
                    derniereMesure = lUF.derniereMesure;
                }
                obj.ageDerniereMesure = this.exporterValeur(derniereMesure);

                // avant CR
                var avantInterventionHMAX = "";
                var avantInterventionHauteur = "";
                var avantInterventionAccroissementVolume = "";
                var avantInterventionDensite = "";
                var avantInterventionCirconference = "";
                var avantInterventionVU = "";
                var avantInterventionVolume = "";
                if ((typeof(lUF.dendrometrieAnnee) !== 'undefined') && lUF.dendrometrieAnnee) {
                    avantInterventionHMAX = lUF.dendrometrieAnneeRetenue.HMAXPrecedent;
                    avantInterventionHauteur = lUF.dendrometrieAnneeRetenue.hauteur;
                    avantInterventionAccroissementVolume = (lUF.dendrometrieAnneeRetenue.volume+lUF.dendrometrieAnneeRetenue.cumulVolumeRecolteEclaircie)/lUF.dendrometrieAnneeRetenue.anneeCourante;
                    avantInterventionDensite = lUF.dendrometrieAnneeRetenue.densite;
                    avantInterventionCirconference = lUF.dendrometrieAnneeRetenue.circonference*100;
                    avantInterventionVU = lUF.dendrometrieAnneeRetenue.volumeUnitaire;
                    avantInterventionVolume = lUF.dendrometrieAnneeRetenue.volume;
                }
                obj.avantInterventionHMAX = this.exporterValeur(avantInterventionHMAX);
                obj.avantInterventionHauteur = this.exporterValeur(avantInterventionHauteur);
                obj.avantInterventionAccroissementVolume = this.exporterValeur(avantInterventionAccroissementVolume);
                obj.avantInterventionDensite = this.exporterValeur(avantInterventionDensite);
                obj.avantInterventionCirconference = this.exporterValeur(avantInterventionCirconference);
                obj.avantInterventionVU = this.exporterValeur(avantInterventionVU);
                obj.avantInterventionVolume = this.exporterValeur(avantInterventionVolume);

                // CR
                var interventionDensite = "";
                var interventionCirconference = "";
                var interventionVU = "";
                var interventionVolume = "";
                obj.interventionDensite = this.exporterValeur(interventionDensite);
                obj.interventionCirconference = this.exporterValeur(interventionCirconference);
                obj.interventionVU = this.exporterValeur(interventionVU);
                obj.interventionVolume = this.exporterValeur(interventionVolume);

                // après CR
                var apresInterventionDensite = "";
                var apresInterventionCirconference = "";
                var apresInterventionVU = "";
                var apresInterventionVolume = "";
                obj.apresInterventionDensite = this.exporterValeur(apresInterventionDensite);
                obj.apresInterventionCirconference = this.exporterValeur(apresInterventionCirconference);
                obj.apresInterventionVU = this.exporterValeur(apresInterventionVU);
                obj.apresInterventionVolume = this.exporterValeur(apresInterventionVolume);

                obj.recetteHA = this.exporterValeur(lUF.recettesPrevuesAnnees/lUF.peuplement.surfaceBoisee);
                obj.recetteTotale = this.exporterValeur(lUF.recettesPrevuesAnnees);

                tab.push(obj);
            })


            // intervention	= Parcelle nue disponible pour le reboisement / Pas d'intervention prévue dans l'année
            this.tabUfSansPeuplement.forEach((lUF, numAnnee) => {

                var obj = {};
                        // intervention	codeUF	libelleUF	surfaceBoisee	codeTerroir	libelleTerroir	commentaireUF	anneePeuplement	age	ageDerniereEclaircie	ageDerniereMesure	avantEclaircieHMAX	avantEclaircieHauteur	avantEclaircieAccroissementVolume	avantEclaircieDensite	avantEclaircieCirconference	avantEclaircieVU	avantEclaircieVolume	eclaircieDensite	eclaircieCirconference	eclaircieVU	eclaircieVolume	apresEclaircieDensite	apresEclaircieCirconference	apresEclaircieVU	apresEclaircieVolume	recetteHA	recetteTotale
                obj.intervention = this.exporterValeur("Parcelle nue disponible pour le reboisement");
                obj.codeUF = this.exporterValeur(lUF.code);
                obj.libelleUF = this.exporterValeur(lUF.identifiant);
                obj.surface = this.exporterValeur(lUF.surface);
                obj.surfaceBoisee = this.exporterValeur("");
                obj.libelleTerroir = this.exporterValeur(lUF.libelleTerroir);
                obj.commentaireUF = this.exporterValeur(lUF.commentaire);

                obj.anneePeuplement = this.exporterValeur("");
                obj.age = this.exporterValeur("");
                var derniereEclaircie = "";
                obj.ageDerniereEclaircie = this.exporterValeur(derniereEclaircie);
                var derniereMesure = "";
                obj.ageDerniereMesure = this.exporterValeur(derniereMesure);

                // avant
                obj.avantInterventionHMAX = this.exporterValeur("");
                obj.avantInterventionHauteur = this.exporterValeur("");
                obj.avantInterventionAccroissementVolume = this.exporterValeur("");
                obj.avantInterventionDensite = this.exporterValeur("");
                obj.avantInterventionCirconference = this.exporterValeur("");
                obj.avantInterventionVU = this.exporterValeur("");
                obj.avantInterventionVolume = this.exporterValeur("");

                // ...
                obj.interventionDensite = this.exporterValeur("");
                obj.interventionCirconference = this.exporterValeur("");
                obj.interventionVU = this.exporterValeur("");
                obj.interventionVolume = this.exporterValeur("");

                // après
                obj.apresInterventionDensite = this.exporterValeur("");
                obj.apresInterventionCirconference = this.exporterValeur("");
                obj.apresInterventionVU = this.exporterValeur("");
                obj.apresInterventionVolume = this.exporterValeur("");

                obj.recetteHA = this.exporterValeur("");
                obj.recetteTotale = this.exporterValeur("");

                tab.push(obj);
            })


            // intervention	= Pas d'intervention dans l'année
            this.tabUfSansIntervention.forEach((lUF, numAnnee) => {

                var obj = {};
                        // intervention	codeUF	libelleUF	surfaceBoisee	codeTerroir	libelleTerroir	commentaireUF	anneePeuplement	age	ageDerniereEclaircie	ageDerniereMesure	avantEclaircieHMAX	avantEclaircieHauteur	avantEclaircieAccroissementVolume	avantEclaircieDensite	avantEclaircieCirconference	avantEclaircieVU	avantEclaircieVolume	eclaircieDensite	eclaircieCirconference	eclaircieVU	eclaircieVolume	apresEclaircieDensite	apresEclaircieCirconference	apresEclaircieVU	apresEclaircieVolume	recetteHA	recetteTotale
                obj.intervention = this.exporterValeur("Pas d'intervention dans l'année");

                obj.codeUF = this.exporterValeur(lUF.code);
                obj.libelleUF = this.exporterValeur(lUF.identifiant);
                obj.surface = this.exporterValeur(lUF.surface);
                obj.surfaceBoisee = this.exporterValeur(lUF.peuplement.surfaceBoisee);
                obj.libelleTerroir = this.exporterValeur(lUF.libelleTerroir);
                obj.commentaireUF = this.exporterValeur(lUF.commentaire);

                obj.anneePeuplement = this.exporterValeur(lUF.peuplement.anneeRetenue);

                obj.age = 0;
                if ((typeof(lUF.dendrometrieAnneeRetenue) !== 'undefined') && lUF.dendrometrieAnneeRetenue && lUF.dendrometrieAnneeRetenue.anneeCourante) {
                    obj.age = this.exporterValeur(lUF.dendrometrieAnneeRetenue.anneeCourante);
                }
                else {
                    obj.age = this.exporterValeur("");
                }
                var derniereEclaircie = "";
                if (lUF.derniereEclaircie > 0) {
                    derniereEclaircie = lUF.derniereEclaircie;
                }
                obj.ageDerniereEclaircie = this.exporterValeur(derniereEclaircie);
                var derniereMesure = "";
                if (lUF.derniereMesure > 0) {
                    derniereMesure = lUF.derniereMesure;
                }
                obj.ageDerniereMesure = this.exporterValeur(derniereMesure);

                // avant CR
                var avantInterventionHMAX = "";
                var avantInterventionHauteur = "";
                var avantInterventionAccroissementVolume = "";
                var avantInterventionDensite = "";
                var avantInterventionCirconference = "";
                var avantInterventionVU = "";
                var avantInterventionVolume = "";
                if ((typeof(lUF.dendrometrieAnnee) !== 'undefined') && lUF.dendrometrieAnnee) {
                    avantInterventionHMAX = lUF.dendrometrieAnneeRetenue.HMAXPrecedent;
                    avantInterventionHauteur = lUF.dendrometrieAnneeRetenue.hauteur;
                    avantInterventionAccroissementVolume = (lUF.dendrometrieAnneeRetenue.volume+lUF.dendrometrieAnneeRetenue.cumulVolumeRecolteEclaircie)/lUF.dendrometrieAnneeRetenue.anneeCourante;
                    avantInterventionDensite = lUF.dendrometrieAnneeRetenue.densite;
                    avantInterventionCirconference = lUF.dendrometrieAnneeRetenue.circonference*100;
                    avantInterventionVU = lUF.dendrometrieAnneeRetenue.volumeUnitaire;
                    avantInterventionVolume = lUF.dendrometrieAnneeRetenue.volume;
                }
                obj.avantInterventionHMAX = this.exporterValeur(avantInterventionHMAX);
                obj.avantInterventionHauteur = this.exporterValeur(avantInterventionHauteur);
                obj.avantInterventionAccroissementVolume = this.exporterValeur(avantInterventionAccroissementVolume);
                obj.avantInterventionDensite = this.exporterValeur(avantInterventionDensite);
                obj.avantInterventionCirconference = this.exporterValeur(avantInterventionCirconference);
                obj.avantInterventionVU = this.exporterValeur(avantInterventionVU);
                obj.avantInterventionVolume = this.exporterValeur(avantInterventionVolume);

                // ...
                obj.interventionDensite = this.exporterValeur("");
                obj.interventionCirconference = this.exporterValeur("");
                obj.interventionVU = this.exporterValeur("");
                obj.interventionVolume = this.exporterValeur("");

                // après
                obj.apresInterventionDensite = this.exporterValeur("");
                obj.apresInterventionCirconference = this.exporterValeur("");
                obj.apresInterventionVU = this.exporterValeur("");
                obj.apresInterventionVolume = this.exporterValeur("");

                obj.recetteHA = this.exporterValeur("");
                obj.recetteTotale = this.exporterValeur("");


                tab.push(obj);
            })

            return(tab);
        },
        etatINVALIDE() {
            return(SteresConstantes.etatINVALIDE);
        }

    },

    methods: {
        exporterValeur(valeur) {
            var valeurRetournee = ""+valeur;
            return(valeurRetournee.replace('.', ','));
        },

        exporterTableau(tableau) {
            var nomCSV = this.propriete.identifiant + "_synthese_interventions_annee";
            return addeoData.exporterTableau(tableau, nomCSV);
        },

        leTerroir(terroirId) {
            var lId = "id"+terroirId;
            if (!this.constantes) {
                return("");
            }
            var terroirObj = this.constantes.params.const_terroirs[lId];
            if (!terroirObj) {
                return("");
            }
            return(terroirObj.libelle);
        },
        laCulture(cultureId) {
            var lId = "id"+cultureId;
            if (!this.constantes) {
                return("");
            }
            //var cultureObj = ApiUser.trouverItemTabConst(constantes.params.const_cultures, this.ppl.cultureId, ""); // verrouille ci-dessous
            var cultureObj = this.constantes.params.const_cultures[lId];
            if (!cultureObj) {
                return("");
            }
            return(cultureObj.libelle);
        },
        derniereIntervention(interventions, max) {
            var laDerniere = -1;
            if (interventions) {
                interventions.forEach(intervention => {
                    if (intervention.age < max) {
                        laDerniere = intervention.age;
                    }
                })
            }
            return(laDerniere);
        },


        initialiser() {
            this.constantes = this.$store.getters.constantes;
            this.tabUfEclaircies=[];
            this.tabUfCoupesRases=[];
            this.tabUfSansPeuplement = [];
            this.tabUfSansIntervention = [];
            this.infosEclaircies = {};
            this.infosEclaircies.volume = 0;
            this.infosEclaircies.recette = 0;
            this.infosEclaircies.surface = 0;
            this.infosCoupesRases = {};
            this.infosCoupesRases.volume = 0;
            this.infosCoupesRases.recette = 0;
            this.infosCoupesRases.surface = 0;
            // on lit toutes les UF de la propriété pour trouver les coupes / eclaircies qu'on va intégrer année par année
            this.propriete.UFs.forEach(uf => {
                uf.libelleTerroir = this.leTerroir(uf.terroirId);
                if ((typeof(uf.peuplement) !== 'undefined') && uf.peuplement && uf.peuplement.ITs) {

                    var dendrometrieAnnee = uf.dendrometrieAnneeRetenue;
                    uf.peuplement.libelleCulture = this.laCulture(uf.peuplement.cultureId);

                    if ((uf.peuplement.etatUtilisateur == this.etatINVALIDE) || (typeof(dendrometrieAnnee) === 'undefined') || !dendrometrieAnnee) {
                        this.tabUfSansIntervention.push(uf);
                    }
                    else {
                        uf.derniereEclaircie = this.derniereIntervention(uf.peuplement.eclaircies, dendrometrieAnnee.anneeCourante);
                        uf.derniereMesure = this.derniereIntervention(uf.peuplement.mesures, dendrometrieAnnee.anneeCourante);
                        var coupeRase = uf.coupeRase;

                        //const eclaircieProspective = true;
                        const eclaircieProspective = ((typeof(dendrometrieAnnee.eclaircieProspective) !== 'undefined') && dendrometrieAnnee.eclaircieProspective ? true : false);

                        if ((coupeRase && (coupeRase == dendrometrieAnnee.anneeCourante)) || (eclaircieProspective && (dendrometrieAnnee.densiteEclaircie || dendrometrieAnnee.densiteApres))) {
                            if (dendrometrieAnnee.densiteEclaircie) {
                                this.tabUfEclaircies.push(uf);
                                this.infosEclaircies.volume += dendrometrieAnnee.volumeRecolteEclaircie*uf.peuplement.surfaceBoisee;
                                this.infosEclaircies.recette += uf.recettesPrevuesAnneeRetenue;
                                this.infosEclaircies.surface += uf.peuplement.surfaceBoisee;
                            }
                            else {
                                if (dendrometrieAnnee.densiteApres) {
                                    this.tabUfEclaircies.push(uf);
                                    // on ne compile aucune valeur sur les dépressages
                                }
                                else {
                                    this.tabUfCoupesRases.push(uf);
                                    this.infosCoupesRases.volume += dendrometrieAnnee.volume*uf.peuplement.surfaceBoisee;
                                    this.infosCoupesRases.recette += uf.recettesPrevuesAnneeRetenue;
                                    this.infosCoupesRases.surface += uf.peuplement.surfaceBoisee;
                                }
                            }
                        }
                        else {
                            this.tabUfSansIntervention.push(uf);
                        }
                    }
                }
            else {// pas de peuplement
                this.tabUfSansPeuplement.push(uf);
            }

        })
        this.tabUfEclaircies.sort(this.triCoupe);
        this.tabUfCoupesRases.sort(this.triCoupe);
        this.tabUfSansPeuplement.sort(this.triCoupe);
        this.tabUfSansIntervention.sort(this.triCoupe);

        this.ready = true;
        },

        triCoupe(x, y) {

            if ((typeof(x.dendrometrieAnnee) !== 'undefined') && (typeof(y.dendrometrieAnnee) !== 'undefined')) {
                if (x.dendrometrieAnnee.densiteEclaircie || y.dendrometrieAnnee.densiteEclaircie) {
                    if (!x.dendrometrieAnnee.densiteEclaircie) {
                        return(1);
                    }
                    if (!y.dendrometrieAnnee.densiteEclaircie) {
                        return(-1);
                    }
                }
            }
            else {
                if (typeof(x.dendrometrieAnnee) !== 'undefined')  {
                        return(-1);
                    }
                if (typeof(y.dendrometrieAnnee) !== 'undefined') {
                        return(1);
                    }
            }
           if (x.identifiant < y.identifiant) {
               return(-1)
           }
           if (x.identifiant > y.identifiant) {
               return(1)
           }

           return(0)
        },

        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },

        arrondir: function(valeur, puissance = 3) {
            var puissanceDe10 = Math.pow(10, puissance);
            var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
            return(arrondi);
        },
        formaterEuros: function(valeur) {
            return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
        },
        formaterVolumeTab(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterVolume(valeur) {
            return(ApiTools.arrondiVolume(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterSurface(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterSurfaceTab(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterDensite(valeur) {
            return(ApiTools.arrondiDensite(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterDensiteTab(valeur) {
            return(ApiTools.arrondiDensiteTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterHauteur(valeur) {
            return(ApiTools.arrondiHauteur(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterCirconference(valeur) {
            return(ApiTools.arrondiCirconference(valeur, this.$store.getters.constantes.steres.arrondisJson, false));
        },
        formaterCirconferenceTab(valeur) {
            return(ApiTools.arrondiCirconferenceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterValeur(valeur, arrondi) {
            return(ApiTools.arrondir(valeur, arrondi));
        }
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {

    #refermer {
        cursor:pointer;
        position:absolute;
        top:0.2em;
        /*color: #238B71;*/
        vertical-align: top;
        left: 50%;
        display: block;
        z-index: 2;
        i {
            color: #565656;
            font-size:2.2em;
            margin-left:0.4em;
        }
        span {
            vertical-align: top;
            display: inline-block;
            padding: 0.8em 0 0;
        }
    }

    #synthese2 {
        padding:5mm;
        border: 1px solid #cccccc;
        overflow-x:auto;

        #infosGeneriques {
            display:none;
        }

        .infoImpression {
            color:#238b71 !important;
            cursor:pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        div.sousTitreSynthese {
            font-size:1.4em;
            font-weight: bold;
            margin:1.6em 0 0.8em;
            color:#223938;
            padding : 0 1em;
            background-color: #DFEAE1;
        }

        .fa-download {
            margin-right : 1em;
        }
        .fa-print {
            font-size:2em;
            cursor:pointer;
        }
        .conteneur {
            max-width: 200mm;
            margin:0;
        }
            table {
                width: 100%;
                min-width: 600px;
                max-width: 200mm;
                background-color: transparent;
                margin-bottom: 1em;
                color: #223938;
                padding: 0.2em 1%;
                border-spacing : 0;
                margin:3mm 0;
                table-layout:auto;

                tr {
                    vertical-align: top;
                    margin-bottom:0.8em;

                    th {
                        padding:0;
                    }

                    &:first-child, &:nth-child(2) {
                        th {
                            span {
                                 display: inline;
                            }
                        }
                    }

                    th {
                        text-align: left;
                        /*
                        padding:1em 0;
                        */
                        width: 15%;
                        min-width: 150px;
                        max-width: 60mm;
                        white-space: nowrap;
                        font-weight: normal;

                        span {
                            display: none;

                            &.annee {
                                display: inline;
                                font-weight: bold;
                            }
                        }

                    }

                    td {
/**/
                        width: 15%;
                        min-width: 100px;
/**/
                        max-width: 40mm;

                        span.utile.inutile {
                            visibility:hidden;
                        }

                        &.petit {
                            width: 12%;
                            min-width: 120px;
                            max-width: 30mm;
                            span {
                                display: inline-block;
                                width: 100%;
                            }
                        }
                        &.comble {
                                width: auto;
                                min-width:0;
                            }

                    }
                    img.iconeFct {
                        text-align: left;
                        margin-left:1em;
                        float: none;
                        width : auto;
                    }
                }

                table.infosEclaircies {
                    width: 100%;
                    max-width: 900px;
                    tr {
                        td, th {
                            min-width: 90px;
                            max-width: 90px;
                            width: 10%;
                            padding:0;

                            span.unites {
                                display:block;
                                font-size:80%;
                                text-align: right;
                                padding-right:1em;
                            }
                        }
                        td {
                            padding:0 2% 0 0;
                            &.libelle {
                                min-width: 120px;
                                max-width: 120px;
                                color: #999;
                            }
                        }
                        th {
                            text-align: center;
                            color: #999;
                        }
                    }
                }
            }
        .image {
            display:none;
        }
        .aDroite {
            text-align: right;
        }
        .droite {
            float: right;
        }
        .centpourcent {
            width:100%;
        }
    }
}
    body#app {
        #syntheseB {
            color:#000000;
            font-family: Helvetica, Arial, sans-serif !important;

            h2 {
                font-family: Helvetica, Arial, sans-serif !important;
            }

            .infosGeneriques {
                margin-right: 2em;
            }
            /*
            background: url("../../assets/img/cpfa.png") transparent no-repeat top left;
            background-size: 15mm 20mm;
            */
            .image {
                text-align:right;
                /*display:inline;*/
                img {
                    width: 10mm;
                    height:auto;
                }
            }
            h2 {
                font-size:4mm;
                text-align: center;
                margin-top: 0;

                i.fa-download {
                   display:none;
                }
            }
            div.sousTitreSynthese {
                margin:1.4cm 0 0 10mm;
                text-align: left;
                font-size:3.8mm;
                font-weight: bold;
                color:#223938;
            }
            .aDroite {
                text-align: right;
                vertical-align: top;
            }
            .majuscules {
                text-transform: uppercase;
            }
            table {
                width: 200mm;
                font-size:3.2mm;
                margin-left:10mm;
                &.infos {
                    margin-top:5mm;
                }

                tr {
                    color:#444444;
                    &.ligneAnnee {
                        color:#000000;
                    }
                    th {
                        min-width: 35mm;
                        text-align: left;
                    }
                    td {
                        width: 30mm;
                        text-align: left;
                    }
                    td.petit {
                        min-width: 25mm;
                    }
                }
            }
        }
    }

</style>
