<template>
    <div id="demandeInscription">
<h2>Formulaire d'inscription</h2>
                <div class="edition">
                    <p class="info">Les champs suivis d'une * sont obligatoires.</p>
                    <v-form v-if="!connexionUtilisateur && connexionEnCours">
                        <v-text-field
                                v-model="form.userNom"
                                :error-messages="error.userNom"
                                label="Votre nom : *"
                                required
                                @input="$v.form.userNom.$touch()"
                                @blur="$v.form.userNom.$touch()"
                                ref="userNom"
                        ></v-text-field>
                        <v-text-field
                                v-model="form.userPrenom"
                                :error-messages="error.userPrenom"
                                label="Votre prénom : *"
                                required
                                @input="$v.form.userPrenom.$touch()"
                                @blur="$v.form.userPrenom.$touch()"
                                ref="userPrenom"
                        ></v-text-field>
                        <v-text-field
                                v-model="form.eMail"
                                required
                                :error-messages="error.eMail"
                                label="Votre adresse Mail : *"
                                @input="$v.form.eMail.$touch()"
                                @blur="$v.form.eMail.$touch()"
                                ref="userMail"
                        ></v-text-field>

                        <v-textarea
                                v-model="form.adresse"
                                label="Votre adresse : *"
                                required
                                ref="adresse"
                        ></v-textarea>

                        <a-autocomplete-villes
                               @selectVille="onSelectVille"
                                :initItem="codePostal_ville"
                                :clearable="true"
                                @click:clear="clickClear"
                                required
                                :error-messages="error.ville"
                                ref="userVille"
                        />
                        <span id="magicErrorAutocomplete" class="messageError v-messages v-messages__message">{{villeErrors}}</span>
                        <p class="info" >Important : STERES est destiné aux forestiers de la Nouvelle-Aquitaine</p>


                        <v-text-field
                                      class="aGauche"
                                v-model="form.telephone"
                                required
                                :error-messages="error.telephone"
                                label="Votre téléphone : *"
                                @input="$v.form.telephone.$touch()"
                                @blur="$v.form.telephone.$touch()"
                                ref="telephone"
                                type="number"
                                :counter="10"
                                @keydown="filtrerNombresEntiersPositifs"
                        ></v-text-field>

                        <v-text-field
                                      class="aGauche"
                            label="Votre Siret *"
                            v-model="form.siret"
                            required
                            :error-messages="error.siret"
                            @input="$v.form.siret.$touch()"
                            @blur="$v.form.siret.$touch()"
                            ref="siret"
                            type="number"
                            :counter="14"
                            @keydown="filtrerNombresEntiersPositifs"
                        ></v-text-field>


                        <div id="assujettiTVA">
                            <span class="checkbox">
                                    <v-checkbox
                                        v-model="form.tva"
                                        label=""
                                    ></v-checkbox>
                            </span>
                            <span class="libelle" >Je suis assujetti à la TVA</span>
                        </div>

                        <span v-if="form.tva" class="tva">FR</span>
                        <v-text-field
                            v-if="form.tva"
                             class="aGauche tva"
                            label="Votre n° de TVA *"
                            v-model="form.numTva"
                            :error-messages="error.numTva"
                            @input="$v.form.numTva.$touch()"
                            @blur="$v.form.numTva.$touch()"
                            ref="numTva"
                            type="number"
                            :counter="11"
                            @keydown="filtrerNombresEntiersPositifs"
                        ></v-text-field>


                        <div class="radioGroupe statuts" >
                            <v-radio-group v-model="form.statut"  v-on:click="">
                                <v-radio
                                    :label=labelEssai
                                    color="#4CAF50"
                                    :value=statutEssai
                                ></v-radio>
                                <v-radio
                                    :label=labelPayant
                                    color="#4CAF50"
                                    :value=statutPayant
                                ></v-radio>
                            </v-radio-group>
                        </div>
                        <p class="info" v-if="offreLancement">** Offre de lancement : - 50 % pour les 10 000 premiers Ha</p>


                        <span class="antiSpam">Validation anti-spam</span>
                        <v-text-field v-if="this.ready"
                            :label="laVerif"
                            v-model="resultat"
                            required
                            :error-messages="resultatErrors"
                            ref="resultat"
                        ></v-text-field>

                        <div id="validationCGU">
                            <span class="checkbox">
                                    <v-checkbox
                                        v-model="cguValidees"
                                        label=""
                                    ></v-checkbox>
                            </span>
                            <span class="libelle" >J'ai lu, compris et accepte les <router-link to="/conditionsGenerales" target="_blank">Conditions Générales d'Utilisation</router-link> de STERES ainsi que la <router-link to="/donneespersonnelles" target="_blank">Charte de Protection des Données Personnelles</router-link> associée<br/><span><i>(consentement obligatoire pour pouvoir se connecter)</i></span></span>
                        </div>

                    <div class="boutonsValidation">
                        <v-btn   v-if="cguValidees" class="a-btn prioritaire" type="button" @click="demanderInscription">Je m'inscris</v-btn>
                        <v-btn   v-if="!cguValidees" class="a-btn prioritaire" type="button" @click.stop="cguNonValideesPrevenir">Je m'inscris</v-btn>
                    </div>

                    </v-form>




                </div>



            <v-dialog v-model="dialogError" v-if="errorDemandeInscription" >
                 <v-card>
                     <v-card-title class="headline">Connexion STERES</v-card-title>
                     <v-card-text>
                         <span v-if="errorDemandeInscription">{{errorDemandeInscription}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

    </div>
</template>


<script>

    import { addeoData } from "../../js/api/datas.js"
    import { ApiTools } from "../../js/api/index.js"

    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength, email/*, phone*/ } from 'vuelidate/lib/validators'


    import AddeoAutocompleteVilles from '../ui/autocomplete/AutocompleteVilles.vue'


    export default {
        mixins: [validationMixin],
        name: 'Login',
        components: {
            'a-autocomplete-villes': AddeoAutocompleteVilles,
        },
        validations: {
            form: {
                userNom: { required, maxLength: maxLength(50)},
                userPrenom: { required, maxLength: maxLength(20) },
                eMail: {  required, email },
                adresse: {  required },
                telephone: {  required, minLength:minLength(10), maxLength: maxLength(10)/*, phone*/ },
                siret: { required, minLength:minLength(14), maxLength:maxLength(14) },
                numTva: { minLength:minLength(11), maxLength:maxLength(11) },
            }
        },
        props: {
        /**
         * Données à injecter
         */
            connexionUtilisateur: Boolean

        },

        data() {
            return {
                connexionEnCours:true,
                codePostal_ville: null,
                form: {
                    userNom: "",
                    userPrenom: "",
                    eMail:"",
                    adresse:"",
                    codeCommune:"",
                    codePostal:"",
                    libelleCommune:"",
                    telephone:"",
                    siret:"",
                    tva:false,
                    numTva:"",
                    statut:this.statutPayant,
                },
                error: {
                    userNom: "",
                    userPrenom: "",
                    eMail:"",
                    adresse:"",
                    ville:"",
                    telephone:"",
                    siret:"",
                    numTva:"",
                },
                dialogError:false,
                errorDemandeInscription:false,
                messageInscription:"",
                cguValidees: false,
                // messages d'erreur
                //eMailErrors:"",
                    // antispam
                resultat:"",
                resultatErrors: '',
                ready:false,
                antiSpam1:89,
                antiSpam2:72,
                myTimeout:null,
            }
        },


        watch: {
            cguValidees(newValue) {
                //this.$store.commit('setCguValidees', this.cguValidees); // on commence par stocker l'état
                if(newValue) {
                    this.cguNonValidees(false);
                }
            }
        },

        created: function () {
            this.myTimeout = setTimeout(this.preparerAntispam, 1500);
            this.form.statut = this.statutPayant;
        },

        mounted: function () {
        },

        computed: {
            statutEssai() {
                return(1);
            },
            statutPayant() {
                return(2);
            },
            labelEssai() {
                return("Je choisis l'accès gratuit, limité à 5 Unités Forestières pour un an");
            },
            labelPayant() {
                var gestionCommercialeJson = this.$store.getters.constantes.steres.gestionCommercialeJson;
                var tarif = ApiTools.tarifStandard(gestionCommercialeJson);
                var libelleEtoile = ApiTools.offreLancement(gestionCommercialeJson)?"**":"";
                return("Je choisis l’accès complet, facturé "+tarif+ "€/Ha/an"+libelleEtoile);
            },
            offreLancement() {
                return(ApiTools.offreLancement(this.$store.getters.constantes.steres.gestionCommercialeJson));
            },
            userNomErrors () {
                if (!this.$v.form.userNom.$invalid) return false // verifier invalid
                if (!this.$v.form.userNom.required) {
                     this.error.userNom  = "Vous devez saisir votre nom"
                }
                this.$refs.userNom.focus();
                return true
            },
            userPrenomErrors () {
                if (!this.$v.form.userPrenom.$invalid) return false // verifier invalid
                if (!this.$v.form.userPrenom.required) {
                     this.error.userPrenom  = "Vous devez saisir votre prénom"
                }
                this.$refs.userPrenom.focus();
                return true
            },
            adresseErrors () {
                if (!this.$v.form.adresse.$invalid) return false // verifier invalid
                if (!this.$v.form.adresse.required) {
                     this.error.adresse  = "Vous devez saisir votre adresse"
                }
                this.$refs.adresse.focus();
                return true
            },
            eMailErrors () {
                if (!this.$v.form.eMail.$invalid) return false // verifier invalid
                if (!this.$v.form.eMail.required) {
                     this.error.eMail  = "Vous devez saisir votre adresse mail"
                }
                this.$refs.userMail.focus();
                return true
            },
            villeErrors () {
                return(this.error.ville)
                // ce qui doit être rempli et créé
                //.v-autocomplete .v-messages__wrapper .v-messages__message
                // créé dans .v-messages__wrapper
                // <div class="v-messages__message" style="">Exemple: 40170 Lit-et-Mixe</div>
            },
            telephoneErrors () {
                if (!this.$v.form.telephone.$invalid) return false // verifier invalid
                if (!this.$v.form.telephone.required) {
                     this.error.telephone  = "Vous devez saisir votre numéro de téléphone"
                }
                if  ((!this.$v.form.telephone.minLength) || (!this.$v.form.telephone.maxLength)) {
                     this.error.telephone  = "Le format du numero de téléphone saisi doit être de type 0911223344 - en cas de problème, complétez le champ - Votre Adresse - avec le format de téléphone qui vous convient";
                }
                this.$refs.telephone.focus();
                return true
            },
            siretErrors () {
                if (!this.$v.form.siret.$invalid) return false // verifier invalid
                if (!this.$v.form.siret.required) {
                     this.error.siret  = "La saisie d'un siret est obligatoire"
                }
                if  ((!this.$v.form.siret.minLength) || (!this.$v.form.siret.maxLength)) {
                     this.error.siret  = "La saisie d'un siret (14 chiffres) est obligatoire"
                }
                this.$refs.siret.focus();
                return true
            },
            numTvaErrors () {
                if (!this.form.tva) {
                    return false
                }
                if (!this.$v.form.numTva.$invalid) return false // verifier invalidnumTva
                if  ((!this.$v.form.numTva.minLength) || (!this.$v.form.numTva.maxLength)) {
                     this.error.numTva  = "La saisie d'un numéro de TVA à 11 chiffres est obligatoire - le code FR est ajouté automatiquement."
                }
                this.$refs.numTva.focus();
                return true
            },
            laVerif() {
                return("Donnez le résultat de "+this.antiSpam1+" + "+ this.antiSpam2+" = *");
            }

        },
        methods: {
            getRandomInt(max) {
              return Math.floor(Math.random() * Math.floor(max))+1;
            },
            preparerAntispam() {
                    if (this.myTimeout) {
                        clearTimeout(this.myTimeout);
                    }
                this.antiSpam1= this.getRandomInt(50);
                this.antiSpam2= this.getRandomInt(30);
                this.ready = true;
            },
            logout() {
                this.$store.commit('setProprieteChargeeId', 0); // on rechargera les UFs de la propriété
                this.connexionEnCours = true;
                this.$emit("transfertConnexion", false);
            },
            reinitErrorMessages() {
                /*
                this.resultatErrors = '';
                this.error.userNom = '';
                this.error.userPrenom = '';
                this.error.eMail = '';
                this.error.ville = '';
                this.error.siret = '';
                */
            },
            demanderInscription() {
                this.reinitErrorMessages();
                this.$v.$touch();
                var userNomErrors = this.userNomErrors;
                var userPrenomErrors = this.userPrenomErrors;
                var eMailErrors = this.eMailErrors;
                var adresseErrors = this.adresseErrors;
                var villeIsVide = this.villeIsVide();
                var telephoneErrors = this.telephoneErrors;
                var siretErrors = this.siretErrors;
                var numTvaErrors = this.numTvaErrors;
                var resultatIsFalse = this.resultatIsFalse();
                if ( (this.$v.$invalid &&
                      (userNomErrors || userPrenomErrors || eMailErrors || adresseErrors || telephoneErrors || siretErrors || numTvaErrors))
                     || villeIsVide || resultatIsFalse ) {

                    console.log("submit pas OK");
                    return;
                }
                if (this.form.tva) {
                    this.form.nTva = "FR"+this.form.numTva;
                }
                else {
                    this.form.nTva = this.form.numTva = "";
                }
                addeoData.validerDemandeInscription(this.form)
                .then(response => {
                    if (response && response.retour == true) {
                        this.messageInscription = "Votre demande d'inscription a bien été traitée. Vous allez recevoir un mail de confirmation dans les minutes qui viennent sur l'adresse eMail que vous avez indiquée. En cas de non réception, vérifiez votre dossier spam.";
                        this.$emit('retourInscription', this.messageInscription);
                    }
                    else {
                        this.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.erreur) {
                            complementErreur = response.erreur;
                        }
                        this.errorDemandeInscription = "Inscription impossible : " + complementErreur;
                        console.log("demanderInscription non valide")
                    }
                })
            },

            cguNonValideesPrevenir() {
                this.dialogError = true;
                this.errorDemandeInscription = "Vous devez accepter les Conditions Générales d'Utilisation";
                this.cguNonValidees(true);
            },
            cguNonValidees(prevenir) {
                var eltCheck = document.getElementById("validationCGU");
                if (prevenir) {
                    ApiTools.toggleClassElt(eltCheck, 'addClass', "insister");
                }
                else  {
                    ApiTools.toggleClassElt(eltCheck, 'removeClass', "insister");
                }
            },

            fermerDialogError() {
                this.dialogError = false;

                if (this.cguValidees && this.errorDemandeInscription) {
                    this.$nextTick(() => {
                        this.$refs.userNom.focus();
                    })
                }

            },
            resultatIsFalse () {
                if (this.resultat != (this.antiSpam1 + this.antiSpam2))  {
                    this.resultatErrors = "Vous devez indiquer le résultat du calcul";
                    this.$refs.resultat.focus();
                    return true;
                }
                else {
                    this.resultatErrors = "";
                }
                return false;
            },
            villeIsVide () {
                if (this.form.codePostal == "")  {
                    this.error.ville = "Vous devez saisir un code postal ou un libellé de ville";
                    this.$refs.userVille.$children[0].focus(); // IMPOSSIBLE
                    //this.$refs.resultat.focus();
                    return true;
                }
                else {
                    this.error.ville = "";
                }
                return false;
            },

            // gestion de l'autocomplete
            onSelectVille(villeObj) {
                this.codePostal_ville = JSON.stringify(villeObj.codePostal_ville)
                this.form.codeCommune = villeObj.codeComm
                this.form.codePostal = villeObj.codePostal
                this.form.libelleCommune = villeObj.libelle
            },
            clickClear() {
                this.codePostal_ville = ''
                this.form.codeCommune = ''
                this.form.codePostal = ''
                this.form.libelleCommune = ''
            },
            majCodePostal_ville(objGlob, codePostal_ville) {
                objGlob.codePostal_ville = codePostal_ville;
            },
            // pour la saisie
            filtrerNombresEntiersPositifs(e){
                return(ApiTools.filtrerNombresEntiersPositifs(e))
            },
        }
    }
</script>

<style lang="scss">
    #app {

        img {
            max-width: 90%;
        }

        .home {
            .conteneur {
                margin:0 auto;
            }
            div.wrapperIllustration {
                div.edition {
                    min-height: 0;
                }
            }

            .connexionPlus {
                margin-right:1em;
                &.lien {
                    color: #238b71;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            span.checkbox {
                div {
                    display: inline;
                }
                .v-input {
                    background-color: transparent;
                }
                .v-icon {
                    color: #394f4e;
                }
            }
            span.tva {
                float: left;
                height: 4em;
                display: block;
                padding: 1.4em 0.4em;
                margin:0.3em 0.2em 0.5em 0;
                background-color: #394F4E;
                border-radius: 5px 5px 0 0;
                color:white;
                opacity:0.7;
            }
            div#assujettiTVA {
                margin-top:1em;
            }
        }
        #demandeInscription {
            .messageInformation {
                padding:1em;
                margin:1em 0;
                position:relative;
                font-size:0.9em;
                text-align: left;
                background-color:white;
                color:#238b71;
            }
            .v-autocomplete {
                .v-input__slot {
                    margin-bottom: 0;
                }
            }
            #magicErrorAutocomplete {
                margin-top:-15px;
                position: absolute;
                z-index: 98;
                background-color: #EDEDED;
                width:100%;
            }
            .statuts {
                label {
                    font-size: 0.9em;
                }
            }

            .v-select--is-menu-active.v-autocomplete {
                .v-messages__wrapper {
                    .v-messages__message {
                        margin: 0 0 1.6em;
                        color:#394f4e;
                        font-size:80%;
                    }
                }
            }
        }
        span.antiSpam {
            margin-top:1em;
            display: block;
        }
        #validationCGU {

            span.checkbox {
                vertical-align: top;
            }
            span.libelle {
                display:inline-block;
                max-width: 316px;
                font-size: 0.9em;
                a {
                    color: #238b71;
                    font-weight: bold;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                i {
                    font-size: 0.9em;
                }
            }
            &.insister {
                span.libelle {
                    span {
                        color:orangered;
                    }
                }
            }
            margin:1em 0;
        }
    }



</style>
