import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
//import LoginComponent from "./views/Login.vue"
//import SecureComponent from "./views/Secure.vue"
import Credits from './views/Credits.vue'
import MentionsLegales from './views/MentionsLegales.vue'
import ConditionsGenerales from './views/ConditionsGenerales.vue'
import Proprietes from './views/Proprietes.vue'
import Propriete from './views/Propriete.vue'
import ProprieteEdition from './views/ProprieteEdition.vue'
import ProprieteTarifs from './views/ProprieteTarifs.vue'
import Synchroniser from './views/Synchroniser.vue'
import Tests from './views/Tests.vue'
import UfEdition from './views/UfEdition.vue'
import UfDecoupe from './views/UfDecoupe.vue'
import UfImport from './views/UfImport.vue'
import Peuplement from './views/Peuplement.vue'
import PeuplementEdition from './views/PeuplementEdition.vue'
import PeuplementMesure from './views/PeuplementMesure.vue'
import PeuplementEclaircie from './views/PeuplementEclaircie.vue'
import PeuplementItEclaircie from './views/PeuplementItinerairesTechniquesEclaircie.vue'

import Densite from './views/AfficherDensite.vue'
import PresentationSteres from './views/PresentationSteres.vue'

import Synchros from './views/Synchros.vue'


Vue.use(Router)


function leDomaine() {
    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return('https://www.steres.fr') // la bonne URL
    }
    if (leDomaine == "steres.fr") {
        return('https://www.steres.fr');
    }
    else {
        if (leDomaine == "preprod.steres.fr") {
            return('https://preprod.steres.fr');
        }
        else {
            return('https://https://steres.addeo.com/')
        }
    }
}

const router = new Router({
//export default new Router({
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
        return { x: 0, y: 0 };
    },
    mode: 'history',
    routes: [
   /*
        {
            path: '/',
            redirect: {
                name: "login"
            }
        },*/
        {
            path: '/',
            name: 'home',
            component: Home,
            alias: '/home',
            meta: {
              title: 'STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles',
              metaTags: [ {
                  name: 'description',
                  content: 'STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles'
                },
                // Twitter
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:title', content: 'STERES : Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles'},
                {name: 'twitter:description', content: "STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles."},
                // image must be an absolute path
                {name: 'twitter:image', content: leDomaine() + ('/img/steres.png')},
                // Facebook OpenGraph
                {property: 'og:url', content: leDomaine()},
                {property: 'og:title', content: 'STERES : Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles'},
                {property: 'og:site_name', content: 'Steres'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content:  leDomaine() + ('/img/steres.png')},
                {property: 'og:description', content: "STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles."}
                ]
            }
        },
  /*      {
            path: "/login",
            name: "login",
            component: LoginComponent
        },
*/
      /*
        {
            path: "/secure",
            name: "secure",
            component: SecureComponent
        },
        */
/*
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( './views/About.vue')
    },
*/
      /*
    {
      path: '/InputForm',
      name: 'InputForm',
      component: InputForm
    },
    */
    {
      path: '/donneespersonnelles',
      name: 'mentionslegales',
      component: MentionsLegales
    },
    {
      path: '/conditionsgenerales',
      name: 'conditionsgenerales',
      component: ConditionsGenerales
    },
    {
      path: '/credits',
      name: 'credits',
      component: Credits
    },
    {
      path: '/proprietes',
      name: 'proprietes',
      component: Proprietes
    },
    {
      path: '/propriete',
      name: 'propriete',
      component: Propriete
    },
    {
      path: '/proprieteedition',
      name: 'proprieteedition',
      component: ProprieteEdition
    },
    {
      path: '/proprietetarifs',
      name: 'proprietetarifs',
      component: ProprieteTarifs
    },
    {
      path: '/ufedition',
      name: 'ufedition',
      component: UfEdition
    },
    {
      path: '/ufdecoupe',
      name: 'ufdecoupe',
      component: UfDecoupe
    },
    {
      path: '/ufimport',
      name: 'ufimport',
      component: UfImport
    },
    {
      path: '/peuplement',
      name: 'peuplement',
      component: Peuplement
    },
    {
      path: '/peuplementedition',
      name: 'peuplementedition',
      component: PeuplementEdition
    },
    {
      path: '/peuplementmesure',
      name: 'peuplementmesure',
      component: PeuplementMesure
    },
    {
      path: '/peuplementeclaircie',
      name: 'peuplementeclaircie',
      component: PeuplementEclaircie
    },
    {
      path: '/peuplementiteclaircie',
      name: 'peuplementiteclaircie',
      component: PeuplementItEclaircie
    },
    {
      path: '/synchroniser',
      name: 'synchroniser',
      component: Synchroniser
    },
      {
          path: "/tests",
          name: "tests",
          component: Tests
      },
    {
      path: '/synchros',
      name: 'synchros',
      component: Synchros
    },
    {
      path: '/presentation',
      name: 'presentation',
      component: PresentationSteres,
      alias: 'presentation',
    },
    {
      path: '/densite',
      name: 'densite',
      component: Densite,
      alias: 'densite',
            meta: {
              title: 'STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles',
              metaTags: [ {
                  name: 'description',
                  content: 'STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles'
                },
                // Twitter
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:title', content: 'STERES : Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles'},
                {name: 'twitter:description', content: "STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles."},
                // image must be an absolute path
                {name: 'twitter:image', content: leDomaine() + ('/img/steres.png')},
                // Facebook OpenGraph
                {property: 'og:url', content: leDomaine()},
                {property: 'og:title', content: 'STERES : Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles'},
                {property: 'og:site_name', content: 'Steres'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content:  leDomaine() + ('/img/steres.png')},
                {property: 'og:description', content: "STERES est votre application de Simulations Technico Economiques de la Rentabilité des Exploitations Sylvicoles."}
                ]
            }
    },
  ]
})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
