<template>
<div>

    <span id="refermer" @click.stop="$parent.refermerSynthese()">
        <i class="fas fa-window-close-o"></i>
        <div class="clear"></div>
    </span>
 <div id="synthese5">
    <div>
        <div v-if="!$store.getters.modeForet" class="aDroite infoImpression"  @click.stop="$parent.imprimerSynthese('syntheseE')">Vous pouvez imprimer cette synthèse :
            <i class="fas fa-print"></i>
        </div>
        <div id="aImprimer" class="conteneur" v-if="ready">
            <div class="gauche image"><img src="../../assets/img/logoCPFAv.png" /></div>
            <div id="infosGeneriques" class="droite"></div>

            <h2 v-if="!$store.getters.modeForet" @click.stop="exporterTableau(laTabValeursExportees)" class="lien"><i class="fas fa-download"></i>Synthèse carbone</h2>
            <h2 v-if="$store.getters.modeForet">Synthèse carbone</h2>

                <table class="infos">
                    <tr class="entete">
                        <th class="">
                            Année
                        </th>
                        <th class="">
                            Stock en forêt
                        </th>
                        <th class="petit">
                            (dont)<br/>Stock Sol
                        </th>
                        <th class="">
                            Stock<br/>Produits bois
                        </th>
                        <th class="">
                            Stock en forêt<br/>et Produits bois
                        </th>
                        <th class="">
                            Substitution
                        </th>
                        <th class="">
                            Eclaircies
                        </th>
                        <th class="">
                            Coupes rases
                        </th>
                    </tr>
<!--
            objStock.carboneStocke = carbone.carboneStocke * surfaceBoisee;
            objStock.carboneSubstitue = carbone.carboneSubstitue * surfaceBoisee;
-->
                    <tr class="aDroite"  :id="numAnnee" v-for="(lAnnee, numAnnee) in leTabCoupesAnneePropriete" :key="numAnnee" v-bind:class="{impair : numAnnee%2}">
                        <td colspan="1" v-if="!lAnnee[0].valeursStockees">{{lAnnee.annee}}</td>
                        <td v-if="lAnnee[0].valeursStockees">{{lAnnee.annee}}</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee[0].valeursStockees.carboneSequestre}">{{formaterCarbone(lAnnee[0].valeursStockees.carboneSequestre)}}</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee[0].valeursStockees.carboneSequestreSol}" class="petit">{{formaterPourCent(lAnnee[0].valeursStockees.carboneSequestreSol/lAnnee[0].valeursStockees.carboneSequestre*100)}}</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee[0].valeursStockees.carboneStocke}">{{formaterCarbone(lAnnee[0].valeursStockees.carboneStocke)}}</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee[0].valeursStockees.carboneSequestre || !lAnnee[0].valeursStockees.carboneStocke}">{{formaterCarbone(lAnnee[0].valeursStockees.carboneSequestre+lAnnee[0].valeursStockees.carboneStocke)}}</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee[0].valeursStockees.carboneSubstitue}">{{formaterCarbone(lAnnee[0].valeursStockees.carboneSubstitue)}}</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee.volumeEclaircies}">{{formaterVolumeTab(lAnnee.volumeEclaircies)}} m3</td>
                        <td v-if="lAnnee[0].valeursStockees" v-bind:class="{vide : !lAnnee.volumeCoupesRases}">{{formaterVolumeTab(lAnnee.volumeCoupesRases)}} m3</td>
                    </tr>
                </table>

        </div>
    </div>
</div>
</div>
</template>


<script>

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
import { addeoData } from "../../js/api/datas.js"
import * as SteresConst from '../../js/steres_const.js'


//import PeuplementDendrometrieVisualisation from './PeuplementDendrometrieVisualisation.vue'


export default {
    components: {
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            // init les données du form à partir des props
            propriete: JSON.parse(JSON.stringify(this.data)),
            tabCoupesAnneePropriete:[],
            ready:false,
        }
    },

    created() {
        this.initialiser();
    },

    computed: {
        leTabCoupesAnneePropriete() {
            var laDate=new Date();
            const anneeCourante = laDate.getFullYear();
            var tabVide=[];
            if (this.tabCoupesAnneePropriete.length==0 || typeof(this.tabCoupesAnneePropriete[0].annee) === 'undefined') {
                return(tabVide);
            }
            // on ne pontre que les 20 prochaines année s MAIS PAS QUE  celles à partir de l'année courante
            return(this.tabCoupesAnneePropriete.filter(
                //coupeAnnee => (((coupeAnnee.annee-anneeCourante) < 21) && (coupeAnnee.annee >= anneeCourante))
                coupeAnnee => (coupeAnnee.annee > 0 && coupeAnnee.annee-anneeCourante <= SteresConst.SYNTHESE_CARBONE_MAX)
                //coupeAnnee => (((coupeAnnee.annee-anneeCourante) <= SteresConst.SYNTHESE_CARBONE_MAX) && (coupeAnnee.annee > 0))
                ));
        },

        laTabValeursExportees() {

            var copieTab = this.leTabCoupesAnneePropriete.slice();
            var tab = [];
            copieTab.forEach((lAnnee, numAnnee) => {

                var obj = {};
                // annee	carboneSequestre	carboneSequestreSol	carboneStocke	carboneSubstitue	volumeEclaircies	volumeCoupesRases
                obj.annee = this.exporterValeur(lAnnee.annee);
                obj.carboneSequestre = this.exporterValeur(lAnnee[0].valeursStockees.carboneSequestre);
                obj.carboneSequestreSol = this.exporterValeur(lAnnee[0].valeursStockees.carboneSequestreSol);
                obj.carboneStocke = this.exporterValeur(lAnnee[0].valeursStockees.carboneStocke);
                obj.carboneSubstitue = this.exporterValeur(lAnnee[0].valeursStockees.carboneSubstitue);
                obj.volumeEclaircies = this.exporterValeur(lAnnee.volumeEclaircies);
                obj.volumeCoupesRases = this.exporterValeur(lAnnee.volumeCoupesRases);

                tab.push(obj);
            })
            return(tab);
        }

    },

    methods: {
        exporterValeur(valeur) {
            var valeurRetournee = ""+valeur;
            return(valeurRetournee.replace('.', ','));
        },

        exporterTableau(tableau) {
            var nomCSV = this.propriete.identifiant + "_synthese_synthese_carbone";
            return addeoData.exporterTableau(tableau, nomCSV);
        },

        initialiser() {
            var tabCoupesPropriete = [];
            var valeursStockees = [];
            // on lit toutes les UF de la propriété pour trouver les coupes / eclaircies qu'on va intégrer année par année
            this.propriete.UFs.forEach(uf => {
                if ((typeof(uf.peuplement) !== 'undefined') && uf.peuplement && uf.peuplement.ITs) {
                    var coupeRase = uf.coupeRase;
                    var eclaircies = uf.eclaircies;
                    var valeursStockees = uf.valeursStockees;
                    if (coupeRase && (uf.dendrometrieAnnee.anneeCourante <= coupeRase)) {
                        var objetCoupe = {};
                        objetCoupe.type = "CR";
                        objetCoupe.annee = coupeRase + uf.peuplement.anneeRetenue;
                        objetCoupe.age = coupeRase;
                        objetCoupe.identifiantUF = uf.identifiant;
                        objetCoupe.codeUF = uf.code;
                        objetCoupe.surfaceBoisee = uf.peuplement.surfaceBoisee;
                        objetCoupe.valeursStockees = uf.valeursStockees[coupeRase];
                        tabCoupesPropriete.push(objetCoupe);
                    }
                    if (typeof(eclaircies) !== 'undefined') {
                        eclaircies.forEach(eclaircie => {
                            if (eclaircie) {
                                if (uf.dendrometrieAnnee.anneeCourante <= eclaircie.age) {
                                //if ((typeof(uf.peuplement) !== 'undefined')
                                    var objetEclaircie = {};
                                    objetEclaircie.type = "EC";
                                    objetEclaircie.annee = uf.peuplement.anneeRetenue + eclaircie.age;
                                    objetEclaircie.age = eclaircie.age;
                                    objetEclaircie.identifiantUF = uf.identifiant;
                                    objetEclaircie.codeUF = uf.code;
                                    objetEclaircie.surfaceBoisee = uf.peuplement.surfaceBoisee;
                                    objetEclaircie.valeursStockees = uf.valeursStockees[eclaircie.age];
                                    tabCoupesPropriete.push(objetEclaircie);
                                }
                            }
                        })
                    }
                }
                else {
                    // this.calculerDepenseUF(propriete, uf, constantes);
                }

            })
            tabCoupesPropriete.sort(this.triCoupe);
            var tabCoupesAnneePropriete = this.traiterAnneesPropriete(tabCoupesPropriete);
            this.tabCoupesAnneePropriete = tabCoupesAnneePropriete;
            this.ready = true;
        },

        triCoupe(x, y) {
           if (x.annee != y.annee) {
                return(x.annee - y.annee);
            }
           if (x.identifiantUF < y.identifiantUF) {
               return(-1)
           }
           if (x.identifiantUF > y.identifiantUF) {
               return(1)
           }
           if (x.type < y.type) {
               return(-1)
           }
           if (x.type > y.type) {
               return(1)
           }
           return(0)
        },

        traiterAnneesPropriete(tabCoupesPropriete) {
            var anneePrecedente = 0;
            var surfaceBoisee = 0;
            var tabAnnees = [];
            var cptAnnees = -1;
            var tabCoupesAnnee = [];

            var valeursStockees = this.propriete.valeursStockees;
            var leThis = this;

            // A - Avant la première coupe / éclaircie "future"
            // il faut peut-être "combler" entre l'annee Courante et la première coupe
            var laDate=new Date();
            const anneeCourante = laDate.getFullYear();
            if (tabCoupesPropriete.length && (anneeCourante < tabCoupesPropriete[0].annee)) {
                cptAnnees++;
                cptAnnees = leThis.comblerTabAnnees(tabAnnees, cptAnnees, valeursStockees, anneeCourante-1, tabCoupesPropriete[0].annee-1);
            }


            // B - Entre les coupes et éclaircies "futures"
            // on lit toutes les coupes - triées par années -
            tabCoupesPropriete.forEach(coupe => {
                if (coupe.annee != anneePrecedente) {
                    cptAnnees++;
                    console.log("traiterAnneesPropriete - cptAnnees, coupe.annee, coupe.type : ", cptAnnees, coupe.annee, coupe.type);
                    if (anneePrecedente && (anneePrecedente+1 < coupe.annee)) {
                        cptAnnees = leThis.comblerTabAnnees(tabAnnees, cptAnnees, valeursStockees, anneePrecedente, coupe.annee);
                    }
                    else {
                        leThis.initialiserTabAnneesAnnee(tabAnnees, cptAnnees, valeursStockees, coupe.annee);
                    }
                    var lAnnee = "annee"+coupe.annee;
                    anneePrecedente = coupe.annee;
                    tabAnnees[cptAnnees].push(coupe);
                }
                else {
                    tabAnnees[cptAnnees].push(coupe);
                }
                if (((typeof coupe.valeursStockees) !== 'undefined') && coupe.valeursStockees) { // 2021/12 coupe.valeursStockees NULL
                    if (coupe.type == "EC") {
                        tabAnnees[cptAnnees].volumeEclaircies += coupe.valeursStockees.volumeCoupe ;
                    }
                    if (coupe.type == "CR") {
                        tabAnnees[cptAnnees].volumeCoupesRases += coupe.valeursStockees.volumeCoupe;
                    }
                }
            })


            // C - Après les coupes et éclaircies "futures"
            if (anneePrecedente < anneeCourante+SteresConst.SYNTHESE_CARBONE_MAX) {
                leThis.comblerTabAnnees(tabAnnees, cptAnnees, valeursStockees, anneePrecedente-1, anneeCourante+SteresConst.SYNTHESE_CARBONE_MAX);
            }


            return(tabAnnees);
        },

        initialiserTabAnneesAnnee(tabAnnees, cptAnnees, valeursStockees, annee) {
            var lAnnee = "annee" + annee;
            var objAnnee = {};
            objAnnee.type = "_CA";
            objAnnee.annee = annee;
            objAnnee.age = 0;
            objAnnee.identifiantUF = "";
            objAnnee.surfaceBoisee = "";
            objAnnee.valeursStockees = valeursStockees[lAnnee];
            tabAnnees[cptAnnees] = [];
            tabAnnees[cptAnnees].push(objAnnee);
            tabAnnees[cptAnnees].annee = annee;
            tabAnnees[cptAnnees].volumeEclaircies = 0;
            tabAnnees[cptAnnees].volumeCoupesRases = 0;
        },

        comblerTabAnnees(tabAnnees, cptAnnees, valeursStockees, anneePrecedente, anneeSuivante) {
            //for(var cptAges = anneePrecedente-1; cptAges >= anneeSuivante; cptAges--) {
            for(var cptAges = anneePrecedente+1; cptAges <= anneeSuivante; cptAges++) {
                this.initialiserTabAnneesAnnee(tabAnnees, cptAnnees, valeursStockees, cptAges);
                cptAnnees++;
                }
            return(cptAnnees-1);
        },

        formaterEuros(valeur) {
            return(ApiTools.formaterEuros0(valeur));
        },
        formaterCarbone(valeur) {
            return(ApiTools.formaterCarbone0(valeur));
        },
        formaterPourCent(valeur) {
            return(ApiTools.formaterPourCent0(valeur));
        },
        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterVolumeTab(valeur) {
            //return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
            return(ApiTools.arrondir(valeur, 0));
        },

    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {

    #refermer {
        cursor:pointer;
        position:absolute;
        top:0.2em;
        /*color: #238B71;*/
        vertical-align: top;
        left: 50%;
        display: block;
        z-index: 2;
        i {
            font-size:2.2em;
            margin-left:0.4em;
            color: #565656;
        }
        span {
            vertical-align: top;
            display: inline-block;
            padding: 0.8em 0 0;
        }
    }

    #synthese5 {
        padding:5mm;
        border: 1px solid #cccccc;
        overflow-x:auto;

        #infosGeneriques {
            display:none;
        }

        .infoImpression {
            color:#238b71 !important;
            cursor:pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .fa-print {
            font-size:2em;
            cursor:pointer;
        }
        .fa-download {
            margin-right : 1em;
        }
        .conteneur {
            /*max-width: 200mm;*/
            margin:0;
            text-align:center;
        }

        table {
                width: 100%;
                min-width: 600px;
                max-width: 720px;
                background-color: transparent;
                color: #223938;
                padding: 1em  1% 0.2em;
                border-spacing : 0;
                margin:3mm auto 1em;
                table-layout:auto;

                tr {
                    vertical-align: top;
                    &.entete {
                        th {
                            padding-bottom:1em;
                        }
                    }

                    th, tr {
                        padding:0;
                    }

                    &:first-child, &:nth-child(2) {
                        th {
                            span {
                                 display: inline;
                            }
                        }
                    }

                    th, td {
                        width: 12.5%;
                        min-width: 120px;
                        max-width: 36mm;
                    }
                    th {
                        margin-bottom: 1em;
                        text-align: center;
                        white-space: nowrap;
                        font-weight: normal;
                        font-weight: bold;
                        vertical-align: bottom;
                        &.petit {
                            width: 12%;
                            min-width: 90px;
                        }

                        span {
                            display: none;

                            &.annee {
                                display: inline;
                                font-weight: bold;
                            }
                        }

                    }

                    td {
                        padding-right:1.4em;
                        span.utile.inutile {
                            visibility:hidden;
                        }
                        &.vide {
                            color: transparent;
                        }

                        &.petit {
                            width: 12%;
                            min-width: 90px;
                            max-width: 30mm;
                            span {
                                display: inline-block;
                                width: 100%;
                            }
                        }
                        &.comble {
                                width: auto;
                                min-width:0;
                            }

                    }
                    &.impair {
                        td {
                            background-color: #F2F7F3;
                        }
                    }
                    img.iconeFct {
                        text-align: left;
                        margin-left:1em;
                        float: none;
                        width : auto;
                    }
                }
            }
        .image {
            display:none;
        }
        .aDroite {
            text-align: right;
        }
        .droite {
            float: right;
        }
    }
}
    body#app {
        #syntheseE {
            color:#000000;
            font-family: Helvetica, Arial, sans-serif !important;

            h2 {
                font-family: Helvetica, Arial, sans-serif !important;
            }
            /*
            background: url("../../assets/img/cpfa.png") transparent no-repeat top left;
            background-size: 15mm 20mm;
            */
            .infosGeneriques {
                margin-right: 2em;
            }
            .image {
                text-align:right;
                /*display:inline;*/
                img {
                    width: 10mm;
                    height:auto;
                }
            }
            h2 {
                font-size:4mm;
                text-align: center;
                margin-top: 0;

                i.fa-download {
                   display:none;
                }
            }
            .aDroite {
                text-align: right;
                vertical-align: top;
            }
            .majuscules {
                text-transform: uppercase;
            }
            table {
                max-width: 200mm;
                width: 200mm;
                font-size:3.2mm;
                margin-left:10mm;

                tr {
                    color:#444444;
                    &.ligneAnnee {
                        color:#000000;
                    }
                    th {
                        width: 15mm;
                        text-align: center;
                        vertical-align: bottom;
                    }
                    td {
                        width: 30mm;
                        text-align: left;
                    }
                    th.petit, td.petit {
                        min-width: 25mm;
                    }
                    &.aDroite {
                        td {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

</style>
