var globGraphes;

//*********************************
class SVGGraphes {

  constructor($q) { // Injection des dépendances SteresDB et $q
    globGraphes = this;

        // paramétrage de l'epure

    this.l = 1000;
    this.lUtile = 900;
    this.hUtile = 530;
    this.h = 640; // ?
    this.hZero = 0;
    this.margeLegendes = 5;
    this.hauteurLegendes = 15;
    this.margeAffichageY = 10;
    this.lEtiquette = 70;
    this.hEtiquette = 30;
    this.lLegende = 60;
    this.hLegende = 20;

        // mécanisme de l'animation
    this.x1 = 0;
    this.y1 = 0;
    this.x2 = this.l-1;
    this.y2 = this.h;
    this.y = 0;


    this.svgns = "http://www.w3.org/2000/svg";
    this.svgnl = "http://www.w3.org/2000/xlink";

  }

        //********************************* balise SVG directement dans le code HTML
	enteteSVGcalque(id) {

        var l = this.l;
        var h = this.h+this.margeAffichageY;
        var x1 = 0;
        var y1 = 0;

        // NB ne surtout pas mettre de largeur ou de hauteur ! responsif !!!
		var retour = '<svg version="1.1" id="'+id+'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="'+x1+' '+y1+' '+l+' '+h+'" xml:space="preserve">';
		//var retour = '<svg version="1.1" id="'+id+'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="'+l+'px" height="'+h+'px"  viewBox="'+x1+' '+y1+' '+l+' '+h+'" xml:space="preserve">';

        this.calqueEnCours.id = id;
        this.calqueEnCours.calque += retour;
        return(retour);
	}

        //*********************************
	cloreSVGcalque() {
        var retour = '</svg>';
        this.calqueEnCours.calque += retour;
        return(retour);
    }

        //*********************************
	styleCSScalque(leStyle) {
        var retour = '<style type="text/css">'+leStyle+'</style>';
        this.calqueEnCours.calque += retour;
        return(retour);
    }


    //*********************************
	epureCSS() {
		//var retour = '\n.alea .polygone {fill:url(#aleaBande);}'
		//var retour = '\n.alea .polygone {fill:#f6f6f6;opacity:0.7}'
		var retour = /*'path.hauteur {fill:none;stroke:#3498DB;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1;}'
            +'\npath.B {stroke:#118900;}'
            */
            /*'text {font-family:"robotoregular, Arial, Helvetica, sans-serif";}'
            +*/'\npath.classCourbe0 {stroke:#111111;}'
//            +'\npath.classCourbe1 {stroke:#804000;}'
            +'\n.axes line {fill:none;stroke:#111111;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1;}'
            +'\.axes line.classCourbe0 {stroke:#111111;}'
            +'\line.ligneSurfaceEquilibre {stroke:red;opacity:0.4;stroke-dasharray:4}'
            +'\.legendeY.ligneSurfaceEquilibre.libelle {fill:red;opacity:0.5;font-size:0.7em;text-anchor: start}'
            +'\.legendeY.ligneSurfaceEquilibre.libelle.libelle1 {dominant-baseline: text-after-edge}'
            +'\.legendeY.ligneSurfaceEquilibre.libelle.libelle2 {dominant-baseline: text-before-edge}'

//            +'\.axes line.classCourbe1 {stroke:#804000;}'
/*
            +'\nrect.fond {fill:#ffffff;stroke:transparent;opacity:0.8;}'
            +'\nrect.transparent {fill:transparent;stroke:transparent;stroke-width:0;}'
*/
            +'\nrect.classe0 {fill:#275241;stroke:#275241;}'
            +'\nrect.classe1 {fill:#275241;stroke:#275241;}'
            +'\nrect.classe2 {fill:#275241;stroke:#275241;}'
            +'\nrect.classe3 {fill:#275241;stroke:#275241;}'
            +'\nrect.classe4 {fill:#275241;stroke:#275241;}'
            +'\nrect.classeCR {fill:#FF9933;stroke:#FF9933;}'
            +'\nrect.classe0-4 {fill:#D8B088;stroke:#D8B088;}'
            +'\nrect.classe5-9 {fill:#C38649;stroke:#C38649;}'
            +'\nrect.classe10-14 {fill:#996633;stroke:#996633;}'
            +'\nrect.classe15-19 {fill:#44FE70;stroke:#44FE70;}'
            +'\nrect.classe20-24 {fill:#01CB31;stroke:#01CB31;}'
            +'\nrect.classe25-29 {fill:#016F1B;stroke:#016F1B;}'
            +'\nrect.classe30-34 {fill:#97C1FF;stroke:#97C1FF;}'
            +'\nrect.classe35-39 {fill:#4B94FF;stroke:#4B94FF;}'
            +'\nrect.classe40-44 {fill:#0066FF;stroke:#0066FF;}'
            +'\nrect.classe45-49 {fill:#FF21FF;stroke:#FF21FF;}'
            +'\nrect.classe50-54 {fill:#BC00BC;stroke:#BC00BC;}'
            +'\nrect.classe55-59 {fill:#800080;stroke:#800080;}'
            +'\nrect.classe+60 {fill:#FF0000;stroke:#FF0000;}'
            +'\nrect.classeCR-49 {fill:#4F81BD;stroke:#4F81BD;}'
            +'\nrect.parDessus {fill:#512800;stroke:#512800;fill-opacity:1;stroke-opacity:1}'

            +'\n.libelle {font-size:0.9em; text-anchor: middle; dominant-baseline: central; fill:#111111;}'
            +'\n.libelle.legendeY {dominant-baseline: central;text-anchor: middle;font-size: 1em;}'
            +'\n.libelle.legendeYbis {font-size: 0.8em;}'
            +'\n.libelle.sousTitre {font-size: 0.7em;fill:#EEEEEE;}'


            +'\n.libelle.axeX {text-anchor: start;dominant-baseline: hanging;}'
            +'\n.libelle.axeY {text-anchor: middle;dominant-baseline: initial;}'

            +'\n.libelle.classCourbe0 {fill:#111111;}'
//            +'\n.libelle.classCourbe1 {fill:#804000;}'
            +'\n.libelle.titre {font-size:1.2em;dominant-baseline:hanging;}'
            +'\n.libelle.titre .titre1 {fill:#000091;}'
            +'\n.libelle.titre .titre2 {fill:#8000FF;}'
            +'\n.libelle.intervalleX {text-anchor: middle;dominant-baseline: hanging;}'
            +'\n.libelle.intervalleY {dominant-baseline: central;font-size: 0.8em;}'
            +'\n.libelle.intervalleYgauche {text-anchor: end;}'
            +'\n.libelle.intervalleYdroite {text-anchor: start;}';

        return(retour);
    }



    //*********************************
    diviser(dividende, diviseur, message = "") {
        if (!diviseur) {
            alert("Division par 0 : ", message);
        }
        return(dividende/diviseur);
    }
    //*********************************
	afficherCourbes(courbe) {

        var laCourbe = this;
        var laClasse = courbe.infos.classe;
        var debutX = courbe.infos.debutX;
        var minX = courbe.infos.minX;
        var intervalleX = courbe.infos.intervalleX;
        var itemsX = courbe.infos.itemsX;
        var infosY = courbe.infosY;
        var existeCourbes = [];

        var x0= (this.l - this.lUtile) / 2;
        var y0 = (this.h - this.hUtile) / 2;



        // calcul des echelles **************************************************************
        var maxX = 0;
        //var minX = 100000;
        //var minX = 0;

        var maxY = [];
        var minY = [];
        var i = 0;

        courbe.datas.forEach(function(point) {
            maxX = Math.max(point.x, maxX);
            minX = Math.min(point.x-intervalleX, minX);
            i = 0;
            while (typeof(point.itemsY[i]) !== 'undefined') {
                existeCourbes[i] = true;
                i++;
            }

        });

        // echelle en fonction des max "normés"
        i = 0;
        while (typeof(courbe.infosY[i]) !== 'undefined') {
            maxY[i] = courbe.infosY[i].max;
            minY[i] = 0;
            if (courbe.negatifPositif) {
//                minY[i] = maxY[i]*(-1); // 2019/12 valeur négatives - idem max positif ... mais négatif !!!
                //minY[i] = courbe.infosY[i].min; // 2019/12 valeur négatives
                minY[i] = Math.min(courbe.infosY[i].min, maxY[i]*(-1));
                maxY[i] = Math.max(courbe.infosY[i].max, minY[i]*(-1));
            }
            i++;
            break; // 09/2022 - une seule fois (cf. une seule série pour le moment)
        }

        // de l'axe de x
        var echelleX = this.diviser(this.lUtile, intervalleX+maxX-minX, "echelleX");
        //var echelleX = this.lUtile/(maxX-minX);
        // des axes des y
        var echelleY = [];
        i = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            //echelleY[i] = this.hUtile/(maxY[i]-minY[i]);
            echelleY[i] = this.diviser(this.hUtile,maxY[i]-minY[i],"echelleY[i]");
            if (courbe.negatifPositif) {
                //echelleY[i] = this.diviser(this.hUtile/2,maxY[i]-minY[i],"echelleY[i]"); // test 2020/03 bug si "que du négatif"
            }
            i++;
            break; // 09/2022 - une seule fois (cf. une seule série pour le moment)
        }

        // titre
        var retour = '<g class="axes">';
        var xBase = x0-minX*echelleX;
        var x1 = xBase+minX*echelleX;
        var x2 = xBase+(maxX+intervalleX)*echelleX;
        var xTitre = (x1+x2)/2;
        var yTitre = 0;
        if (courbe.infos.titre) {
            retour += '<text class="libelle titre" x="'+xTitre+'" y="'+yTitre+'">'+courbe.infos.titre+'</text>'
        }
        if ((courbe.infos.titre1) && (courbe.infos.titre2)) {
            yTitre = 15;
            retour += '<text class="libelle titre" x="'+xTitre+'" y="'+yTitre+'"><tspan class="titre1">'+courbe.infos.titre1+'</tspan> / <tspan class="titre2">'+courbe.infos.titre2+'</tspan></text>';

        }


        // dessin des axes  **************************************************************
        var xBaseGauche = x1; // ? valider - Remarque : l'échelle des x est unique
        var xBaseDroite = x2; // COMPRENDRE !!! voir si on peut utiliser xBase ...
        var yBase = [];
        var yAxe0s = [];
        var classAxesY = [];
        i = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            classAxesY[i] =  " classCourbe"+i; // conserver espace en début
            if (courbe.negatifPositif) {
                yBase[i] = y0-Math.min(minY[i],0)*echelleY[i]+maxY[i]*echelleY[i];
                yAxe0s[i] = (y0+yBase[i])/2;
            }
            else {
                yBase[i] = y0-0+maxY[i]*echelleY[i];
                yAxe0s[i] = yBase[i];
            }
            i++;
            break; // 09/2022 - une seule fois (cf. une seule série pour le moment)
        }

        // axe X
        var xAxeX = x2+2*this.margeLegendes;
        var yAxeX = yAxe0s[0]+this.margeLegendes; // UN SEUL cf. remarque précédente
        retour += '<text class="libelle axeX" x="'+xAxeX+'" y="'+yAxeX+'">'+courbe.infos.axeX+'</text>'
        retour += '<line class="axeX" x1="'+x1+'" y1="'+yAxe0s[0]+'" x2="'+x2+'" y2="'+yAxe0s[0]+'" />';

        // axes Y
        var xAxesY = [];
        var y2 = [];
        var y1 = [];
        i = 0;
        var hVerticaleInteractive = 0;
        var decalageAxe = (this.l-this.lUtile)/4;
        var cptDroite = 0;
        var cptGauche = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            y2[i] = yAxe0s[i]-maxY[i]*echelleY[i];
            // y1[i] = yBase[i]+minY[i]*echelleY[i];
            y1[i] = yAxe0s[i]-minY[i]*echelleY[i]; // correction pour valeurs négatives
            if (i) {
                hVerticaleInteractive = Math.max(hVerticaleInteractive, y1[i]-y2[i]);
            }
            else {
                hVerticaleInteractive = y1[0]-y2[0];
            }
            if (infosY[i].xAxeY == "droite") {
                xAxesY[i] = xBaseDroite + decalageAxe*cptDroite;
                var y2decal = y2[i]-this.hEtiquette+this.hEtiquette*cptDroite;
                cptDroite++;
            }
            else {
                xAxesY[i] = xBaseGauche - decalageAxe*cptGauche;
                var y2decal = y2[i]-this.hEtiquette+this.hEtiquette*cptGauche;
                cptGauche++;
            }
            if (!i) {
                var classeAxe = classAxesY[i];
                retour += '<line class="'+classeAxe+'" x1="'+xAxesY[i]+'" y1="'+y1[i]+'" x2="'+xAxesY[i]+'" y2="'+y2decal+'" />';
                var yAxeY = y2decal-this.hEtiquette; // il faut toujours être au dessus des étiquettes
                retour += '<text class="libelle axeY '+classeAxe+'" x="'+xAxesY[i]+'" y="'+yAxeY+'">'+courbe.infosY[i].libelleAxeY+'</text>';
                yAxeY += this.hauteurLegendes;
                retour += '<text class="libelle axeY '+classeAxe+'" x="'+xAxesY[i]+'" y="'+yAxeY+'">'+courbe.infosY[i].uniteLibelleAxeY+'</text>';
            }
            i++;
            break; // 09/2022 - une seule fois (cf. une seule série pour le moment)
        }


        // les intervalles des axes x et y
        var xIntervalleGauche = xBaseGauche-this.margeLegendes;
        var xIntervalleDroite = xBaseDroite+this.margeLegendes;
        var xIntervalle = xIntervalleGauche;
        var xIntervalleClassGauche = "intervalleYgauche"; // intervalles des axes x
        var xIntervalleClassDroite = "intervalleYdroite";
        var xIntervalleClass = xIntervalleClassGauche;
        var yIntervalle = yAxe0s[0]+this.margeLegendes;
        for (let iIntervalleX = 0; iIntervalleX <= maxX; iIntervalleX+=intervalleX) {
            var xintervalleX = xBase + (iIntervalleX) *echelleX;
            var libelleX = itemsX[iIntervalleX].libelleCourt;
            retour += '<text class="libelle intervalleX" x="'+xintervalleX+'" y="'+yIntervalle+'">'+libelleX+'</text>'
            if (typeof(itemsX[iIntervalleX].libelleCourtBis) !== 'undefined') {
                var yIntervalleBis = yIntervalle+this.hauteurLegendes;
                retour += '<text class="libelle intervalleX" x="'+xintervalleX+'" y="'+yIntervalleBis+'">'+itemsX[iIntervalleX].libelleCourtBis+'</text>'
            }
        }
        i = 0;
        var yIntervalles = [];
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            yIntervalles[i] = yAxe0s[i]+this.margeLegendes;
            var xIntervalles = xAxesY[i];
            if (infosY[i].xAxeY == "droite") {
                xIntervalle = xIntervalleDroite;
                xIntervalleClass = xIntervalleClassDroite;
                xIntervalles += this.margeLegendes
            }
            else {
                xIntervalle = xIntervalleGauche;
                xIntervalleClass = xIntervalleClassGauche;
                xIntervalles -= this.margeLegendes
            }

            if (!i) {
                var classeAxe = classAxesY[i];
                for (let iIntervalleY = 0; iIntervalleY <= maxY[i]; iIntervalleY+=infosY[i].intervalleY) {
                    iIntervalleY = this.arrondir(iIntervalleY); // bug javascript 0.4+0.2 = 0.600000000001 :-/
                    var iIntervalleY_txt = iIntervalleY.toLocaleString();
                    //if (infosY[i].donneesY == "€")
                    var yintervalleY = yAxe0s[i]-iIntervalleY*echelleY[i];
                    retour += '<text class="libelle intervalleY '+xIntervalleClass+classeAxe+'" x="'+xIntervalles+'" y="'+yintervalleY+'">'+iIntervalleY_txt+'</text>'
                }

            }
            i++;
            break; // 09/2022 - une seule fois (cf. une seule série pour le moment)
        }

        retour += '</g>';


        // dessin du path - une seule série pour le moment - 2021-03-02
        var numGraphe = 0;

        for (let iIntervalleX = 0; iIntervalleX <= maxX; iIntervalleX+=intervalleX) {
            var itemsY = courbe.datas[iIntervalleX].itemsY;
            var xintervalleX = xBase + (iIntervalleX) *echelleX;
            var libelleY = itemsY[numGraphe].yLibelle;
            var libelleYbis = itemsY[numGraphe].yLibelleBis;
            var hRect = itemsY[numGraphe].y*echelleY[numGraphe];
            var yBottomRect = yAxe0s[numGraphe];
            var yTopRect = yBottomRect-hRect;
            var lRect = (intervalleX/2)*echelleX;
            var xLeftRect = xintervalleX-lRect/2;
            var yTopY = yTopRect-3*this.margeAffichageY;
            var yTopYbis = yTopY+ this.hauteurLegendes;
            if (itemsY[numGraphe].y) {
                hRect--; // ne pas être sur la ligne noire
                var laClasse = "classe"+itemsX[iIntervalleX].classe;
                retour += '<rect class="'+laClasse+'" x="'+xLeftRect+'" y="'+yTopRect+'" width="'+lRect+'" height="'+hRect+'"  />';
            }
            if (itemsY[numGraphe+1]) {
                hRect = itemsY[numGraphe+1].y*echelleY[numGraphe];
                yTopY = yTopRect+2*this.margeAffichageY/3;
                yTopRect = yTopRect-hRect; // on part du haut de l'autre rectangle
                //yTopRect = yBottomRect-hRect;
                retour += '<rect class="parDessus" x="'+xLeftRect+'" y="'+yTopRect+'" width="'+lRect+'" height="'+hRect+'"  />';
                yTopYbis = yTopRect+2*this.margeAffichageY/3;
                retour += '<text class="libelle sousTitre" x="'+xintervalleX+'" y="'+yTopY+'">'+itemsY[numGraphe].sousTitre+'</text>';
                retour += '<text class="libelle sousTitre" x="'+xintervalleX+'" y="'+yTopYbis+'">' + itemsY[numGraphe+1].sousTitre + '</text>';
                yTopYbis -= 2*this.margeAffichageY;
                libelleY += " " + libelleYbis;
                retour += '<text class="libelle legendeY" x="'+xintervalleX+'" y="'+yTopYbis+'">'+libelleY+'</text>';
            }
            else {
                retour += '<text class="libelle legendeY" x="'+xintervalleX+'" y="'+yTopY+'">'+libelleY+'</text>';
                retour += '<text class="libelle legendeY legendeYbis" x="'+xintervalleX+'" y="'+yTopYbis+'">'+libelleYbis+'</text>';
            }
        }

        // surface d'équilibre - ligne horizontale
        if (courbe.surfaceEquilibre) {
            var ySurfaceEquilibre = courbe.surfaceEquilibre.y*echelleY[0];
            var yBottomSurfaceEquilibre = yAxe0s[0];
            var yTopSurfaceEquilibre = yBottomSurfaceEquilibre-ySurfaceEquilibre;
            retour += '<line class="axeX ligneSurfaceEquilibre" x1="'+x1+'" y1="'+yTopSurfaceEquilibre+'" x2="'+x2+'" y2="'+yTopSurfaceEquilibre+'" />';
            retour += '<text class="libelle libelle1 legendeY ligneSurfaceEquilibre" x="'+x2+'" y="'+yTopSurfaceEquilibre+'">'+courbe.surfaceEquilibre.libelle1+'</text>'
            retour += '<text class="libelle libelle2 legendeY ligneSurfaceEquilibre" x="'+x2+'" y="'+yTopSurfaceEquilibre+'">'+courbe.surfaceEquilibre.libelle2+'</text>'
        }


        this.calqueEnCours.calque += retour;
    }

    //*********************************
    arrondir = function(valeur, puissance = 3) {
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

        //*********************************
	initialiserGraphe(leStore, courbe = null) {

        leStore.commit('setConnexionTimeMSec');

        // calques de l'animation
        this.calqueCourbes = new Object(); // le dessin dont les etiquettes
        this.calqueCourbes.calque = "";
        this.calqueEnCours = this.calqueCourbes;

        var retour = this.grapheParams(courbe);

        return(retour);
    }

        // ************************************
    grapheParams(courbes){

        // un calque avec les polygones ET les etiquettes
        this.calqueEnCours = this.calqueCourbes;
        this.enteteSVGcalque("epureSVG");
        this.styleCSScalque(this.epureCSS());
        this.afficherCourbes(courbes);
        this.cloreSVGcalque();
        var retour = '<div id="grapheParams">';
        retour += this.calqueEnCours.calque;
        retour += '</div>';



        return(retour);
    }

        // ************************************
/*
    afficherDansDiv(divId){
        var element = document.getElementById(divId);
        element.innerHTML = '<div id="courbes">'+this.calqueCourbes.calque+'</div>';
        //$( divId ).html('<div id="courbes">'+this.calqueCourbes.calque+'</div>');

    }
*/
}


export const grapheSVG = new SVGGraphes()
