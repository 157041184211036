// passer en base ce qui doit l'être
// exemple
//*********************************

class STERES_CONSTANTES {

    constructor() { // par défaut
        /*
        */
        this.PI = 3.141592654; //  π
        this.etatVALIDE = 1;
        //this.etatVALIDEUTILISATEUR = 2;
        this.etatAVALIDER = 3;
        this.etatINVALIDE = 4;
        //this.etatINVALIDEUTILISATEUR = 5;

        this.classesAge = [
            {"index":0, "libelle":"CR", "libelleCourt":"CR", "age":{"min":-1, "max":-1}},
            {"index":1, "libelle":"0-4 ans", "libelleCourt":"0-4", "age":{"min":0, "max":4}},
            {"index":2, "libelle":"5-9 ans", "libelleCourt":"5-9", "age":{"min":5, "max":9}},
            {"index":3, "libelle":"10-14 ans", "libelleCourt":"10-14", "age":{"min":10, "max":14}},
            {"index":4, "libelle":"15-19 ans", "libelleCourt":"15-19", "age":{"min":14, "max":19}},
            {"index":5, "libelle":"20-24 ans", "libelleCourt":"20-24", "age":{"min":20, "max":24}},
            {"index":6, "libelle":"25-29 ans", "libelleCourt":"25-29", "age":{"min":25, "max":29}},
            {"index":7, "libelle":"30-34 ans", "libelleCourt":"30-34", "age":{"min":30, "max":34}},
            {"index":8, "libelle":"35-39 ans", "libelleCourt":"35-39", "age":{"min":35, "max":39}},
            {"index":9, "libelle":"40-44 ans", "libelleCourt":"40-44", "age":{"min":40, "max":44}},
            {"index":10, "libelle":"45-49 ans", "libelleCourt":"45-49", "age":{"min":45, "max":49}},
            {"index":11, "libelle":"50-54 ans", "libelleCourt":"50-54", "age":{"min":50, "max":54}},
            {"index":12, "libelle":"55-59 ans", "libelleCourt":"55-59", "age":{"min":55, "max":59}},
            {"index":13, "libelle":"+60 ans", "libelleCourt":"+60", "age":{"min":60, "max":99999}},
        ];


    }

    initialiser(objJson, abaqueEclaircies, repartitionBobi) {
        Object.assign(this, objJson);
        // accès direct
        abaqueEclaircies.forEach(ligneAbaque => {
            var circonference = "circonference" + ligneAbaque.circonference;
            abaqueEclaircies[circonference] = ligneAbaque;
        })
        this.abaqueEclaircies = abaqueEclaircies;
        // accès direct - utilité à valider ...
        repartitionBobi.forEach(ligneRepartition => {
            var vuMin = "vuMin" + (ligneRepartition.vuMin*10);
            repartitionBobi[vuMin] = ligneRepartition;
        })
        this.repartitionBobi = repartitionBobi;
    }


    associerClasseAge(age=-1) {
        var trouve = this.classesAge[0];

        for (let classeAge of this.classesAge) {
            if (age >= classeAge.age.min && age <= classeAge.age.max) {
                trouve = classeAge;
                break;
            }
        }
        return(trouve);
    }

}


export const SteresConstantes = new STERES_CONSTANTES()
