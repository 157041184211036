/****** PRINCIPES
-------------------------> Algorithme de calculs des HMAXs à reprendre
Par itération, on calcule le HMAX qui nous permet d'atteindre la première mesure.
Ce HMAX sera celui de toute les années jusqu'à cette mesure.

A/ il n'y a pas d'autres mesures
Le HMAX est conservé pour toute la croissance
NB :
- il est possible que cette unique mesure soit une éclaircie, on considère alors qu'en dépit de la marge d'erreur, ce HMAX calculé est préférable au HMAX par défaut du contexte.
- les éclaircies ne peuvent être prises en compte que si la hauteur est mesurée

B/ il existe d'autres mesures
On calcule les HMAX qui nous permettent de passer de mesure en mesure (formule)

Plusieurs règles :

a) le HMAX (valide) qui conduit à la dernière mesure est conservé pour toute la suite de la croissance
b) si l'écart entre deux mesures (mesure "m" et mesure précédente "m-1") est insuffisant :
 b1 - le HMAX est utilisé pour donner une croissance cohérente entre ces deux mesures
 MAIS il n'est pas conservé pour la suite de la croissance
 b2 - le HMAX utilisé est alors celui calculé entre la mesure "m" et la mesure "m-x". "m-x" étant la première mesure précédent "m" et qui répond à la condition d'éloignement temporel
 il est possible que "m-x" soit une éclaircie
c) pour donner une croissance cohérente à la suite d'une éclaircie, les éclaircies sont prises en compte
 MAIS
 uniquement comme "m-1" ou "m-x" (cf. discussion précédente)


Exemple de comportement attendu (cf. TEST 4 (HMAX 36,23) avec éclaircie en 2021 et pas 2020)
Soit une plantation réalisée à l'année A et des mesures et éclaircies successives M1, E1, M2, E2 et M3

1/ "A" à "M1" : le HMAX entre "A" et "M1"-1 est calculé par itération pour approcher la mesure M1
2/ "M1" à "E1" : pas calculé - à ce stade, report du HMAX précédent
3/ "E1" à "M2" : le HMAX entre "E1" et "M2"-1 est calculé par formule
il est appliqué entre les deux dates MAIS n'est pas valide (écart trop court)
on calcule donc "M1" à "M2" par formule
le HMAX obtenu est appliqué de "M1" à "E1" et potentiellement à partir de M2 (si dernière mesure)
4/ "M2" à "E2" : pas calculé - à ce stade, report du HMAX valide précédent ("M1" à "M2" visible entre "M1" et "E1")
5/ "E2" à "M3" : le HMAX entre "E2" et "M3"-1 est calculé par formule -
il s'agit du dernier HMAX valide qui est reporté pour toute la croissance

Critique
La proximité entre M2 et M1 qui sont séparés d'une éclaircie donne un HMAX top fort
Seules évolutions simples :
augmenter l'écart minimum pour considérer que deux mesures sont pertinentes
faire les reports vers l’arrière et pas vers l'avant ("M2" à "E2" serait reporté de "E2" à "M3" et pas de "E1" à "M2" - en l’occurrence "M1" à "M2")
En l’occurrence, je doute qu'on obtienne une solution idéale dans tous les cas, et on commence à frôler "l'usine à gaz" (attention à la maintenance longue)
FIN PRINCIPES ******/

import { SteresConstantes } from "./constantes.js"

//*********************************
class UF_PPL_DENDROMETRIQUE {
    constructor() {
        this.ppl = null;
        this.mesures = [];
        this.eclaircies = [];
        this.eclairciesAuto = [];
        this.tabValeursCalculeeS = []; // asymptote de la hauteur - on stocke un HMAX pour chaque hauteur car à chaque nouvelle mesure, on a un nouvel HMAX
                    // de plus, on va lisser les valeurs intermédiares pour lisser les autres calculs
        this.infosFinInstallation = null;
        this.ageDonneesValides = 0; // wiz
        this.donneesIncompletes = false;
        this.ready = false;
        this.calculerStock = false;

        var ddr0 = {"age":0,"qualification":"Q"}
        }


// ****************************************************************************************************************************************************************************
// NIVEAU 0 - Méthodes appelées par l'extérieur
//   a)  initialiserUfPeuplement - Initialisation de l'objet et calculs des résultats
//   b)  initialiserCourbes - Affichage de la courbe
// ****************************************************************************************************************************************************************************

//********************************* initialiserUfPeuplement ************************************
// initialise l'objet
// calcule la dendrométrie
// Paramètres :
// ppl : peuplement sur lequel on travaille - objet issu de "peuplementObj.js" concaténé des valeurs du peuplement lues en base de données
// params : paramètres supplémentaires
// ie.
// - "it": itinéraire technique sur lequel on calcule les valeurs
// - "calculOptimumFutur":true - booléen qui indique qu'on va ajouter les éclairices futures
// - "calculOptimumPasse":false, - caduque : permettait d'injecter des éclaircies dans le passé
// - "ageMaxTest":UfPeuplement1.essence.vieMax - nombres d'années calculées
    initialiserUfPeuplement(ppl, params) {

        // reinit
        this.mesures = [];
        this.eclaircies = [];
        this.eclairciesAuto = [];
        this.tabValeursCalculeeS = [];
        this.ageValeurDeStock = -1;

        // initialisation des paramètres
        this.ppl = ppl;
        this.tabValeursCalculeeS[0] = {};
        this.tabValeursCalculeeS[0].HMAX = ppl.HMAX; // par défaut, on travaille le HMAX du peuplement qui dépend de essences_cultures_terroirs
        this.calculOptimumFutur = params.calculOptimumFutur;
        this.calculOptimumPasse = false;

        // etat "steres" de la parcelle
        this.derniereInfoComplete = -1;
        this.derniereEclaircie = -1;
        this.etat = SteresConstantes.etatVALIDE; // a priori, c'est bon

        //this.calculOptimumPasse = params.calculOptimumPasse;
        /* non on remplace calculOptimumComplet par calculOptimumPasse
        if (this.calculOptimumComplet) {
            this.calculOptimumFutur = this.calculOptimumComplet; // si on fait le complet, on fait le futur
        }
        */
        // paramètrage selon IT
        var it = params.it
        if ((typeof(it) !== 'undefined') && it) {
            this.calculOptimumFutur = it.auto;
            this.ppl.it = it;
        }

        // raccourci pour gain de temps
        this.constantesSteres = ppl.uf.propriete.constantesSteres;

        // intégration des données saisies (il peut en manquer !)
        this.ageMaxTest = Math.trunc(params.ageMaxTest);
        this.dendrometrieConnue = this.initialiserHMAXsEtInstallation();


        // calcul de l'optimum jusqu'à l'année définie dans le jSon
        this.ageValeurDeStock = 0; // pour autoriser le calcul de la coupe rase "stock"
        this.calculerValeursGeometriques(this.ageMaxTest);

        // on a fait tous nos calcul, on peut "transmettre" l'état du peuplement
        this.finaliserEtat();

        this.ready = true;
        return(this);
    }


// ****************************************************************************************************************************************************************************
// initialiserCourbes - Affichage de la dendrométrie
// ****************************************************************************************************************************************************************************
    //initialiserCourbes = function(ageMax = this.tabValeursCalculeeS.length-1) {
    initialiserCourbes(ageMax = this.tabValeursCalculeeS.length-1) {

        this.ppl.essence.densiteMax = 1800;
        var hauteurMax = 0;
        var circonferenceMax = 0;
        var volumeMax = 0;
        var densiteMax = 0;

        var courbe = {"negatifPositif":0, "idCourbe":"d_", "debutValide":this.ageDonneesValides, "anneeCourante":this.ppl.anneeCourante, "anneeDuJour":this.ppl.anneeDateInstallation+this.ppl.anneeCourante-1,
                      "infos" : {"titre":"Evolution par années", "classe":"multi", "axeX":"Age", "donneesX":"ans", "intervalleX":10, "debutX":0, "minY":0},
                      "infosY" : [
                          {"libelleAxeY":"Hauteur", "uniteLibelleAxeY":"(en m)", "xAxeY":"gauche", "donneesY":"m", "intervalleY":5, "max":this.ppl.essence.hauteurMax, "min":0}, {"libelleAxeY":"Circonférence", "uniteLibelleAxeY":"(en cm)",  "xAxeY":"gauche", "donneesY":"cm", "intervalleY":20, "max":this.ppl.essence.circonferenceMax, "min":0},{"libelleAxeY":"Volume unitaire", "uniteLibelleAxeY":"(en m3)", "xAxeY":"droite", "donneesY":"m3", "intervalleY":0.2, "max":this.ppl.essence.volumeMax, "min":0}, {"libelleAxeY":"Densité", "uniteLibelleAxeY":"(en t/ha)",  "xAxeY":"droite", "donneesY":"t/ha", "intervalleY":300, "max":this.ppl.essence.densiteMax, "min":0, "nonNul":true}
                      ],
                      "datas": []}; // todo MAXs en base
        if (this.ppl.it) {
            courbe.infos.titre = this.ppl.it.identifiant;
            this.coupeRase = this.ppl.it.coupeRase;
            courbe.coupeRase = this.coupeRase;
        }

        //courbe.debutValide = this.ageDonneesValides;
        //courbe.anneeCourante = this.ppl.anneeCourante;

        for(var cptAns = 0; cptAns <= ageMax; cptAns++) {
            var item = {};
            item.coupeRase = false;
            var items = [];
            item.itemsY = items;
            items[0] = {};
            items[1] = {};
            items[2] = {};
            items[3] = {};
            item.x = cptAns;

            // hauteur
            var hauteur = this.arrondir(this.tabValeursCalculeeS[cptAns].hauteur);
            /*if (this.tabValeursCalculeeS[cptAns].hauteurAvantEclaircie > 0) { // s'il y a une eclaircie, on affiche la "vrai" hauteur avant l'éclaircie
                hauteur = this.arrondir(this.tabValeursCalculeeS[cptAns].hauteurAvantEclaircie);
            }*/
            items[0].y = hauteur;
            if (items[0].y < 0) {
                items[0].yinconnu = true;
            }
            hauteurMax = Math.max(hauteurMax, hauteur)

            // circonférence
            var circonference = this.tabValeursCalculeeS[cptAns].circonference*100;
            /*if (this.tabValeursCalculeeS[cptAns].circonferenceAvantEclaircie > 0) { // s'il y a une eclaircie, on affiche la "vrai" circonference avant l'éclaircie
                circonference = this.tabValeursCalculeeS[cptAns].circonferenceAvantEclaircie*100;
            }*/
            items[1].y = this.arrondir(Math.max(-1, circonference));
            if (items[1].y < 0) {
                items[1].yinconnu = true;
            }
            circonferenceMax = Math.max(circonferenceMax, circonference)

            // volume unitaire
            var volumeUnitaire = this.tabValeursCalculeeS[cptAns].volumeUnitaire
            items[2].y = this.arrondir(Math.max(-1, volumeUnitaire));
            if (items[2].y < 0) {
                items[2].yinconnu = true;
            }
            volumeMax = Math.max(volumeMax, volumeUnitaire)

            // densité
            var densite = this.tabValeursCalculeeS[cptAns].densite
            items[3].y = this.arrondir(Math.max(-1, densite), 0);
            if (items[3].y < 0) {
                items[3].yinconnu = true;
            }
            densiteMax = Math.max(densiteMax, densite)


            // est-ce qu'on a une mesure ou une eclaircie sur l'année
            item.mesure = false;
            if (typeof(this.mesures[cptAns]) !== 'undefined') {
            item.mesure = true;
            }
            item.eclaircie = false;
            if (typeof(this.eclaircies[cptAns]) !== 'undefined') {
            item.eclaircie = true;
            }

            var anneeReelle = cptAns;
            if (this.coupeRase && (anneeReelle == this.coupeRase)) {
                item.coupeRase = true;
                this.ppl.it.volumeCoupeRase = this.tabValeursCalculeeS[cptAns].volume;
                this.ppl.it.volumeUnitaireCoupeRase = this.tabValeursCalculeeS[cptAns].volumeUnitaire;
            }

            courbe.datas[cptAns] = item;
        }

        courbe.infosY[0].max = Math.min(hauteurMax, this.ppl.essence.hauteurMax);
        courbe.infosY[1].max = Math.min(circonferenceMax, this.ppl.essence.circonferenceMax);
        courbe.infosY[2].max = Math.min(volumeMax, this.ppl.essence.volumeMax);
        courbe.infosY[3].max = Math.min(densiteMax, this.ppl.essence.densiteMax);

        return(courbe);
    }


// ****************************************************************************************************************************************************************************
// NIVEAU 1 - Prise en compte des paramètres "historiques" du peuplement : Mesures et eclaircies
// et prospectifs : Its
// ****************************************************************************************************************************************************************************

//*********************************
// récupération de ppl.mesures, ppl.eclaircies, ppl.it.eclaircies
    initialiserHMAXsEtInstallation(datas = null) {

        if (!datas) {
            this.calerPeuplement();
            // initialisation d'une année et hauteur de fin d'installation (ici à partir du Hmax  standard)
            this.initialiserInfosInstallation();

                // injection des mesures et eclaircies du peuplement
            var cptMesuresEtEclaircies = 0;
            var premiereMesure = true;
            var premiereEclaircie = true;
            var derniereEclaircie = this.derniereEclaircieValide();
            var approximerEclaircie = false;
            while (this.ppl.mesures[cptMesuresEtEclaircies] || (this.ppl.eclaircies[cptMesuresEtEclaircies] && !this.calculOptimumPasse)) {
                if (this.ppl.mesures[cptMesuresEtEclaircies]) {
                    this.ajouterMesure(this.ppl.mesures[cptMesuresEtEclaircies], premiereMesure, (cptMesuresEtEclaircies == this.ppl.mesures.length-1)) ;
                    premiereMesure = false;
                }
                if (this.ppl.eclaircies[cptMesuresEtEclaircies] && !this.calculOptimumPasse) {
                    approximerEclaircie = this.ajouterEclaircie(this.ppl.eclaircies[cptMesuresEtEclaircies], premiereEclaircie, (cptMesuresEtEclaircies == derniereEclaircie)) ;
                    premiereEclaircie = false;
                }
                cptMesuresEtEclaircies++;
            }

            this.initialiserDernieresInfos();

            var eclairciesIt = [];
            if (this.ppl.it && this.ppl.it.eclaircies) {
                eclairciesIt = this.ppl.it.eclaircies;
            }
            cptMesuresEtEclaircies = 0;
            var it = this.ppl.it;
            while (eclairciesIt[cptMesuresEtEclaircies]) { // wiz
                if (eclairciesIt[cptMesuresEtEclaircies]) {
                    var anneeEclaircie = parseInt(eclairciesIt[cptMesuresEtEclaircies].dateEclaircie);
                    if (anneeEclaircie && (anneeEclaircie >= this.anneeMinAcceptable(this.constantesSteres.moisBascule))) {
                        this.ajouterEclaircie(eclairciesIt[cptMesuresEtEclaircies], false, false) ;
                    }
                }
                cptMesuresEtEclaircies++;
            }


            // on s'assure que tous les HMAX sont bons jusqu'à la fin
            this.calculerHMAXs(this.ageMaxTest, approximerEclaircie);
            // et on recalcule à partir de ces HMAX
            this.initialiserInfosInstallation();

            return(true);

        }
    }


// ****************************************************************************************************************************************************************************
// NIVEAU 2 - Calculs de la dendrométrie
// dendrométrie avant installation (il faut fixer la fin d'installation)
// calculs des valeurs post installation
// ****************************************************************************************************************************************************************************

//********************************* à partir du HMAX : obtenir une année de fin d'installation et une hauteur de fin d'installation
// calcul des valeurs avant la fin de l'installation
	initialiserInfosInstallation = function(initHMAX = 0) {
        if (this.tabValeursCalculeeS.length < 1) {
console.log("initialiserInfosInstallation : pb HMAX 0 ");
            return(0);
        }
        var essence = this.ppl.essence;
        var age = 0;
        var HMAX=initHMAX;
        var hauteurFinInstallation = 0;
        var hauteurCalculee = 0;
        var hauteurStock = 0;
        var hauteurUtilisee = 0;
        var dataFinInstallation = {"hauteur":0, "age":0};

        // année 0 à 0
        this.initialiserValeurCalculeeHauteur(0, 0);
        this.initialiserValeurCalculeeDensite(0, this.ppl.calculerDensite());

        // on boucle jusqu'à atteindre la hauteur
        while (1) {
            if (!initHMAX && (typeof(this.tabValeursCalculeeS[age]) !== 'undefined') && (typeof(this.tabValeursCalculeeS[age].HMAX) !== 'undefined')) {
                HMAX = this.tabValeursCalculeeS[age].HMAX;
            }
            else {
                this.initialiserValeurCalculeeHMAX(age, HMAX);
            }


            // attention si on a une mesure, c'est elle qu'on utilise pour l'année d'après
            var hauteurMesuree = this.integrerMesuresAnnee(age);
            if (hauteurMesuree) {
                 hauteurCalculee = hauteurMesuree;
            }

            // accroissement
            var iH = essence.aInstallation * HMAX;
            // hauteur = hauteur précédente + accroissement
            hauteurCalculee += iH;
            var hauteurCalculeeStock = hauteurCalculee;


            // en cas d'eclaircie, la nouvelle hauteur remplacera la hauteur calculée
            this.anticiperPourEclaircie(age, hauteurCalculee);
            var hauteurSuiteEclaircie = this.gererEclaircies(age);
            if (hauteurSuiteEclaircie) {
                // hauteur suite à l'éclaircie
                this.initialiserValeurCalculeeHauteurAvantEclaircie(age, hauteurCalculee-iH);
                hauteurCalculee = hauteurSuiteEclaircie + iH;
            }


            // hauteur de stock - ne prends pas en compte les éclaircies futures
            if (this.calculerStock) { // JAMAIS
                if (this.estFutur(age+1)) {
                    hauteurStock += iH; // données futures sans éclaircies (PS : on ne peut pas avoir de mesure postérieure à la date actuelle)
                    this.initialiserValeurCalculeeHauteurStock(age+1, hauteurStock);
                }
                else {
                    hauteurStock = hauteurCalculee; // données actuelles du peuplement (donc issues du passé)
                    this.initialiserValeurCalculeeHauteurStock(age+1, hauteurStock);
                }
            }

            // on stocke la hauteur
            this.initialiserValeurCalculeeHauteur(age+1, hauteurCalculee);


            // on peut maintenant connaitre la densite
            var densite = this.calculerDensite(age);

            // densite de stock - ne prends pas en compte les éclaircies futures
            if (this.calculerStock) { // JAMAIS
                if (this.prospective(age+1)) {
                    var densiteStock = this.calculerDensiteStock(age);
                    this.initialiserValeurCalculeeDensiteStock(age+1, densiteStock);
                }
            }

            // on stocke la densite
            this.initialiserValeurCalculeeDensite(age+1, densite);


            age++;

            // sorties si installation finie ou soupcon de boucle infinie (bug)
            if (this.finInstallation(hauteurCalculee)) {
                break;
            }
            if (age == 100) {
console.log("initialiserInfosInstallation : boucle infinie ");
                break;
            }

        }

        // on ajoute le HMAX de ageMax / le 28/04/2020 - jusqu'au bout
        // this.initialiserValeurCalculeeHMAX(age, HMAX);
        for(var cptAnsBoucle = age; cptAnsBoucle < this.ageMaxTest; cptAnsBoucle++) {       // les derniers HMAX sont identiques à celui de la dernière mesure
            this.initialiserValeurCalculeeHMAX(cptAnsBoucle, HMAX);
        }

        dataFinInstallation.hauteur = hauteurCalculee;
        dataFinInstallation.age = age;

        this.infosFinInstallation = dataFinInstallation;

		return(this.infosFinInstallation);
	}

//**********************************************************
// calculerValeursGeometriques
// param : année
// calcule et stocke les valeurs dendrométriques à partir du tableau des HMAXs
//*********************************
	calculerValeursGeometriques = function(ageMax = -1) {

        this.ageValeurDeStock = 0; // important pour reinitialiser le calcul

        var essence = this.ppl.essence;
        if (ageMax == -1) {
            ageMax = this.ppl.essence.vieMax;
        }

        if ((typeof(this.tabValeursCalculeeS[this.infosFinInstallation.age]) === 'undefined') || (typeof(this.tabValeursCalculeeS[this.infosFinInstallation.age]).HMAX === 'undefined')) {
console.log("calculerValeursGeometriques : pb initialisation ");
            }

        var age = this.infosFinInstallation.age;
        var HMAX = this.tabValeursCalculeeS[age].HMAX;

        for(var cptAns = this.infosFinInstallation.age; cptAns < ageMax; cptAns++) {

            // travailler sur le bon HMAX
            if ((typeof(this.tabValeursCalculeeS[cptAns]) !== 'undefined') && (typeof(this.tabValeursCalculeeS[cptAns]).HMAX !== 'undefined')) {
                HMAX = this.tabValeursCalculeeS[cptAns].HMAX;
            }
            else {
                this.initialiserValeurCalculeeHMAX(cptAns, HMAX);
            }

            // attention si on a une mesure, c'est elle qu'on utilise pour l'année d'après
            this.integrerMesuresAnnee(cptAns);

            // accroissement hauteur
            var hauteurPred = this.tabValeursCalculeeS[cptAns].hauteur;
            var hauteurCalculee = this.calculerHauteur(HMAX, hauteurPred, essence); // BC 202102 Création de "calculerHauteur"
            /*
            var iH = essence.a * (HMAX - hauteurPred);
            var hauteurCalculee = hauteurPred+iH;
            */


            if (this.calculerStock) { // JAMAIS
                var hauteurPredStock = this.tabValeursCalculeeS[cptAns].hauteurStock;
                if (typeof(hauteurPredStock) === 'undefined') {
                    hauteurPredStock = hauteurPred;
                }
                var iHstock = essence.a * (HMAX - hauteurPredStock);
            }


            // en cas d'eclaircie, la nouvelle hauteur remplacera la hauteur calculée
            this.anticiperPourEclaircie(cptAns, hauteurCalculee); // wizwiz
            var hauteurSuiteEclaircie = this.gererEclaircies(cptAns);
            if (hauteurSuiteEclaircie) {
                hauteurCalculee = this.calculerHauteur(HMAX, hauteurSuiteEclaircie, essence); // BC 202102 Création de "calculerHauteur"
                /*
                var iH = essence.a * (HMAX - hauteurSuiteEclaircie);
                hauteurCalculee = hauteurSuiteEclaircie+iH;
                */
                // hauteur en l'absence d'eclaircie
                this.initialiserValeurCalculeeHauteurAvantEclaircie(cptAns, hauteurPred);
            }

            // hauteur de stock - ne prends pas en compte les éclaircies futures

            if (this.calculerStock) { // JAMAIS
                // if (this.estFutur(cptAns+1)) {
                if (1) {
                    var hauteurStock = hauteurPredStock+iHstock; // données futures sans éclaircies (PS : on ne peut pas avoir de mesure postérieure à la date actuelle)
                    this.initialiserValeurCalculeeHauteurStock(cptAns+1, hauteurStock);
                }
            }


            // hauteur (et circonférence)
            this.initialiserValeurCalculeeHauteur(cptAns+1, hauteurCalculee);


            // on peut maintenant connaitre la densite
            var densite = this.calculerDensite(cptAns);


            if (this.calculerStock) { // JAMAIS
                // densite de stock - ne prends pas en compte les éclaircies futures
                if (this.prospective(cptAns+1)) {
                    var densiteStock = this.calculerDensiteStock(cptAns);
                    this.initialiserValeurCalculeeDensiteStock(cptAns+1, densiteStock);
                }
            }

            // on stocke la densite
            this.initialiserValeurCalculeeDensite(cptAns+1, densite);
        }
        // on ajoute le HMAX de ageMax
        this.initialiserValeurCalculeeHMAX(cptAns, HMAX);

    }



// ****************************************************************************************************************************************************************************
// NIVEAU 1.1 - Fonctions nécessaires à l'initialisation des paramètres
// ****************************************************************************************************************************************************************************

//*********************************
//  calerPeuplement : Mise en cohérence des valeurs initiales de TEST et des valeurs stockées en BD (il s'agit juste du nom des variables)
//*********************************
    calerPeuplement() {

        // calage pour fonctionnement hors tests ...
        if (typeof(this.ppl.anneeRetenue) !== 'undefined') {
            this.ppl.anneeDateInstallation = this.ppl.anneeRetenue;
        }
        if (typeof(this.ppl.densiteInstallation) !== 'undefined') {
            this.ppl.densite = this.ppl.densiteInstallation;
        }
        if ((typeof(this.ppl.uf) !== 'undefined') && (typeof(this.ppl.uf.surface) !== 'undefined')) {
            this.ppl.surfaceUF = this.ppl.uf.surface;
        }

        var ladate=new Date();
        this.ppl.anneeCourante = ladate.getFullYear() - this.ppl.anneeDateInstallation + 1;
    }

//*********************************
//  derniereEclaircieValide : retourne la dernière éclaircie réalisée sur le peuplemnt
//*********************************
    derniereEclaircieValide() {
        var ok = -1;
        this.ppl.eclaircies.forEach((eclaircie, index) => {
            if (eclaircie.hauteurMoyenne) {
                ok = index
            }
        })
        return(ok);
    }

//*********************************
//  ajouterMesure : injection d'une mesure du peuplement
//*********************************
    ajouterMesure = function(mesureJson, premiereMesure, derniereMesure) {


        // calage pour fonctionnement hors tests ...
        if (typeof(mesureJson.dateMesure) !== 'undefined') {
            mesureJson.anneeDate = this.anneeRetenue(mesureJson.dateMesure);
            mesureJson.age = mesureJson.anneeDate - this.ppl.anneeDateInstallation;
        }
        if (typeof(mesureJson.hauteurMoyenne) !== 'undefined') {
            mesureJson.hauteur = mesureJson.hauteurMoyenne;
        }
        if (typeof(mesureJson.circonferenceQuadratique) !== 'undefined') {
            mesureJson.circonference = mesureJson.circonferenceQuadratique/100;
        }
        if (typeof(mesureJson.densiteMoyenne) !== 'undefined') {
            mesureJson.densite = mesureJson.densiteMoyenne;
        }


        if (mesureJson.age == -1) {
            mesureJson.age = mesureJson.anneeDate - this.ppl.anneeDateInstallation;
        }

        mesureJson.annee = mesureJson.age+1; // notre tableau commence à 0 ce qui correspond à l'age et pas aux années
        var mesure = mesureJson;
        this.mesures[mesure.age] = mesure;

        // on recalcule les HMAX
        if (derniereMesure) { // pour ne pas recalculer tout à chaque mesure
            this.calculerHMAXs(mesure.age, false);
        }


        // et si la mesure appartient à la période d'installation, on recalcule les infos finales de cette dernière
        if (mesure.age < this.infosFinInstallation.age || premiereMesure) {
            var i=9;
            var HMAX = this.tabValeursCalculeeS[0].HMAX;
            while (i--)  { // on itère pour ce rapprocher de la bonne valeur de départ ...
                HMAX = (HMAX+this.calculerHMAX(mesure, null))/2;
                this.infosFinInstallation = this.initialiserInfosInstallation(HMAX);
            }

            // et on reinitialise les HMAX
            if (derniereMesure) { // pour ne pas recalculer tout à chaque mesure
                this.calculerHMAXs(mesure.age, false);
                //this.initialiserInfosInstallation(); // test 2021/10
            }
        }
        return;
    }


//*********************************
//  ajouterEclaircie : injection d'une eclaircie du peuplement
//*********************************
    ajouterEclaircie = function(eclaircieJson, premiereEclaircie, derniereEclaircie = false) {

        // 2020/06
        if (typeof(eclaircieJson.itId) !== 'undefined') {
        if (eclaircieJson.anneeRetenue < this.anneeMinAcceptable(this.constantesSteres.moisBascule)) {
            this.pbEclaircieIT = true;
            this.eclaircieJson.pbEclaircieIT = true;
            return false;
            }
        }

        var approximerEclaircie = false;

        // calage pour fonctionnement hors tests ...
        if (typeof(eclaircieJson.dateEclaircie) !== 'undefined') {
            eclaircieJson.anneeDate = this.anneeRetenue(eclaircieJson.dateEclaircie);
            eclaircieJson.age = eclaircieJson.anneeDate - this.ppl.anneeDateInstallation;
        }
        if (typeof(eclaircieJson.densiteMoyenne) !== 'undefined') {
            eclaircieJson.densiteApres = eclaircieJson.densiteMoyenne;
        }


        eclaircieJson.annee = eclaircieJson.age+1; // notre tableau commence à 0 ce qui correspond à l'age et pas aux années
        var eclaircie = eclaircieJson;
        this.eclaircies[eclaircie.age] = eclaircie;

        // on calcule les HMAX sur l'éclarcie si c'est la dernière et quelle est postérieure à la dernière mesure et quelle a une hauteur !
        if (derniereEclaircie
            && (this.ppl.mesures.length == 0) /* || (this.ppl.mesures[this.ppl.mesures.length-1].age < eclaircie.age)*/
            && (this.infosFinInstallation.age < eclaircie.age)) {
                approximerEclaircie = true;
                this.calculerHMAXs(eclaircie.age, approximerEclaircie);

        }


        // si l'eclaircie appartient à la période d'installation, on recalcule les infos finales de cette dernière
        if (eclaircie.age < this.infosFinInstallation.age) {
            this.infosFinInstallation = this.initialiserInfosInstallation();
            return(false);
        }

        return(approximerEclaircie);
    }



// ****************************************************************************************************************************************************************************
// NIVEAU 2.1 - Calculs de la dendrométrie
// fonctions supplémentaires
// ****************************************************************************************************************************************************************************

//*********************************
// calculerHMAXs : itération pour trouver les HMAXs cohérents avec l'ensemble des données disponibles (mesures - éclaircies)
//*********************************
	calculerHMAXs = function(ageMax, approximerSurEclaircie) {

        var ageMesurePred = -1;
        var mesurePred = null;
        var mesurePredValide = true;
        var HMAX = 0;
        var premiereMesure = true; // si on est sur la première mesure - on modifie le HMAX de l'installation pour ne pas rester sur le HMAX standard

        if (this.donneesIncompletes) { // NB : en données incomplètes - on a déjà recalculé ce HMAX
            premiereMesure = false;
        }

        // 1/ on calcule les HMAX en fonction des mesures
        for(var cptAns = 0; cptAns < ageMax; cptAns++) {
            var mesure = null;
            // la mesure peut être une mesure OU une eclaircie
            if (this.mesures[cptAns]) {
                mesure = this.mesures[cptAns];
            }
            else {
                if (this.eclaircies[cptAns] && this.eclaircies[cptAns].hauteurMoyenne) {
                    if (approximerSurEclaircie) {
                        // à part ici, on n'utilise pas la mesure car on ne connais pas la valeur "avant eclaircie"
                        mesure = this.eclaircies[cptAns];
                        mesure.hauteur = mesure.hauteurMoyenne;
                        mesure.eclaircie = true;
                    }
                    else {
                        // on conserve le marqueur sur notre mesure pour un prochain lissage
                        // if (ageMesurePred > 0) {
                        if (cptAns > this.infosFinInstallation.age) {
                            ageMesurePred = cptAns;
                            mesurePred = this.eclaircies[cptAns];
                            mesurePred.hauteur = mesurePred.hauteurMoyenne;
                        }
                        continue;
                    }
                }
            }
            if (mesure) {
                if (cptAns > this.infosFinInstallation.age) {
                    if (!premiereMesure) {
                        ageMesurePred = Math.max(ageMesurePred, this.infosFinInstallation.age);
                    }
                }


                HMAX = this.calculerHMAX(mesure, mesurePred);
                // reporting
                var HMAXBoucle = HMAX
                for(var cptAnsBis = cptAns-1; cptAnsBis >= ageMesurePred; cptAnsBis--) {
                    this.initialiserValeurCalculeeHMAX(cptAnsBis, HMAXBoucle);
                }


// mais la mesure est-elle valide, sinon on va chercher la précédente, s'il y en a ...
        if (mesurePred) {
            var nouvelleMesurePred = mesurePred;
            mesurePredValide = false;
            while (!mesurePredValide) {
                mesurePredValide = this.validerMesurePred(mesure, nouvelleMesurePred);
                if (!mesurePredValide) {
                    var cpt = 0;
                    for(cpt = nouvelleMesurePred.age-1; cpt >= this.infosFinInstallation.age; cpt--) {
                        if (this.mesures[cpt]) {
                            nouvelleMesurePred = this.mesures[cpt];
                            break;
                        }
                        if (this.eclaircies[cpt] && this.eclaircies[cpt].hauteurMoyenne) {
                            nouvelleMesurePred = this.eclaircies[cpt];
                            nouvelleMesurePred.hauteur = mesurePred.hauteurMoyenne;
                            break;
                        }
                    }
                    if (cpt < this.infosFinInstallation.age) {
                        break;
                    }
                }
            }
            if (nouvelleMesurePred.age != mesurePred.age) {
                HMAX = this.calculerHMAX(mesure, nouvelleMesurePred);
                ageMesurePred = nouvelleMesurePred.age;
                // reporting
                var HMAXBoucle = HMAX
                for(var cptAnsBis = mesurePred.age-1; cptAnsBis >= ageMesurePred; cptAnsBis--) {
                    this.initialiserValeurCalculeeHMAX(cptAnsBis, HMAXBoucle);
                }
                // puis jusqu'à la fin
                for(var cptAnsBis = mesure.age; cptAnsBis < this.ageMaxTest; cptAnsBis++) {       // les derniers HMAX sont identiques à celui de la dernière mesure
                    this.initialiserValeurCalculeeHMAX(cptAnsBis, HMAX);
                }
            }
        }


                // on conserve le marqueur sur notre mesure pour un prochain lissage
                ageMesurePred = cptAns;
                mesurePred = mesure;
            }
        }


        if ((ageMesurePred > 0) && mesurePredValide) {
            for(var cptAns = ageMesurePred; cptAns < this.ageMaxTest; cptAns++) {       // les derniers HMAX sont identiques à celui de la dernière mesure
                    this.initialiserValeurCalculeeHMAX(cptAns, HMAX);
            }
        }

		return(this.tabValeursCalculeeS);
	}


    //*********************************
	calculerHMAX = function(mesure, mesurePred) {
        var HMAX = 0;
        var age = mesure.age;
        if (age <= this.infosFinInstallation.age) { // IH	=	a'	*	HMAX => linéaire
            HMAX = mesure.hauteur/age/this.ppl.essence.aInstallation;
        }
        else {
            if (!mesurePred) {
                var calcIntermediare = Math.pow(1-this.ppl.essence.a, age-this.infosFinInstallation.age);
                calcIntermediare = ((mesure.hauteur-this.infosFinInstallation.hauteur)/(1-calcIntermediare))
                HMAX = calcIntermediare+this.infosFinInstallation.hauteur;
            }
            else {
                var hauteur = mesure.hauteur;
                var anneeDate = mesure.anneeDate;
                HMAX = ((hauteur-mesurePred.hauteur)/(1-Math.pow(1-this.ppl.essence.a, anneeDate-mesurePred.anneeDate)))+mesurePred.hauteur;
            }
        }

        //this.initialiserValeurCalculeeHMAX(age, HMAX);
        //this.initialiserValeurCalculeeHMAX(age-1, HMAX);
        return(HMAX);
    }


// ****************************************************************************************************************************************************************************
// NIVEAU 2.2 - Dendrométrie suite
// Gestion des éclaircies - REELLES (intégrées) - VIRTUELLES (proposées)
// ****************************************************************************************************************************************************************************

    //*********************************
    // changement de l'état du peuplement    (valide / validé par utilisateur / à valider / invalide)
    /*
        this.etatVALIDE = 1;
        this.etatVALIDEUTILISATEUR = 2;
        this.etatAVALIDER = 3;
        this.etatINVALIDE = 4;
    */
    indiquerEtatAValider() {
        if (this.etat == SteresConstantes.etatVALIDE) {
            this.etat = SteresConstantes.etatAVALIDER;
        }
    }
    indiquerEtatInvalide() {
        this.etat = SteresConstantes.etatINVALIDE;
    }
    finaliserEtat() { // TODO : finaliser selon l'état "utilisateur"

        // cas particuliers où on modifie l'état utilisateur :
        /*
        ** STERES peut modifier automatiquement les états choisis par l'utilisateur UNIQUEMENT dans quatre cas précis :
        • un peuplement est devenu invalide
        • à l'origine le peuplement était "à valider" + "validé" puis il est devenu "valide" + "validé" puis rechange à nouveau en "à valider" + "validé" : dans ce cas STERES change le peuplement de "validé" en "à valider"
        • à l'origine le peuplement était "invalidé" puis il devient "valide" + "invalidé" : dans ce cas STERES change le peuplement de "invalidé" en "validé"
        • à l'origine le peuplement était "invalide" + "invalidé" puis il devient "à valider" + "invalidé" : dans ce cas STERES change le peuplement de "invalidé" en "à valider"
        */
        if (this.etat == SteresConstantes.etatINVALIDE) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
        if ((this.etat == SteresConstantes.etatAVALIDER) && (this.ppl.uf.peuplement.etatSteres == SteresConstantes.etatVALIDE) && (this.ppl.uf.peuplement.etatUtilisateur == SteresConstantes.etatVALIDE) ) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
        if ((this.etat == SteresConstantes.etatVALIDE) && (this.ppl.uf.peuplement.etatUtilisateur != SteresConstantes.etatVALIDE) ) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
        if ((this.etat == SteresConstantes.etatAVALIDER) && (this.ppl.uf.peuplement.etatSteres == SteresConstantes.etatINVALIDE) && (this.ppl.uf.peuplement.etatUtilisateur == SteresConstantes.etatINVALIDE) ) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }

        this.ppl.uf.peuplement.etatSteres = this.etat;

        // si on n'a pas encore d'état utilisateur, c'est celui de STERES
        if (!this.ppl.uf.peuplement.etatUtilisateur) {
            this.ppl.uf.peuplement.etatUtilisateur = this.etat;
        }
    }

    //*********************************
    initialiserDernieresInfos() {

        var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
        var existeMesure = Math.max(0, this.mesures.length-1);
        var existeEclaircieValide = 0;
        var i = this.eclaircies.length;
        while (i) {
            i--;
            if (this.eclaircies[i]  && this.eclaircies[i].hauteurMoyenne && this.eclaircies[i].anneeDate < anneeMinAcceptable)
                {
                    existeEclaircieValide = i;
                    break;
                }
        }
        this.derniereInfoComplete = Math.max(existeMesure, existeEclaircieValide);
        this.derniereEclaircie = Math.max(0, this.eclaircies.length-1);

        if (!this.derniereInfoComplete && ! this.ppl.densiteInstallation) {
            this.indiquerEtatInvalide();
        }

        // pareil si le peuplement est trop ancien
        if (this.ppl.anneeCourante > this.ppl.essence.vieMax)  {
            this.indiquerEtatInvalide();
        }

    }

    //*********************************
    derniereInfoValidePassee(cptAns) {
        if ((this.derniereInfoComplete < 0) || (this.derniereEclaircie< 0)) {
            return(false);
        }
        else {
            if (cptAns > this.derniereInfoComplete) { // on depasse la dernière info complète
                if ((this.derniereInfoComplete || this.ppl.densiteInstallation) && (cptAns <= this.derniereEclaircie)) { // mais pas la dernière eclaircie incomplète qui suit des infos valides !
                    return(false);
                }
                else {
                    this.toto = 5;
                    return(true);
                }
            }
            else {
                return(false);
            }
        }
    }

    //*********************************
    // en cas d'eclaircie, la nouvelle hauteur remplacera la hauteur calculée
	gererEclaircies = function(cptAns) {
        var ageReel = cptAns;
        var hauteurSuiteEclaircie = 0;

        // 2020/05
        if (!ageReel) {
            this.tabValeursCalculeeS[cptAns].cumulVolumeRecolteEclaircie = 0;
        }
        else {
            this.tabValeursCalculeeS[ageReel].cumulVolumeRecolteEclaircie = this.tabValeursCalculeeS[ageReel-1].cumulVolumeRecolteEclaircie;
        }

        if (ageReel < this.ppl.anneeCourante-1) {
            /*
            var derniereEclairciePassee = (cptAns >= this.eclaircies.length);
            if (this.calculOptimumPasse && derniereEclairciePassee) {
                if (this.infosFinInstallation && (ageReel > this.infosFinInstallation.age)) { // rien l'année 0
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 0);
                }
            }
            */
            if (this.derniereInfoValidePassee(cptAns)) {
                if (this.infosFinInstallation) { // rien l'année 0
                    if (this.proposerEclaircieAnnee(ageReel, 0)) {
                        this.indiquerEtatAValider();
                    }
                }
            }
            else {
                hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel);
            }
        }
        else {
            if (ageReel == this.ppl.anneeCourante-1) {
                hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel);
                var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
                var anneeReelle = ageReel+ this.ppl.anneeRetenue;
                if (!hauteurSuiteEclaircie && this.calculOptimumFutur && (anneeReelle>=anneeMinAcceptable)) { // TODO si après mois de juin, ça n'est plus possible (wiz) - this.anneeMinAcceptable(this.constantesSteres.moisBascule)
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 1);
                }
            }
            else {
                if (this.calculOptimumFutur) {
                    hauteurSuiteEclaircie = this.proposerEclaircieAnnee(ageReel, 1);
                }
                else {
                    hauteurSuiteEclaircie = this.integrerEclaircieAnnee(ageReel);
                }
            }
        }

        // s'il y a eu une eclaircie, le calcul devient inutile ... ??????
        // s'il y a eu une eclaircie, le calcul devient inutile ... on calcul la nouvelle densité
        var eclaircie = this.eclaircies[cptAns];
        if (typeof(eclaircie) !== 'undefined') {
            this.initialiserValeurCalculeeDensite(ageReel, eclaircie.densiteApres);
        }

        return(hauteurSuiteEclaircie);
    }


    //*********************************
    // est-ce que le rapport densité, circonférence nécessite une éclaircie ?
	proposerEclaircieAnnee = function(cptAns, integrerEclaircie) {

        if (cptAns+1 >= this.tabValeursCalculeeS.length) { // ne pas aller trop loin
            return(0);
        }

        var continuer = true;
        var densiteApres = 0;
        var densite = 0;
        var tarif = -1;
        // nouveaux paramètres : densité minimale à ne pas franchir
        var eclaircieDensiteMin = this.ppl.lireEclaircieDensiteMin();
        // volume minimum d'éclaircie (pourcentage de la densité initiale)
        var eclaircieVolumePourCentMin = this.ppl.lireEclaircieVolumePourCentMin();

        // cas particulier des SEMIS
        if ((this.ppl.cultureCode == "SEMIS") || (this.ppl.cultureCode == "SENAT")) {
            var tabDepressages = JSON.parse(this.ppl.essence.semisJson);
            tabDepressages.forEach(depressageSemis => {
                if (cptAns == depressageSemis.annee) {
                    densite = this.tabValeursCalculeeS[cptAns].densite;
                    //if ((densite > depressageSemis.densite) && ((depressageSemis.densite / densite * 100) >= eclaircieVolumePourCentMin)) {
                    if ((densite > depressageSemis.densite) && this.validerPourcent(densite, depressageSemis.densite, eclaircieVolumePourCentMin) ) {
                        densiteApres = depressageSemis.densite;
                        tarif = depressageSemis.tarif;
                        }
                }
            })
        }

        if (!densiteApres) { // on n'a pas déclenché l'éclaircie spécifique
                // demande CPFA de travailler sur l'anticipation des valeurs de fin d'année : "cptAns+1" à la place de "cptAns"
            //var circonferenceX = "circonference"+Math.trunc(this.tabValeursCalculeeS[cptAns].circonference*100); // en cm
            var circonferenceX = "circonference"+Math.trunc(this.tabValeursCalculeeS[cptAns+1].circonference*100); // en cm
            var eltAbaque = this.constantesSteres.abaqueEclaircies[circonferenceX];
            if (typeof(eltAbaque) === 'undefined') {
                 continuer = false;
             }
                // demande CPFA de travailler sur l'anticipation des valeurs de fin d'année : "cptAns+1" à la place de "cptAns"
            //var densite = this.tabValeursCalculeeS[cptAns].densite;
            densite = this.tabValeursCalculeeS[cptAns+1].densite;
            if (continuer && (densite < eltAbaque.densiteMax)) {
                continuer = false;
            }

            if (!continuer) {
                this.tabValeursCalculeeS[cptAns].eclaircieClass = false; // juste pour l'affichage
                this.tabValeursCalculeeS[cptAns].eclairciePropClass = false; // juste pour l'affichage
                this.tabValeursCalculeeS[cptAns].densiteEclaircie = 0;
                this.tabValeursCalculeeS[cptAns].surfaceTerriereEclaircie = 0;
                this.tabValeursCalculeeS[cptAns].volumeRecolteEclaircie = 0;
                this.tabValeursCalculeeS[cptAns].circonferenceAvantEclaircie = -1;
                return(0);
            }

            // utilisation des nouveaux paramètres
            densiteApres = eltAbaque.densiteMin;
            if (densiteApres < eclaircieDensiteMin) {
                densiteApres = eclaircieDensiteMin;
            }
            //if ( (densiteApres >= densite) || ((densiteApres / densite * 100) < eclaircieVolumePourCentMin) ) {
            if ( (densiteApres >= densite) || !this.validerPourcent(densite, densiteApres, eclaircieVolumePourCentMin) ) {
                return(0);
            }
            else {
                var debugOk = 1; // debug
            }
        }

        if (!integrerEclaircie) {
            return(1); // l'eclaircie ne doit pas être intégrée au tableau de valeur, on se contente de la détecter
        }

        var eclaircie = {};
        eclaircie.age = cptAns;
        eclaircie.densiteApres = densiteApres;
        eclaircie.densite = densite - eclaircie.densiteApres;
        eclaircie.proposition = true;
        if (tarif >= 0) {
            eclaircie.tarif = tarif;
        }
        this.eclaircies[eclaircie.age] = eclaircie;
        this.eclairciesAuto.push(eclaircie);

        this.tabValeursCalculeeS[cptAns].eclairciePropClass = true; // juste pour l'affichage

        return(this.integrerEclaircieAnnee(cptAns));
    }

//************************************
// l'éclaircie répond-telle à la règle : pourcentage minimum
//*********************************
    validerPourcent(avant, apres, min) {
        if (!avant) return false;
        var rapport = (avant-apres) / avant;
        if ((rapport*100) < min) return false;
        return true;
    }


//*******************************************
// anticiperPourEclaircie
// Suite à modification demande :
// les valeurs utilisées pour le déclenchement d'une éclairice à l'année N ne sont plus celles de l'année N mais celle de l'année N+1
// donc à chaque année N on calcule des valeurs virtuelles N+1 pour savoir si on déclenche une éclaircie
// si éclairice déclenchée : ces valeurs n'existeront jamais
//*********************************
	anticiperPourEclaircie = function(annee, hauteurCalculee) {

        var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);
        var anneeReelle = annee + this.ppl.anneeRetenue;
        //if ((anneeReelle < anneeMinAcceptable) && !this.calculOptimumPasse) {
        if ((anneeReelle < anneeMinAcceptable) && !this.derniereInfoValidePassee(annee)) {
            return; // on ne déclenche pas d'éclaircie
        }

        // hauteur (et circonférence)
        this.initialiserValeurCalculeeHauteur(annee+1, hauteurCalculee);

        // pour affichage DEBUG
        this.tabValeursCalculeeS[annee].anticipationHauteur = this.tabValeursCalculeeS[annee+1].hauteur;
        this.tabValeursCalculeeS[annee].anticipationCirconference = this.tabValeursCalculeeS[annee+1].circonference;

        // on peut maintenant connaitre la densite
        var densite = this.calculerDensite(annee);
        // on stocke la densite
        this.initialiserValeurCalculeeDensite(annee+1, densite);

        // pour affichage DEBUG
        this.tabValeursCalculeeS[annee].anticipationDensite = this.tabValeursCalculeeS[annee+1].densite;
    }

//************************************
// année minimum pour proposer une éclaircie
//*********************************
    anneeMinAcceptable(moisBascule) { // prise en compte de l'éclaircie aprés que le temps ... soit passé ...
        var lAnnee = new Date().getFullYear();
        var moisReel = new Date().getMonth()+1;
        if (moisReel >= moisBascule) {
            lAnnee += 1;
        }
        return lAnnee;
    }


/****************************************************************** Stockage dans la table des valeurs *************************************************************/
/***************************************************************** et calculs intermédiares ************************************************************************/

    //*********************************
	initialiserValeurCalculeeHMAX = function(age, HMAX) {
      /*  if (age == 50) {
      //      alert("toto");
        }*/
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }
        this.tabValeursCalculeeS[age].HMAX = HMAX;
    }


    //*********************************
    initialiserValeurCalculeeHauteurAvantEclaircie = function(age, hauteur) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }
        this.tabValeursCalculeeS[age].hauteurAvantEclaircie = hauteur;
    }
    //*********************************
    initialiserValeurCalculeeHauteurStock = function(age, hauteur) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }
        this.tabValeursCalculeeS[age].hauteurStock = hauteur;
    }


    //*********************************
	initialiserValeurCalculeeHauteur = function(age, hauteur) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }

        // 2020/05
        this.tabValeursCalculeeS[age].hauteurPrecedente = 0;
        this.tabValeursCalculeeS[age].HMAXPrecedent = this.tabValeursCalculeeS[age].HMAX;
        if (age) {
            this.tabValeursCalculeeS[age].hauteurPrecedente = this.tabValeursCalculeeS[age-1].hauteur;
        this.tabValeursCalculeeS[age].HMAXPrecedent = this.tabValeursCalculeeS[age-1].HMAX;
        }

        this.tabValeursCalculeeS[age].hauteur = hauteur;
        this.tabValeursCalculeeS[age].hauteurAvantEclaircie = -1;

        // et dans tous les cas, on recalcule la circonférence
        // ce qui veut dire qu'en cas de calcul "direct" de la circonférence (eclaircie) : il faut appeler "initialiserValeurCalculeeCirconference" en direct
        var croissanceCirconference = 0;
        var circonference = 0;
        if (age && (this.tabValeursCalculeeS[age-1].hauteur > this.ppl.essence.hauteurSeuilCirconference0) ) { // NB : sous la hauteur seuil, la circonférence est TOUJOURS nulle
            var croissanceHauteur = this.tabValeursCalculeeS[age].hauteur - this.tabValeursCalculeeS[age-1].hauteur;
            var croissanceCirconferencePotentielle = croissanceHauteur * this.ppl.essence.RACH;
            if (0) { // le test ci-dessous est abandonné JC DUPRAT - on considère qu'on est toujours en concurrence
            /*if (!this.infosFinInstallation || (typeof(this.infosFinInstallation.age) === 'undefined') || (age <= this.infosFinInstallation.age)) {*/
                croissanceCirconference = croissanceCirconferencePotentielle;
                circonference = this.tabValeursCalculeeS[age-1].circonference + croissanceCirconference
            }
            else { // TODO
                croissanceCirconference = croissanceCirconferencePotentielle * this.tabValeursCalculeeS[age-1].concurrence;
                circonference = this.tabValeursCalculeeS[age-1].circonference + croissanceCirconference
                if (this.tabValeursCalculeeS[age-1].concurrence == -1) {
                    circonference = -1;
                }
            }

            // calculs pour la valeur de stock
            if (this.calculerStock) { // JAMAIS
                if (this.prospective(age)) {
                    var croissanceCirconferenceStock = 0;
                    var circonferenceStock = 0;
                    var hauteurPredStock = this.tabValeursCalculeeS[age-1].hauteurStock;
                    if ((typeof(hauteurPredStock) === 'undefined')) {
                        hauteurPredStock = this.tabValeursCalculeeS[age-1].hauteur;
                        }
                    var croissanceHauteurStock = this.tabValeursCalculeeS[age].hauteurStock - hauteurPredStock;
                    var croissanceCirconferencePotentielleStock = croissanceHauteurStock * this.ppl.essence.RACH;
                    if (0) { // le test ci-dessous est abandonné JC DUPRAT - on considère qu'on est toujours en concurrence
                    //if (!this.infosFinInstallation || (typeof(this.infosFinInstallation.age) === 'undefined') || (age <= this.infosFinInstallation.age)) {
                        croissanceCirconferenceStock = croissanceCirconferencePotentielleStock;
                        circonferenceStock = this.tabValeursCalculeeS[age-1].circonference;
                        if ((typeof(circonferenceStock) === 'undefined')) {
                            circonferenceStock = this.tabValeursCalculeeS[age-1].circonference;
                            }
                        circonferenceStock += croissanceCirconference;
                    }
                    else { // TODO
                        var concurrenceStock = this.tabValeursCalculeeS[age-1].concurrenceStock;
                        if ((typeof(concurrenceStock) === 'undefined')) {
                            concurrenceStock = this.tabValeursCalculeeS[age-1].concurrence;
                            }
                        var circonferencePredStock = this.tabValeursCalculeeS[age-1].circonferenceStock;
                        if ((typeof(circonferencePredStock) === 'undefined')) {
                            circonferencePredStock = this.tabValeursCalculeeS[age-1].circonference;
                            }
                        croissanceCirconferenceStock = croissanceCirconferencePotentielleStock * concurrenceStock;
                        circonferenceStock = circonferencePredStock + croissanceCirconferenceStock
                        if (concurrenceStock == -1) {
                            circonferenceStock = -1;
                        }
                    }
                this.initialiserValeurCalculeeCirconferenceStock(age, circonferenceStock);
                }
            }
        }
        else {
            if (this.calculerStock) { // JAMAIS
                this.initialiserValeurCalculeeCirconferenceStock(age, 0);
            }
        }


        this.initialiserValeurCalculeeCirconference(age, circonference);

        // pour marquer l'année courante (on le fait ici car on calcule TOUJOURS la hauteur)
        this.tabValeursCalculeeS[age].anneeCouranteClass = false; // pour l'affichage
        if (age == this.ppl.anneeCourante-1) {
            this.tabValeursCalculeeS[age].anneeCouranteClass = true; // pour l'affichage
        }
    }

    //*********************************
	initialiserValeurCalculeeCirconferenceStock = function(age, circonference) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }
        this.tabValeursCalculeeS[age].circonferenceStock = circonference;

        // le volume unitaire est fonction de la hauteur et de la circonférence : V = (c² × H / 4π) * coef forme = volume cylindre  * coef forme
        if (circonference == -1) {
            this.tabValeursCalculeeS[age].volumeUnitaireStock = -1;
        }
        else {
            this.tabValeursCalculeeS[age].volumeUnitaireStock = (Math.pow(circonference, 2) * this.tabValeursCalculeeS[age].hauteurStock / (4 * this.constantesSteres.PI)) * this.ppl.essence.coefForme;
        }
    }

    //*********************************
	initialiserValeurCalculeeCirconference = function(age, circonference) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }

        // 2020/05
        this.tabValeursCalculeeS[age].circonferencePrecedente = 0;
        if (age) {
            this.tabValeursCalculeeS[age].circonferencePrecedente = this.tabValeursCalculeeS[age-1].circonference;
        }

        //this.tabValeursCalculeeS[age].circonference = this.arrondir(circonference);
        this.tabValeursCalculeeS[age].circonference = circonference;


        // le volume unitaire est fonction de la hauteur et de la circonférence : V = (c² × H / 4π) * coef forme = volume cylindre  * coef forme
        if (circonference == -1) {
            this.tabValeursCalculeeS[age].volumeUnitaire = -1;
        }
        else {
            this.tabValeursCalculeeS[age].volumeUnitaire = (Math.pow(circonference, 2) * this.tabValeursCalculeeS[age].hauteur / (4 * this.constantesSteres.PI)) * this.ppl.essence.coefForme;
        }
    }


    //*********************************
    initialiserValeurCalculeeDensiteStock = function(age, densite) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }

        this.tabValeursCalculeeS[age].densiteStock = densite;
    }

    //*********************************
    initialiserValeurCalculeeDensite = function(age, densite) {
        if (typeof(this.tabValeursCalculeeS[age]) === 'undefined') {
            this.tabValeursCalculeeS[age] = {};
            }

        var essence = this.ppl.essence;

        this.tabValeursCalculeeS[age].densite = densite;

        if (densite == -1) {
            this.tabValeursCalculeeS[age].surfaceTerriere = -1;
            this.tabValeursCalculeeS[age].concurrence = -1;
            this.tabValeursCalculeeS[age].mortalite = -1;
            this.tabValeursCalculeeS[age].volume = -1;
            if (this.prospective(age)) {
                this.tabValeursCalculeeS[age].surfaceTerriereStock = -1;
                this.tabValeursCalculeeS[age].concurrenceStock = -1;
                this.tabValeursCalculeeS[age].mortaliteStock = -1;
                this.tabValeursCalculeeS[age].volumeStock = -1;
            }
            return;
        }

        // surface terrière qui dépend directement de la densité et de la circonférence
        var circonference = this.tabValeursCalculeeS[age].circonference;
        var surfaceTerriere = densite * (Math.pow(circonference * 100, 2) / (4*this.constantesSteres.PI)) / 10000;
        this.tabValeursCalculeeS[age].surfaceTerriere = surfaceTerriere;


        // et la concurrence qui dépend directement de la densité et de la circonférence
        var concurrence = 1-(essence.k*this.tabValeursCalculeeS[age].densite*circonference*100);
        this.tabValeursCalculeeS[age].concurrence = concurrence;

        // et la mortalité qui dépend directement de la concurrence
        var mortalite = essence.mortalite;
        this.tabValeursCalculeeS[age].concurrenceClass = false; // pour l'affichage
        if (age) {
            if (concurrence < essence.seuilMortaliteConcurrence) {
                mortalite = this.tabValeursCalculeeS[age-1].mortalite + ( this.tabValeursCalculeeS[age-1].mortalite * essence.augmentationMortalite);
                mortalite = Math.max(mortalite, essence.mortalite);
                this.tabValeursCalculeeS[age].concurrenceClass = true; // pour l'affichage
            }
        }
        // pause mortalité après eclarcie :-)
        if ((age > essence.pauseMortaliteEclarcie) && (this.eclaircies[age-1] || this.eclaircies[age])) { // pas de mortalité pendant 2 ans à partir de l'année de l'éclaircie
            mortalite = 0;
        }
        this.tabValeursCalculeeS[age].mortalite = mortalite;


        // on peut passer aux valeurs de volume (/ha)
        this.tabValeursCalculeeS[age].volume = this.tabValeursCalculeeS[age].volumeUnitaire*densite;
        // 2020/05
        this.tabValeursCalculeeS[age].volumePrecedent = 0;
        if (age) {
            this.tabValeursCalculeeS[age].volumePrecedent = this.tabValeursCalculeeS[age-1].volume;
        }


        // calculs pour la valeur de stock
        if (this.prospective(age)) {
            var densiteStock =  this.tabValeursCalculeeS[age].densiteStock;
            // surface terrière qui dépend directement de la densité et de la circonférence
            var circonferenceStock = this.tabValeursCalculeeS[age].circonferenceStock;
            this.tabValeursCalculeeS[age].surfaceTerriereStock = densiteStock * (Math.pow(circonferenceStock * 100, 2) / (4*this.constantesSteres.PI)) / 10000;

            // et la concurrence qui dépend directement de la densité et de la circonférence
            var concurrenceStock = 1-(essence.k*densiteStock*circonferenceStock*100);
            this.tabValeursCalculeeS[age].concurrenceStock = concurrenceStock;

            // et la mortalité qui dépend directement de la concurrence
            var mortaliteStock = essence.mortalite;
            if (age) {
                var mortalitePred = this.tabValeursCalculeeS[age-1].mortaliteStock;
                if (typeof(mortalitePred) === 'undefined') {
                    mortalitePred =  this.tabValeursCalculeeS[age-1].mortalite;
                }
                if (concurrenceStock < essence.seuilMortaliteConcurrence) {
                    mortaliteStock = mortalitePred + ( mortalitePred * essence.augmentationMortalite);
                    mortaliteStock = Math.max(mortaliteStock, essence.mortalite);
                }
                else {
                    mortaliteStock = mortalitePred;
                }
            }
            this.tabValeursCalculeeS[age].mortaliteStock = mortaliteStock;


            // on peut passer aux valeurs de volume (/ha)
            this.tabValeursCalculeeS[age].volumeStock = this.tabValeursCalculeeS[age].volumeUnitaireStock*densiteStock;
            if (age) {
                var volumeStockPred = this.tabValeursCalculeeS[age-1].volumeStock;
                if (typeof(volumeStockPred) === 'undefined') {
                    volumeStockPred = this.tabValeursCalculeeS[age-1].volume;
                }
                var accroissement = this.tabValeursCalculeeS[age].volumeStock - volumeStockPred;
                var tauxActualisation = this.ppl.lireTauxActualisation();
                //if (!this.ageValeurDeStock && (accroissement < (volumeStockPred * 0.03))) {
                if (!this.ageValeurDeStock && (accroissement < (volumeStockPred * tauxActualisation))) {
                    this.ageValeurDeStock = age;
                }
                else {
                    var toto = "tata";
                }
            }
        }

    }

/****************************************************************** FONCTIONS "LIBRAIRIE" *************************************************************/


//*********************************
// calculerHauteur
// param : HMAX, hauteurPred, essence
// nouvelle hauteur
//*******************************
    calculerHauteur = function(HMAX, hauteurPred, essence) {
        var iH = essence.a * (HMAX - hauteurPred);
        var hauteurCalculee = hauteurPred+iH;
        return(hauteurCalculee);
    }

//*********************************
// calculerDensite
// param : année précédente
// prise en compte de la mortalité
// densité à l'hectare
//*******************************
    calculerDensite = function(age) {
        var densitePred = this.tabValeursCalculeeS[age].densite;
        if (densitePred == -1) { // on n'a pas cette valeur !
            return(densitePred)
        }
        return(densitePred - (densitePred * this.tabValeursCalculeeS[age].mortalite));
    }

// inutile mais appelé
// ********************************************
    calculerDensiteStock = function(age) {
        var densitePred = this.tabValeursCalculeeS[age].densiteStock;
        if (typeof(densitePred) === 'undefined') {
            densitePred = this.tabValeursCalculeeS[age].densite;
        }
        if (densitePred == -1) { // on n'a pas cette valeur !
            return(densitePred)
        }
        var mortalitePred = this.tabValeursCalculeeS[age].mortaliteStock;
        if (typeof(mortalitePred) === 'undefined') {
            mortalitePred = this.tabValeursCalculeeS[age].mortalite;
        }
        return(densitePred - (densitePred * mortalitePred));
    }



//*********************************
// integrerMesuresAnnee
// remplace une hauteur calculée par une hauteur mesurée
// param : année
// return : hauteur
//*********************************
	integrerMesuresAnnee = function(age) {

        var hauteurMesuree = 0;
        this.tabValeursCalculeeS[age].mesuresClass = false; // juste pour l'affichage
        if (typeof(this.mesures[age]) !== 'undefined') {
            hauteurMesuree = this.mesures[age].hauteur;
            this.tabValeursCalculeeS[age].hauteurAvantMesure = this.tabValeursCalculeeS[age].hauteur;
            this.initialiserValeurCalculeeHauteur(age, hauteurMesuree); // et du coup on ecrase l'année en cours avec la valeur mesurée

            // idem pour circonference
            this.initialiserValeurCalculeeCirconference(age, this.mesures[age].circonference);
            // et densité
            this.initialiserValeurCalculeeDensite(age, this.mesures[age].densite);

            this.tabValeursCalculeeS[age].mesuresClass = true; // juste pour l'affichage
        }
        return(hauteurMesuree);
    }

//*********************************
// integrerEclaircieAnnee
// remplace une hauteur calculée par une hauteur mesurée
// calcule les valeurs de l'éclaircie et les valeurs avant (différentes des valeurs calculées)
// param : année
// return : hauteur
// Utilise :
// - Circonférence après éclaircie = CAPR = RACINE( GAPR * 4π / NAPR)
// - Hauteur après éclaircie =  HAPR = (HAV * NAV - HECL * NECL) / NAPR
//*********************************
	integrerEclaircieAnnee = function(age) {

        var essence = this.ppl.essence;

        if (typeof(this.eclaircies[age]) === 'undefined') {
            this.tabValeursCalculeeS[age].eclaircieClass = false; // juste pour l'affichage
            this.tabValeursCalculeeS[age].eclairciePropClass = false; // juste pour l'affichage
            this.tabValeursCalculeeS[age].densiteEclaircie = 0;
            this.tabValeursCalculeeS[age].surfaceTerriereEclaircie = 0;
            this.tabValeursCalculeeS[age].volumeRecolteEclaircie = 0;
            this.tabValeursCalculeeS[age].circonferenceAvantEclaircie = -1;
            // ajout 2020/05
            this.tabValeursCalculeeS[age].hauteurEclaircie = 0;
            this.tabValeursCalculeeS[age].circonferenceEclaircie = 0;
            return(0);
        }

        var eclaircie = this.eclaircies[age];
        var tarifEclaircie = eclaircie.tarif;
        if (typeof(tarifEclaircie) !== 'undefined') {
            this.tabValeursCalculeeS[age].tarifEclaircie = tarifEclaircie;
        }

        // calcul de la nouvelle hauteur
        var hauteur = this.tabValeursCalculeeS[age].hauteur;
        var densite = this.tabValeursCalculeeS[age].densite;
        var densiteEclaircie = densite - eclaircie.densiteApres;
        this.tabValeursCalculeeS[age].densiteEclaircie = densiteEclaircie;
        var hauteurApresEclaircie = ((hauteur * densite) - (hauteur * essence.coefEclaircie * densiteEclaircie))/eclaircie.densiteApres;
        if ((typeof(eclaircie.hauteurMoyenne) !== 'undefined') && eclaircie.hauteurMoyenne) {
            hauteurApresEclaircie = eclaircie.hauteurMoyenne; // si on a saisie une mesure lors de l'éclaircie, elle est prioritaire sur le calcul
        }
        this.tabValeursCalculeeS[age].hauteur = hauteurApresEclaircie; // on ecrit en "direct"

        // calcul de la nouvelle circonférence
        var circonference = this.tabValeursCalculeeS[age].circonference;
        //var surfaceTerriere = densite * (Math.pow(circonference * 100, 2) / (4*this.constantesSteres.PI)) / 10000;
        var surfaceTerriere = this.tabValeursCalculeeS[age].surfaceTerriere;
        var surfaceTerriereEclaircie = densiteEclaircie * (Math.pow(circonference *  essence.coefEclaircieCirc * 100, 2) / (4*this.constantesSteres.PI)) / 10000;
        this.tabValeursCalculeeS[age].surfaceTerriereEclaircie = surfaceTerriereEclaircie;
        var surfaceTerriereApres = surfaceTerriere - surfaceTerriereEclaircie;
        var circonferenceApresEclaircie = Math.sqrt(surfaceTerriereApres * 4 * this.constantesSteres.PI / eclaircie.densiteApres);
        if ((typeof(eclaircie.circonferenceQuadratique) !== 'undefined') && eclaircie.circonferenceQuadratique) {
            circonferenceApresEclaircie = eclaircie.circonferenceQuadratique/100; // si on a saisie une mesure lors de l'éclaircie, elle est prioritaire sur le calcul
        }

            // ajout 2020/05
        var hauteurAvantEclaircie = (hauteurApresEclaircie * eclaircie.densiteApres) / (densite - (essence.coefEclaircie * densiteEclaircie)); // 2020/07 - on repart des valeurs après éclaircies
        this.tabValeursCalculeeS[age].hauteurEclaircie = hauteurAvantEclaircie*essence.coefEclaircie;
        //this.tabValeursCalculeeS[age].hauteurEclaircie = hauteur*essence.coefEclaircie; // TODO calculer à partir de hauteurApresEclaircie
        var surfaceTerriereApresCorrigee = circonferenceApresEclaircie * circonferenceApresEclaircie * eclaircie.densiteApres / (4 *this.constantesSteres.PI);
        var surfaceTerriereAvantCorrigee = surfaceTerriereEclaircie + surfaceTerriereApresCorrigee;
        var circonferenceAvantCorrigee = Math.sqrt(surfaceTerriereAvantCorrigee * 4 * this.constantesSteres.PI / densite);
        //circonference = circonferenceAvantCorrigee;

        //this.tabValeursCalculeeS[age].circonferenceEclaircie = circonference*essence.coefEclaircieCirc;
        this.tabValeursCalculeeS[age].circonferenceEclaircie = circonferenceAvantCorrigee*essence.coefEclaircieCirc;

        this.tabValeursCalculeeS[age].densiteAvantEclaircie = densite; // on sauve la densite d'avant pour "comprendre"
        this.tabValeursCalculeeS[age].circonferenceAvantEclaircie = circonference; // on sauve la circonference d'avant pour "comprendre"
        // attention, l'écriture en direct ne modifie pas le volume unitaire ! il faut donc passer par la fonction standard
        //this.tabValeursCalculeeS[age].circonference = circonferenceApresEclaircie; // on ecrit en "direct"
        this.initialiserValeurCalculeeCirconference(age, circonferenceApresEclaircie);

        this.tabValeursCalculeeS[age].volumeUnitaireAvantEclaircie = ((Math.pow(circonference, 2) * hauteur)/(4*this.constantesSteres.PI))*essence.coefForme;
        this.tabValeursCalculeeS[age].volumeAvantEclaircie = this.tabValeursCalculeeS[age].volumeUnitaireAvantEclaircie * densite;

        if (this.ppl.uf.identifiant == "Semis abandonné") {
            var toto = 1;
        }

        var volumeUnitairePotentielEclaircie = ((Math.pow(circonference*essence.coefEclaircieCirc, 2) * (hauteur*essence.coefEclaircie))/(4*this.constantesSteres.PI))*essence.coefForme;
        this.tabValeursCalculeeS[age].volumeUnitaireEclaircie = volumeUnitairePotentielEclaircie; // 0 possible
        this.tabValeursCalculeeS[age].volumeRecolteEclaircie = volumeUnitairePotentielEclaircie * densiteEclaircie; // 0 possible
        this.tabValeursCalculeeS[age].cumulVolumeRecolteAvantEclaircie = this.tabValeursCalculeeS[age].cumulVolumeRecolteEclaircie;
        this.tabValeursCalculeeS[age].cumulVolumeRecolteEclaircie += this.tabValeursCalculeeS[age].volumeRecolteEclaircie;

        this.tabValeursCalculeeS[age].eclaircieClass = true; // juste pour l'affichage

        return(hauteurApresEclaircie);
    }

//*********************************
// année retenue en fonction du mois (et du mois bascule)
//*********************************
    anneeRetenue = function(dateInstallation) {
        var constantes = {};
        constantes.steres = this.constantesSteres;
        return(this.anneeRetenuePeuplement(dateInstallation, constantes));
	}
//*********************************
// année retenue en fonction du mois (et du mois bascule passé en param)
// duplication de ApiUser
//*********************************
	anneeRetenuePeuplement(dateInstallation, constantes) {
		if (!dateInstallation.length) {
			return("Date d'installation inconnue");
		}
		var annee = parseInt(dateInstallation.substring(0, 0+4));
        if (dateInstallation.length == 4) {
            return(annee);
        }
		var mois = parseInt(dateInstallation.substring(5, 5+2));
		if (mois >= constantes.steres.moisBascule) {
			annee++;
		}
		return(annee);
	}


//*********************************
// finInstallation
// params : hauteur
// return : booléen - fin d'installation si la hauteur passée en paramètre dépasse la hauteur de fin d'installation de l'essence
//*********************************
	finInstallation = function(hauteur) {
        //var cultureCode = this.ppl.cultureCode;
        var essence = this.ppl.essence;

        if (hauteur >= essence.hauteurFinInstallation) {
            this.debug = this.debuger();
            return(1);
            }
            this.debug = false;
        return(0);
    }

    //*********************************
	debuger = function() {

            this.debug = true;
        return(false);
    }


    //*********************************
    arrondir = function(valeur, puissance = 3) {
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

//********************************************************************************************************
// validerMesurePred : peut-on utiliser la mesure précédente (proximité temporelle avzec la suivante) - NB : le smesures les plus récentes sont les plus pertinentes
//********************************************************************************************************
    validerMesurePred = function(mesure, mesurePred) {
        if (mesure.anneeDate-mesurePred.anneeDate < this.ppl.essence.deltaMesuresPourHMAX) {
            return(false);
        }
    return(true);
    }

//*********************************
// estFutur : est-on dans le futur
//*********************************
	estFutur = function(annee) {
        return(annee >= this.ppl.anneeCourante-1);
    }

//*********************************
// pour la valeur de stock (idem que "estFutur" mais caduque - toujours appelé ... pour le moment)
	prospective = function(annee) {
        // annee+1 car on commence une année avant - possibilité d'un eclaircie automatique l'année n qui ne doit pas être prise en compte
        return((this.ageValeurDeStock >= 0) && (annee+1 >= this.ppl.anneeCourante-1));
        //return((this.ageValeurDeStock >= 0) && (annee >= this.ppl.anneeCourante-1));
    }



/************************************************* FONCTIONS "CADUQUES" - trouver un age de peuplement à partir des mesures *********************************************/

//*********************************
// "anneeDateInstallation" et "anneeCourante" sont inconnues
// par contre on a sur les mesures "anneeDate" connu (et "annee": inconnue)
    recalerHistorique(datas) {

        var retour = false;
        // la première chose à faire est de calculer le nombre de mesures dont on dispose
        var nbMesures = datas.mesures.length;

        if (!nbMesures) {
            return(retour);
        }

        // si on a une seule mesure, on va partir sur une hyphotèse de HMAX et en deduire l'age de la plantation lors de cette mesure
        if (nbMesures == 1) {
            retour = this.recalerMesureUnique(datas.mesures[0])
        }
        // si on a plusieurs mesures
        // la première sert ce base (soit en tant qu'elle même - soit pour le calcul de hauteur de fin d'installation)
        // pour chacune des suivantes (ou juste pour la dernière), on calcule le HMAX à partir de l'accroissement entre la base et la valeur
        // HMAX = ((Hn-Hm)/(1-(1-a)puissance(N-M))+Hm
        // quel ecart entre deux années ?
        // pourquoi ne pas utiliser la méthode 1 sur chaque mesure pour calculer deux années de départ puis faire la moyenne de ces deux années et lancer les calculs sur cette valeur
        // quid du delta "années" qui est pourtant "certain" entre ces deux années ? OK delta année pour methode = 3 (cf. deltaMesuresPourHMAX)
        // ATTENTION : si la première mesure "précède" la fin d'installation le modèle mathématique est inexact - mais il est peu probable d'avoir des mesures sur des pins très jeunes sans connaître l'année de plantation ...
        else {
            var mesure1 = datas.mesures[0];
            var mesure2 = datas.mesures[nbMesures-1];
            var departRecalage =  1; // car mesure 1 (indice 0 déjà recalée)
            // il faut que les mesures soient suffisament espacées pour passer par un calcul de Delta
            if (mesure2.anneeDate-mesure1.anneeDate < this.ppl.essence.deltaMesuresPourHMAX) {
                retour = this.recalerMesureUnique(mesure2);
                departRecalage =  0;
            }
            else {
                // a) HMAX à partir des valeurs "certaines" les plus éloignées
                var HMAX = ((mesure2.hauteur-mesure1.hauteur)/(1-Math.pow(1-this.ppl.essence.a, mesure2.anneeDate-mesure1.anneeDate)))+mesure1.hauteur;
                this.tabValeursCalculeeS[0].HMAX = HMAX;
                // b) année et hauteur de fin d'installation  : n tel que xPUISSANCEn = y (ou 1 = y/xPUISSANCEn)
                this.initialiserInfosInstallation(HMAX);

                var n = 1;
                var trouve = false;
                var marge = 0.0049;
                var un = (1 - ((mesure2.hauteur-this.infosFinInstallation.hauteur) / (HMAX-this.infosFinInstallation.hauteur)));
                while (1) {
                    un = un / (1-this.ppl.essence.a);
                    if (un >= 1-marge) {
                        trouve = true;
                        break;
                    }
                    if (n == 150) {
                        break;
                    }
                    n++;
                }
                if (trouve) {
                    var ageMesure2 = n+this.infosFinInstallation.age;
                    //var ageMesure2 = n+this.infosFinInstallation.age+1;
                    var anneeDateInstallation = this.recalerMesure(mesure2, ageMesure2);
                    mesure1.age = mesure2.age - (mesure2.anneeDate - mesure1.anneeDate);
                    this.ageDonneesValides = mesure1.age;
                    this.recalerPeuplement(anneeDateInstallation);
                    retour = true;
                }
            }
            // si c'est bon
            if (retour) {
                // si on a plus de 2 mesures, on se contente de redonner sa date à chacune
                for(var cptMesures = departRecalage; cptMesures < nbMesures-1; cptMesures++) {
                    var mesure = datas.mesures[cptMesures];
                    mesure.age = mesure2.age - (mesure2.anneeDate - mesure.anneeDate);
                }
            }
        }

        return(retour);
    }


		//*********************************
    recalerMesureUnique(mesure) {

        var retour = false;

        // année et hauteur de fin d'installation avec hypothèse de HMAX
        this.initialiserInfosInstallation();

        var cptAns = 0;
        while (1) {
            if (cptAns >= this.ppl.essence.vieMax) { // la parcelle est trop vieille
                break;
            }
            var valeurs = this.tabValeursCalculeeS[cptAns]; // vérifier qu'on va assez loin TODO
            if (typeof(valeurs) === 'undefined') {
                this.calculerValeursGeometriques(this.ppl.essence.vieMax); // on ne le fait pas si c'est inutile
                valeurs = this.tabValeursCalculeeS[cptAns];
            }
            if (valeurs.hauteur > mesure.hauteur) { // on vient de dépasser
                var ageEstime = cptAns-1; // donc c'était peut être bon l'année d'avant
                if ((valeurs.hauteur - mesure.hauteur) < (mesure.hauteur - this.tabValeursCalculeeS[ageEstime].hauteur)) {
                    ageEstime = cptAns; // on prend la valeur la plus proche
                }
                var anneeDateInstallation = this.recalerMesure(mesure, ageEstime);
                this.ageDonneesValides = mesure.age;
                this.recalerPeuplement(anneeDateInstallation);
                retour = true;
                break;
            }
            cptAns++;
        }
        return(retour);
    }

		//*********************************
    // on renseigne l'année "relative" de la mesure et on retourne l'année d'installation
    recalerMesure(mesure, age) {
        mesure.age = age;
        mesure.annee = age+1;
        //return(mesure.anneeDate - (age-1));
        return(mesure.anneeDate - age);
    }

	//*********************************
    recalerPeuplement(anneeDateInstallation) {
        this.ppl.anneeDateInstallation = anneeDateInstallation;
        this.calerPeuplement();
    }

    //***********************************************
    // on peut ne pas avoir les données de peuplement (mesures et/ou année de peuplement)
    // ET juste une mesure (si année c facile - sinon, on déduit l'année d'un HMAX factice)
    // OU au moins deux mesures (on en déduit l'année par les accroissements)
    // chaque valeur peut donc être estimée ... visualisation graphique ?
    reinitialiserDonneesManquantes = function() {
        // option 1) il nous manque juste la densité de départ
        // => toutes les valeurs amont de la mesure à l'exeption de la hauteur sont à 0 (vide)
        // ON NE FAIT DONC RIEN ICI !!!
        // option 2) il nous manque aussi l'année
        // => toutes les valeurs amont de la mesure à l'exeption de la hauteur sont à 0 (vide)
        // ET on cherche un valeur approchée de l'année
        // de cette façon, on date nos mesures ...
    }

}

export const UfPplDendrometrique = new UF_PPL_DENDROMETRIQUE()
export const UfPplDendrometrique1 = new UF_PPL_DENDROMETRIQUE()
export const UfPplDendrometrique2 = new UF_PPL_DENDROMETRIQUE()

