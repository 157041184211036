<template>
    <div id="navLogin">
        <div v-if="connexionUtilisateur && !this.$store.getters.modeForet">
            <div class="userName"><span class="lien" v-on:click="logoutDialog=true;logouting=true">{{this.$store.getters.utilisateurCourant.identifiant}}</span></div>
            <img class="lien"  src="@/assets/img/profil.svg"  v-on:click="logoutDialog=true;logouting=true" >
        </div>
        <div v-if="!connexionUtilisateur && 0">
            <div class="userName"></div>
            <img v-if="0" class="lien" src="@/assets/img/profil.svg"  v-on:click="login" >
            <img v-if="1"  class="" src="@/assets/img/profil.svg"  >
        </div>

        <v-form v-if="!connexionUtilisateur && connexionValidee">
        <v-container fluid>
            <v-layout wrap>
                <v-flex xs12>
                    <v-combobox
                        v-model="userNom"
                            :error-messages="userNomErrors"
                            item-text="identifiant"
                            item-value="id"
                            :items="itemsComboUser"
                            chips
                            required
                            label="Votre nom : "
                            @input="$v.userNom.$touch()"
                            @blur="$v.userNom.$touch()"
                    ></v-combobox>
                </v-flex>

                <v-flex xs12>
                    <v-text-field
                        v-model="userPswd"
                            :error-messages="userPswdErrors"
                            :counter="6"
                            label="Votre mot de passe : "
                            :type="'password'"
                            required
                            @input="$v.userPswd.$touch()"
                            @blur="$v.userPswd.$touch()"
                    ></v-text-field>
                </v-flex>

                <v-btn @click="submit">OK</v-btn>

            </v-layout>
        </v-container>
        </v-form>

        <v-dialog v-model="logoutDialog" v-if="logouting">
            <v-card>
                <v-card-title class="headline">STERES (v.{{$parent.laVersionSTERES}}) - Déconnexion</v-card-title>
                <v-card-text>
                    Voulez-vous vraiment vous déconnecter ? Les saisies en cours seront perdues.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" text @click="logoutDialog = false;logouting=false">Non</v-btn>
                    <v-btn color="" text @click="logout()">Oui</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

    import { addeoData } from "../../js/api/datas.js"
    import { ApiUser } from "../../js/api/index.js"

    import { validationMixin } from 'vuelidate'
    import { required, maxLength, email } from 'vuelidate/lib/validators'


    export default {
        mixins: [validationMixin],
        name: 'Login',
        validations: {
            userNom: { required},
            userPswd: { required, maxLength: maxLength(6) },
        },
        props: {
        /**
         * Données à injecter
         */
            connexionUtilisateur: Boolean

        },

        data() {
            return {
                connexionValidee:false,
                /*
                input: {
                    username: "",
                    password: ""
                },
                */
                userNom: "",
                userPswd: "",
                usersObj:[],
                users:[],
                usersId:[],
                logoutDialog:false,
                logouting:false,
            }
        },

        created: function () {
        },

        computed: {
            itemsComboUser () {
                return this.usersObj
            },
            itemsComboUserNom () {
                return this.users
            },
            itemsComboUserId () {
                return this.usersId
            },
            userNomErrors () {
                const errors = []
                if (!this.$v.userNom.$dirty) return errors
                !this.$v.userNom.required && errors.push('Indiquez votre identifiant')
                return errors
            },
            userPswdErrors () {
                const errors = []
                if (!this.$v.userPswd.$dirty) return errors
                !this.$v.userPswd.maxLength && errors.push('Le mot de passe nécessite au moins 6 caractères')
                !this.$v.userPswd.required && errors.push('Saisissez un mot de passe')
                return errors
            }
        },
        methods: {
            logout() {
                this.$store.commit('setProprieteChargeeId', 0); // on rechargera les UFs de la propriété
                this.logouting=false
                this.logoutDialog = false
                this.connexionValidee = false;
                this.$store.commit('setModeForet', false); // transitoire car - TODO - deconnexion impossible en mode foret
                this.$emit("transfertConnexion", false);
            },
            login() {
                /*
                ApiUser.getUserUtilisateurs()
                    .then(response => {
                        this.usersObj = response;
                        // pour accès direct
                        var vueThis = this;
                        this.usersObj.forEach(user => {
                            var idUser = "id" + user.id;
                            vueThis.usersObj[idUser] = user;
                        })
                        this.connexionValidee = true;
                    })
                    */
            },
            submit () {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    console.log("submit pas OK")
                }
                else {


                    //addeoData.validerUser(this.userNom.identifiant, this.userNom.id, this.userPswd)
                    addeoData.validerUtilisateur(this.userNom.identifiant, this.userNom.id, this.userPswd)
                        .then(response => {
                            if (!response || response.retour == true) {
                                console.log("submit OK")
                                var chargerUserDatas = false;
                                if (response && response.retour == true) {
                                    chargerUserDatas = true;
                                }
                                this.$parent.renseignerUtilisateur(this.userNom, chargerUserDatas);

                                // on maintien également à jour la variable de synchro pour savoir si on devra mettre à jour les données de l'utilisateur vers le serveur central
                                var idUser = "id" + this.userNom.id;
                                ApiUser.majSynchro(this.usersObj[idUser].synchroLocal)

                                // on ferme !
                                this.connexionValidee = false;

                                // on transmet la connexion
                                this.$emit("transfertConnexion", true);
                            }
                            else {
                                alert(response.erreur);
                                console.log("submit non valide")
                            }
                        })
                    }
                }
        }
    }
</script>

<style lang="scss">
#app {
    #navLogin {
        width: 270px;
        /*
        width: 250px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        */
        margin: 0 auto;
        /*
        margin-top: 10px;
        padding: 0.4em 0.8em;
        padding-top:10px;
        */

    }
    #navLogin .userName {
        padding-right: 24px;
        min-width: 214px;
        min-height: 50px;
        display:inline-block;
        text-align: right;
        vertical-align: middle;
        color:white;
        font-size:1.2em;
    }
    #navLogin .userName span {
        text-shadow: 2px 2px 2px black;
        padding: 0.2em 1em;
        background-color: rgba(34,57,56,0.8);
        border-radius: 10px 10px;
    }
    #navLogin > div {
        min-height:70px;
    }
    #navLogin img {
        @media screen and (max-width: 480px) {
            display:none;
        }
        /*
        max-width: 36px;
        height: auto;
        */
        max-width: 56px;
        height: auto;
        padding: 10px;
        vertical-align: text-bottom;
        background-color: rgba(34,57,56,0.8);
        border-radius: 50% 50%;
    }
    #navLogin img, #navLogin span {
        cursor:pointer;
    }
    #navLogin img:hover, #navLogin span:hover {
        background-color: #7DAB87;
        background-color: rgba(125,171,135,0.8);
    }

    #navLogin form {
        position: absolute;
        z-index: 98;
        background-color: white;
    }

    @media screen and (max-width: 480px) {
        div#navFct {
            float:none;
            clear: both;

            #navLogin {
                width:100%;
                /*max-width:150px;*/
                &>div {
                    padding-top:0.2em;
                    min-height: 0px;
                    text-align: right;

                    .userName {
                        padding-right: 0;
                        min-width: 0;
                        min-height: 30px;
                        color: white;
                        font-size: 1em;
                    }

                    img {
                        display:none;
                    }
                }
            }
        }
    }
}
</style>
