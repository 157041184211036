import lf from 'lovefield'

export function const_steres (schemaBuilder) {
  schemaBuilder.createTable('const_steres')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('PI', lf.Type.DOUBLE)
      .addColumn('moisBascule', lf.Type.INTEGER)
      .addColumn('tauxActualisation', lf.Type.DOUBLE)
      .addColumn('arrondiHectares', lf.Type.INTEGER)
      .addColumn('arrondisJson', lf.Type.STRING)
      .addColumn('gestionCommercialeJson', lf.Type.STRING)
      .addColumn('massesMolairesJson', lf.Type.STRING)
      .addPrimaryKey(['id'], false);
}
