import lf from 'lovefield'

export function user_ufpeuplementmesure (schemaBuilder) {
  schemaBuilder.createTable('user_ufpeuplementmesure')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('peuplementId', lf.Type.INTEGER)
      .addColumn('utilisateurId', lf.Type.INTEGER)
      .addColumn('dateMesure', lf.Type.STRING)
      .addColumn('hauteurMoyenne', lf.Type.DOUBLE)
      .addColumn('hauteurJson', lf.Type.STRING)
      .addColumn('circonferenceQuadratique', lf.Type.DOUBLE)
      .addColumn('circonferenceQuadratiqueJson', lf.Type.STRING)
      .addColumn('densiteMoyenne', lf.Type.DOUBLE)
      .addColumn('densiteMethodeId', lf.Type.INTEGER)
      .addColumn('densiteJson', lf.Type.STRING)
      .addColumn('version', lf.Type.INTEGER)
      .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon)
      .addPrimaryKey(['id'], true) // 2020/12 - Mode forêt - on peut créer cet item iniquement en base locale
      .addNullable(['idLocal']);
}
