<style>
   @import './PeuplementEdition.css';
</style>

<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="peuplementEdition" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="peuplement.id" class="blocACompleter">{{peuplement.anneeRetenue}}</h1>
                <h1 v-if="!peuplement.id" class="blocACompleter">Création d'un peuplement</h1>

                <span v-if="peuplement.id && 0" class="complementBloc">
                <v-btn class="boutonFonctionObjet" type="button" @click="supprimerPeuplementouPas">Supprimer</v-btn>
                </span>

                <p class="intro cacher">Consigne peuplement</p>

                <p class="info">Les champs suivis d'une * sont obligatoires.</p>

                <div class="edition">
                    <edition-peuplement :data='lePeuplement'></edition-peuplement>
                    <div class="illustration illustration2"></div>
                </div>

                <v-dialog v-model="dialog" v-if="peuplement.id" >
                    <v-card>
                        <v-card-title class="headline">Suppression</v-card-title>

                        <v-card-text>
                            Voulez-vous vraiment supprimer le peuplement {{ peuplement.identifiant }} ?<br/>Cette action est irréversible.
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="dialog = false">Non</v-btn>
                            <v-btn color="" text @click="supprimerPeuplement()">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </div>
        </div>
    </div>
</template>


<script>


import { SteresConstantes } from "../js/calculs/constantes.js"
import { ApiUser } from '../js/api/index.js'
import PeuplementEditionForm from '../components/uf/PeuplementEditionForm.vue'

export default {

    components: {
        'edition-peuplement': PeuplementEditionForm,
     },

    data() {
        return {
            uf:null,
            peuplement: {
                "userId":this.$store.getters.utilisateurCourantId,
                "id":0,
                "ufId":this.$store.getters.ufId,
                "dateInstallation":"",
                "cultureId":0,
                "essenceId":0,
                // "surface":0, ?
                "surfaceBoisee":"",
                "densiteInstallation":0.0,
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "travauxJson":"[]",
                "eclaircieDensiteMin":-1,
                "version":0,
				"idLocal":true,
                "etatSteres": SteresConstantes.etatVALIDE,
                "etatUtilisateur": 0
            },
            ready: false,
            constantesReady: false,
            dialog: false
        }
    },
    created() {

    },

    mounted: function () {
        this.constantesReady = this.$store.getters.constantes.ready;
        //this.peuplementCourant();
        /*
        if (this.$store.getters.modeForet == 6) {
            this.$store.commit('setModeForet', 7);
        }
        */
    },

    watch: {
        constantesReady(newValue, oldValue) {
            if (newValue) {
                if (this.$store.getters.peuplementId <= 0) {
                    this.peuplement.travauxJson = ApiUser.initialiserTravaux(this.$store.getters.constantes);
                }
                this.peuplementCourant();
            }
        },
        modeForetPreparation(newVal) {
            if (newVal == 7 && this.ready) {
                setTimeout(this.avancerVers7(), 2500); // normalement on ne passe pas par là
                ;
            }
        },
        isReady(newVal) {
            if (newVal && this.modeForetPreparation == 7) {
                setTimeout(this.avancerVers7, 245); // normalement on passe pas par là
            }
        }

    },

    computed: {
        // UF à jour
        laUF() {
            return this.uf
        },
        // Peuplement à jour
        lePeuplement() {
            return this.peuplement
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        modeForetPreparation() {
            return(this.$store.getters.modeForet);
        },
    },

    methods: {
        avancerVers7() {
            this.$parent.clicPourAvanceModeForet('lienModeForet', 0);
            setTimeout(this.avancerVers7Suite, 245);
        },
        avancerVers7Suite() {
            this.$parent.clicPourAvanceModeForet('lienAnnulerModeForet', 0);
            this.$parent.clicPourAvanceModeForet('lienModeForet', 1);
            setTimeout(this.avancerVers7Fin, 245);
        },
         avancerVers7Fin() {
            this.$parent.clicPourAvanceModeForet('lienAnnulerModeForet', 0);
            this.$router.push({ name: "peuplement" });
        },
        // lecture en base et maj du ready
        peuplementCourant() {
            var peuplementId = this.$store.getters.peuplementId;
            // on lit l'UF au passage
            var ufId = this.$store.getters.ufId;
            if (ufId > 0) {
                return ApiUser.lireUF(ufId).then(response => {
                    this.uf = response.uf;
                    this.uf.tabParcelles = response.parcelles;
                    this.uf.userId = this.$store.getters.utilisateurCourantId; // pour l'avoir sous la main
                    if (peuplementId > 0) {
                        return ApiUser.lirePeuplement(peuplementId, this.$store.getters.constantes).then(response => {
                            this.peuplement = response.peuplement;
                            this.amenderDatasPeuplement();
                            this.peuplement.surfaceUF = this.uf.surface; // on a notamment besoin de la surface
                            this.ready = true;
                        })
                    }
                    else {
                        this.amenderDatasPeuplement();
                        //this.peuplement.surfaceUF = this.uf.surface; // on a notamment besoin de la surface
                        this.ready = true;
                    }
                })
            }
        },
        amenderDatasPeuplement() {
            this.peuplement.surfaceUF = this.uf.surface; // on a notamment besoin de la surface
            if (this.peuplement.eclaircieDensiteMin == -1) {
                this.peuplement.eclaircieDensiteMin = this.$store.getters.constantes.params.const_essence.eclaircieDensiteMin;
            }
        },

        // fonctions sur boutons
        supprimerPeuplementouPas() {
            this.dialog = true;
        },
        supprimerPeuplement() {
            this.dialog = false;
            var anneeRetenue = this.peuplement.anneeRetenue;

            return ApiUser.deletePeuplement(this.peuplement) // on detruit notre peuplement (en DB locale et SI POSSIBLE en ligne)
            .then(response => {
                this.$store.commit('donnerMessage', "Le peuplement "+anneeRetenue+" a bien été supprimé");
                //this.$store.commit('setUfId', 0);
                //this.$store.commit('resetDataApplication');
                this.$router.push({ name: "propriete" });
                return(response);
            })

        }


    }
}
</script>

