import { LovefieldService } from './LovefieldService.js'

/* @ngInject */
class SteresDBObj {
  /* @ngInject */
  constructor ($q) {
    this.$q = $q
    this.db = null
    this.tables = null
    this.loveField = LovefieldService;
  }

  /**
  * renvoie ttes les tables json; attachées à lf lors du démarrage (see bindAllJsonData)
  */
   getAllJsonTables(){
    return this.loveField.allJsonData.tables
   }

  /**
  * renvoit immédiatement le contenu d'une table stockée en JSON
  */
   getJsonTable(tName){

    var t = this.loveField.allJsonData.tables[tName];
    if(!t)
      console.log('not found jsonTable ', tName);
    return t;
   }

    /**
    * BC - 2019/04 - il est possible d'avoir des tables exo vides dans allJsonData - si on les as lues en base, on rempli
    **/
/*
setJsonTables(datas) {

    var tables = this.loveField.allJsonData.tables;
    var datables = datas.tables;

    }
*/

  /*
  */
searchInTable(search, limit, tabChamps, tableName) {
  var searchRegEx = new RegExp('^'+search, 'i');
//  var searchRegEx = new RegExp('^'+search+'|'+search);
  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]
    const whereClauses = [];

    tabChamps.forEach(champ=> {
      whereClauses.push(table[champ].match(searchRegEx));
    })

    let query = db
        .select()
        .from(table)
        //.where(table.codePostal.match(searchRegEx))
       .where(LovefieldService.lf.op.or(...whereClauses))
        .limit(limit)
        .exec();
      return Promise.resolve(query)
          .catch(e => {console.log('error saveRowInTable', e, rowData, tableName)})
    });
  }


/*
 */
countInTable(champ, tableName) {

  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]
    let query = db
        .select(LovefieldService.lf.fn.count(table[champ]))
        .from(table)
        .exec();
      return Promise.resolve(query)
    });
  }

  /*
  */
readInTable(value, champ, tableName, orderByChamp="id", orderDESC=false) {
    var order = LovefieldService.lf.Order.ASC;
    if (orderDESC) {
        order = LovefieldService.lf.Order.DESC;
    }
  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]

    let query = db
        .select()
        .from(table)
        .where(table[champ].eq(value))
        .orderBy(table[orderByChamp], order)
        .exec();
      return Promise.resolve(query)
    });
  }

  /*
  */
readInTableFromTab(values, champ, tableName, orderByChamp="id", orderDESC=false) {
    var order = LovefieldService.lf.Order.ASC;
    if (orderDESC) {
        order = LovefieldService.lf.Order.DESC;
    }
  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]

    let query = db
        .select()
        .from(table)
        .where(table[champ].in(values))
        .orderBy(table[orderByChamp], order)
        .exec();
      return Promise.resolve(query)
    });
  }

  /*
  */
  readAllFromTableDB(tableName, db) {
console.log('readAllFromTableDB ', tableName);
    var t = this.tables[tableName]
    var query = db.select().from(t).exec()
    return Promise.resolve(query);
  }

  /*
  */
  readAllFromTable(tableName) {
/*
    if (this.dbSelect) {
          return(this.readAllFromTableDB(tableName, this.dbSelect));
      }

      else {
*/
        return this.getDbConnection()
        .then(db => {
            this.dbSelect = db;
            var t = this.tables[tableName]
            var query = db.select().from(t).exec()
            return Promise.resolve(query);
        })
//      }
  }


  /*
  *
  */
  getRowInTableImmediate(rowId, tableName) {

   var t =  this.getJsonTable(tableName)
   var items = t.filter(item => item.id == rowId);
   return items ? items.pop() : null
}
  /*
  *
  */
  getFirstRowInTableImmediate(tableName) {

   var t =  this.getJsonTable(tableName)
   var items = t.filter(item => item.id != -1);
   return items ? items.pop() : null
}

/*
* TODO; Voir dans les APixxx tous ce qu'on peut factoriser avec ça !!
*/
readRowInTable(rowId, tableName) {

  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]

    let query = db
        .select()
        .from(table)
        .where(table.id.eq(rowId))
        .exec();
      return Promise.resolve(query)
      .then(resultsArray => {
        return resultsArray.pop()
      });
    });
  }

readUserproprietesInTable(userId, tableName) {

var searchRegEx = new RegExp("\,"+userId+"\,?");
  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]

    let query = db
        .select()
        .from(table)
//        .where(table.proprietaireId.eq(userId)) // TODO accès gestionnaire
        .where(lf.op.or(
            table.proprietaireId.eq(userId),
            table.gestionnaireId.eq(userId),
            table.ayantsDroitJson.match(searchRegEx)
        ))
        .exec()
      return Promise.resolve(query)
     /* .then(resultsArray => {
        return resultsArray.pop()
      });*/
    });
  }

readAyantsDroitJsonproprietesInTable(userId, tableName) {
    //var searchRegEx = new RegExp("\,"+userId+"\,?");
    var searchRegEx = new RegExp('^toto', 'i');
  return this.getDbConnection()
  .then(db => {
      let table = this.tables[tableName];
      const whereClauses = [];
      //whereClauses.push(table['identifiant'].match(searchRegEx));
      whereClauses.push(table['ayantsDroitJson'].match(searchRegEx));

      let query = db
        .select()
        .from(table)
        .where(LovefieldService.lf.op.or(...whereClauses))
    //    .limit(limit)
        .exec();
        return Promise.resolve(query)
          .catch(e => {console.log('error readAyantsDroitJsonproprietesInTable', e, userId, tableName)})
    });
  }



/*
*
*/
saveRowsInTable(rowsDatas, tableName) {
    return this.getDbConnection()
        .then(db => {
            let table = this.tables[tableName]
            const rows = [];
            rowsDatas.forEach(rowData=> {
                let row = table.createRow(rowData)
                rows.push(row);
            })
            let query = db
                .insertOrReplace()
                .into(table)
                .values(rows)
                .exec()
            return Promise.resolve(query)
          .catch(e => {console.log('error saveRowsInTable', e, tableName)})
    })
}
/*
*
*/
saveRowInTableDB (rowData, tableName, db) {
    let table = this.tables[tableName]
    let row = table.createRow(rowData)

    let query = db
      .insertOrReplace()
      .into(table)
      .values([row])
      .exec()
      return Promise.resolve(query)
          .then(resultsArray => {
            var insertedRow = resultsArray.pop()
            //console.log('saveRowInTable : ', tableName, rowData.id  );
            return insertedRow
        })
          .catch(e => {console.log('error saveRowInTable', e, rowData, tableName)})
}

/*
*
*/
saveRowInTable (rowData, tableName) {

   /*   if (this.db) {
          return(this.saveRowInTableDB(rowData, tableName, this.db));
      }
      else {*/
          return this.getDbConnection()
              .then(db => {
                return(this.saveRowInTableDB(rowData, tableName, db));
                })
      //}
  }


/*
*
*/
deleteRowInTable (id, tableName) {
        console.log('SteresDB::deleteRowInTable', id, tableName)
  return this.getDbConnection()
    .then(db => {

      let table = this.tables[tableName]

      let query = db
      .delete()
      .from(table)
      .where(table.id.eq(id))
      .exec()
      
     return Promise.resolve(query);
    })
    .catch(e => {console.log('error deleteRowInTable', e, id, tableName); return e})
}
/*
*
*/
purgeRowInTableSynchro (dateLimite) {
    var tableName = 'local_synchro';
    return this.getDbConnection()
    .then(db => {

      let table = this.tables[tableName]

      let query = db
      .delete()
      .from(table)
      .where(table.created2.lt(dateLimite))
   //   .where(table.id.eq(dateLimite))
      .exec()

     return Promise.resolve(query);
    })
    .catch(e => {console.log('error purgeRowInTableSynchro', e, dateLimite, table); return e})
}

/*
*
*/
deleteInTable(value, champ, tableName) {
  return this.getDbConnection()
  .then(db => {
    let table = this.tables[tableName]

    let query = db
        .delete()
        .from(table)
        .where(table[champ].eq(value))
        .exec();
      return Promise.resolve(query)
    });
  }

/*
*
*/
truncate(tableName) {

return this.getDbConnection()
    .then(db => {

      let table = this.tables[tableName]

      let query = db
      .delete()
      .from(table)
      .exec()

      return Promise.resolve(query);
    })
    .catch(e => {console.log('error truncate', e, tableName); return e})
}


/*
*
*/
getDbConnection () {
          // Les références sur les tables sont accessibles depuis le service SteresDB

    this.tables = LovefieldService.refs
/**/
    if(this.db) {
        //console.log('getDbConnection 1')
      return Promise.resolve(this.db);
    }
    else {
/**/
        return LovefieldService.getDbConnection()
            .then(db =>
                  {
                    this.db = db;
                    //console.log('getDbConnection 2')
                    // return db BC 14/10/2019 - la première lecture marchait quant elle voulait ...
                    return Promise.resolve(this.db);
                }
            )
    }
  }

/*
*/
  delete() {
    console.log("SteresDB::delete")
    var request = window.indexedDB.deleteDatabase(LovefieldService.dbName());

    request.onsuccess = function(e) {console.log("Base de données Steres supprimée", e)};
    request.onerror = function(e) {console.log("Erreur lors de la suppression de la BD Steres", e)};

  }
}


export const SteresDB = new SteresDBObj()
