
import { SteresConstantes } from "./constantes.js"
import { ApiConsts, ApiUser } from "../api/index.js"

//*********************************
class UF_PEUPLEMENT {

    constructor() {
        this.uf = null;
        this.annee = 0;
        this.cultureCode = "";
        this.essence = null; // en général "pin maritime"
        this.surfaceBoisee = 0; // hectares
        this.HMAX = 0; // asymptote de la hauteur
        this.calculOptimumPasse = false; // par défaut
    }

		//*********************************
    initialiser(uf, essence, infosPeuplement, infosPropriete) {
        if (uf) {
            this.uf = uf;
        }
        else { // par anticipation
            this.uf = {};
            this.uf.propriete = {};
            this.uf.propriete.constantesSteres = {};

        }
        this.essence = essence;
        this.HMAX = this.essence.HMAX;

        this.uf.propriete = infosPropriete;

        if (typeof(infosPeuplement.etatSteres) === 'undefined') {
            infosPeuplement.etatSteres = SteresConstantes.etatVALIDE;
            infosPeuplement.etatUtilisateur = 0;
        }

        Object.assign(this, infosPeuplement); // tout d'un coup
    }

		//*********************************
    compiler(uf, infosPropriete, constantes) {

        this.uf = uf;
        this.essence = ApiConsts.renseignerEssenceId(constantes.params, uf.peuplement.cultureId, uf.terroirId);
        this.HMAX = this.essence.HMAX;
        this.cultureCode = ApiUser.trouverItemTabConst(constantes.params.const_cultures, this.uf.peuplement.cultureId, "").code; // verrouille ci-dessous
        /*
        var lId = "id"+this.uf.peuplement.cultureId;
        this.cultureCode = constantes.params.const_cultures[lId].code;
        */

        SteresConstantes.initialiser(constantes.steres, constantes.abaqueEclaircies, constantes.repartitionBobi)

        this.uf.propriete = infosPropriete;
        this.uf.propriete.constantesSteres = SteresConstantes;

        if (typeof(this.uf.peuplement.etatSteres) === 'undefined') {
            this.uf.peuplement.etatSteres = SteresConstantes.etatVALIDE;
            this.uf.peuplement.etatUtilisateur = 0;
        }

        Object.assign(this, this.uf.peuplement); // tout d'un coup
        return(this);
    }

		//*********************************
        // densité à l'hectare
    calculerDensite() {
        var densite = 0;
        if (this.densite) {
            densite = this.densite;
        }
        else {
            densite = -1;
            //alert("densité nulle - pas de pins sur la parcelle !")
        }
        return(densite);
    }

		//*********************************
        // taux d'Actualisation qui peut être global ou celui de la propriété si défini
    lireTauxActualisation() {
        var tauxActualisation = this.uf.propriete.tauxActualisation;
         if ((typeof(tauxActualisation) === 'undefined') || !tauxActualisation) {
            tauxActualisation = this.uf.propriete.constantesSteres.tauxActualisation;
         }
        if (tauxActualisation >= 1) {
            tauxActualisation = tauxActualisation / 100;
        }
        return(tauxActualisation);
    }

    // densite min des eclaircies : (nb : en attente du paramètrage spécifique dans le peuplement du user)
    lireEclaircieDensiteMin() {
        if (this.eclaircieDensiteMin > 0) {
            return(this.eclaircieDensiteMin);
        }
        return(this.essence.eclaircieDensiteMin);
    }

    // volume min des eclaircies : (nb : en attente du paramètrage spécifique dans le peuplement du user)
    lireEclaircieVolumePourCentMin() {
        return(this.essence.eclaircieVolumePourCentMin);
    }


		//*********************************
        // tarifs qui peuvent être globaux ou ceux de la propriété si défini
    lireTarifs() {
        var tabTarifs = [];
        var tarifsJson = this.uf.propriete.tarifsJson;
         if ((typeof(tarifsJson) !== 'undefined') && (tarifsJson != "")) {
            tabTarifs = JSON.parse(tarifsJson); // déjà trié
         }
        else {
            tabTarifs = this.essence.tarifs;
            tabTarifs.sort(this.triTarif);
        }
        return(tabTarifs);
    }

    //*********************************
	triTarif(x, y) {
        if (x.anneeDebutValidite != y.anneeDebutValidite) {
            return(y.anneeDebutValidite - x.anneeDebutValidite); // tri descendant
        }
        return(x.volumeMoyen - y.volumeMoyen); // tri ascendant
    }

}

export const UfPeuplement = new UF_PEUPLEMENT()
export const UfPeuplement1 = new UF_PEUPLEMENT()
export const UfPeuplement2 = new UF_PEUPLEMENT()
