<template>
 <div id="peuplementEclaircieForm">
     <div id="tropJeune" v-if="isReady && !eclaircieOuDepressagePossible && !form.id">
         Le peuplement est trop jeune pour qu'il soit pertinent de réaliser une éclaircie.
         <br/>
         <span>{{infosDatesPossibles}}</span>

         <div class="boutonsValidation">
            <v-btn class="a-btn secondaire" type="button" @click="cancelForm">Annuler</v-btn>
        </div>
    </div>
     <form v-if="isReady && (eclaircieOuDepressagePossible || form.id)">

         <v-menu
            :close-on-content-click="false"
            v-model="lePicker"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
                 >
            <v-text-field
                slot="activator"
                label="Mois de l'éclaircie *"
                v-model="form.dateEclaircie"
                prepend-icon="event"
                readonly
                required
                :error-messages="error.dateEclaircie"
                ref="dateEclaircie"
            ></v-text-field>
            <v-date-picker
                no-title
                v-model="form.dateEclaircie"
                type="month"
                locale="fr"
                :min="moisMinimumPicker"
                :max="moisCourant"
                :date-format="date => new Date(date).toDateString()"
                @input="lePicker = false"
                :allowed-dates="getAllowedDates"
            ></v-date-picker>
        </v-menu>

         <p>Année de croissance retenue pour l'éclaircie : {{anneeRetenue}}</p>
         <p class="info">Nb : les éclaircies doivent être réalisées entre octobre et mars, en dehors de la période de croissance</p>

        <h2 class="titreListe blocACompleter" >&nbsp;</h2>
        <v-btn class="a-btn complementBloc" type="button" @click="enSavoirPlus=true;dialogESP = true;">Mesure : en savoir plus</v-btn>

        <h3 class="titreListe" >Densité après éclaircie *</h3>

         <calcul-densite
            @retourDensite="onRetourDensite"
            @retourSelectionMethode="onRetourSelectionMethode"
            :initDatas="infosCalculsDensite"
            :surfaceBoisee=peuplement.surfaceBoisee
            :initCirconference="circonferenceQuadratique"
            @click:clear="clickClear"
            required
            ref="calculDensite"
            :key="calculDensiteKey"
        />

         <div v-if="surfaceTerriereChoisi">
            <span>Circonférence quadratique : {{circonferenceQuadratique}} cm</span>
            <div v-if="lesCirconferences.length" class="itemIconeFct" @click.stop="circonferencesRenseignerSerie()">
                Série de circonférences :
                <span class="" v-for="(serieCirconferences, numSaisieCirconferences) in lesCirconferences" :key="numSaisieCirconferences" >
                     <span v-if="numSaisieCirconferences">, </span>
                     {{ serieCirconferences }}
                </span>
                <!-- <img class="iconeFct" alt="Editer la série" src="@/assets/img/crayon.svg" /> -->
                <i class="fa fa-pencil svg" aria-hidden="true"></i>
            </div>
            <div v-if="!lesCirconferences.length">
                <v-btn class="a-btn" type="button" @click="circonferencesRenseignerSerie">Saisir la série de circonférences</v-btn>
            </div>
        </div>

         <div v-if="eclairciePossible && !isDepressage">
             <h3 class="titreListe" >Hauteur après éclaircie</h3>
             <span>Hauteur moyenne : {{hauteurMoyenne}} m</span>

                 <div v-if="lesHauteurs.length" class="itemIconeFct" @click.stop="hauteursRenseignerSerie()">
                     Série de hauteurs :
                     <span class="" v-for="(serieHauteurs, numSaisieHauteurs) in lesHauteurs" :key="numSaisieHauteurs" >
                         <span v-if="numSaisieHauteurs">, </span>
                         {{ serieHauteurs }}
                    </span>
                    <!-- <img class="iconeFct" alt="Editer la série" src="@/assets/img/crayon.svg" /> -->
                    <i class="fa fa-pencil svg" aria-hidden="true"></i>
                </div>
                <div v-if="!lesHauteurs.length">
                    <v-btn class="a-btn " type="button" @click="hauteursRenseignerSerie">Saisir la série de hauteurs</v-btn>
                </div>

             <div v-if="!surfaceTerriereChoisi">
                <h3 class="titreListe" >Circonférence après éclaircie</h3>
                <span>Circonférence quadratique : {{circonferenceQuadratique}} cm</span>
                <div v-if="lesCirconferences.length" class="itemIconeFct" @click.stop="circonferencesRenseignerSerie()">
                    Série de circonférences :
                    <span class="" v-for="(serieCirconferences, numSaisieCirconferences) in lesCirconferences" :key="numSaisieCirconferences" >
                         <span v-if="numSaisieCirconferences">, </span>
                         {{ serieCirconferences }}
                    </span>
                    <!-- <img class="iconeFct" alt="Editer la série" src="@/assets/img/crayon.svg" /> -->
                    <i class="fa fa-pencil svg" aria-hidden="true"></i>
                </div>
                <div v-if="!lesCirconferences.length">
                    <v-btn class="a-btn" type="button" @click="circonferencesRenseignerSerie">Saisir la série de circonférences</v-btn>
                </div>
            </div>
        </div>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->

        <div class="boutonsValidation">
            <v-btn class="a-btn secondaire" type="button" @click="cancelForm">Annuler</v-btn>
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Enregistrer</v-btn>
        </div>
    </form>

    <v-dialog v-model="dialogError" v-if="errorDensite || errorHauteur || errorCirconference || errorDate" >
         <v-card>
             <v-card-title class="headline">Erreur de saisie</v-card-title>
             <v-card-text>
                 <span v-if="errorHauteur">{{errorHauteur}}</span>
                 <span v-if="errorCirconference">{{errorCirconference}}</span>
                 <span v-if="errorDensite">{{errorDensite}}</span>
                 <span v-if="errorDate">{{errorDate}}</span>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

     <v-dialog v-model="dialogESP" v-if="enSavoirPlus" content-class="grandeImageDialog">
         <v-card>
             <v-card-title class="headline">Comment réaliser une mesure</v-card-title>
             <v-card-text>
                 <div class="grandeImage">
                 <img  src="@/assets/img/mMesure.png" width="748px" />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" text @click="dialogESP=false;enSavoirPlus=false;">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <calcul-hauteur-serie v-if="hauteursSerieEnCours"
        @retourHauteursSerie="onRetourHauteursSerie"
        :initDatas="form"
        :surfaceBoisee=peuplement.surfaceBoisee
        :ouvrirDialog=hauteursSerieEnCours
    />
    <calcul-circonference-serie v-if="circonferencesSerieEnCours"
        @retourCirconferencesSerie="onRetourCirconferencesSerie"
        :initDatas="form"
        :surfaceBoisee=peuplement.surfaceBoisee
        :ouvrirDialog=circonferencesSerieEnCours
    />

</div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"

import CalculDensite from '../ui/mesures/CalculDensite.vue'
import CalculHauteurSerie from '../ui/mesures/CalculHauteurSerie.vue'
import CalculCirconferenceSerie from '../ui/mesures/CalculCirconferenceSerie.vue'


export default {

    components: {
        'calcul-densite': CalculDensite,
        'calcul-hauteur-serie': CalculHauteurSerie,
        'calcul-circonference-serie': CalculCirconferenceSerie
    },

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        },
        data2: {
            type: Object
        }
    },

    data() {
        return {
            calculDensiteKey :0,
            ready:false,
            uf:this.$store.getters.infosUf,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.data)),
            peuplement : this.data2,
            old: null,
            error: {
                dateEclaircie: '',
            },
            clear:{
                    id: '',
                    label: ''
                },
            lePicker: false,
            calculsDensite : {
                methodeId : 0,
                methodesPossibles : { // refaire avec table calc_densitemethodes ?
                    choix : false,
                    interlignes : true,
                    surfaceTerriere : true,
                    ratios : false,
                    saisieDirecte : true,
                },
                interlignes : {
                    nombre : '',
                    largeur : [],
                    longueur : '',
                    tabNombresArbres:[]
                },
                saisieDirecte : {
                    densite : ''
                },
                surfaceTerriere : {
                    surfaceTerriere : ''
                },
            },
            wait:{
                methodeId : 0,
                methodesPossibles : {
                    choix : false,
                    interlignes : true,
                    surfaceTerriere : true,
                    ratios : false,
                    saisieDirecte : true,
                },
                interlignes : {
                    nombre : '',
                    largeur : [],
                    longueur : '',
                    tabNombresArbres:[]
                },
                saisieDirecte : {
                    densite : ''
                },
            },
            hauteursSerieEnCours:false,
            circonferencesSerieEnCours:false,
            dialogError:false,
            errorCirconference:false,
            errorHauteur:false,
            errorDensite:false,
            errorDate:false,
            selectedMethode:-1,
            dialogESP:false,
            enSavoirPlus:false
        }
    },

    created() {
        this.initialiserCalculsDensite(this.isDepressage); // 20222/11

        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        //this.old = ApiTools.clone(this.form);
        this.initItems();

    },

    validations() {
        return {
            form: {
                dateEclaircie: {  required, maxLength: maxLength(7) },
            }
        }
    },

    watch: {
        isDepressage(newVal) {
            this.initialiserCalculsDensite(newVal);
            this.$emit('retourDepressage', newVal);
        }
    },

    computed: {
        moisCourant() {
            var lAnnee = new Date().getFullYear();
            var leMois = "" + (new Date().getMonth()+1);
            // var leMois = "" + (1+1); // pour test
            leMois.padStart(2, '0');
            return lAnnee+"-"+leMois
        },
        // ajout règle sur saisie mesure : 2022/10
        isDepressage() {
            if (this.eclairciePossible) {
                if (!this.form.dateEclaircie.length || this.form.dateEclaircie >= this.moisMinimum ) {
                    return(false);
                }
            }
            return(true);
        },
        borneCulture() {
            return(this.$store.getters.constantes.params.const_essence.borneMesureAutorisee);
        },
        infosDatesPossibles() {
            const infoEssence = this.$store.getters.constantes.params.const_essence;
            var infosDepressage = "";
            if (!this.depressagePossible) {
                infosDepressage = "les dépressages sont sans objet et ";
            }
            return("NB : pour un mode de culture en " +  infoEssence.libelleCulture + ", " +infosDepressage + "STERES ne prend en compte les éclaircies qu'au bout de " + this.$store.getters.constantes.params.const_essence.borneMesureAutorisee + " ans, soit en " + this.moisMinimum +".");
        },
        eclaircieOuDepressagePossible() {
            return(this.eclairciePossible || this.depressagePossible);
        },
        eclairciePossible() {
            if (this.moisMinimum > this.moisCourant) {
                return false;
            }
            return true;
        },
        depressagePossible() {
            if (this.moisMinimumDepressage
                && (this.peuplement.cultureCode == "SEMIS") || (this.peuplement.cultureCode == "SENAT")) {
                return true;
            }
            return false;
        },
        moisMinimumPicker() {
            if (this.depressagePossible) {
                return(this.moisMinimumDepressage);
            }
            return(this.moisMinimum);
        },
        moisMinimum() {
            const borneCulture = this.borneCulture;
            const anneeMois = this.uf.peuplement.dateInstallation.split('-');
            var annee = parseInt(anneeMois[0]) + borneCulture;
            return(""+annee+"-"+anneeMois[1]);
        },
        moisMinimumDepressage() {
            return this.uf.peuplement.dateInstallation
        },
        // fin ajout règle sur saisie mesure : 2022/10
        // l'année retenue peut être la précédente ( a priori si mois antérieur à juin)
        anneeRetenue() {
            var dateEclaircie = this.form.dateEclaircie;
            return(ApiUser.anneeRetenuePeuplement(dateEclaircie, this.$store.getters.constantes));
        },
        isReady() {
            if (!this.ready) {
                return(false);
            }
            return(this.ready);
        },
        surfaceTerriereChoisi() {
            return(this.selectedMethode == 2);
        },
        // pour le calcul de la densité
        infosCalculsDensite() {
            if (this.form.densiteMethodeId) {
                this.calculsDensite.methodeId = this.form.densiteMethodeId;
                var objJson = JSON.parse(this.form.densiteJson);
                if (this.form.densiteMethodeId == 1) {  // refaire avec table calc_densitemethodes ?
                    if (typeof(objJson.longueur) !== 'undefined') {
                        this.calculsDensite.interlignes = objJson;
                    }
                }
                if (this.form.densiteMethodeId == 2) {
                    if (typeof(objJson.surfaceTerriere) !== 'undefined') {
                        this.calculsDensite.surfaceTerriere = objJson;
                    }
                }
                if (this.form.densiteMethodeId == 4) {
                    if (typeof(objJson.densite) !== 'undefined') {
                        this.calculsDensite.saisieDirecte = objJson;
                    }
                }
            }
            return (JSON.parse(JSON.stringify(this.calculsDensite)));
            //return (this.calculsDensite)
        },

        // HAUTEURS
            // pour affichage "à jour" de la liste
        lesHauteurs() {
            return(this.form.tabHauteurs);
        },
        lesCirconferences() {
            return(this.form.tabCirconferences);
        },
        hauteurMoyenne() {
            if (!this.form.tabHauteurs.length) {
                this.form.hauteurMoyenne = 0;
                return 0;
            }
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const total = this.form.tabHauteurs.reduce(reducer);
            this.form.hauteurMoyenne = total / this.form.tabHauteurs.length;

            var hauteur = ApiTools.arrondir(this.form.hauteurMoyenne, 2);
            return hauteur;
        },
        circonferenceQuadratique() {
            if (!this.form.tabCirconferences.length) {
                this.form.circonferenceQuadratique = 0;
                return 0;
            }
            const reducer = (accumulator, currentValue) => accumulator + (currentValue*currentValue); // on fait la somme des carrés
            const totalCarres = this.form.tabCirconferences.reduce(reducer, 0);
            this.form.circonferenceQuadratique = Math.sqrt(totalCarres / this.form.tabCirconferences.length); // racine carré de la moyenne des carrés

            var circonferenceQuadratique = ApiTools.arrondir(this.form.circonferenceQuadratique, 2);
            return circonferenceQuadratique;
        },
    },

    methods: {
        initialiserCalculsDensite(isDepressage) {
            if (isDepressage) {
                this.calculsDensite.methodeId = 4;
                this.calculsDensite.methodesPossibles.interlignes = false;
                this.calculsDensite.methodesPossibles.surfaceTerriere = false;
                //this.calculsDensite.choix = ; // ?
            }
            else {
                this.calculsDensite.methodesPossibles.interlignes = true;
                this.calculsDensite.methodesPossibles.surfaceTerriere = true;
            }
            this.calculDensiteKey += 1; // pour rafraichir
        },

        getAllowedDates (val) {
            var mois = val.substring(5);
            var moisAutorises = ["01", "02", "03", "10", "11", "12"];
            if (moisAutorises.indexOf(mois) !== -1) {
              return true
            } else {
              return false
            }
        },
        //
        initItems() {
            var constantes = this.$store.getters.constantes;
            this.selectedMethode = this.form.densiteMethodeId;
            this.ready = true;
        },
                // gestion du calcul de densite
        onRetourDensite(densiteObj) {
            this.form.densiteMoyenne = densiteObj.densite;
            this.form.densiteMethodeId = densiteObj.methodeId;

            this.form.densiteJson = "{}";
            if (densiteObj.methodeId == 1) {
                this.form.densiteJson = JSON.stringify(densiteObj.interlignes); // JSON.parse à l'aller
            }
            if (densiteObj.methodeId == 2) {
                this.form.densiteJson = JSON.stringify(densiteObj.surfaceTerriere); // JSON.parse à l'aller
            }
            if (densiteObj.methodeId == 4) {
                this.form.densiteJson = JSON.stringify(densiteObj.saisieDirecte); // JSON.parse à l'aller
            }
        },
        onRetourSelectionMethode(idMethod) {
            this.selectedMethode = idMethod;
        },

        clickClear() {

        },

                // HAUTEURS
            // edition d'une série
        hauteursRenseignerSerie() {
            this.hauteursSerieEnCours = true;
        },
        onRetourHauteursSerie(tabSerie) {
            var tab = [];
            if (tabSerie && (tabSerie.length > 0) && (tabSerie[0] != '')) {
                this.form.tabHauteurs = tab.concat(tabSerie);
            }
            else {
                var tabTMP = [];
                this.form.tabHauteurs = tab.concat(tabTMP);
            }

            this.form.hauteurJson = JSON.stringify(this.form.tabHauteurs); // JSON.parse à l'aller
            this.hauteursSerieEnCours = false;
        },
            // CIRCONFERENCES
            // edition d'une série
        circonferencesRenseignerSerie() {
            this.circonferencesSerieEnCours = true;
        },
        onRetourCirconferencesSerie(tabSerie) {
            var tab = [];
            if (tabSerie && (tabSerie.length > 0) && (tabSerie[0] != '')) {
                this.form.tabCirconferences = tab.concat(tabSerie);
            }
            else {
                var tabTMP = [];
                this.form.tabCirconferences = tab.concat(tabTMP);
            }

            this.form.circonferenceQuadratiqueJson = JSON.stringify(this.form.tabCirconferences); // JSON.parse à l'aller
            this.circonferencesSerieEnCours = false;
        },

        // annulation du formulaire
        majCalculsDensite(objGlob, calculsDensite) {
            objGlob.calculsDensite = calculsDensite;
        },
        cancelForm() {
            // on remet les données anciennes dans 'form'
            this.form = JSON.parse(JSON.stringify(this.old))
            /* PAS UTILE SI ON QUITTE LA PAGE
            // on force le rafraichissement
            var objSav = Object.assign({}, this.calculsDensite);
            var objTmp = Object.assign({}, this.wait);
            this.calculsDensite = objTmp;
            setTimeout(this.majCalculsDensite.bind(null, this, objSav), 250);
            this.reinitErrorMessages();
            */

            // on prépare le message passé à la prochaine page
            var message = "La saisie de l'eclaircie a été abandonnée.";
            // on va au bon endroit avec les bonnes valeurs
            this.terminerEdition(message);
        },


        // enregistrement du formulaire
        async saveForm() {

            // on reinit à vide les messages d'erreur
            this.reinitErrorMessages();
/*
            if (!this.validerAnneeEclaircie()) {
                return false
            }
*/
            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (this.$v.$invalid) {
                // maj erreurs
                this.dateEclaircieErrors();
                return false
            }


            // si date vide
            if (!this.form.dateEclaircie) {
                this.dialogError = true;
                this.errorDate = "Attention ! Vous devez saisir une date d'éclaircie.";
                return;
            }

            // si date déjà utilisée
            if (!ApiUser.validerAnneeEclaircie(this.form.id, this.anneeRetenue, this.peuplement, this, ApiTools)) {
                this.dialogError = true;
                return;
            }

            // si densité nulle
            if (!this.form.densiteMoyenne) {
                this.dialogError = true;
                this.errorDensite = "Attention ! La densité moyenne calculée pour l'éclaircie ne peut être nulle ! Revoyez vos saisies.";
                return;
            }

            // si densité trop forte
            if (!ApiUser.validerDensiteEclaircie(this.form.id, this.form.densiteMoyenne, this.anneeRetenue, this.peuplement, this, ApiTools)) {
                this.dialogError = true;
                return;
            }

            // si hauteur incohérente
            if ( (this.form.hauteurMoyenne) && (!ApiUser.validerHauteurEclaircie(this.form.id, this.form.hauteurMoyenne, this.anneeRetenue, this.peuplement, this, ApiTools)) ) {
                this.dialogError = true;
                return;
            }

            // si circonférence incohérente
            if ( (this.form.circonferenceQuadratique) && (!ApiUser.validerCirconferenceEclaircie(this.form.id, this.form.circonferenceQuadratique, this.anneeRetenue, this.peuplement, this, ApiTools)) ) {
                this.dialogError = true;
                return;
            }


            // si pas de modification des données
            if (JSON.stringify(this.old) === JSON.stringify(this.form)) {
                this.terminerEdition();
                return false
            }

            // on sauvegarde (distant et local)
            this.old = JSON.parse(JSON.stringify(this.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
            this.form.modeForet = this.$store.getters.modeForet;
            this.form.modeForetUfId = this.$store.getters.ufId;
            var entityToSave = JSON.parse(JSON.stringify(this.form))
            console.log("saveForm OK")
            //const response = await ApiUser.setEclaircie(entityToSave) // on sauvegarde notre mesure (en DB locale et SI POSSIBLE en ligne)
//            await ApiUser.setEclaircie(entityToSave) // on sauvegarde notre mesure (en DB locale et SI POSSIBLE en ligne)
            return ApiUser.setEclaircie(entityToSave) // on sauvegarde notre mesure (en DB locale et SI POSSIBLE en ligne)
            .then(response => {
                // on prépare le message passé à la prochaine page
                var message = "";
                message = "La saisie de l'éclaircie a bien été enregistrée.";
                /*
                if (navigator.onLine) {
                    message = "La saisie de l'éclaircie a bien été enregistrée.";
                }
                else {
                    message = "La saisie de l'éclaircie a bien été enregistrée. Attention, vous travaillez hors ligne, il vous faudra donc synchroniser cette saisie ultérieurement avec la base de     données Stères.";
                }
                */
                // on met à jour les infos de l'éclaircie
                if ( (typeof(response) !== 'undefined') && response.id) {
                    this.$store.commit('setEclaircieInfos', response);
                }

                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message);
            })
        },

        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message) {
            if (message) {
                this.$store.commit('donnerMessage', message);
            }
            //this.$store.commit('setEclaircieId', 0); // tmp
            //this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplement" }); // tmp
        },
/*
        validerAnneeEclaircie() {
            var tabEclaircies = this.uf.peuplement.eclaircies;
            var ok = true;
            tabEclaircies.forEach(eclaircie => {
                var anneeRetenue = ApiUser.anneeRetenuePeuplement(eclaircie.dateEclaircie, this.$store.getters.constantes);
                if ((anneeRetenue == this.anneeRetenue) && (eclaircie.id != this.form.id)) {
                    ok = false;
                }
            })
            if (!ok) {
                this.error.dateEclaircie  = "Une éclaircie est déja enregistrée pour ce peuplement en " + this.anneeRetenue;
                //this.$v.form.dateEclaircie.$invalid = true;
            }
            return(ok);
        },
*/
        // validation du formulaire
        reinitErrorMessages() {
            this.error.dateEclaircie = ''
            this.errorDensite = false
            this.errorHauteur = false
            this.errorCirconference = false
            this.errorDate = false
        },
        dateEclaircieErrors () {
            if (!this.$v.form.dateEclaircie.$invalid) return false // verifier invalid
            if (!this.$v.form.dateEclaircie.maxLength) {
                this.error.dateEclaircie  = "Le format de la date est 1999-12."
            }
            if (!this.$v.form.dateEclaircie.required) {
                 this.error.dateEclaircie  = "La saisie d'une date est obligatoire." // NON !!!
            }
            return true
        },
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },

        fermerDialogError() {
            this.dialogError = false;

            if (this.errorDate) {
                this.$nextTick(() => {
                    this.$refs.dateEclaircie.focus();
                })
            }
            else {
                if (this.errorDensite) {
                    this.$nextTick(() => {
                        if (typeof(this.$refs.calculDensite.$refs.densiteA) !== 'undefined') {
                            this.$refs.calculDensite.$refs.densiteA.focus(); // on donne le focus au premier item de saisie densité (dépend de la méthode)
                        }
                    })
                }
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    #app {
        #tropJeune {
            font-size:0.94em;
            margin-top: -50px;
            background-color: orangered;
            color:white;
            padding : 1em;
        }

        #peuplementEclaircieForm {

            h2.blocACompleter {
                max-width:50px;
                /*
                font-size:1.2em;
                font-style: italic;
                text-align: right;
                */
            }

            .complementBloc {
                font-size:0.8em;
                height: 30px;
                float:right;
                 margin: 1em 0;
            }
        }

        .grandeImageDialog {
            max-width: 900px;
            overflow:hidden;
            div {
                position:relative;
                overflow:hidden;
            }

            .grandeImage {
                overflow: scroll;
                text-align: center;
                height: auto;
                max-height: 70vh;
                max-width:770px;
                margin: 0 auto;

            img {
                width:auto;
                height:auto;
                max-width: none;
                }
            }
        }
    }
</style>
