import lf from 'lovefield'

export function local_synchro (schemaBuilder) {
    schemaBuilder.createTable('local_synchro')
        .addColumn('id', lf.Type.INTEGER)
        .addColumn('ok', lf.Type.INTEGER)
        .addColumn('json', lf.Type.STRING)
        .addColumn('created', lf.Type.STRING)
        .addColumn('created2', lf.Type.INTEGER)
        .addPrimaryKey(['id'], true);
}

