import lf from 'lovefield'

export function calc_eclaircies (schemaBuilder) {
  schemaBuilder.createTable('calc_eclaircies')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('circonference', lf.Type.INTEGER)
      .addColumn('densiteMax', lf.Type.INTEGER)
      .addColumn('densiteMin', lf.Type.INTEGER)
      .addPrimaryKey(['id'], true); // attention, le fait de mettre un id ralenti singulièrement le readAll sur cette table et vu qu'on n'y accède jamais par id ...
}
