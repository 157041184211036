import lf from 'lovefield'

export function user_proprietes (schemaBuilder) {
  schemaBuilder.createTable('user_proprietes')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('gestionnaireId', lf.Type.INTEGER)
      .addColumn('proprietaireId', lf.Type.INTEGER)
      .addColumn('saisieHorsLigneId', lf.Type.INTEGER) // TODOFORET
      .addColumn('proprietaireDroitEcriture', lf.Type.INTEGER)
      .addColumn('ayantsDroitJson', lf.Type.STRING)
      .addColumn('identifiant', lf.Type.STRING)
      .addColumn('adresse', lf.Type.STRING)
      .addColumn('codePostal', lf.Type.STRING)
      .addColumn('libelleCommune', lf.Type.STRING)
      .addColumn('codeCommune', lf.Type.STRING)
      .addColumn('telephone', lf.Type.STRING)
      .addColumn('adresseMail', lf.Type.STRING)
      .addColumn('siret', lf.Type.STRING)
      .addColumn('version', lf.Type.INTEGER)
      .addColumn('tauxActualisation', lf.Type.DOUBLE)
      .addColumn('depensesJson', lf.Type.STRING)
      .addColumn('tarifsJson', lf.Type.STRING)
      .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon)
      .addColumn('bloquee', lf.Type.INTEGER)
      .addPrimaryKey(['id'], false)
      .addNullable(['bloquee'])
      .addNullable(['idLocal']);
}
