<template>
    <div id="navHeader">
        <div class="navConteneur">
            <div v-if="!this.$store.getters.modeForet">
                <router-link to="/presentation" class="routeEnCours itemHeader" >Présentation de STERES</router-link>
                <span v-if="0" class="itemHeader"><i class="fas fa-circle"></i></span>
                <router-link v-if="0" to="/densite" class="routeEnCours itemHeader" >Outil Densité</router-link>
                <span v-if="testMenuConnexion" class="itemHeader"><i class="fas fa-circle"></i></span>
                <router-link v-if="testMenuConnexion" to="/" class="routeEnCours itemHeader" >Connexion</router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        /**
         * Données à injecter
         */
        menuConnexion: String,
    },
    data() {
        return {
            // init les données du form à partir des props
            testMenuConnexion : parseInt(this.menuConnexion)
        }
    },
    created() {
        var toto = 1;
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style lang="scss">
    #app {
        div#nav.header {
            .navConteneur {
                text-align: right;

                #navHeader {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size:0.7em;


                    .navConteneur {
                        padding: 5px 0 0;
                        /*
                        min-height: 68px;
                        */

                        span.routeEnCours, a.routeEnCours {
                            border-bottom: none;
                            height: auto;
                            display: inline-block;
                        }

                        a {
                            &.router-link-exact-active {
                            /*color: #42b983;*/
                            }
                            &:hover {
                                color:#7DAB87;
                            }
                        }
                    }
                }
            }
        }

    }
</style>

