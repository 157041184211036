<style>
/*
    @import './Courbes.css';
*/
</style>

<template>

  <div id="conditionsGenerales" class="home containerLog">
    <div class="sectionAffichage">
        <div class="conteneur">

            <h1 >Conditions générales d’utilisation et de vente</h1>

            <h2>Article 1 : Objet</h2>
            <p>
Les présentes conditions générales d'utilisation et de vente régissent l’utilisation du Site Internet STERES (https://www.steres.fr) proposant des outils d’aide à la décision sylvicole.<br/>
Tout accès au Site et tout abonnement ou réabonnement implique l’adhésion entière et sans réserve de l’utilisateur aux présentes conditions générales.
            </p>
            <h2>Article 2 : Mentions légales</h2>
            <p>
L’édition du Site STERES est assurée par le CPFA (Centre de productivité et d'action forestière d'Aquitaine) dont le siège social est localisé au 6 parvis des Chartrons, 33075 Bordeaux.<br/>
L’hébergeur du Site STERES est la société ADDEO, sise au 406 Boulevard Jean Jacques Bosc, 33130 Bègles.
            </p>
            <h2>Article 3 : Accès au Site</h2>
            <p>
L’utilisateur peut consulter différents types de contenus proposés par le Site, dont ceux en accès libre d’une part, et ceux accessibles par des abonnements payants d’autre part.
            </p>
            <h2>Article 4 : Utilisation du Site et de ses services</h2>
            <p>
Le Site est accessible gratuitement en tout lieu à tout utilisateur ayant un accès internet et en ayant fait la demande auprès du CPFA. Tous les frais supportés par l'utilisateur pour accéder au service (matériel informatique, logiciels, connexion internet, etc.) sont à sa charge.<br/>
L'utilisateur non abonné n'a pas accès aux services réservés qui sont payants.<br/>
L’abonnement permet d’accéder à l’intégralité des contenus du Site réservés aux abonnés.<br/>
Pour être abonné, l’utilisateur doit s'inscrire aux services réservés en se rendant sur la page de connexion du Site et utilisant le lien « Pas d'identifiant ? Contactez-nous ! ». L’administrateur du Site STERES après avoir collecté ses coordonnées, adresse alors à l’abonné un courriel lui confirmant son abonnement, avec son identifiant et son mot de passe. Il peut exister un délai entre la validation sur le Site de l’abonnement, la réception des modalités d’accès et la mise en place effective des accès, compte tenu notamment des impératifs techniques.
Pour accéder aux services réservés, l’abonné doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués par email après son inscription.
            </p>
            <h2>Article 5 : Conditions tarifaires</h2>
            <p>
L’offre d’abonnement STERES en vigueur est disponible auprès de l’administrateur du Site STERES (info@cpfa.com). Un tarif préférentiel est appliqué à partir de la date de lancement de STERES, dans la limite des 10 000 premiers hectares inscrits.<br/>
Le tarif des abonnements vendus est celui en vigueur au jour de la prise de commande. Il est libellé en euros et calculé hors taxes. Par voie de conséquence, il sera majoré du taux de TVA.
STERES s’accorde le droit de modifier ses tarifs à tout moment.<br/>
Les abonnements ne comprennent pas les coûts de l’équipement, des logiciels et des communications électroniques, nécessaires à la souscription et à l’utilisation des services ; lesdits coûts restent à la charge de l’abonné.<br/>
Les abonnements sont prépayés sur l’année civile et au prorata temporis, dus en tout état de cause, qu’ils soient ou non utilisés par l’utilisateur bénéficiaire, sans que l'abonné ne puisse prétendre à aucun remboursement.
            </p>
            <h2>Article 6 : Modalité de paiement</h2>
            <p>
Le règlement des commandes s’effectue en euros. Deux modalités de paiement sont proposées :
            </p>
            <ul>
                <li>soit par virement bancaire ;</li>
                <li>soit par chèque ;</li>
            </ul>
            <p>
Le règlement se fait à la commande. Il s’ensuit la délivrance d’une facture au format PDF.
            </p>
            <h2>Article 7 : Renouvellement de l’abonnement</h2>
            <p>
15 jours avant la date d’échéance de l’abonnement, soit au 15 décembre, l’abonné reçoit sa facture de renouvellement, payable a échéance de 30 jours, soit avant le 15 Janvier de l’année suivante.<br/>
Si le paiement n’a pas été effectué a échéance, une première et dernière relance est adressée à l’abonné, avec une nouvelle échéance de 30 jours.<br/>
En cas de non paiement à l’échéance de la deuxième relance, l’accès au Site est suspendu, jusqu’à reprise des paiements, dans les conditions fixées à l’article 8. L’abonné en est averti par email.
            </p>
            <h2>Article 8 : Rétractation et résiliation</h2>
            <p>
L’abonné est un professionnel de la sylviculture et ne peut donc être qualifié de "consommateur" au sens des textes en vigueur. Il ne bénéficie donc pas des dispositions protectrices du code de la consommation, notamment celles prévoyant un droit de rétractation.<br/>
L’abonnement est souscrit pour une durée déterminée d’un an à compter du 1er janvier et ne peut pas être résilié avant la fin de l’année civile en cours. Chaque année civile commencée est due en totalité.<br/>
Si après une interruption de son abonnement l’utilisateur souhaite se réabonner sans avoir à saisir à nouveau ses anciennes données, STERES pourra se charger de les réintégrer moyennant le règlement de frais de restauration équivalents au tarif annuel d’abonnement facturé avant l’interruption multiplié par le nombre d’années d’interruption.
            </p>
            <h2>Article 9 : Maintenance et Assistance</h2>
            <p>
STERES peut être amené à interrompre l’accès au Site et à ses services en tout ou partie pour des raisons de maintenance nécessaire au bon fonctionnement. L’utilisateur sera prévenu dans la mesure du possible, et sous réserve du caractère urgent de l’opération, par une information personnelle ou, à tout le moins, par information sur le Site.<br/>
Ces interruptions pour maintenance n’ouvriront droit à aucune indemnité et le Site et ses services seront accessibles dès la fin de l’opération de maintenance.<br/>
En cas de difficulté technique lié au Site et à ses services, l’utilisateur peut contacter STERES à l’adresse suivante : info@cpfa.com. L’assistance est exclusivement réservée aux questions liées aux services du Site, à l’activation, l’installation, le dysfonctionnement et aux éventuelles mises à jour du Site.
            </p>
            <h2>Article 10 : Propriété intellectuelle</h2>
            <p>
Les marques, logos ainsi que les contenus du site STERES (illustrations graphiques, textes…) sont protégés par le code de la propriété intellectuelle et par le droit d’auteur.
La reproduction et la copie des contenus par l’utilisateur requièrent une autorisation préalable du Site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.
            </p>
            <h2>Article 11 : Données à caractère personnel</h2>
            <p>
STERES s’engage à respecter la législation en vigueur en matière de protection des données à caractère personnel et notamment la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés ainsi que le règlement européen (UE) 2016/679 du 25 avril 2016 du Parlement et du Conseil relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel.<br/>
Pour plus d’informations sur les traitements de données à caractère personnel mis en œuvre par STERES dans le cadre du Site, l’utilisateur peut se référer à la <router-link to="/donneespersonnelles" class="routeEnCours" >politique de confidentialité du Site</router-link>.
            </p>
            <h2>Article 12 : Responsabilités</h2>
            <p>
Les informations économiques et techniques liées aux peuplements et aux terrains calculées par STERES sont présentées à titre indicatif et général sans valeur contractuelle. Elles sont réputées fiables mais le Site ne garantit pas qu'elles soient exemptes de défauts, d'erreurs ou d'omissions.<br/>
Le service du Site, en particulier l’aide à l’optimisation économique, est constitutif d’une obligation de moyens au sens des dispositions du code civil. STERES ne garantit pas expressément ou implicitement le rendement obtenu. Les résultats et prévisions de STERES sont des simulations prévisionnelles.<br/>
L’utilisateur est seul responsable de l’utilisation qu’il fait de STERES et des données qu’il rentre.<br/>
Le Site ne peut être tenu responsable de l'utilisation et de l'interprétation de l'information contenue sur le Site.
            </p>
            <h2>Article 13 : Force majeure</h2>
            <p>
La responsabilité de STERES ne pourra pas être mise en œuvre si la non-exécution ou le retard dans l’exécution de l’une de ses obligations décrites dans les présentes conditions générales découle d’un cas de force majeure. À ce titre, la force majeure s’entend de tout événement extérieur, imprévisible et irrésistible au sens de l’article 1218 du code civil.
            </p>
            <h2>Article 14 : Droit applicable et juridiction compétente</h2>
            <p>
Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux.
</p>

        </div>
    </div>

  </div>

</template>

<script>


export default {

    name: 'conditions-generales',
    components: {

    },
    data() {
        return {
          //  svgContent: "",
        }
    },
    mounted: function () {
    },
    updated: function () {

    },
    computed: {

    },
    methods: {

    }
}


</script>


<style lang="scss">
    #app {
        #conditionsGenerales {
            text-align: center;

            div.sectionAffichage {
                max-width: 640px;
                margin-left:auto;
                margin-right:auto;

                @media screen and (max-width: 840px) {
                    margin-left:20px;
                    margin-right:20px;
                }

                .conteneur {
                    text-align:justify;

                    h1, p, ul {
                        text-align: justify;
                    }

                    a {
                        color:#238b71;
                        font-weight:bold;
                    }

                    .bloc {
                        margin-top:3em;
                    }

                    .auteur {
                        font-weight: bold;
                    }

                    img {
                        max-height: 130px;
                        width:auto;
                        margin:1em auto 1em;

                        &.egal {
                        }
                        &.grandLogo {

                            @media screen and (max-width: 750px) {
                                max-height: 100px;
                                display:block;
                                margin:1em auto;
                            }
                        }

                        &.petitLogo {
                            margin-top:1em;;
                            max-height: 65px;
                        }

                        vertical-align: middle;
                    }
                }
            }
        }
    }

</style>
