<template>
    <div id="carboneVisualisation" class="tests conteneurTable" v-if="testDatasReady">

        <div v-if="itEnCours" class="containerCourbes" v-html="contentSVG3"></div>

        <div v-if="tabReady">
            <div class="aGauche entete">
                <h3 class="centrer">Informations chiffrées : {{itEnCours.identifiant}}</h3>
                <h4>Unité forestière : {{ppl.uf.identifiant}} - Surface boisée : {{formaterSurface(ppl.surfaceBoisee)}} ha</h4>
                <span>Coupe Rase à {{laCoupeRase}} ans<i class="fas fa-arrow-right"></i><span v-if="volumeCoupeRase"> Volume : {{volumeCoupeRase}} m3 - Volume Unitaire : {{volumeUnitaireCoupeRase}} m3 - </span>Valeur : {{valeurCoupeRase}}</span>
            </div>

            <table class="carbone">
<thead>
                <tr class="lien" @click.stop="exporterTableau(laTabValeursExportees)">
                    <th colspan="2"><i class="fas fa-download"></i></th>
                    <th colspan="2">Caractéristiques du peuplement sur pied</th>
<!--
                    <th colspan="4">Caractéristiques du peuplement sur pied</th>
                    <th colspan="3">Caractéristiques de la coupe réalisée</th>
                    <th colspan="2">Cumuls</th>
                    <th colspan="3">Ratios carbone</th>
 -->
                    <th colspan="11">Carbone</th>
                </tr>
                <tr>
                    <th >Année</th>
                    <th >Age</th>

                    <th >Volume unitaire<span class="uniteValeur">(m3)</span></th>
                    <th >Volume<span class="uniteValeur">(m3 /&nbsp;ha)</span></th>
<!--
                    <th >Volume unitaire<span class="uniteValeur">(m3)</span></th>
                    <th ><br/>Prix<span class="uniteValeur">(au m3)</span></th>
                    <th >Valeur sur pied<span class="uniteValeur">(/ha)</span></th>

                    <th >Volume récolté<span class="uniteValeur">(m3/ha)</span></th>
                    <th ><br/>Prix<span class="uniteValeur">(au m3)</span></th>
                    <th >Recette Coupe<span class="uniteValeur">(€/ha)</span></th>

                    <th >Recettes cumulées</th>
                    <th >Dépenses cumulées</th>

                    <th >BNA</th>
                    <th >ACE</th>
                    <th >Valeur d'attente</th>
 -->
<!-- -->
<th>Biomasse totale<span class="uniteValeur">(t MS /&nbsp;ha)</span></th>
<th>Stocké en forêt<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Flux Bo<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Flux Pan<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Flux Pap<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Flux Carbone<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Stock Bo<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Stock Pan<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Stock Pap<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Stocké en Produits bois<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<th>Substitué<span class="uniteValeur">(t eq C /&nbsp;ha)</span></th>
<!-- -->
                </tr>
</thead>
<tbody>
                <tr v-bind:class="{anneeCourante : valeur.anneeCouranteClass, mesures: valeur.mesuresClass,   eclaircieProp: valeur.eclairciePropClass,  eclaircie: valeur.eclaircieClass,   concurrence: valeur.concurrenceClass, finInstallation:dataFinInstallation.age==valeur.anneeDate- ppl.anneeDateInstallation, anneeCoupeRase: laCoupeRase==index}"  v-for="(valeur, index) in laTabValeursAffichees" :key="index" >
<!--
                    <td class="aDroite">{{ valeur.anneeDate }}</td>
                    <td class="aDroite">{{ valeur.anneeDate - ppl.anneeDateInstallation }}</td>
 -->
                        <td class="aDroite">{{ ppl.anneeDateInstallation+index}}</td>
                        <td class="aDroite">{{ index }}</td>

                    <td class="aDroite">{{ formaterVolume(valeur.volumeUnitaire) }}</td>
                    <td class="aDroite">{{ formaterVolume(valeur.volume) }}</td>
<!--
                    <td class="aDroite">{{ formaterEuros(valeur.prixM3) }}</td>
                    <td class="euro aDroite"><span v-if="valeur.recettePotentielleCR">{{ formaterEuros0(valeur.recettePotentielleCR) }}</span></td>

                    <td class="aDroite">
                        <span v-if="valeur.volumeRecolteEclaircie  && ((valeur.anneeDate - ppl.anneeDateInstallation) > dataFinInstallation.age)">{{ formaterVolume(valeur.volumeRecolteEclaircie) }}</span>
                        <span v-if="laCoupeRase == (valeur.anneeDate - ppl.anneeDateInstallation)">{{ formaterVolume(valeur.volume) }}</span>
                    </td>
                    <td class="aDroite">
                        <span v-if="valeur.volumeRecolteEclaircie">{{ formaterEuros(valeur.prixEclaircieM3) }}</span>
                        <span v-if="laCoupeRase == (valeur.anneeDate - ppl.anneeDateInstallation)">{{ formaterEuros(valeur.prixM3) }}</span>
                    </td>
                    <td class="euro aDroite">
                        <span v-if="valeur.recetteEclaircie">{{ formaterEuros(valeur.recetteEclaircie) }}</span>
                        <span v-if="laCoupeRase == (valeur.anneeDate - ppl.anneeDateInstallation)">{{ formaterEuros(valeur.recettePotentielleCR) }}</span>
                    </td>

                    <td class="euro aDroite"><span>{{ formaterEuros(valeur.recettesCumulees) }}</span></td>
                    <td class="euro aDroite"><span>{{ formaterEuros(valeur.depensesCumulees) }}</span></td>

                    <td class="euro aDroite"><span v-if="valeur.BNA">{{ formaterEuros(valeur.BNA) }}</span></td>
                    <td class="euro aDroite"><span v-if="valeur.ACE">{{ formaterEuros(valeur.ACE) }}</span></td>
                    <td class="euro aDroite"><span v-if="valeur.valeurAttente > 0">{{ formaterEuros0(valeur.valeurAttente) }}</span></td>
 -->
<!-- -->
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.biomasseAerienne + valeur.biomasseRacinaire) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.carboneSequestre) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.fluxCarboneExportes.fluxBo) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.fluxCarboneExportes.fluxPan) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.fluxCarboneExportes.fluxPap) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.fluxCarboneExportes.fluxCarbone) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.stockageCarboneExportes.carbBo) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.stockageCarboneExportes.carbPan) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.stockageCarboneExportes.carbPap) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.carboneStocke) }}</td>
<td v-if="valeur.carboneSequestre" class="aDroite">{{ formaterTecTab(valeur.carboneSubstitue) }}</td>
<!-- -->

                </tr>
</tbody>
            </table>

        </div>

    </div>
</template>


<script>

import { ApiTools } from "../../js/api/index.js"
import * as SteresConst from '../../js/steres_const.js'

//import { SteresConstantes } from "../../js/calculs/constantes.js"
import { UfPeuplement } from "../../js/calculs/peuplementObj.js"
//import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObj.js'
import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObjTER.js'
import { UfPplFinancier } from '../../js/calculs/financierObj.js'
import { UfPplCarbone } from '../../js/calculs/carboneObj.js'

import { courbeSVG } from "../../js/svg/svgCourbes.js"

import { addeoData } from "../../js/api/datas.js"

export default {


    props: {
        /**
         * Données à injecter (uf)
         */
    },

    data(){
        return {
            itAffichage: null,
            datasReady:false,
            tabReady:false,
            constantes : {},
            ppl : {},
            dataFinInstallation : {},
            tabValeurS:[],
            svgContent3: "",
            courbe3: null,
            tabValeursAffichees:null,
        }
    },

    created: function () {
        return(0);
    },

    mounted: function () {
        this.datasReady = true;
        return(0);
    },

    updated: function () {
    },

    watch: {
        itEnCours(newValue) {
            if(newValue) {
                this.lancerAffichage(newValue)
            }
        }
    },

    computed: {
        laTabValeursAffichees() {
            // return(this.tabValeursAffichees.filter(valeur => valeur.anneeDate - this.ppl.anneeDateInstallation <= this.laCoupeRase)); /* si on veut arreter à la CR */
            return(this.tabValeursAffichees);
        },

        laTabValeursExportees() {

            var anneCoupeRase = this.laCoupeRase;
            var anneeDateInstallation = this.ppl.anneeDateInstallation
            var tab = [];
            this.tabValeursAffichees.forEach((valeur, index) => {
                var obj = {};
                obj.annee = this.exporterValeur(this.ppl.anneeDateInstallation+index);
                obj.age = this.exporterValeur(index);
                /*
                obj.annee = this.exporterValeur(valeur.anneeDate);
                obj.age = this.exporterValeur(valeur.anneeDate - anneeDateInstallation);
                */
                obj.volumeSurPied = this.exporterValeur(valeur.volume);
                obj.volumeUnitaire = this.exporterValeur(valeur.volumeUnitaire);
/*
                obj.prix = this.exporterValeur(valeur.prixM3);
                obj.valeurSurPied = this.exporterValeur((valeur.recettePotentielleCR?valeur.recettePotentielleCR:0));
                obj.volumeRecolteCoupe = this.exporterValeur((valeur.volumeRecolteEclaircie?valeur.volumeRecolteEclaircie:(anneCoupeRase == (valeur.anneeDate - anneeDateInstallation) ? this.formaterVolume(valeur.volume) : '')));
                obj.prixCoupe = this.exporterValeur((valeur.volumeRecolteEclaircie?valeur.prixEclaircieM3:(anneCoupeRase == (valeur.anneeDate - anneeDateInstallation) ? valeur.prixM3 : '')));
                obj.recetteCoupe = this.exporterValeur((valeur.recetteEclaircie?valeur.recetteEclaircie:(anneCoupeRase == (valeur.anneeDate - anneeDateInstallation) ? valeur.recettePotentielleCR : '')));
                obj.recettesCumulees = this.exporterValeur(valeur.recettesCumulees);
                obj.depensesCumulees = this.exporterValeur(valeur.depensesCumulees);
                obj.BNA = this.exporterValeur(valeur.BNA);
                obj.ACE = this.exporterValeur(valeur.ACE);
                obj.valeurAttente = this.exporterValeur((valeur.valeurAttente > 0?valeur.valeurAttente:''));
*/

// carbone (TMP)
                obj.carbone_biomasseAerienne = this.exporterValeur(valeur.biomasseAerienne);
                obj.carbone_biomasseRacinaire = this.exporterValeur(valeur.biomasseRacinaire);
                obj.carbone_biomasse = this.exporterValeur(valeur.biomasseAerienne + valeur.biomasseRacinaire);
                obj.carbone_carboneSequestre = this.exporterValeur(valeur.carboneSequestre);
                obj.carbone_fluxBo = this.exporterValeur(valeur.fluxCarboneExportes.fluxBo);
                obj.carbone_fluxPan = this.exporterValeur(valeur.fluxCarboneExportes.fluxPan);
                obj.carbone_fluxPap = this.exporterValeur(valeur.fluxCarboneExportes.fluxPap);
                obj.carbone_fluxCarbone = this.exporterValeur(valeur.fluxCarboneExportes.fluxCarbone);
                obj.carbone_stockBo = this.exporterValeur(valeur.stockageCarboneExportes.carbBo);
                obj.carbone_stockPan = this.exporterValeur(valeur.stockageCarboneExportes.carbPan);
                obj.carbone_stockPap = this.exporterValeur(valeur.stockageCarboneExportes.carbPap);
                obj.carbone_carboneStocke = this.exporterValeur(valeur.carboneStocke);
                obj.carbone_carboneSubstitue = this.exporterValeur(valeur.carboneSubstitue);

                tab[index] = obj;
            })
            return(tab);
        },
        testDatasReady() {
            return(this.$store.getters.constantes.ready && this.datasReady);
        },

        uf() {
            return this.$store.getters.infosUf
        },

        itEnCours() {
            if (this.testDatasReady) {
                var thisObj = this;
                var choisi = null;
                var enCours = null;

                this.uf.peuplement.ITs.forEach(it => {
                    if (it.enCours) {
                        enCours = it;
                    }
                    if (it.choisi) {
                        choisi = it;
                    }
                })
                if (!enCours) {
                    return choisi;
                }
                return enCours;
            }
            return null;
        },
        laCoupeRase () {
            var lIt = this.itEnCours;
            if (!lIt) {
                return(0);
            }
            return(lIt.coupeRase)
        },

        volumeCoupeRase () {
            var lIt = this.itEnCours;
            if (!lIt || !lIt.volumeCoupeRase) {
                return(0);
            }
            return(this.formaterVolume(lIt.volumeCoupeRase))
        },

        volumeUnitaireCoupeRase () {
            var lIt = this.itEnCours;
            if (!lIt || !lIt.volumeUnitaireCoupeRase) {
                return(0);
            }
            return(this.formaterVolume(lIt.volumeUnitaireCoupeRase))
        },

        valeurCoupeRase () {
            var lIt = this.itEnCours;
            var leFinancier = this.leFinancier;
            if (!lIt || !leFinancier) {
                return(0);
            }
            return(this.formaterEuros(leFinancier.tabValeursCalculeeS[lIt.coupeRase].recettePotentielleCR * this.ppl.surfaceBoisee))
        },

        contentSVG3 () {
            return this.svgContent3
        }
    },

    methods: {

        exporterValeur(valeur) {
            var valeurRetournee = ""+valeur;
            return(valeurRetournee.replace('.', ','));
        },

        arrondir(valeur, puissance = 3) {
            var puissanceDe10 = Math.pow(10, puissance);
            var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
            return(arrondi);
        },
        /*
        formaterEuros: function(valeur) {
            return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
        },
        */
        formaterTecTab(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterEuros(valeur) {
            return(ApiTools.formaterEuros(valeur));
        },
        formaterEuros0(valeur) {
            return(ApiTools.formaterEuros0(valeur));
        },
        formaterVolume(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterSurface(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },

        lancerAffichage(it) {
            this.constantes = this.$store.getters.constantes;
            this.abaqueEclaircies = this.constantes.abaqueEclaircies;
            this.infosPropriete = this.$store.getters.infosPropriete;
            this.svgContent3 = "";
            this.visualiserCarbone(it);
            setTimeout(courbeSVG.maj.bind(null, this.courbe3), 1000);
        },

        visualiserCarbone(it) {

            this.uf.peuplement = this.$store.getters.infosPeuplement; // on s'assure d'être à jour

            // peuplement
            var lePeuplement = UfPeuplement.compiler(this.uf, this.infosPropriete, this.constantes);

            // dendrometrie
            var params = {"it":it, "calculOptimumFutur":true, "calculOptimumPasse":lePeuplement.calculOptimumPasse, "ageMaxTest":lePeuplement.essence.vieMax};
            var laDendrometrie = UfPplDendrometrique.initialiserUfPeuplement(lePeuplement, params);

            // et calculs/ratios carbone
            var params = {"ageMaxTest":lePeuplement.essence.vieMax};
            this.leFinancier = UfPplFinancier.initialiserUfFinancier(laDendrometrie, params);
                // calculs carbone
            var leCarbone = UfPplCarbone.initialiserUfCarbone(UfPplDendrometrique, this.constantes);

            // pour affichage (fusion du tableau carbone avec la dendrométrie) !
            this.ppl = laDendrometrie.ppl;
            this.tabValeursCalculees = laDendrometrie.tabValeursCalculeeS.slice();
            for(var cpt = 0; cpt < leCarbone.tabValeursCalculeeS.length; cpt++) {
                this.tabValeursCalculees[cpt] = Object.assign({}, this.tabValeursCalculees[cpt], leCarbone.tabValeursCalculeeS[cpt]);
            }


            this.dataFinInstallation = laDendrometrie.infosFinInstallation;

            // courbes
            this.courbe3 = leCarbone.initialiserCourbes(lePeuplement.essence.vieMax);
            this.svgContent3 = courbeSVG.initialiserCourbe(this.$store, this.courbe3);

            // pour affichage !
            this.tabValeursAffichees = this.tabValeursCalculees;
            this.tabReady = true;

            // on stocke les résultats
            this.$store.commit('setDendrometrie', laDendrometrie);
            this.$store.commit('setFinances', this.leFinancier);
            //this.$store.commit('setCarbone', leCarbone);
        },

        exporterTableau(tableau) {
            var nomCSV = this.ppl.uf.identifiant + "_carbone_"+this.itEnCours.id;
            return addeoData.exporterTableau(tableau, nomCSV);
        }

    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #app {
        #infosGraphisme {
            #carboneVisualisation {
                .containerCourbes {
                    text-align: center;
                }

                #courbeHauteur {
                    /*max-width:1200px;*/
                    width:100%;
                    margin:0;
                    background-color: white;
                    padding : 0;
                }
                .containerCourbes svg {
                    pointer-events:all;

                    .titre {
                        /*display:none;*/
                    }
                }


                table.carbone {
                    margin : 2em auto 1em ;
                    text-align: left;
                    background-color: #ffffff;
                    padding: 1em;
                    border-spacing: 0;
                    table-layout: fixed;
                    border-color: #777;
                    border-width: 1px;
                    border-style: solid;
                        height:500px;
                        display:block;
                        overflow: auto;

                    thead th {
                        position: sticky;
                        /*top: 0;*/
                        top: -15px;
                        z-index: 100;
                    }
                    tbody {
                    }


                    tr.mesures {
                        font-weight: bold;
                    }
                    tr.eclaircie {
                        color : blue;
                    }
                    tr.eclaircieProp {
                        color : green;
                    }
                    tr.concurrence {
                        text-decoration: underline;
                    }
                    tr.finInstallation {
                        background-color: #dddddd;
                    }
                    tr.anneeCoupeRase {
                        font-weight: bold;
                    }
                    tr.anneeCourante {
                        background-color: #FEFFCC;
                    }
                    tr.finInstallation.anneeCourante {
                        background-color: #DCDD8C;
                    }

                    td, th {
                        max-width : 200px;
                        padding:0 0.6em;
                        white-space :normal;

                        span.uniteValeur {
                            display: block;
                            text-align: right;
                            /*text-align: center;*/
                            font-weight: normal;
                            font-size: 0.9em;
                        }
                    }
                    th {
                        text-align : center;
                        background-color: #f7f7f7;
                        padding-top: 1em;
                        padding-bottom: 0.6em;
                    }
                    .lien {
                        .fas {
                            font-size:1.8em;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    table td.euro {
                        text-align : right;
                    }
                }
            }
        }
    }
</style>
