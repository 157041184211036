<style>
/*
    @import './Courbes.css';
*/
</style>

<template>

  <div id="credits" class="home containerLog">
    <div class="sectionAffichage">
        <div class="conteneur">

            <h1 >Crédits</h1>

            <p>Le <a href="http://www.cpfa.com" target="_blank">CPFA</a> remercie toutes les personnes ayant contribué à la conception et réalisation de STERES :</p>

            <ul class="bloc">
                <li><span class="auteur">Jean Paul Maugé</span>, ancien directeur du <a href="http://www.cpfa.com" target="_blank">CPFA</a> et créateur de GEFAO et COPEL, deux logiciels d’aide à la décision dont STERES est fortement inspiré.</li>
            </ul>

            <a href="http://www.cpfa.com" target="_blank"><img class = "grandLogo egal" src="../assets/img/logoCPFAv.png" /></a>
            <br/>

            <ul>
                <li>Le groupe de travail <a href="http://www.cpfa.com" target="_blank">CPFA</a> / STERES :
                <br/>Particulièrement
                    <ul>
                        <li>le Président du <a href="http://www.cpfa.com" target="_blank">CPFA</a>, <span class="auteur">Emmanuel de Montbron</span>,</li>
                        <li>son animatrice <span class="auteur">Laurence Fort</span>,</li>
                        <li>et <span class="auteur">Jean-Christophe Dutour</span>, Président du GPF Grande Lande et Pays de Born,</li>
                        <li>mais également <span class="auteur">François Capes</span>, <span class="auteur">Vincent Dorlanne</span>, <span class="auteur">Antoine Doutreloux</span>, <span class="auteur">Marine Leblanc</span>, <span class="auteur">Sophie Leze</span>r et <span class="auteur">Joseph Mottais</span>.</li>
                    </ul>
                </li>

                <br/>

                <li>Les GPF et leurs Présidents :
                    <ul>
                        <li>le GPF Sud Gironde et son Président <span class="auteur">Emmanuel de Montbron</span>,</li>
                        <li>le GPF Bassin d'Arcachon et son Président <span class="auteur">Armand Loubiat</span>,</li>
                        <li>le GPF Médoc et son Président <span class="auteur">Alain Bérard</span>,</li>
                        <li>le GPF Grande Lande et Pays de Born et son Président <span class="auteur">Jean-Christophe Dutour</span>,</li>
                        <li>le GPF Sud Landes et son Président <span class="auteur">Arnaud Régnacq</span>,</li>
                        <li>le GPF Petites Landes et sa Présidente <span class="auteur">Chantal Renon</span>,</li>
                        <li>le GPF Sud-Adour et son Président <span class="auteur">François Voisin</span>,</li>
                        <li>le GPF Lot-et-Garonne et son Président <span class="auteur">Alain Navails</span>.</li>
                    </ul>
                </li>
            </ul>

            <ul class="bloc">
                <li>Le <a href="http://www.maisondelaforet-sudouest.com" target="_blank">SYSSO</a>,
                    <ul>
                        <li>son Président <span class="auteur">Vincent Dorlanne</span></li>
                        <li>son Secrétaire Général <span class="auteur">Eric Dumontet</span></li>
                    </ul>
                </li>
            </ul>
            <a href="http://www.maisondelaforet-sudouest.com" target="_blank"><img class = "petitLogo" src="../assets/img/logoMdlF.png" /></a>

            <ul class="bloc">
                <li>La <a href="http://www.nouvelle-aquitaine.fr" target="_blank">Région Nouvelle Aquitaine</a>,
                    <ul>
                        <li>son Président <span class="auteur">Alain Rousset</span>,</li>
                        <li>Madame la Déléguée Bois, Forêt et 1ère Transformation <span class="auteur">Béatrice Gendreau</span></li>
                        <li>le Responsable du Service Forêt Bois Papier <span class="auteur">Bernard Lazarini</span></li>
                    </ul>
                </li>
            </ul>
            <a href="http://www.nouvelle-aquitaine.fr" target="_blank"><img class = "grandLogo " src="../assets/img/logoNA.png" /></a>

            <br/>

            <ul class="bloc">
                <li><a href="http://www.franceboisforet.fr" target="_blank">France Bois Forêt</a></li>
            </ul>
            <a href="http://www.franceboisforet.fr" target="_blank"><img class = "petitLogo" src="../assets/img/logoFBF.png" /></a>


            <ul class="bloc">
                <li>Section Spécialisée Pin Maritime</li>
            </ul>
            <img class = "petitLogo" src="../assets/img/logoSSPM.png" />

            <ul class="bloc">
                <li><a href="http://www.addeo.com" target="_blank">Addeo</a>
                    <br/>notamment son chef de projet <span class="auteur">Bruno Capbern</span>
                </li>
            </ul>
            <a href="http://www.addeo.com" target="_blank"><img class = "petitLogo" src="../assets/img/logoAddeo.png" /></a>

            <ul class="bloc">
                <li>Crédit photo :
                    <br/><a href="http://www.cpfa.com" target="_blank">CPFA</a> / <a href="http://www.forexpo.fr" target="_blank">FOREXPO</a> / <span class="auteur">Laurence Fort</span> / <span class="auteur">Emmanuel de MONTBRON</span></li>
            </ul>

<div id="xylofutur">
            <a href="http://xylofutur.fr/" target="_blank" class="centrer"><img class = "grandLogo" src="../assets/img/xylofutur.png" /></a>
</div>

        </div>
    </div>

  </div>

</template>

<script>


export default {

    name: 'credits',
    components: {

    },
    data() {
        return {
          //  svgContent: "",
        }
    },
    mounted: function () {
    },
    updated: function () {

    },
    computed: {

    },
    methods: {

    }
}


</script>

<style lang="scss">
    #app {
        #credits {

            div.sectionAffichage {
                max-width: 540px;
                margin-left:auto;
                margin-right:auto;
                @media screen and (max-width: 840px) {
                    margin-left:20px;
                    margin-right:20px;
                }

                .conteneur {
                    text-align: center;

                    h1, p, ul {
                        text-align: left;
                    }

                    a {
                        color:#238b71;
                        font-weight:bold;
                    }

                    .bloc {
                        margin-top:3em;
                    }

                    .auteur {
                        font-weight: bold;
                    }

                    img {
                        max-height: 130px;
                        width:auto;
                        margin:1em auto 1em;

                        &.egal {
                        }
                        &.grandLogo {

                            @media screen and (max-width: 750px) {
                                max-height: 100px;
                                display:block;
                                margin:1em auto;
                            }
                        }

                        &.petitLogo {
                            margin-top:1em;;
                            max-height: 65px;
                        }

                        vertical-align: middle;
                    }
                }
                #xylofutur {
                    margin:5em auto 1em;
                    padding:3em 0 0;
                    border-top:1px #223938 solid;

                    img.grandLogo {
                        width:50%;
                        max-width:240px;
                        height:auto;
                        max-height:unset;
                    }
                }
            }
        }
    }

</style>
