import lf from 'lovefield'

export function user_ufparcelle (schemaBuilder) {
  schemaBuilder.createTable('user_ufparcelle')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('ufId', lf.Type.INTEGER)
      .addColumn('identifiant', lf.Type.STRING)
      .addColumn('codePostal', lf.Type.STRING)
      .addColumn('codeCommune', lf.Type.STRING)
      .addColumn('libelleCommune', lf.Type.STRING)
      .addColumn('sectionCadastrale', lf.Type.STRING)
      .addColumn('numeroCadastral', lf.Type.STRING)
      .addColumn('sousParcelle', lf.Type.STRING)
      .addColumn('surface', lf.Type.DOUBLE)
      .addColumn('version', lf.Type.INTEGER)
    /* inutile : la parcelle est gérée directement avec l'UF - pas de sauvegarde autonome */
    /*  .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon) */
      .addPrimaryKey(['id'], false)
    /*  .addNullable(['idLocal']);  */
}
