<template>

    <div id="navModeForet">
<!--        <span class="titre">Mode Forêt</span> -->
        <div id="liensNavForet">
            <div class="synchroniser plusGrand" v-if="$parent.accesInternet" @click="dialogSyncForet = true">
                <v-btn class="boutonFonctionObjet prioritaire" type="button" >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                    Synchroniser
                </v-btn>
            </div>
            <div class="synchroniser plusPetit" v-if="$parent.accesInternet" @click="dialogNavForet = true">
                <v-btn class="boutonFonctionObjet" type="button" >
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    Abandonner
                </v-btn>
            </div>
            <span class="messageError messageInternet" v-if="!$parent.accesInternet">Accès Internet perdu - vous pouvez continuer vos saisies mais vous devrez récupérer une connexion pour synchroniser vos données</span>
            <div class="clear"></div>
        </div>
<v-dialog v-model="dialogNavForet" >
      <v-card>
        <v-card-title class="headline">Fin du "Mode Forêt"</v-card-title>

        <v-card-text>
          Voulez-vous quitter le "Mode Forêt" et abandonner l'ensemble de vos saisies éventuelles ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogNavForet = false">Non</v-btn>
          <v-btn color="" text @click="dialogNavForet = false;$parent.versModeForet(0)">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogSyncForet" >
      <v-card>
        <v-card-title class="headline">Synchronisation de vos saisie</v-card-title>

        <v-card-text>
          Voulez-vous synchroniser l'ensemble de vos saisies ?<br/><br/>
            La synchronisation consiste en l'envoi vers le serveur centralisé STERES de l'ensemble de vos saisies réalisées en "mode forêt".<br/>
            A l'issue de la synchronisation, vos données seront accessibles aux ayants droit de votre propriété.<br/><br/>
            NB : la synchronisation demande une connexion Internet correcte
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogSyncForet = false">Plus tard</v-btn>
          <v-btn color="" text @click="dialogSyncForet = false;synchroniser()">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogImpossibiliteForet" >
      <v-card>
        <v-card-title class="headline">Synchronisation impossible</v-card-title>

        <v-card-text>
          La synchronisation est impossible. Ceci est dû à l'abandon du "mode forêt" depuis votre compte STERES mais sur un autre terminal ou, peut-être, à une déconnexion subite du réseau Internet.<br/><br/>
            Si cet abandon n'est pas volontaire ou que vous pensez ne pas l'avoir demandé, vous devez contacter l'équipe STERES (contact@steres.fr).<br/>Sinon utilisez le bouton "Abandonner".
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogImpossibiliteForet = false">OK</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogImpossibiliteForet2" >
      <v-card>
        <v-card-title class="headline">Synchronisation impossible</v-card-title>

        <v-card-text>
          La synchronisation n'a pas été possible. Ceci est probablement dû à une mauvaise connexion Internet.<br/><br/>
            Déplacez-vous et tentez à nouveau de synchroniser votre terminal ou utilisez une connexion WIFI.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogImpossibiliteForet2 = false">OK</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<v-dialog v-model="dialogSynchronisationForetOK" >
      <v-card>
        <v-card-title class="headline">Données synchronisées</v-card-title>

        <v-card-text>
          La synchronisation s'est terminée avec succès !
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogSynchronisationForetOK = false;$parent.versModeForet(0)">Continuer</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
<!--
<v-dialog v-model="dialogSynchronisationForetOK" >
      <v-card>
        <v-card-title class="headline">Données synchronisées</v-card-title>

        <v-card-text>
          La synchronisation s'est terminée avec succès.<br/><br/>
            Vous pouvez continuer vos saisies (une nouvelle synchronisation sera nécessaire) ou tout simplement quitter le "mode forêt".
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialogSynchronisationForetOK = false">Continuer</v-btn>
          <v-btn color="" text @click="dialogSynchronisationForetOK = false;$parent.versModeForet(0)">Quitter</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
-->
    </div>

</template>

<script>

import { ApiUser } from '../../js/api/index.js'

export default {
    components: {

    },
    props: {
        /**
         * Données à injecter
         */
        data: {
            type: Object
        }
    },
    data() {
        return {
            // init les données du form à partir des props
            datasMenu : this.data,
            dialogSyncForet:false,
            dialogNavForet:false,
            dialogImpossibiliteForet:false,
            dialogImpossibiliteForet2:false,
            dialogSynchronisationForetOK:false
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
        synchroniserSav(propriete, proprieteUFs, ok, json=null) {

            var jsonPropriete = JSON.stringify(propriete);
            var jsonUFs = JSON.stringify(proprieteUFs);
            var jsonGlobal = '{"propriete":'+jsonPropriete+',"UFs":'+jsonUFs+"}";
            var localSynchro = {};
            var laDate = new Date();
            var t = laDate.getTime();
            localSynchro.created2 = t;
            localSynchro.created = laDate.toLocaleDateString();
            localSynchro.ok = ok;
            localSynchro.json = jsonGlobal;
            if (json) {
                localSynchro.json = json;
            }

            // purge automatique
            var moisPrecedent = t - (1000 * 60 * 60 * 24 * 31); // 1 mois en millisecondes 1000 * 60 * 60 * 24 * 31
            //var moisPrecedent = t - (1000 * 30); // juste 30 secondes pour debug

            ApiUser.setLocalSynchroDirect(localSynchro, moisPrecedent);//(propriete, proprieteUFsModifiee);
        },
        initialiserPropriete(propriete) {
            var proprieteModifiee = {};
            proprieteModifiee.id = propriete.id;
            proprieteModifiee.gestionnaireId = propriete.gestionnaireId;
            proprieteModifiee.proprietaireId = propriete.proprietaireId;
            proprieteModifiee.saisieHorsLigneId = propriete.saisieHorsLigneId;
            proprieteModifiee.proprietaireDroitEcriture = propriete.proprietaireDroitEcriture;
            proprieteModifiee.ayantsDroitJson = propriete.ayantsDroitJson;
            proprieteModifiee.identifiant = propriete.identifiant;
            proprieteModifiee.adresse = propriete.adresse;
            proprieteModifiee.codePostal = propriete.codePostal;
            proprieteModifiee.libelleCommune = propriete.libelleCommune;
            proprieteModifiee.codeCommune = propriete.codeCommune;
            proprieteModifiee.telephone = propriete.telephone;
            proprieteModifiee.adresseMail = propriete.adresseMail;
            proprieteModifiee.siret = propriete.siret;
            proprieteModifiee.version = propriete.version;
            proprieteModifiee.tauxActualisation = propriete.tauxActualisation;
            proprieteModifiee.depensesJson = propriete.depensesJson;
            proprieteModifiee.tarifsJson = propriete.tarifsJson;
            proprieteModifiee.idLocal = propriete.idLocal;
            return(proprieteModifiee);
        },
        initialiserUFs(tabUFs) {
            var nouveauTabUFs = [];
            tabUFs.forEach(oldUF => {
                var uf = {};
                Object.assign(uf, oldUF);
                // nettoyage
                delete uf.propriete;
                delete uf.dendrometrieAnnee;
                delete uf.dendrometrieAnneeRetenue;
                delete uf.valeursStockees;
                nouveauTabUFs.push(uf);
            })
            return(nouveauTabUFs);
        },
        synchroniser() {
            this.$parent.synchronisationModeForet = true;
            var thisObj = this;
            // Synchro
            return ApiUser.lirePropriete(this.$store.getters.proprieteId, this.$store.getters.constantes).then(response => {
                var propriete = response.propriete;
                var proprieteUFs  = response.UFs;
                thisObj.$store.commit('setProprieteInfos', propriete); // pour être sûr d'être à jour !
                //this.$store.commit('setProprieteUFs', proprieteUFs); // pour être sûr d'être à jour !  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState

                // NON !!!!!!!!!!!!!!!!!!!!! il faut reprendre dans la base !!!!!!!!!!!!!!!!!!!!!!!!!!! WIZZ cf. ci-dessus
                //var propriete = this.$store.getters.infosPropriete;
                //var proprieteUFs = this.$store.getters.infosProprieteUFs;


                var proprieteModifiee = this.initialiserPropriete(propriete);
                var proprieteUFsModifieesTMP = proprieteUFs.filter(function(uf) {
                    return uf.modeForet;
                });
                var proprieteUFsModifiees = this.initialiserUFs(proprieteUFsModifieesTMP);
                // sauvegarde de la manip en base locale
                thisObj.synchroniserSav(proprieteModifiee, proprieteUFsModifiees, 2);

                // 1/ est-ce qu'on a le droit ?
                return ApiUser.autoriserSynchronisationPropriete(proprieteModifiee, thisObj.$store.getters.utilisateurCourantId)
                .then(response => {
                    if (!response) {
                        thisObj.$parent.synchronisationModeForet = false;
                        thisObj.dialogImpossibiliteForet = true;
                        return;
                    }
                    // 2/ est-ce que ça a marché ?
/*
2021/05 BC - opératio réalisée en amontpour sauvergarde en base locale
                    var proprieteUFsModifiees = proprieteUFs.filter(function(uf) {
                        return uf.modeForet;
                    });
*/
                    return ApiUser.synchroniserPropriete(proprieteModifiee, proprieteUFsModifiees)
                    .then(response => {
                        var ok = 0;
                        var retour = null;
                        if (response) { // 3/ est-ce que ça a marché ?
                            ok = 1; // si oui
                            retour = response;
                        }
                        // sauvegarde de la manip en base locale
                        thisObj.synchroniserSav(proprieteModifiee, proprieteUFsModifiees, ok, retour);

                        // 3/ est-ce que ça a marché ?
                        if (!response) {
                            thisObj.$parent.synchronisationModeForet = false;
                            thisObj.dialogImpossibiliteForet2 = true;
                            return;
                        }
                        thisObj.$parent.synchronisationModeForet = false;
                        // 4/ on libère la propriété
                        thisObj.dialogSynchronisationForetOK = true; // c'est bon !
                    })
                })
            })
        }
    }
}
</script>

<style lang="scss">
    #app {

        #navModeForet {
            margin-top: 48px;
            width: 100%;
            min-height:1em;
            background-color: #285745;
            padding:0.4em 0;
            color:wheat;
            text-transform: uppercase;

            .messageInternet {
                color:#fed99d;
                position:relative;
                font-size:1em;
                display: block;
                margin: 0 1em 1em;
            }

            .titre {
                @media screen and (max-width: 700px) {
                    float:left;
                    margin:1em 0 1em 1em;
                }
            }

            #liensNavForet {
                /*position:absolute;
                right:0;
                top:1em;*/

                @media screen and (max-width: 700px) {
                    position:relative;
                    top:0;
                }
            }

            .synchroniser {
                color:white;
                /*
                position:absolute;
                right:2em;
                top:1em;
                float:right; */
                display:inline-block;
                /*margin-right:2em;*/

                button {
                    color:#285745;
                    background-color: #7FAB88;
                    /*font-size: 1.2em;*/
                    font-size: 0.9em;
                    border-radius: 0.2em;

                    &.prioritaire {
                        background-color: #fed99d;
                    }
                    i.fa {
                        font-size: 1.4em;
                        margin:0 0.4em 0 0;
                    }
                }
 /*
                @media screen and (max-width: 700px) {
                    width:50%;
                    text-align: right;

                    &.plusGrand {
                        display:block;
                        margin-top:1em;
                    }
                }
*/
                &:hover {
                    cursor: pointer;
                    color:#7DAB87;
                }

                &.plusPetit {
                    /*font-size:80%;*/
                    padding-top:0.2em;
                }
            }
        }
    }
</style>

