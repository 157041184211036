/*
* Constantes de configuration pour STERES (et valeur temporaires pour dev)
*/
export const STERES_VERSION = "1.2.06";
export const REQUIRED_DATABASE_VERSION = 75;
export const DATABASE_NAME_PROD = 'steres'; // prevoir prod (en fait preprod) TODO
export const DATABASE_NAME_DEV = 'steresdev';
export const DOMAINE_STD = 'steres.addeo.com'; // semble inutile (créé pour TESTS)
export const DOMAINE_ADMIN_DEMO = 'admin.cpfa.addeo.com'; // pas appelé
export const DOMAINE_ADMIN_PREPROD = 'admin.steres.addeo.com'; // pas appelé
export const DOMAINE_ADMIN_PROD = 'admin.steres.fr'; // pas appelé

export const SYNTHESE_COUPES_MAX = 20; // Affichage max pour Synthèse des coupes
export const SYNTHESE_CARBONE_MAX = 20; // Affichage max pour Synthèse carbone

export const PREMIERE_MESURE_SEMIS = 10; // Affichage max pour Synthèse des coupes
export const PREMIERE_MESURE_PLANTATION = 6; // Affichage max pour Synthèse carbone


export const GLOBAL_TABLES = ['calc_bobi', 'calc_eclaircies', 'const_steres', 'const_cultures', 'const_depenses', 'const_essences', 'const_essences_cultures', 'const_essences_cultures_terroirs', 'const_profils', 'const_qualifications', 'const_tarifs', 'const_depenses', 'const_terroirs']; // pour la synchro
export const USER_TABLES = ['user_utilisateurs', 'user_proprietes']; // pour la synchro - ces tables doivent obligatoirement comprendre un champ version qui permet la synchronisation !
// export const USER_TABLES = ['user_utilisateurs', 'user_proprietes', 'user_uf', 'user_ufparcelle', 'user_ufpeuplement', 'user_ufpeuplementmesure', 'user_ufpeuplementeclaircie', 'user_ufpeuplement_it', 'user_ufpeuplement_iteclaircie']; // pour la synchro - ces tables doivent obligatoirement comprendre un champ version qui permet la synchronisation !
export const USER_PROPRIETE_TABLES = ['user_uf', 'user_ufparcelle', 'user_ufpeuplement', 'user_ufpeuplementmesure', 'user_ufpeuplementeclaircie', 'user_ufpeuplement_it', 'user_ufpeuplement_iteclaircie'];

export const CHAMP_VERSION = 'version'; // nom du champ "version" !!!!!!!!!!!
