<template>
 <div id="dendrometrie">
    <div v-if="ready" >
        <div class="conteneurTable">
            <table v-if="lesInfos" class=" debugLesInfos">
                <tr :id="lInfo.id" v-bind:class="{invalide : lInfo.invalide}" class="lien"  v-for="(lInfo, numInfo) in lesInfos" :key="numInfo" >
                    <th class="" v-on:click="ouvrirInfo(lInfo)">
                        {{ lInfo.anneeRetenue }} -
                    </th>
                    <th class="aDroite" v-on:click="ouvrirInfo(lInfo)">
                        &nbsp;{{ lInfo.age }} an<span v-bind:class="{transparent : lInfo.age < 2}">s</span>
                    </th>
                    <td class="" v-on:click="ouvrirInfo(lInfo)">
                        <span v-if="lInfo.type == 'MESURE'">Mesure<span class="invalide" v-if="lInfo.invalide"> (trop précoce - non prise en compte)</span></span>
                        <span v-if="lInfo.type == 'ECLAIRCIE'">
                            <span v-if="!lInfo.depressage && !lInfo.invalide">Eclaircie</span>
                            <span v-if="!lInfo.depressage && lInfo.invalide">Eclaircie / Dépressage</span>
                            <span v-if="lInfo.depressage">Dépressage</span>
                            <span class="invalide" v-if="lInfo.invalide"> (trop précoce - non pris en compte)</span>
                        </span>
                        <span @click.stop="ouvrirPeuplement" v-if="lInfo.type == 'TRAVAIL'">Travail</span>
                    </td>
                    <td class="" v-on:click="ouvrirPeuplement" v-if="lInfo.type == 'TRAVAIL'">
                        <span>{{lInfo.libelle}},</span>
                        <!-- <img class="iconeFct" alt="Editer le peuplement" src="@/assets/img/crayon.svg" /> -->
                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                    </td>
                    <td class="" v-on:click="ouvrirMesure(lInfo)" v-if="lInfo.type == 'MESURE'">
                        <span class="uniteAffichage">Densité : {{arrondirDensite(lInfo.densiteMoyenne)}} tiges/ha,</span>
                        <span class="uniteAffichage">Hauteur : {{arrondirHauteur(lInfo.hauteurMoyenne)}} m,</span>
                        <span class="uniteAffichage">Circonférence : {{arrondirCirconference(lInfo.circonferenceQuadratique)}} cm</span>
                        <!-- <img class="iconeFct" alt="Editer la mesure" src="@/assets/img/crayon.svg" /> -->
                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                    </td>
                    <td class="" v-on:click="ouvrirEclaircie(lInfo)" v-if="lInfo.type == 'ECLAIRCIE'">
                        <span class="uniteAffichage">Après éclaircie - Densité : {{arrondirDensite(lInfo.densiteMoyenne)}} tiges/ha,</span>
                        <span class="uniteAffichage" v-if="lInfo.hauteurMoyenne">Hauteur : {{arrondirHauteur(lInfo.hauteurMoyenne) }} m,</span>
                        <span class="uniteAffichage" v-if="lInfo.circonferenceQuadratique">Circonférence : {{arrondirCirconference(lInfo.circonferenceQuadratique)}} cm</span>
                        <!-- <img class="iconeFct" alt="Editer l'éclaircie" src="@/assets/img/crayon.svg" /> -->
                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                    </td>
                </tr>
                <tr class="lien">
                    <th @click.stop="ouvrirPeuplement">
                        {{anneeInstallation}} -
                    </th>
                    <th class="aDroite" @click.stop="ouvrirPeuplement">
                        0 an<span class="transparent">s</span>
                    </th>
                    <td @click.stop="ouvrirPeuplement">
                        Installation ({{uf.peuplement.surfaceBoisee}} ha) - {{typePlantation}}
                    </td>
                    <td @click.stop="ouvrirPeuplement">
                        <span class="uniteAffichage">Densité : {{arrondir(uf.peuplement.densiteInstallation,0)}} tiges/ha,</span>
                        <span v-if="lesInfosTravail && lesInfosTravail.length" >Travaux : </span>
                        <span  v-for="(lInfoTravail, numInfoTravail) in lesInfosTravail"  > {{lInfoTravail.libelle}},</span>
                        <!-- <img class="iconeFct" alt="Modifier l'installation" src="@/assets/img/crayon.svg" /> -->
                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                    </td>
                </tr>
            </table>

            <span v-if="!$store.getters.lectureSeule" class="troisBoutons">
                <v-btn class="boutonFonctionObjet prioritaire" type="button" @click="mesureAjouter">Ajouter une mesure</v-btn>
                <v-btn class="boutonFonctionObjet prioritaire" type="button" @click="eclaircieAjouter">Ajouter une éclaircie</v-btn>
                <v-checkbox v-if="0" class="enLigne" @click="majCalculOptimumPasse"
                    v-model="uf.peuplement.calculOptimumPasse"
                    label="Demander à STERES de simuler ses propres éclaircies"
                ></v-checkbox>
            </span>

        </div>

        <div v-if="uf.peuplement.etatSteres !== etatVALIDE" class="infosEtat"
             v-bind:class="{etatVALIDE : infosEtat == etatVALIDE, etatAVALIDER : infosEtat == etatAVALIDER, etatINVALIDE : infosEtat == etatINVALIDE}">
            {{messageInfosEtat}}
            <div class="radioGroupe" v-if="uf.peuplement.etatSteres === etatAVALIDER && !$store.getters.lectureSeule">
                <v-radio-group v-model="uf.peuplement.etatUtilisateur"  v-on:click="stockerEtatsPeuplement()">
                <v-radio
                  label="Je valide le peuplement"
                  color="#4CAF50"
                   :value="etatVALIDE"
                ></v-radio>
                <span>-</span>
                <v-radio
                  label="A valider"
                  color="#FF9800"
                   :value="etatAVALIDER"
                ></v-radio>
                <span>-</span>
                <v-radio
                  label="J'invalide le peuplement"
                  color="#F44336"
                   :value="etatINVALIDE"
                ></v-radio>
              </v-radio-group>
            </div>
        </div>


    </div>
</div>
</template>


<script>

import { SteresConstantes } from "../../js/calculs/constantes.js"
import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"


//import PeuplementDendrometrieVisualisation from './PeuplementDendrometrieVisualisation.vue'


export default {
    components: {
//        'dendrometrie-peuplement-visu': PeuplementDendrometrieVisualisation,
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            // init les données du form à partir des props
            uf: JSON.parse(JSON.stringify(this.data)),
            tabInfos:[],
            tabInfosTravail:[],
            ready:false,
            etatModifie:false,
            messageInfosEtat:""
        }
    },

    created() {
        //this.$store.commit('setItId', this.itCourant);
        //this.$store.commit('resetDataApplication');
        this.initialiser();
        this.initialiserChoixUtilisateur();
        this.ready = true;
    },

    beforeDestroy() {
        if (this.ready && this.etatModifie) {
            return ApiUser.setPeuplement(this.uf.peuplement, this.$store.getters.constantes)
        }
    },

    computed: {
        etatAVALIDER() {
            return(SteresConstantes.etatAVALIDER);
        },
        etatINVALIDE() {
            return(SteresConstantes.etatINVALIDE);
        },
        etatVALIDE() {
            return(SteresConstantes.etatVALIDE);
        },
        infosEtat() {
            if (this.uf.peuplement.etatSteres == SteresConstantes.etatAVALIDER) {
                this.messageInfosEtat = "Attention, STERES indique qu'une éclaircie est peut-être manquante dans l'historique de votre peuplement.";
                if (!this.$store.getters.lectureSeule) {
                    this.messageInfosEtat += " Vous pouvez modifier vos saisies (mesures, éclaircies ...) ou indiquer - ci-dessous - si ces dernières correspondent à la réalité de votre peuplement.";
                }
            }
            if (this.uf.peuplement.etatSteres == SteresConstantes.etatINVALIDE) {
                this.messageInfosEtat = "Les données saisies pour votre peuplement ne permettent pas à STERES de calculer les optimums dendrométriques et financiers.";
                if (!this.$store.getters.lectureSeule) {
                    this.messageInfosEtat += " Vous pouvez modifier vos saisies (mesures, éclaircies ...) pour régler ce problème.";
                }
            }
            if (this.uf.peuplement.etatSteres == SteresConstantes.etatVALIDE)  {
                this.messageInfosEtat = "Les données saisies pour votre peuplement permettent à STERES de calculer les optimums dendrométriques et financiers.";
                if (!this.$store.getters.lectureSeule) {
                    this.messageInfosEtat += " Si ces derniers ne vous satisfont pas, peut-être pouvez-vous modifier vos saisies (mesures, éclaircies ...) ou indiquer que les résultats ne vous semblent pas valides. De cette façon, vous pourrez écarter ce peuplement de la visualisation des listes et synthéses.";
                }
            }
            //return(this.uf.peuplement.etatSteres)
            return(this.uf.peuplement.etatUtilisateur)
        },
        lesMesures() {
            return(this.uf.peuplement.mesures);
        },
        lesInfos() {
            return(this.tabInfos);
        },
        lesInfosTravail() {
            return(this.tabInfosTravail);
        },
        anneeInstallation() {
            return(ApiUser.anneeRetenuePeuplement(this.uf.peuplement.dateInstallation, this.$store.getters.constantes));
        },
        typePlantation() {
            return(ApiUser.trouverItemTabConst(this.$store.getters.constantes.params.const_cultures, this.uf.peuplement.cultureId, "").libelle); // verrouille ci-dessous
            /*
            var lId = "id"+this.uf.peuplement.cultureId;
            return(this.$store.getters.constantes.params.const_cultures[lId].libelle);
            */
        }
        /*
        lItCourantObj() {
            var obj = null;
            if (this.ready) {
                this.uf.peuplement.ITs.forEach(it => {
                    if (it.choisi) {
                        obj = it;
                    }
                })
            }
            return(obj);
        },
        */
    },

    methods: {
        initialiser() {
            var thisObj = this;
            this.uf.peuplement = this.$store.getters.infosPeuplement; // on s'assure que le peuplement est à jour
            this.uf.peuplement.mesures.forEach(mesure => {
                mesure.anneeRetenue = thisObj.anneeRetenue(mesure.dateMesure);
                mesure.age = mesure.anneeRetenue - thisObj.anneeInstallation;
                mesure.type = "MESURE";
                mesure.date = thisObj.anneeRetenue(mesure.dateMesure);
                thisObj.tabInfos.push(mesure);
            })
            this.uf.peuplement.eclaircies.forEach(eclaircie => {
                eclaircie.anneeRetenue = thisObj.anneeRetenue(eclaircie.dateEclaircie);
                eclaircie.age = eclaircie.anneeRetenue - thisObj.anneeInstallation;
                eclaircie.type = "ECLAIRCIE";
                eclaircie.date = thisObj.anneeRetenue(eclaircie.dateEclaircie);
                thisObj.tabInfos.push(eclaircie);
            })

            var tabTravaux = JSON.parse(this.uf.peuplement.travauxJson);
            tabTravaux.forEach(travail => {
                if (travail.choisi) {
                    var leTravail = {};
                    leTravail.age = travail.anneeDebut;
                    leTravail.anneeRetenue = thisObj.anneeInstallation + leTravail.age;
                    leTravail.type = "TRAVAIL";
                    leTravail.date = leTravail.anneeRetenue;
                    leTravail.libelle = travail.libelle;
                    if (leTravail.age) {
                        thisObj.tabInfos.push(leTravail);
                    }
                    else {
                        thisObj.tabInfosTravail.push(leTravail);
                    }
                }
            })
            this.tabInfos.sort(this.triAnneeRetenue)


        },

        initialiserChoixUtilisateur() {
            // si pas encore de "choix" utilisateur
            if (!this.uf.peuplement.etatUtilisateur) {
                this.uf.peuplement.etatUtilisateur = this.uf.peuplement.etatSteres;
                this.etatModifie = true; // on prévoit l'enregistrement distant
            }
            if (this.uf.peuplement.etatSteres == SteresConstantes.etatINVALIDE) {
                if (this.uf.peuplement.etatUtilisateur != this.uf.peuplement.etatSteres) {
                    this.uf.peuplement.etatUtilisateur = this.uf.peuplement.etatSteres;
                    this.etatModifie = true; // on prévoit l'enregistrement distant
                }
            }
        },

        ouvrirPeuplement() {
            if (this.$store.getters.lectureSeule) {
                return;
            }
            var uf = this.uf;
            var peuplement = uf.peuplement;

            this.$store.commit('setUfInfos', uf);
            this.$store.commit('setUfId', uf.id);
            this.$store.commit('setPeuplementInfos', peuplement);
            this.$store.commit('setPeuplementId', peuplement.id);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplementedition" })
        },

	   triAnneeRetenue(x, y) {
           if (x.anneeRetenue != y.anneeRetenue) {
                return(y.anneeRetenue - x.anneeRetenue);
            }
           if (x.date < y.date) {
               return(-1)
           }
           if (x.date > y.date) {
               return(1)
           }
           return(0)
        },
        // l'année retenue peut être la précédente ( a priori si mois antérieur à juin)
        anneeRetenue(dateReelle) {
            return(ApiUser.anneeRetenuePeuplement(dateReelle, this.$store.getters.constantes));
        },
        ouvrirInfo(info) {
            if (info.type == "MESURE") {
                return(this.ouvrirMesure(info));
            }
            if (info.type == "ECLAIRCIE") {
                return(this.ouvrirEclaircie(info));
            }
        },
        ouvrirMesure(mesure) {
            if (this.$store.getters.lectureSeule) {
                return;
            }
            var idMesure = -1;
            if (mesure) {
                idMesure = mesure.id;
                this.$store.commit('setMesureInfos', mesure);
            }
            this.$store.commit('setMesureId', idMesure);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplementmesure" })
        },
        mesureAjouter() {
            this.ouvrirMesure(null);
        },
        travailAjouter() {
        },
        ouvrirEclaircie(eclaircie) {
            if (this.$store.getters.lectureSeule) {
                return;
            }
            var idEclaircie = -1;
            if (eclaircie) {
                idEclaircie = eclaircie.id;
                this.$store.commit('setEclaircieInfos', eclaircie);
            }
            this.$store.commit('setEclaircieId', idEclaircie);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplementeclaircie" })
        },
        eclaircieAjouter() {
            this.ouvrirEclaircie(null);
        },
        /*
        majCalculOptimumPasse() {
            this.ready = false;
            this.$store.commit('setPeuplementInfos', this.uf.peuplement);
            this.$store.commit('setUfInfos', this.uf);
            this.ready = true;
        },
        */
        arrondir(valeur, nbChiffresArrondi) {
            return(ApiTools.arrondir(valeur, nbChiffresArrondi));
        },
        arrondirDensite(valeur) {
            return(ApiTools.arrondiDensite(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        arrondirHauteur(valeur) {
            return(ApiTools.arrondiHauteur(valeur, this.$store.getters.constantes.steres.arrondisJson, false));
        },
        arrondirCirconference(valeur) {
            return(ApiTools.arrondiCirconference(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        arrondirSurface(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },

        stockerEtatsPeuplement() {

            this.etatModifie = true; // on prévoit l'enregistrement distant

            this.$store.commit('setUfInfos', this.uf);
            this.$store.commit('setPeuplementInfos', this.uf.peuplement);

            // sauvegarde en base locale uniquement - on ecrira la base distante uniquement en sortie de la page TODO

            return ApiUser.setPeuplementDirect(this.uf.peuplement) // on sauvegarde mais on n'attend pas ...
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #dendrometrie {
        padding-left:1em;

        .titreListe.suite {
            margin-top:2em;
            margin-bottom: 0;
        }

        .v-input.enLigne {
            display:inline-block;
            padding:0;
            margin: 0;
            vertical-align: bottom;
            background-color:transparent;
            .v-input__slot {
                padding: 0;
                margin: 0;
                .v-label, .v-input, .v-icon {
                    color: #394F4E;
                }
            }
        }

        table {
            width: 100%;
            min-width: 600px;
            background-color: transparent;
            margin-bottom: 1em;
            color: #223938;
            /*border: 1px solid #223938;*/
            padding: 0.6em 1%;
            border-spacing : 0;

            tr {
                vertical-align: top;
                &.invalide {
                    .invalide {
                        color:red;
                    }
                }

                th {
                    text-align: left;
                    padding:1em 0;
                    width: 1%;
                    white-space: nowrap;

                    &:first-child {
                        border-left: 1px solid #223938;
                        padding-left:1em;
                    }
                }
                td {
                    padding:1em 1em;
                }
                img.iconeFct {
                    text-align: left;
                    margin-left:1em;
                    float: none;
                    width : auto;
                }
                i.fa.svg {
                    text-align: left;
                    margin-left:1em;
                }
            }
        }

        .infosEtat {
            margin:2em 0;
            border: 4px solid #A8CCCA;
            padding : 1em;

            &.etatVALIDE {
                border-color : #4CAF50;
            }
            &.etatAVALIDER {
                border-color : #FF9800;
            }
            &.etatINVALIDE {
                border-color : #F44336;
            }

            .radioGroupe {
                text-align: right;

                div {
                    display:inline-block;
                }

                .v-input {
                    background-color: transparent;
                    padding: 0;
                    border-radius: 0;

                    .v-input__slot {
                        padding: 0;
                        margin: 0;
                    }
                    .v-messages {
                        display:none;
                    }

                    .v-label, .theme--light.v-icon {
                        color: #252525;
                        font-size: 1em;
                    }
                    .v-label {
                        font-size: 0.9em;
                    }
                    .theme--light.v-icon {
                        font-size: 22px;
                    }
                }

                label {
                    /*margin:0 1em 0 0.4em;    */
                }
                span {
                    margin:0 1em 0 0;
                }
            }
        }
    }
</style>
