<template>
 <div id="itineraires" class="itineraires">
    <div v-if="ready" >

        <div id="paramItineraires">
            <param-itineraires-peuplement :data='itCourant' @retourItParam="onRetourItParam"  @retourItComparaison="onRetourItComparaison"></param-itineraires-peuplement>
        </div>
        <div id="itinerairesTechniques">
            <div class="conteneurTable">

                <table v-if="lesInfos && !$parent.comparerITs" class="infos  debugInfos">
                    <tr :id="lInfo.id" v-bind:class="{lien : lInfo.type == 'ECLAIRCIE', lectureSeule:lectureSeule}" v-for="(lInfo, numInfo) in lesInfos" :key="numInfo" >
                        <th class="" v-on:click="ouvrirInfo(lInfo)">
                            {{ lInfo.anneeRetenue }} -&nbsp;
                        </th>
                        <th class="aDroite" v-on:click="ouvrirInfo(lInfo)">
                            {{ lInfo.age }} an<span v-bind:class="{transparent : lInfo.age < 2}">s</span>
                        </th>
                        <td class="" v-on:click="ouvrirInfo(lInfo)">
                            <span v-if="lInfo.type == 'ECLAIRCIE' || lInfo.type == 'ECLAIRCIEAUTO'">
                                <span v-if="!lInfo.depressage && !lInfo.invalide">Eclaircie</span>
                                <span v-if="!lInfo.depressage && lInfo.invalide">Eclaircie / Dépressage</span>
                                <span v-if="lInfo.depressage">Dépressage</span>
                                <span class="invalide" v-if="lInfo.invalide"> (trop précoce - non pris en compte)</span>
                                <span class="invalide" v-if="lInfo.doublon"> (doublon - non pris en compte)<br/>Il existe une éclaircie pour cette année dans votre historique.<br/>Corrigez l'historique ou votre itinéraire technique.</span>
                            </span>


                        </td>
                        <td class="" v-on:click="ouvrirEclaircie(lInfo)" v-if="lInfo.type == 'ECLAIRCIE' || lInfo.type == 'ECLAIRCIEAUTO'">
                            <span class="uniteAffichage">Après éclaircie - Densité : {{arrondir(lInfo.densiteMoyenne,0)}} tiges/ha,</span>
                            <span class="uniteAffichage" v-if="lInfo.hauteurMoyenne">Hauteur : {{ arrondir(lInfo.hauteurMoyenne,2) }} m,</span>
                            <span class="uniteAffichage" v-if="lInfo.circonferenceQuadratique">Circonférence : {{ arrondir(lInfo.circonferenceQuadratique,2) }} cm</span>
                            <span class="messageError" v-if="lInfo.age > lItCourantObj.coupeRase">Attention, cette éclaircie est postérieure à la coupe rase</span>
                            <span class="messageError" v-if="lInfo.age == lItCourantObj.coupeRase">Attention, cette éclaircie est programmée l'année de la coupe rase</span>
                            <span class="messageError" v-if="lInfo.anneeRetenue < anneeMinAcceptable">Attention, cette éclaircie est programmée avant l'année de croissance courante : {{anneeMinAcceptable}}. Elle est ignorée dans les calculs</span>

                            <!-- <img v-if="lInfo.type == 'ECLAIRCIE' && !lectureSeule" class="iconeFct" alt="Editer l'éclaircie" src="@/assets/img/crayon.svg" /> -->
                            <i v-if="lInfo.type == 'ECLAIRCIE' && !lectureSeule"  class="fa fa-pencil svg" aria-hidden="true"></i>
                        </td>
                    </tr>
                </table>

                <div v-if="$parent.comparerITs" class="comparaison">
                    <table v-if="lesInfos1" class="infos comparaison gauche debugInfos1">
                        <tr :id="lInfo.id" v-for="(lInfo, numInfo) in lesInfos1" :key="numInfo" >
                            <th class="" >
                                {{ lInfo.anneeRetenue }} -
                            </th>
                            <th class="aDroite" >
                                {{ lInfo.age }} an<span v-bind:class="{transparent : lInfo.age < 2}">s</span>
                            </th>
                            <td class="" >
                                <span v-if="lInfo.type == 'ECLAIRCIE' || lInfo.type == 'ECLAIRCIEAUTO'">Eclaircie</span>
                            </td>
                            <td class="" v-if="lInfo.type == 'ECLAIRCIE' || lInfo.type == 'ECLAIRCIEAUTO'">
                                <span class="uniteAffichage">Après éclaircie - Densité : {{arrondir(lInfo.densiteMoyenne,0)}} tiges/ha,</span>
                                <span class="uniteAffichage" v-if="lInfo.hauteurMoyenne">Hauteur : {{ arrondir(lInfo.hauteurMoyenne,2) }} m,</span>
                                <span class="uniteAffichage" v-if="lInfo.circonferenceQuadratique">Circonférence : {{ arrondir(lInfo.circonferenceQuadratique,2) }} cm</span>
                                <span class="messageError" v-if="lInfo.age > lItCourantObj.coupeRase">Attention, cette éclaircie est postérieure à la coupe rase</span>
                                <span class="messageError" v-if="lInfo.age == lItCourantObj.coupeRase">Attention, cette éclaircie est programmée l'année de la coupe rase</span>
                            </td>
                        </tr>
                    </table>
                    <table v-if="lesInfos2" class="infos comparaison gauche debugInfos2">
                        <tr :id="lInfo.id" v-for="(lInfo, numInfo) in lesInfos2" :key="numInfo" >
                            <th class="" >
                                {{ lInfo.anneeRetenue }} -
                            </th>
                            <th class="aDroite" >
                                {{ lInfo.age }} an<span v-bind:class="{transparent : lInfo.age < 2}">s</span>
                            </th>
                            <td class="" >
                                <span v-if="lInfo.type == 'ECLAIRCIE' || lInfo.type == 'ECLAIRCIEAUTO'">Eclaircie</span>
                            </td>
                            <td class="" v-if="lInfo.type == 'ECLAIRCIE' || lInfo.type == 'ECLAIRCIEAUTO'">
                                <span class="uniteAffichage">Après éclaircie - Densité : {{arrondir(lInfo.densiteMoyenne,0)}} tiges/ha,</span>
                                <span class="uniteAffichage" v-if="lInfo.hauteurMoyenne">Hauteur : {{ arrondir(lInfo.hauteurMoyenne,2) }} m,</span>
                                <span class="uniteAffichage" v-if="lInfo.circonferenceQuadratique">Circonférence : {{ arrondir(lInfo.circonferenceQuadratique,2) }} cm</span>
                            </td>
                        </tr>
                    </table>
                <div class="clear"></div>
                </div>

                <span v-if="!lectureSeule && !$parent.comparerITs">
                    <span v-if="lItCourant && !lItCourantAuto" class="troisBoutons">
                        <v-btn class="boutonFonctionObjet prioritaire" type="button" @click="eclaircieAjouter">Ajouter une éclaircie</v-btn>
                    </span>
                </span>
            </div>
        </div>
        <div id="itinerairesTechniquesComparaisonVisu" class=""   v-if="$parent.comparerITs">
                <div class="wrapper">
                    <h2 class="titreListe suite" >Comparaison</h2>
                    <div v-if="comparaison" class="conteneur swap">
                        <itineraires-peuplement-comp :data='comparaison'></itineraires-peuplement-comp>
                    </div>
                </div>


        </div>
    </div>
</div>
</template>


<script>

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"


import PeuplementItinerairesTechniquesParam from './PeuplementItinerairesTechniquesParam.vue'
import PeuplementItinerairesTechniquesComparaison from './PeuplementItinerairesTechniquesComparaison.vue'


export default {
    components: {
        'param-itineraires-peuplement': PeuplementItinerairesTechniquesParam,
        'itineraires-peuplement-comp': PeuplementItinerairesTechniquesComparaison,
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            // init les données du form à partir des props
            //uf: JSON.parse(JSON.stringify(this.data)),
            uf: this.data,
            tabInfos:[],
            ready:false,
            itCourant:0,
            comparaison:null,
        }
    },

    created() {
        this.initialiser();
        this.ready = true;
    },

    computed: {
        lectureSeule() {
            return(this.$store.getters.lectureSeule || this.$store.getters.modeForet)
        },
        anneeMinAcceptable() {
            var moisBascule = this.$store.getters.constantes.steres.moisBascule;
            var lAnnee = new Date().getFullYear();
            var moisReel = new Date().getMonth()+1;
            if (moisReel >= moisBascule) {
                lAnnee += 1;
            }
            return lAnnee;
        },
        lesMesures() {
            return(this.uf.peuplement.mesures);
        },

        lesInfos() {
            var debug = this.tabInfosIT(this.lItCourantObj, this.$store.getters.dendrometrie);
            return(this.tabInfosIT(this.lItCourantObj, this.$store.getters.dendrometrie));
        },

        lesInfos1() {
            if (this.comparaison) {
                return(this.tabInfosIT(this.comparaison.it1, this.$store.getters.dendrometrie1));
            }
            return(null)
        },
        lesInfos2() {
            if (this.comparaison) {
                return(this.tabInfosIT(this.comparaison.it2, this.$store.getters.dendrometrie2));
            }
            return(null)
        },

        lItCourant() {
            return(this.itCourant);
        },
        lItCourantObj() {
            if (!this.itCourant) {
                return(null);
            }
            var peuplement = this.$store.getters.infosPeuplement;
            var itId = this.itCourant;
            var obj = null;
            peuplement.ITs.forEach(it => {
                if (it.id == itId) {
                    obj = it;
                }
            })
            return(obj);
        },
        lItCourantAuto() {
            var obj = this.lItCourantObj;
            if (!obj) {
                return(0);
            }
            return(obj.auto);
        },

        anneeInstallation() {
            return(ApiUser.anneeRetenuePeuplement(this.uf.peuplement.dateInstallation, this.$store.getters.constantes));
        }
    },

    methods: {
        tabInfosIT(it, dendrometrie) {
            var uf = this.uf;
            var thisObj = this;
            var tabInfos = [];
            if (it && it.id) {
                if (!it.auto && it.eclaircies) {
                    it.eclaircies.forEach(eclaircie => {
                        eclaircie.anneeRetenue = thisObj.anneeRetenue(eclaircie.dateEclaircie);
                        eclaircie.age = eclaircie.anneeRetenue - thisObj.anneeInstallation;
                        eclaircie.type = "ECLAIRCIE";
                        eclaircie.date = thisObj.anneeRetenue(eclaircie.dateEclaircie);
                        // 2022/12
                        eclaircie.doublon = thisObj.testerDoublon(eclaircie.anneeRetenue);
                        // fin 2022/12
                        tabInfos.push(eclaircie);
                    })
                }
                if (it.auto && dendrometrie.eclairciesAuto) {
                    var eclairciesAuto = dendrometrie.eclairciesAuto;
                    eclairciesAuto.forEach(eclaircie => {
                        eclaircie.anneeRetenue = eclaircie.age + thisObj.anneeInstallation;
                        eclaircie.type = "ECLAIRCIEAUTO";
                        eclaircie.date = eclaircie.anneeRetenue;
                        eclaircie.densiteMoyenne = eclaircie.densiteApres;
                        eclaircie.doublon = false;
                        tabInfos.push(eclaircie);
                    })
                }
            tabInfos.sort(this.triAnneeRetenue)
            }
            return(tabInfos);
        },

        testerDoublon(anneeRetenue) {
            var trouve = false;

            this.uf.peuplement.eclaircies.forEach(eclaircie => {
                if (eclaircie.anneeRetenue == anneeRetenue) {
                    trouve = true;
                }
            })
            return(trouve);
        },

        creerItSteres(peuplement, identifiant = null) { // PAS ICI A TERME : TODO
            var lIT = {"id":0, "peuplementId": peuplement.id, "identifiant":"Nouvel itinéraire technique", "enCours":false, "choisi":false, "auto":false, "dateDebut":"0", "coupeRase":0, "version":0};
            return lIT
        },

        async lireITCourant() {
            var itCourant = null;
            this.itCourant = 0;
            var choisi = -1;
            var enCours = -1;
            var thisObj = this;
            this.uf.peuplement.ITs.forEach(it => {
                if (it.choisi) {
                    choisi = it.id;
                }
                if (it.enCours) {
                    enCours = it.id;
                    thisObj.itCourant = enCours;
                    itCourant = it;
                }
                // trop lent ? vraiment faire ça ici ?
                if (it.auto || (typeof(it.coupeRase) === 'undefined') || !it.coupeRase ) {
                    ApiUser.calculerUFIt(this.$store.getters.infosPropriete, this.uf, this.$store.getters.constantes, it);
                }
            })
            // on n'a pas d'IT en cours car c'est le premier passage : le courant devient le choisi qui devient le enCours
            if ((enCours < 0) && (choisi>=0)) {
                this.itCourant = choisi;
                this.uf.peuplement.ITs.forEach(it => {
                    if (it.choisi) {
                        it.enCours = true;
                        itCourant = it;
                    }
                })
            }

            if (!this.itCourant) { // temporaire car à terme les IT STERES sont créés avec les peuplements
                var itCourant = this.creerItSteres(this.uf.peuplement);
                itCourant.identifiant = "Proposition Steres";
                itCourant.choisi = true;
                itCourant.auto = true;
                // TODO wiz il faut enregistrer
/*
this.old = JSON.parse(JSON.stringify(this.form)) // l'état "en cours" du formulaire devient l'ancien (pour la restauration)
var entityToSave = JSON.parse(JSON.stringify(this.form))
*/
                var entityToSave = JSON.parse(JSON.stringify(itCourant))
                return ApiUser.setIt(entityToSave) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)
                .then(response => {
                    itCourant.id = response;
                    this.itCourant = itCourant.id;
                    this.uf.peuplement.ITs[0] = itCourant;

                    ApiUser.calculerUFIt(this.$store.getters.infosPropriete, this.uf, this.$store.getters.constantes, itCourant);
                    return itCourant
                })
            }
            else {
                return itCourant
            }


        },

        initialiser() {
            this.uf.peuplement = this.$store.getters.infosPeuplement; // on s'assure que le peuplement est à jour
            return this.lireITCourant()
            .then(response => {
                this.initialiserInfos(response);
            })
        },
        initialiserInfos(it) {
            /*
            var thisObj = this;
            this.tabInfos = [];
            if (it && it.id) {
                if (!it.auto && it.eclaircies) {
                    it.eclaircies.forEach(eclaircie => {
                        eclaircie.anneeRetenue = thisObj.anneeRetenue(eclaircie.dateEclaircie);
                        eclaircie.age = eclaircie.anneeRetenue - thisObj.anneeInstallation;
                        eclaircie.type = "ECLAIRCIE";
                        eclaircie.date = thisObj.anneeRetenue(eclaircie.dateEclaircie);
                        thisObj.tabInfos.push(eclaircie);
                    })
                }
                if (it.auto) {
                    var eclairciesAuto = this.$store.getters.dendrometrie.eclairciesAuto;
                    eclairciesAuto.forEach(eclaircie => {
                        eclaircie.anneeRetenue = eclaircie.age + thisObj.anneeInstallation;
                        eclaircie.type = "ECLAIRCIEAUTO";
                        eclaircie.date = eclaircie.anneeRetenue;
                        eclaircie.densiteMoyenne = eclaircie.densiteApres;
                        thisObj.tabInfos.push(eclaircie);
                    })
                }
            }
            this.tabInfos.sort(this.triAnneeRetenue)
            */
        },

        onRetourItParam(itCourant) {

            this.uf.peuplement = this.$store.getters.infosPeuplement; // on met à jour du store
            this.$store.commit('setUfInfos', JSON.parse(JSON.stringify(this.uf))); // la transmission passe par le STORE
            this.itCourant = itCourant.id;
            this.initialiserInfos(itCourant);

            this.$emit('retourIt', itCourant);
        },

        onRetourItComparaison(comparaison) {
            this.comparaison = comparaison;
            this.comparaison.ratio = "BNA"
        },

	   triAnneeRetenue(x, y) {
           if (x.anneeRetenue != y.anneeRetenue) {
                return(y.anneeRetenue - x.anneeRetenue);
            }
           if (x.date < y.date) {
               return(-1)
           }
           if (x.date > y.date) {
               return(1)
           }
           return(0)
        },
        // l'année retenue peut être la précédente ( a priori si mois antérieur à juin)
        anneeRetenue(dateReelle) {
            return(ApiUser.anneeRetenuePeuplement(dateReelle, this.$store.getters.constantes));
        },
        ouvrirInfo(info) {
            if (this.lectureSeule) {
                return;
            }

            if (info.type == "ECLAIRCIE") {
                return(this.ouvrirEclaircie(info));
            }
        },


        ouvrirEclaircie(eclaircie) {
            if (this.lectureSeule) {
                return;
            }
            var idEclaircie = -1;
            if (eclaircie) {
                idEclaircie = eclaircie.id;
                this.$store.commit('setItEclaircieInfos', eclaircie);
            }
            this.$store.commit('setItId', this.itCourant);
            this.$store.commit('setItEclaircieId', idEclaircie);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplementiteclaircie" })
        },
        eclaircieAjouter() {
            this.ouvrirEclaircie(null);
        },
        arrondir(valeur, nbChiffresArrondi) {
            return(ApiTools.arrondir(valeur, nbChiffresArrondi));
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #itineraires {
        &>div>div, &>div>span {
            /*padding-left: 1em;*/
        }
        #paramItineraires {
            /* max-width: 1100px; */
        }
        #itinerairesTechniques {
            /*padding-left:1em;*/

            .conteneurTable {
                .infos, .troisBoutons {
                    /*display:none;*/
                }
            }

            .titreListe.suite {
                margin-top:2em;
                margin-bottom: 0;
            }
            div.comparaison {
                padding:0 1em;
                @media screen and (max-width: 820px) {
                    table {
                        width:100%;
                    }
                }
            }
            table {
                width: 100%;
                min-width: 600px;
                background-color: transparent;
                margin-bottom: 1em;
                color: #223938;
                /*border: 1px solid #223938;*/
                padding: 0.6em 1%;
                border-spacing : 0;
                margin:1em 0;

                &.comparaison {
                    width: 48%;
                    /*min-width: 500px;*/
                    min-width: unset;
                }

                tr {
                    vertical-align: top;

                    &.lien.lectureSeule {
                        cursor:default;
                    }

                    th {
                        text-align: left;
                        padding:1em 0;
                        width: 1%;
                        white-space: nowrap;

                        &:first-child {
                            border-left: 1px solid #223938;
                            padding-left:1em;
                        }
                    }
                    td {
                        padding:1em 1em;
                       .invalide {
                           color:red;
                        }
                    }
                    img.iconeFct {
                        text-align: left;
                        margin-left:1em;
                        float: none;
                        width : auto;
                    }
                }
            }
        }
    }
</style>
