<template>
 <div id="peuplementEditionForm">
     <form v-if="isReady">


        <h3 class="titreListe" >Installation *</h3>
         <p class="info">Pour modifier l'année, cliquer sur l'année affichée, faire défiler puis choisir le mois</p>
         <v-menu
            :close-on-content-click="false"
            v-model="lePicker"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
                 >
            <v-text-field
                slot="activator"
                label="Date d'installation *"
                v-model="form.dateInstallation"
                prepend-icon="event"
                readonly
                required
                :error-messages="error.dateInstallation"
                ref="dateInstallation"
            ></v-text-field>
            <v-date-picker
                no-title
                v-model="form.dateInstallation"
                type="month"
                locale="fr"
                :min="moisMinimum"
                :max="moisCourant"
                :date-format="date => new Date(date).toDateString()"
                @input="lePicker = false"
            ></v-date-picker>
        </v-menu>

         <p>Année de croissance retenue : {{anneeRetenue}}</p>

        <h3 class="titreListe" >Type de culture *</h3>
        <v-select
            v-model="form.cultureId"
            item-text="libelle"
            item-value="id"
            :items="lesItemsCulture"
            label="Choisir"
            required
            minValue=1
            :error-messages="error.culture"
        ></v-select>

        <h3 class="titreListe" >Essence *</h3>
        <v-select
            v-model="form.essenceId"
            item-text="libelle"
            item-value="id"
            :items="lesItemsEssence"
            label="Choisir"
            :disabled="lesItemsEssence.length === 1"
            required
            minValue=1
            :error-messages="error.essence"
        ></v-select>


        <v-text-field
            label="Surface boisée (en hectares)"
            v-model="form.surfaceBoisee"
            type="number"
            @keydown="filtrerNombresPositifs"
            required
            :error-messages="error.surfaceBoisee"
            ref="surfaceBoisee"
        ></v-text-field>
         <span>Surface maximum : {{form.surfaceUF}} ha</span>
<div class="clear">&nbsp;</div>

        <h3 class="titreListe blocACompleter" >Densité moyenne à l'installation</h3>
            <v-btn class="a-btn complementBloc lienModeForet" type="button" @click="enSavoirPlus=true;dialogESP = true;">En savoir plus</v-btn>
        <p class="info">Si vous ne connaissez pas la densité au moment de l'installation, vous pouvez laisser cette valeur à 0.<br/><span>La saisie ultérieure d'une mesure sera obligatoire.</span></p>
        <calcul-densite v-if="surfaceBoiseeOk"
            @retourDensite="onRetourDensite"
            :initDatas="infosCalculsDensite"
            @click:clear="clickClear"
            ref="calculDensite"
            :surfaceBoisee=form.surfaceBoisee
            required
        />


        <h3 class="titreListe" >Travaux</h3>
         <p class="info">Choisir les travaux appliqués à ce peuplement</p>
        <div class="listeTravaux">
            <div class="itemListeTravaux" v-for="(leTravail, indexTravail) in lesTravaux" v-bind:key="indexTravail" >
                <peuplement-travail :data='leTravail' :dataCout=true @retourItemCheck="onRetourTravail"></peuplement-travail>
            </div>
        </div>



        <h3 class="titreListe" >Densité minimale objectif après la dernière éclaircie * </h3>
        <v-text-field
            label="Objectif de densité après la dernière éclaircie *"
            v-model="form.eclaircieDensiteMin"
            type="number"
            @keydown="filtrerNombresPositifs"
            required
            min=1
            :error-messages="error.eclaircieDensiteMin"
            ref="eclaircieDensiteMin"
        ></v-text-field>


        <!-- les boutons peuvent être mis dans un composant pour être générique -->

        <div class="boutonsValidation">
            <v-btn class="a-btn secondaire" type="button" @click="cancelForm">Annuler</v-btn>
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Enregistrer</v-btn>
        </div>
    </form>

     <v-dialog v-model="dialogError" v-if="errorDensite || errorDate || errorSurface" >
         <v-card>
             <v-card-title class="headline">Erreur de saisie</v-card-title>
             <v-card-text>
                 <span v-if="errorDensite">{{errorDensite}}</span>
                 <span v-if="errorDate">{{errorDate}}</span>
                 <span v-if="errorSurface">{{errorSurface}}</span>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

     <v-dialog v-model="dialogESP" v-if="enSavoirPlus" content-class="grandeImageDialog">
         <v-card>
             <v-card-title class="headline">Comment mesurer la densité</v-card-title>
             <v-card-text>
                 <div class="grandeImage">
                 <img  src="@/assets/img/mDensite.png" width="623px" />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="lienAnnulerModeForet" color="" text @click="dialogESP=false;enSavoirPlus=false;">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'

import { ApiUser, ApiTools } from "../../js/api/index.js"

import CalculDensite from '../ui/mesures/CalculDensite.vue'
import ItemCheck from '../ui/ItemCheck.vue'

export default {

    components: {
        'calcul-densite': CalculDensite,
        'peuplement-travail': ItemCheck
    },

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {
            ready:false,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.data)),
            old: null,
            error: {
                dateInstallation: '',
                culture: '',
                essence: '',
                surfaceBoisee: '',
                eclaircieDensiteMin:'',
            },
            tabTravaux:[],
            itemsCulture: [],
            itemsEssence: [],
            clear:{
                    id: '',
                    label: ''
                },
            lePicker: false,
            calculsDensite : {
                methodeId : 0,
                methodesPossibles : { // refaire avec table calc_densitemethodes ?
                    choix : false,
                    interlignes : true,
                    surfaceTerriere : false,
                    ratios : false,
                    saisieDirecte : true,
                },
                interlignes : {
                    nombre : '',
                    largeur : [],
                    longueur : '',
                    tabNombresArbres:[]
                },
                saisieDirecte : {
                    densite : ''
                },
            },
            wait:{
                methodeId : 0,
                methodesPossibles : {
                    choix : false,
                    interlignes : true,
                    surfaceTerriere : false,
                    ratios : false,
                    saisieDirecte : true,
                },
                interlignes : {
                    nombre : 0,
                    largeur : [],
                    longueur : 0,
                    tabLargeurs:[],
                    tabNombresArbres:[]
                },
                saisieDirecte : {
                    densite : ''
                },
            },
            dialogESP:false,
            dialogError:false,
            errorDensite:false,
            errorDate:false,
            errorSurface:false,
            enSavoirPlus:false
        }
    },

    created() {
        this.initialiserTravaux();
        if (this.form.eclaircieDensiteMin == -1) {
            this.form.eclaircieDensiteMin = "";
        }
        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        //this.old = ApiTools.clone(this.form);
        this.initItems();

        if (this.$store.getters.modeForet == 6) {
            this.form.densiteMethodeId = 1;
            this.$store.commit('setModeForet', 7);
        }
    },

    mounted: function () {
    },

    validations() {
        return {
            form: {
                dateInstallation: { minLength: minLength(5), maxLength: maxLength(7) },
                cultureId: {  required, minValue:minValue(1) },
                essenceId: {  required, minValue:minValue(1) },
                surfaceBoisee: {  required, minValue:minValue(0) },
                eclaircieDensiteMin: {  required, minValue:minValue(1) },
            }
        }
    },

    computed: {
        surfaceBoiseeOk() {
            var surfaceBoiseeTest = parseFloat(this.form.surfaceBoisee);
            if (isNaN(surfaceBoiseeTest) || !surfaceBoiseeTest) {
                return(false);
            }
            this.form.surfaceBoisee = surfaceBoiseeTest; // on passe en float
            return(!this.surfaceBoiseeErrors())
        },
        moisMinimum() {
            var moisBascule = this.$store.getters.constantes.steres.moisBascule;
            var lAnnee = new Date().getFullYear()-this.itemsEssence[0].vieMax; // TODO pb ergonomie si essence choisie après la date d'installation (prévoir avertissement)
            var leMoisCalc = Math.min(new Date().getMonth()+1, moisBascule-1);
            var leMois = "" + leMoisCalc;
            // var leMois = "" + (1+1); // pour test
            leMois.padStart(2, '0');
            return lAnnee+"-"+leMois
        },
        moisCourant() {
            var moisBascule = this.$store.getters.constantes.steres.moisBascule;
            var lAnnee = new Date().getFullYear();
            var leMoisCalc = Math.min(new Date().getMonth()+1, moisBascule-1);
            var leMois = "" + leMoisCalc;
            // var leMois = "" + (1+1); // pour test
            leMois.padStart(2, '0');
            return lAnnee+"-"+leMois
        },
        isReady() {
            if (!this.ready) {
                return(false);
            }
            return(this.ready);
        },
        // pour le calcul de la densité
        infosCalculsDensite() {
            if (this.form.densiteMethodeId) {
                this.calculsDensite.methodeId = this.form.densiteMethodeId;
                var objJson = JSON.parse(this.form.densiteJson);
                if (this.form.densiteMethodeId == 1) {  // refaire avec table calc_densitemethodes ?
                    if (typeof(objJson.longueur) !== 'undefined') {
                        this.calculsDensite.interlignes = objJson;
                    }
                }
                if (this.form.densiteMethodeId == 4) {
                    if (typeof(objJson.densite) !== 'undefined') {
                        this.calculsDensite.saisieDirecte = objJson;
                    }
                }
            }
            return (JSON.parse(JSON.stringify(this.calculsDensite)));
            //return (this.calculsDensite)
        },
        // pour le combo "cultures"
        lesItemsCulture() {
            return(this.itemsCulture);
        },
        // pour le combo "essences"
        lesItemsEssence() {
            return(this.itemsEssence);
        },
        // l'année retenue peut être la précédente ( a priori si mois antérieur à juin)
        anneeRetenue() {
            var dateInstallation = this.form.dateInstallation;
            return(ApiUser.anneeRetenuePeuplement(dateInstallation, this.$store.getters.constantes));
        },
        lesTravaux() {
            var tableauAffichage = this.tabTravaux.slice();
            return(tableauAffichage.sort(this.triTravail));
        }
    },

    methods: {

        // travaux
        initialiserTravaux() {
            this.tabTravaux = JSON.parse(this.form.travauxJson);
            var thisObj = this;
            this.tabTravaux.forEach(travail => {
                thisObj.tabTravaux[travail.code] = travail;
            })
        },

        onRetourTravail(travail) {
            this.tabTravaux[travail.code].choisi = travail.choisi;
        },

        triTravail(x, y) {
            if (x.anneeDebut < y.anneeDebut) {
                return(-1)
            }
            if (x.anneeDebut > y.anneeDebut) {
                return(1)
            }

            if (x.libelle < y.libelle) {
                return(-1)
            }
            if (x.libelle > y.libelle) {
                return(1)
            }
            return(0)
        },

        // contenu du combo type de culture
        initItems() {
            var constantes = this.$store.getters.constantes;
            this.itemsCulture = constantes.params.const_cultures; // attention on a des datas 0,n mais aussi associatives (par code) : ça ne semble pas géner vue
            this.itemsEssence = constantes.params.const_essences;
            if (this.itemsEssence.length == 1) {
                this.form.essenceId = this.itemsEssence[0].id;
            }
            this.ready = true;
        },

                // gestion du calcul de densite
        onRetourDensite(densiteObj) {
            this.form.densiteInstallation = densiteObj.densite;
            this.form.densiteMethodeId = densiteObj.methodeId;

            this.form.densiteJson = "{}";
            if (densiteObj.methodeId == 1) {
                this.form.densiteJson = JSON.stringify(densiteObj.interlignes); // JSON.parse à l'aller
            }
            if (densiteObj.methodeId == 4) {
                this.form.densiteJson = JSON.stringify(densiteObj.saisieDirecte); // JSON.parse à l'aller
            }
        },
        clickClear() {

        },

        // annulation du formulaire
        majCalculsDensite(objGlob, calculsDensite) {
            objGlob.calculsDensite = calculsDensite;
        },
        cancelForm() {
            // on remet les données anciennes dans 'form'
            this.form = JSON.parse(JSON.stringify(this.old))
            /* INUTILE PUISQUE CHANGEMENT DE PAGE
            // on force le rafraichissement
            var objSav = Object.assign({}, this.calculsDensite);
            var objTmp = Object.assign({}, this.wait);
            this.calculsDensite = objTmp;
            setTimeout(this.majCalculsDensite.bind(null, this, objSav), 250);
            */
            var message = "La saisie du peuplement de l'année "+this.anneeRetenue+ " a été abandonnée.";
            // on va au bon endroit avec les bonnes valeurs
            this.terminerEdition(message);

        },
        // enregistrement du formulaire
        async saveForm() {

            // on reinit à vide les messages d'erreur
            this.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (this.$v.$invalid) {
                // maj erreurs
                this.dateInstallationErrors();
                this.cultureErrors();
                this.essenceErrors();
                this.surfaceBoiseeErrors();
                this.eclaircieDensiteMinErrors();
                return false
            }
            // si date vide
            if (!this.form.dateInstallation) {
                this.dialogError = true;
                this.errorDate = "Attention ! Vous devez saisir une date d'installation.";
                return;
            }
            // stockage d'entiers
            var eclaircieDensiteMin = parseInt(this.form.eclaircieDensiteMin);
            if (!isNaN(eclaircieDensiteMin)) {
                this.form.eclaircieDensiteMin = eclaircieDensiteMin;
            }
            var surfaceBoisee = parseFloat(this.form.surfaceBoisee);
            if (!isNaN(surfaceBoisee)) {
                this.form.surfaceBoisee = surfaceBoisee;
            }
            // si incohérence entre densités saisies
            if (this.form.densiteInstallation && (this.form.densiteInstallation <= this.form.eclaircieDensiteMin)) {
                this.dialogError = true;
                this.errorDensite = "Attention ! Indiquer un objectif de densité minimale supérieur à votre densité à l'installation n'a pas de sens ! Revoyez vos saisies.";
                return;
            }
            // si incohérence surface
            if (this.form.surfaceBoisee > this.form.surfaceUF) {
                this.dialogError = true;
                this.errorSurface = "Attention ! La surface boisée ne peut pas dépasser celle de l'unité forestière, soit " + this.form.surfaceUF + " ha.";
                return;
            }


            this.form.travauxJson = JSON.stringify(this.tabTravaux); // JSON.parse à l'aller

            // si pas de modification des données
            if (JSON.stringify(this.old) === JSON.stringify(this.form)) {
                this.terminerEdition();
                return false
            }

            // on sauvegarde (distant et local)
            this.form.anneeRetenue = this.anneeRetenue; // attention ! Mettre à jour 2020/05
            this.old = JSON.parse(JSON.stringify(this.form)) // l'état "en cours" du formulaire devient l'ancien (pour la restauration)
            this.form.modeForet = this.$store.getters.modeForet;
            this.form.modeForetUfId = this.$store.getters.ufId;
            var entityToSave = JSON.parse(JSON.stringify(this.form));
            console.log("saveForm OK");
            return ApiUser.setPeuplement(entityToSave, this.$store.getters.constantes) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)
            .then(response => {
                var peuplement = response;
                this.$store.commit('setPeuplementId', peuplement.id); // tmp
                this.$store.commit('setPeuplementInfos', JSON.parse(JSON.stringify(peuplement))); // la transmission passe par le STORE
                var uf = this.$store.getters.infosUf; // on met à jour du store
                uf.peuplement = peuplement; // on met à jour du store
                this.$store.commit('setUfInfos', JSON.parse(JSON.stringify(uf))); // la transmission passe par le STORE


                // on prépare le message passé à la prochaine page
                var message = "";
                message = "La saisie du peuplement de l'année "+this.anneeRetenue+ " a bien été enregistrée.";
                if (!this.form.densiteInstallation) {
                    message = "La saisie du peuplement de l'année "+this.anneeRetenue+ " a bien été enregistrée. Vous n'avez pas enregistré de densité, la saisie d'une mesure sera donc nécessaire.";
                }
                /*
                if (navigator.onLine) {
                    message = "La saisie du peuplement de l'année "+this.anneeRetenue+ " a bien été enregistrée.";
                    if (!this.form.densiteInstallation) {
                        message = "La saisie du peuplement de l'année "+this.anneeRetenue+ " a bien été enregistrée. Vous n'avez pas enregistré de densité, la saisie d'une mesure sera donc nécessaire.";
                    }
                }
                else {
                    message = "La saisie du peuplement de l'année "+this.anneeRetenue+ " a bien été enregistrée. Attention, vous travaillez hors ligne, il vous faudra donc synchroniser cette saisie ultérieurement avec la base de données Stères.";
                }
                */
                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message);
            })
        },


        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message) {
            if (message) {
                this.$store.commit('donnerMessage', message);
            }
            //this.$store.commit('setUfId', 0); // tmp
            //this.$store.commit('resetDataApplication');
            if (parseInt(this.$store.getters.peuplementId) > 0) {
                this.$router.push({ name: "peuplement", params: { voirDendrometrie: true} });
            }
            else {
                this.$router.push({ name: "propriete" });
            }
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.dateInstallation = ''
            this.error.culture = ''
            this.error.essence = ''
            this.error.surfaceBoisee = ''
            this.error.eclaircieDensiteMin = ''
            this.errorDensite = false
            this.errorDate = false
            this.errorSurface = false
        },
        dateInstallationErrors () {
            if (!this.$v.form.dateInstallation.$invalid) return false // verifier invalid
            if (!this.$v.form.dateInstallation.maxLength) {
                this.error.dateInstallation  = "Le format de la date est 1999-12."
            }
            if (!this.$v.form.dateInstallation.minLength) {
                this.error.dateInstallation  = "La saisie d'une date d'installation est obligatoire."
            }
            if (!this.$v.form.dateInstallation.required) {
                 this.error.dateInstallation  = "La saisie d'une date d'installation est obligatoire." // NON !!!
            }
            return true
        },
        cultureErrors () {
            if (!this.$v.form.cultureId.$invalid) return false // verifier invalid
            if ((!this.$v.form.cultureId.required) || (!this.$v.form.cultureId.minValue))  {
                 this.error.culture  = "Le choix d'un type de culture est obligatoire."
            }
            return true
        },
        essenceErrors () {
            if (!this.$v.form.essenceId.$invalid) return false // verifier invalid
            if ((!this.$v.form.essenceId.required) || (!this.$v.form.essenceId.minValue))  {
                 this.error.essence  = "Le choix d'une essence est obligatoire."
            }
            return true
        },
        surfaceBoiseeErrors () {
            if (!this.$v.form.surfaceBoisee.$invalid) return false // verifier invalid
            if (!this.$v.form.surfaceBoisee.required) {
                 this.error.surfaceBoisee  = "Vous devez indiquer quelle est la surface boisée de votre peuplement."
            }
            if (!this.$v.form.surfaceBoisee.minValue) {
                 this.error.surfaceBoisee  = "Vous devez saisir une surface boisée non nulle."
            }
            return true
        },
        eclaircieDensiteMinErrors () {
            if (!this.$v.form.eclaircieDensiteMin.$invalid) return false // verifier invalid
            if (!this.$v.form.eclaircieDensiteMin.required) {
                 this.error.eclaircieDensiteMin  = "Vous devez indiquer quelle est la densité minimale souhaitée pour ce peuplement après la dernière éclaircie."
            }
            if (!this.$v.form.eclaircieDensiteMin.minValue) {
                 this.error.eclaircieDensiteMin  = "Vous devez saisir une densité minimale non nulle."
            }
            return true
        },
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },

        fermerDialogError() {
            this.dialogError = false;

            if (this.errorDate) {
                this.$nextTick(() => {
                    this.$refs.dateInstallation.focus();
                })
            }
            else {
                if (this.errorSurface) {
                    this.$nextTick(() => {
                        this.$refs.surfaceBoisee.focus();
                    })
                }
                else {
                    if (this.errorDensite) {
                        this.$nextTick(() => {
                            if (typeof(this.$refs.calculDensite.$refs.densiteA) !== 'undefined') {
                                this.$refs.calculDensite.$refs.densiteA.focus(); // on donne le focus au premier item de saisie densité (dépend de la méthode)
                            }
                        })
                    }
                }
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

        #app {
            .grandeImageDialog {
                max-width: 900px;
                overflow:hidden;
                div {
                    /*max-height: 100%;*/
                    position:relative;
                    overflow:hidden;
                }

                .grandeImage {
                    overflow: scroll;
                    text-align: center;
                    height: auto;
                    max-height: 70vh;
                    max-width:645px;
                    margin: 0 auto;

                    img {
                        width:auto;
                        height:auto;
                        max-width: none;
                        /*max-height: 1147px;*/
                    }
                }
            }
            .conteneur {

                #peuplementEditionForm {
                    h3.blocACompleter {
                        max-width:250px;
                    }
                    .complementBloc {
                        font-size:0.8em;
                        height: 30px;
                    }
                    .edition {
                        .info {
                            margin:1em 0 0;
                            }
                    }
                    .listeTravaux {
                        padding :1em 0 0 1em;

                        div.v-input--checkbox {
                            background-color:transparent;
                            padding-top: 0;

                            .v-icon {
                                color:#394F4E;
                                }
                            label {
                                color:#394F4E;
                                font-weight:bold;
                            }
                        }

                        .v-input {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

</style>
