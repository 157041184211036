<template>
    <div class="proprietes containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>
        <div v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="wrapper">
                <div class="conteneur"  v-if="utilisateurBloque">
                    <span class="infoBloquee">
                    Votre compte est bloqué, merci de contacter l'équipe STERES (contact@steres.fr).
                    </span>
                </div>
                <div class="conteneur"  v-if="!utilisateurBloque">
                    <div class="gauche" @click.stop="creerPropriete" v-if="!limitationProprietes && $store.getters.utilisateurCourant.profilId!=3">
                        <v-btn class="boutonFonctionObjet prioritaire" type="button" @click.stop="creerPropriete">Créer une propriété</v-btn>
                    </div>
                    <div class="gauche" v-if="limitationProprietes && $store.getters.utilisateurCourant.profilId!=3">
                    <span class="infoBloquee">
                        Vous avez atteint le nombre maximum de propriétés en mode "ESSAI". Pour pouvoir créer de nouvelles propriétés, merci de contacter l'équipe STERES (contact@steres.fr).
                    </span>
                    </div>
                    <div class="clear"></div>
                    <div class="proprietesGroup"  v-for="(lesProprietes, numGroupe) in groupesProprietes" :key="numGroupe" >
                        <div class="proprietesList"  v-for="(laPropriete, numPropriete) in lesProprietes" :key="numPropriete"  v-bind:class="{nouveauProprietaire : laPropriete.nouveauProprietaire}">
                            <div class="clear" v-if="laPropriete.nouveauProprietaire">&nbsp;</div>
                            <div class="proprietaire" v-if="laPropriete.nouveauProprietaire">Propriétaire : {{laPropriete.proprietaire.prenom}} {{laPropriete.proprietaire.nom}}</div>
                            <div class="boite" v-if="!laPropriete.bloquee" v-on:click="ouvrirPropriete(laPropriete, (($store.getters.utilisateurCourantId != laPropriete.gestionnaireId) && !laPropriete.proprietaireDroitEcriture) || laPropriete.saisieHorsLigneId)"  v-bind:class="{lectureSeule : (($store.getters.utilisateurCourantId != laPropriete.gestionnaireId) && !laPropriete.proprietaireDroitEcriture) || laPropriete.saisieHorsLigneId}">
                                <span class="titreBoite">{{ laPropriete.identifiant }}</span>
                                {{ laPropriete.adresse }}<br/>
                                {{ laPropriete.codePostal }} {{ laPropriete.libelleCommune }}
                                <span class="infoLectureSeule">Vous pouvez accéder à cette propriété uniquement en consultation - pour plus d'informations, consultez son gestionnaire.</span>
                            </div>
                            <div class="boite bloquee" v-if="laPropriete.bloquee">
                                <span class="titreBoite">{{ laPropriete.identifiant }}</span>
                                {{ laPropriete.adresse }}<br/>
                                {{ laPropriete.codePostal }} {{ laPropriete.libelleCommune }}
                                <span class="infoBloquee">Cette propriété est bloquée - pour plus d'informations, consultez son gestionnaire.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'

export default {


    data(){
        return {
            proprietes:[],
            propriete: null,
            ready:false,
            onLine:true,
 //           propriete: new Object(),
        }
    },
    created: function () {
        this.ready = false;
        this.$store.commit('setProprieteId', 0);
        this.$store.commit('setUfId', 0);
        this.$store.commit('resetDataApplication');
        this.$store.commit('resetTrisFiltresPropriete'); // on reset les filtres de la propriété précédente
    },
    mounted: function () {
            // on initialise
            this.ready = false;
            this.initialiser();
    },
    computed: {
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        utilisateurBloque() {
            return(this.$store.getters.utilisateurCourant.bloque);
        },
        limitationProprietes() {
            var limitationNbProprietes = this.$store.getters.utilisateurCourant.nbProprietes;
            if ((limitationNbProprietes <= 0) || (limitationNbProprietes > this.proprietes.length)) {
                return(false);
            }
            return(true);
        }
    },

    methods: {
        initialiser() {
            // on initialise la propriété à vide
            this.initialiserPropriete();
            this.initialiserProprietes();
        },

        initialiserProprietes() {

            var utilisateurCourantId = this.$store.getters.utilisateurCourantId;
            // on charge les éventuelles propriétés de l'utilisateur
            if (!utilisateurCourantId) {
                return;
            }

            return ApiUser.getUserProprietes(utilisateurCourantId)
                .then(response => {
                    var objEnCours = this;
                    var allPromisesProprietaires = [];
                    var proprietaires = [];
                    response.forEach(propriete => {
                        var i = proprietaires.length;
                        var idpropriete = "id" + propriete.id;
                        response[idpropriete] = propriete;
                        propriete.nouveauProprietaire = false;
                        proprietaires[i] = ApiUser.lireUtilisateur(propriete.proprietaireId); // il y en a maintenant un seul  !!!
                        allPromisesProprietaires.push(proprietaires[i])
                    })
                    this.proprietes = response;
                    return Promise.all(allPromisesProprietaires).then(responseProprietaires => {
                        var i=0;
                        responseProprietaires.forEach(proprietaire => {
                            objEnCours.proprietes[i].proprietaire = proprietaire.utilisateur; // il y a 1 proprietaire par propriete
                            i++;
                        })

                        objEnCours.proprietes.sort(this.triProprietaire)

                        var proprietePrecedente = null;
                        this.groupesProprietes = [];
                        var proprietes = [];
                        objEnCours.proprietes.forEach(propriete => {
                            if ((!proprietePrecedente) || (proprietePrecedente.proprietaireId != propriete.proprietaireId)) {
                                if (proprietePrecedente) {
                                    this.groupesProprietes.push(proprietes);
                                    proprietes = [];
                                }
                                propriete.nouveauProprietaire = true; // on change de ligne, si on change de proprietaire
                            }
                            proprietes.push(propriete);
                            proprietePrecedente = propriete;
                        })
                        this.groupesProprietes.push(proprietes);

                        objEnCours.ready = true;

                    })

                })
                .catch(e => {
                    console.log("pb getUserProprietes : Proprietes.vue", e);
                });
        },

        triProprietaire(x, y) {

            if (x.proprietaire.nom != y.proprietaire.nom) {
               return x.proprietaire.nom.toString().localeCompare(y.proprietaire.nom.toString());
            }

            if (x.identifiant != y.identifiant) {
               return x.identifiant.toString().localeCompare(y.identifiant.toString());
            }




           if (x.codePostal < y.codePostal) {
               return(1)
           }
           if (x.codePostal > y.codePostal) {
               return(-1)
           }

           if (x.libelleCommune < y.libelleCommune) {
               return(1)
           }
           if (x.libelleCommune > y.libelleCommune) {
               return(-1)
           }
           return(0)
        },

        initialiserPropriete() {

            var utilisateurCourantId = this.$store.getters.utilisateurCourantId;
            // on charge les éventuelles propriétés de l'utilisateur
            if (!utilisateurCourantId) {
                return;
            }

            this.propriete = new Object();

        // on initialise la propriété à vide
            this.propriete.id = 0;
            this.propriete.userId = utilisateurCourantId;
            this.propriete.libelle = "";
            this.propriete.siret = "";
            this.propriete.version = 0;
            this.propriete.idLocal = true;
        },

        ouvrirPropriete(propriete, lectureSeule=false) {
            this.$store.commit('setProprieteInfos', propriete); // infos utiles pour calcul - abaque, taux d'Actualisation ...
            this.$store.commit('setProprieteId', propriete.id);
            this.$store.commit('setLectureSeule', lectureSeule);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "propriete" });
        },
        creerPropriete() {
            this.$store.commit('setProprieteId', -1);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "proprieteedition" });
        },
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #app {
        div.wrapper div.conteneur {
            margin:2em auto;

            .infoBloquee {
                color:#E84B00;
                font-size:0.9em;
                line-height: 1.2em;
                margin-top: 0.4em;
            }

            .proprietesGroup {
                    display:block;
                .proprietesList {
                    display:inline-block;
                    /*margin-left:1em;*/
                    margin-right:1em;
                    vertical-align: bottom;

                    span {
                        cursor:pointer;
                        display:inline-block;
                    }

                    .proprietaire {
                        font-weight: bold;
                        margin:0.4em 0;
                    }

                    &.nouveauProprietaire {
                       /* margin-left:0;*/ /* on passe par un margin-right sur .proprietesList */
                    }

                    div.boite {
                        display:inline-block;
                        cursor: pointer;
                        padding:1em;
                        font-size:1.1em;

                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 50%;
                        flex: 0 0 50%;
                        width: 280px;
                        max-width: 100%;

                        min-height: 100px;

                        /*
                        background-color: #238b71;
                        color:white;
                        */
                        background-color: #fcfcfc;
                        /*
                        color:white;
                        */
                        margin: 0 0 1em;
                        position: relative;
                        box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);

                        &.bloquee {
                            cursor: auto !important;
                            background-color: #ececec;
                            span {
                                cursor: auto !important;
                            }
                        }

                        .titreBoite {
                            display:block;
                            font-weight: bold;
                            font-size:1.1em;
                        }

                        &:hover {
                            transition: all 250ms;
                            /*height: 141px;*/
                            transform: translateY(-3px);
                            box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
                        }

                        &.lectureSeule {
                            background-color: #F3F9F2;
                            /*color:#252525;*/
                            min-height: 150px;

                            .infoLectureSeule {
                                display:block;
                            }
                        }

                        .infoLectureSeule {
                            margin-top:1em;
                            font-size:0.8em;
                            font-style: italic;
                            display:none;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 640px) {
        #app {
            div.wrapper {
                div.conteneur {
                    .proprietesGroup {
                        .proprietesList {
                            margin-left: 0;
                            display:block;
                            text-align: center;

                            div.proprietaire {
                            text-align: left;
                            }

                            div.boite {
                                text-align: left;
                                margin-left: auto;
                                margin-right: auto;
                                width:100%;
                                max-width: 400px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

