<style>
/*
    @import './Courbes.css';
*/
</style>

<template>

  <div id="presentation" class="home containerLog">
    <div class="sectionAffichage">
        <div class="conteneur">

            <h1 >Présentation du logiciel STERES</h1>
            <p class="lienPDF">
                <span  @click.stop="ouvrirPDF">
                    <i class="fa fa-file-pdf-o"></i>
                    Télécharger la notice d'utilisation au format PDF (492 Ko)
                </span>
            </p>
        </div>
    </div>
  </div>

</template>

<script>

export default {

    name: 'presentation',
    components: {
    },
    data() {
        return {
        pdfChemin:'/img/',
        pdfNom:'noticeUtilisationSTERES.pdf',
        }
    },
    mounted: function () {
    },
    updated: function () {

    },
    computed: {

    },
    methods: {
        ouvrirPDF() {
            this.ouvrirFichier(this.pdfChemin, this.pdfNom)
        },
        forceFileDownload(response, leFichier){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', leFichier) //or any other extension
            document.body.appendChild(link)
            link.click()
        },
        ouvrirFichier(leChemin, leFichier) {
          this.$http({
            method: 'get',
            url: leChemin+leFichier,
            responseType: 'arraybuffer'
          })
          .then(response => {
            this.forceFileDownload(response, leFichier)
          })
          .catch(() => console.log('error occured'))
        }
    }
}


</script>

<style lang="scss">
    #app {
        #presentation {
            min-width: 540px;

            div.sectionAffichage {
                max-width: 640px;
                margin-left:auto;
                margin-right:auto;
                @media screen and (max-width: 840px) {
                    margin-left:20px;
                    margin-right:20px;
                }

                .conteneur {
                    .lienPDF {
                        span {
                            cursor:pointer;
                            color: #238b71;
                            font-weight: bold;
                            .fa {
                                font-weight: bold ;
                            }
                        }
                    }

                }
            }
        }
    }

</style>
