<style>
/*
    @import './Courbes.css';
*/
</style>

<template>

  <div id="mentionsLegales" class="home containerLog">
    <div class="sectionAffichage">
        <div class="conteneur">

            <h1 >Charte de protection de vos données personnelles</h1>


<h2>Protection de la vie Privée</h2>
<p>
Les données personnelles collectées par le biais du site internet et de l’application web STERES sont collectées et traitées conformément au règlement Européen 2016/679 et de la Loi informatique et libertés.</p>
<p>Vous pourrez retrouver le texte du règlement Européen applicable ci-après : <a href="https://www.legifrance.gouv.fr/eli/loi/2018/6/20/2018-493/jo/texte" target="_blank">https://www.legifrance.gouv.fr/eli/loi/2018/6/20/2018-493/jo/texte</a>
</p>
<p>La société <a href="https://www.addeo.com" target="_blank">Addeo</a>, ci-dessous dénommée l’Editeur, est en charge de la protection de vos données à caractère personnel collectées via le site internet et l’application mobile STERES au sens de l’Art. 4(7) du RGPD.
</p>
<h2>Collecte des données à caractère personnel</h2>
<p>Les données personnelles sont collectées lors de votre visite sur notre site internet et sur l’application STERES sur votre smartphone, lors de votre souscription ou de votre abonnement STERES.
</p>
<p>Conformément à la réglementation, le traitement de vos données personnelles est fondée sur :</p>
<ul>
<li>Votre consentement au traitement de vos données. Vous pourrez retirer ce consentement à tout moment.</li>
<li>L’existence d’un contrat entre vous et notre société pour les besoins de l’abonnement STERES et les paiements.</li>
<li>L’intérêt légitime pour notre société au traitement de vos données personnelles c’est-à-dire en respectant la proportionnalité entre cet intérêt légitime et vos droits fondamentaux et votre vie privée. Les données saisies peuvent servir à analyser les résultats et les pratique sylvicoles.</li>
<li>Le respect de la réglementation en ce qui concerne le traitement de vos données personnelles.</li>
</ul>
<h2>Nous collectons ces Données à caractère personnel :</h2>
<ul>
<li>à partir des informations que vous acceptez de nous transmettre directement ou indirectement via votre gestionnaire forestier réalisant des prestations pour votre compte et comportant des traitements de vos données personnelles dans le respect des finalités précisées dans la présente chartre.</li>
<li>de façon automatique à partir des données de navigation au sein de l’application STERES</li>
<li>Les cookies.</li>
</ul>
<p>
Les données sont conservées pendant toute la phase d’utilisation de l’application.</p>
<p>Nous ne collectons pas de données non nécessaires à la finalité du traitement précisé lors de la collecte de vos données.</p>

<h2>Les données à caractère personnel utilisées :</h2>
<p>Les Données à caractère personnel que nous collectons peuvent amener notre organisme au traitement de vos données personnelles suivantes :</p>
<ul>
<li>Les données relatives à votre identification : nom, prénom, date de naissance, adresse email, éléments de facturation, numéro de téléphone.</li>
<li>Les données que vous nous aurez transmises. Ces données sont intégrées à un traitement destiné à personnaliser les recommandations d’aide à la décision en matière sylvicole (basé sur des données générales des plantations). Ces données incluent des données de vos propriétés forestières que nous utiliserons avec votre consentement préalable conformément à l’Art. 9(2)(a) du RGPD. Ce traitement de vos Données à caractère personnel, est nécessaire à l’exécution d’une partie de nos services (aide à la gestion).</li>
<li>Données relatives à votre programme de gestion.</li>
<li>Les éléments relatifs à votre navigation sur notre site : identifiant, mot de passe<span class="cacher">, adresse IP, cookies</span>.</li>
</ul>
<h2>Conservation des données :</h2>
<p>Nous conservons vos données à caractère personnel et vos autres informations aussi longtemps que nécessaire pour vous permettre d’utiliser notre Site Web et suivre votre abonnement, pour vous fournir nos services, respecter les lois en vigueur (y compris celles concernant la conservation des documents), et nous permettre de mener nos activités. Si vous avez une question concernant une période de conservation particulière pour certains types de Données à caractère personnel que nous traitons sur vous, veuillez nous contacter en utilisant les coordonnées indiquées ci-dessous.</p><p>Vous avez le droit de vous opposer au traitement de vos Données à caractère personnel dans certaines circonstances, en particulier si nous les traitons en nous appuyant sur la base juridique relative à nos intérêts légitimes (Art. 6(1)(f) du RGPD)</p>

            <h2>Partage des données à caractère personnel</h2>
<p>Nous ne partagerons, ne vendrons, ne transférerons et ne diffuserons pas vos Données à caractère personnel à des tiers, à moins que la loi ne l’exige conformément à l’Art. 6(1)(c) RGPD, ou que cela ne soit nécessaire à la bonne exécution de votre contrat conformément à l’Art. 6(1)(c) du RGPD, sauf si le tiers agit en tant que sous-traitant pour notre compte conformément à l’Art. 28 du RGPD ou si vous nous avez donné votre consentement exprès conformément à l’Art. 6(1)(a) du RGPD.</p>
<p>Nous pouvons également être tenus de divulguer vos données personnelles aux autorités gouvernementales ou aux forces de l’ordre en réponse à une demande légitime ou si nous devons le faire pour nous conformer à une obligation légale.</p>
<p>Si cela est obligatoire en vertu de la législation en vigueur en matière de protection des données, nous obtiendrons votre consentement au préalable avant de partager vos Données à caractère personnel avec d’autres entreprises.</p>
<h2>Vous avez les droits suivants :</h2>
<h3>Retrait de votre consentement à la collecte ou au traitement de vos données personnelles :</h3>
Votre consentement pour la collecte de vos données personnelles peut être retiré en écrivant à CPFA, 6 Parvis des Chartrons  33075 Bordeaux cedex. Merci de mentionner vos noms, prénoms, email, adresse avec l’objet de votre demande.
Vous pouvez nous adresser tout commentaire ou demande sur vos données personnelles.
<h3>Droit d’accès</h3>
Vous avez le droit de demander la confirmation du traitement de vos données à caractère personnel et, le cas échéant, de demander l’accès aux Données à caractère personnel que nous détenons sur vous.
<h3>Droit de rectification</h3>
Vous avez le droit de demander la correction de Données à caractère personnel qui seraient inexactes.
<h3>Droit d’effacement</h3>
Vous avez le droit de demander l’effacement des Données à caractère personnel dans un délai raisonnable dans certaines circonstances, par exemple si vos Données à caractère personnel ne sont plus nécessaires aux fins pour lesquelles elles ont été́ collectées ou si vous retirez le consentement sur lequel notre traitement est basé selon l’Art. 6(1)(a) du RGPD et lorsqu’il n’y a pas d’autre motif légal pour le traitement.
<h3>Droit à la restriction du traitement</h3>
Vous avez le droit de nous demander de restreindre le traitement de vos Données à caractère personnel dans certaines circonstances, par exemple si vous estimez que les Données à caractère personnel que nous traitons à votre sujet sont incorrectes ou illégales.
<h3>Droit à la portabilité des données</h3>
Dans certaines circonstances, vous avez le droit de recevoir vos Données à caractère personnel que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible et vous avez le droit de transmettre ces informations à un autre responsable du traitement sans entrave ou de nous demander de le faire.
<h3>Droit d’opposition</h3>
Vous pouvez faire valoir les droits susmentionnés en nous contactant via les coordonnées indiquées ci-dessous.
<h3>Droit de déposer une plainte devant l’autorité de protection des données</h3>
Vous avez le droit de déposer une plainte devant une autorité de contrôle, en particulier dans l’État membre de l’UE où vous avez votre résidence habituelle, votre lieu de travail ou le lieu de la supposée violation si vous considérez que notre traitement de vos Données à caractère personnel viole la législation en vigueur en matière de protection des données. Veuillez nous contacter en utilisant les coordonnées indiquées ci-dessous et nous vous aiderons à trouver l’autorité de contrôle compétente.
<h3 class="cacher">Politique des cookies</h3>
<p>Nous n’utilisons pas de cookies sur notre site</p>
<h3 class="cacher">Politique des cookies</h3>
<p class="cacher">Nous utilisons des cookies sur notre site pour lesquels votre consentement est sollicité.<br/>
    Sont utilisés les cookies suivants :</p>
<ul class="cacher">
<li>Cookies nécessaires à l’utilisation optimisée du site,</li>
<li>Cookies de performances permettant d’établir des statistiques et d’établir le niveau de trafic du site</li>
<li>Cookies fonctionnels collectant des informations sur votre utilisation du site et permettant l’adaptation permanente de notre offre,</li>
<li>Cookies analytiques nous permettant d’analyser votre navigation sur notre site.</li>
<li>Les cookies sont soumis à votre approbation sur notre site lors de votre première visite. Vous pourrez gérer votre acceptation ou refus de cookies directement à partir des paramètres de votre navigateur. Le refus des cookies peut perturber votre navigation sur le site.</li>
</ul>
<p>&nbsp;</p>
<hr/>
<p>&nbsp;</p>
<p>Les données personnelles collectées sur notre site sont traitées par : CPFA, 6 Parvis des Chartrons  33075 Bordeaux cedex</p>
<p>Si vous avez des questions sur notre Charte de la protection de la vie privée ou que vous estimez que nous ne respectons pas les conditions de notre Charte de la protection de la vie privée publiée ou la législation en vigueur en matière de protection des données, veuillez contacter notre délégué́ à la protection des données à l’adresse email : info[AT]cpfa.com ou par courrier à CPFA, 6 Parvis des Chartrons  33075 Bordeaux cedex
</p>



        </div>
    </div>

  </div>

</template>

<script>


export default {

    name: 'mentions-legales',
    components: {

    },
    data() {
        return {
          //  svgContent: "",
        }
    },
    mounted: function () {
    },
    updated: function () {

    },
    computed: {

    },
    methods: {

    }
}


</script>


<style lang="scss">
    #app {
        #mentionsLegales {
            text-align: center;

            div.sectionAffichage {
                max-width: 640px;
                margin-left:auto;
                margin-right:auto;
                @media screen and (max-width: 840px) {
                    margin-left:20px;
                    margin-right:20px;
                }

                .conteneur {
                    text-align:justify;

                    h1, p, ul {
                        text-align: justify;
                    }

                    a {
                        color:#238b71;
                        font-weight:bold;
                    }

                    .bloc {
                        margin-top:3em;
                    }

                    .auteur {
                        font-weight: bold;
                    }

                    img {
                        max-height: 130px;
                        width:auto;
                        margin:1em auto 1em;

                        &.egal {
                        }
                        &.grandLogo {

                            @media screen and (max-width: 750px) {
                                max-height: 100px;
                                display:block;
                                margin:1em auto;
                            }
                        }

                        &.petitLogo {
                            margin-top:1em;;
                            max-height: 65px;
                        }

                        vertical-align: middle;
                    }
                }
            }
        }
    }

</style>
