<template>
    <div id="homeLogin">
                <img v-if="0" class="droite logoCPFA" id="logoCPFA" src="../assets/img/cpfa.png" />

                <div class="edition">

                    <p class="info" v-if="!autoInscrit">Les champs suivis d'une * sont obligatoires.</p>

                    <home-inscription v-if="autoInscrit" @retourInscription="onRetourInscription"></home-inscription>

                    <v-form v-if="!connexionUtilisateur && connexionEnCours">
                        <v-text-field  v-if="!motPasseOublie && !prospect && !autoInscrit"
                                v-model="userNom"
                                :error-messages="userNomErrors"
                                label="Votre login : *"
                                required
                                @input="$v.userNom.$touch()"
                                @blur="$v.userNom.$touch()"
                                ref="userNom"
                        ></v-text-field>
                        <v-text-field  v-if="!motPasseOublie && !prospect && !autoInscrit"
                                v-model="userPswd"
                                :error-messages="userPswdErrors"
                                label="Votre mot de passe : *"
                                :type="'password'"
                                required
                                @input="$v.userPswd.$touch()"
                                @blur="$v.userPswd.$touch()"
                        ></v-text-field>
                        <v-text-field  v-if="motPasseOublie || prospect"
                                v-model="eMail"
                                :error-messages="eMailErrors"
                                label="Votre adresse Mail : *"
                                @input="$v.eMail.$touch()"
                                @blur="$v.eMail.$touch()"
                                ref="userNom"
                        ></v-text-field>
                        <v-textarea  v-if="prospect"
                                v-model="leMessage"
                                label="Votre message : "
                        ></v-textarea>
                        <p class="info"  v-if="prospect">Si vous souhaitez être contacté par téléphone, indiquez votre numéro dans votre message.</p>

                        <div id="validationCGU" v-if="!motPasseOublie && !prospect &&!autoInscrit">
                            <span class="checkbox">
                                    <v-checkbox
                                        v-model="cguValidees"
                                        label=""
                                    ></v-checkbox>
                            </span>
                            <span class="libelle" >J'ai lu, compris et accepte les <router-link to="/conditionsGenerales" target="_blank">Conditions Générales d'Utilisation</router-link> de STERES ainsi que la <router-link to="/donneespersonnelles" target="_blank">Charte de Protection des Données Personnelles</router-link> associée<br/><span><i>(consentement obligatoire pour pouvoir se connecter)</i></span></span>
                        </div>



                        <span class="antiSpam"   v-if="motPasseOublie || prospect">Validation anti-spam</span>
                        <v-text-field v-if="(motPasseOublie || prospect) && this.ready"
                            :label="laVerif"
                            v-model="resultat"
                            required
                            :error-messages="resultatErrors"
                            ref="resultat"
                        ></v-text-field>

                    <div class="boutonsValidation">
                        <v-btn   v-if="!motPasseOublie && !prospect && !autoInscrit && cguValidees" class="a-btn prioritaire" type="button" @click="submit">Se connecter</v-btn>
                        <v-btn   v-if="!motPasseOublie && !prospect && !autoInscrit && !cguValidees" class="a-btn prioritaire" type="button" @click.stop="cguNonValideesPrevenir">Se connecter</v-btn>
                        <v-btn   v-if="motPasseOublie" class="a-btn prioritaire" type="button" @click.stop="nouveauMotDePasse">Nouveau mot de passe</v-btn>
                        <v-btn   v-if="prospect" class="a-btn prioritaire" type="button" @click.stop="demandeInformations">Demande d'informations</v-btn>
                    </div>

                    </v-form>

                    <div v-if="messageDemandeMP != ''" class= "messageInformation">{{messageDemandeMP}}</div>
                    <div v-if="messageDemandeContact != ''" class= "messageInformation">{{messageDemandeContact}}</div>
                    <div v-if="messageInscription != ''" class= "messageInformation">{{messageInscription}}</div>


                    <span class="checkbox" v-if="motPasseOublie">
                            <v-checkbox
                                v-model="motPasseOublie"
                                label=""
                            ></v-checkbox>
                    </span>
                    <span class="connexionPlus lien" @click.stop="motPasseOublie = !motPasseOublie;prospect=false;autoInscrit=false;eMail='';messageDemandeMP='';messageDemandeContact='';messageInscription='';">Identifiant ou Mot de passe oublié ?</span>
                    <br/>
                    <span class="checkbox" v-if="prospect">
                            <v-checkbox
                                v-model="prospect"
                                label=""
                            ></v-checkbox>
                    </span>
                    <span class="connexionPlus lien" @click.stop="prospect = !prospect;motPasseOublie=false;autoInscrit=false;eMail='';messageDemandeMP='';messageDemandeContact='';messageInscription='';">Besoin d'un renseignement ? Contactez-nous !</span>
                    <br/>
                    <span class="checkbox" v-if="autoInscrit">
                            <v-checkbox
                                v-model="autoInscrit"
                                label=""
                            ></v-checkbox>
                    </span>
                    <span class="connexionPlus lien" @click.stop="autoInscrit = !autoInscrit;motPasseOublie=false;prospect=false;eMail='';messageDemandeMP='';messageDemandeContact='';messageInscription='';">Pas d'identifiant ? Inscrivez-vous !</span>

                </div>

                <home-logos></home-logos>


            <v-dialog v-model="dialogError" v-if="errorLogin" >
                 <v-card>
                     <v-card-title class="headline">Connexion STERES</v-card-title>
                     <v-card-text>
                         <span v-if="errorLogin">{{errorLogin}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

    </div>
</template>


<script>

    import { addeoData } from "../js/api/datas.js"
    import { ApiUser, ApiTools } from "../js/api/index.js"

    import { validationMixin } from 'vuelidate'
    import { required, maxLength, email } from 'vuelidate/lib/validators'

    import HomeLogos from './HomeLogos.vue'
    import InscriptionForm from './form/InscriptionForm.vue'


    export default {
        mixins: [validationMixin],
        name: 'Login',
        components: {
            'home-logos': HomeLogos,
            'home-inscription': InscriptionForm,
        },
        validations: {
            userNom: { required, maxLength: maxLength(50)},
            userPswd: { required, maxLength: maxLength(20) },
            eMail: {  required, email },
        },
        props: {
        /**
         * Données à injecter
         */
            connexionUtilisateur: Boolean

        },

        data() {
            return {
                connexionEnCours:true,
                userNom: "",
                userPswd: "",
                dialogError:false,
                errorLogin:false,
                motPasseOublie:false,
                prospect:false,
                autoInscrit:false,
                eMail:"",
                eMailErrors:"",
                messageDemandeMP:"",
                messageDemandeContact:"",
                messageInscription:'',
                leMessage:"",
                cguValidees: this.$store.getters.cguValidees,
                    // antispam
                resultat:"",
                resultatErrors: '',
                ready:false,
                antiSpam1:89,
                antiSpam2:72,
                myTimeout:null,
            }
        },


        watch: {
            cguValidees(newValue) {
                this.$store.commit('setCguValidees', this.cguValidees); // on commence par stocker l'état
                if(newValue) {
                    this.cguNonValidees(false);
                }
            }
        },

        created: function () {
            this.myTimeout = setTimeout(this.preparerAntispam, 1500);
        },

        mounted: function () {
        },

        computed: {
            userNomErrors () {
                const errors = []
                if (!this.$v.userNom.$dirty) return errors
                !this.$v.userNom.required && errors.push('Indiquez votre identifiant')
                return errors
            },
            userPswdErrors () {
                const errors = []
                if (!this.$v.userPswd.$dirty) return errors
                !this.$v.userPswd.maxLength && errors.push('Le mot de passe nécessite au moins 6 caractères')
                !this.$v.userPswd.required && errors.push('Saisissez un mot de passe')
                return errors
            },
            laVerif() {
                return("Donnez le résultat de "+this.antiSpam1+" + "+ this.antiSpam2+" = *");
            }

        },
        methods: {
            getRandomInt(max) {
              return Math.floor(Math.random() * Math.floor(max))+1;
            },
            preparerAntispam() {
                    if (this.myTimeout) {
                        clearTimeout(this.myTimeout);
                    }
                this.antiSpam1= this.getRandomInt(50);
                this.antiSpam2= this.getRandomInt(30);
                this.ready = true;
            },
            logout() {
                this.$store.commit('setProprieteChargeeId', 0); // on rechargera les UFs de la propriété
                this.connexionEnCours = true;
                this.$emit("transfertConnexion", false);
            },
            submit () {
                this.resultatErrors = '';
                this.$v.$touch();
                if (this.$v.$invalid && (this.$v.userPswd.error || this.$v.userNom.error)) {
                    console.log("submit pas OK")
                }
                else {
                    addeoData.validerLogin(this.userNom, this.userPswd)
                    .then(response => {
                        if (response && response.retour == true) {
                            console.log("submit OK")
                            var chargerUserDatas = true;
                            this.$parent.$parent.renseignerUtilisateur(response.data, chargerUserDatas)
                            .then(response => {
                                // on maintien également à jour la variable de synchro pour savoir si on devra mettre à jour les données de l'utilisateur vers le serveur central
                                ApiUser.majSynchro(true)
                                // on ferme !
                                this.connexionEnCours = false;
                                // on transmet la connexion
                                this.$emit("transfertConnexion", true);
                            })
                        }
                        else {
                            this.dialogError = true;
                            this.errorLogin = "Connexion impossible, merci de vérifier votre saisie";
                            console.log("submit non valide")
                        }
                    })
                }
            },


            testEMailErrors () {
                const errors = [];
                //if (!this.$v.eMail.$dirty) return errors
                if (!this.$v.eMail.email || this.$v.eMail.$invalid) {
                    this.eMailErrors = "Vous devez saisir une adresse mail valide";
                    return false;
                }
                else {
                    this.eMailErrors = "";
                }
                return true;
            },
            cguNonValideesPrevenir() {
                this.dialogError = true;
                this.errorLogin = "Vous devez accepter les Conditions Générales d'Utilisation";
                this.cguNonValidees(true);
            },
            cguNonValidees(prevenir) {
                var eltCheck = document.getElementById("validationCGU");
                if (prevenir) {
                    ApiTools.toggleClassElt(eltCheck, 'addClass', "insister");
                }
                else  {
                    ApiTools.toggleClassElt(eltCheck, 'removeClass', "insister");
                }
            },
            nouveauMotDePasse() {
                this.resultatErrors = '';
                if (this.resultatIsFalse() || !this.testEMailErrors()) {
                    return;
                }
                addeoData.validerDemandeMP(this.eMail)
                    .then(response => {
                    if (response && response.ok == true) {
                        this.messageDemandeMP = "Votre demande de réinitialisation de mot de passe a bien été traitée. Vous allez recevoir un mail de confirmation dans les minutes qui viennent sur l'adresse eMail que vous avez indiquée. En cas de non réception, vérifiez votre dossier spam.";
                    }
                    else {
                        this.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.errors) {
                            complementErreur = response.errors;
                        }
                        this.errorLogin = "Création d'un nouveau mot de passe impossible : " + complementErreur;
                        console.log("testEMailErrors non valide")
                    }
                })
            },

            demandeInformations() {
                this.resultatErrors = '';
                if (this.resultatIsFalse() || !this.testEMailErrors()) {
                    return;
                }
                addeoData.validerDemandeContact(this.eMail, this.leMessage)
                    .then(response => {
                    if (response && response.ok == true) {
                        this.messageDemandeContact = "Votre demande de contact a bien été traitée. Vous allez recevoir une copie du message transmis à l'équipe STERES sur l'adresse eMail que vous avez indiquée. L'équipe vous contactera alors dans les plus brefs délais. En cas de non réception, vérifiez votre dossier spam.";
                    }
                    else {
                        this.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.errors) {
                            complementErreur = response.errors;
                        }
                        this.errorLogin = "Envoi du message de contact impossible : " + complementErreur;
                        console.log("demandeInformations non valide")
                    }
                })
            },

            fermerDialogError() {
                this.dialogError = false;

                if (this.cguValidees && this.errorLogin) {
                    this.$nextTick(() => {
                        this.$refs.userNom.focus();
                    })
                }

            },
            resultatIsFalse () {
                if (!this.motPasseOublie && !this.prospect) {
                    this.resultatErrors = "";
                    return false;
                }
                if (this.resultat != (this.antiSpam1 + this.antiSpam2))  {
                    this.resultatErrors = "Vous devez indiquer le résultat du calcul";
                    this.$refs.resultat.focus();
                    return true;
                }
                else {
                    this.resultatErrors = "";
                }
                return false;
            },
            onRetourInscription(messageInscription) {
                this.messageInscription = messageInscription;
                this.autoInscrit = false;
            }
        }
    }
</script>

<style lang="scss">
    #app {

        img {
            max-width: 90%;
        }

        .home {
            .conteneur {
                margin:0 auto;
            }
            div.wrapperIllustration {
                div.edition {
                    min-height: 0;
                }
            }

            .connexionPlus {
                margin-right:1em;
                &.lien {
                    color: #238b71;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            span.checkbox {
                div {
                    display: inline;
                }
                .v-input {
                    background-color: transparent;
                }
                .v-icon {
                    color: #394f4e;
                }
            }
        }
        #homeLogin {
            .messageInformation {
                padding:1em;
                margin:1em 0;
                position:relative;
                font-size:0.9em;
                text-align: left;
                background-color:white;
                color:#238b71;
            }
        }
        span.antiSpam {
            margin-top:1em;
            display: block;
        }
        #validationCGU {

            span.checkbox {
                vertical-align: top;
            }
            span.libelle {
                display:inline-block;
                max-width: 316px;
                font-size: 0.9em;
                a {
                    color: #238b71;
                    font-weight: bold;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                i {
                    font-size: 0.9em;
                }
            }
            &.insister {
                span.libelle {
                    span {
                        color:orangered;
                    }
                }
            }
            margin:1em 0;
        }
    }



</style>
