import {openDB} from 'idb';
import axios from 'axios'

import { SteresDB } from '../db/SteresDB.js'
import { ApiTools } from "../api/index.js"


    /*
axios({
  url: 'http://localhost:5000/static/example.pdf',
  method: 'GET',
  responseType: 'blob', // important
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'file.pdf');
  document.body.appendChild(link);
  link.click();
});
*/
function navigateurEnLigne() {
    return(navigator.onLine);
}

async function postDataApiDownload(paramApi, paramPost, nomFichierCSV) {
    if (navigateurEnLigne()) {
        ApiTools.appAttendre(true); // on attend

        // pour le croos domain
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // et le post
        return axios.post(paramApi, {
            param:paramPost
            })
            .then(response => {
                ApiTools.appAttendre(false); // on n'attend plus

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                nomFichierCSV += ".csv";
                link.setAttribute('download', nomFichierCSV);
                document.body.appendChild(link);
                link.click();

            /*
                if ((typeof response !== "undefined") && (typeof response.data !== "undefined") && (typeof response.data.retourPost !== "undefined")) {
                    return(response.data.retourPost);
                }
                else {
                    return(null)
                    }
            */
                })
            .catch(e => {
                ApiTools.appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        return(null);
    }
}

export const addeoData = {

        navigateurEnLigne() {
            return(navigateurEnLigne());
        },


        async exporterTableau(tableau, nomFichierCSV) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/api/exportCSV/export";
                var jsonTableau = JSON.stringify(tableau);
                //jsonTableau = jsonTableau.replace('.', ',');

                return(postDataApiDownload(paramApi, jsonTableau, nomFichierCSV));
            }
            else {
                return(null)
            }
        },

        async lireCommunes() {
            console.log("lireCommunes");
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/datasCommunes/index.json";
                return(getDataApi(paramApi));
            }
            else {
                return(null)
            }
        },

        async lireDatasConstantes() {
            if (navigator.onLine) {
                console.log("lireDatasConstantes1");
                var paramApi = "https://"+adminNameDomaine()+"/const/index.json";
                return(getDataApi(paramApi));
            }
            else {
                console.log("lireDatasConstantes2");
                return(null)
            }
        },
/*
        async lireDatasUser(id) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/utilisateur/"+id+".json";
                var autorisation = true;
                return(postDataApi(paramApi, autorisation)); // on passe l'autorisation en POST
            }
            else {
                return(null)
            }
        },
*/
        async deletePropriete(id) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },

        async lireUtilisateursData() {
            console.log("lireUtilisateursData");
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUtilisateurs/index.json";
                return(getDataApi(paramApi));
            }
            else {
                return(null)
            }
        },

        async validerUtilisateur(nom, id, motDePasse) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUtilisateurs/autoriser/"+nom+"/"+id+".json";
                return(postDataApi(paramApi, motDePasse));
            }
            else {
                return(null)
            }
        },
        async validerLogin(login, motDePasse) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUtilisateurs/autoriserLogin/"+login+".json";
                return(postDataApi(paramApi, motDePasse));
            }
            else {
                return(false)
            }
        },
        async validerDemandeInscription(inscription) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUtilisateurs/inscription.json";
                var jsonUtilisateur = JSON.stringify(inscription);
                return(postDataApi(paramApi, jsonUtilisateur));
            }
            else {
                return(null)
            }
        },
        async validerDemandeMP(mail) {
            if (navigator.onLine) {
                var param = {};
                param.paramPost = {};
                param.paramPost.mail = mail;
                param.paramPost.action = "PSWD";
                var paramPost = {};
                paramPost.mail = mail;
                paramPost.action = "PSWD";
                //var paramApi = "https://"+adminNameDomaine()+"/api/mails/nouveauMdp/"+mail+".json";
                var paramApi = "https://"+adminNameDomaine()+"/api/mails/new-password";
                //return(postDataApi(paramApi, param));
                return(postDataApiErreurs(paramApi, paramPost, true));
            }
            else {
                return(false)
            }
        },
        async validerDemandeContact(mail, leMessage) {
            if (navigator.onLine) {
                var paramPost = {};
                paramPost.mail = mail;
                paramPost.message = leMessage;
                paramPost.action = "CONTACT";
                var paramApi = "https://"+adminNameDomaine()+"/api/mails/new-password";
                return(postDataApiErreurs(paramApi, paramPost, true));
            }
            else {
                return(false)
            }
        },

        async ecrireDatasUtilisateur(utilisateur) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUtilisateurs/ecrire.json";
                var jsonUtilisateur = JSON.stringify(utilisateur);
                return(postDataApi(paramApi, jsonUtilisateur));
            }
            else {
                return(null)
            }
        },

        async lireDatasUtilisateur(idUtilisateur) {
            if (navigator.onLine) {
                //var paramApi = "https://"+adminNameDomaine()+"/userProprietes/utilisateur/"+idUtilisateur+".json";
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/utilisateur2/"+idUtilisateur+".json";
                var autorisation = true;
                return(postDataApi(paramApi, autorisation)); // on passe l'autorisation en POST
            }
            else {
                return(null)
            }
        },
        async lireDatasPropriete(page, limite, idPropriete) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/utilisateur2propriete/"+page+"/"+limite+"/"+idPropriete+".json";
                var autorisation = true;
                return(postDataApi(paramApi, autorisation)); // on passe l'autorisation en POST
            }
            else {
                return(null)
            }
        },

        async ecrireDatasPropriete(propriete) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/ecrire.json";
                var jsonPropriete = JSON.stringify(propriete);
                return(postDataApi(paramApi, jsonPropriete));
            }
            else {
                return(null)
            }
        },

        async autoriserSynchronisationDatasPropriete(propriete) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/autoriserSynchronisation.json";
                var jsonPropriete = JSON.stringify(propriete);
                return(postDataApi(paramApi, jsonPropriete));
            }
            else {
                return(null)
            }
        },

        async synchroniserDatasPropriete(propriete, proprieteUFs) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userProprietes/synchroniser.json";
                // on envoyer dans un unique JSON : les UF, les peuplements, les mesures et les éclaircies des peuplement
                // TODO
                var jsonPropriete = JSON.stringify(propriete);
                var jsonUFs = JSON.stringify(proprieteUFs);
                var jsonGlobal = '{"propriete":'+jsonPropriete+',"UFs":'+jsonUFs+"}";
                return(postDataApi(paramApi, jsonGlobal));
            }
            else {
                return(null)
            }
        },

        async ecrireDatasUF(uf) {
            if (this.testerModeForet(uf)) {
                return(this.retourModeForet(uf));
            }
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUf/ecrire.json";
                var jsonUF = JSON.stringify(uf);
                return(postDataApi(paramApi, jsonUF));
            }
            else {
                return(null)
            }
        },
        async deleteUF(id) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUf/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },

        async ecrireDatasPeuplement(peuplement) {
            if (this.testerModeForet(peuplement)) {
                return(this.retourModeForet(peuplement));
            }
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplement/ecrire.json";
                var jsonPeuplement = JSON.stringify(peuplement);
                return(postDataApi(paramApi, jsonPeuplement));
            }
            else {
                return(null)
            }
        },

        async deletePeuplement(id) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplement/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },

        async ecrireDatasMesure(mesure) {
            if (this.testerModeForet(mesure)) {
                return(this.retourModeForet(mesure));
            }
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementmesure/ecrire.json";
                var jsonMesure = JSON.stringify(mesure);
                return(postDataApi(paramApi, jsonMesure));
            }
            else {
                return(null)
            }
        },

        async deleteMesure(mesure) {
            var id = mesure.id;
            if (this.testerModeForet(mesure)) { // inutile : on ne peut pas détruire en mode forêt
                return(this.retourModeForet(mesure));
            }
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementmesure/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },

        async ecrireDatasEclaircie(eclaircie) {
            if (this.testerModeForet(eclaircie)) {
                return(this.retourModeForet(eclaircie));
            }
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementeclaircie/ecrire.json";
                var jsonEclaircie = JSON.stringify(eclaircie);
                return(postDataApi(paramApi, jsonEclaircie));
            }
            else {
                return(null)
            }
        },
        async ecrireDatasItEclaircie(eclaircie) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementIteclaircie/ecrire.json";
                var jsonEclaircie = JSON.stringify(eclaircie);
                return(postDataApi(paramApi, jsonEclaircie));
            }
            else {
                return(null)
            }
        },

        async deleteEclaircie(eclaircie) {
            var id = eclaircie.id;
            if (this.testerModeForet(eclaircie)) { // inutile : on ne peut pas détruire en mode forêt
                return(this.retourModeForet(eclaircie));
            }
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementeclaircie/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },

        async deleteItEclaircie(id) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementIteclaircie/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },


        async ecrireDatasIt(it) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementIt/ecrire.json";
                var jsonIt = JSON.stringify(it);
                return(postDataApi(paramApi, jsonIt));
            }
            else {
                return(null)
            }
        },

        async deleteIt(id) {
            if (navigator.onLine) {
                var paramApi = "https://"+adminNameDomaine()+"/userUfpeuplementIt/delete.json";
                return(postDataApi(paramApi, '{"id":'+id+'}'));
            }
            else {
                return(null)
            }
        },

        testerModeForet(item) {
            if ((typeof item.modeForet !== "undefined") && item.modeForet)
                {
                    return(true);
                }
            return(false);
        },

        async retourModeForet(item) {
            var data = item;
            var retour = {};
            retour.data = item;
            retour.success = false;
            retour.erreur = false;
            return(retour);
        }

    }


async function postDataApiErreurs(paramApi, paramPost, gestionErreurs) {
    if (navigator.onLine) {
        ApiTools.appAttendre(true); // on attend

        // pour le cross domain
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // et le post
        return axios.post(paramApi, {
            param:paramPost
            })
            .then(response => {
                ApiTools.appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    if (response.data.ok) {
                        return(response.data);
                    }
                    else {
                        if (gestionErreurs) {
                            return(response.data);
                        }
                    }
                }
                return(null)
                })
            .catch(e => {
                ApiTools.appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        ApiTools.appAttendre(false); // on n'attend plus
        return(null);
    }
}

async function postDataApi(paramApi, paramPost) {
    if (navigator.onLine) {
        ApiTools.appAttendre(true); // on attend
        /*
        var data = JSON.stringify({"param":paramPost});
        return axios.post(paramApi, {data}, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
            })
            */
        // pour le croos domain
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // et le post
        return axios.post(paramApi, {
            param:paramPost
            })
            .then(response => {
                ApiTools.appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined") && (typeof response.data.retourPost !== "undefined")) {
                    return(response.data.retourPost);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                ApiTools.appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        ApiTools.appAttendre(false); // on n'attend plus
        return(null);
    }
}

async function getDataApi(paramApi) {
    if (navigator.onLine) {
        return axios.get(paramApi)
            .then(response => {
                if ((typeof response !== "undefined") && (typeof response.data !== "undefined") && !response.data.utiliserBdAddeo) {
                    return(response);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                return(null);
                });
        }
    else {
        return(lireTableBD(nomTable));
    }
}

async function lireDataBD(nomTable) {
    return(lireTableBD(nomTable));
}

async function lireData(paramApi, nomTable) {
    if (navigator.onLine) {
        return axios.get(paramApi)
            .then(response => {
                if ((typeof response !== "undefined") && (typeof response.data !== "undefined") && !response.data.utiliserBdAddeo) {
                    ecrireTableBD(nomTable, response.data);
                    return(response);
                }
                else {
                    return(lireTableBD(nomTable))
                    }
                })
            .catch(e => {
                //erreurBD("lirePoeme - pb indexedDb : ");
                return(lireTableBD(nomTable));
                });
        }
    else {
        return(lireTableBD(nomTable));
    }
}

function erreurBD(libelle) {
    var response = {};
    response.okAddeo = false;
    response.erreur = libelle;
    console.log("libelle", libelle)
    return(response);
}

async function lireTableBD(nomTable) {
     return SteresDB.readAllFromTable(nomTable)
}

async function ecrireTableBD(nomTable, data) {
    var item;
     if (typeof data[0] !== "undefined") {
         item = data[0];
     }
    else {
        item = data;
    }

    console.log("ecrireTableBD");
    return(SteresDB.saveRowInTable(item, nomTable) );
}


function adminNameDomaine() {

    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return('admin.steres.addeo.com');
    }
    if ((leDomaine === "steres.fr") || (leDomaine === "www.steres.fr"))  {
        return('admin.steres.fr');
    }
    else {
        if (leDomaine !== "steres.addeo.com")  {
            return('admin.cpfa.addeo.com');
        }
        else {
            return('admin.steres.addeo.com') // la bonne URL
        }
    }
}

