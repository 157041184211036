import lf from 'lovefield'

export function datas_communes (schemaBuilder) {
  schemaBuilder.createTable('datas_communes')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('dep', lf.Type.INTEGER)
      .addColumn('codeComm', lf.Type.STRING)
      .addColumn('codePostal', lf.Type.STRING)
      .addColumn('libelle', lf.Type.STRING)
      .addPrimaryKey(['id'], false);
}
