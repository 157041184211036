import lf from 'lovefield'

export function const_depenses (schemaBuilder) {
  schemaBuilder.createTable('const_depenses')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('code', lf.Type.STRING)
      .addColumn('libelle', lf.Type.STRING)
      .addColumn('annuelle', lf.Type.INTEGER)
      .addColumn('bonnePratiqueOuValide', lf.Type.INTEGER)
      .addColumn('anneeDebut', lf.Type.INTEGER)
      .addColumn('coutHectare', lf.Type.DOUBLE)
      .addPrimaryKey(['id'], false);
}
