var globCourbes;

//*********************************
//function SVGCourbes() {
class SVGCourbes {
//export const SVGCourbes() {

  constructor($q) { // Injection des dépendances SteresDB et $q
    globCourbes = this;

        // paramétrage de l'epure

    this.l = 1200;
    this.lUtile = 1000;
    this.hUtile = 530;
    this.h = 695; // 500 + 150 + 45
    this.hZero = 0;
    this.margeLegendes = 5;
    this.hauteurLegendes = 15;
    this.margeAffichageY = 10;
    this.lEtiquette = 70;
    this.hEtiquette = 30;
    this.lLegende = 60;
    this.hLegende = 20;

        // mécanisme de l'animation
    this.x1 = 0;
    this.y1 = 0;
    this.x2 = this.l-1;
    this.y2 = this.h;
    this.y = 0;


    this.svgns = "http://www.w3.org/2000/svg";
    this.svgnl = "http://www.w3.org/2000/xlink";

  }

        //********************************* balise SVG directement dans le code HTML
	enteteSVGcalque(id) {

        var l = this.l;
        var h = this.h+this.margeAffichageY;
        var x1 = 0;
        var y1 = 0;

        // NB ne surtout pas mettre de largeur ou de hauteur ! responsif !!!
		var retour = '<svg version="1.1" id="'+id+'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="'+x1+' '+y1+' '+l+' '+h+'" xml:space="preserve">';
		//var retour = '<svg version="1.1" id="'+id+'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="'+l+'px" height="'+h+'px"  viewBox="'+x1+' '+y1+' '+l+' '+h+'" xml:space="preserve">';

        this.calqueEnCours.id = id;
        this.calqueEnCours.calque += retour;
        return(retour);
	}

        //*********************************
	cloreSVGcalque() {
        var retour = '</svg>';
        this.calqueEnCours.calque += retour;
        return(retour);
    }

        //*********************************
	styleCSScalque(leStyle) {
        var retour = '<style type="text/css">'+leStyle+'</style>';
        this.calqueEnCours.calque += retour;
        return(retour);
    }


    //*********************************
	epureCSS() {
		//var retour = '\n.alea .polygone {fill:url(#aleaBande);}'
		//var retour = '\n.alea .polygone {fill:#f6f6f6;opacity:0.7}'
		var retour = 'path.hauteur {fill:none;stroke:#3498DB;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1;}'
            +'\npath.multi,path.comp {fill:none;stroke:#3498DB;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:2;}'
            +'\npath.B {stroke:#118900;}'
            +'\npath.classCourbe0 {stroke:#3498DB;}'
            +'\npath.classCourbe1 {stroke:#804000;}'
            +'\npath.comp.classCourbe0 {stroke:#000091;}'
            +'\npath.comp.classCourbe1 {stroke:#8000FF;}'
            +'\npath.classCourbe2 {stroke:#C109A0;}'
            +'\npath.classCourbe3 {stroke:#118900;}'
            +'\npath.classCourbe4 {stroke:#173C00;}'
            +'\npath.classCourbe5 {stroke:#118900;}'
            +'\npath.classCourbe6 {stroke:#118900;}'
            +'\npath.classCourbe7 {stroke:#118900;}'
            +'\npath.estimation {stroke-dasharray:4;}'
            +'\npath.inhibe {stroke:transparent;}'
            +'\n.axes line {fill:none;stroke:#111111;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1;}'
            +'\.axes line.classCourbe0 {stroke:#3498DB;}'
            +'\.axes line.classCourbe1 {stroke:#804000;}'
            +'\.axes line.classCourbe2 {stroke:#C109A0;}'
            +'\.axes line.classCourbe3 {stroke:#118900;}'
            +'\.axes line.classCourbe4 {stroke:#173C00;}'
            +'\n.axes.interactifs line {stroke:transparent;stroke-width:1;stroke-dasharray:4;pointer-events: none;}'
            +'\n.axes.interactifs.active line {stroke-width:1;}'
            +'\n.axes.interactifs.active line.axeA {stroke:#3498DB;}'
            +'\n.axes.interactifs.active line.axeB {stroke:#118900;}'
            +'\n.axes.interactifs.active line.classCourbe0 {stroke:#3498DB;}'
            +'\n.axes.interactifs.active line.classCourbe3 {stroke:#118900;}'
            +'\n.axes.interactifs.active line.classCourbe2 {stroke:#C109A0;}'
            +'\n.axes.interactifs.active line.classCourbe1 {stroke:#804000;}'
            +'\n.axes.interactifs.active line.axeVertical {stroke:#777777;}'
            +'\n line.axeAnneeCourante {stroke:#111111;stroke-dasharray:4;}'
            +'\n.libelle.axeAnneeCourante {text-anchor: middle;dominant-baseline: initial;fill:#111111;}'
            +'\n line.axeAnneeMesure {stroke:#aaaaaa;stroke-dasharray:4;}'
            +'\n line.axeAnneeEclaircie {stroke:#aaaaaa;}'
            +'\n line.axeAnneeEclaircie.eclaircie1 {stroke:#000091;}'
            +'\n line.axeAnneeEclaircie.eclaircie2 {stroke:#8000FF;}'
            +'\n line.axeAnneeEclaircie.eclaircie1eclaircie2 {stroke-dasharray: 20 20;}'
            +'\n line.axeAnneeEclaircie.eclaircie2eclaircie3 {}'
            +'\n line.axeAnneeEclaircie.IT {stroke-dasharray: 2 2;}'
            +'\n line.axeAnneeCoupeRase {stroke:#B70000;stroke-width:3;}'
            +'\n line.axeAnneeOptimumSteres {stroke:#223938;stroke-width:1;}'
            +'\n line.axeAnneeCoupeRaseBNA {stroke:#3498DB;stroke-width:1;}'
            +'\n line.axeAnneeCoupeRaseACE {stroke:#804000;stroke-width:1;}'
            +'\n line.axeAnneeCoupeRaseTIR {stroke:#C109A0;stroke-width:1;}'
            +'\n.libelle.axeAnneeMesure {text-anchor: middle;dominant-baseline: central;fill:#aaaaaa}'
            +'\n.libelle.axeAnneeEclaircie {text-anchor: middle;dominant-baseline: central;fill:#aaaaaa}'
            +'\n.libelle.axeAnneeEclaircie.eclaircie1 {fill:#000091}'
            +'\n.libelle.axeAnneeEclaircie.eclaircie2 {fill:#8000FF}'
            +'\n.libelle.axeAnneeEclaircie.eclaircie1eclaircie2 {fill:#A60000}'
            +'\n.libelle.axeAnneeCoupeRase {text-anchor: middle;dominant-baseline: central;fill:#B70000}'
            +'\n.libelle.axeAnneeOptimumSteres {text-anchor: middle;dominant-baseline: central;fill:#223938}'
            +'\n.libelle.axeAnneeCoupeRaseBNA {text-anchor: middle;dominant-baseline: central;fill:#3498DB}'
            +'\n.libelle.axeAnneeCoupeRaseACE {text-anchor: middle;dominant-baseline: central;fill:#804000}'
            +'\n.libelle.axeAnneeCoupeRaseTIR {text-anchor: middle;dominant-baseline: central;fill:#C109A0}'



            +'\nrect.fond {fill:#ffffff;stroke:transparent;opacity:0.8;}'
            +'\nrect.transparent {fill:transparent;stroke:transparent;stroke-width:0;}'
            +'\nrect.transparent.dejaPasse {fill:#dddddd;opacity:0.3;visibility: visible !important;}'
            +'\nrect.transparent.apresCoupeRase {fill:#cccccc;opacity:0.5;visibility: visible !important;}'
+'\n.axes.interactifs rect.opaque {fill:#eeeeee;stroke:transparent;opacity:0;}'
+'\n.axes.interactifs.active rect.opaque {opacity:0.7;}'
            +'\n.axes.interactifs text.libelle {fill:transparent;}'
            +'\n.axes.interactifs text.libelle.legendeX {dominant-baseline: central;text-anchor: middle;font-size: 0.8em;}'
            +'\n.axes.interactifs text.libelle.legendeY {dominant-baseline: central;text-anchor: middle;font-size: 0.8em;}'
            +'\n.axes.interactifs.active text.libelle {fill:#8f8f8f;}'
            +'\n.axes.interactifs.active text.libelle.legendeY.classCourbe0 {fill:#3498DB;}'
            +'\n.axes.interactifs.active text.libelle.legendeY.classCourbe3 {fill:#118900;}'
            +'\n.axes.interactifs.active text.libelle.legendeY.classCourbe2 {fill:#C109A0;}'
            +'\n.axes.interactifs.active text.libelle.legendeY.classCourbe1 {fill:#804000;}'
            +'\n.libelle {font-family:"robotoregular"; font-size:1em; text-anchor: middle; dominant-baseline: central; fill:#111111;}'
            +'\n.libelle.axeX {text-anchor: start;dominant-baseline: hanging;}'
            +'\n.libelle.axeY {text-anchor: middle;dominant-baseline: initial;}'
            +'\n.libelle.axeYA {fill:#3498DB;}'
            +'\n.libelle.axeYB {fill:#118900;}'
            +'\n.libelle.classCourbe0 {fill:#3498DB;}'
            +'\n.libelle.classCourbe3 {fill:#118900;}'
            +'\n.libelle.classCourbe2 {fill:#C109A0;}'
            +'\n.libelle.classCourbe1 {fill:#804000;}'
            +'\n.libelle.titre {font-size:1.4em;dominant-baseline:hanging;}'
            +'\n.libelle.titre .titre1 {fill:#000091;}'
            +'\n.libelle.titre .titre2 {fill:#8000FF;}'
            +'\n.libelle.intervalleX {text-anchor: middle;dominant-baseline: hanging;}'
            +'\n.libelle.intervalleY {dominant-baseline: central;font-size: 0.8em;}'
            +'\n.libelle.intervalleYgauche {text-anchor: end;}'
            +'\n.libelle.intervalleYdroite {text-anchor: start;}'
            +'\n.libelle.optimumDecalage1 {dominant-baseline: initial;}'
            +'\n.libelle.optimumDecalage2 {dominant-baseline: central;}'
            +'\n.libelle.optimumDecalage3 {dominant-baseline: hanging;}'
        ;
        retour += '\n#carboneVisualisation path.hauteur {fill:none;stroke:#804000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1;}'
            +'\n#carboneVisualisation path.multi,path.comp {fill:none;stroke:#804000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:2;}'
            +'\n#carboneVisualisation path.B {stroke:#118900;}'
            +'\n#carboneVisualisation path.classCourbe0 {stroke:#804000;}'
            +'\n#carboneVisualisation path.classCourbe1 {stroke:#118900;}'
            +'\n#carboneVisualisation path.comp.classCourbe0 {stroke:#000091;}'
            +'\n#carboneVisualisation path.comp.classCourbe1 {stroke:#8000FF;}'
            +'\n#carboneVisualisation path.classCourbe2 {stroke:#C109A0; stroke-dasharray:1 3;}'
            +'\n#carboneVisualisation path.classCourbe3 {stroke:#118900;}'
            +'\n#carboneVisualisation path.classCourbe4 {stroke:#173C00;}'
            +'\n#carboneVisualisation path.classCourbe5 {stroke:#118900;}'
            +'\n#carboneVisualisation path.classCourbe6 {stroke:#118900;}'
            +'\n#carboneVisualisation path.classCourbe7 {stroke:#118900;}'
            +'\n#carboneVisualisation path.estimation {stroke-dasharray:4;}'
            +'\n#carboneVisualisation path.inhibe {stroke:transparent;}'
            +'\n#carboneVisualisation .axes line {fill:none;stroke:#111111;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1;}'
            +'\n#carboneVisualisation .axes line.classCourbe0 {stroke:#804000;}'
            +'\n#carboneVisualisation .axes line.classCourbe1 {stroke:#118900;}'
            +'\n#carboneVisualisation .axes line.classCourbe2 {stroke:#C109A0;}'
            +'\n#carboneVisualisation .axes line.classCourbe3 {stroke:#118900;}'
            +'\n#carboneVisualisation .axes line.classCourbe4 {stroke:#173C00;}'
            +'\n#carboneVisualisation .axes.interactifs line {stroke:transparent;stroke-width:1;stroke-dasharray:4;pointer-events: none;}'
            +'\n#carboneVisualisation .axes.interactifs.active line {stroke-width:1;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.axeA {stroke:#804000;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.axeB {stroke:#118900;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.classCourbe0 {stroke:#804000;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.classCourbe3 {stroke:#118900;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.classCourbe2 {stroke:#C109A0;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.classCourbe1 {stroke:#118900;}'
            +'\n#carboneVisualisation .axes.interactifs.active line.axeVertical {stroke:#777777;}'
            +'\n#carboneVisualisation  line.axeAnneeCourante {stroke:#111111;stroke-dasharray:4;}'
            +'\n#carboneVisualisation .libelle.axeAnneeCourante {text-anchor: middle;dominant-baseline: initial;fill:#111111;}'
            +'\n#carboneVisualisation  line.axeAnneeMesure {stroke:#aaaaaa;stroke-dasharray:4;}'
            +'\n#carboneVisualisation  line.axeAnneeEclaircie {stroke:#aaaaaa;}'
            +'\n#carboneVisualisation  line.axeAnneeEclaircie.eclaircie1 {stroke:#000091;}'
            +'\n#carboneVisualisation  line.axeAnneeEclaircie.eclaircie2 {stroke:#8000FF;}'
            +'\n#carboneVisualisation  line.axeAnneeEclaircie.eclaircie1eclaircie2 {stroke-dasharray: 20 20;}'
            +'\n#carboneVisualisation  line.axeAnneeEclaircie.eclaircie2eclaircie3 {}'
            +'\n#carboneVisualisation  line.axeAnneeEclaircie.IT {stroke-dasharray: 2 2;}'
            +'\n#carboneVisualisation  line.axeAnneeCoupeRase {stroke:#B70000;stroke-width:3;}'
            +'\n#carboneVisualisation  line.axeAnneeOptimumSteres {stroke:#223938;stroke-width:1;}'
            +'\n#carboneVisualisation  line.axeAnneeCoupeRaseBNA {stroke:#804000;stroke-width:1;}'
            +'\n#carboneVisualisation  line.axeAnneeCoupeRaseACE {stroke:#118900;stroke-width:1;}'
            +'\n#carboneVisualisation  line.axeAnneeCoupeRaseTIR {stroke:#C109A0;stroke-width:1;}'
            +'\n#carboneVisualisation .libelle.axeAnneeMesure {text-anchor: middle;dominant-baseline: central;fill:#aaaaaa}'
            +'\n#carboneVisualisation .libelle.axeAnneeEclaircie {text-anchor: middle;dominant-baseline: central;fill:#aaaaaa}'
            +'\n#carboneVisualisation .libelle.axeAnneeEclaircie.eclaircie1 {fill:#000091}'
            +'\n#carboneVisualisation .libelle.axeAnneeEclaircie.eclaircie2 {fill:#8000FF}'
            +'\n#carboneVisualisation .libelle.axeAnneeEclaircie.eclaircie1eclaircie2 {fill:#A60000}'
            +'\n#carboneVisualisation .libelle.axeAnneeCoupeRase {text-anchor: middle;dominant-baseline: central;fill:#B70000}'
            +'\n#carboneVisualisation .libelle.axeAnneeOptimumSteres {text-anchor: middle;dominant-baseline: central;fill:#223938}'
            +'\n#carboneVisualisation .libelle.axeAnneeCoupeRaseBNA {text-anchor: middle;dominant-baseline: central;fill:#804000}'
            +'\n#carboneVisualisation .libelle.axeAnneeCoupeRaseACE {text-anchor: middle;dominant-baseline: central;fill:#118900}'
            +'\n#carboneVisualisation .libelle.axeAnneeCoupeRaseTIR {text-anchor: middle;dominant-baseline: central;fill:#C109A0}'



            +'\n#carboneVisualisation rect.fond {fill:#ffffff;stroke:transparent;opacity:0.8;}'
            +'\n#carboneVisualisation rect.transparent {fill:transparent;stroke:transparent;stroke-width:0;}'
            +'\n#carboneVisualisation rect.transparent.dejaPasse {fill:#dddddd;opacity:0.3;visibility: visible !important;}'
            +'\n#carboneVisualisation rect.transparent.apresCoupeRase {fill:#cccccc;opacity:0.5;visibility: visible !important;}'
+'\n#carboneVisualisation .axes.interactifs rect.opaque {fill:#eeeeee;stroke:transparent;opacity:0;}'
+'\n#carboneVisualisation .axes.interactifs.active rect.opaque {opacity:0.7;}'
            +'\n#carboneVisualisation .axes.interactifs text.libelle {fill:transparent;}'
            +'\n#carboneVisualisation .axes.interactifs text.libelle.legendeX {dominant-baseline: central;text-anchor: middle;font-size: 0.8em;}'
            +'\n#carboneVisualisation .axes.interactifs text.libelle.legendeY {dominant-baseline: central;text-anchor: middle;font-size: 0.8em;}'
            +'\n#carboneVisualisation .axes.interactifs.active text.libelle {fill:#8f8f8f;}'
            +'\n#carboneVisualisation .axes.interactifs.active text.libelle.legendeY.classCourbe0 {fill:#804000;}'
            +'\n#carboneVisualisation .axes.interactifs.active text.libelle.legendeY.classCourbe3 {fill:#118900;}'
            +'\n#carboneVisualisation .axes.interactifs.active text.libelle.legendeY.classCourbe2 {fill:#C109A0;}'
            +'\n#carboneVisualisation .axes.interactifs.active text.libelle.legendeY.classCourbe1 {fill:#118900;}'
            +'\n#carboneVisualisation .libelle {font-family:"robotoregular"; font-size:1em; text-anchor: middle; dominant-baseline: central; fill:#111111;}'
            +'\n#carboneVisualisation .libelle.axeX {text-anchor: start;dominant-baseline: hanging;}'
            +'\n#carboneVisualisation .libelle.axeY {text-anchor: middle;dominant-baseline: initial;}'
            +'\n#carboneVisualisation .libelle.axeYA {fill:#804000;}'
            +'\n#carboneVisualisation .libelle.axeYB {fill:#118900;}'
            +'\n#carboneVisualisation .libelle.classCourbe0 {fill:#804000;}'
            +'\n#carboneVisualisation .libelle.classCourbe3 {fill:#118900;}'
            +'\n#carboneVisualisation .libelle.classCourbe2 {fill:#C109A0;}'
            +'\n#carboneVisualisation .libelle.classCourbe1 {fill:#118900;}'
            +'\n#carboneVisualisation .libelle.titre {font-size:1.4em;dominant-baseline:hanging;}'
            +'\n#carboneVisualisation .libelle.titre .titre1 {fill:#000091;}'
            +'\n#carboneVisualisation .libelle.titre .titre2 {fill:#8000FF;}'
            +'\n#carboneVisualisation .libelle.intervalleX {text-anchor: middle;dominant-baseline: hanging;}'
            +'\n#carboneVisualisation .libelle.intervalleY {dominant-baseline: central;font-size: 0.8em;}'
            +'\n#carboneVisualisation .libelle.intervalleYgauche {text-anchor: end;}'
            +'\n#carboneVisualisation .libelle.intervalleYdroite {text-anchor: start;}'
            +'\n#carboneVisualisation .libelle.optimumDecalage1 {dominant-baseline: initial;}'
            +'\n#carboneVisualisation .libelle.optimumDecalage2 {dominant-baseline: central;}'
            +'\n#carboneVisualisation .libelle.optimumDecalage3 {dominant-baseline: hanging;}'
        ;


        return(retour);
    }


    //*********************************
    observerCourbe(laCourbe) {
        var laClasse = laCourbe.infos.classe;
        var leObj = this;

        laCourbe.datas.forEach(function(point) {
            var leID = laCourbe.idCourbe+laClasse+'x'+point.x+'survol';
            var element = document.getElementById(leID);
            if (element) {
                var retour1 = element.addEventListener('mouseover', leObj.montrerAxe, false);
                var retour2 = element.addEventListener('mouseout', leObj.cacherAxe, false);
            }
        })
    }


    //*********************************
	montrerAxe() {
        var idCible = this.attributes["class"].value;
        var element = document.getElementById(idCible);
        /*
        var idSource = this.attributes["id"].value;
        var idCible = idSource.split('survol');
        var element = document.getElementById(idCible[0]);
        */
        element.attributes["class"].value = "axes interactifs active";
    }

    //*********************************
	cacherAxe() {
        var idCible = this.attributes["class"].value;
        var element = document.getElementById(idCible);
        /*
        var idSource = this.attributes["id"].value;
        var idCible = idSource.split('survol');
        var element = document.getElementById(idCible[0]);
        */
        element.attributes["class"].value = "axes interactifs";
    }

    //*********************************
    diviser(dividende, diviseur, message = "") {
        if (!diviseur) {
            alert("Division par 0 : ", message);
        }
        return(dividende/diviseur);
    }
    //*********************************
	afficherCourbes(courbe) {

        var laCourbe = this;
        var laClasse = courbe.infos.classe;
        var debutX = courbe.infos.debutX;
        var intervalleX = courbe.infos.intervalleX;
        var infosY = courbe.infosY;
        var existeCourbes = [];
        //var existeCourbeB = false;
        var axesAnneeSpecifiques = '<g class="axes" >';

        var x0= (this.l - this.lUtile) / 2;
        var y0 = (this.h - this.hUtile) / 2;



        // calcul des echelles **************************************************************
        var maxX = 0;
        var minX = 100000;

        var maxY = [];
        var minY = [];
        var i = 0;

        courbe.datas.forEach(function(point) {
            maxX = Math.max(point.x, maxX);
            minX = Math.min(point.x, minX);
            i = 0;
            while (typeof(point.itemsY[i]) !== 'undefined') {
                existeCourbes[i] = true;
                i++;
            }

        });

        // echelle en fonction des max "normés"
        i = 0;
        while (typeof(courbe.infosY[i]) !== 'undefined') {
            maxY[i] = courbe.infosY[i].max;
            minY[i] = 0;
            if (courbe.negatifPositif) {
//                minY[i] = maxY[i]*(-1); // 2019/12 valeur négatives - idem max positif ... mais négatif !!!
                //minY[i] = courbe.infosY[i].min; // 2019/12 valeur négatives
                minY[i] = Math.min(courbe.infosY[i].min, maxY[i]*(-1));
                maxY[i] = Math.max(courbe.infosY[i].max, minY[i]*(-1));
            }
            i++;
        }

        // de l'axe de x
        var echelleX = this.diviser(this.lUtile, maxX-minX, "echelleX");
        //var echelleX = this.lUtile/(maxX-minX);
        // des axes des y
        var echelleY = [];
        i = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            //echelleY[i] = this.hUtile/(maxY[i]-minY[i]);
            echelleY[i] = this.diviser(this.hUtile,maxY[i]-minY[i],"echelleY[i]");
            if (courbe.negatifPositif) {
                //echelleY[i] = this.diviser(this.hUtile/2,maxY[i]-minY[i],"echelleY[i]"); // test 2020/03 bug si "que du négatif"
            }
            i++;
        }

        // titre
        var retour = '<g class="axes">';
        var xBase = x0-minX*echelleX;
        var x1 = xBase+minX*echelleX;
        var x2 = xBase+maxX*echelleX;
        var xTitre = (x1+x2)/2;
        var yTitre = 0;
        if (courbe.infos.titre) {
            retour += '<text class="libelle titre" x="'+xTitre+'" y="'+yTitre+'">'+courbe.infos.titre+'</text>'
        }
        if ((courbe.infos.titre1) && (courbe.infos.titre2)) {
            yTitre = 15;
            retour += '<text class="libelle titre" x="'+xTitre+'" y="'+yTitre+'"><tspan class="titre1">'+courbe.infos.titre1+'</tspan> / <tspan class="titre2">'+courbe.infos.titre2+'</tspan></text>';

        }


        // dessin des axes  **************************************************************
        var xBaseGauche = x1; // ? valider - Remarque : l'échelle des x est unique
        var xBaseDroite = x2; // COMPRENDRE !!! voir si on peut utiliser xBase ...
        var yBase = [];
        var yAxe0s = [];
        var classAxesY = [];
        i = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            classAxesY[i] =  " classCourbe"+i; // conserver espace en début
            if (courbe.negatifPositif) {
                yBase[i] = y0-Math.min(minY[i],0)*echelleY[i]+maxY[i]*echelleY[i];
                yAxe0s[i] = (y0+yBase[i])/2;
            }
            else {
                yBase[i] = y0-0+maxY[i]*echelleY[i];
                yAxe0s[i] = yBase[i];
            }
            i++;
        }

        // axe X
        var xAxeX = x2+2*this.margeLegendes;
        var yAxeX = yAxe0s[0]+this.margeLegendes; // UN SEUL cf. remarque précédente
        retour += '<text class="libelle axeX" x="'+xAxeX+'" y="'+yAxeX+'">'+courbe.infos.axeX+'</text>'
        retour += '<line class="axeX" x1="'+x1+'" y1="'+yAxe0s[0]+'" x2="'+x2+'" y2="'+yAxe0s[0]+'" />';

        // axes Y
        var xAxesY = [];
        var y2 = [];
        var y1 = [];
        i = 0;
        var hVerticaleInteractive = 0;
        var decalageAxe = (this.l-this.lUtile)/4;
        var cptDroite = 0;
        var cptGauche = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            y2[i] = yAxe0s[i]-maxY[i]*echelleY[i];
            // y1[i] = yBase[i]+minY[i]*echelleY[i];
            y1[i] = yAxe0s[i]-minY[i]*echelleY[i]; // correction pour valeurs négatives
            if (i) {
                hVerticaleInteractive = Math.max(hVerticaleInteractive, y1[i]-y2[i]);
            }
            else {
                hVerticaleInteractive = y1[0]-y2[0];
            }
            if (infosY[i].xAxeY == "droite") {
                xAxesY[i] = xBaseDroite + decalageAxe*cptDroite;
                var y2decal = y2[i]-this.hEtiquette+this.hEtiquette*cptDroite;
                cptDroite++;
            }
            else {
                xAxesY[i] = xBaseGauche - decalageAxe*cptGauche;
                var y2decal = y2[i]-this.hEtiquette+this.hEtiquette*cptGauche;
                cptGauche++;
            }
            if ((courbe.code != "COMP") || (!i)) {
                var classeAxe = classAxesY[i];
                if (courbe.code == "COMP") {
                    classeAxe = "";
                }
                retour += '<line class="'+classeAxe+'" x1="'+xAxesY[i]+'" y1="'+y1[i]+'" x2="'+xAxesY[i]+'" y2="'+y2decal+'" />';
                var yAxeY = y2decal-this.hEtiquette; // il faut toujours être au dessus des étiquettes
                retour += '<text class="libelle axeY '+classeAxe+'" x="'+xAxesY[i]+'" y="'+yAxeY+'">'+courbe.infosY[i].libelleAxeY+'</text>';
                yAxeY += this.hauteurLegendes;
                retour += '<text class="libelle axeY '+classeAxe+'" x="'+xAxesY[i]+'" y="'+yAxeY+'">'+courbe.infosY[i].uniteLibelleAxeY+'</text>';
            }
            i++;


        }


        // les intervalles des axes x et y
        var xIntervalleGauche = xBaseGauche-this.margeLegendes;
        var xIntervalleDroite = xBaseDroite+this.margeLegendes;
        var xIntervalle = xIntervalleGauche;
        var xIntervalleClassGauche = "intervalleYgauche"; // intervalles des axes x
        var xIntervalleClassDroite = "intervalleYdroite";
        var xIntervalleClass = xIntervalleClassGauche;
        var yIntervalle = yAxe0s[0]+this.margeLegendes;
        //retour += '<text class="libelle intervalleX" x="'+x1+'" y="'+yIntervalle+'">'+debutX+'</text>'
        for (let iIntervalleX = intervalleX; iIntervalleX < maxX; iIntervalleX+=intervalleX) {
            var xintervalleX = xBase + iIntervalleX *echelleX;
            var libelleX = iIntervalleX;
            retour += '<text class="libelle intervalleX" x="'+xintervalleX+'" y="'+yIntervalle+'">'+libelleX+'</text>'
        }
        i = 0;
        var yIntervalles = [];
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            yIntervalles[i] = yAxe0s[i]+this.margeLegendes;
            var xIntervalles = xAxesY[i];
            if (infosY[i].xAxeY == "droite") {
                xIntervalle = xIntervalleDroite;
                xIntervalleClass = xIntervalleClassDroite;
                xIntervalles += this.margeLegendes
            }
            else {
                xIntervalle = xIntervalleGauche;
                xIntervalleClass = xIntervalleClassGauche;
                xIntervalles -= this.margeLegendes
            }

            if ((courbe.code != "COMP") || (!i)) {
                var classeAxe = classAxesY[i];
                if (courbe.code == "COMP") {
                    classeAxe = "";
                }
                for (let iIntervalleY = 0; iIntervalleY < maxY[i]; iIntervalleY+=infosY[i].intervalleY) {
                    iIntervalleY = this.arrondir(iIntervalleY); // bug javascript 0.4+0.2 = 0.600000000001 :-/
                    var iIntervalleY_txt = iIntervalleY.toLocaleString();
                    //if (infosY[i].donneesY == "€")
                    var yintervalleY = yAxe0s[i]-iIntervalleY*echelleY[i];
                    retour += '<text class="libelle intervalleY '+xIntervalleClass+classeAxe+'" x="'+xIntervalles+'" y="'+yintervalleY+'">'+iIntervalleY_txt+'</text>'
                }
                if (courbe.negatifPositif) {
                    for (let iIntervalleY = 0; iIntervalleY > minY[i]; iIntervalleY-=infosY[i].intervalleY) {
                        iIntervalleY = this.arrondir(iIntervalleY); // bug javascript 0.4+0.2 = 0.600000000001 :-/
                        var iIntervalleY_txt = iIntervalleY.toLocaleString();
                        var yintervalleY = yAxe0s[i]-iIntervalleY*echelleY[i];
                        retour += '<text class="libelle intervalleY '+xIntervalleClass+classeAxe+'" x="'+xIntervalles+'" y="'+yintervalleY+'">'+iIntervalleY_txt+'</text>'
                    }
                }
            }
            i++;
        }

        retour += '</g>';


        // dessin du path
        var axesAffichables = '';
        var axesInteractifs = '';
        var verticaleInteractive = '';
        var laClasseEstimee = "";
        if ((courbe.debutValide)  && (courbe.debutValide >= courbe.datas[0].x)) {
            laClasseEstimee += " estimation";
        }

        i = 0;
        var finInhibe = [];
        var classeInhibe = [];
        var retours = [];
        var suites = [];
        var xPred = x1-4;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            classeInhibe[i] = "";
            if (infosY[i].nonNul) {
                finInhibe[i] = false;
                classeInhibe[i] = " inhibe ";
            }
            retours[i] = '<path class="'+laClasse+laClasseEstimee+classAxesY[i]+classeInhibe[i]+'" d=" ';
            suites[i] = 0;
            i++;
        }

        // path des courbes  **************************************************************
        var yPointPred = [];
        var cptClassDecalage = 0;
        courbe.datas.forEach(function(point) {
            var x = xBase+point.x*echelleX;
            var xAxeXDonnees = x+laCourbe.lEtiquette/2;
            var xAxeYDonnees = [];
            var yAxeYDonnees = [];

            i = 0;
            var yTopGauche = 999999999;
            while (typeof(point.itemsY[i]) !== 'undefined') {

                if (infosY[i].nonNul && (typeof(finInhibe[i])  !== 'undefined') && !finInhibe[i]) {
                    if (typeof(point.itemsY[i].yinconnu) === 'undefined') {
                        finInhibe[i] = true;
                    }
                }


                // 1ère courbe
                var yPoint = yAxe0s[i]-Math.max(0,point.itemsY[i].y)*echelleY[i];
                if (courbe.negatifPositif && ((typeof(courbe.infosY[i].positif) === 'undefined') || !courbe.infosY[i].positif) ) {
                    yPoint = yAxe0s[i]-point.itemsY[i].y*echelleY[i];
                }
                if (point.itemsY[i].y >= 0 || courbe.negatifPositif)  {
                    yPointPred[i] = yPoint;
                    }
                else {
                    if (typeof(yPointPred[i]) !== 'undefined') {
                        yPoint = yPointPred[i];
                    }
                    else {
                        //yPoint = 0;
                    }
                }

                if (infosY[i].xAxeY == "gauche") {
                    yTopGauche = Math.min(yTopGauche, yPoint); // plus le y est faible, plus on est haut dans l'écran !
                }

                var yAxeXDonnees = yPoint+laCourbe.hEtiquette/2;
                yAxeYDonnees[i] = yPoint-laCourbe.hEtiquette/2;


                // point de la courbe
                if (!suites[i]) {
                    if (typeof(yPoint) === 'undefined') {
                        yPoint = 0;
                    }
                    retours[i] += 'M ' +x+ ','+yPoint+ ' ';
                }
                else {
                    if (!point.itemsY[i].yinconnu) {
                        retours[i] += ' ' +x+ ','+yPoint+ ' ';
                    }
                }
                suites[i]++;

                // on passe en zone "validée" (données "sûres")
                if (courbe.debutValide == point.x || ((typeof(finInhibe[i])  !== 'undefined') && finInhibe[i]) ) {
                    var lesClasses = laClasse+classAxesY[i];
                    if ((typeof(finInhibe[i])  !== 'undefined') && finInhibe[i]) {
                        classeInhibe[i] = "";
                        delete(finInhibe[i]);
                    }
                    lesClasses += classeInhibe[i];

                    suites[i] = 0;
                    retours[i] += ' " />'; // fin du premier path
                    retours[i] += '<path class="'+lesClasses+'" d=" ';

                    // à nouveau (même) point de la courbe
                    if (typeof(yPoint) === 'undefined') {
                        yPoint = 0;
                    }
                    retours[i] += 'M ' +x+ ','+yPoint+ ' ';
                    suites[i]++;
                }

                i++;
            }


            // bandes verticales invisibles qui "affichent" les coordonnées au passage souris
            var x1VerticaleInteractive = xPred+2;
            var lVerticaleInteractive = x-xPred/*+1*/;
            var classTransparent = "transparent";
            if (courbe.anneeCourante > point.x) {
                classTransparent += " dejaPasse";
            }
            if (courbe.coupeRase && (courbe.coupeRase < point.x)) {
                classTransparent += " apresCoupeRase";
            }

            verticaleInteractive = '<rect class="'+classTransparent+'" x="'+x1VerticaleInteractive+'" y="'+y2[0]+'" width="'+lVerticaleInteractive+'" height="'+hVerticaleInteractive+'"  />';
            xPred = x;

            // axes des coordonnées : affichés au passage de la souris sur les bandes verticales
            axesAffichables += '<g class="axes interactifs" id="'+courbe.idCourbe+laClasse+'x'+point.x+'">';
            axesAffichables += '<line class="axeVertical" x1="'+x+'" y1="'+y1[0]+'" x2="'+x+'" y2="'+y2[0]+'"  />';

            // on calcule
            i = 0;
            var xCroisement = x;
            var yCroisement = yAxe0s[0]-Math.max(0,point.itemsY[0].y)*echelleY[0];
            if (courbe.negatifPositif) {
                yCroisement = yAxe0s[0]-point.itemsY[0].y*echelleY[0];
            }
            var yTop = 999999999;
            var yCroisement = 0;
            var xCroisementsDecal = [];
            var yCroisementsDecal = [];
            var cptyTopGauche = 0;
            while (typeof(point.itemsY[i]) !== 'undefined') {

                // 1ère courbe
                var yPoint = yAxe0s[i]-Math.max(0,point.itemsY[i].y)*echelleY[i];
                if (courbe.negatifPositif) {
                    yPoint = yAxe0s[i]-point.itemsY[i].y*echelleY[i];
                }
                if (point.itemsY[i].y >= 0 || courbe.negatifPositif) {
                    yPointPred[i] = yPoint;
                    }
                else {
                    if (typeof(yPointPred[i]) !== 'undefined') {
                        yPoint = yPointPred[i];
                    }
                    else {
                        //yPoint = 0;
                    }
                }

                yTop = Math.min(yTop, yPoint); // plus le y est faible, plus on est haut dans l'écran !
                yCroisement = Math.max(yCroisement, yPoint);
                yCroisementsDecal[i] = yPoint - laCourbe.hEtiquette;

                if (infosY[i].xAxeY == "droite") {
                    xCroisementsDecal[i] = xCroisement;
                    xAxeYDonnees[i] = x+laCourbe.lEtiquette/2;
                }
                else {
                    xCroisementsDecal[i] = xCroisement-laCourbe.lEtiquette;
                    xAxeYDonnees[i] = x-laCourbe.lEtiquette/2
                    if ((yPoint == yTopGauche) && !cptyTopGauche) {
                        cptyTopGauche++;
                    }
                    else {
                        yCroisementsDecal[i] += laCourbe.hEtiquette;
                        yAxeYDonnees[i] += laCourbe.hEtiquette;
                    }
                }
                axesAffichables += '<line class="'+classAxesY[i]+'" x1="'+xAxesY[i]+'" y1="'+yPoint+'" x2="'+x+'" y2="'+yPoint+'"  />';


                i++;
            }

            // on affiche
            // rectangles gris sous les chiffres
            axesAffichables += '<rect class="opaque" x="'+xCroisement+'" y="'+yCroisement+'" width="'+laCourbe.lEtiquette+'" height="'+laCourbe.hEtiquette+'"  />'; // x
            // et chiffres
            yAxeXDonnees = yCroisement+laCourbe.hEtiquette/2;
            axesAffichables += '<text class="libelle legendeX" x="'+xAxeXDonnees+'" y="'+yAxeXDonnees+'">'+point.x+' '+courbe.infos.donneesX+'</text>'; // x
            i = 0;
            while (typeof(point.itemsY[i]) !== 'undefined') {
                // rectangles gris sous les chiffres
                axesAffichables += '<rect class="opaque" x="'+xCroisementsDecal[i]+'" y="'+yCroisementsDecal[i]+'" width="'+laCourbe.lEtiquette+'" height="'+laCourbe.hEtiquette+'"  />'; // y
                // et chiffres
                var libelleYB = point.itemsY[i].y+' '+courbe.infosY[i].donneesY;
                if (point.itemsY[i].y < 0 && !courbe.negatifPositif) {
                    libelleYB = "inconnu";
                }
                axesAffichables += '<text class="libelle legendeY '+classAxesY[i]+'" x="'+xAxeYDonnees[i]+'" y="'+yAxeYDonnees[i]+'">'+libelleYB+'</text>';

                i++;
            }
            // 2022/11
            if ((typeof(courbe.montrerAnnees) !== 'undefined') && courbe.montrerAnnees) {
                const yLibelleAnnee = y2[0]+laCourbe.margeLegendes;
                const lAnnee = courbe.anneeDuJour+point.x-(courbe.anneeCourante-1);
                axesAffichables += '<text class="libelle legendeX" x="'+x+'" y="'+yLibelleAnnee+'">'+lAnnee+'</text>';
            }
            // fin 2022/11

            axesAffichables += '</g>';
            axesInteractifs += '<g class="'+courbe.idCourbe+laClasse+'x'+point.x+'" id="'+courbe.idCourbe+laClasse+'x'+point.x+'survol">';
            axesInteractifs += verticaleInteractive; // on recouvre EN DERNIER pour permettre le clic
            axesInteractifs += '</g>';

            // axex verticaux pour l'année courante, les mesures et les éclaircies
            //var yLibelle = y1[0]+laCourbe.hauteurLegendes+laCourbe.margeLegendes;
            var yLibelle = y1[0]+2*laCourbe.hauteurLegendes;
            var yLine = yLibelle-laCourbe.margeLegendes;
            var axesMesures = "";
            if (point.mesure) {
                axesMesures += '<line class="axeAnneeMesure" x1="'+x+'" y1="'+yLine+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                var xRectMesure = x - laCourbe.lLegende / 2;
                var yRectMesure = yLibelle - laCourbe.hLegende / 2;
                axesMesures += '<rect class="fond" x="'+xRectMesure+'" y="'+yRectMesure+'" width="'+laCourbe.lLegende+'" height="'+laCourbe.hLegende+'"  />';
                axesMesures += '<text class="libelle axeAnneeMesure" x="'+x+'" y="'+yLibelle+'">Mesure</text>'; // TODO txt en dehors
            }
            if (point.eclaircie || point.eclaircie1 || point.eclaircie2) {
                yLine += 2*laCourbe.hauteurLegendes;
                var yLineEclaircie = yLine+laCourbe.margeLegendes;
                var classeBis = "";
                var classeTer = "";
                if (typeof(point.eclaircieIT) !== 'undefined') {
                    classeBis += " IT "
                }
                if (point.eclaircie1 && point.eclaircie2) {
                    classeBis = "eclaircie1 eclaircie1eclaircie2";
                    classeTer = "eclaircie2 eclaircie2eclaircie3";
                    axesAnneeSpecifiques += '<line class="axeAnneeEclaircie '+classeTer+'" x1="'+x+'" y1="'+yLineEclaircie+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                }
                else {
                    if (point.eclaircie1) {
                        classeBis = "eclaircie1";
                    }
                    if (point.eclaircie2) {
                        classeBis = "eclaircie2";
                    }
                }
                axesAnneeSpecifiques += '<line class="axeAnneeEclaircie '+classeBis+'" x1="'+x+'" y1="'+yLineEclaircie+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                yLibelle = yLine + 3*laCourbe.margeLegendes; // on double car le texte est centré verticalement
                const libelleEclaircieOuDepressage = (point.depressage ? "Dépressage" : "Eclaircie");
                axesAnneeSpecifiques += '<text class="libelle axeAnneeEclaircie '+classeBis+'" x="'+x+'" y="'+yLibelle+'">'+libelleEclaircieOuDepressage+'</text>';
            }
            if (point.coupeRase) {
                var yLineCR = y1[0]+laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<line class="axeAnneeCoupeRase" x1="'+x+'" y1="'+yLineCR+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                yLibelle = y2[0]-2*laCourbe.margeLegendes;
                axesAnneeSpecifiques += '<text class="libelle axeAnneeCoupeRase" x="'+x+'" y="'+yLibelle+'">Coupe rase</text>';
            }
            if (point.coupeRaseBNA) {
                cptClassDecalage++;
                var yLineCR_BNA = y1[0]+(0.7*(cptClassDecalage+2))*laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<line class="axeAnneeCoupeRaseBNA" x1="'+x+'" y1="'+yLineCR_BNA+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                yLibelle = yLineCR_BNA+0.5*laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<text class="libelle axeAnneeCoupeRaseBNA" x="'+x+'" y="'+yLibelle+'">Optimum BNA</text>';
            }
            if (point.coupeRaseACE) {
                cptClassDecalage++;
                var yLineCR_ACE = y1[0]+(0.7*(cptClassDecalage+2))*laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<line class="axeAnneeCoupeRaseACE" x1="'+x+'" y1="'+yLineCR_ACE+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                yLibelle = yLineCR_ACE+0.5*laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<text class="libelle axeAnneeCoupeRaseACE" x="'+x+'" y="'+yLibelle+'">Optimum ACE</text>';
            }
            if (point.coupeRaseTIR) {
                cptClassDecalage++;
                var yLineCR_TIR = y1[0]+(0.7*(cptClassDecalage+2))*laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<line class="axeAnneeCoupeRaseTIR" x1="'+x+'" y1="'+yLineCR_TIR+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                yLibelle = yLineCR_TIR+0.5*laCourbe.hauteurLegendes;
                axesAnneeSpecifiques += '<text class="libelle axeAnneeCoupeRaseTIR" x="'+x+'" y="'+yLibelle+'">Optimum TIR</text>';
            }

            if (courbe.anneeCourante-1 == point.x && courbe.anneeDuJour) {
                axesAnneeSpecifiques += '<line class="axeAnneeCourante" x1="'+x+'" y1="'+y1[0]+'" x2="'+x+'" y2="'+y2[0]+'"  />';
                yLibelle = y2[0]-laCourbe.margeLegendes;
                axesAnneeSpecifiques += '<text class="libelle axeAnneeCourante" x="'+x+'" y="'+yLibelle+'">'+courbe.anneeDuJour+'</text>';
            }
            axesAnneeSpecifiques += axesMesures;

        })

        i = 0;
        while (typeof(courbe.datas[0].itemsY[i]) !== 'undefined') {
            retours[i] += ' " />'; // fin du path
            retour += retours[i];
            i++;
        }


        axesAnneeSpecifiques += '</g>';
        retour += axesAnneeSpecifiques; // on ajoute les axes affichables (annee courante ... )
        retour += axesAffichables; // on ajoute les axes affichables
        retour += axesInteractifs; // puis les axes interactifs qui permettent d'afficher les axes affichables


        this.calqueEnCours.calque += retour;
    }

    //*********************************
    arrondir = function(valeur, puissance = 3) {
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

        //*********************************
	initialiserCourbe(leStore, courbe = null) {

        leStore.commit('setConnexionTimeMSec');

        // calques de l'animation
        this.calqueCourbes = new Object(); // le dessin dont les etiquettes
        this.calqueCourbes.calque = "";
        this.calqueEnCours = this.calqueCourbes;

        var retour = this.courbeHauteur(courbe);

        return(retour);
    }

        //*********************************
	maj(courbe) {

        if (!courbe) {
            return;
        }
console.log("svgCourbe : maj");
        //globCourbes.observerCourbe(globCourbes.courbeHauteurs);
        globCourbes.observerCourbe(courbe);

    }

        // ************************************
    courbeHauteur(courbes){

        //this.courbeHauteurs = courbes;

        // un calque avec les polygones ET les etiquettes
        this.calqueEnCours = this.calqueCourbes;
        this.enteteSVGcalque("epureSVG");
        this.styleCSScalque(this.epureCSS());
        this.afficherCourbes(courbes);
        this.cloreSVGcalque();
        var retour = '<div id="courbeHauteur">';
        retour += this.calqueEnCours.calque;
        retour += '</div>';



        return(retour);
    }

        // ************************************
    afficherDansDiv(divId){
        var element = document.getElementById(divId);
        element.innerHTML = '<div id="courbes">'+this.calqueCourbes.calque+'</div>';
        //$( divId ).html('<div id="courbes">'+this.calqueCourbes.calque+'</div>');

    }

}


export const courbeSVG = new SVGCourbes()
