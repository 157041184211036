//*********************************
class UF_PPL_FINANCIER {
    constructor() {
        this.ppl = null;
        this.depenses = [];
        this.travaux = [];
        this.tabValeursCalculeeS = [];
        this.ready = false;
        }

		//*********************************
/*
initialiser(dendrometrie, datas) {

        // init et raccourcis
        this.dendrometrie = dendrometrie;
        this.ppl = dendrometrie.ppl;

        this.depenses = [];
        this.travaux = [];
        this.tabValeursCalculeeS = [];

        this.constantesSteres = dendrometrie.constantesSteres;
        this.maxBNA = 0.0;
        this.maxACE = 0.0;
        this.minBNA = 0.0;
        this.minACE = 0.0;
        this.maxTIR = 0.0;
        this.minTIR = 0.0;
        this.maxRecettePotentielleCR = 0.0;
        this.minRecettePotentielleCR = 0.0;
        this.tabValeursCalculeeS[0] = {};
        this.ageMaxTest = Math.trunc(datas.ageMaxTest);
        this.surfacePropriete = datas.surfacePropriete;

        // raccourci pour gain de temps
        this.constantesSteres = this.ppl.uf.propriete.constantesSteres;

        // intégration des données saisies (et calculs des dépenses an/an)
        this.initialiserDonneesEntrees(datas);

        // calcul des recettes à partir des infos dendrométriques
        this.calculerRecettes();

        return(this);
    }
*/
		//*********************************
    trouverAgeDerniereEclaircie(dendrometrie) {
        var ageDerniereEclaircie = 0;
        dendrometrie.eclaircies.forEach(eclaircie => {
            ageDerniereEclaircie = eclaircie.age;
        })
        this.ageDerniereEclaircie = ageDerniereEclaircie;
        return(ageDerniereEclaircie);
    }

		//*********************************
    initialiserUfFinancier(dendrometrie, params) {

        // init et raccourcis
        this.dendrometrie = dendrometrie;
        this.ppl = dendrometrie.ppl;

        this.depenses = [];
        this.travaux = [];
        this.tabValeursCalculeeS = [];

        this.constantesSteres = dendrometrie.constantesSteres;
        this.maxBNA = 0.0;
        this.maxACE = 0.0;
        this.minBNA = 0.0;
        this.minACE = 0.0;
        this.maxTIR = 0.0;
        this.minTIR = 0.0;
        this.maxVolume = 0.0;
        this.minVolume = 0.0;
        this.maxVolumeUnitaire = 0.0;
        this.minVolumeUnitaire = 0.0;
        this.maxRecettePotentielleCR = 0.0;
        this.minRecettePotentielleCR = 0.0;
        this.tabValeursCalculeeS[0] = {};
        this.ageMaxTest = Math.trunc(params.ageMaxTest);
        this.surfacePropriete = this.ppl.uf.surface;

        // initialisation optimum
        this.ageDerniereEclaircie = 0;
        this.optimumBNA = {};
        this.optimumBNA.valeur = 0.0;
        this.optimumBNA.annee = 0;
        this.optimumACE = {};
        this.optimumACE.valeur = 0.0;
        this.optimumACE.annee = 0;
        this.optimumTIR = {};
        this.optimumTIR.valeur = 0.0;
        this.optimumTIR.annee = 0;
        this.optimumBNA2 = {};
        this.optimumBNA2.valeur = 0.0;
        this.optimumBNA2.annee = 0;
        this.optimumTIR2 = {};
        this.optimumTIR2.valeur = 0.0;
        this.optimumTIR2.annee = 0;

        // raccourci pour gain de temps
        this.constantesSteres = this.ppl.uf.propriete.constantesSteres;
        var datasDepenses = this.preparerDatasDepenses(this.ppl);

        // intégration des données saisies (et calculs des dépenses an/an)
        this.initialiserDonneesEntrees(datasDepenses);

        // calcul des recettes à partir des infos dendrométriques
        this.calculerRecettes();

        this.ready = true;

        return(this);
    }

    //*********************************
    preparerDatasDepenses(ppl) {

        var datas = {};

        var travaux = JSON.parse(ppl.travauxJson);
        datas.travaux = [];
        travaux.forEach(travail => {
            if (travail.choisi) {
                var datasTravaux = {};
                datasTravaux.code = travail.code;
                datasTravaux.age = travail.anneeDebut;
                datasTravaux.cout = travail.coutHectare;
                datas.travaux.push(datasTravaux);
            }
        })

        var depenses = JSON.parse(ppl.uf.propriete.depensesJson);
        datas.depenses = [];
        depenses.forEach(depense => {
            if (depense.choisi) {
                var datasDepenses = {};
                datasDepenses.code = depense.code;
                datasDepenses.age = depense.anneeDebut;
                //datasDepenses.anneeDate = 0;
                datasDepenses.cout = depense.coutHectare;
                datas.depenses.push(datasDepenses);
            }
        })

        return datas;
    }

		//*********************************
    initialiserDonneesEntrees(datas = null) {

        /*
        var depenseNulle= [] ;
        depenseNulle[0] = {};
        depenseNulle[0].cout = 0;
        depenseNulle[0].total = 0;
        this.depenses.length = this.ageMaxTest+1;
        this.depenses.fill(depenseNulle, 0)
        */
        var depenseNulle= {};
        depenseNulle.total = 0;
        this.depenses.length = this.ageMaxTest+1;
        this.depenses.fill(depenseNulle, 0)


        if (datas) {
            // Maintenant on a les infos nécessaires aux calculs (même si elles sont estimées)
                // injection des dépenses et travaux
            var cptDepensesEtTravaux = 0;
            while (datas.depenses[cptDepensesEtTravaux] || datas.travaux[cptDepensesEtTravaux]) {
                if (datas.depenses[cptDepensesEtTravaux]) {
                    this.ajouterDepense(datas.depenses[cptDepensesEtTravaux]) ;
                }
                if (datas.travaux[cptDepensesEtTravaux]) {
                    this.ajouterTravail(datas.travaux[cptDepensesEtTravaux]) ;
                }
                cptDepensesEtTravaux++;
            }

            // les dépenses sont reportées sur chaque années (contrairement aux travaux)
            for(var cptDepensesAge = 1; cptDepensesAge <= this.ageMaxTest+1; cptDepensesAge++) {
                var tabDepensesAMoins1 = this.depenses[cptDepensesAge-1];
                var cptDepensesAMoins1 = 0;
                var total = 0.0;
                var ok = true;
                while (ok) {
                    var laDepense = tabDepensesAMoins1[cptDepensesAMoins1];
                    if (typeof(laDepense) === 'undefined') {
                        break;
                    }
                    total += laDepense.cout;
                    if ((cptDepensesAge <= this.ageMaxTest) && (typeof(this.depenses[cptDepensesAge]) === 'undefined')) {
                        this.depenses[cptDepensesAge] = [];
                    }
                    if ((cptDepensesAge <= this.ageMaxTest) && (typeof(this.depenses[laDepense.code]) !== 'undefined') && (typeof(this.depenses[laDepense.code][cptDepensesAge]) === 'undefined')) {
                        if (typeof(this.depenses[cptDepensesAge][0]) === 'undefined') {
                                this.depenses[cptDepensesAge] = [];
                            }
                        this.depenses[cptDepensesAge].push(laDepense);
                        this.depenses[laDepense.code][cptDepensesAge] = laDepense.cout; // on n'a pas besoin de plus
                    }
                    cptDepensesAMoins1++;
                }
                tabDepensesAMoins1.total = total; // vu qu'en fait les tableaux sont des objets maquillés ...
            }
        }



        return(true);
    }

    //*********************************
    ajouterTravail(travailJson) {

        // notre tableau commence à 0 ce qui correspond à l'age et pas aux années
        var travail = travailJson;

        if (travail.age < 0) {
            travail.age = travail.anneeDate - this.ppl.anneeDateInstallation;
        }
        if ((typeof(this.travaux[travail.age]) === 'undefined') || (typeof(this.travaux[travail.age][0]) === 'undefined')) {
            this.travaux[travail.age] = [];
            this.travaux[travail.age].total = 0.0;
        }

        this.travaux[travail.age].push(travail);

        // et on en profite pour faire le total sur l'année ...
        this.travaux[travail.age].total += travail.cout;

        return(1);
    }

    //*********************************
    ajouterDepense(depenseJson) {

        // notre tableau commence à 0 ce qui correspond à l'age et pas aux années
        var depense = depenseJson;
        depense.cout = depense.cout / this.ppl.surfaceBoisee * this.ppl.surfaceUF; // c'est un cout à l'hectare qui est plus fort si la surface boisée n'est pas totale
        if (depense.age < 0) {
            depense.age = Math.max(0, depense.anneeDate - this.ppl.anneeDateInstallation); // la dépense récurrente peut être antérieure au peuplement
        }

        if ((typeof(this.depenses[depense.age]) === 'undefined') || (typeof(this.depenses[depense.age][0]) === 'undefined')) {
            var total = this.depenses[depense.age].total;
            this.depenses[depense.age] = [];
            this.depenses[depense.age].total = total;
        }
        if (typeof(this.depenses[depense.code]) === 'undefined') {
            this.depenses[depense.code] = [];
        }

        this.depenses[depense.age].push(depense);
        this.depenses[depense.code][depense.age] = depense.cout;  // pour accès direct, on n'a pas besoin de plus


        return(1);
    }

    //*********************************
/*
	triTarif(x, y) {
        if (x.anneeDebutValidite != y.anneeDebutValidite) {
            return(y.anneeDebutValidite - x.anneeDebutValidite); // tri descendant
        }
        return(x.volumeMoyen - y.volumeMoyen); // tri ascendant
    }
*/
		//*********************************
    anneeMinAcceptable(moisBascule) { // prise en compte de l'éclaircie aprés que le temps ... soit passé ...
        var lAnnee = new Date().getFullYear();
        var moisReel = new Date().getMonth()+1;
        if (moisReel >= moisBascule) {
            lAnnee += 1;
        }
        return lAnnee;
    }

		//*********************************
    calculerRecettes() {

        // le param qu'on passera au "calculateur"
        var paramsCalculSource = {};
        //paramsCalculSource.essenceId = this.ppl.essence.id; ??????????????????

        var ageDerniereEclaircie = this.trouverAgeDerniereEclaircie(this.dendrometrie); // pour l'optimume STERE
        this.tauxActualisation = this.ppl.lireTauxActualisation();
        this.optimumSteres = 0;

        var tabTarifs = this.ppl.lireTarifs();

        var cumulDepenses = 0;
        var cumulRecettes = 0;


        var anneeMinAcceptable = this.anneeMinAcceptable(this.constantesSteres.moisBascule);

        for(var cptAges = 0; cptAges <= this.ageMaxTest; cptAges++) {

            var paramsCalcul = Object.assign({}, paramsCalculSource); // a priori pas la peine de cloner

            paramsCalcul.hauteur = this.dendrometrie.tabValeursCalculeeS[cptAges].hauteur;
            paramsCalcul.circonference = this.dendrometrie.tabValeursCalculeeS[cptAges].volume;
            paramsCalcul.densite = this.dendrometrie.tabValeursCalculeeS[cptAges].densite;
            paramsCalcul.volumeUnitaire = this.dendrometrie.tabValeursCalculeeS[cptAges].volumeUnitaire;
            paramsCalcul.volumeUnitaireEclaircie = this.dendrometrie.tabValeursCalculeeS[cptAges].volumeUnitaireEclaircie;
            paramsCalcul.volume = this.dendrometrie.tabValeursCalculeeS[cptAges].volume;
            paramsCalcul.volumeRecolteEclaircie = this.dendrometrie.tabValeursCalculeeS[cptAges].volumeRecolteEclaircie;
            paramsCalcul.anneeDate = this.ppl.anneeDateInstallation + cptAges;


            var retour = this.calculerPrixM3(paramsCalcul.anneeDate, paramsCalcul.volumeUnitaire, tabTarifs);
            paramsCalcul.prixM3 = retour.prixM3;
            var tarifEclaircie = this.dendrometrie.tabValeursCalculeeS[cptAges].tarifEclaircie; // prix spécifique (0) pour les dépressages ! 2020/03/18
            if (typeof(tarifEclaircie) !== 'undefined') {
                paramsCalcul.prixEclaircieM3 = tarifEclaircie;
            }
            else {
                var retourEclaircie = this.calculerPrixM3(paramsCalcul.anneeDate, paramsCalcul.volumeUnitaireEclaircie, tabTarifs);
                paramsCalcul.prixEclaircieM3 = retourEclaircie.prixEclaircieM3;
                if (paramsCalcul.volumeRecolteEclaircie > 0) {
                    if (this.ppl.uf.identifiant == "Semis abandonné") {
                        var toto = 1;
                    }
                }
            }

            var volumeRecolteEclaircie = paramsCalcul.volumeRecolteEclaircie;
            var volumeAvantEclaircie = this.dendrometrie.tabValeursCalculeeS[cptAges].volumeAvantEclaircie

            // si on considère que l'année retenue dendrométrique n'est pas l'année financière : 2020/07/06
            if (typeof(this.dendrometrie.tabValeursCalculeeS[cptAges+1]) !== 'undefined') {
                var anneeFinanciereReelle = {};
                paramsCalcul.anneeFinanciereReelle = anneeFinanciereReelle;
                anneeFinanciereReelle.volumeUnitaireEclaircie = this.dendrometrie.tabValeursCalculeeS[cptAges+1].volumeUnitaireEclaircie;
                anneeFinanciereReelle.volumeRecolteEclaircie = this.dendrometrie.tabValeursCalculeeS[cptAges+1].volumeRecolteEclaircie;
                // tarifs pour la bonne année - todo ? a priori non, puisque on ramène les valeurs dendro de n+1 sur n avec les tarifs de n
            }

            paramsCalcul.recetteEclaircie = 0;
            if (volumeRecolteEclaircie > 0) {
                paramsCalcul.recetteEclaircie = paramsCalcul.prixEclaircieM3 * volumeRecolteEclaircie;
                cumulRecettes += paramsCalcul.recetteEclaircie;
            }
            paramsCalcul.recettePotentielleCR = 0;
            paramsCalcul.recettePotentielleCRavantEclaircie = 0;
            if (paramsCalcul.volume > 0 && !paramsCalcul.recetteEclaircie) {
                paramsCalcul.recettePotentielleCR = paramsCalcul.prixM3 * paramsCalcul.volume;
            }
            if (paramsCalcul.volume > 0 && paramsCalcul.recetteEclaircie) {
                paramsCalcul.recettePotentielleCR = paramsCalcul.prixM3 * paramsCalcul.volume;
                paramsCalcul.recettePotentielleCRavantEclaircie = paramsCalcul.prixM3 * volumeAvantEclaircie;
            }

            this.tabValeursCalculeeS[cptAges] = paramsCalcul;
            this.tabValeursCalculeeS[cptAges].depenses = this.calculerDepenses(cptAges);
            cumulDepenses += this.tabValeursCalculeeS[cptAges].depenses;
            this.tabValeursCalculeeS[cptAges].depensesCumulees = parseFloat(cumulDepenses);
            this.tabValeursCalculeeS[cptAges].BNA = this.calculerBNA(cptAges);
            this.tabValeursCalculeeS[cptAges].TIR = this.calculerTIR(cptAges);
            this.tabValeursCalculeeS[cptAges].ACE = this.calculerACE(cptAges);

            // calcul des max et des min depuis année courante seulement (pour cohérence affichage)
            if (cptAges >= this.ppl.anneeCourante-1) {
                this.maxBNA = Math.max(this.maxBNA, this.tabValeursCalculeeS[cptAges].BNA);
                this.minBNA = Math.min(this.minBNA, this.tabValeursCalculeeS[cptAges].BNA);
                this.maxTIR = Math.max(this.maxTIR, this.tabValeursCalculeeS[cptAges].TIR);
                this.minTIR = Math.min(this.minTIR, this.tabValeursCalculeeS[cptAges].TIR);
                this.maxACE = Math.max(this.maxACE, this.tabValeursCalculeeS[cptAges].ACE);
                this.minACE = Math.min(this.minACE, this.tabValeursCalculeeS[cptAges].ACE);
                this.maxVolumeUnitaire = Math.max(this.maxVolumeUnitaire, this.tabValeursCalculeeS[cptAges].volumeUnitaire);
                this.minVolumeUnitaire = Math.min(this.minVolumeUnitaire, this.tabValeursCalculeeS[cptAges].volumeUnitaire);
                this.maxVolume = Math.max(this.maxVolume, this.tabValeursCalculeeS[cptAges].volume);
                this.minVolume = Math.min(this.minVolume, this.tabValeursCalculeeS[cptAges].volume);

                this.maxRecettePotentielleCR = Math.max(this.maxRecettePotentielleCR, this.tabValeursCalculeeS[cptAges].recettePotentielleCR);
                this.minRecettePotentielleCR = Math.min(this.minRecettePotentielleCR, this.tabValeursCalculeeS[cptAges].recettePotentielleCR);

                // calcul des optimum
                this.majOptimum(cptAges, this.optimumBNA, this.tabValeursCalculeeS[cptAges].BNA, false);
                this.majOptimum(cptAges, this.optimumACE, this.tabValeursCalculeeS[cptAges].ACE, false);
                this.majOptimum(cptAges, this.optimumTIR, this.tabValeursCalculeeS[cptAges].TIR, false);

                // optimum STERES
                if (cptAges+ this.ppl.anneeRetenue >= anneeMinAcceptable) { // bascule de juin
                    this.majOptimum(cptAges, this.optimumBNA2, this.tabValeursCalculeeS[cptAges].BNA, true);
                    this.majOptimum(cptAges, this.optimumTIR2, this.tabValeursCalculeeS[cptAges].TIR, true);
                }
            }

            // calcul de l'Optimum Steres (abandonné et remplacé par optimum BNA)
            if (ageDerniereEclaircie && cptAges > (ageDerniereEclaircie+1) && !this.optimumSteres) {
                var ratioNNmoins1 = this.tabValeursCalculeeS[cptAges].recettePotentielleCR / this.tabValeursCalculeeS[cptAges-1].recettePotentielleCR;
                if (ratioNNmoins1 < (1+this.tauxActualisation)) {
                    this.optimumSteres = cptAges-1;
                    this.ppl.it.optimumSteres =  cptAges-1; // il faut donner l'info à l'IT
                    //cumulRecettes += this.tabValeursCalculeeS[cptAges-1].recettePotentielleCR
                }
            }


            this.tabValeursCalculeeS[cptAges].recettesCumulees = cumulRecettes;
        }

        // optimum et recette de la CR qui correspond à l'optimum
        this.optimumSteres = this.optimumBNA2.annee;
        if (!this.optimumSteres) {
            this.optimumSteres = this.optimumTIR2.annee;
        }
        this.ppl.it.optimumSteres =  this.optimumSteres; // il faut donner l'info à l'IT
        // this.tabValeursCalculeeS[this.optimumSteres].recettesCumulees = this.tabValeursCalculeeS[this.optimumSteres].recettePotentielleCR; // ajout CR aux recettes cf. boucle ci-dessous


        for(cptAges = 0; cptAges <= this.ageMaxTest; cptAges++) {

            if (cptAges >= this.optimumSteres) {
                this.tabValeursCalculeeS[cptAges].recettesCumulees += this.tabValeursCalculeeS[this.optimumSteres].recettePotentielleCR; // ajout CR aux recettes
            }
        }

        // pour la valeur d'attente
        var anneeCoupeRase = this.optimumSteres;
        if (this.ppl.it) {
            if (this.ppl.it.auto) {
                this.ppl.it.coupeRase = this.optimumSteres;
            }
            anneeCoupeRase = this.ppl.it.coupeRase;
        }

        for(var cptAges = 0; cptAges <= this.ageMaxTest; cptAges++) {
            this.tabValeursCalculeeS[cptAges].valeurAttente = this.calculervaleurAttente(cptAges, anneeCoupeRase);
        }

    }

    // ***********************
    majOptimum(cptAges, optimum, valeurCalculee, testDerniereEclaircie) {

        if (testDerniereEclaircie) {
            //if (!this.ageDerniereEclaircie || (cptAges <= this.ageDerniereEclaircie)) {
            if (cptAges <= this.ageDerniereEclaircie) {
                return;
            }
        }

        optimum.valeur = Math.max(optimum.valeur, valeurCalculee);
        if (optimum.valeur == valeurCalculee) {
            optimum.annee = cptAges;
        }
    }


		//*********************************
    calculerPrixM3(anneeDate, volumeUnitaire, tabTarifs) {

        var cptTarifs = 0;
        var prixM3 = 0;
        var basePrixM3 = 0;
        var accroissement = 0;
        var baseAccroissement = 0;
        var baseAccroissementPrix = 0;
        var baseAccroissementPrixM3 = 0;
        var accroissementPrixM3 = 0;
        var tarifPred = tabTarifs[0];
        //while (1) {
        while (cptTarifs <= tabTarifs.length) {
            if (anneeDate >= tabTarifs[cptTarifs].anneeDebutValidite) { // il se peut que les tarifs les plus récents soit postérieurs à la date
                var tarif = tabTarifs[cptTarifs];
                if (volumeUnitaire == tarif.volumeMoyen) {
                    prixM3 = tarif.prixM3;
                    break;
                }
                if (volumeUnitaire > tarif.volumeMoyen) {
                    basePrixM3 = tarif.prixM3;
                    if (cptTarifs == tabTarifs.length-1) {
                        // il faut pouvoir sortir sur le prix max
                        prixM3 = tarif.prixM3;
                        break;
                    }
                }
                else {
                    var antiPbArrondis = 1000; // on multiplie pour rester sur des chiffres suffisament grands
                    baseAccroissement = tarif.volumeMoyen - tarifPred.volumeMoyen;
                    baseAccroissementPrix = tarif.prixM3 - tarifPred.prixM3;
                    if (baseAccroissement) {
                        baseAccroissementPrixM3 = antiPbArrondis * baseAccroissementPrix * (1/baseAccroissement);
                    }
                    accroissement = volumeUnitaire - tarifPred.volumeMoyen;
                    accroissementPrixM3 = baseAccroissementPrixM3 * accroissement / antiPbArrondis;
                    prixM3 = basePrixM3 + accroissementPrixM3;
                    break;
                }
            }
            cptTarifs++;
            tarifPred = tarif;
            if (cptTarifs == tabTarifs.length) {
                    console.log("calculerPrixM3 EREUR - pas de prix : ", anneeDate, volumeUnitaire);
                    break;
            }
        }

        var retour = {};
        retour.prixM3 = prixM3;
        retour.prixEclaircieM3 = prixM3 * this.ppl.essence.minorationPrixM3Eclaircie;
        return retour;
    }

		//*********************************
    calculerBNAs() {
        for(var cptAges = 0; cptAges <= this.ageMaxTest; cptAges++) {
            this.tabValeursCalculeeS[cptAges].BNA = this.calculerBNA(cptAges);
        }
    }
		//*********************************
    calculerBNA(age) {
        var tabRecettes = this.tabValeursCalculeeS;
        var tabTravaux = this.travaux;
        var tabDepenses = this.depenses;
        //var tauxActualisation = this.ppl.lireTauxActualisation();
        var tauxActualisation = this.tauxActualisation;

        var totalSommesActualisees = this.calculerHypotheseBNA(age, tabRecettes, tabTravaux, tabDepenses, tauxActualisation);

        if (totalSommesActualisees) {
            return(totalSommesActualisees);
        }
        return(-1);
    }
		//*********************************
    calculervaleurAttente(age, anneeCoupeRase) {
        var tabRecettes = this.tabValeursCalculeeS;
        var tabTravaux = this.travaux;
        var tabDepenses = this.depenses;
        //var tauxActualisation = this.ppl.lireTauxActualisation();
        var tauxActualisation = this.tauxActualisation;

        var totalSommesActualisees = this.calculerHypotheseValeurAttente(age, tabRecettes, tabTravaux, tabDepenses, tauxActualisation, anneeCoupeRase);

        if (totalSommesActualisees) {
            return(totalSommesActualisees);
        }
        return(-1);
    }


		//*********************************
    calculerDepenses(age) {

        var tabTravaux = this.travaux;
        var tabDepenses = this.depenses;

        var depenses = 0;
        if (typeof(tabTravaux[age]) !== 'undefined') {
            depenses += tabTravaux[age].total;
        }
        if (typeof(tabDepenses[age]) !== 'undefined') {
            depenses += tabDepenses[age].total;
        }

        return(depenses);
    }

		//*********************************
    calculerHypotheseBNA(age, tabRecettes, tabTravaux, tabDepenses, tauxActualisation) {

        var totalSommesActualisees = 0;
        for(var cptAges = 0; cptAges <= age; cptAges++) {
            var recettes = 0;
            if (cptAges == age) {
                recettes = tabRecettes[cptAges].recettePotentielleCR;
                if (tabRecettes[cptAges].recetteEclaircie) {
                    recettes += tabRecettes[cptAges].recetteEclaircie;
                    // return(-1); // si on considère qu'on ne peut pas faire de coupe rase l'année d'une éclaircie ....
                }
            }
            else {
                recettes = tabRecettes[cptAges].recetteEclaircie;
            }
            var depenses = 0;
            if (typeof(tabTravaux[cptAges]) !== 'undefined') {
                depenses += tabTravaux[cptAges].total;
            }
            if (typeof(tabDepenses[cptAges]) !== 'undefined') {
                depenses += tabDepenses[cptAges].total;
            }
            var unPlusRPuissanceI = Math.pow(1+tauxActualisation, cptAges);
            var sommeActualisee = (recettes-depenses)/unPlusRPuissanceI;
            totalSommesActualisees += sommeActualisee;
        }
        return(totalSommesActualisees);
    }

		//*********************************
    calculerHypotheseValeurAttente(age, tabRecettes, tabTravaux, tabDepenses, tauxActualisation, anneeCoupeRase) {

        var totalSommesActualisees = 0;
        var identifiant = this.ppl.uf.identifiant;
        var anneeCourante = this.ppl.anneeCourante;
        for(var cptAges = age; cptAges <= anneeCoupeRase; cptAges++) {
            var recettes = 0;
            if (tabRecettes[cptAges].recetteEclaircie) {
                recettes = tabRecettes[cptAges].recetteEclaircie;
            }
            else {
                if (cptAges == anneeCoupeRase) {
                    recettes = tabRecettes[cptAges].recettePotentielleCR;
                }
            }

            var depenses = 0;
            if (typeof(tabTravaux[cptAges]) !== 'undefined') {
                depenses += tabTravaux[cptAges].total;
            }
            if (typeof(tabDepenses[cptAges]) !== 'undefined') {
                depenses += tabDepenses[cptAges].total;
            }
            //var unPlusRPuissanceNmoinsM = Math.pow(1+tauxActualisation, anneeCoupeRase-cptAges);
            var unPlusRPuissanceImoinsM = Math.pow(1+tauxActualisation, cptAges-age);
            //var sommeActualisee = (recettes-depenses)/unPlusRPuissanceNmoinsM;
            var sommeActualisee = (recettes-depenses)/unPlusRPuissanceImoinsM;
            totalSommesActualisees += sommeActualisee;
            if (identifiant == "Semis abandonné") {
                if (age == anneeCourante-1) {
                    var toto = 1;
                }
            }
        }
        if (identifiant == "Semis abandonné") {
            if (age == anneeCourante-1) {
                var toto = 1;
            }
        }
        return(totalSommesActualisees);
    }


		//*********************************
    calculerTIR(age) {
        var tabRecettes = this.tabValeursCalculeeS;
        var tabTravaux = this.travaux;
        var tabDepenses = this.depenses;
        var TIRhaut = 0; // on ne veut pas des TIRs haut et bas trop faible
        var TIRbas = 0;
        var TIR = TIRhaut;

        // si on n'est en perte avec un taux d'actualisation nul, on n'insiste pas
        var totalSommesActualisees = this.calculerHypotheseBNA(age, tabRecettes, tabTravaux, tabDepenses, TIR);
        if (totalSommesActualisees < 0) {
            return(-1);
        }

        var i = 0;
        var affinage = 1;
        var decrement = 0.005;
        while (Math.round(totalSommesActualisees*100)/100) { // et c'est parti pour une belle dichotomie

            if (totalSommesActualisees < 0) { // on y est allé trop fort !
                TIRhaut = TIR;
                //TIRbas -= (TIR - TIRbas) / 2;
                //TIRbas = Math.max(0, TIRbas - 0.01*affinage);
            }
            else {
                //TIRbas = (TIRbas + TIR) / 2;
                //TIRhaut += (TIRhaut - TIR) / 2;
                //TIRhaut = (TIRhaut + TIR) / 2;
                if (TIR) {
                    TIRhaut += (TIRhaut-TIR);
                }
                else {
                    TIRhaut += 0.02*affinage;
                }
                //TIRbas = (TIRbas+TIR)/2;
                TIRbas = TIR;
            }
            TIR = (TIRhaut + TIRbas) / 2;
            totalSommesActualisees = this.calculerHypotheseBNA(age, tabRecettes, tabTravaux, tabDepenses, TIR);
            affinage -= decrement;

            if (++i == 100) {
                console.log("calculerTIR : impossible -> ", age);
                return(-1);
            }
        }
        return(TIR*100); // en %


    }

		//*********************************
    calculerACE(age) {
        //var tauxActualisation = this.ppl.lireTauxActualisation();
        var tauxActualisation = this.tauxActualisation;
        var BNA = this.tabValeursCalculeeS[age].BNA

        /*
        if (BNA <= 0) {
            return(-1);
        }
        */
        var unPlusRPuissanceN = Math.pow(1+tauxActualisation, age);
        if (unPlusRPuissanceN == 1) { // ne pas diviser par 0
            return -1;
        }
        var ACE = BNA * (unPlusRPuissanceN/(unPlusRPuissanceN-1)) * (tauxActualisation);

        return(ACE);
    }

    //*********************************
    arrondir = function(valeur, puissance = 3) {
        if ((typeof(valeur) === 'undefined') || isNaN(valeur)) {
            valeur = 0;
        }
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

    formaterEuros = function(valeur) {
        return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
    }

		//*********************************
    calculerIntervalle = function(min, max) {
        //min = 0; // tmp
        var intervalle = Math.trunc(max-min);
        var chaineIntervalle = ""+intervalle;
        var deltaIntervalle = 2;
        if (chaineIntervalle.length >= 2) {
            deltaIntervalle = Math.pow(10, chaineIntervalle.length-2)*parseInt(chaineIntervalle[0])*2;
        }
        return(deltaIntervalle)
    }

		//*********************************
    initialiserCourbes = function() {

        UfPplFinancierLocal = this;
        var tabValeursAffichees = this.tabValeursCalculeeS.filter(this.filtrerValeursFinancieres);
        this.tabValeursAffichees = tabValeursAffichees;

        var courbe = {"negatifPositif":1, "idCourbe":"f_", "debutValide":this.dendrometrie.ageDonneesValides, "anneeCourante":this.ppl.anneeCourante, "coupeRase":0, "anneeDuJour":this.ppl.anneeDateInstallation+this.ppl.anneeCourante-1, "infos" : {"titre":"Evolution par années", "classe":"multi", "axeX":"Age", "donneesX":"ans", "intervalleX":10, "debutX":this.ppl.anneeCourante-1, "minY":0}, "infosY" : [
            {"libelleAxeY":"BNA", "uniteLibelleAxeY":"(en € / ha)", "xAxeY":"droite", "donneesY":"€", "intervalleY":this.calculerIntervalle(this.minBNA, this.maxBNA), "max":this.maxBNA, "min":this.minBNA},
            {"libelleAxeY":"ACE", "uniteLibelleAxeY":"(en € / ha)",  "xAxeY":"gauche", "donneesY":"€", "intervalleY":this.calculerIntervalle(this.minACE, this.maxACE), "max":this.maxACE, "min":this.minACE},
            {"libelleAxeY":"TIR", "uniteLibelleAxeY":"(en %)",  "xAxeY":"gauche", "donneesY":"%", "intervalleY":this.calculerIntervalle(this.minTIR, this.maxTIR), "max":this.maxTIR, "min":this.minTIR, "positif":1}
            ],
        "datas": [],
        "montrerAnnees":true
        }; // todo MAXs en base


        if (this.ppl.it) {
            courbe.infos.titre = this.ppl.it.identifiant;
            this.coupeRase = this.ppl.it.coupeRase;
            courbe.coupeRase = this.coupeRase;
        }
        var coupeRaseACE = false;
        var coupeRaseBNA = false;
        var coupeRaseTIR = false;

        var negatifPositif = 0;
        var incrementTab = 0;
        //for(var cptAns = this.ppl.anneeCourante-1; cptAns <= ageMax; cptAns++) {
        for(var cptAns = 0; cptAns < tabValeursAffichees.length; cptAns++) {
            var item = {};
            item.coupeRase = false;
            var items = [];
            item.itemsY = items;
            items[0] = {};
            items[1] = {};
            items[2] = {};
//            items[3] = {};
            var anneeReelle = cptAns+this.ppl.anneeCourante-1;
            item.x = anneeReelle;
            items[0].y = this.arrondir(tabValeursAffichees[cptAns].BNA, 2);
            item.coupeRaseBNA = false;
            if (!coupeRaseBNA && (tabValeursAffichees[cptAns].BNA == this.optimumBNA.valeur)) {
                coupeRaseBNA = true;
                item.coupeRaseBNA = true;
            }
            if (items[0].y < 0) {
                //items[0].yinconnu = true;
                negatifPositif = 1;
            }
            items[1].y = this.arrondir(tabValeursAffichees[cptAns].ACE, 2);
            item.coupeRaseACE = false;
            if (!coupeRaseACE && (tabValeursAffichees[cptAns].ACE == this.optimumACE.valeur)) {
                coupeRaseACE = true;
                item.coupeRaseACE = true;
            }
            if (items[1].y < 0) {
                //items[1].yinconnu = true;
                negatifPositif = 1;
            }
            items[2].y = this.arrondir(tabValeursAffichees[cptAns].TIR);
            item.coupeRaseTIR = false;
            if (!coupeRaseTIR && (tabValeursAffichees[cptAns].TIR == this.optimumTIR.valeur)) {
                coupeRaseTIR = true;
                item.coupeRaseTIR = true;
            }
            if (items[2].y < 0) {
                //items[1].yinconnu = true;
                negatifPositif = 1;
            }
/*
            items[3].y = this.arrondir(Math.max(0, tabValeursAffichees[cptAns].recettePotentielleCR));
            if (items[3].y < 0) {
                items[3].yinconnu = true;
                negatifPositif = 1;
            }
*/
            // est-ce qu'on a une mesure ou une eclaircie sur l'année
            item.mesure = false;
            if (typeof(this.dendrometrie.mesures[anneeReelle]) !== 'undefined') {
            item.mesure = true;
            }
            item.eclaircie = false;
            if (typeof(this.dendrometrie.eclaircies[anneeReelle]) !== 'undefined') {
                item.eclaircie = true;
                if (this.dendrometrie.eclaircies[anneeReelle].fromIT || (typeof(this.dendrometrie.eclaircies[anneeReelle].proposition) !== 'undefined')) {
                    item.eclaircieIT = true;
                }
            }
            if (this.coupeRase && (anneeReelle == this.coupeRase)) {
                item.coupeRase = true;
            }

            courbe.datas[incrementTab] = item;
            incrementTab++;
        }


        courbe.negatifPositif = negatifPositif;
        return(courbe);
    }


//*********************************
    filtrerValeursFinancieres = function(valeur, index) {
        return ((index <= UfPplFinancierLocal.ageMaxTest) && (index >= UfPplFinancierLocal.ppl.anneeCourante-1))
    }

//*********************************
    initialiserCourbesComparaison = function(objFinancier1, objFinancier2, ratio) {

        UfPplFinancierLocal = objFinancier1;
        var tabValeursAffichees1 = objFinancier1.tabValeursCalculeeS.filter(this.filtrerValeursFinancieres);
        objFinancier1.tabValeursAffichees = tabValeursAffichees1;
        UfPplFinancierLocal = objFinancier2;
        var tabValeursAffichees2 = objFinancier2.tabValeursCalculeeS.filter(this.filtrerValeursFinancieres);
        objFinancier2.tabValeursAffichees = tabValeursAffichees2;

        var minBNA = Math.min(objFinancier1.minBNA, objFinancier2.minBNA);
        var maxBNA = Math.max(objFinancier1.maxBNA, objFinancier2.maxBNA);
        var minACE = Math.min(objFinancier1.minACE, objFinancier2.minACE);
        var maxACE = Math.max(objFinancier1.maxACE, objFinancier2.maxACE);
        var minTIR = Math.min(objFinancier1.minTIR, objFinancier2.minTIR);
        var maxTIR = Math.max(objFinancier1.maxTIR, objFinancier2.maxTIR);
        var minVolume = Math.min(objFinancier1.minVolume, objFinancier2.minVolume);
        var maxVolume = Math.max(objFinancier1.maxVolume, objFinancier2.maxVolume);
        var minVolumeUnitaire = Math.min(objFinancier1.minVolumeUnitaire, objFinancier2.minVolumeUnitaire);
        var maxVolumeUnitaire = Math.max(objFinancier1.maxVolumeUnitaire, objFinancier2.maxVolumeUnitaire);

        var tabInfosY = [];
        tabInfosY["BNA"] = {"libelleAxeY":"BNA / ha", "uniteLibelleAxeY":"(en €)", "xAxeY":"gauche", "donneesY":"€", "intervalleY":this.calculerIntervalle(minBNA, maxBNA), "max":maxBNA, "min":minBNA};
        tabInfosY["ACE"]  = {"libelleAxeY":"ACE / ha", "uniteLibelleAxeY":"(en €)",  "xAxeY":"gauche", "donneesY":"€", "intervalleY":this.calculerIntervalle(minACE, maxACE), "max":maxACE, "min":minACE};
        tabInfosY["TIR"]  = {"libelleAxeY":"TIR / ha", "uniteLibelleAxeY":"(%)",  "xAxeY":"gauche", "donneesY":"%", "intervalleY":this.calculerIntervalle(minTIR, maxTIR), "max":maxTIR, "min":minTIR};
        tabInfosY["volume"]  = {"libelleAxeY":"Volume / ha", "uniteLibelleAxeY":"(en m3)",  "xAxeY":"gauche", "donneesY":"m3", "intervalleY":this.calculerIntervalle(minVolume, maxVolume), "max":maxVolume, "min":minVolume};
        tabInfosY["volumeUnitaire"]  = {"libelleAxeY":"Volume Unitaire", "uniteLibelleAxeY":"(en m3)",  "xAxeY":"gauche", "donneesY":"m3", "intervalleY":this.calculerIntervalle(minVolumeUnitaire, maxVolumeUnitaire), "max":maxVolumeUnitaire, "min":minVolumeUnitaire};

        var courbe = {"code":"COMP", "negatifPositif":1, "idCourbe":"f_", "debutValide":this.dendrometrie.ageDonneesValides, "anneeCourante":this.ppl.anneeCourante, "infos" : {"classe":"comp", "axeX":"Age", "donneesX":"ans", "intervalleX":10, "debutX":this.ppl.anneeCourante-1, "minY":0}, "infosY" : [tabInfosY[ratio], tabInfosY[ratio]  // GARDER LES DEUX !!!
        ], "datas": []}; // todo MAXs en base

        var arrondi = 2;
        if ((ratio == "volume") || (ratio == "volumeUnitaire")) {
            var arrondis = JSON.parse(this.constantesSteres.arrondisJson);
            arrondi = arrondis.arrondiVolumeM3;
        }

        //courbe.infos.titre = objFinancier1.ppl.it.identifiant + " / " + objFinancier2.ppl.it.identifiant;
        courbe.infos.titre1 = objFinancier1.ppl.it.identifiant;
        courbe.infos.titre2 = objFinancier2.ppl.it.identifiant;

        var negatifPositif = 0;
        var incrementTab = 0;
        for(var cptAns = 0; cptAns < tabValeursAffichees1.length; cptAns++) { // tabValeursAffichees1 et tabValeursAffichees2 ont la même taille
            var item = {};
            var items = [];
            item.itemsY = items;
            items[0] = {};
            items[1] = {};
            /*
            items[2] = {};
            items[3] = {};
            */
            var anneeReelle = cptAns+this.ppl.anneeCourante-1;
            item.x = anneeReelle;
            items[0].y = this.arrondir(tabValeursAffichees1[cptAns][ratio], arrondi);
            if (items[0].y < 0) {
                //items[0].yinconnu = true;
                negatifPositif = 1;
            }
            items[1].y = this.arrondir(tabValeursAffichees2[cptAns][ratio], arrondi);
            if (items[1].y < 0) {
                //items[1].yinconnu = true;
                negatifPositif = 1;
            }


            // est-ce qu'on a une eclaircie sur l'année
            item.eclaircie1 = false;
            item.eclaircie2 = false;
            if (typeof(objFinancier1.dendrometrie.eclaircies[anneeReelle]) !== 'undefined') {
            item.eclaircie1 = true;
            }
            if (typeof(objFinancier2.dendrometrie.eclaircies[anneeReelle]) !== 'undefined') {
            item.eclaircie2 = true;
            }

            courbe.datas[incrementTab] = item;
            incrementTab++;
        }
        courbe.negatifPositif = negatifPositif;
        return(courbe);
    }


}

export const UfPplFinancier = new UF_PPL_FINANCIER()
export const UfPplFinancier1 = new UF_PPL_FINANCIER()
export const UfPplFinancier2 = new UF_PPL_FINANCIER()
var UfPplFinancierLocal = null;
