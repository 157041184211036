<template>
 <div>

<v-dialog v-model="dialog"  persistent>
      <v-card>
        <v-card-title class="headline">Modifier un tarif</v-card-title>

        <v-card-text>
          Saisissez le tarif qui correspond au prix unitaire.
        </v-card-text>

          <div class="modaleConteneur" v-if="ready">

              <div >
                <span id="leMessage" class="pour-v-text-field messageError cacher" >Avant de valider à nouveau, vous devez saisir un tarif !</span>
                <v-text-field
                    label="Volume Unitaire (m3)"
                    v-model="tarif.volumeMoyen"
                    type="number"
                    readonly
                    disabled
                ></v-text-field>
                <v-text-field
                    label="Tarif associé (en €)"
                    v-model="tarif.prixM3"
                    type="number"
                     @keydown="filtrerNombresPositifs"
                    min=0
                    ref="refTarif"
                ></v-text-field>
            </div>

        </div>




        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  text @click="annulerSerie()">Annuler</v-btn>
          <v-btn  text @click="validerSerie()">Valider</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

</div>
</template>


<script>


import { ApiTools } from "../../js/api/index.js"


export default {

 //   mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        initDatas: {
            type: Object
        },
        ouvrirDialog : Boolean,
    },

    data() {
        return {
            dialog: this.ouvrirDialog,
            tarif: JSON.parse(JSON.stringify(this.initDatas)),
            ready:false,
        }
    },

    created() {
        this.ready = true;
    },

    mounted() {
        // on donne le focus
        setTimeout(this.donnerFocus.bind(), 100);
    },

    validations() {
    },

    computed: {

    },

    methods: {
        donnerFocus() {
            this.$nextTick(() => {
                this.$refs.refTarif.focus();
            })
        },


        validerSerie() {

            var ok = true;

            var eltMessage = document.getElementById("leMessage");
            if ((this.tarif.volumeMoyen == "") || (this.tarif.prixM3 == "")) {
                ok = false;
                ApiTools.toggleClassElt(eltMessage, 'removeClass', "cacher");
                }
            else {
                var leNumber = parseFloat(this.tarif.volumeMoyen);
                this.tarif.volumeMoyen = leNumber;
                leNumber = parseFloat(this.tarif.prixM3);
                this.tarif.prixM3 = leNumber;
                ApiTools.toggleClassElt(eltMessage, 'addClass', "cacher");
                }

            if (ok) {
                this.$emit('retourTarifsSerie', this.tarif)
            }
        },
        annulerSerie() {
            this.dialog = false;
            this.$emit('retourTarifsSerie', null)
        },

        // pour la saisie
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#app {
        .modaleConteneur {
            .v-input.v-text-field {
                width: 40%;
                margin-right: 5%;
                display:inline-block;
            }
        }
    }
</style>
