import { SteresDB } from '../db/SteresDB.js'

/* @ngInject */
class ApiConstsObj {

    constructor() {

    }

// constantes


    // ************************
    countCommunesFromTable() {
        return SteresDB.countInTable('id', 'datas_communes');
    }
    // ************************
    readCommunesFromTable() {
        return SteresDB.getDbConnection()
        .then(db => {
            SteresDB.dbSelect = db;
            var t = SteresDB.tables['datas_communes']
            var query = db.select(t.codeComm, t.codePostal, t.libelle).from(t).exec()
            return Promise.resolve(query);
        })
    }

    // ************************
    getDatasCommunes() {
        return SteresDB.readAllFromTable('datas_communes');
    }
    // ************************
    getDatasCommunesSearch(search) {
        var tabChamps = ["codePostal", "libelle"];
        var limit = 20;
        return SteresDB.searchInTable(search, limit, tabChamps, 'datas_communes')
    }
    // ************************ BUG mais pas appellé : search non défini
    getCommuneCodeComm(codeComm) {
        var tabChamps = ["codeComm"];
        var limit = 1;
        return SteresDB.searchInTable(search, limit, tabChamps, 'datas_communes')
    }
    // ************************
    getConstSteres() {
        return SteresDB.readAllFromTable('const_steres')
    }
    // ************************
    getConstEssences() {
        return SteresDB.readAllFromTable('const_essences')
    }
    // ************************
    getConstEssenceCode(codeEssence) {
        return SteresDB.readInTable(codeEssence, 'code', 'const_essences')
            .then(resultsArray => {
                return resultsArray.pop()
            });
    }

    // ************************
    getConstCultures() {
        return SteresDB.readAllFromTable('const_cultures')
    }
    // ************************
    getConstDepenses() {
        return SteresDB.readAllFromTable('const_depenses')
    }

    // ************************
    getConstEssencesCultures() {
        return SteresDB.readAllFromTable('const_essences_cultures')
    }

    // ************************
    getConstEssenceCultures(essenceId) {
        return SteresDB.readInTable(essenceId, 'essenceId', 'const_essences_cultures')
    }

    // ************************
    getConstTerroirs() {
        return SteresDB.readAllFromTable('const_terroirs')
    }

    // ************************
    getConstEssencesCulturesTerroirs() {
        return SteresDB.readAllFromTable('const_essences_cultures_terroirs')
    }

    // ************************
    getConstEssenceCulturesTerroirs(essenceId) {
        return SteresDB.readInTable(essenceId, 'essenceId', 'const_essences_cultures_terroirs')
    }
    // ************************
    getConstEssenceTarifs(essenceId) {
        return SteresDB.readInTable(essenceId, 'essenceId', 'const_tarifs')
    }

    // ************************
    getCalcEclaircies() {
        return SteresDB.readAllFromTable('calc_eclaircies')
    }

    // ************************
    getCalcBobi() {
        return SteresDB.readAllFromTable('calc_bobi')
    }

    // ************************
    async getConstantesPourEssence(essenceCode) {
        return(await this.getConstEssences()
            .then(response => {
                var constantes = new Object;
                constantes.const_essences = response;
        return(this.getConstEssenceCode(essenceCode)
            .then(response => {
                var constEssence = response;
            /*
                var constEssences = response;
                    // pour accès direct
                //var thisObj = this;
                constEssences.forEach(essence => {
                    constEssences[essence.code] = essence;
                })
            */
                //constantes.const_essences = constEssences;
                constantes.const_essence = constEssence;

                return(this.getConstEssenceTarifs(constEssence.id)
                .then(response => {
                    constantes.const_tarifs = response;

                    return(this.getConstDepenses()
                    .then(response => {
                        var constDepenses = response;
                        // pour accès direct
                        constDepenses.forEach(depense => {
                            constDepenses[depense.code] = depense;
                            constDepenses["id"+depense.id] = depense;
                        })
                        constantes.const_depenses = constDepenses;

                        return(this.getConstCultures()
                        .then(response => {
                            var constCultures = response;
                            // pour accès direct
                            constCultures.forEach(culture => {
                                constCultures[culture.code] = culture;
                                constCultures["id"+culture.id] = culture;
                            })
                            constantes.const_cultures = constCultures;

                            //return(this.getConstEssencesCultures()
                            return(this.getConstEssenceCultures(constEssence.id)
                            .then(response => {
                                constantes.const_essences_cultures = response;

                                return(this.getConstTerroirs()
                                .then(response => {
                                    var constTerroirs = response;
                                    // pour accès direct
                                    constTerroirs.forEach(terroir => {
                                        constTerroirs[terroir.code] = terroir;
                                        constTerroirs["id"+terroir.id] = terroir;
                                    })
                                    constantes.const_terroirs = constTerroirs;

                                    //return(this.getConstEssencesCulturesTerroirs()
                                    return(this.getConstEssenceCulturesTerroirs(constEssence.id)
                                    .then(response => {
                                        constantes.const_essences_cultures_terroirs = response;
                                        return(constantes);
                                    }))
                                }))
                            }))
                        }))
                    }))
                }))
            }))
            }))
    }

    // ************************
    renseignerEssence(constantes, cultureCode, essenceCode, terroirCode) {
        //var essence = constantes.const_essences[essenceCode];
        var essence = constantes.const_essence;
        var culture = constantes.const_cultures[cultureCode];
        var terroir = constantes.const_terroirs[terroirCode];

        // la hauteur de fin d'installation
        constantes.const_essences_cultures.forEach(essence_culture => {
            if (essence_culture.essenceId == essence.id && essence_culture.cultureId == culture.id) {
                essence.hauteurFinInstallation = essence_culture.hauteurFinInstallation;
                essence.borneMesureAutorisee = essence_culture.borneMesureAutorisee;
                essence.libelleCulture = culture.libelle;
            }
        })
        // le HMAX
        constantes.const_essences_cultures_terroirs.forEach(essence_culture_terroir => {
            if ((essence_culture_terroir.essenceId == essence.id) && (essence_culture_terroir.cultureId == culture.id) && (essence_culture_terroir.terroirId == terroir.id)) {
                essence.HMAX = essence_culture_terroir.HMAX;
            }
        })

        // et on passe les tarifs au passage
        essence.tarifs = constantes.const_tarifs;

        return(essence);
    }
    renseignerEssenceId(constantes, cultureId, terroirId) {
        var essence = constantes.const_essence;
        var culture = constantes.const_cultures["id"+cultureId];
        var terroir = constantes.const_terroirs["id"+terroirId];
        if (typeof(terroir) === 'undefined') {
            var constTerroirs = constantes.const_terroirs;
            constTerroirs.forEach(iterTerroir => {
                    constTerroirs[iterTerroir.code] = iterTerroir;
                    constTerroirs["id"+iterTerroir.id] = iterTerroir;
                })
            var terroir = constantes.const_terroirs["id"+terroirId];
            }

        // la hauteur de fin d'installation
        constantes.const_essences_cultures.forEach(essence_culture => {
            if (essence_culture.essenceId == essence.id && essence_culture.cultureId == culture.id) {
                essence.hauteurFinInstallation = essence_culture.hauteurFinInstallation;
                essence.borneMesureAutorisee = essence_culture.borneMesureAutorisee;
                essence.libelleCulture = culture.libelle;
            }
        })
        // le HMAX
        constantes.const_essences_cultures_terroirs.forEach(essence_culture_terroir => {
            if ((essence_culture_terroir.essenceId == essence.id) && (essence_culture_terroir.cultureId == culture.id) && (essence_culture_terroir.terroirId == terroir.id)) {
                essence.HMAX = essence_culture_terroir.HMAX;
            }
        })

        // et on passe les tarifs au passage
        essence.tarifs = constantes.const_tarifs;

        return(essence);
    }

}

export const ApiConsts = new ApiConstsObj()
