<style>
/*
    @import './Courbes.css';
*/
</style>

<template>

  <div class="home containerLog">
    <div class="bandeauInterne">
        <h1 >Bienvenue sur<span class="cacher">STERES</span><br/>
                    <img class="logoSteres" alt ="STERES" src="@/assets/img/steresV321.png" /></h1>
        <p class="intro"><span class="sigle">STERES</span> est votre application de<span class="debut"> </span><span class="sigle">S</span>imulations  <span class="sigle">T</span>echnico <span class="sigle">E</span>conomiques de la <span class="sigle">R</span>entabilité des <span class="sigle">E</span>xploitations <span class="sigle">S</span>ylvicoles.<span class="fin">Elle est réalisée <span class="maj">par</span> des sylviculteurs <span class="maj">pour</span> des sylviculteurs</span></p>
    </div>
    <div class="wrapperIllustration sectionAffichage">
        <div class="conteneur">

            <div v-if="!$store.getters.connexion">
                <home-login :connexionUtilisateur='etatConnexionUtilisateur' @transfertConnexion='rafraichirConnexion'></home-login>

                    <div class="illustration illustration0"></div>
            </div>

            <div v-if="$store.getters.connexion">
                Connexion OK
            </div>

        </div>
    </div>

  </div>

</template>

<script>

    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'

    //import { courbeSVG } from "../js/svg/svgCourbes.js"
    import HomeLoginForm from '../components/HomeLoginForm.vue'

export default {

    name: 'home',
    components: {
        //HelloWorld
        'home-login': HomeLoginForm
    },
    data() {
        return {
          //  svgContent: "",
        }
    },
    created() {
        if (this.etatConnexionUtilisateur) {
            //alert("Connecté"); TODOFORET
            if (this.$store.getters.modeForet) {
                this.$router.push({ name: "propriete" });
            }
            else {
                this.$router.push({ name: "proprietes" });
            }
        }
        else {
            //alert("Home.vue");
        }
    },
    mounted: function () {
    },
    updated: function () {
      //   console.log("courbeSVG.maj");
      //  courbeSVG.maj();
    },
    computed: {
        etatConnexionUtilisateur() {
            // this.reinitialiserUtilisateur(); // pour obliger la "relance" de la computed datasApplication()
            return(this.$store.getters.connexion)
        },
      /*  contentSVG () {
            return this.svgContent
        }
        */
    },
    methods: {
        rafraichirConnexion(status) {
            //this.authenticated = status;
            this.$store.commit('setConnexion', status);
            if (status) {
                this.goConnected();
            }
        },
        /*
        reinitialiserUtilisateur() {
            this.$store.commit('setProprieteId', 0);
            this.$store.commit('setUfId', 0);
            this.$store.commit('resetDataApplication');
        },
        goHome() {
            this.$router.push({ name: "home" });
        },
        */
        goConnected() {
            this.$router.push({ name: "proprietes" });
        },
        /*
        reinitialiserUtilisateur() {
            this.$store.commit('setProprieteId', 0);
            this.$store.commit('setUfId', 0);
            this.$store.commit('resetDataApplication');
        }
        */
    }
}


</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {
    .home {
        background-color: white;

        .bandeauInterne {
            padding-top:2em;
            background-position: center center;
            padding-left: 2em;
            padding-right: 2em;

            h1 {
                margin-top:0;
                margin-bottom:0.6em;
                .logoSteres {
                    width:90%;
                    max-width: 321px;
                    margin: 0.8em auto 0.4em;
                }
            }
            span.debut, span.fin {
                display:block;
            }
            span.fin {
                margin-top:0.5em;
            }
            span.maj {
                text-transform: uppercase;
            }
            span.sigle {
                font-weight: bold;
                font-size: 1.1em;
            }
            p {
                padding-bottom:1em;
                font-style:italic;
            }
        }

        .conteneur {
            p.intro {
                font-size:1.1em;
                margin-bottom: 3em;
            }
            #homeLogin {
                .edition {
                    @media screen and (max-width: 1300px) {
                        max-width: 360px;
                        margin:0 auto;
                    }
                }
            }
        }
    }
}
</style>
