<template>

    <div id="navArianne"  v-bind:class="{modeForetArianne : this.$store.getters.modeForet}">
        <span class="routeEnCours" v-if="!this.data.connexion"  to="/">Accueil</span>

        <span v-if="this.data.connexion">
                <!-- VISUALISATION DE PROPRIETES -->
            <span class="routeEnCours" v-if="!this.data.proprieteId && this.$route.name == 'proprietes'" to="/proprietes">Mes propriétés</span>
                <!-- VISUALISATION DE PROPRIETES MAIS PAGE DE TYPE CREDITS -->
            <router-link to="/proprietes" v-if="!this.data.proprieteId && this.$route.name != 'proprietes' && !$store.getters.modeForet" >Mes propriétés</router-link>
                <!-- VISUALISATION DE PROPRIETES en MODE FORET une fois pour toutes -->
            <span class="routeEnCours modeForet" v-if="$store.getters.modeForet" to="/proprietes">Mes propriétés</span>

            <!-- PROPRIETE ET EDITION DE PROPRIETE -->
            <span v-if="this.data.proprieteId && !this.data.ufId">
                <router-link v-if="!$store.getters.modeForet"  to="/proprietes">Mes propriétés</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <!-- CREATION DE PROPRIETE -->
                <span class="routeEnCours" v-if="this.data.proprieteId == -1 && this.$route.name == 'proprieteedition'">Création d'une propriété</span>
                <!-- EDITION DE PROPRIETE OU PAGE DE TYPE CREDITS -->
                <span v-if="this.data.proprieteId > 0 && this.$route.name != 'propriete'">
                    <router-link to="/propriete">
                            {{nomPropriete}}
                    </router-link>
                    <span  v-if="this.$route.name == 'proprieteedition'">
                        <span><i class="fas fa-angle-right"></i></span>
                        <span class="routeEnCours" >Edition de la propriété</span>
                    </span>
                </span>
                <!-- VISUALISATION DE PROPRIETE -->
                <span class="routeEnCours" v-if="$route.name == 'propriete'">
                    {{nomPropriete}}
                </span>
            </span>

            <span v-if="this.data.ufId && !this.data.peuplementId">
                <router-link v-if="!$store.getters.modeForet"   to="/proprietes">Mes propriétés</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <router-link to="/propriete">{{nomPropriete}}</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <span v-if="this.$route.name == 'ufedition'">
                    <span class="routeEnCours" v-if="this.data.ufId == -1">
                        Création d'une Unité Forestière
                    </span>
                    <span class="routeEnCours" v-if="this.data.ufId > 0">
                        {{this.$store.getters.infosUf.identifiant}}
                    </span>
                </span>
                <router-link to="/ufedition" class="routeEnCours" v-if="this.$route.name != 'ufedition'">
                    <span v-if="this.data.ufId == -1">
                        Création d'une Unité Forestière
                    </span>
                    <span v-if="this.data.ufId > 0">
                        {{this.$store.getters.infosUf.identifiant}}
                    </span>
                </router-link>
            </span>

            <span v-if="this.data.peuplementId && !this.data.mesureId && !this.data.eclaircieId && !this.data.itEclaircieId">
                <router-link v-if="!$store.getters.modeForet"   to="/proprietes">Mes propriétés</router-link>
                <span v-if="this.data.peuplementId"><i class="fas fa-angle-right"></i></span>
                <router-link v-if="this.data.peuplementId" to="/propriete">{{nomPropriete}}</router-link>
                <span v-if="this.data.peuplementId"><i class="fas fa-angle-right"></i></span>
                <router-link class="routeEnCours" v-if="this.data.peuplementId > 0" to="/peuplement">
                    <span>{{this.$store.getters.infosUf.identifiant}}</span>
                    <span>&nbsp;--&nbsp;</span>
                    <span>{{this.$store.getters.infosPeuplement.anneeRetenue}}</span>
                </router-link>
                <span v-if="this.$route.name == 'peuplement' || this.$route.name == 'peuplementedition'">
                    <span class="routeEnCours" v-if="this.data.peuplementId == -1" to="/peuplement">
                        <span>{{this.$store.getters.infosUf.identifiant}}</span>
                        <span>&nbsp;-&nbsp;</span>
                        <span>Création d'un peuplement</span>
                    </span>
                </span>
                <span v-if="this.$route.name != 'peuplement' && this.$route.name != 'peuplementedition'">
                    <router-link class="routeEnCours" v-if="this.data.peuplementId == -1" to="/peuplementedition">
                        <span>{{this.$store.getters.infosUf.identifiant}}</span>
                        <span>&nbsp;-&nbsp;</span>
                        <span>Création d'un peuplement</span>
                    </router-link>
                </span>
            </span>

            <span v-if="this.data.peuplementId && (this.data.mesureId || this.data.eclaircieId) && !this.data.itEclaircieId">
                <router-link v-if="!$store.getters.modeForet"   to="/proprietes">Mes propriétés</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <router-link to="/propriete">{{nomPropriete}}</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <router-link to="/peuplement">
                    <span>{{this.$store.getters.infosUf.identifiant}}</span>
                    <span >&nbsp;-&nbsp;</span>
                    <span>{{this.$store.getters.infosPeuplement.anneeRetenue}}</span>
                </router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <span class="routeEnCours">
                    <span v-if="this.data.mesureId > 0">Mesure</span>
                    <span v-if="this.data.mesureId == -1">Mesure</span>
                    <span v-if="this.data.eclaircieId > 0">Eclaircie</span>
                    <span v-if="this.data.eclaircieId == -1">Eclaircie</span>
                </span>
            </span>


            <span v-if="this.data.itId && this.data.itEclaircieId">
                <router-link v-if="!$store.getters.modeForet"   to="/proprietes">Mes propriétés</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <router-link to="/propriete">{{nomPropriete}}</router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <router-link to="/peuplement">
                    <span>{{this.$store.getters.infosUf.identifiant}}</span>
                    <span >&nbsp;-&nbsp;</span>
                    <span>{{this.$store.getters.infosPeuplement.anneeRetenue}}</span>
                    <span> / </span>
                    <span>{{lIt}}</span>
                </router-link>
                <span><i class="fas fa-angle-right"></i></span>
                <span class="routeEnCours">
                    <span v-if="this.data.itEclaircieId > 0">Eclaircie</span>
                    <span v-if="this.data.itEclaircieId == -1">Eclaircie</span>
                </span>
            </span>
        </span>
    </div>

</template>

<script>

export default {
    components: {

    },
    props: {
        /**
         * Données à injecter
         */
        data: {
            type: Object
        }
    },
    data() {
        return {
            // init les données du form à partir des props
            datasMenu : this.data,
        }
    },
    created() {
    },
    computed: {
        connexionOK() {
            return(this.data.connexion);
        },
        nomPropriete() {
            var chaine = this.$store.getters.infosPropriete.identifiant;
            if (!chaine) {
                return("");
            }
            if (this.$store.getters.infosPropriete.identifiant.length >= 28) {
                var newChaine = this.$store.getters.infosPropriete.identifiant.slice(0, 25);
                newChaine += "..."
            }
            else {
                return this.$store.getters.infosPropriete.identifiant
            }
            return(newChaine)
        },
        lIt() {
            var peuplement = this.$store.getters.infosPeuplement;
            var itId = this.data.itId;
            var trouve = "Itinéraire technique";
                if (peuplement) {
                peuplement.ITs.forEach(it => {
                    if (it.id == itId) {
                        trouve = it.identifiant;
                    }
                })
            }
            return(trouve);
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
    #app {
        #navArianne {
            width: 100%;
            /*max-width:1000px;*/
            margin: 0;
            padding: 0;
            text-align: left;
            span {
                line-height: 1em;
            }

            /*font-size: 0.8em;*/

            &.modeForetArianne {
                font-size: 0.8em;

                i.fa-angle-right:first-of-type {
                    color:#285745;
                }
            }

            a {
                &.router-link-exact-active {
                /*color: #42b983;*/
                }
                &:hover {
                    color:#285745;
                }
            }
        }

    }
</style>

