<template>

    <div id="navMenu">
        <router-link v-if="!this.data.connexion" to="/tests" >Tests</router-link>
    </div>

</template>

<script>

export default {
    components: {

    },
    props: {
        /**
         * Données à injecter
         */
        data: {
            type: Object
        }
    },
    data() {
        return {
            // init les données du form à partir des props
            datasMenu : this.data,
        }
    },
    created() {

    },
    computed: {
    },
    methods: {
    }
}
</script>

<style lang="scss">

    #navMenu {
        width: 30%;
        max-width: 400px;
        margin: 0;
        padding: 0;
        text-align:right;
        /*
        height : 68px;
        color: #fff;
        */
        a {
        /*
            font-weight: bold;
            color: #fff;
            */
            &.router-link-exact-active {
            color: #42b983;
            }
        }
    }
</style>

