<style>
   @import './Tests.css';
   @import './Courbes.css';
</style>

<template>
    <div class="tests conteneurTable" v-if="testDatasReady">
        <span>Installation : {{ ppl.anneeDateInstallation}}, densité : {{ ppl.densite}}, HMAX de base : {{ ppl.HMAX}}</span><br/>
        <span>Fin installation : age, {{ dataFinInstallation.age}} ({{ ppl.anneeDateInstallation+dataFinInstallation.age}}), hauteur, {{ dataFinInstallation.hauteur}}</span>
            <table>
                <tr>
                    <th >année</th>
                    <th >age</th>
                    <th >HMAX</th>
                    <th >hauteur</th>
                    <th >circonference</th>
                    <th >densite</th>
                    <th >densite éclaircie</th>
                    <th>hauteur avant éclaircie</th>
                    <th>circonference avant éclaircie</th>
                    <th >concurrence</th>
                    <th >mortalité</th>
                    <th >volume unitaire</th>
                    <th >volume</th>
                    <th >surface terrière éclaircie</th>
                    <th >volume récolté éclaircie</th>
                </tr>
                <tr v-bind:class="{anneeCourante : valeur.anneeCouranteClass, mesures: valeur.mesuresClass,   eclaircieProp: valeur.eclairciePropClass,  eclaircie: valeur.eclaircieClass,   concurrence: valeur.concurrenceClass, finInstallation:dataFinInstallation.age==index}"  v-for="(valeur, index) in tabValeursDendrometrique" :key="index" >
                    <td >{{ ppl.anneeDateInstallation+index}}</td>
                    <td >{{ index }}</td>
                    <td >{{ arrondir(valeur.HMAX) }}</td>
                    <td >{{ arrondir(valeur.hauteur) }}</td>
                    <td >{{ arrondir(Math.max(valeur.circonference*100,-1)) }}</td>
                    <td >{{ arrondir(valeur.densite) }}<br/><span v-if="valeur.densiteEclaircie">({{ arrondir(valeur.anticipationDensite) }})</span></td>
                    <td ><span v-if="valeur.densiteEclaircie">{{ arrondir(valeur.densiteEclaircie) }}</span></td>
                    <td ><span v-if="valeur.densiteEclaircie">{{ arrondir(valeur.hauteurAvantEclaircie) }}</span><br/><span v-if="valeur.densiteEclaircie">({{ arrondir(valeur.anticipationHauteur) }})</span></td>
                    <td ><span v-if="valeur.densiteEclaircie">{{ arrondir(valeur.circonferenceAvantEclaircie*100) }}</span><br/><span v-if="valeur.densiteEclaircie">({{ arrondir(valeur.anticipationCirconference*100) }})</span></td>


                    <td >{{ arrondir(valeur.concurrence) }}</td>
                    <td >{{ arrondir(valeur.mortalite,4) }}</td>
                    <td >{{ arrondir(valeur.volumeUnitaire) }}</td>
                    <td >{{ arrondir(valeur.volume) }}</td>
                    <td ><span v-if="valeur.surfaceTerriereEclaircie">{{ arrondir(valeur.surfaceTerriereEclaircie) }}</span></td>
                    <td ><span v-if="valeur.volumeRecolteEclaircie">{{ arrondir(valeur.volumeRecolteEclaircie) }}</span></td>

                </tr>
            </table>
    <div class="containerCourbes" v-html="contentSVG1"></div>


        <span>Age pour coupe rase et valeur de Stock : {{ageValeurDeStock}}<br/>Surface boisée : {{ppl.surfaceBoisee}} ha</span>

            <table class="financier">
                <tr>
                    <th >année</th>
                    <th >age</th>
                    <th >volume / h</th>
                    <th >volume / h récolté éclaircie</th>
                    <th >prix au M3</th>
                    <th >prix au M3 éclaircie</th>
                    <th >recette éclaircie</th>
                    <th >recette potentielle coupe rase</th>
                    <th >BNA</th>
                    <th >ACE</th>
                    <th >valeur de Stock</th>
                </tr>
                <tr v-bind:class="{anneeCourante : valeur.anneeCouranteClass, mesures: valeur.mesuresClass,   eclaircieProp: valeur.eclairciePropClass,  eclaircie: valeur.eclaircieClass,   concurrence: valeur.concurrenceClass, finInstallation:dataFinInstallation.age==valeur.anneeDate- ppl.anneeDateInstallation}"  v-for="(valeur, index) in tabValeursFinancier" :key="index" >
                    <td >{{ valeur.anneeDate }}</td>
                    <td >{{ valeur.anneeDate - ppl.anneeDateInstallation }}</td>
                    <td >{{ arrondir(valeur.volume) }}</td>
                    <td ><span v-if="valeur.volumeRecolteEclaircie">{{ arrondir(valeur.volumeRecolteEclaircie) }}</span></td>
                    <td >{{ formaterEuros(valeur.prixM3) }}</td>
                    <td class="euro"><span v-if="valeur.recetteEclaircie">{{ formaterEuros(valeur.prixEclaircieM3) }}</span></td>
                    <td class="euro"><span v-if="valeur.recetteEclaircie">{{ formaterEuros(valeur.recetteEclaircie) }}</span></td>
                    <td class="euro"><span v-if="valeur.recettePotentielleCR">{{ formaterEuros(valeur.recettePotentielleCR) }}</span></td>
                    <td class="euro"><span v-if="valeur.BNA">{{ formaterEuros(valeur.BNA) }}</span></td>
                    <td class="euro"><span v-if="valeur.ACE">{{ formaterEuros(valeur.ACE) }}</span></td>
                    <td class="euro"><span v-if="valeur.valeurDeStock > 0">{{ formaterEuros(valeur.valeurDeStock) }}</span></td>
                </tr>
            </table>
    <div class="containerCourbes" v-html="contentSVG2"></div>

    </div>
</template>


<script>

import { ApiConsts } from "../js/api/index.js"
import * as SteresConst from '../js/steres_const.js'

import { SteresConstantes } from "../js/calculs/constantes.js"
import { UfPeuplement } from "../js/calculs/peuplementObj.js"
import { UfPplDendrometrique } from '../js/calculs/dendrometriqueObj.js'
import { UfPplFinancier } from '../js/calculs/financierObj.js'

import { courbeSVG } from "../js/svg/svgCourbes.js"

export default {


    data(){
        return {
            datasReady:false,
            constantesReady:false,
            //datasReady:false,
            datas : null,
            constantes : {},
            ppl : {},
            dataFinInstallation : {},
            ageValeurDeStock : 0,
            tabValeurS:[],
            svgContent1: "",
            svgContent2: "",
            courbe1: null,
            courbe2: null,
        }
    },

    created: function () {

        // pour fil d'Ariane
        this.$store.commit('setProprieteId', 0);
        this.$store.commit('setUfId', 0);

        var myComponent = this;

            // on lance la requete (sans cache !)
//console.log("tests : 1");
        var leDomaine = window.location.hostname;
        if (leDomaine.indexOf("localhost") >= 0) {
            leDomaine = SteresConst.DOMAINE_STD;
            }
        var lUrl = "https://" + leDomaine + "/_tests/datas.json";
        //fetch(lUrl, myInit)
        fetch(lUrl, {cache: "reload"})
            .then(function (response) {
//console.log("tests : 2");
                return response.json();
            })
            .then(function (result) {
//console.log("tests : 3");
                myComponent.datas =  result;
                myComponent.datasReady = true;
            })
            .catch(err => {
                alert(err.message);
            });

        return(0);
    },

    mounted: function () {
        return(0);
    },

    updated: function () {
    },

    methods: {

        arrondir: function(valeur, puissance = 3) {
            var puissanceDe10 = Math.pow(10, puissance);
            var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
            return(arrondi);
        },
        formaterEuros: function(valeur) {
            return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
        },


        lancerTests: function (constantesEssences) {

            SteresConstantes.initialiser(this.constantes, this.abaqueEclaircies)

            // les infos "datas" sont lues en json, elles permettent le test sur un "jeux d'essai" et correspondent à de futures saisies
            // on selectionne la bonne essence
            var essence = ApiConsts.renseignerEssence(constantesEssences, this.datas.peuplement.cultureCode, this.datas.peuplement.essenceCode, this.datas.peuplement.terroirCode);

                // et c'est parti !

            // peuplement
            UfPeuplement.initialiser(null, essence, this.datas.peuplement, this.infosPropriete); // todo plusieurs essences

            // tmp : en attendant d'avoir la structure complète
            UfPeuplement.uf.propriete.constantesSteres = SteresConstantes;

            // dendrometrie
            UfPplDendrometrique.initialiser(UfPeuplement, this.datas);

            // pour affichage !
            this.ppl = UfPplDendrometrique.ppl;
            this.tabValeursCalculees = UfPplDendrometrique.tabValeursCalculeeS;
            this.dataFinInstallation = UfPplDendrometrique.infosFinInstallation;

            // et calculs/ratios financiers
            UfPplFinancier.initialiser(UfPplDendrometrique, this.datas);
            // pour affichage !
            this.tabValeursCalculeesFinancier = UfPplFinancier.tabValeursCalculeeS;
            this.ageValeurDeStock = UfPplFinancier.ageValeurDeStock;

            // courbes
            this.courbe1 = UfPplDendrometrique.initialiserCourbes(this.datas.ageMaxTest);
            this.svgContent1 = courbeSVG.initialiserCourbe(this.$store, this.courbe1);
            this.courbe2 = UfPplFinancier.initialiserCourbes(this.tabValeursFinancier/*, this.datas.ageMaxTest*/);
            this.svgContent2 = courbeSVG.initialiserCourbe(this.$store, this.courbe2);
        },

        filtrerValeursFinancieres(valeur, index) {
            return ((index <= this.datas.ageMaxTest) && (index >= this.ppl.anneeCourante-1))
        },

        filtrerValeursDendrometriques(valeur, index) {
            return ((index <= this.datas.ageMaxTest))
        }
    },

    watch: {

        testDatasReady(newValue) {
            if(newValue) {
                var constantes = this.$store.getters.constantes;
                this.constantes = constantes.steres;
                this.abaqueEclaircies = constantes.abaqueEclaircies;
                this.infosPropriete = this.$store.getters.infosPropriete;
                this.svgContent1 = "";
                this.svgContent2 = "";
                this.lancerTests(constantes.params);

                setTimeout(courbeSVG.maj.bind(null, this.courbe1), 1000);
                setTimeout(courbeSVG.maj.bind(null, this.courbe2), 1000);
console.log("datasReady !!!");
            }
        }
    },

    computed: {
        testDatasReady() {
            return(this.$store.getters.constantes.ready && this.datasReady)
        },
        tabValeursFinancier() {
            return(this.tabValeursCalculeesFinancier.filter(this.filtrerValeursFinancieres));
        },
        tabValeursDendrometrique() {
            return(this.tabValeursCalculees.filter(this.filtrerValeursDendrometriques));
        },
        contentSVG1 () {
            return this.svgContent1
        },
        contentSVG2 () {
            return this.svgContent2
        }
    }


}
</script>
