<template>
    <div v-if="testDatasReady && peuplement">

        <div id="itinerairesTechniquesComparaisonParam" class=""   v-if="$parent.$parent.comparerITs">
            <div class="groupeIt choixIt" v-if="!editionEnCours">
                <div id="choixIt">
                    <v-select
                        v-model="itCourant1"
                        item-text="identifiant"
                        item-value="id"
                        :items="listeITs"
                        label="Itinéraire technique"
                        :return-object="true"
                    ></v-select>
                </div>
                <div id="choixIt">
                    <v-select
                        v-model="itCourant2"
                        item-text="identifiant"
                        item-value="id"
                        :items="listeITs"
                        label="Itinéraire technique"
                        :return-object="true"
                    ></v-select>
                </div>
            </div>
        </div>

        <div id="itinerairesTechniquesParam" class="tests conteneurTable"   v-if="!$parent.$parent.comparerITs">
            <div class="groupeIt choixIt" v-if="!editionEnCours">
                <div id="choixIt">
                    <v-select
                        v-model="itCourant"
                        item-text="identifiant"
                        item-value="id"
                        :items="listeITs"
                        label="Itinéraire technique"
                        required
                        minValue=1
                        @change="changedValue"
                    ></v-select>
                </div>
                <span id="optimumSteres" class="coupeRase">
                    <v-text-field
                        label="Coupe Rase *"
                        v-model="laCoupeRase"
                        required
                        ref="coupeRase"
                        class="inactif"
                        disabled
                    ></v-text-field>
                </span>


<!--                <img v-if="itChoisi" class="iconeFct inactif" alt="Choisir l'itinéraire technique" src="@/assets/img/etoile0.svg" /> -->
                <span v-if="itChoisi" class="iconeFct inactif svg"><i class="fa fa-star svg" aria-hidden="true"></i></span>
<!--                <img v-if="!itChoisi && !lectureSeule" class="iconeFct" alt="Choisir l'itinéraire technique" src="@/assets/img/etoile.svg"  @click.stop="choisirItCourant()" /> -->
                <span v-if="!itChoisi && !lectureSeule" class="iconeFct svg"   @click.stop="choisirItCourant()" ><i class="fa fa-star-o svg" aria-hidden="true"></i></span>
<!--                <img v-if="!itAuto && !lectureSeule" class="iconeFct" alt="Editer l'itinéraire technique" src="@/assets/img/crayon.svg"  @click.stop="editerItCourant()" /> -->
                <span v-if="!itAuto && !lectureSeule" class="iconeFct svg"  @click.stop="editerItCourant()" ><i class="fa fa-pencil svg" aria-hidden="true"></i></span>
<!--                <img v-if="dendrometrieReady && !lectureSeule" class="iconeFct cacher" alt="Dupliquer l'itinéraire technique" src="@/assets/img/duplication.svg" @click.stop="dupliquerItCourant()" /> -->
                <span v-if="dendrometrieReady && !lectureSeule" class="iconeFct cacher svg" @click.stop="dupliquerItCourant()" ><i class="fa fa-files-o svg" aria-hidden="true"></i></span>
<!--                <img v-if="!itAuto && !lectureSeule" class="iconeFct" alt="Supprimer l'itinéraire technique" src="@/assets/img/poubelle.svg" @click.stop="supprimerItCourantOuiNon()" /> -->
                <span v-if="!itAuto && !lectureSeule" class="iconeFct svg" @click.stop="supprimerItCourantOuiNon()"><i class="fa fa-trash svg" aria-hidden="true"></i></span>
                <div class="clear"></div>


            </div>

            <div class="groupeIt" v-if="!editionEnCours && !lectureSeule">
                <span class="iconeFct" alt="Dupliquer l'itinéraire technique" @click.stop="dupliquerItCourant()">Dupliquer</span>
                <span class="separateurFct">/</span>
                <span class="iconeFct" alt="Nouvel itinéraire technique" @click.stop="creerItCourant()">Nouveau</span>
            </div>

            <div v-if="editionEnCours" id="editionEnCours" class="boutonsValidation">
                <v-text-field
                    label="Identifiant *"
                    v-model="itCourantObj.identifiant"
                    required
                    ref="identifiant"
                    maxLength="50"
                    :counter="50"
                ></v-text-field>
                <span v-if="datasReady" class="coupeRase">
                    <v-text-field
                        label="Âge à la coupe rase *"
                        v-model="itCourantObj.coupeRase"
                        type="number"
                        @keydown="filtrerNombresEntiersPositifs"
                        :min="anneeMinimumCoupeRase"
                        :max="anneeMaximumCoupeRase"
                        required
                        ref="coupeRase"
                    ></v-text-field>
                </span>

                <v-btn class="a-btn secondaire" type="button" @click.stop="cancelItCourant">Annuler</v-btn>
                <v-btn class="a-btn prioritaire registerBD" type="button" @click.stop="saveItCourant">Enregistrer</v-btn>
            </div>



            <v-dialog v-model="dialog">
                <v-card>
                    <v-card-title class="headline" v-if="!erreurCoupeRase">Suppression</v-card-title>
                    <v-card-title class="headline" v-if="erreurCoupeRase">Erreur de saisie</v-card-title>
                    <v-card-text>
                        {{messageDialog}}
                    </v-card-text>

                    <v-card-actions v-if="!erreurCoupeRase">
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click.stop="dialog=false;">Non</v-btn>
                        <v-btn color="" text @click.stop="supprimerItCourant">Oui</v-btn>
                    </v-card-actions>
                    <v-card-actions v-if="erreurCoupeRase">
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click.stop="dialog=false;erreurCoupeRase=false;">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </div>


    </div>
</template>


<script>

import { ApiConsts, ApiUser, ApiTools } from "../../js/api/index.js"
import * as SteresConst from '../../js/steres_const.js'

import { SteresConstantes } from "../../js/calculs/constantes.js"
import { UfPeuplement } from "../../js/calculs/peuplementObj.js"
//import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObj.js'
import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObjTER.js'
import { UfPplFinancier } from '../../js/calculs/financierObj.js'

import { courbeSVG } from "../../js/svg/svgCourbes.js"

export default {


    props: {
        /**
         * Données à injecter (uf)
         */
        data: Number,
        data2: {
            type: Object
        }
    },

    data(){
        return {
            itCourant: this.data,
            itCourant1: null,
            itCourant2: null,
            itCourantObj: null,
            itCourantSav: null,
            peuplement: this.$store.getters.infosPeuplement,
            datasReady:false,
            editionEnCours:false,
            dialog: false,
            erreurCoupeRase: false,
            messageDialog:"",
            itDupliqueEclaircies: null,
        }
    },

    created: function () {

        return(0);
    },

    mounted: function () {
        this.datasReady = true;
        return(0);
    },

    updated: function () {
    },

    watch: {
        itCourant(newVal) {
            this.majItCourant(newVal);
        },

        testDatasReady(newValue) {
            if(newValue) {
                var constantes = this.$store.getters.constantes;
                this.constantes = constantes;
                this.abaqueEclaircies = constantes.abaqueEclaircies;
                this.infosPropriete = this.$store.getters.infosPropriete;

                this.majItCourant(this.itCourant);
console.log("datasReady !!!");
            }
        },

        itCourant1(newVal) {
            if (newVal) {
                this.transmettreComparaison();
            }
        },
        itCourant2(newVal) {
            if (newVal) {
                this.transmettreComparaison();
            }
        },
        comparerITs(newVal) {
            if (newVal) {
                this.transmettreComparaison();
            }
        },
    },

    computed: {
        lectureSeule() {
            return(this.$store.getters.lectureSeule || this.$store.getters.modeForet)
        },
        anneeMinimumCoupeRase() {
            /*
            var moisBascule = this.$store.getters.constantes.steres.moisBascule;
            var lAnnee = new Date().getFullYear() - this.peuplement.anneeRetenue;
            return(lAnnee+1);
        },
        anneeMinAcceptable() {
        */
            var moisBascule = this.$store.getters.constantes.steres.moisBascule;
            var lAnnee = new Date().getFullYear();
            var moisReel = new Date().getMonth()+1;
            if (moisReel >= moisBascule) {
                lAnnee += 1;
            }
            return lAnnee - this.peuplement.anneeRetenue;
        },
        anneeMaximumCoupeRase() {
            return(this.$store.getters.constantes.params.const_essence.vieMax);
        },
        laCoupeRase() {
           // if (this.itCourantObj)
                return(""+this.itCourantObj.coupeRase+" ans");
        },
        comparerITs() {
            if (this.datasReady) {
                return this.$parent.$parent.comparerITs
            }
            return false;
        },
        testDatasReady() {
            return(this.$store.getters.constantes.ready && this.datasReady)
        },
        itChoisi() {
            return(this.itCourantObj && this.itCourantObj.choisi)
        },
        itAuto() {
            return(this.itCourantObj && this.itCourantObj.auto)
        },
        listeITs() {
            return(this.peuplement.ITs)
        },
        dendrometrieReady() {
            if (this.$store.getters.dendrometrie.ready) {
                return true
            }
            return false
        },
        dendrometrieEclairciesAuto() {
            return this.$store.getters.dendrometrie.eclairciesAuto;
        }
    },

    methods: {
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e));
        },

        choisirItCourant() {
            var trouve = false;
            this.peuplement.ITs.forEach(it => {
                it.choisi = false;
                ApiUser.setIt(it); // on n'attend pas car on n'est pas sur cet IT
                if (it.id == this.itCourant) {
                    trouve = true;
                    it.choisi = true;
                }
            })
            if (trouve) {
                this.itCourantObj.choisi = true;
                this.saveItCourant();
            }
        },
        editerItCourant() {
            this.editionEnCours = true;
        },

        copierEclaircieAuto(eclaircieAuto) {
            var nouvelleEclaircie = {};
            nouvelleEclaircie.id = 0;
            nouvelleEclaircie.itId = 0;
            var dateEclaircie = this.peuplement.anneeRetenue + eclaircieAuto.age;
            nouvelleEclaircie.dateEclaircie = ""+dateEclaircie;
            nouvelleEclaircie.densiteMoyenne = eclaircieAuto.densiteApres;
            nouvelleEclaircie.densiteMethodeId = 4;
            // nouvelleEclaircie.densiteJson = '"{"densite":"'+eclaircieAuto.densiteApres+'"}"'; BUG 2020/04/09 !!!!!!!!!!!!!!!!!
            nouvelleEclaircie.densiteJson = '{"densite":"'+eclaircieAuto.densiteApres+'"}';
            nouvelleEclaircie.hauteurMoyenne = 0;
            nouvelleEclaircie.hauteurJson = "[]";
            nouvelleEclaircie.circonferenceQuadratique = 0;
            nouvelleEclaircie.circonferenceQuadratiqueJson = "[]"
            nouvelleEclaircie.version = 0;
            return(nouvelleEclaircie);
        },

        dupliquerItCourant() {
            var newIt = JSON.parse(JSON.stringify(this.itCourantObj));
            newIt.id = 0;
            newIt.choisi = false;
            newIt.auto = false;
            newIt.enCours = true;
            newIt.identifiant = "(copie) " + newIt.identifiant;
            // gestion des éclaircies de l'IT qu'on duplique
            if (this.itCourantObj.auto) {
                var eclairciesAuto = this.dendrometrieEclairciesAuto;
                if (eclairciesAuto.length) {
                    var thisObj = this;
                    this.itDupliqueEclaircies = [];
                    eclairciesAuto.forEach(eclaircieAuto => {
                        thisObj.itDupliqueEclaircies.push(thisObj.copierEclaircieAuto(eclaircieAuto));
                    })
                }

            }
            else {
                this.itDupliqueEclaircies = newIt.eclaircies;
            }
            newIt.eclaircies = []; // tant qu'on n'a pas sauvé les éclaircies, on fait comme si on n'en avait pas

            this.itCourantSav = JSON.parse(JSON.stringify(this.itCourantObj));
            this.itCourantObj.enCours = false;
            this.itCourantObj = newIt;
            this.itCourant = 0; // induit un maj et une transmission


            this.editionEnCours = true;
        },


        creerItCourant() {
            var itCourant = this.$parent.creerItSteres(this.peuplement);
            this.initItCourant(itCourant);
            this.editionEnCours = true;
        },
        supprimerItCourantOuiNon() {
            this.messageDialog = "Voulez-vous vraiment supprimer cet itinéraire technique ? Cette action est irréversible.";
            this.dialog = true;
        },
        supprimerItCourant() {
            var itCourant = this.itCourant;
            var trouve = null;
            var cpt=0;
            this.peuplement.ITs.forEach(it => {
                if (it.id == itCourant) {
                    trouve = cpt;
                }
                cpt++;
            })
            if (trouve >= 0) {
                var choisi = this.peuplement.ITs[trouve].choisi;
                ApiUser.deleteIt(this.peuplement.ITs[trouve]);
                this.peuplement.ITs.splice(trouve, 1);
                var itObj =  this.peuplement.ITs[0];
                this.itCourant = itObj.id;
                this.itCourantSav = JSON.parse(JSON.stringify(itObj));
                this.itCourantObj = JSON.parse(JSON.stringify(itObj));
                if (choisi) {
                    this.choisirItCourant();
                }
                else {
                    this.transmettrePeuplement(this.peuplement);
                }
            }
            this.dialog = false;
        },
        changedValue(value) {
            var totot = 1;
        },
        initItCourant(itObj) { // it virtuel car pas encore sauvé : on ne touche pas au tableau this.peuplement.ITs
            if (!this.itCourantSav) {
                this.itCourantSav = JSON.parse(JSON.stringify(itObj));
            }
            this.itCourantObj = JSON.parse(JSON.stringify(itObj));
            this.itCourant = itObj.id; // possiblement 0
            return;
        },
        majItCourant(value, itObj = null) {
            var trouve = false;
            var thisObj = this;
            this.peuplement.ITs.forEach(it => {
                it.enCours = false;
                if (it.id == value) {
                    it.enCours = true;
                    if (itObj) {
                        it.identifiant = itObj.identifiant; // suite à une sauvegarde
                        it.coupeRase = itObj.coupeRase; // suite à une sauvegarde
                        it.choisi = itObj.choisi; // suite à une sauvegarde
                    }
                    thisObj.itCourantSav = JSON.parse(JSON.stringify(it));
                    thisObj.itCourantObj = JSON.parse(JSON.stringify(it));
                    thisObj.itCourant = it.id;
                    trouve = true;
                }
            })
            if (!trouve && (value > 0)) {
                this.itCourant = value;
                this.peuplement.ITs.push(JSON.parse(JSON.stringify(this.itCourantObj)));
            }
            if (value >= 0) {
                this.transmettrePeuplement(this.peuplement);
            }
        },
        validerCoupeRase(itObj) {
            var coupeRase = parseInt(itObj.coupeRase);
            if (coupeRase < this.anneeMinimumCoupeRase) {
                //this.messageDialog = "Vous ne pouvez pas programmer une coupe rase dans le passé ni dans l'année courante.";
                this.messageDialog = "Vous ne pouvez pas programmer une coupe rase dans le passé.";
                this.erreurCoupeRase = true;
                this.dialog = true;
                return(0);
            }
            if (coupeRase > this.anneeMaximumCoupeRase) {
                this.messageDialog = "STERES ne vous permet pas de programmer une coupe rase au delà de " + this.anneeMaximumCoupeRase + " ans.";
                this.erreurCoupeRase = true;
                this.dialog = true;
                return(0);
            }
            return(coupeRase);
        }
        ,
        saveItCourant() {
            var entityToSave = JSON.parse(JSON.stringify(this.itCourantObj))
            var thisObj = this;
            var coupeRase = this.validerCoupeRase(entityToSave);
            if (!coupeRase) {
                return;
            }
            this.itCourantObj.coupeRase = coupeRase; // 2022/09 - on veut un int !!!
        // TODO
            entityToSave.coupeRase = coupeRase;
            return ApiUser.setIt(entityToSave) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)
            .then(response => {

                // si on sauve un it qu'on vient de dupliquer, il faut enregistrer toutes ses eclaircies avec le bon id
                if (thisObj.itDupliqueEclaircies) {
                    //var eclaircies = [];
                    var allPromisesEclaircies = [];
                    thisObj.itDupliqueEclaircies.forEach(eclaircie => {
                        eclaircie.id = 0;
                        eclaircie.itId = response;
                        var l = allPromisesEclaircies.length
                        allPromisesEclaircies[l] = ApiUser.setItEclaircie(eclaircie);
                    })
                    thisObj.itDupliqueEclaircies = null;
                    thisObj.itCourantObj.eclaircies = [];

                    return Promise.all(allPromisesEclaircies)
                    .then(responseEclaircies => {
                        responseEclaircies.forEach(eclairciesIT => {
                            thisObj.itCourantObj.eclaircies.push(eclairciesIT);
                        })
                        // on met à jour l'IT sauvée
                        thisObj.itCourantObj.id = response;
                        thisObj.majItCourant(response, thisObj.itCourantObj);
                        // this.transmettrePeuplement(this.peuplement); // inutile, c'est déjà fait dans majItCourant
                        thisObj.editionEnCours = false;
                    })
                }
                else {
                    // on met à jour l'IT sauvé
                    thisObj.itCourantObj.id = response;
                    thisObj.majItCourant(response, thisObj.itCourantObj);
                        // this.transmettrePeuplement(this.peuplement); // inutile, c'est déjà fait dans majItCourant

                    thisObj.editionEnCours = false;
                }
            })
        },

        transmettrePeuplement(peuplement) {
            this.$store.commit('setPeuplementInfos', peuplement); // la transmission passe par le STORE
            this.$store.commit('setItInfos', this.itCourantObj); // la transmission passe par le STORE
            this.$emit('retourItParam', this.itCourantObj);
        },
        cancelItCourant() {
            this.itCourantObj = JSON.parse(JSON.stringify(this.itCourantSav));
            this.itCourant = this.itCourantObj.id;
            if (this.itDupliqueEclaircies) {
                this.itDupliqueEclaircies = null;
            }
            this.editionEnCours = false;
        },
        transmettreComparaison() {
            if (!this.itCourant1 && !this.itCourant2) {
                this.itCourant1 = this.listeITs[0];
                // return BC 2021/02/01 - pas de return car il faut faire les initialisations de "comparaison" et l'emit "retourItComparaison"
            }
            if (!this.itCourant1) {
                if (this.itCourant2.id != this.listeITs[0].id) {
                    this.itCourant1 = this.listeITs[0];
                }
                else {
                    this.itCourant1 = this.listeITs[1];
                }
                // return BC 2021/02/01 - pas de return car il faut faire les initialisations de "comparaison" et l'emit "retourItComparaison"
            }
            if (!this.itCourant2) {
                if (this.itCourant1.id != this.listeITs[0].id) {
                    this.itCourant2 = this.listeITs[0];
                }
                else {
                    this.itCourant2 = this.listeITs[1];
                }
                // return BC 2021/02/01 - pas de return car il faut faire les initialisations de "comparaison" et l'emit "retourItComparaison"
            }
            if (this.itCourant1.id == this.itCourant2.id) {
                if (this.itCourant1.id != this.listeITs[0].id) {
                    this.itCourant2 = this.listeITs[0];
                }
                else {
                    this.itCourant2 = this.listeITs[1];
                }
                // return BC 2021/02/01 - pas de return car il faut faire les initialisations de "comparaison" et l'emit "retourItComparaison"
            }

            var comparaison = {};
            comparaison.it1 = this.itCourant1;
            comparaison.it2 = this.itCourant2;
            this.$emit('retourItComparaison', comparaison);
        },

    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {
    #itinerairesTechniquesParam, #itinerairesTechniquesComparaisonParam {
        padding: 1em;
        border: 1px solid #223938;
        border-radius: 10px;
        background-color: #eee;
        display:inline-block;

        .groupeIt {
            float:left;
            display: inline-block;
            margin-right: 3em;
            /*border-bottom: 6px solid #238b71;*/
            padding-bottom: 3px;

            span.iconeFct.svg {
                margin-top:1em;
            }
            i.svg {
                color:#223938;
                font-size:1.8em;
                vertical-align: top;
            }

            &:last-child {
                margin-right: 0;
            }

            &.choixIt {
                min-width:580px;
            }

            #choixIt {
                margin-right: 1em;
                float:left;
                .v-input {
                    margin:0;

                    .v-input__control {
                        width: 480px;
                        max-height: 3em;

                        .v-input__slot {
                            /*padding:0;*/
                        }
                    }
                }
            }

            .iconeFct, .separateurFct {
                float:left;
                margin: 0.8em 0.4em;
                height: 24px;
                max-width: 20px;
                width:auto;

                &.inactif {
                    cursor:default;
                }
            }

            span.iconeFct, span.separateurFct {
                max-width: fit-content;
                font-weight: bold;
                margin: 1.6em 0.4em 0;
            }
            span.iconeFct {
                color: #238b71
            }
        }

        button {
            margin:1em 0.8em 0;
            vertical-align: bottom;
        }
        .v-input {
            .v-input__control {
                .v-input__slot {
                    margin-bottom:0.2em;
                }
            }
        }
            .coupeRase {
                float:left;

                 .v-input {
                     margin:0;
                     padding-bottom:0;

                     .v-input__control {
                        width: 124px;

                        .v-input__slot {
                            padding-bottom:0;

                            input {
                                color: white;
                            }
                        }
                     }
                }
            }
            #optimumSteres, #coupeRase {

                 .v-input {

                     .v-input__control {
                        max-width: 100px;
                        max-height: 3em;

                        .v-input__slot {
                            margin-bottom:0;

                            input {
                                color: #223938;
                            }
                        }
                     }
                }
            }
        #editionEnCours {
            max-width: 800px;

            div.v-input {
                &:first-child {
                        margin-right:1em;
                    }
            }

            .v-input {
                max-width: 400px;
                width: 100%;
                max-height: 3em;
                display: inline-block;
                float: left;
                margin:0 0 0.4em 0;
            }
            span {
            div.v-input {
                &:first-child {
                        margin-right:0;
                    }
            }
            }
        }
    }

    #itinerairesTechniquesComparaisonParam {
        width: 100%;

        .groupeIt {

            &.choixIt {
                width: 100%;

                #choixIt {
                    width: 48%;
                    margin-bottom: 0.6em;
                }
            }
        }
    }
}

</style>
