import lf from 'lovefield'

export function user_ufpeuplement_iteclaircie (schemaBuilder) {
  schemaBuilder.createTable('user_ufpeuplement_iteclaircie')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('itId', lf.Type.INTEGER)
      .addColumn('dateEclaircie', lf.Type.STRING)
      .addColumn('densiteMoyenne', lf.Type.DOUBLE)
      .addColumn('densiteMethodeId', lf.Type.INTEGER)
      .addColumn('densiteJson', lf.Type.STRING)
      .addColumn('hauteurMoyenne', lf.Type.DOUBLE)
      .addColumn('hauteurJson', lf.Type.STRING)
      .addColumn('circonferenceQuadratique', lf.Type.DOUBLE)
      .addColumn('circonferenceQuadratiqueJson', lf.Type.STRING)


      .addColumn('version', lf.Type.INTEGER)
      .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon)
      .addPrimaryKey(['id'], false)
      .addNullable(['idLocal']);
}

