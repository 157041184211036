import lf from 'lovefield'

export function const_essences_cultures_terroirs (schemaBuilder) {
  schemaBuilder.createTable('const_essences_cultures_terroirs')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('essenceId', lf.Type.INTEGER)
      .addColumn('cultureId', lf.Type.INTEGER)
      .addColumn('terroirId', lf.Type.INTEGER)
      .addColumn('HMAX', lf.Type.DOUBLE)
      .addPrimaryKey(['id'], false);
}
