import lf from 'lovefield'

export function const_terroirs (schemaBuilder) {
  schemaBuilder.createTable('const_terroirs')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('code', lf.Type.STRING)
      .addColumn('libelle', lf.Type.STRING)
      .addColumn('carboneSolLitiere', lf.Type.DOUBLE)
      .addPrimaryKey(['id'], false);
}
