<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="peuplementEditionEclaircie" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="eclaircie.id" class="blocACompleter">Edition d'une éclaircie</h1>
                <h1 v-if="!eclaircie.id" class="blocACompleter">Ajout d'une éclaircie</h1>

                <span v-if="eclaircie.id" class="complementBloc">
                <v-btn class="boutonFonctionObjet" type="button" @click="supprimerEclaircieouPas">Supprimer</v-btn>
                </span>


                <p class="info">Les champs suivants marqués du symbole * sont obligatoires.</p>

                <div class="edition">
                    <it-eclaircies-form-peuplement :data='eclaircie' :data2='it'></it-eclaircies-form-peuplement>
                    <div class="illustration illustration3"></div>
                </div>

                <v-dialog v-model="dialog" v-if="eclaircie.id" >
                    <v-card>
                        <v-card-title class="headline">Suppression</v-card-title>

                        <v-card-text>
                            Voulez-vous vraiment supprimer l'éclaircie {{ eclaircie.dateEclaircie }} ?<br/>Cette action est irréversible.
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="dialog = false">Non</v-btn>
                            <v-btn color="" text @click="supprimerEclaircie()">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </div>
        </div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import PeuplementItinerairesTechniquesEclaircieForm from '../components/uf/PeuplementItinerairesTechniquesEclaircieForm.vue'

export default {

    components: {
        'it-eclaircies-form-peuplement': PeuplementItinerairesTechniquesEclaircieForm,
     },

    data() {
        return {
            it:this.$store.getters.infosIt,
            eclaircie: {
                "id":0,
                "userId":this.$store.getters.utilisateurCourantId,
                "itId":this.$store.getters.infosIt.id,
                "dateEclaircie":"",
                "densiteMoyenne":0.0,
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "hauteurMoyenne":0.0,
                "hauteurJson":"[]",
                "tabHauteurs":[],
                "circonferenceQuadratique":0.0,
                "circonferenceQuadratiqueJson":"[]",
                "tabCirconferences":[],
                "version":0,
				"idLocal":true,
            },
            ready: false,
            dialog: false
        }
    },
    created() {

    },

    mounted: function () {
        this.eclaircieCourante();
    },

    computed: {

        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
    },

    methods: {
        // lecture en base et maj du ready
        eclaircieCourante() {
            var eclaircieId = this.$store.getters.itEclaircieId;
            if (eclaircieId > 0) {
                return ApiUser.lireItEclaircie(eclaircieId).then(response => {
                    this.eclaircie = response.eclaircie;
                    this.ready = true;
                })
            }
            else {
                this.ready = true;
            }
        },
        // fonctions sur boutons
        supprimerEclaircieouPas() {
            this.dialog = true;
        },
        supprimerEclaircie() {
            this.dialog = false;
            return ApiUser.deleteItEclaircie(this.eclaircie).then(response => { // on detruit notre Eclaircie (en DB locale et SI POSSIBLE en ligne)
                this.$store.commit('donnerMessage', "L'éclaircie du "+this.eclaircie.dateEclaircie+" a bien été supprimée");
                this.$router.push({ name: "peuplement" });
            })
        }

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
}
</style>
