<template>

    <div id="logos" class="centrer">
        <div class="ligneLogos">
            <a href="http://www.nouvelle-aquitaine.fr" target="_blank"><img class = "grandLogo" src="../assets/img/logoNA.png" /></a>
            <a href="http://www.maisondelaforet-sudouest.com" target="_blank"><img class = "petitLogo" src="../assets/img/logoMdlF.png" /></a>
            <a href="http://www.franceboisforet.fr" target="_blank"><img class = "petitLogo" src="../assets/img/logoFBF.png" /></a>
            <img class = "petitLogo" src="../assets/img/logoSSPM.png" />
            <a href="http://www.xylofutur.fr" target="_blank"><img class = "grandLogo grandLogoFin" src="../assets/img/xylofutur2.png" /></a>
        </div>
    </div>

</template>

<script>

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
    }
}
</script>


<style lang="scss">
    #app {
        #logos {
            margin-top:50px;

            .ligneLogos {
                text-align: left;

                @media screen and (max-width: 750px) {
                    text-align: center;
                }

                img {
                    max-height: 130px;
                    width:auto;
                    margin:1em 0.8em;

                    &.grandLogo {
                        margin-left:0;
                        margin-right:1.5em;

                        @media screen and (max-width: 750px) {
                            max-height: 100px;
                            display:block;
                            margin:1em auto;
                        }
                    }

                    &.grandLogoFin {
                        margin-right:0;
                        margin-left:0.8em;

                        @media screen and (max-width: 900px) {
                            max-height: 120px;
                            display:block;
                            margin:1em auto;
                        }
                    }

                    &.petitLogo {
                        max-height: 60px;
                        margin-top:10px;
                    }

                    vertical-align: middle;
                }
            }
            min-height:68px;

        }
    }

</style>

