
class ApiUploadObj {

    upload(formData) {
        const leCSV = formData.getAll('leCSV');
        const promises = leCSV.map((x) => this.getCSV(x)
            .then(csv => ({
                csv: csv,
            })));
        return Promise.all(promises);
    }

    getCSV(file) {
        return new Promise((resolve, reject) => {
            const fReader = new FileReader();
            fReader.onload = function (e) {
                resolve(e.target.result);
            }

            fReader.readAsText(file);
        })
    }

    getImage(file) {
        return new Promise((resolve, reject) => {
            const fReader = new FileReader();
            const img = document.createElement('img');

            fReader.onload = () => {
                img.src = fReader.result;
                resolve(this.getBase64Image(img));
            }

            fReader.readAsDataURL(file);
        })
    }

    getBase64Image(img) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        return dataURL;
    }
}

export const ApiUpload = new ApiUploadObj()
