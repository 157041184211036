<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="UfEdition" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="uf.id" class="blocACompleter">{{uf.identifiant}}</h1>
                <h1 v-if="!uf.id" class="blocACompleter">Création d'une unité forestière</h1>

                <span v-if="uf.id && !$store.getters.modeForet" class="complementBloc">
                    <v-btn v-if="uf.peuplement" class="boutonFonctionObjet" type="button" @click="decouperUF">Découper</v-btn>
                    <v-btn class="boutonFonctionObjet" type="button" @click="supprimerUFouPas">Supprimer</v-btn>
                </span>

                <p class="intro">Une Unité Forestière (UF) est constituée d'une ou plusieurs parcelles cadastrales, entières ou partielles, contigües ou non, ayant un peuplement homogène (âge, types de terrain et de sylviculture) sur lequel un même itinéraire est appliqué. </p>

                <p class="info">Les champs suivis d'une * sont obligatoires.</p>

                <div class="edition">
                    <edition-uf :data='laUF'></edition-uf>
                    <div class="illustration illustration1"></div>
                </div>
            </div>

<v-dialog v-model="dialog" v-if="uf.id" >
      <v-card>
        <v-card-title class="headline">Suppression</v-card-title>

        <v-card-text>
          Voulez-vous vraiment supprimer l'unité forestière {{ uf.identifiant }} ainsi que toutes ses parcelles et peuplements liés ?<br/>Cette action est irréversible.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialog = false">Non</v-btn>
          <v-btn color="" text @click="supprimerUF()">Oui</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
        </div>
        <div class="clear"></div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import UfEditionForm from '../components/uf/UfEditionForm.vue'

export default {

    components: {
        'edition-uf': UfEditionForm,
     },

    data() {
        return {
            uf: {
                "userId":this.$store.getters.utilisateurCourantId,
                "id":0,
                "code":"",
                "identifiant":"",
                "proprieteId":this.$store.getters.proprieteId,
                "terroirId":0,
                "surface":0,
                "commentaire":"",
                "version":0,
				"idLocal":true,
                tabParcelles:[{"id":0, "ufId":0, "identifiant":"", "codePostal":"", "codeCommune":"", "libelleCommune":"", "sectionCadastrale":"",  "numeroCadastral":"",  "sousParcelle":"", "surface":"", "version":0}]
            },
            ready: false,
            dialog: false
        }
    },
    created() {

    },

    mounted: function () {
        this.ufCourante();
        if (this.$store.getters.modeForet == 3) {
            this.$store.commit('setModeForet', 4);
        }
    },
    watch: {
        modeForetPreparation(newVal) {
            if (newVal == 4 && this.ready) {
                setTimeout(this.avancerVers4(), 2500); // normalement on ne passe pas par là
                ;
            }
        },
        isReady(newVal) {
            if (newVal && this.modeForetPreparation == 4) {
                setTimeout(this.avancerVers4, 245); // normalement on passe pas par là
            }
        }
    },

    computed: {
        // Uité forestière à jour
        laUF() {
            return this.uf
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        modeForetPreparation() {
            return(this.$store.getters.modeForet);
        },
    },

    methods: {
        avancerVers4() {
            this.$router.push({ name: "propriete" });
        },

        // lecture en base et maj du ready
        ufCourante() {
            var ufId = this.$store.getters.ufId;
            if (ufId > 0) {
                return ApiUser.lireUF(ufId).then(response => {
                    this.uf = response.uf;
                    this.uf.tabParcelles = response.parcelles;
                    this.uf.userId = this.$store.getters.utilisateurCourantId; // pour l'avoir sous la main
                    this.ready = true;
                })
            }
            else {
                this.ready = true;
            }
        },
        // fonctions sur boutons
        decouperUF() {
            this.$store.commit('setUfId', this.uf.id);
            this.$store.commit('setUfInfos', this.uf);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "ufdecoupe" });
        },
        supprimerUFouPas() {
            this.dialog = true;
        },
        supprimerUF() {
            this.dialog = false;
            ApiUser.deleteUF(this.uf) // on detruit notre UF (en DB locale et SI POSSIBLE en ligne)
            this.$store.commit('donnerMessage', "L'unité forestière "+this.uf.identifiant+" a bien été supprimée");
            //this.$store.commit('setUfId', 0);
            //this.$store.commit('resetDataApplication');
            this.$router.push({ name: "propriete" });
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #app {
        div.itemsList {
            border-left:none;

            div.itemListEdit  {
                margin:1em 1em 1em 0;
                border-left:#238b71 solid 3px;
                padding-left:1em;

                &.itemEdit {
                    span.groupEdit .blocIntermediare {
                        >span/*, >div*/ {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
</style>



