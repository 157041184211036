<template>
    <div id="dendrometrieVisualisation" class="tests conteneurTable" v-if="testDatasReady">

        <div v-if="itEnCours" class="containerCourbes" v-html="contentSVG1"></div>

        <div v-if="tabReady">

            <div class="aGauche entete">
                <h3 class="centrer">Informations chiffrées : {{itEnCours.identifiant}}</h3>
                <h4>Unité forestière : {{ppl.uf.identifiant}} - Surface boisée : {{formaterSurface(ppl.surfaceBoisee)}} ha</h4>

            <span>Installation en {{ ppl.anneeDateInstallation}} <i class="fas fa-arrow-right"></i> Densité initiale : {{formaterDensite(ppl.densite)}} tiges/ha - Type de lande : {{leTerroir}} - Type de culture : {{laCulture}} - HMAX de base : {{ ppl.HMAX}}</span><br/>

            <span>Fin installation : {{ dataFinInstallation.age}} ans ({{ ppl.anneeDateInstallation+dataFinInstallation.age}}), hauteur : {{formaterHauteur(dataFinInstallation.hauteur)}} m</span>
            </div>

            <div class="aDroite legende">
                <span class="mesure">Mesure</span> / <span class="eclaircie">Eclaircie</span> / <span class="anneeCoupeRase">Coupe rase</span> / <span class="concurrence">Augmentation de la mortalité</span>
            </div>
            <table class="dendrometrique">
                <thead>
                    <tr class="lien" @click.stop="exporterTableau(laTabValeursExportees)">
                        <th colspan="2"><i class="fas fa-download"></i></th>
                        <th colspan="6" v-if="enDebug">Caractéristiques du peuplement en début d'année de croissance</th>
                        <th colspan="5" v-if="!enDebug">Caractéristiques du peuplement en début d'année de croissance</th>
                        <!-- <th colspan="6">Caractéristiques du peuplement en début d'année de croissance</th> -->
                        <th colspan="5">Caractéristiques de l'éclaircie proposée</th>
                        <th colspan="3">Accroissements<br/>= Année - année précédente</th>
                        <th colspan="1">Accroissement moyen</th>
                    </tr>
                    <tr>
                        <th >Année</th>
                        <th >Age</th>
<!-- -->
<th v-if="enDebug">Hmax</th>
<!-- -->
                        <th >Hauteur<span class="uniteValeur">(m)</span></th>
<th v-if="0 && enDebug">Hauteur Calculée</th>
                        <th >Circonférence<span class="uniteValeur">(cm)</span></th>
                        <th >Densite<span class="uniteValeur">(tiges/ha)</span></th>
                        <th >Volume Unitaire<span class="uniteValeur">(m3)</span></th>
                        <th >Volume<span class="uniteValeur">(m3/ha)</span></th>

                        <th >Hauteur<span class="uniteValeur">(m)</span></th>
                        <th >Circonférence<span class="uniteValeur">(cm)</span></th>
                        <th class="supplement">Densité<span class="uniteValeur">(tiges/ha)</span></th>
                        <th >Volume Unitaire<span class="uniteValeur">(m3)</span></th>
                        <th >Volume<span class="uniteValeur">(m3/ha)</span></th>

                        <th >Hauteur<span class="uniteValeur">(m)</span></th>
                        <th >Circonférence<span class="uniteValeur">(cm)</span></th>
                        <th >Volume<span class="uniteValeur">(m3)</span></th>

                        <th>Volume<span class="uniteValeur">(m3)</span></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-bind:class="{anneeCourante : valeur.anneeCouranteClass, mesures: valeur.mesuresClass,   eclaircieProp: valeur.eclairciePropClass,  eclaircie: valeur.eclaircieClass,   concurrence: valeur.concurrenceClass, anneeCoupeRase: laCoupeRase==index}"  v-for="(valeur, index) in tabValeursCalculees" :key="index" >
                        <td class="aDroite">{{ ppl.anneeDateInstallation+index}}</td>
                        <td class="aDroite">{{ index }}</td>
<!-- -->
<td v-if="enDebug" class="aDroite">{{ formaterVolumeTab(valeur.HMAX) }}</td>
<!-- -->
                        <td class="aDroite">
                            <span v-if="!valeur.densiteEclaircie">{{ formaterHauteurTab(valeur.hauteur) }}</span>
                            <span v-if="valeur.densiteEclaircie" class="avantEclaircie">{{ formaterHauteurTab(valeur.hauteurAvantEclaircie) }}</span>
                        </td>
<!-- -->
<td v-if="0 && enDebug" class="aDroite">{{ formaterHauteurTab(valeur.hauteurWiz) }}</td>
<!-- -->
                        <td class="aDroite">
                            <span v-if="valeur.circonference <= 0"></span>
                            <span v-if="valeur.circonference > 0 && (!valeur.densiteEclaircie || valeur.densiteEclaircie < 0)">{{ formaterCirconferenceTab(Math.max(valeur.circonference*100,-1)) }}</span>
                            <span class="avantEclaircie" v-if="valeur.circonferenceAvantEclaircie > 0 && valeur.densiteEclaircie > 0">{{ formaterCirconferenceTab(Math.max(valeur.circonferenceAvantEclaircie*100,-1)) }}</span>
                        </td>
                        <td class="aDroite">
                            <span v-if="valeur.densite <= 0"></span>
                            <span v-if="valeur.densite > 0 && (!valeur.densiteEclaircie || valeur.densiteEclaircie < 0)">{{ formaterDensiteTab(valeur.densite) }}</span>
                            <span v-if="valeur.densite > 0 && valeur.densiteEclaircie > 0">{{ formaterDensiteTab(valeur.densiteAvantEclaircie) }}</span>
                        </td>
                        <td class="aDroite">
                            <span v-if="valeur.volumeUnitaire <= 0"></span>
                            <span v-if="valeur.volumeUnitaire > 0 && (!valeur.densiteEclaircie || valeur.densiteEclaircie < 0)">{{ formaterVolumeTab(valeur.volumeUnitaire) }}</span>
                            <span v-if="valeur.volumeUnitaire > 0 && valeur.densiteEclaircie > 0">{{ formaterVolumeTab(valeur.volumeUnitaireAvantEclaircie) }}</span>
                        </td>
                        <td class="aDroite">
                            <span v-if="valeur.volumeUnitaire > 0 && (!valeur.densiteEclaircie || valeur.densiteEclaircie < 0)">{{ formaterVolumeTab(valeur.volume) }}</span>
                            <span v-if="valeur.volumeUnitaire > 0 && valeur.densiteEclaircie > 0">{{ formaterVolumeTab(valeur.volumeAvantEclaircie) }}</span>
                        </td>

                        <td class="aDroite"><span v-if="valeur.densiteEclaircie > 0">{{ formaterHauteurTab(valeur.hauteurEclaircie) }}</span></td>
                        <td class="aDroite"><span v-if="valeur.densiteEclaircie > 0">{{ formaterCirconferenceTab(valeur.circonferenceEclaircie*100) }}</span></td>
                        <td class="aDroite"><span v-if="valeur.densiteEclaircie > 0">{{ formaterDensiteTab(valeur.densiteEclaircie) }} <span class="supplement">({{arrondir(valeur.densiteEclaircie/valeur.densiteAvantEclaircie*100, 1)}}%)</span></span></td>
                        <td class="aDroite"><span v-if="valeur.densiteEclaircie > 0 && (index > dataFinInstallation.age)">{{ formaterVolumeTab(valeur.volumeUnitaireEclaircie) }}</span></td>
                        <td class="aDroite"><span v-if="valeur.densiteEclaircie > 0 && (index > dataFinInstallation.age)">{{ formaterVolumeTab(valeur.volumeRecolteEclaircie) }}</span></td>


                        <td class="aDroite">
                            <span v-if="!index">&nbsp;</span>
                            <span v-if="index">{{ formaterHauteurTab(valeur.hauteur-valeur.hauteurPrecedente) }}</span>
                        </td>
                        <td class="aDroite">
                            <span v-if="!index">&nbsp;</span>
                            <span v-if="index && valeur.circonferencePrecedente >= 0 && (!valeur.densiteEclaircie ||  valeur.densiteEclaircie < 0)">{{formaterCirconferenceTab((valeur.circonference-valeur.circonferencePrecedente)*100) }}</span>
                            <span v-if="index && valeur.circonferencePrecedente >= 0 && valeur.densiteEclaircie > 0">{{formaterCirconferenceTab((valeur.circonferenceAvantEclaircie-valeur.circonferencePrecedente)*100) }}</span>
                        </td>
                        <td class="aDroite">
                            <span v-if="index && valeur.volumePrecedent >= 0 && (!valeur.densiteEclaircie ||  valeur.densiteEclaircie < 0)">{{ formaterVolumeTab(valeur.volume-valeur.volumePrecedent) }}</span>
                            <span v-if="index && valeur.volumePrecedent >= 0 && valeur.densiteEclaircie > 0">{{ formaterVolumeTab(valeur.volume-valeur.volumePrecedent+valeur.volumeRecolteEclaircie) }}</span>
                        </td>

                        <td class="aDroite">
                            <span v-if="index && valeur.volume >= 0  && (!valeur.densiteEclaircie ||  valeur.densiteEclaircie < 0)">{{ formaterVolumeTab(valeur.volume/index) }}</span>
                            <span v-if="index && valeur.volume >= 0  && valeur.densiteEclaircie > 0">{{ formaterVolumeTab((valeur.volume+valeur.volumeRecolteEclaircie)/index) }}</span>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>


<script>


import { ApiTools, ApiUser } from "../../js/api/index.js"

//import { SteresConstantes } from "../../js/calculs/constantes.js"
import { UfPeuplement } from "../../js/calculs/peuplementObj.js"
//import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObj.js'
import { UfPplDendrometrique } from '../../js/calculs/dendrometriqueObjTER.js'
import { UfPplFinancier } from '../../js/calculs/financierObj.js'

import { courbeSVG } from "../../js/svg/svgCourbes.js"

import { addeoData } from "../../js/api/datas.js"

export default {

    props: {
        /**
         * Données à injecter (uf)
         */
    },

    data(){
        return {
            datasReady:false,
            tabReady:false,
            constantes : {},
            ppl : {},
            dataFinInstallation : {},
            tabValeurS:[],
            svgContent1: "",
            courbe1: null,
        }
    },

    created: function () {
        return(0);
    },

    mounted: function () {
        this.datasReady = true;
        return(0);
    },

    updated: function () {
    },


    watch: {
        itEnCours(newValue) {
            if(newValue) {
                this.lancerAffichage(newValue)
            }
        },
        /*
        calculOptimumPasse(newValue) {
            if (this.itEnCours) {
                this.lancerAffichage(this.itEnCours);
            }
        }
        */
    },

    computed: {
        enDebug() {
            return(this.$store.getters.domaine != "PROD");
        },
        testDatasReady() {
            return(this.$store.getters.constantes.ready && this.datasReady);
        },
        laTabValeursExportees() {

            var anneCoupeRase = this.laCoupeRase;
            var anneeDateInstallation = this.ppl.anneeDateInstallation
            var tab = [];
            this.tabValeursCalculees.forEach((valeur, index) => {
                var obj = {};
                obj.annee = this.exporterValeur(this.ppl.anneeDateInstallation+index);
                obj.age = this.exporterValeur(index);

                obj.HMAX = this.exporterValeur(valeur.HMAX);
                obj.hauteur = this.exporterValeur(valeur.hauteur);
                obj.circonference = this.exporterValeur(valeur.circonference*100);
                obj.croissanceCirconference = this.exporterValeur(valeur.croissanceCirconference*100);
                obj.densite = this.exporterValeur(valeur.densite);
                obj.volumeUnitaire = this.exporterValeur(valeur.volumeUnitaire);
                obj.volume = this.exporterValeur(valeur.volume);

                // ajout BC 2021/02
                obj.hauteurAvantEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.hauteurAvantEclaircie:''));
                obj.circonferenceAvantEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.circonferenceAvantEclaircie*100:''));
                obj.densiteAvantEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.densiteAvantEclaircie:''));
                obj.volumeUnitaireAvantEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.volumeUnitaireAvantEclaircie:''));
                obj.volumeRecolteAvantEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.volumeAvantEclaircie:''));

                obj.hauteurEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.hauteurEclaircie:''));
                obj.circonferenceEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.circonferenceEclaircie*100:''));
                obj.densiteEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.densiteEclaircie:''));
                obj.volumeUnitaireEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.volumeUnitaireEclaircie:''));
                obj.volumeRecolteEclaircie = this.exporterValeur((valeur.densiteEclaircie?valeur.volumeRecolteEclaircie:''));

                obj.accroissementHauteur = this.exporterValeur((!index?0:valeur.hauteur-valeur.hauteurPrecedente));
                obj.accroissementCirconference = this.exporterValeur((!index?0:valeur.circonference-valeur.circonferencePrecedente));
                obj.accroissementVolume = this.exporterValeur((!index?0:!valeur.densiteEclaircie ? valeur.volume-valeur.volumePrecedent : valeur.volume-valeur.volumePrecedent+valeur.volumeRecolteEclaircie));

                obj.accroissementMoyen = this.exporterValeur((!index?0:!valeur.densiteEclaircie ? valeur.volume/index : (valeur.volume+valeur.volumeRecolteEclaircie)/index));

                tab[index] = obj;
            })
            return(tab);

        },

        uf() {
            return this.$store.getters.infosUf
        },
/*
        calculOptimumPasse() {
            if (this.testDatasReady) {
                return(this.uf.peuplement.calculOptimumPasse)
            }
            return 0;
        },
*/
        itEnCours() {
            if (this.testDatasReady) {
                var choisi = null;
                var enCours = null;
                if (typeof(this.uf.peuplement.ITs) !== 'undefined') {
                    this.uf.peuplement.ITs.forEach(it => {
                        if (it.enCours) {
                            enCours = it;
                        }
                        if (it.choisi) {
                            choisi = it;
                        }
                    })
                    if (!enCours) {
                        return choisi;
                    }
                    return enCours;
                }
            }
            return null;
        },
        laCoupeRase () {
            var lIt = this.itEnCours;
            if (!lIt) {
                return(0);
            }
            return(lIt.coupeRase)
        },

        contentSVG1 () {
            return this.svgContent1
        },

        laCulture() {
            var constantes = this.$store.getters.constantes;
            var cultureObj = ApiUser.trouverItemTabConst(constantes.params.const_cultures, this.ppl.cultureId, ""); // verrouille ci-dessous
            /*
            var lId = "id"+this.ppl.cultureId;
            var cultureObj = constantes.params.const_cultures[lId];
            */
            if (!cultureObj) {
                return("");
            }
            return(cultureObj.libelle);
        },
        leTerroir() {
            var lId = "id"+this.ppl.uf.terroirId;
            var constantes = this.$store.getters.constantes;
            var terroirObj = constantes.params.const_terroirs[lId];
            if (!terroirObj) {
                return("");
            }
            return(terroirObj.libelle);
        }

    },

    methods: {
        exporterValeur(valeur) {
            var valeurRetournee = ""+valeur;
            return(valeurRetournee.replace('.', ','));
        },
        arrondir: function(valeur, puissance = 3) {
            var puissanceDe10 = Math.pow(10, puissance);
            var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
            return(arrondi);
        },
        formaterEuros: function(valeur) {
            return(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(valeur))
        },
        formaterTecTab(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterVolumeTab(valeur) {
            return(ApiTools.arrondiVolumeTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterVolume(valeur) {
            return(ApiTools.arrondiVolume(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterSurface(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterSurfaceTab(valeur) {
            return(ApiTools.arrondiSurfaceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterDensite(valeur) {
            return(ApiTools.arrondiDensite(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterDensiteTab(valeur) {
            return(ApiTools.arrondiDensiteTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterHauteur(valeur) {
            return(ApiTools.arrondiHauteur(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterHauteurTab(valeur) {
            return(ApiTools.arrondiHauteurTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        formaterCirconferenceTab(valeur) {
            return(ApiTools.arrondiCirconferenceTab(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },


        lancerAffichage(it) {

            this.constantes = this.$store.getters.constantes;
            this.abaqueEclaircies = this.constantes.abaqueEclaircies;
            this.infosPropriete = this.$store.getters.infosPropriete;
            this.svgContent1 = "";
            this.visualiserDendrometrie(it);
            setTimeout(courbeSVG.maj.bind(null, this.courbe1), 1000);
        },

        visualiserDendrometrie(it) {

            // peuplement
            var lePeuplement = UfPeuplement.compiler(this.uf, this.infosPropriete, this.constantes);

            // dendrometrie
            var params = {"it":it, "calculOptimumFutur":false, "calculOptimumPasse":lePeuplement.calculOptimumPasse, "ageMaxTest":lePeuplement.essence.vieMax};
            var laDendrometrie = UfPplDendrometrique.initialiserUfPeuplement(lePeuplement, params);
            // et calculs/ratios financiers : pour la coupe rase
            var paramsFi = {"ageMaxTest":lePeuplement.essence.vieMax};
            UfPplFinancier.initialiserUfFinancier(laDendrometrie, paramsFi);


            // courbes
            this.courbe1 = laDendrometrie.initialiserCourbes(params.ageMaxTest);
            this.svgContent1 = courbeSVG.initialiserCourbe(this.$store, this.courbe1);

            // pour affichage !
            this.ppl = laDendrometrie.ppl;
            this.tabValeursCalculees = laDendrometrie.tabValeursCalculeeS;

            this.dataFinInstallation = laDendrometrie.infosFinInstallation;
            this.tabReady = true;

            // on stocke les résultats
            this.$store.commit('setDendrometrie', laDendrometrie);
        },

        exporterTableau(tableau) {
            var nomCSV = this.ppl.uf.identifiant + "__dendrometrie_"+this.itEnCours.id;
            return addeoData.exporterTableau(tableau, nomCSV);
        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #dendrometrieVisualisation {

        .containerCourbes {
            text-align: center;
        }

        #courbeHauteur {
            /*max-width:1200px;*/
            width:100%;
            margin:0;
            background-color: white;
            /*padding : 0;*/
        }
        .containerCourbes svg {
            pointer-events:all;

            .titre {
                /*
                display:none;
                */
            }
        }


        .mesure {
            font-weight: bold;
            color: #375E5B;
        }
        .eclaircie {
            font-weight: bold;
            color : #238b71;
        }
        .anneeCoupeRase {
            font-weight: bold;
        }
        .concurrence {
            text-decoration: underline;
        }
        .legende  {
            margin-top : 2em;
            }

                table.dendrometrique {
                    margin : 0.4em auto 1em ;
                    text-align: left;
                    background-color: #ffffff;
                    padding: 1em;
                    border-spacing: 0;
                    table-layout: fixed;
                    border-color: #777;
                    border-width: 1px;
                    border-style: solid;
                        height:500px;
                        display:block;
                        overflow: auto;

                    thead th {
                        position: sticky;
                        /*top: 0;*/
                        top: -15px;
                        z-index: 100;
                    }
                    tbody {
                    }

                    tr.mesures {
                        font-weight: bold;
                        color: #375E5B;
                    }
                    tr.eclaircie {
                        font-weight: bold;
                        color : #238b71;
                    }
                    tr.eclaircieProp {
                        background-color : #9EC7C5;
                    }
                    tr.concurrence {
                        text-decoration: underline;
                    }
/*
                    tr.finInstallation {
                        background-color: #dddddd;
                    }
*/
                    tr.anneeCoupeRase {
                        font-weight: bold;
                    }

                    /*
                    tr.anneeCourante {
                        background-color: #FEFFCC;
                    }
                    tr.finInstallation.anneeCourante {
                        background-color: #DCDD8C;
                    }
                    */

                    td, th {
                        max-width : 200px;
                        padding:0 0.6em;
                        white-space :normal;

                        span.uniteValeur {
                            display: block;
                            text-align: right;
                            font-weight: normal;
                            font-size: 0.9em;
                        }
                    }
                    td {
                        span.supplement {
                            font-size: 0.7em;
                            opacity: 0.8;
                        }
                    }
                    th {
                        text-align : center;
                        background-color: #f7f7f7;
                        padding-top: 1em;
                        padding-bottom: 0.6em;

                        &.supplement {
                            padding-left: 1em;
                        }
                    }
                    .lien {
                        .fas {
                            font-size:1.8em;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    table td.euro {
                        text-align : right;
                    }
                }

    }
</style>
