<template>
 <div id="visualisationParcelles">
    <div v-if="ready" class="conteneurTable">
        <h2 class="titreListe blocACompleter" >{{lesParcelles.length}} parcelle<span v-if="lesParcelles.length > 1">s</span> - {{arrondiHectares(surfaceTotale)}} ha</h2>

        <span class="complementBloc masquerDemasquer"   @click.stop="parcellesOuvert=false" v-if="parcellesOuvert">
            <i class="fas fa-caret-down"></i><span class="facultatif640">Masquer</span>
        </span>
        <span class="complementBloc masquerDemasquer"   @click.stop="parcellesOuvert=true" v-if="parcellesOuvert==false">
            <i class="fas fa-caret-up"></i><span class="facultatif640">Montrer</span>
        </span>

        <transition name="fadeHeight">
            <div v-if="parcellesOuvert" class="conteneurTable">
                <table v-for="(leGroupe, numGroupe) in  lesParcelles" :key="numGroupe" >
                    <tr>
                        <th >
                            {{ leGroupe.libelleCommune }} - {{ leGroupe.codePostal }}
                        </th>
                        <td colspan="2">
                        </td>
                    </tr>
                    <tr v-for="(laParcelle, numParcelle) in  leGroupe.parcelles" :key="numParcelle">
                        <th></th>
                        <td>
                            {{ laParcelle.identifiant }}
                        </td>
                        <td>
                            {{arrondiHectares(laParcelle.surface)}} ha
                        </td>
                    </tr>
                </table>
            </div>
        </transition>


    </div>
</div>
</template>


<script>

import { ApiTools } from "../../js/api/index.js"

export default {
    components: {
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {

            // init les données du form à partir des props
            uf: JSON.parse(JSON.stringify(this.data)),
            parcelles:[],
            ready:false,
            parcellesOuvert:false,
        }
    },

    created() {
        this.parcelles = this.initialiserTabParcelles();
        this.ready = true;
    },

    computed: {
        // pour affichage "à jour" de la liste
        lesParcelles() {
            return(this.parcelles);
        },
        // calcul de la somme des surfaces des parcelles
        surfaceTotale() {
            var laSurface = 0.0;
            this.uf.tabParcelles.forEach(parcelle => {
                var surface = parseFloat(parcelle.surface)
                if (isNaN(surface)) {
                    surface = 0;
                }
                laSurface += surface;
            })
            return laSurface;
        }
    },

    methods: {
        initialiserTabParcelles() {
            var tabTmp = [];
            tabTmp = tabTmp.concat(this.uf.tabParcelles);
            // on créé un nouveau tableau : niveau 1 : Ville / niveau deux : les parcelles sur cette ville
            var tabRetour = [];
            var parcellePred = null;
            tabTmp.forEach(parcelle => {
                var newObj = {"parcelles":[]};
                if (parcellePred && (parcellePred.libelleCommune == parcelle.libelleCommune)) {
                    var objTmp = tabRetour[tabRetour.length-1];
                    objTmp.parcelles[objTmp.parcelles.length] = parcelle;
                }
                else {
                    newObj.libelleCommune = parcelle.libelleCommune;
                    newObj.codePostal = parcelle.codePostal;
                    newObj.parcelles[0] = parcelle;
                    tabRetour[tabRetour.length] = newObj;
                    parcellePred = parcelle
                }
            })
            return(tabRetour);
        },
        triVille(x, y) {
            if (x.libelleCommune < y.libelleCommune) {
               return(-1)
            }
            if (x.libelleCommune > y.libelleCommune) {
                return(1)
            }
            if (x.identifiant < y.identifiant) {
                return(-1)
            }
            if (x.identifiant > y.identifiant) {
                return(1)
            }
            return(0)
        },
        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#visualisationParcelles {
    .conteneurTable {
        h2.titreListe.blocACompleter {
            max-width: 80%;
        }
    }
    table {
        width : 100%;
        min-width: 600px;
        background-color: white;
        margin-bottom:1em;
        color:#223938;
        border:1px solid #223938;
        padding: 0.6em 1%;
        th {
            width : 28%;
            vertical-align: top;
        }
        td {
            width : 40%;
        }
    }
    .masquerDemasquer {
        cursor: pointer;
        font-size: 1.1em;
        color: #238B71;
        i {
            font-size: 1.8em;
        }
        span {
            vertical-align: text-bottom;
            font-weight: bold;
            line-height: 2em;
        }
    }
}
</style>
