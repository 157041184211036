<style lang="css">
   @import './assets/styles/Font.css';
   @import './assets/font/font-awesome/css/font-awesome.css';
</style>
<style lang="scss">
   @import './assets/styles/App.scss';
</style>

<template>
    <div id="app" data-app="true" v-bind:class="{noNet : !accesInternet,modeForet : this.$store.getters.modeForet, majDispo : updateExists}" class="addeo">

<v-snackbar top right :value="updateExists" :timeout="0" color="primary" class="barreMajDispo" v-if="!plusTard">
  Nouvelle version disponible
  <v-btn text @click="refreshApp">
    Mettre à jour
  </v-btn>
  <v-btn text @click="plusTard=true;">
    Plus tard
  </v-btn>
</v-snackbar>



        <div v-if="preparationModeForet" class="masquePreparationModeForet">
            <div class="messagePreparationModeForet">Mode forêt en cours de préparation : merci de patienter.<br/>Un message vous préviendra quand STERES sera prêt.
                <v-btn class="boutonFonctionObjet" type="button" @click="boutonSecours = false;versModeForet(false);" v-if="boutonSecours">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    Annuler
                </v-btn>
            </div>
        </div>
        <div v-if="finModeForet" class="masquePreparationModeForet">
            <div class="messagePreparationModeForet">Fin du mode forêt, merci de patienter quelques secondes.</div>
        </div>
        <div v-if="synchronisationModeForet" class="masquePreparationModeForet">
            <div class="messagePreparationModeForet">Synchronisation en cours, merci de patienter quelques secondes.</div>
        </div>
        <div v-if="importationEnCours" class="masquePreparationModeForet">
            <div class="messagePreparationModeForet">Importation en cours, merci de patienter quelques secondes.</div>
        </div>
        <div v-if="chargementProprieteEnCours && !this.$store.getters.modeForet" class="masquePreparationModeForet">
            <div class="messagePreparationModeForet">Chargement de la propriete en cours, merci de patienter quelques secondes.</div>
        </div>
        <div id="bandeau" class="header">
            <div id="bandeauConteneur">
                <router-link v-if="!this.$store.getters.connexion" id="navIco" to="/">
                    <span v-if="1" class="logo">STERES</span>
                    <img v-if="0"  src="@/assets/img/steresB.png" width="180px" />
                </router-link>
                <router-link v-if="this.$store.getters.connexion && !this.$store.getters.modeForet" id="navIco" to="/proprietes">
                    <span v-if="1" class="logo">STERES</span>
                    <img v-if="0"  src="@/assets/img/steresB.png" width="180px" />
                </router-link>
                <router-link v-if="this.$store.getters.connexion && this.$store.getters.modeForet" id="navIco" to="/propriete">
                    <span v-if="1">STERES</span> <span>Mode forêt</span>
                </router-link>
                <div id="navFct">
                    <navigation-login :connexionUtilisateur='etatConnexionUtilisateur' @transfertConnexion='rafraichirConnexion'></navigation-login>
                    <span v-if="userSynchroProposer"> | </span><router-link v-if="userSynchroProposer" to="/Synchroniser">Envoyer vos données locales</router-link>
                </div>
            </div>
        </div>
        <navigation-foret v-if="this.$store.getters.modeForet"  :data='datasApplication'></navigation-foret>
        <div id="nav" class="header" v-bind:class="{modeForet : this.$store.getters.modeForet}">
                <span class="messageError messageInternet" v-if="!accesInternet && !this.$store.getters.modeForet">Accès Internet perdu - vous ne pouvez pas enregistrer vos données</span>
                <!-- <span class="messageError messageInternet" v-if="!accesInternet && this.$store.getters.modeForet">Accès Internet perdu - vous pouvez continuer vos saisies mais vous devrez récupérer une connexion pour synchroniser vos données</span> -->
            <navigation-logos v-if="$route.name == 'never'"></navigation-logos>
            <div class="navConteneur" v-if="$route.name !== 'home'" >
                <navigation-menu v-if="datasApplication" :data='datasApplication'></navigation-menu>
                <navigation-arianne v-if="datasApplication"  :data='datasApplication'></navigation-arianne>
                <navigation-header v-if="!datasApplication" menuConnexion="1"></navigation-header>
                <div class="clear"></div>
            </div>
            <div class="navConteneur" v-if="$route.name === 'home'" >
                <navigation-header v-if="!datasApplication" menuConnexion="0"></navigation-header>
            </div>
        </div>

        <router-view  />

        <div id="footer" class="footer">
                <span class="messageError messageInternet" v-if="!accesInternet && !this.$store.getters.modeForet">Accès Internet perdu - vous ne pouvez pas enregistrer vos données</span>
                <span class="messageError messageInternet" v-if="!accesInternet && this.$store.getters.modeForet">Accès Internet perdu - vous pouvez continuer vos saisies mais vous devrez récupérer une connexion pour synchroniser vos données</span>
                <navigation-footer></navigation-footer>
                <div class="clear"></div>
        </div>

<v-dialog v-model="confirmLogoutDialog" v-if="$store.getters.connexion && $store.getters.deconnexion" @click:outside="reconnecter()">
            <v-card>
                <v-card-title class="headline">Déconnexion</v-card-title>
                <v-card-text>
                    En raison de votre inactivité, vous allez être déconnecté de l'application dans 1 minute.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" text @click="reconnecter()">Rester connecté</v-btn>
                    <v-btn color="" text @click="nePasReconnecter()">Déconnecter</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

  </div>
</template>


<script>

    //import { register } from 'register-service-worker'

    import { ApiConsts } from "./js/api/index.js"
    import { addeoData } from "./js/api/datas.js"
    import { ApiUser } from "./js/api/index.js"
    import { LovefieldService } from "./js/db/LovefieldService.js"
    import * as SteresConst from './js/steres_const.js'

    import NavigationLogin from './components/nav/NavigationLogin.vue'
    import NavigationMenu from './components/nav/NavigationMenu.vue'
    import NavigationArianne from './components/nav/NavigationArianne.vue'
    import NavigationForet from './components/nav/NavigationForet.vue'
    //import NavigationLogos from './components/nav/NavigationLogos.vue'
    import NavigationFooter from './components/nav/NavigationFooter.vue'
    import NavigationHeader from './components/nav/NavigationHeader.vue'
    //import IconePoubelle from '@/assets/img/profil.svg'

    export default {
        //name: 'App',
        name: 'STERES',
        data() {
            return {
                confirmLogoutDialog:true,
                accesInternet:true,
                appModeForet:false,
                synchronisationModeForet:false,
                finModeForet:false,
                importationEnCours:false,
                chargementProprieteEnCours:false,
                swEnregistre:false,
                swErreur:false,
                // refresh variables
                refreshAutomatique:true, // true : on ne demande pas à l'utilisateur
                refreshing: false,
                rafraichirDemande:false,
                rafraichir:false,
                registration: null,
                updateExists: false,
                plusTard:false,
                boutonSecours:false,
                waitMontrerBoutonSecours:false
            }
        },
        components: {
            'navigation-arianne': NavigationArianne,
            'navigation-foret': NavigationForet,
   //         'navigation-logos': NavigationLogos,
            'navigation-menu': NavigationMenu,
            'navigation-login': NavigationLogin,
            'navigation-footer': NavigationFooter,
            'navigation-header': NavigationHeader,

//            'icone-poubelle': IconePoubelle,
        },

        created: function () {
//console.log("app created");
            this.initialiserSteres();
            document.addEventListener('swRegistered', this.swRegisteredAvailable, { once: true })
            document.addEventListener('swUpdated', this.updateAvailable, { once: true })
            document.addEventListener('swError', this.swErrorDetected, { once: true })

                // Prevent multiple refreshes
/*
                // BC 2021/04 - reload
            navigator.serviceWorker.addEventListener('controllerchange', () => {

                // BC 2021/04 - reload - on ne passe pas ici ...
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                console.log('before reload');
                window.location.reload()
            })
*/
        },


        mounted() {
//console.log("app mounted");
                this.$store.commit('setDomaine',  window.location.hostname); // si nouvelle version, on redemande le consentement

                this.plusTard=false;
                if (this.messagesModeForet) { // si on rentre dans l'appli avec un message, ça sent le bug ! NE PAS BLOQUER !!!!
                    this.testerDeclenchementSecours(2);
                }
        },

        watch:{
            rafraichir(newValue) {
                if (newValue) {
                    this.rafraichir = false;
                    this.$store.commit('setCguValidees', false); // si nouvelle version, on redemande le consentement
                    console.log('before reload');
                    setTimeout(function(){
                        console.log('reload en cours');
                        window.location.reload();
                    }, 3000);
                }
            },

            $route (to, from){
                this.$store.commit('setConnexionTimeMSec');
                this.$store.commit('setDbVersion', SteresConst.REQUIRED_DATABASE_VERSION);
            },
            accesInternet(newValue) {
                return(newValue);
            },
            messagesModeForet(newValue) {
                this.testerDeclenchementSecours(newValue);
                /*
                if (newValue && newValue != 1) {
                    if (!this.boutonSecours && !this.waitMontrerBoutonSecours) {
                        this.waitMontrerBoutonSecours = setTimeout(this.montrerBoutonSecours, 15000);
                    }
                }
                else {
                    this.boutonSecours = false;
                    if (!newValue && this.waitMontrerBoutonSecours) {
                        clearTimeout(this.waitMontrerBoutonSecours);
                    }
                }
                */
            },
            importModeForet(newValue) {
                this.testerDeclenchementSecours(newValue);
                /*
                if (newValue && newValue != 1) {
                    if (!this.boutonSecours && !this.waitMontrerBoutonSecours) {
                        this.waitMontrerBoutonSecours = setTimeout(this.montrerBoutonSecours, 15000);
                    }
                }
                else {
                    this.boutonSecours = false;
                    if (!newValue && this.waitMontrerBoutonSecours) {
                        clearTimeout(this.waitMontrerBoutonSecours);
                    }
                }
                */
            },
            confirmLogoutDialog(newValue) {
                if (!newValue) { // si on clique à côté du dialog
                    this.reconnecter(); // on considère qu'on reconnecte - pour éviter les deconnexions non souhaitées
                    this.confirmLogoutDialog=true;
                }
            }
        } ,

        computed: {
            laVersionSTERES() {
                return(SteresConst.STERES_VERSION);
            },
            preparationModeForet() {
                return(this.$store.getters.modeForet && (this.$store.getters.modeForet != 1));
            },
            messagesModeForet() {
                if (this.preparationModeForet || this.finModeForet || this.synchronisationModeForet || this.importationEnCours) {
                    return(2);
                }
                return(false);
            },
            importModeForet() {
                return(this.$store.getters.modeForet)
            },
            datasApplication() {
                if (!this.$store.getters.dataApplication && this.$store.getters.connexion) { // 2020/02/04 ajout du test sur la connexion pour la maj
                    this.$store.commit('majDataApplication');
                }
                return(this.$store.getters.dataApplication)
            },
            /*
            datasApplicationCommit() {
                this.$store.commit('majDataApplication');
                return(this.$store.getters.dataApplication)
            },
            */
            etatConnexionUtilisateur() {
                this.reinitialiserDatasUtilisateur(this.$store.getters.connexion); // 2020/02/04 reinitialiserDatasUtilisateur remplace reinitialiserUtilisateur : boucle infinie
                //this.reinitialiserUtilisateur(); // pour obliger la "relance" de la computed datasApplication()
                return(this.$store.getters.connexion)
            },
            connexionUtilisateurValide() {
                this.reinitialiserDatasUtilisateur(this.$store.getters.connexion); // 2020/02/04 reinitialiserDatasUtilisateur remplace reinitialiserUtilisateur : boucle infinie
                //this.reinitialiserUtilisateur(); // pour obliger la "relance" de la computed datasApplication()
                if (!this.connexion()) {
                    setTimeout(this.goHome, 2000); // on repart à la page d'accueil
                }
                else {
                   // this.goConnected();
                }
                return(this.$store.getters.connexion)
            },
            userSynchroProposer() {
                return (this.connexion() && this.synchronisationPossible())
            },
            okServiceWorker() {
                return(this.swEnregistre && !this.swErreur)
            },
            rafraichissementAutomatique() {
                console.log('rafraichissementAutomatique');
                if (this.refreshAutomatique && this.$store.getters.modeForet != 1) {
                    console.log('rafraichissementAutomatique OK');
                    return(true);
                    }
                console.log('rafraichissementAutomatique NON');
                return(false);
            }
        },

        methods: {
            testerDeclenchementSecours(newValue) {
                if (newValue && newValue != 1) {
                    if (!this.boutonSecours && !this.waitMontrerBoutonSecours) {
                        this.waitMontrerBoutonSecours = setTimeout(this.montrerBoutonSecours, 15000);
                    }
                }
                else {
                    this.boutonSecours = false;
                    if (!newValue && this.waitMontrerBoutonSecours) {
                        clearTimeout(this.waitMontrerBoutonSecours);
                    }
                }
            },
            reconnecter() {
                this.$store.commit('setConnexionTimeMSec');
                this.$store.commit('setConnexion', true);
                this.$store.commit('setDeconnexion', false);
            },
            nePasReconnecter() {
                this.$store.commit('setConnexion', false);
                this.$store.commit('setDeconnexion', false);
            },
            montrerBoutonSecours() {
                if (this.boutonSecours) {
                    return;
                }
                if ((this.$store.getters.modeForet == 0 || this.$store.getters.modeForet == 1) && !this.messagesModeForet) {
                    this.boutonSecours = false;
                }
                else {
                    this.boutonSecours = true;
                }

            },
            swRegisteredAvailable(event) {
                console.log('swRegisteredAvailable');
                this.swEnregistre = true;
            },
            swErrorDetected(event) {
                console.log('swErrorDetected');
                this.swErreur = true;
            },
            updateAvailable(event) {
                console.log('swUpdatedDetected');
                //this.swUpdate = true;
                this.registration = event.detail;
                if (this.rafraichissementAutomatique && (this.$store.getters.modeForet == false)) {
                    this.refreshApp();
                }
                else { // on demande à l'utilisateur
                    this.updateExists = true;
                }
            },
            // Called when the user accepts the update
            refreshApp() {
                console.log('refreshApp');
                this.updateExists = false
                // Make sure we only send a 'skip waiting' message if the SW is waiting
                //if (!this.registration || !this.registration.waiting) return
                console.log('refreshApp OK');

                var thisObj = this;
                /**/
                navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for(let registration of registrations) {
                        registration.unregister(); // pris en compte uniquement après reload !!!!
                    }
                    console.log('serviceWorker unregister : ');
                    // send message to SW to skip the waiting and activate the new SW
                    if (thisObj.registration && thisObj.registration.waiting) {
                        thisObj.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                    }

                    console.log('refreshApp OK 1');

                    // BC 2021/04 - reload
                    if (!thisObj.rafraichirDemande) {
                        thisObj.rafraichir = true;
                    }
                    thisObj.rafraichirDemande = true;
                    return;
                })
/*
                // send message to SW to skip the waiting and activate the new SW
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })

                console.log('refreshApp OK 2');

                // BC 2021/04 - reload
                if (!this.rafraichirDemande) {
                    this.rafraichir = true;
                }
                this.rafraichirDemande = true;
*/

            },

            initialiserSteres() {
                // mode forêt : on ne charge rien, on doit tout avoir en mémoire
                if (this.$store.getters.modeForet == false) {
                    this.$store.commit('resetConnexionTimeMSec'); // 2022 on le fait une premiere fois pour tester la validité d'une éventuelle connexion
                    if (!this.$store.getters.utilisateurCourantId || !this.$store.getters.connexion) { // si déjà connecté, c'est un F5
                        return addeoData.lireDatasConstantes()
                        .then(allConstData => {
                            this.$store.commit('setProprieteChargeeId', 0); // on rechargera les UFs de la propriété
                            var allUserData = {"data":{"user_utilisateurs": []}};
                            return LovefieldService.loadData(allConstData, allUserData)
                            .then(datas => {
                                if (!datas) {
                                    console.log("no db");
                                    return
                                }
                                console.log("data loaded via LovefieldService : feu vert VUE");
                                this.lancer();
                            })
                        })
                    }
                    else {
                        setInterval(this.testerSynchronisationEtConnexion, 1000);
                    }
                }

                else {
                    this.bloquerF5();
                    // todo : mettre un else derrière et voir dans quel état on arrive - utilisation du store - de la base locale - et passge direct sur la propriété
                    // ...
                    setInterval(this.testerSynchronisationEtConnexion, 1000);
                    return;
                }
            },

            bloquerF5() {
                window.onbeforeunload = function(event) {
                    event.preventDefault();
                    return "Are you sure to exit?";
                }
            },
            debloquerF5() {
                window.onbeforeunload = function(event) {
                }
            },



            lancer() {
                this.$store.commit('setConstantesReady', false);
    //            this.$store.commit('setConnexionTimeMSec');

                console.log("lancer : testerSynchronisationEtConnexion");
                setInterval(this.testerSynchronisationEtConnexion, 1000);
                //setInterval(this.testerSynchronisationEtConnexion, 50000);

                // on stocke les constantes dans le STORE une fois pour toutes
                return ApiConsts.getConstSteres().then(responseVide => { // c'est incompréhensible - la première lecture ne renvoie rien
                return ApiConsts.getConstSteres().then(response => {
                    //this.$store.commit('setChargementAutorise', 1);
                    this.$store.commit('setDbVersion', SteresConst.REQUIRED_DATABASE_VERSION);
                    this.$store.commit('setConstantesSteres', response[0]);

                    return ApiConsts.getCalcBobi().then(response => {
                        this.$store.commit('setConstantesBobi', response);
                        this.repartitionBobi = response;

                        return ApiConsts.getCalcEclaircies().then(response => {
                            this.$store.commit('setConstantesAbaqueEclaircies', response);
                            this.abaqueEclaircies = response;
                            return ApiConsts.getConstantesPourEssence("PINMAR").then(response => { // Pin maritime uniquement
                                this.$store.commit('setConstantesParams', response);
                                this.$store.commit('setConstantesReady', true);
                            })
                        })
                    })
                })
                })
            },

            reinitialiserDatasUtilisateur(connexion) {
                if (!connexion) {
                    this.$store.commit('setProprieteId', 0);
                    this.$store.commit('setUfId', 0);
                    this.$store.commit('setPeuplementId', 0);
                    this.$store.commit('resetDataApplication');
                }
            },
            reinitialiserUtilisateur() {
                this.$store.commit('setProprieteId', 0);
                this.$store.commit('setUfId', 0);
                this.$store.commit('resetDataApplication');
            },
            testerSynchronisationEtConnexion() {
                var synchro = ApiUser.lireSynchro();
                var enLigne = addeoData.navigateurEnLigne();
                this.$store.commit('setSynchronisationPossible', enLigne && !synchro);
                this.$store.commit('setAccesInternet', enLigne);
                this.accesInternet = enLigne;
                // si pas en mode forêt
                if (!this.$store.getters.modeForet) {
                    this.$store.commit('resetConnexionTimeMSec');
                }
            },
            renseignerUtilisateur(utilisateurCourant, chargerUserDatas) {
                this.$store.commit('setUtilisateurCourant', utilisateurCourant);
                this.$store.commit('setUtilisateurCourantId', utilisateurCourant.id);

                if (chargerUserDatas) {
                    return addeoData.lireDatasUtilisateur(utilisateurCourant.id)
                    .then(response => {
                        if (response && response.retour == true) {
                            var userDatas = response.data
                            if (!userDatas.user_utilisateurs.length) { // si pas de propriété, tout est renvoyé à vide !
                                userDatas.user_utilisateurs[0] = utilisateurCourant;
                            }
                             return LovefieldService.importUserDatas(userDatas)
                            .then(response => {
                                return response
                             })
                        }
                    })
                }
            },
            connexion() {
                return(this.$store.getters.connexion);
            },
            synchronisationPossible() {
                return(this.$store.getters.synchronisationPossible);
            },
            rafraichirConnexion(status) {
                //this.authenticated = status;
                this.$store.commit('setConnexion', status);
                if (status) {
                    this.goConnected();
                }
            },
            goHome() {
                this.$router.push({ name: "home" });
            },
            goConnected() {
                this.$router.push({ name: "proprietes" });
            },

            versModeForet(etat) {
                // on sauvegarde l'état
                var saisieHorsLigneId = 0;
                if (etat) {
                    saisieHorsLigneId = this.$store.getters.utilisateurCourantId;
                    this.bloquerF5();
                }
                else  {
                    this.debloquerF5();
                    this.finModeForet = true;
                    this.$store.commit('setConnexionTimeMSec');
                    this.$store.commit('setProprieteChargeeId', 0); // on rechargera les UFs de la propriété
                }
                var propriete = this.$store.getters.infosPropriete;
                propriete.saisieHorsLigneId = saisieHorsLigneId;
                var entityToSave = JSON.parse(JSON.stringify(propriete));
                ApiUser.setPropriete(entityToSave)
                .then(response => {
                    //this.cacheModeForet(etat);
                    this.$store.commit('setModeForet', etat);
                    if (!etat) {
                        var utilisateurCourant = this.$store.getters.utilisateurCourant;
                        this.renseignerUtilisateur(utilisateurCourant, true)
                        .then(response => {
                            this.finModeForet = false;
                            this.$router.push({ name: "proprietes" });
                        })
                    }

                })
            },
/*
            cacheModeForet(etat) {

                var swf = `${process.env.BASE_URL}service-worker.js`;
                if (etat) {
                    swf = `${process.env.BASE_URL}service-worker-mf.js`;
                }
                var thisObj = this;

                navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for(let registration of registrations) {
                        registration.unregister();
                        console.log('serviceWorker unregister : ');
                    }
                    thisObj.cacheModeForetNom(swf, etat);
                })
            },

            cacheModeForetNom(swf, etat) {
                console.log('registerServiceWorker MF : ', etat)

                if (1 || (process.env.NODE_ENV === 'production')) {

                    //const registredEvent = new Event('swRegistered');
                    //const errorEvent = new Event('swError');
                    var thisObj = this;

                    console.log('registerServiceWorker production')
                    if ('serviceWorker' in navigator) {
                        navigator.serviceWorker.register(swf).then(function(registration) {
                        console.log('Registration succeeded.');

                            thisObj.$store.commit('setModeForet', etat);
                            if (!etat) {
                                var utilisateurCourant = thisObj.$store.getters.utilisateurCourant;
                                thisObj.renseignerUtilisateur(utilisateurCourant, true)
                                .then(response => {
                                    thisObj.finModeForet = false;
                                    thisObj.$router.push({ name: "proprietes" });
                                })
                            }
                            else {
                                thisObj.refreshApp();
                            }

                      }).catch(function(error) {
                        // registration failed
                        console.log('Registration failed with ' + error);
                      });

                    }
                }
            },
            */
            clicPourAvanceModeForet(classeDom, ordre) {
                var item = document.getElementsByClassName(classeDom);
                if (item.length <= ordre) {
                    return
                }
                var itemOrdre = item[ordre];
                itemOrdre.click();
            }
        },
    }
</script>
