
<script>
/*
<v-autocomplete
    hide-selected // ce paramètre empêche l'affichage de l'item déjà selectioné dans la liste des propositions ... NE PAS UTILISER
>
*/
</script>
<template>
  <v-autocomplete
    id="autocomplete-cp-ville"
    class="ajout-struct"
    :label="label"
    :items="items"
    v-model="cpVilleSelected"
    @update:searchInput="searchCPVille($event)"
    @change="selectCPVille($event)"
    @blur="onBlur"
    :item-text="itemText"
    :item-value="itemValue"
    :hint="hint"
    return-object
    hide-no-data
    :error-messages="errorMessage"
    attach="autocomplete-cp-ville"
    :clearable="clearable"
    @click:clear="clickClear($event)"
    >
          <template
      slot="item"
      slot-scope="{ item }"
      >
      <v-list-tile-content>
        <v-list-tile-title>{{ item.codePostal }} | {{ item.libelle }}</v-list-tile-title>
      </v-list-tile-content>
    </template>

  </v-autocomplete>
</template>

<script>
    import { ApiConsts } from "../../../js/api/index.js"
//import AddeoSpinnerCircle from '@common/vuejs/components/UI/Spinners/SpinnerCircle.vue'
//import {isStringEmpty} from '../../../js/utils/addeo_chaine.js'
/*
import {
  postData,
} from '@common/js/forms/forms'
*/
/*
import {
  createCancelTokenSource
} from '@common/api/myaxios'
*/

export default {
  props: {
    itemText: {
      type: String,
      default: 'label'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    /**
     * Item selectionné à l'init du component
     */
    initItem: {
      type: String,
      default() {
        return null
      }
    },
    label: {
      type: String,
      default: 'Code postal et Commune *'
    },
    hint: {
      type: String,
      default: 'Exemple: 40170 Lit-et-Mixe'
    },
    minNbCharacters: {
      type: Number,
      default: 1
    },
    errorMessageNoVille: {
      type: String,
      default: 'Aucune commune trouvée'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    /**
     * Le message d'erreur eventuel
     */
    errorMessage: {
      type: String,
      default: ''
    }
  },
  components: {
    //'a-spinner': AddeoSpinnerCircle,
  },
  created() {
    if (this.isStringEmpty(this.initItem)) {
        return
      }
      const initItem = JSON.parse(this.initItem)
      this.initSingleVille(initItem)
      this.cpVilleSelected = initItem
      //this.searchCPVille(initItem.label)
  },
  data() {
    return {
      ajaxVilles: [],
      /**
       * à true quand on est en train de faire une recherche
       */
      isLoading: false,
      /**
       * Ville choisie dans l'autocomplete
       */
      cpVilleSelected: null,
      /**
       * La recherche effectuée
       */
      search: ''
    }
  },
  watch: {
    initItem(newValue, oldValue) {
      this.initVille()
    },
    // errorMessages(newValue, oldValue) {
    //   console.log(newValue)
    //   this.errorMessage = newValue
    // }
  },
  computed: {
    items() {
        var villesVides = [];
      if (this.ajaxVilles.length === 0) {
        //return this.ajaxVillesVides
        return villesVides
      }

      return this.ajaxVilles
    }
  },
  methods: {
    initVille(){
      if (this.isStringEmpty(this.initItem)) {
        return
      }
      const initItem = JSON.parse(this.initItem)
      this.initSingleVille(initItem)
      this.cpVilleSelected = initItem
    },
    /**
     * Quand on selectionne une valeur dans la liste des propositions de code postal/ville
     * On emet un event au component parent
     */
    selectCPVille(villeObj) {
      if (villeObj === undefined) {
        return
      }
      // console.log(villeObj)
      // si jamais on veut enlever la liste des villes et ne garder que la ville choisie, attention cela enleve aussi le resultat selectionné (car il ne le retrouve pas dans la liste des possibles)
      this.initSingleVille(villeObj)

      const objectToEmit = {
        'codePostal_ville': villeObj,
        'codePostal': villeObj.codePostal,
        'codeComm': villeObj.codeComm,
        'libelle': villeObj.libelle
      }
      this.$emit('selectVille', objectToEmit)

      // console.log('emit ville selected', villeObj)
    },
    initSingleVille(villeObj) {
      this.ajaxVilles = [
        villeObj
      ]
    },
    /**
     * A chaque fois qu'on modifie la recherche, cette fonction se declenche
     */
    searchCPVille(search) {
      // if (isStringEmpty(search)) {
      //   this.reinitData()
      // }
      //this.reinitData()

      this.$emit('update:errorMessage', '')

      if (!this.canSearch(search)) {
        return
      }
      this.search = search

      // console.log('search lancé a chaque fois qu"on change la recherche search=', search)

      // annule les requetes anciennes si des requetes ajax ont déjà été lancées
      //this.cancelAjaxToken()

      // indicateur (spinner)
      this.isLoading = true

      // search_term est le nom de l'index attendu côté serveur dans _POST

        var myComponent = this;
        ApiConsts.getDatasCommunesSearch(search).then(responses => {
          if (responses) {
            myComponent.isLoading = false

            // on crée le label pour chaque en registrement
            responses.forEach(response=> {
                response.label = response.codePostal + " " + response.libelle;
            })

            myComponent.ajaxVilles = responses
            if (myComponent.ajaxVilles.length === 0) {
              myComponent.$emit('update:errorMessage', myComponent.errorMessageNoVille)
            }
          }
        })

    },
    canSearch(search) {
      if (this.isStringEmpty(search)) {
        return false
      }
      // si la recherche contient moins de {this.minNbCharacters}, on sort, cad on ne declenche qu'à partir de {this.minNbCharacters} caractères
      if (search.length < this.minNbCharacters) {
        return false
      }

      // si on a une ville selectionnée et qu'elle est identique à la valeur entrée dans la recherche, on ne relance pas la recherche
/**/
      if (!this.isStringEmpty(this.cpVilleSelected) && search === this.cpVilleSelected.label) {
        return false
      }
/**/

      return true
    },
    onBlur() {
      this.isLoading = false
    },

    reinitData() {
        //this.cpVilleSelected = null
        this.cpVilleSelected = ""
        this.ajaxVilles = []
    },

    clickClear() {
      /*
      this.cancelAjaxToken()
      */
      this.isLoading = false
      this.$emit('click:clear')
      this.ajaxVilles = []
    },
      isStringEmpty(str) { // TODO !!!
        if ((typeof str === "undefined")) {
            return true;
        }
        switch (str) {
            case "":
            case 0:
            case "0":
            case null:
            case false:
                return true;
            default:
                return false;
        }
    }
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .v-autocomplete {
        .v-input__icon--append {
            display:none;
        }
    }
</style>

