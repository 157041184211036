<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="peuplementEdition" v-if="$parent.connexionUtilisateurValide && !$parent.synchronisationModeForet && isReady" class="sousBandeauInterne" v-bind:class="{lectureSeule : $store.getters.lectureSeule}">
            <div id="infosAdministratives" class="sectionAffichage bandeauInterne">
                <div class="wrapper">

                    <div class="conteneur">

                        <table id="peuplementIdentification" class="blocACompleter">
                            <tr><th>Unité Forestière</th><th>Peuplement</th></tr>
                            <tr><td><h2>{{uf.identifiant}}</h2></td><td><h1>{{peuplement.anneeRetenue}}</h1></td></tr>
                        </table>

                        <span v-if="peuplement.id && !$store.getters.lectureSeule" class="complementBloc menuContextuel">
                            <span class="lien" @click="menuOuvert=true" ><i class="fas fa-pencil"></i></span>

                            <transition name="fade" :duration="1000">
                                <v-form v-if="menuOuvert">
                                    <span class="lien droiteAbs" @click="menuOuvert=false"><i class="fas fa-window-close"></i></span>
                                    <v-container fluid>
                                        <ul>
                                            <li v-if="peuplement.id"  @click.stop="ouvrirPeuplement">Modifier le peuplement</li>
                                            <li v-if="!peuplement.id && !$store.getters.modeForet"  @click.stop="menuOuvert=false">Ajouter un peuplement</li>
                                            <li v-if="peuplement.id && !$store.getters.modeForet"  @click.stop="supprimerPeuplementouPas">Supprimer le peuplement</li>
                                        </ul>
                                    </v-container>
                                </v-form>
                            </transition>
                        </span>

                        <div class="clear" ></div>

                        <div class="infosUF" v-if="dendrometrieAnnee"><i class="fas fa-asterisk"></i><span v-html="lesInfosUF"></span><br/><span v-html="lesInfosDendroUF"></span><br/><span v-html="lesInfosEurosUF"></span></div>

                        <v-dialog v-model="dialog" v-if="peuplement.id" >
                            <v-card>
                                <v-card-title class="headline">Suppression</v-card-title>

                                <v-card-text>
                                    Voulez-vous vraiment supprimer le peuplement {{ peuplement.identifiant }} ?<br/>Cette action est irréversible.
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="" text @click="dialog = false">Non</v-btn>
                                    <v-btn color="" text @click="supprimerPeuplement()">Oui</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialogDepressage" v-if="!infoDepressageDonnee && warningDepressage">
                            <v-card>
                                <v-card-title class="headline">Dépressage oublié ?</v-card-title>

                                <v-card-text>
                                    Attention, la densité de votre peuplement semble trop importante.<br/><br/>Vous pouvez corriger ce problème par la saisie d'une mesure antérieure à aujourd'hui.<br/>Si cette densité correspond à l'état réel de votre peuplement, vous pouvez choisir d'ignorer ce message.
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="" text @click="dialogDepressage = false;infoDepressageDonnee=true;">OK</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </div>
                </div>
            </div>


            <div id="infosTab" class="sectionAffichage">

                <transition name="fade" :duration="5000" >
                    <div class="messageInformation" v-if="messageInformation">
                        {{messageInformation}}
                    </div>
                </transition>

                <div class="wrapper">
                    <div class="conteneur">
                        <visualisation-parcelles-uf :data='laUF'></visualisation-parcelles-uf>
                    </div>
                </div>
            </div>

            <div id="infosPeuplement" class="sectionAffichage">
                <div class="wrapper">
                    <h2 class="titreListe suite" >Paramétrage</h2>
                    <div class="conteneur swap">
                        <div v-if="!lePeuplementValideInfos" class="messageInformation" >
                            Vous devez <span class="majuscules">impérativement</span> saisir une densité d'installation du peuplement ou bien  une mesure ou une éclaircie (et ses mesures) qui indiquera cette densité.
                        </div>
                        <div v-if="!lePeuplementValideAge" class="messageInformation" >
                            L'âge de votre peuplement a dépassé le maximum autorisé pour les calculs de STERES : {{$store.getters.constantes.params.const_essence.vieMax}} ans.
                        </div>
                        <h3 class="titreListe modes" v-bind:class="{decalageVertical : !lePeuplementValide, partieMilieu : laUF.peuplement.ITs.length > 1}" >
                            <span v-if="lePeuplementValide" @click.stop="voirItinerairesTechniques=false,voirDendrometrie=true,comparerITs=false" v-bind:class="{enLien : !voirDendrometrie,enCours : voirDendrometrie}" class="partieGauche lienAnnulerModeForet"><span>Historique</span><span class="ref"></span></span>
                            <span class="lienModeForet" v-if="lePeuplementValide" @click.stop="voirDendrometrie=false,voirItinerairesTechniques=true,comparerITs=false" v-bind:class="{enLien : !voirItinerairesTechniques,enCours : voirItinerairesTechniques, partieDroite : laUF.peuplement.ITs.length <= 1, partieMilieu : laUF.peuplement.ITs.length > 1}"><span>Itinéraires techniques</span><span class="ref"></span></span>
                            <span v-if="lePeuplementValide && laUF.peuplement.ITs.length > 1" @click.stop="voirDendrometrie=false,voirItinerairesTechniques=false,comparerITs=true" v-bind:class="{enLien : !comparerITs,enCours : comparerITs}" class="partieDroite"><span>Mode comparaison</span><span class="ref"></span></span>
                        </h3>

                        <dendrometrie-peuplement :data='laUF' v-if="voirDendrometrie && !comparerITs"></dendrometrie-peuplement>
                        <itineraires-techniques-peuplement :data='laUF'  @retourIt="onRetourIt" v-if="(voirItinerairesTechniques || comparerITs) && lePeuplementValide"></itineraires-techniques-peuplement>
                    </div>
                </div>
            </div>

            <div id="infosGraphisme" class="sectionAffichage" v-if="lePeuplementValide && !comparerITs">
                <div class="wrapper">
                    <h2 class="titreListe suite" >Visualisation</h2>

                    <div v-if="lItCourantObj" class="conteneur swap">

                        <h3 class="titreListe partieMilieu" >
                            <span @click.stop="voirCourbesFinancieres=false,voirCourbesDendrometrie=true,voirCourbesCarbone=false" v-bind:class="{enLien : !voirCourbesDendrometrie,enCours : voirCourbesDendrometrie}" class="partieGauche">Dendrométrie</span>
                            <span v-if="itCourantObj && itCourantObj.coupeRase" @click.stop="voirCourbesDendrometrie=false,voirCourbesFinancieres=false,voirCourbesCarbone=true" v-bind:class="{enLien : !voirCourbesCarbone,enCours : voirCourbesCarbone}" class="partieMilieu">Carbone</span>
                            <span @click.stop="voirCourbesDendrometrie=false,voirCourbesFinancieres=true,voirCourbesCarbone=false" v-bind:class="{enLien : !voirCourbesFinancieres,enCours : voirCourbesFinancieres}" class="partieDroite">Aide à la décision</span>
                        </h3>
                        <dendrometrie-peuplement-visu v-if="voirCourbesDendrometrie"></dendrometrie-peuplement-visu>
                        <financier-peuplement-visu v-if="voirCourbesFinancieres"></financier-peuplement-visu>
                        <carbone-peuplement-visu v-if="voirCourbesCarbone"></carbone-peuplement-visu>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>

import { SteresConstantes } from "../js/calculs/constantes.js"
import { ApiTools, ApiUser } from '../js/api/index.js'
import UfVisualisationParcelles from '../components/uf/UfVisualisationParcelles.vue'
import PeuplementDendrometrie from '../components/uf/PeuplementDendrometrie.vue'
import PeuplementItinerairesTechniques from '../components/uf/PeuplementItinerairesTechniques.vue'


import PeuplementDendrometrieVisualisation from '../components/uf/PeuplementDendrometrieVisualisation.vue'
import PeuplementFinancierVisualisation from '../components/uf/PeuplementFinancierVisualisation.vue'
import PeuplementCarboneVisualisation from '../components/uf/PeuplementCarboneVisualisation.vue'


export default {

    components: {
        'visualisation-parcelles-uf': UfVisualisationParcelles,
        'dendrometrie-peuplement': PeuplementDendrometrie,
        'itineraires-techniques-peuplement': PeuplementItinerairesTechniques,
        'financier-peuplement-visu': PeuplementFinancierVisualisation,
        'dendrometrie-peuplement-visu': PeuplementDendrometrieVisualisation,
        'carbone-peuplement-visu': PeuplementCarboneVisualisation
     },

    data() {
        return {
            voirDendrometrie:true,
            voirItinerairesTechniques:false,
            voirCourbesDendrometrie:true,
            voirCourbesFinancieres:false,
            voirCourbesCarbone:false,
            uf:null,
            itCourantObj:null,
            peuplement: {
                "userId":this.$store.getters.utilisateurCourantId,
                "id":0,
                "ufId":this.$store.getters.ufId,
                "dateInstallation":"",
                "cultureId":0,
                "essenceId":0,
                // "surface":0, ?
                "surfaceBoisee":0.0,
                "densiteInstallation":0.0,
                "densiteMethodeId":0,
                "densiteJson":"{}",
                "version":0,
				"idLocal":true,
            },
            menuOuvert: false,
            ready: false,
            dialog: false,
            comparerITs:false,
            dialogDepressage: false,
            infoDepressageDonnee:false
        }
    },
    created() {
        this.$store.commit('resetDataApplication');
    },

    mounted: function () {
        this.peuplementCourant();
        var paramsRoute = this.$route.params;
        if (paramsRoute && paramsRoute.voirDendrometrie) {
            this.voirDendrometrie = true;
            this.voirItinerairesTechniques = false;
        }

        setTimeout(this.datasApplicationCommit, 2000);

        if (this.$store.getters.modeForet == 5) {
            this.$store.commit('setModeForet', 6);
        }
        if (this.$store.getters.modeForet == 7) {
            this.$store.commit('setModeForet', 8);
        }
    },

    watch: {
        ready(newValue) {
            if(newValue) {
                var obj = null;
                var choisi = null;
                // en priorité, le enCours - du paramètrage d'IT - qui n'est pas forcément choisi
                this.uf.peuplement.ITs.forEach(it => {
                    if (it.choisi) {
                        choisi = it;
                    }
                    if (it.enCours) {
                        obj = it;
                    }
                })
                // on n'a pas d'IT en cours ...
                if (!obj) {
                    obj = choisi
                }

                this.itCourantObj = obj;

                // pour mettre à jour les affichages récapitulatifs du haut - on est obligé de le faire car l'IT en cours n'est pas forcément celui qui est choisi :(
                // remarque 2021/12 BC
                // cette modification induit un double calcul de la dendrométrie puisqu'il est suivi de visualiserDendrometrie
                // peut-être que dans visualiserDendrometrie, on pourrait se passer de "initialiserUfPeuplement"
                // à voir ...
                if (choisi == null) {
                    choisi = this.uf.peuplement.ITs[0];
                    choisi.choisi = true;
                    this.itCourantObj = choisi;
                }
                ApiUser.calculerUFIt(this.$store.getters.infosPropriete, this.uf, this.$store.getters.constantes, choisi);
            }
        },
        modeForetPreparation(newVal) {
            if (newVal == 6 && this.ready) {
                setTimeout(this.avancerVers6(), 245); // normalement on ne passe pas par là
            }
            if (newVal == 8 && this.ready) {
                setTimeout(this.avancerVers8(), 245); // normalement on ne passe pas par là
            }
        },
        isReady(newVal) {
            if (newVal && this.modeForetPreparation == 6) {
                setTimeout(this.avancerVers6, 245); // normalement on passe pas par là
            }
            if (newVal && this.modeForetPreparation == 8) {
                setTimeout(this.avancerVers8, 245); // normalement on passe pas par là
            }
        }

    },

    computed: {
        // uf.dendrometrieAnnee = UfPplDendrometrique.tabValeursCalculeeS[anneeCourante];
        dendrometrieAnnee() {

            if (typeof(this.uf.dendrometrieAnnee) === 'undefined') {
                return(null);
            }
             return(this.uf.dendrometrieAnnee);
        },
        lesInfosEurosUF() {
            if (!this.dendrometrieAnnee) {
                return("");
            }
            var peuplement = this.uf.peuplement;
            var eclaircieVirtuelle = ApiUser.retrouverEclaircieVirtuelle(this.dendrometrieAnnee.anneeCourante, peuplement, this.uf.eclaircies);
            var valeurSurPiedAnnees = this.uf.valeurSurPiedAnnees;
            if (eclaircieVirtuelle && (typeof(this.uf.valeurSurPiedAnneesAvantEclaircie) !== 'undefined')) {
                valeurSurPiedAnnees = this.uf.valeurSurPiedAnneesAvantEclaircie;
            }
            return("Valeur sur pied : <b>" + this.formaterEuros0(valeurSurPiedAnnees)
                   + "</b> - Valeur d'attente : <b>" + this.formaterEuros0(this.uf.valeurAttenteAnnees)+ "</b>");
        },
        lesInfosDendroUF() {
            if (!this.dendrometrieAnnee) {
                return("");
            }
            var peuplement = this.uf.peuplement;
            var dendrometrieAnnee = this.dendrometrieAnnee;
            var eclaircieVirtuelle = ApiUser.retrouverEclaircieVirtuelle(dendrometrieAnnee.anneeCourante, peuplement, this.uf.eclaircies);
            var messagePrecision = "";
            if (eclaircieVirtuelle) {
                messagePrecision = " (NB : l'éclaircie proposée n'est pas prise en compte dans ces valeurs)"
            }
            if ((typeof(dendrometrieAnnee.densiteAvantEclaircie) !== 'undefined') && dendrometrieAnnee.densiteAvantEclaircie && eclaircieVirtuelle) { // si éclaircie MAIS éclaircie virtuelle
                return("HMAX : <b>" + this.formaterHauteur(dendrometrieAnnee.HMAX)
                       + "</b> - Densité : <b>" + this.formaterDensite(dendrometrieAnnee.densiteAvantEclaircie)
                       + "</b> - Hauteur : <b>" + this.formaterHauteur(dendrometrieAnnee.hauteurAvantEclaircie)
                       + "</b> - Circonférence : <b>" + this.formaterCirconference(dendrometrieAnnee.circonferenceAvantEclaircie*100)
                       + "</b> - VU : <b>" + this.formaterVolume(dendrometrieAnnee.volumeUnitaireAvantEclaircie)
                       + "</b> - Volume : <b>" + this.formaterVolume(dendrometrieAnnee.volumeAvantEclaircie)+ "/ha</b>"
                      + messagePrecision);
            }
            return("HMAX : <b>" + this.formaterHauteur(dendrometrieAnnee.HMAX)
                   + "</b> - Densité : <b>" + this.formaterDensite(dendrometrieAnnee.densite)
                   + "</b> - Hauteur : <b>" + this.formaterHauteur(dendrometrieAnnee.hauteur)
                   + "</b> - Circonférence : <b>" + this.formaterCirconference(dendrometrieAnnee.circonference*100)
                   + "</b> - VU : <b>" + this.formaterVolume(dendrometrieAnnee.volumeUnitaire)
                   + "</b> - Volume : <b>" + this.formaterVolume(dendrometrieAnnee.volume)+ "/ha</b>");
        },
        lesInfosUF() {
            if (!this.dendrometrieAnnee) {
                return("");
            }
            var peuplement = this.uf.peuplement;
            var age = this.dendrometrieAnnee.anneeCourante;
            return("Age : <b>" + age + "</b> ans - Surface boisée : <b>" + this.formaterHectares(peuplement.surfaceBoisee)+ "</b>");
        },
        // UF à jour
        laUF() {
            return this.uf
        },
        // Peuplement à jour
        lePeuplement() {
            return this.peuplement
        },
        lePeuplementValideInfos() {
            if (this.peuplement.densiteInstallation || this.peuplement.mesures[0]  || (this.peuplement.eclaircies[0] && this.peuplement.eclaircies[0].circonferenceQuadratique && this.peuplement.eclaircies[0].hauteurMoyenne)) {
                return 1
            }
            return 0
        },
        lePeuplementValideAge() {
            if (this.dendrometrieAnnee && this.dendrometrieAnnee.anneeCourante <= this.$store.getters.constantes.params.const_essence.vieMax)  {
                return 1
            }
            return 0
        },
        lePeuplementValide() {
            if (this.lePeuplementValideInfos && this.lePeuplementValideAge) {
                return 1
            }
            this.peuplement.etatSteres = SteresConstantes.etatINVALIDE;
            //this.etatUtilisateur = 0;
            //this.etatUtilisateur = SteresConstantes.etatINVALIDE;
            return 0
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        messageInformation() {
            return this.$store.getters.messageInformation
        },

        lItCourantObj() {
            return(this.itCourantObj);
        },

        warningDepressage() {
        //dialogDepressage() {
            var retour = false;
            if (this.ready && ((this.peuplement.cultureCode == "SEMIS") || (this.peuplement.cultureCode == "SENAT")) ) {
                var infosDendrometriques = this.uf.dendrometrieAnnee;
                if (typeof(infosDendrometriques) !== 'undefined') {
                    var densiteAvantEclaircie = 0;
                    if (typeof(infosDendrometriques.densiteAvantEclaircie) !== 'undefined') {
                        densiteAvantEclaircie = infosDendrometriques.densiteAvantEclaircie
                    }
                    var tabDepressages = JSON.parse(this.$store.getters.constantes.params.const_essence.semisJson);
                    tabDepressages.forEach(depressageSemis => {
                        if ((infosDendrometriques.anneeCourante >= depressageSemis.annee)
                            && ((infosDendrometriques.densite > depressageSemis.densite) || (densiteAvantEclaircie > depressageSemis.densite)) ) {
                            retour = true;
                            this.dialogDepressage = true;
                        }
                    })
                    return retour;
                }
            }
            return false;
        },
        modeForetPreparation() {
            return(this.$store.getters.modeForet);
        },

    },

    methods: {
        avancerVers6() {
            this.$parent.clicPourAvanceModeForet('lienModeForet', 0);
            setTimeout(this.avancerVers6Suite, 245);
            //this.creerPeuplement();
        },
        avancerVers6Suite() {
            this.$parent.clicPourAvanceModeForet('lienAnnulerModeForet', 0);
            setTimeout(this.avancerVers6Fin, 245);
        },
        avancerVers6Fin() {
            this.ouvrirPeuplement();
        },



        avancerVers8() {
            this.$router.push({ name: "peuplementmesure" });
        },

        /*
        retrouverEclaircieVirtuelle(age) {
            var eclaircieTrouvee = null
            this.uf.peuplement.eclaircies.forEach(eclaircie => {
                if ((eclaircie.age == age) && (typeof(eclaircie.proposition) !== 'undefined') && eclaircie.proposition) {
                    eclaircieTrouvee = eclaircie;
                }
            })
            return(eclaircieTrouvee);
        },
        */
        /*
        anneeCourante() {
            var ladate=new Date();
            return(ladate.getFullYear());
        },
        */
        onRetourIt(itCourant) {
            this.itCourantObj = itCourant;
        },

        datasApplicationCommit() {
            this.$store.commit('majDataApplication');
            return(this.$store.getters.dataApplication)
            },
        // lecture en base et maj du ready
        peuplementCourant() {
            this.itCourantObj = null;
            this.$store.commit('setMesureId', 0);
            this.$store.commit('setEclaircieId', 0);
            this.$store.commit('setItEclaircieId', 0);
            // this.$store.commit('resetDataApplication'); // 2020/01/22 bug message
            var peuplementId = this.$store.getters.peuplementId;
            // on lit l'UF au passage
            var ufId = this.$store.getters.ufId;
            if (ufId > 0) {
                return ApiUser.lireUF(ufId).then(response => {
                    this.uf = response.uf;
                    this.uf.tabParcelles = response.parcelles;
                    this.uf.userId = this.$store.getters.utilisateurCourantId; // pour l'avoir sous la main
                    if (peuplementId > 0) {
                        return ApiUser.lirePeuplement(peuplementId, this.$store.getters.constantes).then(response => {
                            this.peuplement = response.peuplement;
                            this.uf.peuplement = response.peuplement;
                            this.$store.commit('setPeuplementInfos', this.peuplement);
                            this.$store.commit('setUfInfos', this.uf);  // 2020/07 mettre à jour car c'est l'UF qui est rechargée pour les affichages

                            var itId = this.$store.getters.itId;
                            if (itId) {
                                this.voirDendrometrie = false;
                                this.voirItinerairesTechniques = true;
                            }

                            this.ready = true;
                        })
                    }
                    else {
                        this.ready = true;
                    }
                })
            }
        },
        // fonctions sur boutons
        supprimerPeuplementouPas() {
            this.menuOuvert=false
            this.dialog = true;
        },
        supprimerPeuplement() {
            this.menuOuvert=false
            this.dialog = false;
            this.ready = false;
            var anneeRetenue = this.peuplement.anneeRetenue;

            return ApiUser.deletePeuplement(this.peuplement) // on detruit notre peuplement (en DB locale et SI POSSIBLE en ligne)
            .then(response => {
                this.$store.commit('donnerMessage', "Le peuplement "+anneeRetenue+" a bien été supprimé");
                //this.$store.commit('setUfId', 0);
                //this.$store.commit('resetDataApplication');
                this.$router.push({ name: "propriete" });
                return(response);
            })
        },
        ouvrirPeuplement() {
            this.menuOuvert=false
            var uf = this.uf;
            var peuplement = this.peuplement;

            this.$store.commit('setUfInfos', uf);
            this.$store.commit('setUfId', uf.id);
            this.$store.commit('setPeuplementInfos', peuplement);
            this.$store.commit('setPeuplementId', peuplement.id);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplementedition" })
        },
/*
        creerPeuplement() {
            var uf = this.uf;
            var idUF = uf.id;
            var idPeuplement = -1;
            var peuplement = null;
            this.$store.commit('setUfInfos', uf);
            this.$store.commit('setUfId', idUF);
            this.$store.commit('setPeuplementId', idPeuplement);
            this.$store.commit('resetDataApplication');
            this.$router.push({ name: "peuplementedition" })
        },
*/
        formaterHectares(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson, false)+" ha");
        },
        formaterHauteur(valeur) {
            return(ApiTools.arrondiHauteur(valeur, this.$store.getters.constantes.steres.arrondisJson, false)+" m");
        },
        formaterDensite(valeur) {
            return(ApiTools.arrondiDensite(valeur, this.$store.getters.constantes.steres.arrondisJson, false)+" tiges/ha");
        },
        formaterCirconference(valeur) {
            return(ApiTools.arrondiCirconference(valeur, this.$store.getters.constantes.steres.arrondisJson, false)+" cm");
        },
        formaterVolume(valeur) {
            return(ApiTools.arrondiVolume(valeur, this.$store.getters.constantes.steres.arrondisJson, false)+" m3");
        },
        formaterEuros(valeur) {
            return(ApiTools.formaterEuros(valeur));
        },
        formaterEuros0(valeur) {
            return(ApiTools.formaterEuros0(valeur));
        },

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
#app {

    .infosUF {
        font-size: 0.9em;
        padding-left:36px;
        .fa-asterisk {
            margin-left:-24px;
        }
    }
    #demanderComparaisonITs {
        float:right;
        z-index:2;
        &>div {
            padding: 1.45em 1em;
            border: 1px solid #223938;
            border-radius: 10px;
            background-color: #eee;
            display:inline-block;
            vertical-align: top;
            margin:0 0 0 1em;
            text-align: center;
            color: #238b71;
            font-weight: bold;
            cursor:pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .itineraires {
        /*max-width: 1100px;*/
    }

    table#peuplementIdentification  {
        th, td {
            padding:0 10px;
            margin:0;
        }
        th {
            font-size:0.8em;
            font-weight: normal;
        }
    }
    #infosPeuplement {
        background-color : #fafafa;
        padding-bottom: 0;
    }
    #infosGraphisme {
        padding-top: 0;
    }
    #infosPeuplement, #infosGraphisme {

        h2 {
            text-align: left;
            display:none;

        }
        .onglets {
            margin-bottom:2em;

            h2 {
                border-bottom: 6px solid #238b71;
                height:auto;
                padding-bottom:0px;
                margin-bottom:0;

                span {
                    display: inline-block;
                    /*padding-left: 1%;
                    padding-right: 1%;*/
                    text-align: center;
                    padding-bottom:2px;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &.enCours {
                        /*border-bottom: 6px solid #aaa;*/
                        width: 50%;
                        color:#F5FAF5;
                        background-color: #238b71;
                        border-radius: 10px 10px 0 0;
                    }

                    &.enLien {
                        cursor:pointer;
                        width: 50%;
                        color:#7DAB87;
                    }
                }
            }

            &>div {
                border-left: 6px solid #238b71;
                /*padding-left: 1em;*/
            }
        }
        .swap {

            h3 {
                height:auto;
                padding-bottom:0px;
                margin-bottom:0;
                max-width: 460px;
                margin: 2em auto;

                &.decalageVertical {
                    padding-top:1em;
                }

                @media only screen and (max-width: 700px) {
                    span {
                        font-size: 0.9em;
                    }
                }

                span {
                    display: inline-block;
                    /*padding-left: 1%;
                    padding-right: 1%;*/
                    text-align: center;
                    background-color: #223938;
                    color:#eeeeee;
                    /*height:2em;*/ /* BC 2022/09 crée un bug */
                    padding:0.3em 0;

                    &:first-of-type {
                    }

                    &.partieGauche, &.partieDroite {
                        span {
                            background-color: transparent;
                        }
                    }

                    &.partieGauche {
                        width: 50%;
                        border-radius: 10px 0 0 10px;
                    }
                    &.partieDroite {
                        width: 50%;
                        border-radius: 0 10px 10px 0;
                    }
                    &.partieMilieu {
                        box-sizing: border-box;
                        width: 33%;
                        border-left: 1px solid #223938;
                        border-right: 1px solid #223938;
                    }
                    &.partieMilieuVide {
                        width: 0 !important;
                        border-left: 1px solid #223938;
                        border-right: 1px solid #223938;
                    }

                    &.enCours {
                        /*border-bottom: 6px solid #aaa;*/
                        /*width: 50%;*/
                        position:relative;
                        &:before {
                            font-family: 'FontAwesome';
                            font-style: normal;
                            font-size:110%;
                            content:"";
                            margin-right:0.4em;
                        }
                        @media only screen and (max-width: 820px) {

                            &:before {
                                position:absolute;
                                top:50%;
                                transform:translateY(-50%);
                                left: 0.2em;
                                font-size: 100%;
                                display:none;
                            }
                        }
                    }

                    &.enLien {
                        color:#F5FAF5;
                        background-color: #238b71;
                        cursor:pointer;
                    }
                }

                &.partieMilieu {
                    max-width: 690px;
                    font-size: 1.15em;
                    text-align: center;
                    span {
                        &.partieGauche {
                            width: 32%;
                        }
                        &.partieDroite {
                            width: 32%;
                        }
                    }

                    &.modes{
                        span {
                            width: 30%;
                            padding:0.2em 0.4em;
                            vertical-align: middle;

                            span {
                                padding:0;
                                background-color: transparent;
                                text-align:center;
                                width: auto;
                                max-width: 99%;
                                height:auto;
                                &.ref {
                                    height:100%;
                                    width: 1px;
                                }
                            }
                            @media only screen and (max-width: 700px) {
                                height:4em;
                                span {
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .lectureSeule {
        .iconeFct {
            display:none;
        }
        .lien {
            cursor:default;
        }
    }

}
</style>
