<template>
 <div>

<v-dialog v-model="dialog"  persistent>
      <v-card>
        <v-card-title class="headline">Saisie de la série de hauteurs</v-card-title>

        <v-card-text>
            <p class="consigne">
                Vous devez saisir une ou des hauteurs comprises entre <span class="gras">{{min}} m</span> et <span class="gras">{{max}} m</span> dans le champ ci-dessous.<br/>
                Vous pouvez créer une nouvelle saisie à l'aide de "+ Ajouter une saisie" ou supprimer une saisie inutile à l'aide de l'icone "Poubelle".<br/>
                NB : pour une surface boisée de {{surfaceBoisee}} ha (soit {{nbPlacettes}} placettes), nous préconisons une série de {{nbPreconise}} mesures.
            </p>
        </v-card-text>

          <div class="modaleConteneur" v-if="ready">

              <div class="itemListEdit" v-for="(laSaisie, numSaisie) in lesSaisies" :key="numSaisie" >
                <span v-if="tabIds.length" class="pour-v-text-field messageError cacher" :id="tabIds[numSaisie]">Avant de valider à nouveau, vous devez saisir un nombre dans le champ ci-dessous ou utiliser la poubelle pour le supprimer. Pour chaque saisie, vous devez respecters les valeurs indiquées dans la consigne.</span>
                <v-text-field
                    :label="leLabel(numSaisie+1)"
                    v-model="form.tabHauteurs[numSaisie]"
                    type="number"
                     @keydown="filtrerNombresPositifs"
                    :min=min
                    :max=max
                    required
                    ref="numSaisie"
                ></v-text-field>
                <!-- <img class="iconeFct" alt="Supprimer saisie" src="@/assets/img/poubelle.svg" @click.stop="supprimerSaisie(numSaisie)" />  -->
                <span class="iconeFct svg"  @click.stop="supprimerSaisie(numSaisie)"><i class="fa fa-trash svg" aria-hidden="true"></i></span>
            </div>

        <a class="" @click="ajouterSaisieVide">+ Ajouter une saisie</a>

        </div>




        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  class="lienAnnulerModeForet" text @click="annulerSerie()">Annuler</v-btn>
          <v-btn  text @click="validerSerie()">Valider</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

</div>
</template>


<script>


import { ApiTools } from "../../../js/api/index.js"


export default {

 //   mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        initDatas: {
            type: Object
        },
        surfaceBoisee : Number,
        ouvrirDialog : Boolean,
    },

    data() {
        return {
            dialog: this.ouvrirDialog,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.initDatas)),
            old: null,
            tabIds:[],
            ready:false,
            min:3,
            max:30,
        }
    },

    created() {
        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        //this.old = ApiTools.clone(this.form);
        //this.dialog = true;

        this.remplirTabIds(100);
        this.ready = true;
        this.$nextTick(() => {
                this.$refs.numSaisie[0].focus();
            })
    },

    mounted() {
        // on donne le focus
        setTimeout(this.donnerFocus.bind(), 100);
    },

    validations() {

    },

    computed: {
        nbPreconise() {
            return (5*(this.nbPlacettes));
        },
        nbPlacettes() {
            return (3*(1+Math.floor((this.surfaceBoisee-0.0000001)/5)));
        },
        lesSaisies() {
            if (!this.form.tabHauteurs.length) {
                this.ajouterSaisieVide();
            }
            return this.form.tabHauteurs;
        }
    },

    methods: {
        leLabel(rang) {
            return("Hauteur mesurée (m) : " + rang + "/" + this.nbPreconise);
        },
        donnerFocus() {
            this.$nextTick(() => {
                this.$refs.numSaisie[0].focus();
            })
        },
        // gestion des saisies
        reinitialiserObjetTabHauteurs() {
            var tab = [];
            this.form.tabHauteurs = tab.concat(this.form.tabHauteurs);
        },
        ajouterSaisieVide() {
            var tabHauteurs = this.form.tabHauteurs;
            var position = tabHauteurs.length;
            tabHauteurs[position] = "";
            // pour forcer le reaffichage
            this.reinitialiserObjetTabHauteurs();
                        // on donne le focus quand on est prêt
            this.$nextTick(() => {
                this.$refs.numSaisie[position].focus();
            })

        },
        supprimerSaisie(numSaisie) {
            this.form.tabHauteurs.splice(numSaisie, 1);
            // pour forcer le reaffichage
            this.form.tabHauteurs = this.form.tabHauteurs.concat([]);

                        // on donne le focus s'il n'en reste qu'un
            if (this.form.tabHauteurs.length == 1) {
                this.$nextTick(() => {
                    this.$refs.numSaisie[0].focus();
                })
            }
        },


        validerSerie() {

            var ok = true;
            if ((this.form.tabHauteurs.length == 0) || ((this.form.tabHauteurs.length == 1) && this.form.tabHauteurs[0]=="")) {
                this.form.tabHauteurs = [];
            }
            else {
//            if (this.form.tabHauteurs.length > 1) {
                for (var i=0; i<this.form.tabHauteurs.length; i++) {
                    var eltMessage = document.getElementById("m"+i);
                    if (this.form.tabHauteurs[i] == "") {
                        ok = false;
                        ApiTools.toggleClassElt(eltMessage, 'removeClass', "cacher");
                    }
                    else {
                        var leNumber = parseFloat(this.form.tabHauteurs[i]);
                        if ((leNumber < this.min) || (leNumber > this.max)) {
                            ok = false;
                            ApiTools.toggleClassElt(eltMessage, 'removeClass', "cacher");
                        }
                        else {
                            this.form.tabHauteurs[i] = leNumber;
                            ApiTools.toggleClassElt(eltMessage, 'addClass', "cacher");
                        }
                    }
                }
            }
//            }

            if (ok) {
                this.$emit('retourHauteursSerie', this.form.tabHauteurs)
            }
        },
        annulerSerie() {
            this.dialog = false;
            this.form = JSON.parse(JSON.stringify(this.old));
            this.$emit('retourHauteursSerie', this.form.tabHauteurs)
        },

        // pour la saisie
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },


        // tableau rempli
        remplirTabIds(max) {
            for (var i = 0; i <= max; i++) {
                this.tabIds.push("m"+i);
            }
        },

    }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#app {
    .v-dialog {
        i.svg {
            font-size: 1.8em;
            float:none;
        }
    }
}
</style>
