import lf from 'lovefield'

export function user_ufpeuplement (schemaBuilder) {
  schemaBuilder.createTable('user_ufpeuplement')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('ufId', lf.Type.INTEGER)
      .addColumn('dateInstallation', lf.Type.STRING)
      .addColumn('cultureId', lf.Type.INTEGER)
      .addColumn('essenceId', lf.Type.INTEGER)
      .addColumn('surfaceBoisee', lf.Type.DOUBLE)
      .addColumn('densiteInstallation', lf.Type.DOUBLE)
      .addColumn('densiteMethodeId', lf.Type.INTEGER)
      .addColumn('densiteJson', lf.Type.STRING)
      .addColumn('travauxJson', lf.Type.STRING)
      .addColumn('eclaircieDensiteMin', lf.Type.INTEGER)
      .addColumn('etatSteres', lf.Type.INTEGER)
      .addColumn('etatUtilisateur', lf.Type.INTEGER)
      .addColumn('version', lf.Type.INTEGER)
      .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon)
      .addPrimaryKey(['id'], false)
      .addNullable(['idLocal']);
}
