<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="UfEdition" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="propriete.id" class="blocACompleter">{{propriete.identifiant}}</h1>
                <h1 v-if="!propriete.id" class="blocACompleter">Création d'une propriete</h1>

                <p class="intro">Saisir les données de la propriété qui vous appartient ou que vous gérez</p>

                <p class="info">Les champs suivis d'une * sont obligatoires.</p>

                <div class="edition">
                    <edition-propriete :data='laPropriete'></edition-propriete>
                    <div class="illustration illustration1"></div>
                </div>
            </div>

        </div>
        <div class="clear"></div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import ProprieteEditionForm from '../components/propriete/ProprieteEditionForm.vue'

export default {

    components: {
        'edition-propriete': ProprieteEditionForm,
     },

    data() {
        return {
            propriete: {
                "userId":this.$store.getters.utilisateurCourantId,
                "id":0,
                "gestionnaireId":this.$store.getters.utilisateurCourantId, // seul le proprietaire peut creer sa propriété
                "identifiant":"",
                "adresse":"",
                "codePostal":"",
                "libelleCommune":"",
                "codeCommune":"",
                "telephone":"",
                "adresseMail":"",
                "siret":"",
                "tauxActualisation":0,
                "proprietaireId":0,
                "saisieHorsLigneId":0, // TODOFORET
                "proprietaireDroitEcriture":0,
                "ayantsDroitJson":"[]",
                "infosProprietaire":"",
                "depensesJson":"[]",
                "tarifsJson":"",
                "version":0,
				"idLocal":true,
            },
            ready: false,
            //constantes: this.$store.getters.constantes.steres
            constantesReady: false
        }
    },
    created() {
    },

    mounted: function () {
        this.constantesReady = this.$store.getters.constantes.ready;
        // this.proprieteCourante(); faity dans le watch quand les constantes sont prêtes
    },
    watch: {
        constantesReady(newValue, oldValue) {
            if (newValue) {
                if (this.$store.getters.proprieteId <= 0) {
                    this.propriete.tauxActualisation = this.$store.getters.constantes.steres.tauxActualisation;
                    this.propriete.depensesJson = ApiUser.initialiserDepenses(this.$store.getters.constantes);
                }
                this.proprieteCourante(this.$store.getters.constantes);
            }
        }
    },

    computed: {
        // Propriete à jour
        laPropriete() {
            return this.propriete
        },
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
    },

    methods: {
        // lecture en base et maj du ready
        proprieteCourante(constantes) {
            var proprieteId = this.$store.getters.proprieteId;
            if (proprieteId > 0) {
                return ApiUser.lirePropriete(proprieteId, constantes).then(response => {
                    this.propriete = response.propriete;
                    this.propriete.userId = this.$store.getters.utilisateurCourantId; // pour l'avoir sous la main

                    if (this.propriete.gestionnaireId != this.propriete.proprietaireId) {
                        return ApiUser.lireUtilisateur(this.propriete.proprietaireId).then(response => {
                            this.propriete.proprietaire = response.utilisateur;
                            this.ready = true;
                            })
                        }
                    else {
                        this.ready = true;
                    }
                })
            }
            else {
                this.ready = true;
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #app {
        div.itemsList {
            border-left:none;

            .itemListEdit  {
                margin:1em 1em 1em 0;
                border-left:#238b71 solid 3px;
            }
        }
    }
</style>



