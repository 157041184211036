
import { ApiTools } from "../api/index.js"

//*********************************
class UF_PPL_CARBONE {
    constructor() {
        this.ppl = null;
        this.tabValeursCalculeeS = [];
        this.ready = false;
        }

		//*********************************
    initialiserUfCarbone(dendrometrie, constantes) {

        // init et raccourcis
        this.dendrometrie = dendrometrie;
        this.ppl = dendrometrie.ppl;
        this.constantes = constantes;
        this.maxSequestration = 0.0;
        this.maxStockage = 0.0;
        this.maxSubstitution = 0.0;
        this.maxTotal = 0.0;

        this.tabValeursCalculeeS = [];

        this.constantesSteres = dendrometrie.constantesSteres;

        // raccourci pour gain de temps
        this.constantesSteres = this.ppl.uf.propriete.constantesSteres;

        // calcul carbone à partir des infos dendrométriques
        this.calculerCarbone();

        return(this);
    }


		//*********************************
    calculerCarbone(anneeFinCalculs = 0) {

        const anneeCoupeRase = this.ppl.it.coupeRase;
        if (!anneeCoupeRase) {
            // traitement à prévoir
            return;
        }


          // les params qu'on passera au "calculateur" (une fois pour toutes)
        var paramsCalculSource = {};
        paramsCalculSource.infradensite = this.ppl.essence.infradensite;
        paramsCalculSource.tauxCarboneBiomasse = this.ppl.essence.tauxCarboneBiomasse;
        paramsCalculSource.carboneSolLitiere = this.leTerroirCarboneSolLitiere(this.ppl.uf.terroirId);
        // volumeAerienJson
        paramsCalculSource.calcVolumeAerien = JSON.parse(this.ppl.essence.volumeAerienJson);
        // biomasseRacinaireJson
        paramsCalculSource.calcBiomasseRacinaire = JSON.parse(this.ppl.essence.biomasseRacinaireJson);
        // produitsBoisJson
        paramsCalculSource.produitsBois = JSON.parse(this.ppl.essence.produitsBoisJson);
        // substitutionCarboneJson
        paramsCalculSource.substitutionCarbone = JSON.parse(this.ppl.essence.substitutionCarboneJson);
        // massesMolairesJson
        paramsCalculSource.massesMolaires = JSON.parse(this.constantesSteres.massesMolairesJson);


        this.paramsCalculSource = paramsCalculSource;
        const maxCpt = this.dendrometrie.tabValeursCalculeeS.length;

        for(var cptAges = 0; cptAges < maxCpt; cptAges++) {
            this.tabValeursCalculeeS[cptAges] = this.calculerCarboneAge(cptAges);
        }
        // il faut peut-être aller plus loin si on est sur plusieurs cycles
        if (anneeFinCalculs && anneeFinCalculs >= maxCpt) {
            const flux = false;
            for(var cptAges = maxCpt; cptAges <= anneeFinCalculs+1; cptAges++) {
                this.tabValeursCalculeeS[cptAges] = {};
                // STOCKAGE
                this.tabValeursCalculeeS[cptAges].stockageCarboneExportes = this.calculerStockCarboneExportes(cptAges, false);
                this.tabValeursCalculeeS[cptAges].carboneStocke = this.tabValeursCalculeeS[cptAges].stockageCarboneExportes.carboneTotal;
                // SUBSTITUTION
                this.tabValeursCalculeeS[cptAges].stockageCarboneSubstitue = this.calculerSubstitutionCarbone(cptAges, false);
                this.tabValeursCalculeeS[cptAges].carboneSubstitue = this.tabValeursCalculeeS[cptAges].stockageCarboneSubstitue.carboneTotalSub;
                // SEQUESTRATION
                this.tabValeursCalculeeS[cptAges].carboneSequestre = this.paramsCalculSource.carboneSolLitiere;
                this.tabValeursCalculeeS[cptAges].carboneSequestreSol = this.paramsCalculSource.carboneSolLitiere;
            }
        }
    }

		//*********************************
        leTerroirCarboneSolLitiere(terroirId) {
            var lId = "id"+terroirId;
            if (!this.constantes) {
                return(0);
            }
            var terroirObj = this.constantes.params.const_terroirs[lId];
            if (!terroirObj) {
                return(0);
            }
            return(terroirObj.carboneSolLitiere);
        }

		//*********************************
    // Carb_seq = (Bio_aer + Bio_rac) x Tx_Carb + Carb_sol
    // 	Carb_seq est exprimé en tonne  C / Ha

    calculerCarboneAge(age) {

        const ageCoupeRase = this.ppl.it.coupeRase;

          // le param qu'on passera au "calculateur"
        var retoursCalcul = {};
        this.retoursCalcul = retoursCalcul; // pour utilisation "à la volée" des retours en cours


        // CARBONE DANS PARCELLE : SEQUESTRATION
        // volume aerien
        retoursCalcul.volumeAerien = this.calculerVolumeAerien(age);
        // biomasse  aérienne
        retoursCalcul.biomasseAerienne = this.calculerBiomasseAerienne(age);
        // biomasse  racinaire
        retoursCalcul.biomasseRacinaire = this.calculerBiomasseRacinaire(age);
        // total
        retoursCalcul.carboneSequestreSol = this.paramsCalculSource.carboneSolLitiere;
        //if (age >= ageCoupeRase) {
        if (age > ageCoupeRase) {
            retoursCalcul.carboneSequestre = this.paramsCalculSource.carboneSolLitiere;
        }
        else {
            retoursCalcul.carboneSequestre = (retoursCalcul.biomasseAerienne+retoursCalcul.biomasseRacinaire) * this.paramsCalculSource.tauxCarboneBiomasse + this.paramsCalculSource.carboneSolLitiere;
        }


        // CARBONE DANS PRODUITS : STOCKAGE
        retoursCalcul.fluxCarboneExportes = this.calculerFluxCarboneExportes(age);
        if (!age) {
            retoursCalcul.stockageCarboneExportes = {};
            retoursCalcul.stockageCarboneExportes.carbBo = 0;
            retoursCalcul.stockageCarboneExportes.carbPan = 0;
            retoursCalcul.stockageCarboneExportes.carbPap = 0;
            retoursCalcul.stockageCarboneExportes.carboneTotal = 0;
            }
        else {
            /*
            if ((!this.dendrometrie.eclaircies[age]) && (ageCoupeRase != age)) {
                retoursCalcul.stockageCarboneExportes = {};
                retoursCalcul.stockageCarboneExportes.carbBo = 0;
                retoursCalcul.stockageCarboneExportes.carbPan = 0;
                retoursCalcul.stockageCarboneExportes.carbPap = 0;
            }
            else {
                retoursCalcul.fluxCarboneExportes = this.calculerFluxCarboneExportes(age);
            }
            */
            // Calculs stockage
            const flux = true;
            retoursCalcul.stockageCarboneExportes = this.calculerStockCarboneExportes(age, flux);
        }
        retoursCalcul.carboneStocke = retoursCalcul.stockageCarboneExportes.carboneTotal;


        // CARBONE SUBSTITUE
            // Calculs substitution
        retoursCalcul.stockageCarboneSubstitue = this.calculerSubstitutionCarbone(age, true);
        retoursCalcul.carboneSubstitue = retoursCalcul.stockageCarboneSubstitue.carboneTotalSub;

        this.maxSequestration = Math.max(this.maxSequestration, retoursCalcul.carboneSequestre);
        this.maxStockage = Math.max(this.maxStockage, retoursCalcul.carboneStocke);
        this.maxSubstitution = Math.max(this.maxSubstitution, retoursCalcul.carboneSubstitue);
        this.maxTotal = Math.max(this.maxTotal, retoursCalcul.carboneSubstitue + retoursCalcul.carboneStocke + retoursCalcul.carboneSequestre);

        return(retoursCalcul);
    }

		//*********************************
    // Calcul du volume aérien total à l'age n
    // 	V_tot = H x C2/ 4PI /(1- 1,30/H)2 x (0,396 + 1,756 x √C /H - 0,002 x H/C ) x densité
    // NB : V_tot est le volume total en m3/Ha, H la hauteur totale moyenne en m, C la circonférence moyenne en m et densité le nombre de tiges/Ha du peuplement
    calculerVolumeAerien(age) {
            var hauteur = this.dendrometrie.tabValeursCalculeeS[age].hauteur;
            var circonference = this.dendrometrie.tabValeursCalculeeS[age].circonference;
            var densite = this.dendrometrie.tabValeursCalculeeS[age].densite;
        // cas particulier si eclaircie
        if (this.dendrometrie.eclaircies[age] && age > this.dendrometrie.infosFinInstallation.age) {
            hauteur = this.dendrometrie.tabValeursCalculeeS[age].hauteurAvantEclaircie;
            circonference = this.dendrometrie.tabValeursCalculeeS[age].circonferenceAvantEclaircie;
            densite = this.dendrometrie.tabValeursCalculeeS[age].densiteAvantEclaircie;
        }


        if (!hauteur || circonference <= 0 || densite <= 0 ) {
            return(0);
        }
/*
        if (age == 11) {
            console.log("hauteur, circonference, densite", hauteur, circonference, densite);
        }
*/
        var volumeAerienTotal = hauteur*circonference*circonference;
        volumeAerienTotal /= (4 * this.constantesSteres.PI);
        var tmp1 = 1-1.3/hauteur;
        tmp1 *= tmp1;
        volumeAerienTotal /= tmp1;
        const tmp2 = this.paramsCalculSource.calcVolumeAerien.a + this.paramsCalculSource.calcVolumeAerien.b * Math.sqrt(circonference) / hauteur + this.paramsCalculSource.calcVolumeAerien.c * hauteur / circonference;
        volumeAerienTotal *= tmp2;
        volumeAerienTotal *= densite;
        return(volumeAerienTotal);
    }
		//*********************************
    // Calcul de la biomasse  aérienne à l'age n
    // Bio_aer = V_tot x Infrad   exprimée en tonne de MS / Ha
    calculerBiomasseAerienne(age) {
        const volumeAerienTotal = this.retoursCalcul.volumeAerien;
        const biomasseAerienne = volumeAerienTotal * this.paramsCalculSource.infradensite;
        return(biomasseAerienne);
    }
		//*********************************
    // Calcul de la biomasse  racinaire à l'age n
    // Bio_rac = Exp( -1,0587 +  0,8836 x LN(Bio_aer) + 0,2840 )
    // Bio_rac est exprimée en tonne de MS / Ha
    // NB : Exp est la fonction mathématique exponentielle, LN est la fonction mathématique Logarithme népérien
    calculerBiomasseRacinaire(age) {
        const biomasseAerienne = this.retoursCalcul.biomasseAerienne;
        const tmp1 = this.paramsCalculSource.calcBiomasseRacinaire.a + this.paramsCalculSource.calcBiomasseRacinaire.b * Math.log(biomasseAerienne) + this.paramsCalculSource.calcBiomasseRacinaire.c;
        const biomasseRacinaire = Math.exp(tmp1);
        return(biomasseRacinaire);
    }

    		//*********************************
        infosBobi(volumeUnitaireMoyen) {

            const tmp = this.constantes.repartitionBobi;

            const idVU = Math.trunc(volumeUnitaireMoyen*10);
            var lId = "vuMin"+idVU;
            if (!this.constantes || !this.constantes.repartitionBobi || !this.constantes.repartitionBobi.length) {
                return(null);
            }
            var bobiObj = this.constantes.repartitionBobi[lId];
            if (!bobiObj) {
                return(this.constantes.repartitionBobi[this.constantes.repartitionBobi.length-1]); // le plus grand si on dépasse
            }
            return(bobiObj);

        }


		//*********************************
    // Calcul des Flux de carbone exportés lors des coupes réalisées à l'age n
    // Lors de chaque éclaircie ou de la coupe rase finale souhaitée, on connaît le volume unitaire moyen des arbres enlevé Vu_exp (en m3), le nombre d'arbres enlevés d_exp (en tige/Ha) et donc le volume total de bois exporté V_exp en m3 ( = Vu_exp x d_exp) .
    // Flux_carb (n) = V_exp (n) x Infrad x Tx_Carb
    // Flux_carb est exprimé en tonne eq C /Ha, initialisé à zéro pour age=0
    // si pas de coupe à l'age n Flux_carb = 0
    // A partir du volume unitaire moyen des arbres coupés Vu on extrait de la table Tab(Vu)  les proportions de BO et de BI, soit %BO et %BI, pour répartir ensuite le flux global dans trois
    // Flux_BO (n) = Flux_carb(n) x %BO x Rndt_BO
    // Flux_Pan (n)= Flux_carb(n) x  [ %BI x %Pan + (1-Rndt_BO) x %Pan_connexe]
	// Flux_Pap (n) = Flux_carb(n) x [ %BI x (100 - %Pan) + (1-Rndt_BO) x (1- %Pan_connexe - %BE_connexe)]
    // Correction 30/09
    // Flux_Pan (n)= Flux_carb(n) x  [ %BI x %Pan + %BO x (1-Rndt_BO) x %Pan_connexe]
    // Flux_Pap (n) = Flux_carb(n) x [ %BI x (1 - %Pan)]


    // ces Flux sont exprimés en tonne eq C /Ha
    calculerFluxCarboneExportes(age) {

        var fluxCarboneExportes = {};
        // test = dans l'année : pas d'éclaircie (les depressage ne sont pas pris en compte) et pas de coupe rase
        if (((!this.dendrometrie.eclaircies[age] || this.dendrometrie.tabValeursCalculeeS[age].volumeRecolteEclaircie <= 0) || age <= this.dendrometrie.infosFinInstallation.age) && (age != this.ppl.it.coupeRase)) {
            fluxCarboneExportes.fluxCarbone = 0;
            fluxCarboneExportes.fluxBo = 0;
            fluxCarboneExportes.fluxPan = 0;
            fluxCarboneExportes.fluxPap = 0;
            return(fluxCarboneExportes);
        }
        var volumeUnitaireRecolte = 0;
        var volumeRecolte = 0;
        if (this.dendrometrie.eclaircies[age]) { // vérifier qu'on dispose toujours de ces valeurs
            volumeUnitaireRecolte = this.dendrometrie.tabValeursCalculeeS[age].volumeUnitaireEclaircie;
            volumeRecolte = this.dendrometrie.tabValeursCalculeeS[age].volumeRecolteEclaircie;
        }
        if (age == this.ppl.it.coupeRase) {
            volumeUnitaireRecolte = this.dendrometrie.tabValeursCalculeeS[age].volumeUnitaire;
            volumeRecolte = this.dendrometrie.tabValeursCalculeeS[age].volume;
        }

        const substitutionCarbone = this.paramsCalculSource.substitutionCarbone;
        const infosBobi = this.infosBobi(volumeUnitaireRecolte);
        fluxCarboneExportes.volumeRecolte = volumeRecolte;
        fluxCarboneExportes.fluxCarbone = fluxCarboneExportes.volumeRecolte * this.paramsCalculSource.infradensite * this.paramsCalculSource.tauxCarboneBiomasse;
        // fluxBo
        fluxCarboneExportes.fluxBo = fluxCarboneExportes.fluxCarbone * infosBobi.pourcentBoTotal * substitutionCarbone.rendementBo;
        // fluxPan
        // fluxCarboneExportes.fluxPan = fluxCarboneExportes.fluxCarbone * (infosBobi.pourcentBi * this.paramsCalculSource.produitsBois.pourcentPan + (1-substitutionCarbone.rendementBo) * substitutionCarbone.proportionConnexesPanneaux);
        fluxCarboneExportes.fluxPan = fluxCarboneExportes.fluxCarbone * (infosBobi.pourcentBi * this.paramsCalculSource.produitsBois.pourcentPan + infosBobi.pourcentBoTotal * (1-substitutionCarbone.rendementBo) * substitutionCarbone.proportionConnexesPanneaux);
        // fluxPap
        // fluxCarboneExportes.fluxPap = fluxCarboneExportes.fluxCarbone * (infosBobi.pourcentBi * (1-this.paramsCalculSource.produitsBois.pourcentPan) + (1-substitutionCarbone.rendementBo) * (1- substitutionCarbone.proportionConnexesPanneaux - substitutionCarbone.proportionConnexesBe));
        fluxCarboneExportes.fluxPap = fluxCarboneExportes.fluxCarbone * (infosBobi.pourcentBi * (1-this.paramsCalculSource.produitsBois.pourcentPan));
        return(fluxCarboneExportes);
    }

		//*********************************
    // Calcul du stockage carbone dans les produits bois récoltés au fil des années
    // Pour tenir compte de la décomposition des produits bois au fil des années et donc de la perte de carbone correspondante, on calcule  dans chacun des 3 compartiments le carbone stocké à l'age n dans les produits bois Stock_carb(n) comme suit :
    // A l'age = 0 on initialise dans chaque catégorie Stock_carb(0) = 0
    // A l'age n+1, on calcule Stock_carb(n+1) dans chaque catégorie :
    // Stock_carb_BO(n+1) = Stock_carb_BO(n) x Exp(-kbo) + (1-Exp(-kbo))/kbo x Flux_BO(n)
    // Stock_carb_Pan(n+1) = Stock_carb_Pan(n) x Exp(-kpan) + (1-Exp(-kpan)) / kpan x Flux_Pan(n)
    // Stock_carb_Pap (n+1) = Stock_carb_Pap(n) x Exp(-kpap)	+ (1-Exp(-kpap))/kpap x Flux_Pap(n)
    // On additionne à chaque age n les Stock_carb(n) des 3 compartiments pour obtenir le carbone stocké total à l'age n noté Carb_stock(n) exprimé en tonne eq C /Ha :
    // Carb_stock(n) = Stock_carb_BO(n) + Stock_carb_Pan(n) + Stock_carb_Pap (n)
    // NB : le carbone stocké dans les produits bois récoltés issus des coupes augmente
    // donc lors de chaque coupe puis décroît ensuite avec le temps entre chaque coupe
    calculerStockCarboneExportes(age, flux) {
        const valeursCalculeesPred = this.tabValeursCalculeeS[age-1];
        var stockageCarboneExportes = {};
        const expMoinsKbo = Math.exp(-1*this.paramsCalculSource.produitsBois.kbo);
        const fluxBoN = !flux ? 0 : valeursCalculeesPred.fluxCarboneExportes.fluxBo;
        //const fluxBoN = this.retoursCalcul.fluxCarboneExportes.fluxBo;
        stockageCarboneExportes.carbBo = valeursCalculeesPred.stockageCarboneExportes.carbBo * expMoinsKbo + (1-expMoinsKbo) / this.paramsCalculSource.produitsBois.kbo * fluxBoN;
        const expMoinsKpan = Math.exp(-1*this.paramsCalculSource.produitsBois.kpan);
        const fluxPanN = !flux ? 0 : valeursCalculeesPred.fluxCarboneExportes.fluxPan;
        //const fluxPanN = this.retoursCalcul.fluxCarboneExportes.fluxPan;
        stockageCarboneExportes.carbPan = valeursCalculeesPred.stockageCarboneExportes.carbPan * expMoinsKpan + (1-expMoinsKpan) / this.paramsCalculSource.produitsBois.kpan * fluxPanN;
        const expMoinsKpap = Math.exp(-1*this.paramsCalculSource.produitsBois.kpap);
        const fluxPapN = !flux ? 0 : valeursCalculeesPred.fluxCarboneExportes.fluxPap;
        //const fluxPapN = this.retoursCalcul.fluxCarboneExportes.fluxPap;
        stockageCarboneExportes.carbPap = valeursCalculeesPred.stockageCarboneExportes.carbPap * expMoinsKpap + (1-expMoinsKpap) / this.paramsCalculSource.produitsBois.kpap * fluxPapN;
        stockageCarboneExportes.carboneTotal = stockageCarboneExportes.carbBo + stockageCarboneExportes.carbPan + stockageCarboneExportes.carbPap;
        return(stockageCarboneExportes);
    }

		//*********************************
    // Calcul du Carbone substitué par les produits bois récoltés lors de chaque coupe
    // Subst_carb(n) = [ Vu_exp(n) x %BO x Rndt_BO x Coef_bo + Vu_exp(n) x %BO x (1- Rndt_BO) x %Pan_connexe x Coef_pan + Vu_exp(n) x %BO x (1- Rndt_BO) x %BE_connexe x Coef_be + Vu_exp(n) x %BI x %Pan x Coef_pan ] x d_exp(n) x 12/44
    calculerSubstitutionCarbone(age, flux) {


        var stockageCarboneSubstitue = {};
        const ageCoupeRase = this.ppl.it.coupeRase;

        // cas simples
        // test = dans l'année : pas d'éclaircie (les depressage ne sont pas pris en compte) et pas de coupe rase
        if (!age) {
            stockageCarboneSubstitue.carboneTotalSub = 0;
            return(stockageCarboneSubstitue);
        }
        /*
        if (((!this.dendrometrie.eclaircies[age]) || age <= this.dendrometrie.infosFinInstallation.age) && (age != ageCoupeRase)) {
            stockageCarboneSubstitue.carboneTotalSub = this.tabValeursCalculeeS[age-1].stockageCarboneSubstitue.carboneTotalSub;
            return(stockageCarboneSubstitue);
        }
        */
        const anneePrecedente = age-1;
        if (((!this.dendrometrie.eclaircies[anneePrecedente]) || age <= this.dendrometrie.infosFinInstallation.age) && (anneePrecedente != ageCoupeRase)) {
            stockageCarboneSubstitue.carboneTotalSub = this.tabValeursCalculeeS[age-1].stockageCarboneSubstitue.carboneTotalSub;
            return(stockageCarboneSubstitue);
        }


        // calculs
        var volumeUnitaireRecolte = 0;
        var volumeRecolte = 0;
        if (this.dendrometrie.eclaircies[anneePrecedente]) { // vérifier qu'on dispose toujours de ces valeurs
            const volumeUnitaireRecolteTmp = this.dendrometrie.tabValeursCalculeeS[anneePrecedente].volumeUnitaireEclaircie;
            const volumeRecolteTmp = this.dendrometrie.tabValeursCalculeeS[anneePrecedente].volumeRecolteEclaircie;
            if ((typeof(volumeUnitaireRecolteTmp) !== 'undefined') && (typeof(volumeRecolteTmp) !== 'undefined')) {
                volumeUnitaireRecolte = volumeUnitaireRecolteTmp;
                volumeRecolte = volumeRecolteTmp;
            }
        }
        if (anneePrecedente == ageCoupeRase) {
            volumeUnitaireRecolte = this.dendrometrie.tabValeursCalculeeS[anneePrecedente].volumeUnitaire;
            volumeRecolte = this.dendrometrie.tabValeursCalculeeS[anneePrecedente].volume;
        }

        const infosBobi = this.infosBobi(volumeUnitaireRecolte);
        const substitutionCarbone = this.paramsCalculSource.substitutionCarbone;

        stockageCarboneSubstitue.carboneSubstituable = volumeUnitaireRecolte * infosBobi.pourcentBoTotal * substitutionCarbone.rendementBo * substitutionCarbone.coefBo;

        const tmp1 = volumeUnitaireRecolte * infosBobi.pourcentBoTotal * (1-substitutionCarbone.rendementBo) * substitutionCarbone.proportionConnexesPanneaux * substitutionCarbone.coefPan;
        stockageCarboneSubstitue.carboneSubstituable += tmp1;

        const tmp2 = volumeUnitaireRecolte * infosBobi.pourcentBoTotal * (1-substitutionCarbone.rendementBo) * substitutionCarbone.proportionConnexesBe * substitutionCarbone.coefBe;
        stockageCarboneSubstitue.carboneSubstituable += tmp2;

        const tmp3 = volumeUnitaireRecolte * infosBobi.pourcentBi * this.paramsCalculSource.produitsBois.pourcentPan * substitutionCarbone.coefPan;
        stockageCarboneSubstitue.carboneSubstituable += tmp3;

        const tmp4 = (volumeUnitaireRecolte ? volumeRecolte/volumeUnitaireRecolte : 0);

        const tmpMul = tmp4 * (this.paramsCalculSource.massesMolaires.C/this.paramsCalculSource.massesMolaires.co2);
        stockageCarboneSubstitue.carboneSubstituable *= tmpMul;

        if (isNaN(stockageCarboneSubstitue.carboneSubstituable)) {

            console.log("calculerSubstitutionCarbone - carboneSubstituable : ", tmpMul);

        }

        stockageCarboneSubstitue.carboneSubstituable = Math.max(stockageCarboneSubstitue.carboneSubstituable, 0);

        // la somme (stock)
        stockageCarboneSubstitue.carboneTotalSub = this.tabValeursCalculeeS[age-1].stockageCarboneSubstitue.carboneTotalSub + stockageCarboneSubstitue.carboneSubstituable;

        return(stockageCarboneSubstitue);
    }

		//*********************************
    calculerIntervalle = function(min, max) {
        //min = 0; // tmp
        var intervalle = Math.trunc(max-min);
        var chaineIntervalle = ""+intervalle;
        var deltaIntervalle = 2;
        if (chaineIntervalle.length >= 2) {
            deltaIntervalle = Math.pow(10, chaineIntervalle.length-2)*parseInt(chaineIntervalle[0])*2;
        }
        return(deltaIntervalle)
    }

		//*********************************
    initialiserCourbes = function(ageMaxTest) {

        UfPplCarboneLocal = this;
        this.ageMaxTest = ageMaxTest;
        var tabValeursAffichees = this.tabValeursCalculeeS.filter(this.filtrerValeursCarbone);
        this.tabValeursAffichees = tabValeursAffichees;

        // l'ensemble des affichages est en tonne C / ha, il est donc important d'afficher les 3 courbes à la même échelle !
        var maxDeTout = Math.max(this.maxSequestration, this.maxStockage, this.maxSubstitution);
        // inutile si option de l'affichage du maxTotal
        maxDeTout = this.maxTotal;
        // dans tous les cas, en t eq CO2 ...
        maxDeTout = this.carboneVersCO2(maxDeTout);

        var courbe = {"negatifPositif":0, "idCourbe":"c_", "debutValide":this.dendrometrie.ageDonneesValides, "anneeCourante":this.ppl.anneeCourante, "anneeDuJour":this.ppl.anneeDateInstallation+this.ppl.anneeCourante-1, "coupeRase":0, "infos" : {"titre":"Evolution par années", "classe":"multi", "axeX":"Age", "donneesX":"ans", "intervalleX":10, "debutX":0/*this.ppl.anneeCourante-1*/, "minY":0}, "infosY" : [
            {"libelleAxeY":"Stock Produits bois", "uniteLibelleAxeY":"(t eq CO2 / ha)",  "xAxeY":"gauche", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0},
            {"libelleAxeY":"Stock en forêt", "uniteLibelleAxeY":"(t eq CO2 / ha)", "xAxeY":"gauche", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0},
            {"libelleAxeY":"Substitution", "uniteLibelleAxeY":"(t eq CO2 / ha)",  "xAxeY":"droite", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0, "positif":1},
            /*
            {"libelleAxeY":"Substitution", "uniteLibelleAxeY":"(t eq CO2 / ha)",  "xAxeY":"gauche", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0, "positif":1},
            {"libelleAxeY":"Stock Produits bois", "uniteLibelleAxeY":"(t eq CO2 / ha)",  "xAxeY":"gauche", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0},
            {"libelleAxeY":"Total", "uniteLibelleAxeY":"(t eq CO2 / ha)",  "xAxeY":"droite", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0, "positif":1},
            {"libelleAxeY":"Stock en forêt", "uniteLibelleAxeY":"(t eq CO2 / ha)", "xAxeY":"droite", "donneesY":"t eq CO2 / ha", "intervalleY":this.calculerIntervalle(0, maxDeTout), "max":maxDeTout, "min":0},
            */
/*
            items[0].y = this.arrondir(carboneSubstitue, 2);
            items[1].y = this.arrondir(carboneStocke, 2);
            items[2].y = this.arrondir(carboneSequestre + carboneStocke + carboneSubstitue, 2);
            items[3].y = this.arrondir(carboneSequestre, 2);
*/
                ], "datas": [],
        "montrerAnnees":true
        }; // todo MAXs en base


        if (this.ppl.it) {
            courbe.infos.titre = this.ppl.it.identifiant;
            this.coupeRase = this.ppl.it.coupeRase;
            courbe.coupeRase = this.coupeRase;
        }


        var negatifPositif = 0;
        var incrementTab = 0;
        //for(var cptAns = this.ppl.anneeCourante-1; cptAns < tabValeursAffichees.length; cptAns++) {
        for(var cptAns = 0; cptAns < tabValeursAffichees.length; cptAns++) {
            var item = {};
            item.coupeRase = false;
            var items = [];
            item.itemsY = items;
            items[0] = {};
            items[1] = {};
            items[2] = {};
            //items[3] = {};
            var anneeReelle = cptAns;
            //var anneeReelle = cptAns+this.ppl.anneeCourante-1;
            item.x = anneeReelle;


            // en t eq CO2 ...
            const carboneSequestre = this.carboneVersCO2(tabValeursAffichees[cptAns].carboneSequestre);
            const carboneStocke = this.carboneVersCO2(tabValeursAffichees[cptAns].carboneStocke);
            const carboneSubstitue = this.carboneVersCO2(tabValeursAffichees[cptAns].carboneSubstitue);

/*
            items[0].y = this.arrondir(carboneSubstitue, 2);
            items[1].y = this.arrondir(carboneStocke, 2);
            items[2].y = this.arrondir(carboneSequestre + carboneStocke + carboneSubstitue, 2);
            items[3].y = this.arrondir(carboneSequestre, 2);
*/
            items[0].y = this.arrondir(carboneStocke, 2);
            items[1].y = this.arrondir(carboneSequestre, 2);
            items[2].y = this.arrondir(carboneSubstitue, 2);



            // est-ce qu'on a une mesure ou une eclaircie sur l'année
            item.mesure = false;
            if (typeof(this.dendrometrie.mesures[anneeReelle]) !== 'undefined') {
            item.mesure = true;
            }
            item.eclaircie = false;
            item.depressage = false;
            if (typeof(this.dendrometrie.eclaircies[anneeReelle]) !== 'undefined') {
                item.eclaircie = true;
                if (this.dendrometrie.eclaircies[anneeReelle].fromIT || (typeof(this.dendrometrie.eclaircies[anneeReelle].proposition) !== 'undefined')) {
                    item.eclaircieIT = true;
                }
                if ((typeof(this.dendrometrie.eclaircies[anneeReelle].depressage) !== 'undefined') &&           this.dendrometrie.eclaircies[anneeReelle].depressage) {
                    item.depressage = true;
                }
            }
            if (this.coupeRase && (anneeReelle == this.coupeRase)) {
                item.coupeRase = true;
            }

            courbe.datas[incrementTab] = item;
            incrementTab++;
        }


        courbe.negatifPositif = negatifPositif;
        return(courbe);
    }
//*********************************
    filtrerValeursCarbone = function(valeur, index) {
        //return ((index <= UfPplCarboneLocal.ageMaxTest) && (index >= UfPplCarboneLocal.ppl.anneeCourante-1))
        return (index <= UfPplCarboneLocal.ageMaxTest)
    }

//*********************************
lePPCM = function(valeur1, valeur2) {
    if (!valeur1 || !valeur2) {
        return(0);
    }
    // algo Euclide
    var ppcm = 0;
    var euclideMax = Math.max(valeur1, valeur2);
    var euclideMin = Math.min(valeur1, valeur2);
    var reste = euclideMax%euclideMin;
    var restePrecedent = euclideMin;
    while (reste) {
        restePrecedent = reste;
        euclideMax = euclideMin;
        euclideMin = reste;
        reste = euclideMax%euclideMin;
    }
    ppcm = valeur1 * valeur2 / restePrecedent; // ici restePrecedent est le PGCD
    /*
    // algo bourrin
    for (var i=valeur1*valeur2; i>=valeur1; i--) {
        if ((i%valeur1==0)&&(i%valeur2==0)){
            ppcm=i;
        }
    }
    */
    return(ppcm);
    }

    triCoupeRase(obj1, obj2) {
        return(obj1.ppl.it.coupeRase - obj2.ppl.it.coupeRase);
    }

//*********************************
    initialiserGraphesComparaison(objCarbone1, objCarbone2, ratio) {

/*
        // on commence par calculer
        // le PPCM des coupes rases (il nous permettra d'afficher les résultats à la même date - ie. PPCM + 1)
        var ppcm = this.lePPCM(objCarbone1.ppl.it.coupeRase, objCarbone2.ppl.it.coupeRase);
        // à partir de ce PPCM on va recalculer les 2 ITs en allant jusqu'au PPCM
        objCarbone1.calculerCarbone(ppcm);
        objCarbone2.calculerCarbone(ppcm);
*/
        // l'hypothèse PPCM est abandonnée - on se contente de calculer au max sur deux cycles pour calculer les valeurs moyennes (sur deux cycles pour le stockage et la substitution - 1 cycle pour la séquestration)
        objCarbone1.calculerCarbone(objCarbone1.ppl.it.coupeRase*2);
        objCarbone2.calculerCarbone(objCarbone2.ppl.it.coupeRase*2);

        // on commence par ordonner
        var tabObjCarbone = [];
        tabObjCarbone.push(objCarbone1);
        tabObjCarbone.push(objCarbone2);
        tabObjCarbone = tabObjCarbone.sort(this.triCoupeRase);

        // différencier les affichages des courbes (pb taille dispo) ?
        var titreY = "";
        var uniteLibelleAxeY = "";
        switch (ratio) {
            case 'S&S' :
                titreY = "Stock en forêt et Produits bois";
                uniteLibelleAxeY = "t eq CO2 / ha";
                break;
            case 'SUB' :
                titreY = "Substitution";
                uniteLibelleAxeY = "t eq CO2 / ha / an";
                break;
            case 'TOT' :
                titreY = "3S";
                break;
        }


        // l'echelle des x n'est pas dépendante des années
        //const XMAX = 5;
        const X1 = 1;
        const X2 = 2;

        var graphe = {"negatifPositif":0, "idCourbe":"CC_",
                      "infos" : {"titre":"Comparaison carbone", "classe":"multi", "itemsX":null, "axeX":" ", "donneesX":"", "intervalleX":1, "debutX":0, "minX":0, "minY":0},
                      "infosY" : [
                          {"libelleAxeY":"", "uniteLibelleAxeY":uniteLibelleAxeY, "xAxeY":"gauche", "donneesY":"t C/ha", "intervalleY":0, "max":0, "min":0},
                      ],
                      "datas": []}; // todo MAXs en base

        var itemsX=[];
        var params = {};
        params.ratio = ratio;

/*
        // premiere colonne
        params.index = 0;
        params.annee = 0;
        graphe.datas[0] = this.itemCarbone(tabObjCarbone[0], params); // peut modifier params.annee
            // valeurs à la coupe rase +/- x
        var itemX0={"index":0, "classe":0, "libelle":""+params.annee, "libelleCourt":""+graphe.datas[0].xLibelle, "x":params.annee};
        itemsX.push(itemX0);

        // deuxième colonne
        params.index = 1;
        params.annee = 0;
        graphe.datas[1] = this.itemCarbone(tabObjCarbone[1], params); // peut modifier params.annee
            // valeurs à la coupe rase +/- x
        var itemX1={"index":1, "classe":0, "libelle":""+params.annee, "libelleCourt":""+graphe.datas[1].xLibelle, "x":params.annee};
        itemsX.push(itemX1);
*/

        // troisième colonne (premier PPCM)
        params.index = 0;
        params.annee = objCarbone1.ppl.it.coupeRase; // WIZ adapter cet affichage (c'est une moyenne)
        graphe.datas[0] = this.itemCarbone(tabObjCarbone[0], params); // peut modifier params.annee FAUX A REFAIRE (calculs sur tout le ppcm !!!!) ???
            // valeurs à la coupe rase +/- x
        var itemX2={"index":0, "classe":0, "libelle":""+params.annee, "libelleCourt":""+graphe.datas[0].xLibelle, "x":X1};
        //"x":params.annee};
        if (typeof(graphe.datas[0].xLibelleBis) !== 'undefined') {
            itemX2.libelleCourtBis = graphe.datas[0].xLibelleBis;
        }
        itemsX.push(itemX2);

        // quatrième colonne (deuxième PPCM)
        params.index = 1;
        params.annee = objCarbone2.ppl.it.coupeRase; // WIZ adapter cet affichage (c'est une moyenne)
        graphe.datas[1] = this.itemCarbone(tabObjCarbone[1], params); // peut modifier params.annee FAUX A REFAIRE (calculs sur tout le ppcm !!!!)
            // valeurs à la coupe rase +/- x
        var itemX3={"index":1, "classe":0, "libelle":""+params.annee, "libelleCourt":""+graphe.datas[1].xLibelle, "x":X2};
        //"x":params.annee};
        if (typeof(graphe.datas[1].xLibelleBis) !== 'undefined') {
            itemX3.libelleCourtBis = graphe.datas[1].xLibelleBis;
        }
        itemsX.push(itemX3);

        // integration des X
        graphe.infos.itemsX = itemsX;

        // calcul de l'echelle et des intervalles des Y qui dépend de l'ensemble des Y (calcul du max)
        var maxY = Math.max(graphe.datas[0].maxY, graphe.datas[1].maxY); // pour echelle commune
        //maxY += maxY*20/100;
        graphe.infosY[0].max = maxY; // plus d'echelle commune 2023/01
        graphe.infosY[0].intervalleY = this.calculerIntervalle(0, maxY);
/*
        maxY = graphe.datas[1].maxY; // pour echelle commune
        graphe.infosY[1].max = maxY; // plus d'echelle commune 2023/01
        graphe.infosY[1].intervalleY = this.calculerIntervalle(0, maxY);
*/



        return(graphe);
    }

    //*********************************
    itemCarbone(objCarbone, params) {
        var item = {};
        var items = [];
        item.itemsY = items;
        items[0] = {};
        item.x = params.index;
        var valeursY = 0;

        /*
        // cas particulier d'un PPCM
        var nbCycles = Math.floor(params.annee/objCarbone.ppl.it.coupeRase);
        */
        var nbCycles = 2; // deux cycles pour stockage et séquestration
        var cptCycles =0;
        var carboneCycles = 0;
        var sequestrationCycles = 0;
        var substitutionCycles = 0;

        // calcul des valeurs affichées
        // NB : toutes les valeurs sont TOUJOURS calculées de façon à proposer une echelle unique
        // S&S
        var iCoupeRase = objCarbone.ppl.it.coupeRase;
        for (var i=0; i<= iCoupeRase; i++) {
            carboneCycles += objCarbone.tabValeursCalculeeS[i].carboneStocke;
            sequestrationCycles += objCarbone.tabValeursCalculeeS[i].carboneSequestre;
            //substitutionCycles += objCarbone.tabValeursCalculeeS[i].carboneSubstitue;
        }
        for (var i=iCoupeRase+1; i<= 2*iCoupeRase+1; i++) {
            carboneCycles += objCarbone.tabValeursCalculeeS[i].carboneStocke;
            //substitutionCycles += objCarbone.tabValeursCalculeeS[i].carboneSubstitue;
        }
        substitutionCycles = objCarbone.tabValeursCalculeeS[iCoupeRase+1].carboneSubstitue;
        iCoupeRase++; // car de 0 à n = n+1
        //carboneCycles /= iCoupeRase*2; // modif demande JCD du 30/01/2023
        carboneCycles /= iCoupeRase; // modif demande JCD du 30/01/2023
        sequestrationCycles /= iCoupeRase;
        //substitutionCycles /= iCoupeRase*2; // modif demande JCD du 30/01/2023
        substitutionCycles /= iCoupeRase; // modif demande JCD du 30/01/2023

        // passage en tonnes eq CO2 / hectare
        carboneCycles = this.carboneVersCO2(carboneCycles);
        sequestrationCycles = this.carboneVersCO2(sequestrationCycles);
        substitutionCycles = this.carboneVersCO2(substitutionCycles);

        const total = this.arrondir(carboneCycles + sequestrationCycles, 1);
        item.maxY = Math.max(total, substitutionCycles);

        switch (params.ratio) {
            case 'S&S' :
                item.maxY = total;
                items[1] = {};  // cas particulier : on montre deux valeurs

                item.xLibelle = "Stock moyen de long terme";
                item.xLibelleBis = "Coupe rase à " + objCarbone.ppl.it.coupeRase + " ans";

                items[1].y = this.arrondir(carboneCycles, 1);
                items[0].y = this.arrondir(sequestrationCycles, 1); // cas particulier : on montre deux valeurs

                items[1].sousTitre = "Stock Produits bois (" + items[1].y + ")";
                items[0].sousTitre = "Stock en forêt (" + items[0].y + ")"; // cas particulier : on montre deux valeurs
                items[0].yLibelleBis = "(" + total + " t eq CO2/ha)";
                break;
            case 'SUB' :
                item.maxY = substitutionCycles;
                item.xLibelle = "Flux moyen annuel";
                item.xLibelleBis = "Coupe rase à " + objCarbone.ppl.it.coupeRase + " ans";
                items[0].y = this.arrondir(substitutionCycles, 1);
                items[0].yLibelleBis = "(" + items[0].y + " t eq CO2/ha/an)";
                break;
/*
            case 'TOT' : // sans objet au 2022/10
                if (!params.annee) {
                    //item.xLibelle = "" + objCarbone.ppl.it.coupeRase + " + 1";
                    item.xLibelle = "Sans objet";
                    params.annee = objCarbone.ppl.it.coupeRase+1;
                    valeursY = objCarbone.tabValeursCalculeeS[params.annee];
                    items[0].y = valeursY.carboneSequestre + valeursY.carboneStocke + valeursY.carboneSubstitue;
                }
                else {
                    //item.xLibelle = "" + params.annee + " + 1";
                    item.xLibelle = "Stock moyen de long terme";
                    params.annee += 1;
                    carboneCycles += objCarbone.tabValeursCalculeeS[params.annee].carboneSequestre; // une seule fois
                    while (nbCycles--) {
                        carboneCycles += objCarbone.tabValeursCalculeeS[params.annee-cptCycles].carboneStocke + objCarbone.tabValeursCalculeeS[params.annee-cptCycles].carboneSubstitue;
                        cptCycles += objCarbone.ppl.it.coupeRase
                    }
                    items[0].y = carboneCycles;
                }
                break;
*/
        }

        items[0].yLibelle = objCarbone.ppl.it.identifiant;
        return(item)
    }


    //*********************************
    arrondir = function(valeur, puissance = 3) {
        if ((typeof(valeur) === 'undefined') || isNaN(valeur)) {
            valeur = 0;
        }
        var puissanceDe10 = Math.pow(10, puissance);
        var arrondi = Math.round(valeur*puissanceDe10)/puissanceDe10; // arrondi à 3 chiffres
        return(arrondi);
    }

    carboneVersCO2 = function(valeur) {
        return(ApiTools.carboneVersCO2(valeur, this.constantesSteres.massesMolairesJson));
    }



}

export const UfPplCarbone = new UF_PPL_CARBONE()
export const UfPplCarbone1 = new UF_PPL_CARBONE()
export const UfPplCarbone2 = new UF_PPL_CARBONE()
var UfPplCarboneLocal = null;
