
//import {const_tmps} from './constTmps.schema.js'
import {datas_communes} from './datasCommunes.schema.js'
import {const_steres} from './constSteres.schema.js'
import {const_profils} from './constProfils.schema.js'
import {const_qualifications} from './constQualifications.schema.js'
import {const_depenses} from './constDepenses.schema.js'
import {const_essences} from './constEssences.schema.js'
import {const_cultures} from './constCultures.schema.js'
import {const_essences_cultures} from './constEssencesCultures.schema.js'
import {const_terroirs} from './constTerroirs.schema.js'
import {const_essences_cultures_terroirs} from './constEssencesCulturesTerroirs.schema.js'
import {const_tarifs} from './constTarifs.schema.js'
import {calc_bobi} from './calcBobi.schema.js'
import {calc_eclaircies} from './calcEclaircies.schema.js'
//import {user_users} from './userUsers.schema.js'
import {user_utilisateurs} from './userUtilisateurs.schema.js'
import {user_proprietes} from './userProprietes.schema.js'
import {user_uf} from './userUf.schema.js'
import {user_ufparcelle} from './userUfparcelle.schema.js'
import {user_ufpeuplement} from './userUfpeuplement.schema.js'
import {user_ufpeuplementmesure} from './userUfpeuplementmesure.schema.js'
import {user_ufpeuplementeclaircie} from './userUfpeuplementeclaircie.schema.js'
import {user_ufpeuplement_it} from './userUfpeuplementIt.schema.js'
import {user_ufpeuplement_iteclaircie} from './userUfpeuplementIteclaircie.schema.js'

import {local_synchro} from './localSynchro.schema.js'

/*
Passage du schemaBuilder à chaque table listée ici
*/
export function generateSchemas(schemaBuilder) {
  //const_tmps(schemaBuilder);
  datas_communes(schemaBuilder);
  const_steres(schemaBuilder);
  const_profils(schemaBuilder);
  const_qualifications(schemaBuilder);
  const_depenses(schemaBuilder);
  const_essences(schemaBuilder);
  const_cultures(schemaBuilder);
  const_essences_cultures(schemaBuilder);
  const_terroirs(schemaBuilder);
  const_essences_cultures_terroirs(schemaBuilder);
  const_tarifs(schemaBuilder);
  calc_bobi(schemaBuilder);
  calc_eclaircies(schemaBuilder);
  //user_users(schemaBuilder);
  user_utilisateurs(schemaBuilder);
  user_proprietes(schemaBuilder);
  user_uf(schemaBuilder);
  user_ufparcelle(schemaBuilder);
  user_ufpeuplement(schemaBuilder);
  user_ufpeuplementmesure(schemaBuilder);
  user_ufpeuplementeclaircie(schemaBuilder);
  user_ufpeuplement_it(schemaBuilder);
  user_ufpeuplement_iteclaircie(schemaBuilder);
  local_synchro(schemaBuilder);
}
