<template>
 <div>


         <span  v-bind:class="{resultatOK : calculDensite}">Densité moyenne : {{calculDensite}} tiges/ha</span>
         <v-select
            v-model="form.methodeId"
            item-text="libelle"
            item-value="id"
            :items="lesItemsCalcul"
            label="Choisir la méthode de calcul"
            :disabled="lesItemsCalcul.length === 1"
            required
            :error-messages="error.methodeCalcul"
        ></v-select>


         <!-- saisie directe -->
         <div class="methode" v-if="form.methodeId==4">

            <v-text-field
                label="Densité *"
                v-model="form.saisieDirecte.densite"
                type="number"
                @keydown="filtrerNombresEntiersPositifs"
                min=50
                max=5000
                required
                :error-messages="error.saisieDirecte.densite"
                ref="densiteA"
            ></v-text-field>

        </div>

         <!-- surface terrière -->
         <div class="methode" v-if="form.methodeId==2">

            <span class="cacher">En savoir plus : Comment réaliser une mesure de densité par surface terrière ?</span>
<!--
            <v-text-field
                label="Surface terrière mesurée (m²) *"
                v-model="form.surfaceTerriere.surfaceTerriere"
                type="number"
                @keydown="filtrerNombresPositifs"
                min=1
                required
                :error-messages="error.surfaceTerriere.surfaceTerriere"
                ref="densiteA"
            ></v-text-field>
-->
            <span>Surface terrière : {{surfacesTerrieresMesuresMoyenne}} m²</span>
             <div v-if="surfacesTerrieresMesures.length" class="itemIconeFct lienModeForet" @click.stop="interlignesRenseignerSerieSurfacesTerrieres()">
                 Série de surfaces terrières :
                 <span class="" v-for="(nbSurfacesTerrieres, numSurfacesTerrieres) in surfacesTerrieresMesures" :key="numSurfacesTerrieres" >
                     <span v-if="numSurfacesTerrieres">, </span>
                     {{ nbSurfacesTerrieres }}
                </span>
                <i class="fa fa-pencil svg" aria-hidden="true"></i>
            </div>
            <div v-if="!surfacesTerrieresMesures.length">
                <v-btn class="a-btn lienModeForet" type="button" @click="interlignesRenseignerSerieSurfacesTerrieres">Saisir une série de surfaces terrières *</v-btn>
            </div>

        </div>

         <!-- interlignes -->
         <div class="methode" v-if="form.methodeId==1">
             <span class="cacher">En savoir plus : Comment réaliser une mesure de densité par interlignes ?</span>

            <v-text-field
                label="Nombre d'interlignes mesurés *"
                v-model="form.interlignes.nombre"
                type="number"
                @keydown="filtrerNombresEntiersPositifs"
                min=1
                required
                :error-messages="error.interlignes.nombre"
                ref="densiteA"
            ></v-text-field>
<!--
            <v-text-field
                label="Largeur mesurée sur ces interlignes (m) *"
                v-model="form.interlignes.largeur"
                type="number"
                @keydown="filtrerNombresPositifs"
                min=1
                required
                :error-messages="error.interlignes.largeur"
            ></v-text-field>
-->
            <v-text-field
                label="Longueur (m) *"
                v-model="form.interlignes.longueur"
                type="number"
                @keydown="filtrerNombresPositifs"
                min=1
                required
                :error-messages="error.interlignes.longueur"
            ></v-text-field>

            <div v-if="interlignesLargeur.length > 1">Largeur moyenne : {{arrondir(interlignesLargeurMoyenne,2)}} m </div>
             <div v-if="interlignesLargeur.length" class="itemIconeFct lienModeForet" @click.stop="interlignesRenseignerSerieLargeur()">
                 Série de largeurs :
                 <span class="" v-for="(nbLargeurs, numSaisieLargeurs) in interlignesLargeur" :key="numSaisieLargeurs" >
                     <span v-if="numSaisieLargeurs">, </span>
                     {{ nbLargeurs }}
                </span>
                <i class="fa fa-pencil svg" aria-hidden="true"></i>
            </div>
            <div v-if="!interlignesLargeur.length">
                <v-btn class="a-btn lienModeForet" type="button" @click="interlignesRenseignerSerieLargeur">Saisir une série de largeurs *</v-btn>
            </div>

            <div v-if="interlignesLesArbres.length > 1">Nombre moyen d'arbres : {{arrondir(interlignesMoyenne,2)}} </div>
            <div v-if="interlignesLesArbres.length" class="itemIconeFct lienModeForet" @click.stop="interlignesRenseignerSerie()">
                 Série de nombres d'arbres sur 2 lignes :
                 <span class="" v-for="(nbArbres, numSaisieArbres) in interlignesLesArbres" :key="numSaisieArbres" >
                     <span v-if="numSaisieArbres">, </span>
                     {{ nbArbres }}
                </span>
                <!-- <img class="iconeFct" alt="Editer la série" src="@/assets/img/crayon.svg" /> -->
                <i class="fa fa-pencil svg" aria-hidden="true"></i>
            </div>
            <div v-if="!interlignesLesArbres.length">
                <v-btn class="a-btn lienModeForet" type="button" @click="interlignesRenseignerSerie">Saisir une série de nombres d'arbres *</v-btn>
            </div>



        </div>


        <calcul-densite-surface-terriere-serie v-if="surfaceTerriereSerieEnCours"
            @retourSurfaceTerriereSerie="onRetourSurfaceTerriereSerie"
            :surfaceBoisee=surfaceBoisee
            :initDatas="form.surfaceTerriere"
            :ouvrirDialog=surfaceTerriereSerieEnCours
        />
        <calcul-densite-interlignes-serie-largeur v-if="interlignesSerieLargeurEnCours"
            @retourInterlignesSerieLargeur="onRetourInterlignesSerieLargeur"
            :surfaceBoisee=surfaceBoisee
            :initDatas="form.interlignes"
            :ouvrirDialog=interlignesSerieLargeurEnCours
        />
        <calcul-densite-interlignes-serie v-if="interlignesSerieEnCours"
            @retourInterlignesSerie="onRetourInterlignesSerie"
            :surfaceBoisee=surfaceBoisee
            :initDatas="form.interlignes"
            :ouvrirDialog=interlignesSerieEnCours
        />


</div>
</template>


<script>


import { validationMixin } from 'vuelidate'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'

import { ApiTools } from "../../../js/api/index.js"

import CalculDensiteInterlignesSerie from './CalculDensiteInterlignesSerie.vue'
import CalculDensiteInterlignesSerieLargeur from './CalculDensiteInterlignesSerieLargeur.vue'
import CalculDensiteSurfaceTerriereSerie from './CalculDensiteSurfaceTerriereSerie.vue'


export default {

    components: {
        'calcul-densite-interlignes-serie': CalculDensiteInterlignesSerie,
        'calcul-densite-interlignes-serie-largeur': CalculDensiteInterlignesSerieLargeur,
        'calcul-densite-surface-terriere-serie' : CalculDensiteSurfaceTerriereSerie,
    },

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        initDatas: {
            type: Object
        },
        initCirconference: {
            type: Number
        },
        surfaceBoisee : Number,
        methodeId: Number,
    },

    data() {
        return {

            old: null,
            form: JSON.parse(JSON.stringify(this.initDatas)),
            affichageDensite:0,
            comboChoix: [  // refaire avec table calc_densitemethodes ?
                {id:0, libelle:"Choisir", code:"choix"},
                {id:1, libelle:"Interlignes", code:"interlignes"},
                {id:2, libelle:"Surface Terrière", code:"surfaceTerriere"},
                {id:3, libelle:"Ratio Nombre / Surface", code:"ratios"},
                {id:4, libelle:"Saisie directe", code:"saisieDirecte"}
            ],
            interlignesSerieEnCours:false,
            interlignesSerieLargeurEnCours:false,
            surfaceTerriereSerieEnCours:false,
            error: {
                methodeCalcul: '',
                interlignes : {
                    nombre: '',
                    //largeur: '',
                    longueur: ''
                },
                surfaceTerriere : {
                    surfaceTerriere: ''
                },
                saisieDirecte : {
                    densite: ''
                }
            },
            circonferenceTerriere :this.initCirconference
        }
    },

    created() {
        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        this.initItems();
    },

    validations() {
        var laValidation =  {
            form: {
                methodeCalcul: {  required },
            }
        };

        if (this.form.methodeId==1) {
            var laValidationInterlignes =  {
                nombre: {  required },
//                largeur: {  required },
                longueur: {  required },
            };
            laValidation.form.interlignes = laValidationInterlignes;
        }
        if (this.form.methodeId==2) {
            var laValidationSurfaceTerriere =  {
                surfaceTerriere: {  required },
            };
            laValidation.form.surfaceTerriere = laValidationSurfaceTerriere;
        }
        if (this.form.methodeId==4) {
            var laValidationSaisieDirecte =  {
                densite: {  required },
            };
            laValidation.form.saisieDirecte = laValidationSaisieDirecte;
        }

        return laValidation;
    },

    watch: {
        selectedMethod(newValue, oldValue) {
            if (newValue) {
                // 2021/03/02
                this.initMethode();
                this.$emit('retourSelectionMethode', newValue);

                // 2021/03/02 - on recalcule la densité même si on n'en a pas les moyens
                var densiteTest = this.calculDensite;
                if (!this.affichageDensite) { // et si on ne peut pas on le transmet pour permettre l'erreur à l'enregistrement
                    this.form.densite=0;
                    this.$emit('retourDensite', this.form);
                }
            }
            else {
                this.form.densite=0;
                this.$emit('retourDensite', this.form);
            }
        },
        initCirconference(newValue, oldValue) {
            if (newValue) {
                this.circonferenceTerriere = this.initCirconference;
                if (this.form.methodeId==2) {
                    this.surfaceTerriereDensite();
                }
            }
        },

    },

    computed: {
        methodeIdContrainte() {
            if (typeof(this.methodeId) !== 'undefined') {
                this.form.methodeId = this.methodeId;
                return true
            }
            return false
        },
        selectedMethod() {
            return(this.form.methodeId);
        },
        // pour le combo "choix de la méthode"
        lesItemsCalcul() {
            if (this.methodeIdContrainte) {
                var tab = [];
                tab[0] = this.comboChoix[this.methodeId];
                return(tab);
            }
            return(this.comboChoix.filter(this.filtrerChoixMethode));
        },

        // METHODE DES SURFACES TERRIERES
            // pour affichage "à jour" de la liste
        surfacesTerrieresMesures() {
            return(this.form.surfaceTerriere.surfaceTerriere);
        },
        surfacesTerrieresMesuresMoyenne() {
            if (!this.form.surfaceTerriere.surfaceTerriere.length) {
                return 0;
            }
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const total = this.form.surfaceTerriere.surfaceTerriere.reduce(reducer);
            return total / this.form.surfaceTerriere.surfaceTerriere.length;
        },

        // METHODE DES INTERLIGNES
            // pour affichage "à jour" de la liste
        interlignesLargeur() {
            return(this.form.interlignes.largeur);
        },
        interlignesLargeurMoyenne() {
            if (!this.form.interlignes.largeur.length) {
                return 0;
            }
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const total = this.form.interlignes.largeur.reduce(reducer);
            return total / this.form.interlignes.largeur.length;
        },
        interlignesLesArbres() {
            return(this.form.interlignes.tabNombresArbres);
        },
        interlignesMoyenne() {
            if (!this.form.interlignes.tabNombresArbres.length) {
                return 0;
            }
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const total = this.form.interlignes.tabNombresArbres.reduce(reducer);
            return total / this.form.interlignes.tabNombresArbres.length;
        },
            // calcul de la densité
        calculDensite() {
            // BC 2021/03
            this.affichageDensite = 0;

            if (this.form.methodeId==1) {
                this.interlignesDensite();
            }
            else {
                if (this.form.methodeId==2) {
                    this.surfaceTerriereDensite();
                }
                else {
                    if (this.form.methodeId==4) {
                        this.saisieDirecteDensite();
                    }
                    else {
                        this.form.densite = 0;
                    }
                }
            }
            return(this.affichageDensite);
        },

    },

    methods: {
        surfaceTerriereDensite() {
            // Densité = G4pi/C² (avec C en m)
            var PI = this.$store.getters.constantes.steres.PI;
            var calculDensite = 0;
            var circonferenceCarre = this.circonferenceTerriere / 100;
            var surfaceTerriere = parseFloat(this.form.surfaceTerriere.surfaceTerriere);
            if (circonferenceCarre && surfaceTerriere) {
                circonferenceCarre *= circonferenceCarre;
                calculDensite = surfaceTerriere * 4 * PI  / circonferenceCarre;
            }

            this.form.densite = calculDensite;
            this.$emit('retourDensite', this.form);
            this.affichageDensite = ApiTools.arrondir(calculDensite, 0);
        },

        interlignesDensite() {
            var lParam = this.interlignesLargeurMoyenne; // La largeur des interlignes mesurée, l
//            var lParam = this.form.interlignes.largeur; // La largeur des interlignes mesurée, l
            var nParam = this.form.interlignes.nombre; // Le nombre d’interlignes mesuré, n
            var lgParam = this.form.interlignes.longueur; // La longueur sur l’interligne, L
            var nb = this.interlignesMoyenne / 2; // Le nombre de pins sur cette surface, nb mesuré sur (Lnl) - on divise par 2 car la mesure est prise sur 2 lignes
            var densiteRelle = 0;
            if (lParam && nParam && lgParam) {
                // this.form.densite = (nb/(lParam*nParam*lgParam))*10000;  // La densité = (nb/(lnL))*10000
                densiteRelle = (nb/(lParam/nParam*lgParam))*10000;  // La densité = (nb/(lnL))*10000 - correction E. de Montbron 01/2020
                if (this.form.densite != densiteRelle) {
                    this.form.densite = densiteRelle;
                    this.$emit('retourDensite', this.form);
                    this.affichageDensite = ApiTools.arrondir(densiteRelle, 0);
                }
            }
            // NB : largeur moyenne de l’interligne = l/n -------------- PAS UTILISE
        },

        saisieDirecteDensite() {
            var densite = parseInt(this.form.saisieDirecte.densite);
            if (!densite) {
                densite = 0;
            }

            if (this.form.densite != densite) {
                this.form.densite = densite;
                this.$emit('retourDensite', this.form);
                this.affichageDensite = densite;
            }
        },

        // init
        initMethode() {

            // 2021/03/02 - la largeur est maintenant un tableau de largeurs !!!!
            if (this.form.methodeId==1) {
                if (typeof(this.form.interlignes.largeur) !== 'undefined') {
                    if (!Array.isArray(this.form.interlignes.largeur)) {
                        var tmp = parseFloat(this.form.interlignes.largeur);
                        this.form.interlignes.largeur = [];
                        if (!isNaN(tmp) && tmp) {
                            this.form.interlignes.largeur[0] = tmp;
                        }
                    }
                }
            }
            // 2021/03/02 - la surface terriere est maintenant un tableau de surfaces terrieres !!!!
            if (this.form.methodeId==2) {
                if (typeof(this.form.surfaceTerriere.surfaceTerriere) !== 'undefined') {
                    if (!Array.isArray(this.form.surfaceTerriere.surfaceTerriere)) {
                        var tmp = parseFloat(this.form.surfaceTerriere.surfaceTerriere);
                        this.form.surfaceTerriere.surfaceTerriere = [];
                        if (!isNaN(tmp) && tmp) {
                            this.form.surfaceTerriere.surfaceTerriere[0] = tmp;
                        }
                    }
                }
            }

        },
        initItems() {
            if (this.lesItemsCalcul.length == 1) {
                this.form.methodeId = this.lesItemsCalcul[0].id;
            }
            else {
                this.form.methodesPossibles.choix = true;
            }

            // 2021/03/02
            this.initMethode();

        },

        // quelle méthode ?
        filtrerChoixMethode(valeur, index) {
            return (this.form.methodesPossibles[valeur.code])
        },

        // METHODE DES INTERLIGNES
            // edition d'une série pour la méthode interlignes
        interlignesRenseignerSerie() {
            this.interlignesSerieEnCours = true;
        },
        onRetourInterlignesSerie(tabSerie) {
            if (tabSerie) {
                var tab = [];
                this.form.interlignes.tabNombresArbres = tab.concat(tabSerie);
            }
            this.interlignesSerieEnCours = false;
        },

        interlignesRenseignerSerieSurfacesTerrieres() {
            this.surfaceTerriereSerieEnCours = true;
        },
        onRetourSurfaceTerriereSerie(tabSerie) {
            if (tabSerie) {
                var tab = [];
                this.form.surfaceTerriere.surfaceTerriere = tab.concat(tabSerie);
            }
            this.surfaceTerriereSerieEnCours = false;
        },

        interlignesRenseignerSerieLargeur() {
            this.interlignesSerieLargeurEnCours = true;
        },
        onRetourInterlignesSerieLargeur(tabSerie) {
            if (tabSerie) {
                var tab = [];
                this.form.interlignes.largeur = tab.concat(tabSerie);
            }
            this.interlignesSerieLargeurEnCours = false;
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.methodeCalcul = ''
            this.error.saisieDirecte.densite = ''
            this.error.interlignes.nombre = ''
//            this.error.interlignes.largeur = ''
            this.error.interlignes.longueur = ''
            this.error.surfaceTerriere.surfaceTerriere = ''
        },

        choixMethodeErrors () {
            if (!this.$v.form.methodeCalcul.$invalid) return false // verifier invalid
            if (!this.$v.form.methodeCalcul.required) {
                 this.error.methodeCalcul  = "Le choix d'une méthode de calcul est obligatoire."
            }
            return true
        },
        surfaceTerriereErrors () {
            if (!this.$v.form.surfaceTerriere.surfaceTerriere.$invalid) return false // verifier invalid
            if (!this.$v.form.surfaceTerriere.surfaceTerriere.required) {
                 this.error.surfaceTerriere.surfaceTerriere  = "Vous devez saisir une surface terrière."
            }
            return true
        },
        saisieDirecteDensiteErrors () {
            if (!this.$v.form.saisieDirecte.densite.$invalid) return false // verifier invalid
            if (!this.$v.form.saisieDirecte.densite.required) {
                 this.error.saisieDirecte.densite  = "Vous devez saisir une densité."
            }
            return true
        },
        interlignesNombreErrors () {
            if (!this.$v.form.interlignes.nombre.$invalid) return false // verifier invalid
            if (!this.$v.form.interlignes.nombre.required) {
                 this.error.interlignes.nombre  = "Vous devez saisir un nombre d'interlignes."
            }
            return true
        },
/*
        interlignesLargeurErrors () {
            if (!this.$v.form.interlignes.largeur.$invalid) return false // verifier invalid
            if (!this.$v.form.interlignes.largeur.required) {
                 this.error.interlignes.largeur  = "Vous devez saisir la largeur sur les interlignes."
            }
            return true
        },
*/
        interlignesLongueurErrors () {
            if (!this.$v.form.interlignes.longueur.$invalid) return false // verifier invalid
            if (!this.$v.form.interlignes.longueur.required) {
                 this.error.interlignes.longueur  = "Vous devez saisir la longueur."
            }
            return true
        },


        // annulation du formulaire
        cancelForm() {
            // on remet les données anciennes dans 'form'
            this.form = JSON.parse(JSON.stringify(this.old))
        },
        // enregistrement du formulaire
        async saveForm() {
        },
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },
        arrondir(valeur, nbChiffresArrondi) {
            return(ApiTools.arrondir(valeur, nbChiffresArrondi));
        }

    }
}
</script>
