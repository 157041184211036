import lf from 'lovefield'

export function const_essences (schemaBuilder) {
  schemaBuilder.createTable('const_essences')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('code', lf.Type.STRING)
      .addColumn('libelle', lf.Type.STRING)
      .addColumn('HMAX', lf.Type.DOUBLE)
      .addColumn('a', lf.Type.DOUBLE)
      .addColumn('aInstallation', lf.Type.DOUBLE)
      .addColumn('mortalite', lf.Type.DOUBLE)
      .addColumn('pauseMortaliteEclarcie', lf.Type.INTEGER)
      .addColumn('seuilMortaliteConcurrence', lf.Type.INTEGER)
      .addColumn('augmentationMortalite', lf.Type.INTEGER)
      .addColumn('hauteurSeuilCirconference0', lf.Type.DOUBLE)
      .addColumn('coefForme', lf.Type.DOUBLE)
      .addColumn('coefEclaircie', lf.Type.DOUBLE)
      .addColumn('coefEclaircieCirc', lf.Type.DOUBLE)
      .addColumn('RACH', lf.Type.DOUBLE)
      .addColumn('k', lf.Type.DOUBLE)
      .addColumn('vieMax', lf.Type.INTEGER)
      .addColumn('hauteurMax', lf.Type.INTEGER)
      .addColumn('circonferenceMax', lf.Type.INTEGER)
      .addColumn('volumeMax', lf.Type.INTEGER)
      .addColumn('deltaMesuresPourHMAX', lf.Type.INTEGER)
      .addColumn('minorationPrixM3Eclaircie', lf.Type.DOUBLE)
      .addColumn('seuilMinorationHauteurSurDiametre', lf.Type.DOUBLE)
      .addColumn('minorationPrixM3HauteurDiametre', lf.Type.DOUBLE)
      .addColumn('eclaircieDensiteMin', lf.Type.INTEGER)
      .addColumn('eclaircieVolumePourCentMin', lf.Type.INTEGER)
      .addColumn('semisJson', lf.Type.STRING)
      .addColumn('exploitabiliteAge', lf.Type.INTEGER)
      .addColumn('exploitabiliteDelai', lf.Type.INTEGER)
      .addColumn('exploitabiliteCoef', lf.Type.INTEGER)
      .addColumn('infradensite', lf.Type.DOUBLE)
      .addColumn('tauxCarboneBiomasse', lf.Type.DOUBLE)
      .addColumn('volumeAerienJson', lf.Type.STRING)
      .addColumn('biomasseRacinaireJson', lf.Type.STRING)
      .addColumn('produitsBoisJson', lf.Type.STRING)
      .addColumn('substitutionCarboneJson', lf.Type.STRING)
      .addPrimaryKey(['id'], false);
}
