<template>

    <div class="containerLog">
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>

        <div id="peuplementEditionEclaircie" class="wrapperIllustration sectionAffichage" v-if="$parent.connexionUtilisateurValide && isReady">

            <div class="conteneur">

                <h1 v-if="existeTarifsSpecifiques" class="blocACompleter">Modification des tarifs de la propriété</h1>
                <h1 v-if="!existeTarifsSpecifiques" class="blocACompleter">Création de tarifs spécifiques à la propriété</h1>

                <span v-if="existeTarifsSpecifiques" class="complementBloc">
                <v-btn class="boutonFonctionObjet" type="button" @click="supprimerTarifouPas">Revenir aux tarifs STERES</v-btn>
                </span>

                <p v-if="!existeTarifsSpecifiques" class="intro">Les tarifs STERES ci-dessous sont basés sur l'observation du prix des ventes publiques de bois de l'année précédente, vous pouvez les modifier en indiquant quel est votre prix pour un volume moyen.</p>

                <p class="info">Cliquez une ligne pour modifier un prix associé à un volume.<br/>NB : Vous pouvez associer un même prix à plusieurs volumes unitaires moyens.</p>

                <div class="edition">
                    <tarifs-form-propriete :data='lesTarifs' :data2='propriete' @retourForm="onRetourForm"></tarifs-form-propriete>
                    <div class="illustration illustration3"></div>
                </div>

                <v-dialog v-model="dialog" v-if="dialog" >
                    <v-card>
                        <v-card-title class="headline">Suppression</v-card-title>

                        <v-card-text>
                            Voulez-vous vraiment utiliser les tarifs standards de Steres ?<br/>Cette action est irréversible et vous perdrez les tarifs précédemment saisis.
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="dialog = false">Non</v-btn>
                            <v-btn color="" text @click="supprimerTarif()">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </div>
        </div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import ProprieteTarifsForm from '../components/propriete/ProprieteTarifsForm.vue'

export default {

    components: {
        'tarifs-form-propriete': ProprieteTarifsForm,
     },

    data() {
        return {
            propriete:this.$store.getters.infosPropriete,
            ready: false,
            dialog: false
        }
    },
    created() {
        this.initialiserTarifsJson();

    },

    mounted: function () {
    },

    computed: {

        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        lesTarifs() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.tabTarifs) === 'undefined')) {
                return(null);
            }
            return(this.propriete.tabTarifs);
        },
        existeTarifsSpecifiques() {
            if ((typeof(this.propriete) === 'undefined') || (typeof(this.propriete.tabTarifs) === 'undefined')
                || (typeof(this.propriete.tarifsJson) === 'undefined') || (this.propriete.tarifsJson === "")) {
                return(false);
            }
            return(true);
        },
    },

    methods: {
        // fonctions sur boutons
        supprimerTarifouPas() {
            this.dialog = true;
        },
        // cette fonction permet l'enregistrement dans la propriété des tarifs saisis - si pas de "tabTarifs", on ne fera rien
        onRetourForm(tabTarifs) {
            if (!tabTarifs) {
                this.terminerEdition("L'édition des tarifs a été abandonnée sans enregistrement.");
                return;
            }
            // TODO
            this.propriete.tarifsJson = JSON.stringify(tabTarifs);
            this.enregistrerPropriete("Les tarifs spécifiques de la propriété ont été enregistrés.");
        },
        // cette fonction permet l'enregistrement dans la propriété du fait qu'il n'y a plus de tarifs spécifiques
        supprimerTarif() {
            this.dialog = false;
            this.propriete.tarifsJson = "";
            this.enregistrerPropriete("L'abandon des tarifs spécifiques de la propriété est effectif.");
        },
        async enregistrerPropriete(message) {
            return await ApiUser.setPropriete(this.propriete) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
            .then(response => {
                // on retourne sur la propriété (qu'on va recharger)
                this.terminerEdition(message);
            })
        },

        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message) {
            if (message) {
                this.$store.commit('donnerMessage', message);
            }
            this.$router.push({ name: "propriete" });
        },

        initialiserTarifsJson() {
            if ((typeof(this.propriete.tarifsJson) !== 'undefined') && (this.propriete.tarifsJson !== "")) {
                this.propriete.tabTarifs = JSON.parse(this.propriete.tarifsJson);
                this.ready = true;
                return;
            }

            var tabTarifsBD = this.$store.getters.constantes.params.const_tarifs;
            var tabTarifsDebutValidite0 = tabTarifsBD.filter(this.filtrerValeursFinancieres);
            var tabTarifs = tabTarifsDebutValidite0.sort(this.triVolume);

            var proprieteTabTarifs = [];
            tabTarifs.forEach(tarif => {
                var objTarif = {};
                objTarif.anneeDebutValidite = tarif.anneeDebutValidite;
                objTarif.volumeMoyen = tarif.volumeMoyen;
                objTarif.prixM3 = tarif.prixM3;
                proprieteTabTarifs.push(objTarif);
            })
            this.propriete.tabTarifs = proprieteTabTarifs;

            this.ready = true;
            return;
        },
        filtrerValeursFinancieres(valeur, index) {
            return (valeur.anneeDebutValidite == 0)
        },
        triVolume(x, y) {
            return(x.volumeMoyen - y.volumeMoyen);
        },

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
}
</style>
