<template>
    <div id="itemCheck">
        <span v-if="!dataCout">
            <v-checkbox v-model="lItemCheck.choisi" :label="`${lItemCheck.libelle}`" @change="traiterItemCheck()"></v-checkbox>
        </span>
        <span v-if="dataCout">
            <v-checkbox v-model="lItemCheck.choisi" :label="`${lItemCheck.libelle+' ('+lItemCheck.coutHectare+' €/ha)'}`" @change="traiterItemCheck()"></v-checkbox>
        </span>
    </div>
</template>


<script>

export default {

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        },
        dataCout: {
            type:Boolean
        }
    },

	data(){
		return {
            itemCheck: JSON.parse(JSON.stringify(this.data)),
			ready:false,
			fmt:null,
		}
	},

	created: function () {
	},

	mounted: function () {
	},

	computed: {
		lItemCheck() {
			return(this.itemCheck)
		}
	},

	methods: {
        traiterItemCheck() {
             this.$emit("retourItemCheck", this.itemCheck);
		},
    }


	}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
    #itemCheck {
        padding:0;

        .v-input--selection-controls {
            margin:0;
            padding:0;

            .v-input__slot {
                margin:0;
                padding:0;
            }
        }

    }
</style>
