<template>
    <div class="wrapper">
    <div class="synchros">
        <h2>Historique des demandes de synchronisations</h2>
        <h3>Version STERES : {{laVersionSTERES}}</h3>
        <div v-if="!$parent.connexionUtilisateurValide">
        Vous devez vous connecter pour accéder à cette page
        </div>
        <div v-if="$parent.connexionUtilisateurValide && isReady">
            <div class="synchroGroup"  v-for="(synchro, numSynchro) in lesSynchros" :key="numSynchro" >
                <div class="blocACompleter">
                {{synchro.created}} : <span v-if="!synchro.ok">Erreur</span><span v-if="synchro.ok==1">OK</span><span v-if="synchro.ok==2">Envoyé</span>
                </div>
                <span class="complementBloc masquerDemasquer"   @click.stop="synchroOuvert = -1" v-if="leSynchroOuvert == numSynchro">
                    <i class="fas fa-caret-down"></i>
                </span>
                <span class="complementBloc masquerDemasquer"   @click.stop="synchroOuvert = numSynchro" v-if="leSynchroOuvert != numSynchro">
                    <i class="fas fa-caret-up"></i>
                </span>
                <div v-if="leSynchroOuvert == numSynchro">
                    {{synchro.json}}
                </div>
            </div>
            <div class="basSynchros" >
                &nbsp;
            </div>
        </div>

    </div>
    </div>
</template>


<script>

import { ApiUser } from '../js/api/index.js'
import * as SteresConst from '../js/steres_const.js'

export default {


    data(){
        return {
            ready:false,
            lesSynchros:[],
            synchroOuvert:0
        }
    },
    created: function () {
        this.ready = false;
    },
    mounted: function () {
            // on initialise
            this.ready = false;
            this.initialiser();
    },
    computed: {
        // est-ce que les données sont chargées ?
        isReady() {
            return this.ready
        },
        leSynchroOuvert() {
            return(this.synchroOuvert)
        },
        laVersionSTERES() {
            return(SteresConst.STERES_VERSION);
        }
    },

    methods: {
        initialiser() {
            return ApiUser.getLocalSynchro()
            .then(response => {
                    this.lesSynchros = response;
                    this.ready = true;
            })
        }

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #app {
        div.wrapper {
            .synchros {
                min-height:1200px;
                width:90%;
                margin: 2rem auto;
                text-align: left;
                .synchroGroup {
                    min-height:100%;
                    margin:1rem 0 0;
                    overflow-x: scroll;
                }
                .basSynchros {
                    /* sous le footer */
                    min-height: 100px;
                }
            }
        }
    }

</style>

