import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'




/*
import VeeValidate from "vee-validate";
import vSelect from "vue-select";
Vue.use(VeeValidate);
Vue.component("v-select", vSelect);
*/
//import Vuelidate from 'vuelidate'

Vue.config.productionTip = false

import axios from 'axios'
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

/*
import {
  ApiUser
} from './js/api/index.js'
*/

import Vuetify from 'vuetify'
Vue.use(Vuetify)

import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
//@import '~vuetify/src/stylus/main' // Ensure you are using stylus-loader

import './main.css' // Ensure you are using css-loader

import VueMatomo from 'vue-matomo'

import Meta from 'vue-meta';
Vue.use(Meta);

// OU 
// par LovefieldService
import { addeoData } from "./js/api/datas.js";
import { LovefieldService } from './js/db/LovefieldService.js'

initialiserMatomo();
lancerSteres();

function lancerSteres() {
    console.log("lancerSteres");
    /*
    return addeoData.lireDatasConstantes()
        .then(allConstData => {
            //addeoData.lireUsersData()
            var allUserData = {"data":{"user_utilisateurs": []}};
                return LovefieldService.loadData(allConstData, allUserData)
                .then(datas => {
                    if (!datas) {
                        console.log("no db");
                        return
                    }
    console.log("data loaded via LovefieldService : feu vert VUE");
                    new Vue({
                        router,
                        store,
                        render: h => h(App)
                    }).$mount('#app')
            })
        })
    */
    new Vue({
        data: {
            appName: "STERES"
        },
        router,
        store,
        render: h => h(App)
        }).$mount('#app');
}



function initialiserMatomo() {
    if (window.location.hostname.indexOf("localhost") < 0 ) {

        Vue.use(VueMatomo, {
          // Configure your matomo server and site by providing
          host: 'https://matomo2.privilis.com',
          siteId: 2,
          // Changes the default .js and .php endpoint's filename
          // Default: 'matomo'
          trackerFileName: 'matomo',

          // Overrides the autogenerated tracker endpoint entirely
          // Default: undefined
          // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

          // Overrides the autogenerated tracker script path entirely
          // Default: undefined
          // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

          // Enables automatically registering pageviews on the router
          router: router,

          // Enables link tracking on regular links. Note that this won't
          // work for routing links (ie. internal Vue router links)
          // Default: true
          enableLinkTracking: true,

          // Require consent before sending tracking information to matomo
          // Default: false
          requireConsent: false,

          // Whether to track the initial page view
          // Default: true
          trackInitialView: true,

          // Run Matomo without cookies
          // Default: false
          disableCookies: true,

          // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
          // Default: false
          enableHeartBeatTimer: false,

          // Set the heartbeat timer interval
          // Default: 15
          heartBeatTimerInterval: 15,

          // Whether or not to log debug information
          // Default: false
          debug: false,

          // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
          // Default: undefined
          userId: undefined,

          // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
          // Default: undefined, example '*.example.com'
          cookieDomain: undefined,

          // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
          // Default: undefined, example: '*.example.com'
          domains: undefined,
          //domains: '*.lorfolio.fr',

          // A list of pre-initialization actions that run before matomo is loaded
          // Default: []
          // Example: [
          //   ['API_method_name', parameter_list],
          //   ['setCustomVariable','1','VisitorType','Member'],
          //   ['appendToTrackingUrl', 'new_visit=1'],
          //   etc.
          // ]
          preInitActions: []
        });
    }
}


/* */
/* si on est hors ligne, on va prévenir l'utilisateur du danger de rafraichir sa page  */
/* */
/*
window.addEventListener("beforeunload", function (e) {
  var confirmationMessage = "Votre navigateur est trop vieux !";
    
  e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
  return confirmationMessage;              // Gecko, WebKit, Chrome <34
});
*/
