<template>
  <div class="synchroniser">
  </div>
</template>


<script>

//import { addeoData } from "../js/api/datas.js";
import { ApiUser } from '../js/api/index.js'

export default {

    mounted: function () {
        ApiUser.ecrireSynchroUtilisateur(this.$store.getters.utilisateurCourantId, true) // sauvegarde en DB et en global
    },

}
</script>
