/* eslint-disable no-console */

import { register } from 'register-service-worker'

/* version */
import * as SteresConst from './js/steres_const.js'
console.log('registerServiceWorker V', SteresConst.STERES_VERSION)

/**/
if (1 || (process.env.NODE_ENV === 'production')) {

    const registredEvent = new Event('swRegistered');
    //const updatedEvent = new Event('swUpdated');
    const errorEvent = new Event('swError');

//console.log('registerServiceWorker production')
  register(`${process.env.BASE_URL}service-worker-8.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      document.dispatchEvent(registredEvent); // on transmet l'enregistrement à app.vue
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
        //const updatefoundEvent = new CustomEvent('swUpdated', { detail: registration })
      //document.dispatchEvent(updatefoundEvent); // on transmet l'enregistrement à app.vue
      console.log('New content is downloading.')
    },
    updated (registration) {
        const updatedEvent = new CustomEvent('swUpdated', { detail: registration })
      document.dispatchEvent(updatedEvent); // on transmet l'enregistrement à app.vue
      console.log('New content is available; please refresh.') // proposer : window.location.reload(true)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      document.dispatchEvent(errorEvent); // on transmet l'erreur à app.vue
      console.error('Error during service worker registration:', error)
    }
  })
}
/**/



