<template>
 <div>

<v-dialog v-model="dialog"  persistent>
      <v-card>
        <v-card-title class="headline" v-if="ready && form.proprietaire">Saisie d'un propriétaire</v-card-title>
        <v-card-text v-if="ready && form.proprietaire">
          La personne dont vous saisissez les coordonnées en sera avertie par mail. Elle pourra valider cette saisie et accéder à STERES en fonction des droits que vous lui accordez sur la propriété (simple consultation ou édition - cf. case à cocher en bas de l'écran).
        </v-card-text>

        <v-card-title class="headline" v-if="!ready || !form.proprietaire">Saisie d'un ayant droit</v-card-title>
        <v-card-text v-if="!ready || !form.proprietaire">
          La personne dont vous saisissez les coordonnées en sera avertie par mail. Elle pourra valider cette saisie et accéder à STERES en fonction des droits que vous lui accordez sur la propriété (simple consultation).
        </v-card-text>

          <div class="modaleConteneur" v-if="ready">

                <v-text-field
                    label="Prénom"
                    v-model="form.prenom"
                    required
                    :error-messages="error.prenom"
                    ref="prenom"
                ></v-text-field>
                <v-text-field
                    label="Nom"
                    v-model="form.nom"
                    required
                    :error-messages="error.nom"
                    ref="nom"
                ></v-text-field>
                <v-text-field
                    label="Email"
                    v-model="form.adresseMail"
                    required
                    :error-messages="error.adresseMail"
                    ref="adresseMail"
                ></v-text-field>

              <v-checkbox  v-if="0 && form.proprietaire"
                v-model="form.utilisateurDroitEcriture"
                label="Le propriétaire peut modifier les saisies de sa propriété"
                ></v-checkbox>

            </div>

        <v-card-text>
            Rappel : Pour finaliser cette saisie, vous devrez enregistrer la propriété.
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  text @click="annulerUtilisateur()">Annuler</v-btn>
          <v-btn class="prioritaire" text @click="validerUtilisateur()">Valider</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

</div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, maxLength, email, minValue } from 'vuelidate/lib/validators'

import { ApiTools, ApiUser } from "../../js/api/index.js"


export default {

 //   mixins: [validationMixin],

    mixins: [validationMixin],

    props: {
        /**
         * Données à injecter dans le form
         */
        initDatas: {
            type: Object
        },
        ouvrirDialog : Boolean,
    },

    data() {
        return {
            dialog: this.ouvrirDialog,
            // init les données du form à partir des props
            form: JSON.parse(JSON.stringify(this.initDatas)),
            old: null,
            error: {
                nom: '',
                prenom: '',
                adresseMail: '',
            },
            ready:false,
        }
    },

    created() {
        // on init les données dans la var 'old' pour pouvoir annuler et remettre les données de form
        this.old = JSON.parse(JSON.stringify(this.form));
        //this.old = ApiTools.clone(this.form);
        //this.dialog = true;

        this.ready = true;
        this.$nextTick(() => {
                this.$refs.prenom.focus();
            })
    },

    validations() {
        return {
            form: {
                nom: { required, maxLength: maxLength(65) },
                prenom: { required, maxLength: maxLength(65) },
                adresseMail: { required, email, maxLength: maxLength(50) },
            }
        }
    },

    computed: {
    },

    methods: {

        async validerUtilisateur() {
            // on reinit à vide les messages d'erreur
            this.reinitErrorMessages();
            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (this.$v.$invalid) {
                // maj erreurs
                this.nomErrors();
                this.prenomErrors();
                this.adresseMailErrors();
                return false
            }

            // si pas de modification des données
            if (JSON.stringify(this.old) === JSON.stringify(this.form)) {
                this.dialog = false;
                //this.$emit('retourSaisieUtilisateur', this.form);
                this.$emit('retourSaisieUtilisateur') // 2021/03 - si rien on ne renvoie pas le form
                return
            }

            this.form.id = 0; // on ne sais pas qui est l'utilisateur
            var entityToSave = JSON.parse(JSON.stringify(this.form))
            console.log("saveForm OK")
            // const response = await ApiUser.setUtilisateur(entityToSave) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)
            return await ApiUser.setUtilisateur(entityToSave) // on sauvegarde notre UF (en DB locale et SI POSSIBLE en ligne)
             .then(response => {
                this.dialog = false;
                //this.form.id = entityToSave.id; // il faut connaitre l'id de l'utilisateur créé
                this.form.id = response; // il faut connaitre l'id de l'utilisateur créé
                this.$emit('retourSaisieUtilisateur', this.form)
            })
        },

        annulerUtilisateur() {
            this.dialog = false;
            this.$emit('retourSaisieUtilisateur')
            /*
            this.form = JSON.parse(JSON.stringify(this.old));
            this.$emit('retourSaisieUtilisateur', this.form)
            */
        },


        // validation du formulaire
        reinitErrorMessages() {
            this.error.nom = ''
            this.error.prenom = ''
            this.error.adresseMail = ''
        },
        nomErrors () {
            if (!this.$v.form.nom.$invalid) return false // verifier invalid
            if (!this.$v.form.nom.maxLength) {
                this.error.nom  = "La taille maximale du nom est de 65 caractères"
            }
            if (!this.$v.form.nom.required) {
                 this.error.nom  = "La saisie d'un nom est obligatoire"
            }
            return true
        },
        prenomErrors () {
            if (!this.$v.form.prenom.$invalid) return false // verifier invalid
            if (!this.$v.form.prenom.maxLength) {
                this.error.prenom  = "La taille maximale du prénom est de 65 caractères"
            }
            if (!this.$v.form.prenom.required) {
                 this.error.prenom  = "La saisie d'un prénom est obligatoire"
            }
            return true
        },
        adresseMailErrors () {
            if (!this.$v.form.adresseMail.$invalid) return false // verifier invalid
            if (!this.$v.form.adresseMail.required) {
                 this.error.adresseMail  = "La saisie d'une adresse mail est obligatoire"
            }
            if (!this.$v.form.adresseMail.email) {
                 this.error.adresseMail  = "Vous devez saisir une adresse mail valide"
            }
            if (!this.$v.form.adresseMail.maxLength) {
                this.error.adresseMail  = "La taille maximale d'une adresse mail est de 50 caractères"
            }
            return true
        },

        // pour la saisie
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    table {
    }
</style>
