import lf from 'lovefield'

export function user_ufpeuplement_it (schemaBuilder) {
  schemaBuilder.createTable('user_ufpeuplement_it')
      .addColumn('id', lf.Type.INTEGER)
      .addColumn('peuplementId', lf.Type.INTEGER)
      .addColumn('identifiant', lf.Type.STRING)
      .addColumn('choisi', lf.Type.INTEGER)
      .addColumn('auto', lf.Type.INTEGER)
      .addColumn('dateDebut', lf.Type.STRING)
      .addColumn('coupeRase', lf.Type.INTEGER)

      .addColumn('version', lf.Type.INTEGER)
      .addColumn('idLocal', lf.Type.INTEGER) // savoir si on a stocké avec un idLocal (donc bidon) false ou true (si bidon)
      .addPrimaryKey(['id'], false)
      .addNullable(['idLocal']);
}

