import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

function getTimeMSec() {
    return(Date.now());
}
function testerConnexionValide(start) {
    if (!start) {
        start = Date.now();
    }
    var millis = Date.now() - start;
    var min = Math.floor(millis/1000) / 60;
    if (min > 21) { // 11 minutes : 1 min après message
//    if (min > 0.6) { // 1 minute DEBUG
        return(false);
    }
    return(true);
}
function testerDeconnexion(start) {
    if (!start) {
        start = Date.now();
    }
    var millis = Date.now() - start;
    var min = Math.floor(millis/1000) / 60;
    if (min > 20) { // 10 minutes
//    if (min > 0.3) { // 30s DEBUG
        return(true);
    }
    return(false);
}

export default new Vuex.Store({
  state: {
      dbVersion:0,
      cguValidees:false,
      connexionTimeMSec:0,
      chargementAutorise:1,
	  deconnexion: false,
	  // les paramètres globaux de l'appli
	  synchronisationPossible: false,
	  accesInternet: true,
	  connexion: false,
	  lectureSeule: false,
      modeForet: false,
	  utilisateurCourant: {},
	  utilisateurCourantId: 0,
	  proprieteId: 0,
      proprieteChargeeId:0,
      proprieteChargeeNbUfs:0,
	  ufId:0,
	  peuplementId:0,
	  mesureId:0,
	  eclaircieId:0,
	  itId:0,
	  itEclaircieId:0,
      messageInformation:'',
	  // l'objet manipulé par la computed sur les menus (reinit à chaque resetDataApplication qui entraine un majDataApplication)
      dataApplication: {},
	  // les constantes lues en base une fois pour toutes
      constantes: {ready: false},
	  // données de la propriété
	  infosPropriete: {},
      //infosProprieteUFs:[], // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState
	  // données de l'UF
	  infosUf: {},
	  // données du peuplement
	  infosPeuplement: {},
	  infosMesure: {},
	  infosIt: {},
	  infosEclaircie: {},
	  infosItEclaircie: {},
	  // calculs
	  dendrometrie:{},
	  dendrometrie1:{},
	  dendrometrie2:{},
	  finances:{},
      trisFiltresPropriete:{},
      domaine:"",
  },

  mutations: {
      setCguValidees(state, etat) {
          state.cguValidees = etat;
	  },
      setConstantesSteres(state, constantes) {
          state.constantes.steres = constantes;
	  },
      setConstantesBobi(state, constantes) {
          state.constantes.repartitionBobi = constantes;
	  },
      setConstantesAbaqueEclaircies(state, constantes) {
          state.constantes.abaqueEclaircies = constantes;
	  },
      setConstantesParams(state, constantes) {
          state.constantes.params = constantes;
	  },
      setConstantesReady(state, etat) {
          state.constantes.ready = etat;
	  },
      setSynchronisationPossible(state, etat) {
          state.synchronisationPossible = etat;
	  },
      setAccesInternet(state, etat) {
          state.accesInternet = etat;
	  },
      setConnexion(state, etat) {
          state.connexion = etat;
		  if (etat) {
			state.deconnexion = false;
		  }
	  },
      setDeconnexion(state, etat) {
          state.deconnexion = etat;
	  },
      setLectureSeule(state, etat) {
          state.lectureSeule = etat;
	  },
      setModeForet(state, etat) {
          state.modeForet = etat;
	  },
      setUtilisateurCourant(state, utilisateur) {
          state.utilisateurCourant = utilisateur;
	  },
      setUtilisateurCourantId(state, id) {
          state.utilisateurCourantId = id;
	  },
      setProprieteId(state, id) {
          state.proprieteId = id;
          state.ufId = 0;
          state.peuplementId = 0;
	  },
      setProprieteChargeeId(state, id) {
          state.proprieteChargeeId = id;
	  },
      setProprieteChargeeNbUfs(state, nb) {
          state.proprieteChargeeNbUfs = nb;
	  },
	  setProprieteInfos(state, datas) {
          state.infosPropriete = datas;
	  },
 // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState
/*
      setProprieteUFs(state, datas) {
          state.infosProprieteUFs = datas;
	  },
*/
      setUfId(state, id) {
          state.ufId = id;
          state.peuplementId = 0;
          state.itId = 0;
	  },
	  setUfInfos(state, datas) {
          state.infosUf = datas;
	  },
      setPeuplementId(state, id) {
          state.peuplementId = id;
          state.itId = 0;
	  },
	  setPeuplementInfos(state, datas) {
          state.infosPeuplement = datas;
	  },
      setMesureId(state, id) {
          state.mesureId = id;
	  },
	  setMesureInfos(state, datas) {
          state.infosMesure = datas;
	  },
      setEclaircieId(state, id) {
          state.eclaircieId = id;
	  },
	  setEclaircieInfos(state, datas) {
          state.infosEclaircie = datas;
	  },
	  setItInfos(state, datas) {
          state.infosIt = datas;
	  },
      setItId(state, id) {
          state.itId = id;
	  },
      setItEclaircieId(state, id) {
          state.itEclaircieId = id;
	  },
	  setItEclaircieInfos(state, datas) {
          state.infositEclaircie = datas;
	  },
	  setDendrometrie(state, datas) {
          state.dendrometrie = datas;
	  },
	  setDendrometrie1(state, datas) {
          state.dendrometrie1 = datas;
	  },
	  setDendrometrie2(state, datas) {
          state.dendrometrie2 = datas;
	  },
	  setFinances(state, datas) {
          state.finances = datas;
	  },
	  setTrisFiltresPropriete(state, datas) {
          state.trisFiltresPropriete = datas;
	  },
	  resetTrisFiltresPropriete(state) {
          state.trisFiltresPropriete = {};
	  },
      majDataApplication(state) {
          state.dataApplication = {};
		  // et on met à jour toutes les variables
		  state.dataApplication.synchronisationPossible = state.synchronisationPossible // inutile pour le moment : seulement utilisé dans app
          state.dataApplication.connexion = state.connexion
          state.dataApplication.utilisateurCourant = state.utilisateurCourant
          state.dataApplication.utilisateurCourantId = state.utilisateurCourantId
          state.dataApplication.proprieteId = state.proprieteId
          state.dataApplication.proprieteChargeeId = state.proprieteChargeeId
          state.dataApplication.proprieteChargeeNbUfs = state.proprieteChargeeNbUfs
          state.dataApplication.ufId = state.ufId
          state.dataApplication.peuplementId = state.peuplementId
          state.dataApplication.mesureId = state.mesureId
          state.dataApplication.eclaircieId = state.eclaircieId
          state.dataApplication.itId = state.itId
          state.dataApplication.itEclaircieId = state.itEclaircieId
		  state.dataApplication.messageInformation = state.messageInformation
		  state.messageInformation = ""
      },
      resetDataApplication(state) {
          state.dataApplication = null;
	  },
      donnerMessage(state, message) {
          state.messageInformation = message;
      },
      setDbVersion(state, version) {
          if (state.dbVersion && (state.dbVersion != version)) {
              state.connexion = false;
              }
          state.dbVersion = version;
	  },
      setConnexionTimeMSec(state) {
          //if (!state.modeForet) {
            state.connexionTimeMSec = getTimeMSec();
          //}
      },
      resetConnexionTimeMSec(state) {
          if (state.connexion) {
			  if (!state.deconnexion) {
				state.deconnexion = testerDeconnexion(state.connexionTimeMSec);
			  }
              state.connexion = testerConnexionValide(state.connexionTimeMSec);
          }
      },
      setDomaine(state, leDomaine) {
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                    state.domaine = "LOCAL";
            }
            if (leDomaine.indexOf("addeo") >= 0) {
                    state.domaine = "ADDEO";
            }
            if (leDomaine.indexOf("preprod") >= 0) {
                    state.domaine = "PREPROD";
            }
            if (leDomaine === "steres.fr") {
                    state.domaine = "PROD";
            }
      }
  },

  actions: {

  },

  getters: {
    cguValidees: state => state.cguValidees,
    messageInformation: state => state.dataApplication.messageInformation,
    connexionTimeMSec: state => state.connexionTimeMSec,
    connexion: state => state.connexion,
    deconnexion: state => state.deconnexion,
    lectureSeule: state => state.lectureSeule,
    modeForet: state => state.modeForet,
    utilisateurCourant: state => state.utilisateurCourant,
    utilisateurCourantId: state => state.utilisateurCourantId,
    proprieteId: state => state.proprieteId,
    proprieteChargeeId: state => state.proprieteChargeeId,
    proprieteChargeeNbUfs: state => state.proprieteChargeeNbUfs,
    ufId: state => state.ufId,
    peuplementId: state => state.peuplementId,
    mesureId: state => state.mesureId,
    eclaircieId: state => state.eclaircieId,
    itId: state => state.itId,
    itEclaircieId: state => state.itEclaircieId,
    dataApplication: state => state.dataApplication,
    constantes: state => state.constantes,
    infosPropriete: state => state.infosPropriete,
    // infosProprieteUFs: state => state.infosProprieteUFs,  // Impossible de stocker dans le store en  :( 2020/11 / createPersistedState
    infosUf: state => state.infosUf,
    infosPeuplement: state => state.infosPeuplement,
    infosMesure: state => state.infosMesure,
    infosEclaircie: state => state.infosEclaircie,
    infosIt: state => state.infosIt,
    infosItEclaircie: state => state.infosItEclaircie,
    dendrometrie: state => state.dendrometrie,
    dendrometrie1: state => state.dendrometrie1,
    dendrometrie2: state => state.dendrometrie2,
    finances: state => state.finances,
    trisFiltresPropriete: state => state.trisFiltresPropriete,
    dbVersion: state => state.dbVersion,
    chargementAutorise: state => state.chargementAutorise,
    domaine: state => state.domaine,
  },

    plugins: [createPersistedState()]
})
