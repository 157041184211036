<template>
    <div id="decoupeParcellesComposant">
        <div v-if="ready" class="conteneurTable">

            <table >
                <tr class="titresTableau">
                    <th >
                        <span class="mineur">Unité forestière<br/>{{uf.identifiant}}</span>
                        <br/>avant découpe
                    </th>
                    <td>
                        <span class="mineur">Unité forestière<br/>{{uf.identifiant}}</span>
                        <br/>après découpe
                    </td>
                    <td>
                        Nouvelle unité forestière
                        <span class="saisie">
                            <v-text-field
                                label="Nom de l'unité forestière *"
                                v-model="form1.identifiant"
                                required
                            ></v-text-field>
                        </span>
                    </td>
                </tr>
            </table>

            <table v-for="(leGroupe, numGroupe) in  lesParcelles" :key="numGroupe" >
                <tr class="commune">
                    <th >
                        {{ leGroupe.codePostal }} {{ leGroupe.libelleCommune }}
                    </th>
                    <td >
                    </td>
                    <td >
                    </td>
                </tr>
                <tr class="parcelles" v-for="(laParcelle, numParcelle) in  leGroupe.parcelles" :key="numParcelle">
                    <th>Parcelle "<span v-if="laParcelle.sectionCadastrale !== ''">{{ laParcelle.sectionCadastrale }} : {{ laParcelle.numeroCadastral}}</span><span v-if="(laParcelle.sectionCadastrale === '') && (laParcelle.identifiant != '')">{{ laParcelle.identifiant }}</span>"<br/>
                        <span class="mineur" v-if="(laParcelle.sectionCadastrale !== '') && (laParcelle.identifiant != '')">{{ laParcelle.identifiant }}</span><br/><span class="hectares">{{arrondiHectares(laParcelle.surface)}} ha</span></th>
                    <td v-bind:class="{inhibe : lesParcelles0[numGroupe].parcelles[numParcelle].surface === 0}">
                        Parcelle "<span v-if="lesParcelles0[numGroupe].parcelles[numParcelle].sectionCadastrale !== ''">{{ lesParcelles0[numGroupe].parcelles[numParcelle].sectionCadastrale }} : {{ lesParcelles0[numGroupe].parcelles[numParcelle].numeroCadastral}}</span><span v-if="(lesParcelles0[numGroupe].parcelles[numParcelle].sectionCadastrale === '') && (lesParcelles0[numGroupe].parcelles[numParcelle].identifiant != '')">{{ lesParcelles0[numGroupe].parcelles[numParcelle].identifiant }}</span>"<br/><br/>
                        <span class="hectares">{{arrondiHectares(lesParcelles0[numGroupe].parcelles[numParcelle].surface)}} ha</span>
                    </td>
                    <td>
                        <span class="checkbox">
                            <v-checkbox
                                v-model="lesParcelles1[numGroupe].parcelles[numParcelle].selectionnee"
                                label=""
                                @change="traiterNouvelleParcelleCheck(numGroupe, numParcelle)"
                            ></v-checkbox>
                        </span>
                        Parcelle "<span v-if="lesParcelles1[numGroupe].parcelles[numParcelle].sectionCadastrale !== ''">{{ lesParcelles1[numGroupe].parcelles[numParcelle].sectionCadastrale }} : {{ lesParcelles1[numGroupe].parcelles[numParcelle].numeroCadastral}}</span><span v-if="(lesParcelles1[numGroupe].parcelles[numParcelle].sectionCadastrale === '') && (lesParcelles1[numGroupe].parcelles[numParcelle].identifiant != '')">{{ lesParcelles1[numGroupe].parcelles[numParcelle].identifiant }}</span>"
                        <span class="saisie surface">
                            <v-text-field
                                label="Surface (ha) *"
                                v-model="lesParcelles1[numGroupe].parcelles[numParcelle].surface"
                                type="number"
                                @keydown="filtrerNombresPositifs"
                                ref="saisieSurface1"
                                min=1
                                required
                                @input="traiterNouvelleParcelleSurface(numGroupe, numParcelle)"
                                :disabled="lesParcelles1[numGroupe].parcelles[numParcelle].selectionnee ? false : true"
                            ></v-text-field>
                        </span>
                        <span  v-if="lesParcelles1[numGroupe].parcelles[numParcelle].message != ''" class="message clear">
                            {{lesParcelles1[numGroupe].parcelles[numParcelle].message}}
                        </span>
                    </td>
                </tr>
            </table>
            <table  class="piedTableau">
                <tr>
                    <th >
                        <span class="recap">Surface totale : {{arrondiHectares(surfaceTotale)}} ha</span>
                    </th>
                    <td>
                        <span class="recap">Surface totale : {{arrondiHectares(surfaceTotale0)}} ha</span>
                    </td>
                    <td>
                        <span class="recap">Surface totale : {{arrondiHectares(surfaceTotale1)}} ha</span>
                    </td>
                </tr>
                <tr>
                    <th >
                        <span class="recap">Surface boisée : {{arrondiHectares(uf.peuplement.surfaceBoisee)}} ha</span>
                        <span  v-if="this.uf.message != ''" class="message clear">
                            {{this.uf.message}}
                        </span>
                    </th>
                    <td>
                        <span class="saisie surface">
                            <v-text-field
                                label="Surface boisée (ha) *"
                                v-model="form0.peuplement.surfaceBoisee"
                                type="number"
                                @keydown="filtrerNombresPositifs"
                                @input="traiterSurfaceTotale0()"
                                min=1
                                required
                            ></v-text-field>
                        </span>
                        <span  v-if="this.form0.message != ''" class="message clear">
                            {{this.form0.message}}
                        </span>
                    </td>
                    <td>
                        <span class="saisie surface">
                            <v-text-field
                                label="Surface boisée (ha) *"
                                v-model="form1.peuplement.surfaceBoisee"
                                type="number"
                                @keydown="filtrerNombresPositifs"
                                @input="traiterSurfaceTotale1()"
                                min=1
                                required
                            ></v-text-field>
                        </span>
                        <span  v-if="this.form1.message != ''" class="message clear">
                            {{this.form1.message}}
                        </span>
                    </td>
                </tr>
            </table>

            <v-card-actions>
                <div>
                    <v-btn class="a-btn secondaire" type="button" @click="annulerDecoupe">Annuler</v-btn>
                    <v-btn class="a-btn prioritaire registerBD" type="button" @click="validerDecoupe">Enregistrer</v-btn>
                </div>
            </v-card-actions>


            <v-dialog v-model="dialogError" v-if="errorDecoupe" >
                 <v-card>
                     <v-card-title class="headline">Enregistrement impossible</v-card-title>
                     <v-card-text>
                         <span v-if="errorDecoupe">{{errorDecoupe}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="dialogError=false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </div>
</template>


<script>

import { ApiUser, ApiTools } from "../../js/api/index.js"

export default {
    components: {
    },

    props: {
        /**
         * Données à injecter dans le form
         */
        data: {
            type: Object
        }
    },

    data() {
        return {

            // init les données du form à partir des props
            form:{},
            uf: JSON.parse(JSON.stringify(this.data)),
            form0: JSON.parse(JSON.stringify(this.data)), // TODO
            form1: JSON.parse(JSON.stringify(this.data)),
            parcelles:[],
            ready:false,
            dialogError:false,
            errorDecoupe:false,
        }
    },

    created() {
        var reinit = false;
        // l'ancienne UF
        this.parcelles = this.initialiserTabParcelles(this.uf, reinit);
        this.uf.message = "";
        // l'ancienne modifiée
        this.parcelles0 = this.initialiserTabParcelles(this.form0, reinit);
        this.form0.peuplement.surfaceBoisee = 0;
        this.form0.message = "";
        // la nouvelle
        reinit = true;
        this.parcelles1 = this.initialiserTabParcelles(this.form1, reinit);
        this.form1.id = 0;
        this.form1.identifiant = "";
        this.form1.code = "";

        this.form.uf = this.uf;
        this.form.form0 = this.form0;
        this.form.form1 = this.form1;

        this.ready = true;
    },

    computed: {
        // pour affichage "à jour" de la liste
        lesParcelles() {
            return(this.parcelles);
        },
        lesParcelles0() {
            return(this.parcelles0);
        },
        lesParcelles1() {
            return(this.parcelles1);
        },
        // calcul de la somme des surfaces des parcelles
        surfaceTotale() {
            var laSurface = 0.0;
            this.uf.tabParcelles.forEach(parcelle => {
                var surface = parseFloat(parcelle.surface)
                if (isNaN(surface)) {
                    surface = 0;
                }
                laSurface += surface;
            })
            return laSurface;
        },
        surfaceTotale0() {
            var laSurface = 0.0;
            this.form0.tabParcelles.forEach(parcelle => {
                var surface = parseFloat(parcelle.surface)
                if (isNaN(surface)) {
                    surface = 0;
                }
                laSurface += surface;
            })
            return laSurface;
        },
        surfaceTotale1() {
            var laSurface = 0.0;
            this.form1.tabParcelles.forEach(parcelle => {
                var surface = parseFloat(parcelle.surface)
                if (isNaN(surface)) {
                    surface = 0;
                }
                laSurface += surface;
            })
            return laSurface;
        },
    },

    methods: {
        initialiserTabParcelles(uf, reinit) {
           var tabTmp = [];
           tabTmp = tabTmp.concat(uf.tabParcelles);

           if (reinit) {
               uf.peuplement = JSON.parse(JSON.stringify(uf.peuplement));
               uf.peuplement.surfaceBoisee = 0;
               uf.peuplement.id = 0;
               uf.peuplement.ufId = 0;
               uf.message = "";
           }

           // on créé un nouveau tableau : niveau 1 : Ville / niveau deux : les parcelles sur cette ville
           var tabRetour = [];
           var parcellePred = null;
           tabTmp.forEach(parcelle => {
               if (reinit) {
                   parcelle.id = 0;
                   parcelle.ufId = 0;
                   parcelle.surface = 0;
                   parcelle.selectionnee = true;
                   parcelle.message = "";
               }
               var newObj = {"parcelles":[]};
               if (parcellePred && (parcellePred.libelleCommune == parcelle.libelleCommune)) {
                   var objTmp = tabRetour[tabRetour.length-1];
                   objTmp.parcelles[objTmp.parcelles.length] = parcelle;
               }
               else {
                   newObj.libelleCommune = parcelle.libelleCommune;
                   newObj.codePostal = parcelle.codePostal;
                   newObj.parcelles[0] = parcelle;
                   tabRetour[tabRetour.length] = newObj;
                   parcellePred = parcelle
               }

            })
            return(tabRetour);
        },

        refAssociee(numGroupe, numParcelle) {
            var refAssociee = 0;
            this.lesParcelles1.forEach((groupe, indexGroupe) => {
                if (indexGroupe <= numGroupe) {
                    groupe.parcelles.forEach((parcelle, indexParcelle) => {
                        if (((indexGroupe < numGroupe)) || (indexParcelle < numParcelle)) {
                            refAssociee ++;
                        }
                    })
                }
            })
            return(refAssociee);
        },

        erreurParcelle() {
            var erreur = false;
            this.lesParcelles1.forEach(groupe => {
                groupe.parcelles.forEach(parcelle => {
                    if (parcelle.message) {
                        erreur = true;
                    }
                })
            })
            return(erreur);
        },

        traiterNouvelleParcelleCheck(numGroupe, numParcelle) {
            if (this.lesParcelles1[numGroupe].parcelles[numParcelle].selectionnee) {
                // on active la saisie associée - fait par VUE
                // on la met à 0 - il y était déjà mais c'est maj force le réaffichage
                this.lesParcelles1[numGroupe].parcelles[numParcelle].surface = -1;
                this.lesParcelles1[numGroupe].parcelles[numParcelle].surface = 0;
            }
            else {

                // on désactive la saisie associée - fait par VUE
                // on la met à 0
                this.lesParcelles1[numGroupe].parcelles[numParcelle].surface = -1;
                this.lesParcelles1[numGroupe].parcelles[numParcelle].surface = 0;
                // on réinitialise l'ancienne UF
                this.lesParcelles0[numGroupe].parcelles[numParcelle].surface = parseFloat(this.lesParcelles[numGroupe].parcelles[numParcelle].surface);
            }

        },

        traiterNouvelleParcelleSurface(numGroupe, numParcelle) {
            var surfaceAvantDecoupe = parseFloat(this.lesParcelles[numGroupe].parcelles[numParcelle].surface);
            this.lesParcelles1[numGroupe].parcelles[numParcelle].message = "";
            var refAssocie = this.refAssociee(numGroupe, numParcelle);
            var surfaceFloat = parseFloat(this.lesParcelles1[numGroupe].parcelles[numParcelle].surface);
            if (isNaN(surfaceFloat)) {
                return;
            }
            this.lesParcelles0[numGroupe].parcelles[numParcelle].surface = ApiTools.arrondir(surfaceAvantDecoupe - surfaceFloat, 4);
            if (surfaceAvantDecoupe < surfaceFloat) {
                    this.lesParcelles1[numGroupe].parcelles[numParcelle].message = 'Attention, la surface de la nouvelle parcelle ne peut excéder celle "avant découpe"". Revoyez votre saisie.'
                }
            this.traiterSurfaceTotale0();
            this.traiterSurfaceTotale1();
        },

        traiterSurfaceTotale0() {
            this.form0.message = "";
            var surfaceBoisee = parseFloat(this.form0.peuplement.surfaceBoisee);
            if (isNaN(surfaceBoisee)) {
                surfaceBoisee = 0;
            }
            if (surfaceBoisee) {
                this.form0.peuplement.surfaceBoisee = surfaceBoisee;
            }
            if (this.surfaceTotale0 < this.form0.peuplement.surfaceBoisee) {
                this.form0.message = "Attention, la surface boisée de l'unité forestière ne peut pas dépasser sa surface totale !";
            }
            this.traiterSurfacesTotales();
        },

        traiterSurfaceTotale1() {
            this.form1.message = "";
            var surfaceBoisee = parseFloat(this.form1.peuplement.surfaceBoisee);
            if (isNaN(surfaceBoisee)) {
                surfaceBoisee = 0;
            }
            if (surfaceBoisee) {
                this.form1.peuplement.surfaceBoisee = surfaceBoisee;
            }
            if (this.surfaceTotale1 < this.form1.peuplement.surfaceBoisee) {
                this.form1.message = "Attention, la surface boisée de la nouvelle unité forestière ne peut pas dépasser sa surface totale !";
            }
            this.traiterSurfacesTotales();
        },

        traiterSurfacesTotales() {
            this.uf.message = "";
            // ... valider que la somme des surfaces boisées n'excède pas la précédente ...
            if (this.uf.peuplement.surfaceBoisee < (this.form0.peuplement.surfaceBoisee + this.form1.peuplement.surfaceBoisee)) {
                this.uf.message = "La somme des surfaces boisées saisies dépasse la surface boisée avant découpe. Vous pouvez conserver ces valeurs si vous jugez qu'elles sont correctes ...";
            }
        },


        // annulation du formulaire
        annulerDecoupe() {
            // on prépare le message passé à la prochaine page
            var message = "La découpe de l'Unité Forestière "+this.uf.identifiant+ " a été abandonnée.";
            // on va au bon endroit avec les bonnes valeurs
            this.terminerEdition(message, "ufedition");
        },


        validationPossible() {
            var retour = true;
            this.errorDecoupe = false;

            if (this.form0.peuplement.surfaceBoisee < 0 || this.form1.peuplement.surfaceBoisee < 0) {
                this.errorDecoupe = "Les nouvelles surfaces boisées doivent être renseignées avec des valeurs supérieures au moins égales à 0. Corrigez votre saisie !";
                retour = false;
            }
            if (this.form1.identifiant == "") {
                this.errorDecoupe = "Vous devez saisir un nom pour la nouvelle unité forestière. Corrigez votre saisie !";
                retour = false;
            }
            if (this.form0.message || this.form1.message) {
                this.errorDecoupe = "Corrigez la saisie des surfaces boisées comme les messages en rouge vous y invitent.";
                retour = false;
            }
            if (this.erreurParcelle()) {
                this.errorDecoupe = "Corrigez la saisie des surfaces de la nouvelle unité forestière comme les messages en rouge vous y invitent.";
                retour = false;
            }

            if (!retour) {
                this.dialogError = true;
            }

            return(retour);
        },


        preparerSauvegardeParcelles(uf, tabParcellesGroupe) {

            uf.tabParcelles = [];
            tabParcellesGroupe.forEach(groupe => {
                groupe.parcelles.forEach(parcelle => {
                    var surface = parseFloat(parcelle.surface)
                    if (isNaN(surface)) {
                        surface = 0;
                    }
                    if (surface) {
                        parcelle.surface = surface;
                        uf.tabParcelles.push(parcelle);
                    }
                })
            })

            return(uf.peuplement)
        },

        dupliquerTabEnfants(tabSource, idItSTERES = 0) {
            var tabEnfants = [];
            if (tabSource) {
                tabEnfants = JSON.parse(JSON.stringify(tabSource));
                tabEnfants.forEach(enfant => {
                    enfant.id = 0;
                    if (idItSTERES) { // on est sur un It - on donne l'ID de l'IT créée par défaut par le backoffice de STERES
                        if (enfant.auto) {
                            enfant.id = idItSTERES;
                        }
                    }
                    enfant.peuplementId = 0;
                    // et en plus s'il s'agit d'un IT il peut avoir des eclaircies !
                    if (enfant.eclaircies) {
                        enfant.eclaircies.forEach(eclaircie => {
                            eclaircie.id = 0;
                            eclaircie.itId = 0;
                        })
                    }
                })
            }
            return(tabEnfants);
        },

        dupliquerEnfantsPeuplement(peuplementCible, peuplementSource) {
            peuplementCible.mesures = this.dupliquerTabEnfants(peuplementSource.mesures);
            peuplementCible.eclaircies = this.dupliquerTabEnfants(peuplementSource.eclaircies);
            // pour la duplication des ITs : attention, le backoffice créée automatique un ITs STERES lors de la création deu peuplement -> on conserve son id !
            var idItSTERES = 0;
            if ((typeof(peuplementCible.ITs) !== 'undefined') && (typeof(peuplementCible.ITs[0]) !== 'undefined') && (typeof(peuplementCible.ITs[0].id) !== 'undefined')) {
                idItSTERES = peuplementCible.ITs[0].id;
            }
            peuplementCible.ITs = this.dupliquerTabEnfants(peuplementSource.ITs, idItSTERES);
            return(peuplementCible);
        },

        // enregistrement du formulaire
        async validerDecoupe() {

            var message = "";
            if (!this.validationPossible()) {
                return false
            }

            // si leur surface n'est pas nulle, on recrée les parcelles de chaque UF
            var peuplement0 = this.preparerSauvegardeParcelles(this.form0, this.parcelles0);
            var peuplement1 = this.preparerSauvegardeParcelles(this.form1, this.parcelles1);

            // on sauvegarde l'ancienne UF modifiée (form0), ses parcelles et son peuplement
            var entityToSave = JSON.parse(JSON.stringify(this.form0)); // inutile ?
            return ApiUser.setUF(entityToSave) // maj de l'UF et de toutes ses parcelles (les anciennes parcelles sont détruites)
            .then(responseUfId => {
                peuplement0.ufId = responseUfId
                return ApiUser.setPeuplement(peuplement0, this.$store.getters.constantes) // et enfin le peuplement ... dont on a changé la surface boisée
                .then(responsePeuplement => {

                    entityToSave = JSON.parse(JSON.stringify(this.form1)); // inutile ?
                    return ApiUser.setUF(entityToSave) // maj de l'UF et de toutes ses parcelles (les anciennes parcelles sont détruites)
                    .then(responseUfId => {
                        peuplement1.ufId = responseUfId
                        return ApiUser.setPeuplement(peuplement1, this.$store.getters.constantes) // et enfin le peuplement de la nouvelle UF
                        .then(responsePeuplement => {

                            // on a l'id du peuplement : on peut maintenant sauvegarder ses enfants : mesures, eclaircies et ITs
                            peuplement1 = this.dupliquerEnfantsPeuplement(responsePeuplement, peuplement0);
                            return ApiUser.setEnfantsPeuplement(peuplement1)
                            .then(responsePeuplementEnfants => {
                                this.$store.commit('setUfId', 0);
                                this.$store.commit('setUfInfos', {});
                                this.$store.commit('setPeuplementId', 0);
                                this.$store.commit('setPeuplementInfos', {});
                                // on prépare le message passé à la prochaine page
                                message = "La découpe de l'Unité Forestière "+this.uf.identifiant+ " a bien été enregistrée.";
                                /*
                                if (navigator.onLine) {
                                    message = "La découpe de l'Unité Forestière "+this.uf.identifiant+ " a bien été enregistrée.";
                                }
                                else {
                                    message = "La découpe de l'Unité Forestière "+this.uf.identifiant+ " a bien été enregistrée. Attention, vous travaillez hors ligne, il vous faudra donc synchroniser cette saisie ultérieurement avce la base de données Stères.";
                                }
                                */
                                // on va au bon endroit avec les bonnes valeurs
                                this.terminerEdition(message);
                            })
                        })
                    })
                })
            })
        },

        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message, destination = "propriete") {
            if (message) {
                this.$store.commit('donnerMessage', message);
            }
            this.$router.push({ name: destination});
        },

/*
    triVille(x, y) {
           if (x.libelleCommune < y.libelleCommune) {
               return(-1)
           }
           if (x.libelleCommune > y.libelleCommune) {
               return(1)
           }
           if (x.identifiant < y.identifiant) {
               return(-1)
           }
           if (x.identifiant > y.identifiant) {
               return(1)
           }
           return(0)
        },
*/
        arrondiHectares(valeur) {
            return(ApiTools.arrondiSurface(valeur, this.$store.getters.constantes.steres.arrondisJson));
        },
        // pour la saisie
        filtrerNombresEntiersPositifs(e){
            return(ApiTools.filtrerNombresEntiersPositifs(e))
        },
        filtrerNombresPositifs(e){
            return(ApiTools.filtrerNombresPositifs(e))
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
    #decoupeParcellesComposant {
        div.conteneurTable {

            table {
                width : 100%;
                min-width: 600px;
                background-color: white;
                margin:0;
                color:#223938;
                padding: 0;
                border-bottom:1px solid #dddddd;
                border-collapse: collapse;
                cell-spacing:0;
                cell-padding:0;
                tr {
                    th, td {
                        vertical-align: top;
                        padding:0.4em 2%;

                        span.hectares, span.recap {
                            float:right;
                        }
                        span.mineur {
                        font-weight: normal;
                            color:#777777;
                        }
                    }
                    th {
                        width : 22%;
                        background-color: #eeeeee;
                        /*border-right:1px solid #dddddd;*/

                        span.message {
                            color:#7DAB87;
                            display:block;
                            width:100%;
                            padding: 0.4em 0;
                            font-weight: normal;
                            font-size: 0.9em;
                        }
                    }
                    td {
                        width : 30%;

                        span.checkbox {
                            div {
                                display: inline;
                            }
                            .v-input {
                                background-color: transparent;
                            }
                            .v-icon {
                                color: #394f4e;
                            }
                        }

                        span.hectares, span.recap {
                            margin-right: 2em;
                        }

                        &:last-child {
                            border-left:1px solid #dddddd;
                        }

                        span.message {
                            color:red;
                            display:block;
                            width:100%;
                            padding-right: 2em;
                        }

                        &.inhibe {
                            color:#dddddd;
                        }
                    }
                    span.saisie {
                        font-weight: normal;
                        display: block;

                        &.surface {
                            float: right;
                            margin-right:2em;
                            max-width: 140px;
                        }
                    }

                    &.titresTableau {
                        font-size:1.4em;
                        td {
                            font-weight: bold;
                        }
                    }
                    &.commune {
                        th, td {
                            background-color: #f2f2f2;
                            border-bottom:1px solid #dddddd;
                        }
                    }


                    &:last-child {
                        th, td {
                            border-bottom:none;
                        }
                    }


                    &.parcelles {
                        th, td {
                            border-bottom:1px solid #dddddd;
                        }
                    }
                }

                &.piedTableau {
                    border-bottom:none;
                }
            }
        }

        .v-card__actions {
            text-align: center;
            margin: 2em 0 0;

            &>div {
                margin:0 auto;
            }
        }
    }
</style>
